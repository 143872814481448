import { useActivityEntity } from 'src/hooks/useEntity'
import { BucketsAndCupsGroupGroupEntity } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'

/**
 *
 * @param props entityId: string representing the entity to observe changes, selector is the store to look for the entity
 * @returns { entity: T, dispatch: (evt: StoreEvent) => void } while the dispatch is exposed, recommend to not use it unless there is no other clean design
 */
export function useBucketsAndCupsGroupEntity(props: {
    workflowEntityId: string
    moduleEntityId: string
}) {
    return useActivityEntity<BucketsAndCupsGroupGroupEntity>(props)
}
