import * as React from 'react'
import { useState } from 'react'

import { InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { SearchField } from '@amzn/stencil-react-components/search'
import { Text } from '@amzn/stencil-react-components/text'

import { Countries, Country } from 'src/pages/scoring-test-ui/Countries'

export interface CountrySearchFieldProps {
    onValueChange: (value: string) => void
    countryId: string
    countryIdNotFound: boolean
}

export const CountrySearchField = (props: CountrySearchFieldProps) => {
    const { onValueChange, countryId, countryIdNotFound } = props
    const [countryList, setCountryList] = useState<Country[]>(Countries)

    const renderResult = (option: Country) => (
        <Row>
            <ScreenReaderOnly>{option.name}</ScreenReaderOnly>
            <Col height='auto' minWidth='S100' width='S100' />
            <Text aria-hidden={true} fontSize='T200' color='neutral100' fontWeight='bold'>
                {option.name}
            </Text>
            <Spacer width='S100' />
            <Text aria-hidden={true} color='neutral70'>
                -
            </Text>
            <Spacer width='S100' />
            <Text aria-hidden={true} color='primary70' fontWeight='bold'>
                {option.alpha3Code}
            </Text>
        </Row>
    )

    const searchCountries = (value: string) => {
        onValueChange(value)
        const updatedList = Countries.filter(
            (country) =>
                country.name.toLowerCase().includes(value.toLowerCase()) ||
                country.alpha3Code.toLowerCase().includes(value.toLowerCase())
        )
        setCountryList(updatedList)
    }

    return (
        <Col>
            <InputWrapper
                id='country-id-wrapper'
                labelText='Country ID'
                error={countryIdNotFound}
                footer={countryIdNotFound ? 'The country ID was not found' : undefined}
            >
                {(inputProps) => (
                    <SearchField
                        {...inputProps}
                        data-test-id='country-id'
                        results={countryList}
                        resultToString={(result: Country) => result.name}
                        renderResult={renderResult}
                        name='Country ID'
                        placeholder='Enter the Country ID here...'
                        query={countryId}
                        onChange={searchCountries}
                        onResultClick={(value: Country) => onValueChange(value.alpha3Code)}
                        listMaxHeight='45vh'
                        error={countryIdNotFound}
                    />
                )}
            </InputWrapper>
        </Col>
    )
}
