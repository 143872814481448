import React, { useState } from 'react'
import _ from 'lodash'

import { Col, Container, Hr, Spacer, View } from '@amzn/stencil-react-components/layout'

import { Button } from 'src/components/Button'
import { TreeEditor } from 'src/pages/GraphConstructionEditor/Editor'
import {
    Alt,
    expandToDistributions,
    GraphConstructionType,
    itemCodeConstruction,
    Leaf,
    Seq,
} from 'src/pages/GraphConstructionEditor/index'

export const GraphConstructionEditorPage = ({
    initialValue,
    showButton = true,
}: {
    initialValue?: Alt
    showButton?: boolean
}) => {
    const [value, setValue] = useState<Alt | Leaf | Seq>(() =>
        _.cloneDeep(initialValue ?? itemCodeConstruction)
    )

    return (
        <Container>
            <View role='tree' aria-multiselectable={false}>
                <TreeEditor
                    value={value}
                    setValue={setValue}
                    type={GraphConstructionType.Alt}
                    hideWeight
                    isRoot
                />
            </View>
            {showButton && (
                <>
                    <Spacer height='S200' />
                    <Hr />
                    <Spacer height='S200' />
                    <Col>
                        <Button
                            onClick={() => {
                                console.log(expandToDistributions(value))
                            }}
                        >
                            Expand to distribution (see console)
                        </Button>
                    </Col>
                </>
            )}
        </Container>
    )
}
