import { useCallback } from 'react'

import { ItemDTO } from 'src/models/dto/items/ItemDTO'
import { RowLayoutDTO, StaticContentLayoutDTO } from 'src/models/dto/items/StaticContentLayoutDTO'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'

export function useUpdateRowLayoutDTO<T extends RowLayoutDTO>(itemId: string, rowIndex: number) {
    return useCallback(
        (change: (prevValue: T) => T) => {
            ItemEntityService.updateWithChange(itemId, (dto) => {
                const newRows = [...(dto as StaticContentLayoutDTO).rows]
                newRows[rowIndex] = change(newRows[rowIndex] as T)

                return {
                    ...(dto as StaticContentLayoutDTO),
                    rows: newRows,
                } as ItemDTO
            })
        },
        [rowIndex, itemId]
    )
}
