import React, { useState } from 'react'
import PapaParse, { ParseResult } from 'papaparse'

import { Button } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Label } from '@amzn/stencil-react-components/dist/submodules/text'
import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { Col, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { useBucketsAndCupsGroupEntity } from 'src/hooks/useBucketsAndCupsGroup'
import { BucketDTO, CupDTO } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import { ItemPoolModal } from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ItemPoolModal'
import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'

export interface CSVImporterProps {
    moduleId: string
    entityId: string
    setNumberOfImports: (value: (prevState: number) => number) => void
    setImportSuccess: (value: boolean) => void
    editDisabled?: boolean
}

export const ValidationErrorBanner = (props: { errors: string[] }) => {
    if (props.errors.length === 0) {
        return null
    }

    return (
        <>
            <MessageBanner
                dataTestId='buckets-and-cups-csv-errors'
                titleText={'Buckets and Cups CSV Errors'}
                type={MessageBannerType.Error}
                isDismissible={true}
            >
                <ul>
                    {props.errors.map((errorMessage, index) => (
                        <li key={index} data-test-id='module-validation-error-message'>
                            {errorMessage}
                        </li>
                    ))}
                </ul>
            </MessageBanner>
            <Spacer width={'S300'} />
        </>
    )
}

const indexOfHeader = (
    results: PapaParse.ParseResult<string[]>,
    headerName: string,
    errors: string[]
): number => {
    const lowerCaseHeaderName = headerName.toLowerCase()

    const value = results.data[0].findIndex((v) => {
        return v.toLocaleLowerCase().trim() === lowerCaseHeaderName
    })

    if (value < 0) {
        errors.push(`The CSV file is missing a column named '${headerName}'`)
    }

    return value
}

interface ImportedBucket {
    bucketDTO: BucketDTO
    rowNumber: number
}

interface ImportedCup {
    cupDTO: CupDTO
    rowNumber: number
}

export const enum BUCKETSANDCUPSCSVHEADERS {
    ITEM_ID = 'Item ID.',
    CUP = 'Cup',
    BUCKET = 'Bucket',
    BUCKET_RANK = 'Bucket Rank',
    CUP_RANK = 'Cup Rank',
    BUCKET_MAX_FREQUENCY = 'Bucket Max Frequency',
}
const MAX_NUMBER_OF_ERRORS_TO_DISPLAY = 10
const parseCSV = (results: ParseResult<string[]>): [BucketDTO[], string[]] => {
    const importedCups: Record<string, ImportedCup> = {}
    const importedBuckets: Record<string, ImportedBucket> = {}
    const bucketForCup: Record<string, ImportedBucket> = {}
    let errors: string[] = []
    const importedItemIds: Map<string, number[]> = new Map<string, number[]>()

    const legacyRankIndex = results.data[0].findIndex((v) => {
        return v.toLocaleLowerCase().trim() === 'rank'
    })

    if (legacyRankIndex >= 0) {
        errors.push(
            "Your CSV file has a column named 'Rank', the legacy name for 'Bucket Rank'. Please rename the column 'Bucket Rank'"
        )
        return [[], errors]
    }

    const itemVersionIdColumnIndex = indexOfHeader(
        results,
        BUCKETSANDCUPSCSVHEADERS.ITEM_ID,
        errors
    )
    const cupColumnIndex = indexOfHeader(results, BUCKETSANDCUPSCSVHEADERS.CUP, errors)
    const bucketIndex = indexOfHeader(results, BUCKETSANDCUPSCSVHEADERS.BUCKET, errors)
    const bucketRankIndex = indexOfHeader(results, BUCKETSANDCUPSCSVHEADERS.BUCKET_RANK, errors)
    const cupRankIndex = indexOfHeader(results, BUCKETSANDCUPSCSVHEADERS.CUP_RANK, errors)
    const bucketMaxFrequencyIndex = indexOfHeader(
        results,
        BUCKETSANDCUPSCSVHEADERS.BUCKET_MAX_FREQUENCY,
        errors
    )

    if (errors.length > 0) {
        return [[], errors]
    }

    results.data.slice(1, results.data.length).forEach((row: string[], rowIndex: number) => {
        const item: string = row[itemVersionIdColumnIndex].trim()
        const cupName: string = row[cupColumnIndex].trim()
        const bucketName: string = row[bucketIndex].trim()
        const bucketRank: number = +row[bucketRankIndex].trim()
        const cupRank: number = +row[cupRankIndex].trim()
        const bucketMaxFrequency: number = +row[bucketMaxFrequencyIndex].trim()

        rowIndex += 1 // So the error messages match the row number TA see in their CSV files.

        let hasError = false

        if (cupName.length === 0) {
            errors.push(`Row ${rowIndex} does not contain a cup name.`)
            hasError = true
        }

        if (bucketName.length == 0) {
            errors.push(`Row ${rowIndex} does not contain a bucket name.`)
            hasError = true
        }

        if (bucketRank < 1) {
            errors.push(
                `Row ${rowIndex} has a bucket rank of '${+row[
                    bucketRankIndex
                ]}', when it should be a number greater than one.`
            )
            hasError = true
        }

        if (cupRank < 1) {
            errors.push(
                `Row ${rowIndex} has a cup rank of '${+row[
                    cupRankIndex
                ]}', when it should be a number greater than one.`
            )
            hasError = true
        }

        if (bucketMaxFrequency < 1) {
            errors.push(
                `Row ${rowIndex} has a bucket max frequency of '${+row[
                    bucketMaxFrequencyIndex
                ]}', when it should be a number greater than one.`
            )
            hasError = true
        }

        if (item.length < 1) {
            errors.push(`Row ${rowIndex} is missing an item id.`)
            hasError = true
        } else {
            if (importedItemIds.has(item)) {
                hasError = true
                importedItemIds.get(item)!.push(rowIndex)
            } else {
                importedItemIds.set(item, [rowIndex])
            }
        }

        if (hasError) {
            return
        }

        if (!importedBuckets[bucketName]) {
            importedBuckets[bucketName] = {
                bucketDTO: {
                    bucketId: bucketName,
                    rank: bucketRank,
                    cups: [],
                    maxFrequency: bucketMaxFrequency,
                },
                rowNumber: rowIndex,
            }
        } else {
            const previous = importedBuckets[bucketName]

            if (previous.bucketDTO.rank != bucketRank) {
                errors.push(
                    `On row ${rowIndex} the bucket named ${bucketName} has a rank of ${bucketRank}, but on row ${previous.rowNumber} it has a rank of ${previous.bucketDTO.rank}.`
                )
            }

            if (previous.bucketDTO.maxFrequency != bucketMaxFrequency) {
                errors.push(
                    `On row ${rowIndex} the bucket named ${bucketName} has a max frequency of ${bucketMaxFrequency}, but on row ${previous.rowNumber} it has a max frequency of ${previous.bucketDTO.maxFrequency}.`
                )
            }
        }

        if (!importedCups[cupName]) {
            importedCups[cupName] = {
                cupDTO: {
                    cupId: cupName,
                    itemIds: [],
                    maxFrequency: 1,
                    rank: cupRank,
                },
                rowNumber: rowIndex,
            }

            importedBuckets[bucketName].bucketDTO.cups.push(importedCups[cupName].cupDTO)
        } else {
            const previous = importedCups[cupName]

            if (previous.cupDTO.rank != cupRank) {
                errors.push(
                    `On row ${rowIndex} the cup named ${cupName} has a rank of ${cupRank}, but on row ${previous.rowNumber} it has a rank of ${previous.cupDTO.rank}.`
                )
            }
        }

        importedCups[cupName].cupDTO.itemIds.push(item)

        if (!bucketForCup[cupName]) {
            bucketForCup[cupName] = importedBuckets[bucketName]
        } else {
            const previousBucket = bucketForCup[cupName].bucketDTO.bucketId

            if (previousBucket !== bucketName) {
                errors.push(
                    `Each cup can only be in 1 bucket, but cup '${cupName}' is in bucket '${previousBucket}' on row '${bucketForCup[cupName].rowNumber}', and bucket ${bucketName} on row ${rowIndex}.`
                )
            }
        }
    })

    importedItemIds.forEach((value: number[], key: string) => {
        if (value.length > 1) {
            errors.push(
                `Each item ID only appear in one cup, but item ID '${key}' appears on the rows ${value.toString()}`
            )
        }
    })

    if (errors.length > MAX_NUMBER_OF_ERRORS_TO_DISPLAY) {
        errors = errors.splice(0, MAX_NUMBER_OF_ERRORS_TO_DISPLAY)
        errors.push(
            'There are more than 10 errors, but they have been trimmed. Please resolve these errors first.'
        )
    }

    return [Object.values(importedBuckets).map((b) => b.bucketDTO), errors]
}

export const BucketsAndCupsCSVImporter = ({
    entityId,
    moduleId,
    setNumberOfImports,
    setImportSuccess,
    editDisabled,
}: CSVImporterProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<string[]>([])
    const [file, setFile] = useState<File>()

    const {
        entity: { buckets },
    } = useBucketsAndCupsGroupEntity({
        workflowEntityId: entityId,
        moduleEntityId: moduleId,
    })

    return (
        <Card flexDirection={'column'} padding={'S300'}>
            {loading ? (
                <Spinner loadingText={'Loading'} />
            ) : (
                <Col gridGap='S300' dataTestId={'buckets-and-cups-csv-importer'}>
                    <ValidationErrorBanner errors={errors} />
                    <Label htmlFor='file-upload'>Select a buckets and cups CSV file</Label>
                    <FileUpload
                        dataTestId={'file-upload'}
                        accept='text/csv'
                        id='file-upload'
                        isMulti={false}
                        onFileAttached={(files) => {
                            setFile(files[0])
                        }}
                        disabled={editDisabled}
                    />
                    <ResponsiveRow justifyContent={'center'} gridGap='S300'>
                        <ItemPoolModal existingPool={false} />
                        <Button
                            dataTestId={'import-csv-button'}
                            aria-disabled={!file || editDisabled}
                            onClick={() => {
                                if (file) {
                                    setLoading(true)

                                    PapaParse.parse(file, {
                                        skipEmptyLines: true,
                                        complete: (results: ParseResult<string[]>) => {
                                            const [b, e] = parseCSV(results)
                                            if (e.length > 0) {
                                                setErrors(e)
                                                setLoading(false)
                                                setFile(undefined)
                                                setImportSuccess(false)
                                            } else {
                                                setNumberOfImports((prevState) => prevState + 1)
                                                BucketsAndCupsGroupHandler.setBuckets(entityId, b)
                                                setImportSuccess(true)
                                            }
                                        },
                                    })
                                }
                            }}
                        >
                            Import File
                        </Button>
                        <Button
                            aria-disabled={buckets.length == 0 || loading || editDisabled}
                            onClick={() => {
                                BucketsAndCupsGroupHandler.setUpdatingCSV(entityId, false)
                                setImportSuccess(false)
                            }}
                            dataTestId={'cancel-csv-update-button'}
                        >
                            Cancel
                        </Button>
                    </ResponsiveRow>
                </Col>
            )}
        </Card>
    )
}
