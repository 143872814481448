import React, { ReactNode, useEffect, useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import {
    IconAlertCircleFill,
    IconBin,
    IconChevronDown,
    IconChevronUp,
    IconPlus,
} from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { WithModal } from '@amzn/stencil-react-components/modal'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { DeleteItemModal } from 'src/components/ItemOptions'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useInstructionEntity, useInstructionItemEntity } from 'src/hooks/useEntity'
import { InstructionalContentEntity } from 'src/models/dto/InstructionalContentDTO'
import {
    FlyoutInstructionalContentItemDTO,
    FlyoutSectionTypeList,
} from 'src/models/dto/items/FlyoutInstructionalContentItemDTO'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    focusOnId,
    instructionalContentFocusId,
    moveDownFocusId,
    moveUpFocusId,
} from 'src/pages/module-builder/focus'
import {
    GenericItemEditorSelectInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { InstructionalContentEntityService } from 'src/services/EntityServices/InstructionalContentEntityService'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { FlyoutInstructionalContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/FlyoutInstructionalContentHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

interface InstructionItemProps {
    instructionItemNumber: number
    instructionId: string
    instructionalModuleId: string
    locale: Locale
    isLastInstruction?: boolean
    multipleInstructions?: boolean
    removeInstruction: (instructionId: string) => void
    showValidationError?: boolean
    editDisabled?: boolean
}

const customSectionNameLabel = ({
    htmlFor,
    children,
}: {
    htmlFor: string
    labelId?: string | undefined
    color: string
    children: ReactNode
}) => (
    <Label htmlFor={htmlFor} fontSize={'T100'} color={'neutral70'}>
        <View maxHeight={'21px'} alignItems={'center'}>
            {children}
        </View>
    </Label>
)

// the replace finds capital letters and places a space before them, so they look better when we display the options
const flyoutOptions: string[] = Array.from(FlyoutSectionTypeList).map((rec) => {
    return rec.replace(/[A-Z]/g, ' $&').trim()
})

const flyoutDisplayNamesToValues: Map<string, string> = new Map<string, string>(
    Array.from(FlyoutSectionTypeList).map((rec) => {
        return [rec.replace(/[A-Z]/g, ' $&').trim(), rec] as [string, string]
    })
)

const flyoutValuesToName: Map<string, string> = new Map<string, string>(
    Array.from(FlyoutSectionTypeList).map((rec) => {
        return [rec, rec.replace(/[A-Z]/g, ' $&').trim()] as [string, string]
    })
)

const InstructionItem = ({
    instructionItemNumber = 0,
    instructionId,
    isLastInstruction = false,
    instructionalModuleId,
    removeInstruction,
    multipleInstructions,
    locale,
    showValidationError,
    editDisabled,
}: InstructionItemProps) => {
    const { entity: instructionDTO } = useInstructionItemEntity<FlyoutInstructionalContentItemDTO>({
        entityId: instructionId,
        instructionEntityId: instructionalModuleId,
    })

    if (!ValidationErrorEntityService.has(instructionId)) {
        ValidationErrorEntityService.create(instructionId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: instructionId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const invalid = ErrorCheckService.getErrorCount(validationErrorEntity.id) > 0

    const deleteButton = () => {
        const renderModal = ({ close }: { close: () => void }) => (
            <DeleteItemModal
                onClose={close}
                onDone={() => {
                    removeInstruction(instructionId)
                }}
            />
        )

        return (
            <WithModal renderModal={renderModal} isScrollable={false}>
                {({ open }) => (
                    <Button
                        id={instructionalContentFocusId(instructionItemNumber)}
                        dataTestId={`delete-instruction-${instructionItemNumber}`}
                        variant={ButtonVariant.Secondary}
                        isDestructive={true}
                        aria-label={`Delete instruction ${instructionItemNumber}.`}
                        icon={<IconBin aria-hidden={true} />}
                        onClick={open}
                        aria-disabled={editDisabled || !multipleInstructions}
                    />
                )}
            </WithModal>
        )
    }

    return (
        <>
            <Spacer height='S400' />
            <Hr size='wide' />
            <Spacer height='S400' />
            <Row alignItems={'center'} gridGap={'S300'}>
                <Text
                    fontSize='T300'
                    fontWeight='bold'
                    color={invalid && showValidationError ? 'red70' : 'neutral90'}
                >
                    Instruction Item {multipleInstructions ? instructionItemNumber + 1 : ''}
                </Text>
                <Spacer flex={1} />
                {invalid && showValidationError && (
                    <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                        <IconAlertCircleFill aria-hidden={true} />
                        {ErrorCheckService.getErrorCount(validationErrorEntity.id)} issues
                    </Row>
                )}
                <Button
                    id={moveUpFocusId(instructionalContentFocusId(instructionItemNumber))}
                    dataTestId={`move-instruction-${instructionItemNumber}-up`}
                    variant={ButtonVariant.Secondary}
                    aria-label={`Move instruction ${instructionItemNumber} up.`}
                    icon={<IconChevronUp aria-hidden={true} />}
                    aria-disabled={instructionItemNumber === 0 || editDisabled}
                    onClick={() => {
                        InstructionalContentEntityService.moveUp(
                            instructionalModuleId,
                            instructionId
                        )
                        const prevFocusId = instructionalContentFocusId(instructionItemNumber - 1)
                        focusOnId(moveUpFocusId(prevFocusId), prevFocusId)
                    }}
                />
                <Button
                    id={moveDownFocusId(instructionalContentFocusId(instructionItemNumber))}
                    dataTestId={`move-instruction-${instructionItemNumber}-down`}
                    variant={ButtonVariant.Secondary}
                    aria-label={`Move instruction ${instructionItemNumber} down.`}
                    icon={<IconChevronDown aria-hidden={true} />}
                    aria-disabled={isLastInstruction || editDisabled}
                    onClick={() => {
                        InstructionalContentEntityService.moveDown(
                            instructionalModuleId,
                            instructionId
                        )
                        const nextFocusId = instructionalContentFocusId(instructionItemNumber + 1)
                        focusOnId(moveDownFocusId(nextFocusId), nextFocusId)
                    }}
                />
                {deleteButton()}
            </Row>
            <Spacer height='S400' />
            <ResponsiveRow gridGap='S300'>
                <Col flex='1'>
                    <Spacer height='S100' />
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        value={instructionDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={instructionDTO.id}
                        setValue={(nextValue: string) => {
                            FlyoutInstructionalContentHandler.updateLabel(
                                instructionDTO.id,
                                nextValue
                            )
                        }}
                        labelText={'Unique Item ID'}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex='1'>
                    <Spacer height='S100' />
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={flyoutValuesToName}
                        inputId={'instruction-section-type'}
                        itemId={instructionDTO.id}
                        labelText={'Section Type'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={flyoutOptions}
                        displayNamesToValues={flyoutDisplayNamesToValues}
                        value={instructionDTO.sectionType}
                        setValue={(nextValue) => {
                            FlyoutInstructionalContentHandler.updateSectionType(
                                instructionDTO.id,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.sectionType ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex='1'>
                    <InputWrapper
                        labelText='Section name (optional)'
                        id={`instruction-${instructionItemNumber}-section-name`}
                        tooltipText='If you add this section name, it will be required to do this for all the locales'
                        renderLabel={customSectionNameLabel}
                    >
                        {(props) => (
                            <Input
                                dataTestId={`instruction-${instructionItemNumber}-section-name-input`}
                                placeholder='Descriptive section name'
                                value={instructionDTO.sectionNameI18N[locale] ?? ''}
                                onChange={(e) =>
                                    FlyoutInstructionalContentHandler.updateSectionName(
                                        instructionId,
                                        locale,
                                        e?.target?.value ?? ''
                                    )
                                }
                                disabled={editDisabled}
                                {...props}
                            />
                        )}
                    </InputWrapper>
                </Col>
            </ResponsiveRow>
            <Spacer height='S400' />
            <ItemEditorTextArea
                key={instructionDTO.id}
                inputId={'instruction-content'}
                value={instructionDTO.textI18N[locale] ?? ''}
                locale={locale}
                disabled={editDisabled}
                itemId={instructionId}
                setValue={(nextValue: string) => {
                    FlyoutInstructionalContentHandler.updateText(instructionId, locale, nextValue)
                }}
                labelText={'Instruction content'}
                validationErrorMessage={(
                    validationErrorEntity.validationErrors.textI18N ?? []
                ).join(', ')}
                showError={showValidationError}
            />
        </>
    )
}

export const FlyoutInstructions = ({
    instructionalContentId,
    showValidationError,
    moduleId,
    locale,
    editDisabled,
}: {
    instructionalContentId: string
    moduleId: string
    showValidationError?: boolean
    locale: Locale
    editDisabled?: boolean
}) => {
    const { entity: instructionalContentEntity } = useInstructionEntity<InstructionalContentEntity>(
        {
            instructionEntityId: instructionalContentId,
            moduleEntityId: moduleId,
        }
    )

    if (!ValidationErrorEntityService.has(instructionalContentId)) {
        ValidationErrorEntityService.create(instructionalContentId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: instructionalContentId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Flyout Instructions`
    const [instructionsMap, setInstructionsMap] = useState<string[]>([])
    const invalid =
        ErrorCheckService.getTotalInstructionalContentError(validationErrorEntity.id) > 0

    const removeInstructionItem = (instructionId: string) => {
        setInstructionsMap(instructionsMap.filter((id) => id !== instructionId))
        InstructionalContentEntityService.removeItem(instructionalContentId, instructionId)
        ItemEntityService.remove(instructionId)
    }

    useEffect(() => {
        if (instructionalContentEntity.itemIds.length > 0) {
            setInstructionsMap([...instructionalContentEntity.itemIds])
        } else {
            const item = ItemEntityService.create(ItemType.FlyoutInstructionalContentItem)
            InstructionalContentEntityService.addItem(instructionalContentId, item.id)
            setInstructionsMap([item.id])
        }
    }, [instructionalContentId, instructionalContentEntity.itemIds])

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Col
                gridGap='S300'
                padding={{ top: 'S300', left: 'S400', right: 'S400', bottom: 'S300' }}
                backgroundColor='#FFFFFF'
            >
                <Row alignItems='center' gridGap='S200'>
                    <Col rowGap={0}>
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                dataTestId={`${
                                    isExpanded ? 'collapse' : 'expand'
                                }-flyout-instructions-button`}
                                iconAltText={triggerButtonName}
                                aria-label={triggerButtonName}
                                isExpanded={isExpanded}
                                onClick={() => setIsExpanded(!isExpanded)}
                                role={'button'}
                            />
                            <Text
                                fontSize='T400'
                                fontWeight='bold'
                                color={invalid && showValidationError ? 'red70' : 'neutral90'}
                            >
                                Customize Flyout Instruction
                            </Text>
                        </Row>
                    </Col>
                    {invalid && showValidationError && (
                        <>
                            <Spacer flex={1} />
                            <Col padding={{ right: 'S200' }}>
                                <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                                    <IconAlertCircleFill />
                                    {ErrorCheckService.getTotalInstructionalContentError(
                                        validationErrorEntity.id
                                    )}{' '}
                                    issues
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
                <ExpanderContent isExpanded={isExpanded}>
                    {instructionsMap.map((instructionId: string, instructionIndex: number) => {
                        return (
                            <InstructionItem
                                instructionItemNumber={instructionIndex}
                                key={instructionIndex}
                                instructionId={instructionId}
                                instructionalModuleId={instructionalContentId}
                                isLastInstruction={instructionsMap.length === instructionIndex + 1}
                                removeInstruction={removeInstructionItem}
                                multipleInstructions={instructionsMap.length > 1}
                                locale={locale}
                                showValidationError={showValidationError}
                                editDisabled={editDisabled}
                            />
                        )
                    })}
                    <Spacer height={'S200'} />
                    <Row alignSelf={'flex-end'}>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {
                                const item = ItemEntityService.create(
                                    ItemType.FlyoutInstructionalContentItem
                                )
                                InstructionalContentEntityService.addItem(
                                    instructionalContentId,
                                    item.id
                                )
                            }}
                            aria-disabled={editDisabled}
                        >
                            <IconPlus aria-hidden={true} />
                            Add new instruction content
                        </Button>
                    </Row>
                </ExpanderContent>
            </Col>
            <Spacer height='S400' />
        </>
    )
}
