import React from 'react'

import { IconQuestionCircle } from '@amzn/stencil-react-components/icons'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

const IconWithTooltipInner = withTooltip()(IconQuestionCircle)

export function IconWithTooltip({
    title = 'Open tooltip',
    ...props
}: {
    tooltipText: string
    title?: string
    color?: string
    isInline?: boolean
}) {
    return <IconWithTooltipInner title={title} {...props} />
}
