import React, { useContext } from 'react'

import {
    MediaContentsEditor,
    MediaContentsEditorProps,
} from 'src/components/MediaContentsEditor/MediaContentsEditor'
import { MediaManagerV2 } from 'src/components/MediaContentsEditor/MediaManagerV2'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'

export const MediaManager = (props: MediaContentsEditorProps) => {
    const { moduleUsingMediaManager: usingMediaManagerV2 } = useContext(ModuleBuilderContext)
    if (usingMediaManagerV2) {
        return <MediaManagerV2 {...props} />
    } else {
        return <MediaContentsEditor {...props} />
    }
}
