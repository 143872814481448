import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import {
    defaultWritingExerciseItemDTO,
    WritingExerciseItemDTO,
} from 'src/models/dto/items/WritingExerciseItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.WritingExercise

export class WritingExerciseHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => WritingExerciseHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): WritingExerciseItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as WritingExerciseItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): WritingExerciseItemDTO {
        return defaultWritingExerciseItemDTO()
    }

    private static update(entity: WritingExerciseItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as WritingExerciseItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as WritingExerciseItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        const entity = this.getEntity(id)
        entity.preserveOrder = nextValue
        this.update(entity)
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.titleI18N[locale] = nextValue
        this.update(entity)
    }

    static updateInstruction(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.instructionI18N[locale] = nextValue
        this.update(entity)
    }

    static updateMinWordsAllowed(id: string, minCharacterLimit: number | null) {
        this.update({
            ...this.getEntity(id),
            minWordLimit: minCharacterLimit,
        } as WritingExerciseItemDTO)
    }

    static updateWordLimit(id: string, characterLimit: number | null) {
        this.update({
            ...this.getEntity(id),
            wordLimit: characterLimit ?? 0,
        } as WritingExerciseItemDTO)
    }
}
