import AWS from 'aws-sdk'

import { QA_AUTOMATION_STS_ASSUME_ROLE } from 'src/config.app'

export const setCrossAccountCredentials = async () => {
    const sts = new AWS.STS()

    const timestamp = new Date().getTime()
    const params = {
        RoleArn: QA_AUTOMATION_STS_ASSUME_ROLE,
        RoleSessionName: `pulling-test-results-${timestamp}`,
    }

    return await sts
        .assumeRole(params)
        .promise()
        .then((data) => {
            return {
                accessKeyId: data.Credentials?.AccessKeyId,
                secretAccessKey: data.Credentials?.SecretAccessKey,
                sessionToken: data.Credentials?.SessionToken,
            }
        })
}
