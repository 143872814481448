import {
    AudioCheckItemDTO,
    defaultAudioCheckDTO,
    SoundCheckMLModelType,
} from 'src/models/dto/items/AudioCheckItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.AudioCheck

export class AudioCheckHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => AudioCheckHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): AudioCheckItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as AudioCheckItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): AudioCheckItemDTO {
        return defaultAudioCheckDTO()
    }

    private static update(entity: AudioCheckItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as AudioCheckItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as AudioCheckItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as AudioCheckItemDTO)
    }

    static updateMaxNumberOfAttempts(id: string, nextValue: number) {
        this.update({
            ...this.getEntity(id),
            maxNumberOfAttempts: nextValue,
        } as AudioCheckItemDTO)
    }

    static updateMaxRecordingTimeInSeconds(id: string, nextValue: number) {
        this.update({
            ...this.getEntity(id),
            maxRecordingTimeInSeconds: nextValue,
        } as AudioCheckItemDTO)
    }

    static updateSoundCheckMlModel(id: string, nextValue: SoundCheckMLModelType) {
        this.update({
            ...this.getEntity(id),
            soundCheckMLModel: nextValue,
        } as AudioCheckItemDTO)
    }

    static updateSoundCheckMlModelVersion(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            soundCheckMLModelVersion: nextValue,
        } as AudioCheckItemDTO)
    }

    static updatePrompt(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.promptI18N[locale] = nextValue
        this.update(entity)
    }

    static updateSentence(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.sentenceI18N[locale] = nextValue
        this.update(entity)
    }
}
