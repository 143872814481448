import React from 'react'

export interface ModuleBuilderContextProps {
    moduleVersionId: string
    moduleUsingMediaManager: boolean
    lockScoringEditing: boolean
}

export const ModuleBuilderContext = React.createContext<ModuleBuilderContextProps>({
    moduleVersionId: '',
    moduleUsingMediaManager: false,
    lockScoringEditing: false,
})
