import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconDownload } from '@amzn/stencil-react-components/icons'
import { Col, Container, Hr, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { ScoringTestingBreadCrumbs } from 'src/components/scoring-testing-v2/ScoringTestingBreadCrumbs'
import { STAGE } from 'src/config.app'
import { HookAuthenticator } from 'src/pages/scoring-test-ui/authentication/HookAuthenticator'
import { STORAGE_STAGE_NAME } from 'src/pages/scoring-testing-v2/ScoringTestingV2Landing'
import { downloadFileFromS3 } from 'src/services/hook/HookFileService'
import { XlsxFileParser } from 'src/services/parser/XlsxFileParser'

export const ScoringTestingV2OutputPage = () => {
    HookAuthenticator.initCognitoAuth(sessionStorage.getItem(STORAGE_STAGE_NAME) as STAGE)
    HookAuthenticator.authenticate(() => {})
    const OUTPUT_FILE_FOLDER = 'output/'
    const { workflowId, outputFileName } = useParams()
    const navigate = useNavigate()

    const [outputTestingFile, setOutputTestingFile] = useState<ArrayBufferView | undefined>(
        undefined
    )
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        const getOutputFile = async () => {
            if (outputFileName) {
                setIsLoadingFile(true)
                try {
                    const response = await downloadFileFromS3(
                        `${OUTPUT_FILE_FOLDER}${outputFileName}.xlsx`,
                        sessionStorage.getItem(STORAGE_STAGE_NAME) as STAGE
                    )

                    if (!response.Body) {
                        setErrorMessage('The output file is empty')
                        setIsLoadingFile(false)
                        return
                    }
                    setOutputTestingFile(response.Body as ArrayBufferView)
                } catch (e) {
                    setErrorMessage('Unable to download the output file from Hook')
                }
                setIsLoadingFile(false)
            }
        }
        void getOutputFile()
    }, [outputFileName])

    const backToLandingPage = () => {
        navigate('/scoring-test-v2')
    }

    const handleDownload = () => {
        if (outputTestingFile && outputFileName) {
            setIsDownloading(true)
            // Backhanded method to download file to avoid paying for the sheetjs premium tier for styling
            const outputFile = new File([outputTestingFile], 'test', {
                type: XlsxFileParser.XLSX_FILE_TYPE,
            })
            const objectURL = URL.createObjectURL(outputFile)
            const tempLink = document.createElement('a')
            tempLink.href = objectURL
            tempLink.setAttribute('download', `${outputFileName}.xlsx`)
            tempLink.click()
            URL.revokeObjectURL(objectURL)
            setIsDownloading(false)
        }
    }

    if (!workflowId || !outputFileName) {
        return (
            <MessageBanner type={MessageBannerType.Error}>
                There was a problem loading your test results, please try again...
            </MessageBanner>
        )
    }

    if (isLoadingFile) {
        return (
            <Col justifyContent='center' width='100%' margin={{ top: 'S400' }}>
                <Spinner size={SpinnerSize.Large} dataTestId='loading-file-spinner' />
                <Text fontSize='T400' textAlign='center'>
                    Loading your test results...
                </Text>
            </Col>
        )
    }

    return (
        <Container>
            <Col gridGap='S400'>
                <Col gridGap='S200'>
                    <ScoringTestingBreadCrumbs
                        scoringTestingResult={outputFileName}
                        workflowId={workflowId}
                    />
                    <Row justifyContent='space-between'>
                        <H2>{`${workflowId} Test Results`}</H2>
                        <Row height='50%' gridGap='S200'>
                            <Button onClick={backToLandingPage}>Back</Button>
                            <Button
                                icon={isDownloading ? <Spinner /> : <IconDownload />}
                                onClick={handleDownload}
                                variant={ButtonVariant.Primary}
                            >
                                Download
                            </Button>
                        </Row>
                    </Row>
                    <Text fontSize='T300'>Download your results</Text>
                    <Hr size='wide' />
                </Col>
                {errorMessage && (
                    <MessageBanner type={MessageBannerType.Error}>
                        {`An unexpected error happened: ${errorMessage}`}
                    </MessageBanner>
                )}
            </Col>
        </Container>
    )
}
