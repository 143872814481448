import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconGlobe } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Row, View } from '@amzn/stencil-react-components/layout'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { SetStateCallback } from 'src/hooks/DTOEditor'
import { useEntity } from 'src/hooks/useEntity'
import { Locale, LocaleDescription } from 'src/models/dto/Locale'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

export function LocaleSelectorContainer({ children }: { children?: React.ReactNode }) {
    const { theme } = useContext(StencilContext)

    const {
        matches: { s: small },
    } = useBreakpoints()

    const containerStyle = useMemo(
        () => ({ borderBottom: '1px solid ' + theme.color.neutral30 }),
        [theme]
    )

    return (
        <Container
            paddingTop='S300'
            paddingBottom='S300'
            style={containerStyle}
            paddingHorizontal='S400'
        >
            <Flex
                flexDirection={small ? 'column' : 'row'}
                justifyContent='space-between'
                alignItems={small ? undefined : 'center'}
                gridGap='S200'
            >
                {children}
            </Flex>
        </Container>
    )
}

function RenderManageLocalesFlyout({ close }: { close: () => void }, moduleEntity: ModuleEntity) {
    const renderOption = (curLocale: string) => (
        <Row gridGap='S200' key={curLocale} dataTestId={curLocale} alignItems='center'>
            {curLocale}
        </Row>
    )

    const [locales, setLocales] = useState<Locale[]>(
        moduleEntity.availableLocales.filter((l) => l !== Locale.en_US)
    )

    useEffect(() => {
        setLocales(moduleEntity.availableLocales.filter((l) => l !== Locale.en_US))
    }, [moduleEntity.availableLocales])

    return (
        <FlyoutContent
            titleText='Manage Locales'
            closeButtonAltText={'Close'}
            onCloseButtonClick={() => {
                setLocales(moduleEntity.availableLocales.filter((l) => l !== Locale.en_US))
                close()
            }}
            buttons={[
                <Button
                    key={'close'}
                    onClick={() => {
                        setLocales(moduleEntity.availableLocales.filter((l) => l !== Locale.en_US))
                        close()
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    key={'update'}
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                        ModuleEntityService.setAvailableLocales(moduleEntity.version, locales)
                        close()
                    }}
                >
                    Update Locales
                </Button>,
            ]}
        >
            <Col gridGap={'S300'}>
                <Text>Add the locales for this module.</Text>

                <InputWrapper id={'locales-drop-down'} labelText={'Manage locales'}>
                    {(inputProps) => (
                        <Select
                            dataTestId={'locales-drop-down'}
                            {...inputProps}
                            renderOption={renderOption}
                            value={locales}
                            isMulti
                            options={Object.values(Locale).filter((l) => l !== Locale.en_US)}
                            onChange={(value) => setLocales([...value])}
                        />
                    )}
                </InputWrapper>

                <Row flex={1}>
                    <Button
                        size={ButtonSize.Small}
                        variant={ButtonVariant.Tertiary}
                        dataTestId={'addLocales'}
                        onClick={() =>
                            setLocales(Object.values(Locale).filter((l) => l !== Locale.en_US))
                        }
                    >
                        Add all Locales
                    </Button>
                </Row>
            </Col>
        </FlyoutContent>
    )
}

export interface LocaleDropdownProps {
    id: string
    options: Locale[]
    locale: Locale
    setLocale: React.Dispatch<React.SetStateAction<Locale>>
    flexDirection?: 'column' | 'row'
    flex?: string
}

export function LocaleDropdown({
    id,
    options,
    locale,
    setLocale,
    flexDirection,
}: LocaleDropdownProps) {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const renderOption = (option: Locale) => (
        <Row gridGap='S200' key={option} alignItems='center'>
            {LocaleDescription[option]} - {option}
        </Row>
    )

    useEffect(() => {
        if (options.indexOf(locale) === -1) {
            setLocale(Locale.en_US)
        }
    }, [options, locale, setLocale])

    const direction = flexDirection ?? (small ? 'column' : 'row')
    return (
        <Flex
            flexDirection={direction}
            alignItems={direction === 'column' ? 'left' : 'center'}
            gridGap='S200'
            flex={1}
        >
            <View>
                <Label htmlFor={id} id={`${id}-label`}>
                    Viewing locale
                </Label>
            </View>
            <View minWidth={160}>
                <Select
                    id={id}
                    labelId={`${id}-label`}
                    options={options}
                    renderOption={renderOption}
                    value={options.indexOf(locale) !== -1 ? locale : Locale.en_US}
                    onChange={setLocale}
                />
            </View>
        </Flex>
    )
}

export interface LocaleSelectorProps {
    locale: Locale
    setLocale: SetStateCallback<Locale>
    moduleId: string
    manageLocales?: boolean
    editDisabled?: boolean
}

export function LocaleSelector({
    moduleId,
    locale,
    setLocale,
    manageLocales = true,
    editDisabled,
}: LocaleSelectorProps) {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    return (
        <LocaleSelectorContainer>
            <LocaleDropdown
                id='viewing-locale-select'
                options={moduleEntity.availableLocales}
                locale={locale}
                setLocale={setLocale}
            />
            {manageLocales && (
                <Row justifyContent='center' width={small ? '100%' : undefined}>
                    <WithFlyout
                        renderFlyout={(close) => RenderManageLocalesFlyout(close, moduleEntity)}
                    >
                        {({ open }) => (
                            <>
                                <Button
                                    variant={ButtonVariant.Tertiary}
                                    icon={<IconGlobe title='' aria-hidden />}
                                    onClick={open}
                                    style={{ width: '100%' }}
                                    aria-disabled={editDisabled}
                                >
                                    Manage locales
                                </Button>
                            </>
                        )}
                    </WithFlyout>
                </Row>
            )}
        </LocaleSelectorContainer>
    )
}
