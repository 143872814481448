import React, { useState } from 'react'
import _ from 'lodash'

import { Col, Spacer } from '@amzn/stencil-react-components/layout'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table'

import { BucketDTO } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'

const toCommaSeparatedListOfCups = (data: BucketDTO) =>
    data.cups
        .map((c) => c.cupId)
        .sort()
        .join(', ')

function sortData(
    activeSortId: string,
    sortDirection: SortDirection,
    buckets: BucketDTO[]
): BucketDTO[] {
    const direction = sortDirection === SortDirection.Ascending ? 'asc' : 'desc'

    switch (activeSortId) {
        case 'bucketId':
            return _.orderBy(
                buckets,
                ['bucketId', 'rank', 'maxFrequency', toCommaSeparatedListOfCups],
                [direction, direction]
            )
        case 'rank':
            return _.orderBy(
                buckets,
                ['rank', 'bucketId', 'maxFrequency', toCommaSeparatedListOfCups],
                [direction, direction]
            )
        case 'maxFrequency':
            return _.orderBy(
                buckets,
                ['maxFrequency', 'bucketId', 'rank', toCommaSeparatedListOfCups],
                [direction, direction]
            )
        case 'cups':
            return _.orderBy(
                buckets,
                [toCommaSeparatedListOfCups, 'bucketId', 'rank', 'maxFrequency'],
                [direction, direction]
            )
    }

    throw new Error(`Unsupported active sort ID - ${activeSortId}`)
}

export interface BucketsTableProps {
    buckets: BucketDTO[]
    numberOfImports: number
}

export const BucketsTable = React.memo(
    (props: BucketsTableProps) => {
        const [selectedPage, setSelectedPage] = useState(1)
        const { activeSortId, sortDirection, onSort } = useSort({
            defaultSortDirection: SortDirection.Ascending,
            defaultActiveSortId: 'bucketId',
        })

        const pageSize = 10

        const sortedData = sortData(activeSortId ?? 'bucketId', sortDirection, props.buckets)

        const columns: TableColumn<BucketDTO>[] = [
            { header: 'Bucket', accessor: 'bucketId', sortId: 'bucketId', width: '34%' },
            { header: 'Rank', accessor: 'rank', sortId: 'rank', width: '14%' },
            {
                header: 'Max Frequency',
                accessor: 'maxFrequency',
                sortId: 'maxFrequency',
                width: '14%',
            },
            {
                header: 'Cups',
                accessor: ({ data }) => toCommaSeparatedListOfCups(data),
                sortId: 'cups',
                width: '34%',
            },
        ]

        const numberOfPages = Math.ceil(sortedData.length / pageSize)

        const page = sortedData.slice(
            (selectedPage - 1) * pageSize,
            (selectedPage - 1) * pageSize + pageSize
        )

        return (
            <Col gridGap='S200'>
                <Table
                    dataTestId='buckets-table'
                    activeSortId={activeSortId}
                    sortDirection={sortDirection}
                    onSort={onSort}
                    columns={columns}
                    data={page}
                />
                <Spacer height={'S200'} />
                <Pagination
                    dataTestId={'buckets-table-pagination'}
                    numberOfPages={numberOfPages}
                    onPageSelect={(pageIndex) => {
                        setSelectedPage(pageIndex)
                    }}
                    selectedPage={selectedPage}
                />
            </Col>
        )
    },
    (previous, next) => {
        return previous.numberOfImports === next.numberOfImports
    }
)

BucketsTable.displayName = 'EndOfModuleCard'
