import React, { useState } from 'react'

import { Col } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useBucketsAndCupsGroupEntity } from 'src/hooks/useBucketsAndCupsGroup'
import { useEntity } from 'src/hooks/useEntity'
import { Locale } from 'src/models/dto/Locale'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { BucketsAndCupsCSVImporter } from 'src/pages/module-builder/buckets-and-cups-editor/BucketsAndCupsCSVImporter'
import { BucketsTable } from 'src/pages/module-builder/buckets-and-cups-editor/BucketsTable'
import { CupsTable } from 'src/pages/module-builder/buckets-and-cups-editor/CupsTable'
import { ItemPoolVersionIdField } from 'src/pages/module-builder/buckets-and-cups-editor/ItemPoolVersionIdField'
import { SelectionStepEditor } from 'src/pages/module-builder/buckets-and-cups-editor/SelectionStepEditor'
import { ItemEditorCheckboxInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { BucketsAndCupsGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/BucketsAndCupsGroupHandler'
import { MODULE_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ModuleEntityService'
import { VALIDATION_ERROR_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ValidationErrorEntityService'

interface EditorProps {
    moduleEntityId: string
    workflowEntityId: string
    showValidationErrors: boolean
    locale: Locale
    editDisabled?: boolean
}

export const BucketsAndCupsGroupEditor = ({
    moduleEntityId,
    workflowEntityId,
    showValidationErrors,
    locale,
    editDisabled,
}: EditorProps) => {
    const {
        entity: {
            poolVersionId,
            buckets,
            generateScoringTemplate,
            timingEnabled,
            updatingCsvFile,
            bucketAndCupSelectionStep,
        },
    } = useBucketsAndCupsGroupEntity({
        workflowEntityId: workflowEntityId,
        moduleEntityId: moduleEntityId,
    })

    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleEntityId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })
    const moduleEvaluationScoreEnabled = !!moduleEntity.compositeScores?.length

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [numberOfImports, setNumberOfImports] = useState(0)
    const [importSuccess, setImportSuccess] = useState(false)

    const tabs = ['Editor', 'View Imported Cups', 'View Imported Buckets']

    const { tabBarProps, tabSwitcherProps } = useTabs({
        tabs,
        defaultTab: 'Editor',
        onTabSelect: () => {},
    })

    const {
        matches: { s: isSmall },
    } = useBreakpoints()

    if (updatingCsvFile || buckets.length === 0) {
        return (
            <BucketsAndCupsCSVImporter
                moduleId={moduleEntityId}
                entityId={workflowEntityId}
                setNumberOfImports={setNumberOfImports}
                setImportSuccess={setImportSuccess}
                editDisabled={editDisabled}
            />
        )
    }

    const itemPoolVersionIdErrors = showValidationErrors
        ? validationErrorEntity.validationErrors.poolVersionId?.join(', ')
        : undefined

    const onItemPoolVersionIdChange = (value: string) => {
        BucketsAndCupsGroupHandler.setItemPoolVersionId(workflowEntityId, value)
    }
    const autoDismissTime = 3000
    return (
        <Col gridGap='S500' padding='S300' backgroundColor='white'>
            <div aria-live='polite'>
                {importSuccess && (
                    <MessageBanner
                        type={MessageBannerType.Success}
                        isDismissible={true}
                        autoDismissAfter={autoDismissTime}
                    >
                        CSV is imported successfully
                    </MessageBanner>
                )}
            </div>
            <TabBar {...tabBarProps} />
            <TabSwitcher {...tabSwitcherProps}>
                <TabPanel value='Editor'>
                    <Col gridGap='S200' padding='S300'>
                        <ResponsiveRow padding={{ bottom: 'S300' }}>
                            <ItemPoolVersionIdField
                                footer={itemPoolVersionIdErrors}
                                itemPoolVersionId={poolVersionId}
                                editDisabled={editDisabled}
                                onItemPoolVersionIdChange={onItemPoolVersionIdChange}
                            />
                            <Col
                                flex={'0 0 auto'}
                                justifyContent={'center'}
                                alignItems={isSmall ? 'flex-start' : 'flex-end'}
                                gridGap={'S200'}
                                padding={{ top: '32px', left: `${isSmall ? '0' : 'S300'}` }}
                            >
                                <ItemEditorCheckboxInput
                                    itemId={workflowEntityId}
                                    inputId={`${workflowEntityId}-generate-scoring-template`}
                                    labelText={'Generate Scoring Template'}
                                    dataTestId={'enable-scoring-checkbox'}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    value={generateScoringTemplate}
                                    setValue={() =>
                                        BucketsAndCupsGroupHandler.toggleGenerateScoringTemplate(
                                            workflowEntityId
                                        )
                                    }
                                />
                                <ItemEditorCheckboxInput
                                    itemId={workflowEntityId}
                                    inputId={`${workflowEntityId}-timing-enabled`}
                                    labelText={'Enable Timing'}
                                    dataTestId={'timing-enabled-checkbox'}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    value={timingEnabled}
                                    setValue={(value) =>
                                        BucketsAndCupsGroupHandler.setTimingEnabled(
                                            workflowEntityId,
                                            value
                                        )
                                    }
                                />
                            </Col>
                        </ResponsiveRow>
                        <SelectionStepEditor
                            entityId={workflowEntityId}
                            timingEnabled={timingEnabled}
                            scoringEnabled={moduleEvaluationScoreEnabled}
                            selectionSteps={bucketAndCupSelectionStep}
                            editDisabled={editDisabled}
                        />
                    </Col>
                </TabPanel>
                <TabPanel value='View Imported Cups'>
                    <CupsTable buckets={buckets} numberOfImports={numberOfImports} />
                </TabPanel>
                <TabPanel value='View Imported Buckets'>
                    <BucketsTable buckets={buckets} numberOfImports={numberOfImports} />
                </TabPanel>
            </TabSwitcher>
        </Col>
    )
}
