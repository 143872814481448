import {
    BaseTableDTO,
    SymbolLegend,
    SymbolLegendDefinition,
    TableSymbolType,
} from 'src/models/dto/items/BaseTableDTO'
import { Locale } from 'src/models/dto/Locale'
import { SYMBOL_MAPPING } from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'

export function getSymbolLegends({
    itemId,
    baseTableDTO,
    tableOperations,
    locale,
}: {
    itemId: string
    baseTableDTO: BaseTableDTO
    tableOperations: TableOperations<BaseTableDTO>
    locale: Locale
}): SymbolLegend {
    return {
        setEnabled: (enabled: boolean) => tableOperations.toggleSymbolLegend(itemId, enabled),
        legendHumanReadableName: 'Symbol',
        legendDataTestId: 'symbol-legend-checkbox',
        legendDefinitions: baseTableDTO.legends
            .map((legend, originalIndex) => {
                return { originalIndex, legend }
            })
            .filter((wrappedLegend) => {
                return (
                    !wrappedLegend.legend.iconType ||
                    wrappedLegend.legend.iconType in TableSymbolType
                )
            })
            .map((wrappedLegend) => {
                return {
                    symbolType: (wrappedLegend.legend.iconType as TableSymbolType) || undefined,
                    symbolLegendText: wrappedLegend.legend.textI18N[locale],
                    index: wrappedLegend.originalIndex,
                }
            }),
        onDefTextChange: (symbolLegendText: string, symbolLegendIndex: number) => {
            tableOperations.setSymbolLegend(itemId, locale, symbolLegendText, symbolLegendIndex)
        },
        onDefSymbolTypeChange: (symbolType: TableSymbolType, symbolLegendIndex: number) => {
            tableOperations.setSymbolType(itemId, symbolType, locale, symbolLegendIndex)
        },
        enabled: baseTableDTO.symbolLegendEnabled,
        onLegendDelete: (index: number) => tableOperations.deleteSymbolLegend(itemId, index),
        createEmptySymbolLegend: () => tableOperations.createNewSymbolEmptyLegend(itemId),
        numOfSymbols: SYMBOL_MAPPING.size,
    }
}

export function getAvailableSymbols(legendDefs: SymbolLegendDefinition[]): TableSymbolType[] {
    return Object.keys(TableSymbolType)
        .filter((symbol: string) => {
            return !legendDefs
                .map((def) => {
                    return def.symbolType
                })
                .includes(TableSymbolType[symbol] as TableSymbolType)
        })
        .map((symbol: string) => {
            return TableSymbolType[symbol] as TableSymbolType
        })
}

export function replaceCellSymbol(
    currentData: string,
    index: number,
    newSymbol: TableSymbolType
): string {
    const currentSymbols: string[] = currentData.split(',')
    currentSymbols.splice(index, 1, newSymbol)
    return currentSymbols.join(',')
}

export function getCellDataAfterDelete(currentData: string, index: number): string {
    const currentSymbols: string[] = currentData.split(',')
    currentSymbols.splice(index, 1)
    return currentSymbols.join(',')
}

export function getCellDataAfterAddition(currentData: string, value: TableSymbolType): string {
    if (!currentData || currentData.length === 0) {
        return value
    }
    const currentSymbols: string[] = currentData.split(',')
    currentSymbols.push(value)
    return currentSymbols.join(',')
}
