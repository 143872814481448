import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js'
import { parse } from 'query-string'

import { APP_CONFIG, CONFIGS_BY_ENVIRONMENT, STAGE } from 'src/config.app'

const APP_ROOT_URI = '/scoring-test-ui'
const COGNITO_AUTH_STATE_PARAM = 'state'

type VariadicCallbackFunction = (...args: any[]) => void

export class HookAuthenticator {
    static auth: CognitoAuth

    private static authHandler = {
        onSuccess: (session: CognitoAuthSession) => {
            // Removing access token param from URL
            let redirectUri = APP_ROOT_URI
            if (session.getState()) {
                // Redirect back to the user's original pre-authentication URL browser path
                redirectUri = decodeURIComponent(session.getState())
            }
            window.location.replace(redirectUri)
        },
        onFailure: (error: Error) => {
            console.error(`Cognito authentication failed, Error: ${error.toString()}`)
            // Removing error params from URL before another authentication attempt
            const redirectUri = HookAuthenticator.extractStateFromRedirectUrlHash()
            window.location.replace(redirectUri)
            window.location.reload()
        },
    }

    static initCognitoAuth(stage?: STAGE) {
        const authConfig = stage
            ? // need the hook auth for the stage selected and keep the same designer redirect uri
              {
                  ...CONFIGS_BY_ENVIRONMENT[stage].hookAuth,
                  RedirectUriSignIn: APP_CONFIG.hookAuth.RedirectUriSignIn,
                  RedirectUriSignOut: APP_CONFIG.hookAuth.RedirectUriSignOut,
              }
            : APP_CONFIG && APP_CONFIG.hookAuth
        if (!authConfig) {
            return
        }
        HookAuthenticator.auth = new CognitoAuth(authConfig)
        // Register callback functions to be executed after authentication attempt
        HookAuthenticator.auth.userhandler = HookAuthenticator.authHandler
        HookAuthenticator.auth.useImplicitFlow()
    }

    static authenticate(callback: VariadicCallbackFunction) {
        if (!HookAuthenticator.auth) {
            return callback()
        }
        const session = this.auth.getSignInUserSession()
        if (session.isValid()) {
            return callback()
        }
        // It may be a redirect back from Cognito after an authentication attempt
        const href = window.location.href
        const accessTokenReceived = href.includes('access_token=')
        if (accessTokenReceived || href.includes('error=')) {
            // Authentication response is in URL hash. Result of the following call will be passed to authHandler.
            this.auth.parseCognitoWebResponse(href)
            if (accessTokenReceived) {
                callback()
            }
            return
        }
        // No valid session - making authentication attempt
        this.authenticateWithCognito()
    }

    static getValidUserSession(): CognitoAuthSession | null {
        const userSession: CognitoAuthSession = this.auth.getSignInUserSession()
        if (!userSession.isValid()) {
            this.authenticateWithCognito()
            return null
        }
        return userSession
    }

    private static authenticateWithCognito(): void {
        // Save URL path in Cognito auth state so we can redirect user back to the same page after being authenticated with Cognito
        const encodedUriComponent = encodeURIComponent(
            window.location.href.replace(window.location.origin, '')
        )
        this.auth.setState(encodedUriComponent)
        // Cognito SDK will handle session refresh / authentication.
        this.auth.getSession()
    }

    private static extractStateFromRedirectUrlHash(): string {
        const stateParam =
            (parse(window.location.hash)[COGNITO_AUTH_STATE_PARAM] as string) || APP_ROOT_URI
        // For reverting state encoding in redirect URL from Cognito
        return decodeURIComponent(stateParam).replace(/\\+/g, '')
    }
}
