import React, { useEffect, useState } from 'react'

import { Card } from '@amzn/stencil-react-components/card'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'

import { RawMediaView } from 'src/components/media/RawMediaView'
import { UpdateFields, UseMediaButton } from 'src/components/media/UseMediaButton'
import { MediaMetadataDTO } from 'src/models/media/MediaMetadataDTO'
import { MediaService } from 'src/services/media/MediaService'

export interface SearchResultCardProps {
    mediaMetadata: MediaMetadataDTO
    isModuleBuilder?: boolean
    updateFields?: UpdateFields
}

export const SearchResultCard = (props: SearchResultCardProps) => {
    const [mediaUrl, setMediaUrl] = useState('')
    const [loadS3Error, setLoadS3Error] = useState('')

    const s3Path = props.mediaMetadata.s3Path
    useEffect(() => {
        void MediaService.getFromS3(s3Path)
            .then(setMediaUrl)
            .catch((e: unknown) => {
                console.error('Error while loading file from S3: ', s3Path, e)
                setLoadS3Error('Error while loading file from S3')
            })
    }, [s3Path])

    return (
        <>
            <Card flex={1} flexDirection='column' height={280} width={280}>
                <Col flex={1} gridGap={10} alignItems='center' height='100%'>
                    {loadS3Error && (
                        <Row justifyContent='center'>
                            <MessageBanner type={MessageBannerType.Error}>
                                {loadS3Error}
                            </MessageBanner>
                        </Row>
                    )}
                    <Row height='100px' justifyContent='center'>
                        <RawMediaView
                            url={mediaUrl}
                            mimeType={props.mediaMetadata.fileType}
                            controls
                            altText=''
                        />
                    </Row>
                    <Col justifyContent='center' alignSelf='center' width='100%'>
                        <Text fontSize='T100' textAlign='center'>
                            {'Filename: ' + props.mediaMetadata.filename}
                        </Text>
                        <Text fontSize='T100' textAlign='center'>
                            {'Author: ' + props.mediaMetadata.author}
                        </Text>
                        <Text fontSize='T100' textAlign='center'>
                            {'Date: ' + props.mediaMetadata.date}
                        </Text>
                        <UseMediaButton
                            isModuleBuilder={props.isModuleBuilder}
                            updateFields={props.updateFields}
                            media={
                                s3Path
                                    ? {
                                          filename: props.mediaMetadata.filename,
                                          relativePath: s3Path,
                                          mimeType: props.mediaMetadata.fileType,
                                      }
                                    : undefined
                            }
                        />
                    </Col>
                </Col>
            </Card>
        </>
    )
}
