import React, { useCallback, useContext, useState } from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { Modal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { Button } from 'src/components/Button'
import { ModalCloseButtonComponent } from 'src/components/MediaContentsEditor/ModalCloseButtonComponent'
import { EditAssetPage } from 'src/pages/asset-library/AssetUploadedPage'

export function MediaPreviewModal({ mediaVersionId }: { mediaVersionId: string }) {
    const { theme } = useContext(StencilContext)

    const {
        matches: { s: isSmall },
    } = useBreakpoints()

    const [isOpen, setIsOpen] = useState(false)

    const open = useCallback(() => {
        setIsOpen(true)
    }, [])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            open()
        },
        [open]
    )

    const width = theme.constants.maxWidth
    const maxWidth = isSmall ? '100vw' : '95vw'
    const minHeight = isSmall ? '100vh' : '95vh'

    return (
        <>
            <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Tertiary}
                onClick={onClick}
                dataTestId='preview-media-file'
            >
                Preview
            </Button>
            <Modal isOpen={isOpen} close={close} isScrollable={false}>
                <div
                    style={{
                        width,
                        maxWidth,
                        minHeight,
                        height: minHeight,
                        backgroundColor: '#F8F8F8',
                        overflow: 'auto',
                    }}
                >
                    <div
                        style={{
                            padding: '5px',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ModalCloseButtonComponent close={close} />
                    </div>
                    <div style={{ marginTop: '-60px' }}>
                        <EditAssetPage isEmbedded={true} cancel={close} mediaId={mediaVersionId} />
                    </div>
                </div>
            </Modal>
        </>
    )
}
