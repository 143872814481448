import * as queryString from 'querystring'

import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Expander } from '@amzn/stencil-react-components/expander'
import { IconAlertCircleFill, IconSize } from '@amzn/stencil-react-components/icons'
import { Col, Container, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Modal } from '@amzn/stencil-react-components/modal'
import { SortDirection } from '@amzn/stencil-react-components/table'
import { H2, H3, H4, Text } from '@amzn/stencil-react-components/text'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { APP_CONFIG } from 'src/config.app'
import {
    getModuleStatusKeyByValue,
    ModuleGroupSortCriteria,
    ModuleGroupStatus,
    ModuleGroupType,
    PageOfModuleGroups,
    QueryParams,
    SearchQuery,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { focusOnDataTestId, focusOnId, focusOnSortedHeader } from 'src/pages/module-builder/focus'
import { DuplicateModuleGroupModal } from 'src/pages/module-groups/DuplicateModuleGroupModal'
import { ModuleGroupBuilderModal } from 'src/pages/module-groups/ModuleGroupBuilderModal'
import { ModuleGroupsFilter } from 'src/pages/module-groups/ModuleGroupsFilter'
import { DuplicateOptionsProps, ModuleGroupTable } from 'src/pages/module-groups/ModuleGroupTable'
import { ApiActionNames } from 'src/services/AxiosInterceptor'

const DEFAULT_PAGE_SIZE = 10

export const ModuleGroupsPage = () => {
    const [moduleGroupName, setModuleGroupName] = useState<string>('')
    const [typeOfEquivalency, setTypeOfEquivalency] = useState<ModuleGroupType[]>([])
    const [moduleVersionId, setModuleVersionId] = useState<string>('')
    const [status, setStatus] = useState<ModuleGroupStatus[]>([])
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [ownerOfRecord, setOwnerOfRecord] = useState<string>('')
    const [showModuleGroupBuilderPage, setShowModuleGroupBuilderPage] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [syncFilter, setSyncFilter] = useState<boolean>(false)
    const [focus, setFocus] = useState<string>('')

    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    const [triggeredSearch, setTriggerSearch] = useState<boolean>()
    const [pushQueryParam, setPushQueryParam] = useState<boolean>(false)
    const [{ loading, data, error }, executeSearchRequest] = useAxios<PageOfModuleGroups>(
        {},
        { useCache: false, manual: true }
    )
    const offsetSearchParam = urlSearchParams.get(QueryParams.OFFSET) ?? undefined
    const [offset, setOffset] = useState<number>(offsetSearchParam ? +offsetSearchParam : 0)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [sortCriteria, setSortCriteria] = useState<ModuleGroupSortCriteria>({
        sortField: 'modified_at',
        sortDirection: SortDirection.Descending,
    })
    const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false)
    const [selectedModuleGroupId, setSelectedModuleGroupId] = useState<string>('')
    const [selectedModuleGroupName, setSelectedModuleGroupName] = useState<string>('')

    const searchQueryFromUrlQueryParameters = useCallback(() => {
        const nextSearchQuery: SearchQuery = {
            search: moduleGroupName,
            ...(ownerOfRecord && { owner: ownerOfRecord }),
            ...(moduleVersionId && { moduleVersionId: moduleVersionId }),
            ...(startDate && { startDate: startDate }),
            ...(endDate && { endDate: endDate }),
            moduleGroupType: typeOfEquivalency,
            moduleGroupStatus: status,
            numberOfRecordsPerPage: pageSize,
            offset: offset,
        }

        return {
            nextSearchQuery,
            sortField: sortCriteria.sortField,
            sortDirection: sortCriteria.sortDirection,
        }
    }, [
        moduleGroupName,
        ownerOfRecord,
        moduleVersionId,
        startDate,
        endDate,
        typeOfEquivalency,
        status,
        pageSize,
        offset,
        sortCriteria.sortField,
        sortCriteria.sortDirection,
    ])

    const createAndPushQueryParams = useCallback(() => {
        setPushQueryParam(false)
        const queryParams: string[][] = [
            [QueryParams.SEARCH, moduleGroupName],
            [QueryParams.SELECTED_PAGE, String(currentPage)],
            [QueryParams.OFFSET, String(offset)],
            [QueryParams.RECORDS_PER_PAGE, String(pageSize)],
        ]

        if (startDate) {
            queryParams.push([QueryParams.START_DATE, startDate])
        }

        if (endDate) {
            queryParams.push([QueryParams.END_DATE, endDate])
        }

        if (ownerOfRecord) {
            queryParams.push([QueryParams.OWNER, ownerOfRecord])
        }

        if (moduleVersionId) {
            queryParams.push([QueryParams.MODULE_VERSION_ID, moduleVersionId])
        }

        if (typeOfEquivalency && typeOfEquivalency.length > 0) {
            typeOfEquivalency.forEach((f) => {
                queryParams.push([QueryParams.GROUP_TYPE, f])
            })
        }

        if (status && status.length > 0) {
            status.forEach((f) => {
                queryParams.push([QueryParams.GROUP_STATUS, f])
            })
        }

        if (sortCriteria) {
            queryParams.push([QueryParams.SORT_FIELD, sortCriteria.sortField])
            queryParams.push([QueryParams.SORT_DIRECTION, sortCriteria.sortDirection])
        }

        const newSearchParams = new URLSearchParams(queryParams)
        if (urlSearchParams.toString() !== newSearchParams.toString()) {
            setUrlSearchParams(newSearchParams)
        }
        setSyncFilter(true)
        setTriggerSearch(true)
    }, [
        currentPage,
        endDate,
        moduleGroupName,
        moduleVersionId,
        offset,
        ownerOfRecord,
        pageSize,
        setUrlSearchParams,
        sortCriteria,
        startDate,
        status,
        typeOfEquivalency,
        urlSearchParams,
    ])

    const resetAndSearch = () => {
        setOffset(0)
        setCurrentPage(1)
        setPushQueryParam(true)
    }

    useEffect(() => {
        if (pushQueryParam) {
            createAndPushQueryParams()
        }
    }, [createAndPushQueryParams, pushQueryParam])

    useEffect(() => {
        const currentOffsetSearchParam = urlSearchParams.get(QueryParams.OFFSET) ?? undefined
        const currentPageSizeSearchParam =
            urlSearchParams.get(QueryParams.RECORDS_PER_PAGE) ?? undefined
        const currentSelectedPageSearchParam =
            urlSearchParams.get(QueryParams.SELECTED_PAGE) ?? undefined
        const urlSortField = urlSearchParams.get(QueryParams.SORT_FIELD) ?? 'modified_at'
        const urlSortDirection =
            urlSearchParams.get(QueryParams.SORT_DIRECTION) === SortDirection.Ascending.toString()
                ? SortDirection.Ascending
                : SortDirection.Descending
        setModuleGroupName(urlSearchParams.get(QueryParams.SEARCH) ?? '')
        setStartDate(urlSearchParams.get(QueryParams.START_DATE) ?? '')
        setEndDate(urlSearchParams.get(QueryParams.END_DATE) ?? '')
        setOwnerOfRecord(urlSearchParams.get(QueryParams.OWNER) ?? '')
        setModuleVersionId(urlSearchParams.get(QueryParams.MODULE_VERSION_ID) ?? '')
        setStatus((urlSearchParams.getAll(QueryParams.GROUP_STATUS) as ModuleGroupStatus[]) ?? [])
        setTypeOfEquivalency(
            (urlSearchParams.getAll(QueryParams.GROUP_TYPE) as ModuleGroupType[]) ?? []
        )
        setOffset(currentOffsetSearchParam ? +currentOffsetSearchParam : 0)
        setCurrentPage(currentSelectedPageSearchParam ? +currentSelectedPageSearchParam : 1)
        setPageSize(currentPageSizeSearchParam ? +currentPageSizeSearchParam : DEFAULT_PAGE_SIZE)
        setSortCriteria({ sortField: urlSortField, sortDirection: urlSortDirection })
        setTriggerSearch(true)
        setSyncFilter(true)
    }, [urlSearchParams])

    useEffect(() => {
        if (triggeredSearch) {
            setTriggerSearch(false)

            const {
                nextSearchQuery: nextQuery,
                sortField,
                sortDirection,
            } = searchQueryFromUrlQueryParameters()

            const { moduleGroupStatus, moduleGroupType, ...nonArrayArguments } = nextQuery
            const arrayArguments = queryString.stringify({
                ...(moduleGroupStatus
                    ? {
                          moduleGroupStatus: moduleGroupStatus.map((m) =>
                              getModuleStatusKeyByValue(m)
                          ),
                      }
                    : {}),
                ...(moduleGroupType ? { moduleGroupType: moduleGroupType } : {}),
            })

            void executeSearchRequest({
                apiActionName: ApiActionNames.GetModuleGroup,
                method: 'GET',
                url:
                    `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions?` +
                    (arrayArguments != '&' ? arrayArguments : ''),
                params: {
                    ...nonArrayArguments,
                    [QueryParams.SORT_FIELD]: sortField.toUpperCase(),
                    [QueryParams.SORT_DIRECTION]: sortDirection.toUpperCase(),
                },
            })
                .catch(() => {})
                .then(() => {
                    if (focus.length > 0 && focus === 'sort') {
                        focusOnSortedHeader()
                    } else if (focus.length && focus === 'pageSize') {
                        focusOnId('items-per-page-select-toggle-button')
                    } else if (focus.length && focus === 'pageNumber') {
                        focusOnDataTestId(currentPage.toString())
                    }
                    setFocus('')
                })
        }
    }, [
        triggeredSearch,
        executeSearchRequest,
        searchQueryFromUrlQueryParameters,
        focus,
        currentPage,
    ])

    useEffect(() => {
        setTriggerSearch(true)
        setSyncFilter(true)
    }, [])

    const duplicateOptionsProps = {
        setSelectedModuleGroupName: setSelectedModuleGroupName,
        setShowDuplicateModal: setShowDuplicateModal,
        setSelectedModuleGroupId: setSelectedModuleGroupId,
    } as DuplicateOptionsProps

    const Results = () => {
        if (error) {
            return (
                <Col
                    display='flex'
                    justifyContent='flex-start'
                    width='100%'
                    alignItems='center'
                    gridGap='S200'
                    padding={{ top: 'S200' }}
                >
                    <Row gridGap={'S200'} alignItems={'center'}>
                        <IconAlertCircleFill color={'red70'} size={IconSize.Medium} />
                        <Text fontSize={'T500'} textAlign={'center'} color='neutral90'>
                            Oops, an unexpected error occurred on our end.
                        </Text>
                    </Row>
                    <Text fontSize={'T400'} color='neutral70'>
                        Please try searching again.
                    </Text>
                </Col>
            )
        } else if ((data && data.moduleGroupMetadataList.length > 0) || loading) {
            return (
                <ModuleGroupTable
                    data={data?.moduleGroupMetadataList ?? []}
                    tableType='moduleGroup'
                    pageSize={pageSize}
                    totalRecords={data?.totalRecords ?? 0}
                    loading={loading}
                    selectedPage={currentPage}
                    setPage={(page: number) => {
                        setOffset((page - 1) * pageSize)
                        setCurrentPage(page)
                        setPushQueryParam(true)
                        setFocus('pageNumber')
                    }}
                    updatePageSize={(passedPageSize: number) => {
                        setPageSize(passedPageSize)
                        setFocus('pageSize')
                    }}
                    sortCriteria={sortCriteria}
                    onSortCriteriaChange={(newSortCriteria: ModuleGroupSortCriteria) => {
                        setSortCriteria(newSortCriteria)
                        resetAndSearch()
                        setFocus('sort')
                    }}
                    duplicateOptions={duplicateOptionsProps}
                />
            )
        }
        return (
            <Col
                display='flex'
                justifyContent='flex-start'
                width='100%'
                alignItems='center'
                gridGap='S200'
                padding={{ top: 'S200' }}
                dataTestId={'no-results-component'}
            >
                <H4 color='neutral70' textAlign='center' data-no-search-results>
                    No module groups were found with these parameters. Please refine the search or
                    create a module group that matches these filters and try again.
                </H4>
            </Col>
        )
    }

    return (
        <Container>
            <Col gridGap='S300'>
                <View padding={{ top: 'S300', left: 'S300', right: 'S300', bottom: 'S200' }}>
                    <H2>Module Groups</H2>
                </View>
                <Expander shouldExpandOnMount={true} titleText={'What are the equivalency types'}>
                    <Container paddingHorizontal={'S600'} paddingBottom={'S400'}>
                        <Text fontSize={'T200'} fontWeight={'regular'}>
                            <strong>Measurement Equivalent Scores (MES)-</strong> Use this score
                            group when there is a module (the reference module) that has equivalent
                            module level scores in another module(s) that could be reused for a
                            candidate, such that the reference module would not need to be
                            presented.
                        </Text>
                        <Spacer height={'S400'} />
                        <Text fontSize={'T200'} fontWeight={'regular'}>
                            <strong>Presentation Equivalent Content (PEC)-</strong> Use this group
                            when the module (evaluation or non-evaluation) that should be presented
                            to a candidate differs based on job metadata (eg., job code, job level,
                            job family).
                        </Text>
                        <Spacer height={'S400'} />
                        <Text fontSize={'T200'} fontWeight={'regular'}>
                            <strong>Functionality Equivalent Scores (FES)-</strong> This score group
                            is a sub-feature of PEC. Use this score group if the PEC contains
                            evaluation modules that have module level scores that will function
                            identically in the workflow level overall score formula. These module
                            level scores are not measurement equivalent and can not be substituted
                            with each other, but they do have the same mathematical role in the
                            workflow level overall score formula.
                        </Text>
                    </Container>
                </Expander>
                <Hr size='wide' />
                <Container>
                    <ResponsiveRow gridGap={'S300'}>
                        <Col>
                            <H3 id={'current-module-group-filter-header'}>Filters</H3>
                            <Spacer height={'S400'} />
                            <ModuleGroupsFilter
                                moduleGroupName={moduleGroupName}
                                typeOfEquivalency={typeOfEquivalency}
                                moduleVersionId={moduleVersionId}
                                status={status}
                                startDate={startDate}
                                endDate={endDate}
                                ownerOfRecord={ownerOfRecord}
                                syncState={syncFilter}
                                updateGroupName={setModuleGroupName}
                                updateModuleVersionId={setModuleVersionId}
                                updateOwnerOfRecord={setOwnerOfRecord}
                                updateStatus={setStatus}
                                updateStartDate={setStartDate}
                                updateEndDate={setEndDate}
                                updateSyncState={setSyncFilter}
                                updateTypeOfEquivalency={setTypeOfEquivalency}
                                applyFilters={resetAndSearch}
                                sideFilter={true}
                            />
                        </Col>
                        <Col flex={1}>
                            <ResponsiveRow>
                                <H3 id={'current-module-group-header'}>
                                    <strong>Current Module Groups</strong>
                                </H3>
                                <Spacer flex={1} />
                                <Button
                                    dataTestId={'add-module-group-button'}
                                    onClick={() => setShowModuleGroupBuilderPage(true)}
                                    variant={ButtonVariant.Primary}
                                >
                                    Add Module Group
                                </Button>
                            </ResponsiveRow>
                            <Spacer height={'S400'} />
                            <Results />
                        </Col>
                    </ResponsiveRow>
                    <View>
                        <ModuleGroupBuilderModal
                            showModuleGroupBuilder={showModuleGroupBuilderPage}
                            setShowModuleGroupBuilder={setShowModuleGroupBuilderPage}
                        />
                    </View>
                </Container>

                <Modal
                    isOpen={showDuplicateModal}
                    close={() => {
                        setShowDuplicateModal(false)
                        focusOnId(`duplicate-${selectedModuleGroupName}`)
                    }}
                    onClose={() => focusOnId(`duplicate-${selectedModuleGroupName}`)}
                    isScrollable={false}
                >
                    <DuplicateModuleGroupModal
                        selectedModuleGroupId={selectedModuleGroupId}
                        selectedModuleGroupName={selectedModuleGroupName}
                        close={() => setShowDuplicateModal(false)}
                    />
                </Modal>
            </Col>
        </Container>
    )
}
