// This configuration maps the change in module keyPath to a user-friendly message
export const moduleReviewConfiguration = new Map<string, string>([
    ['moduleTimerConfig', 'Module timer config was changed'],
    ['compositeScores', 'Module level composite scores were added, removed or updated'],
    ['..scores', 'Item level scores were added'],
    ['..score', 'Item level scores were added'],
    ['scores', 'Item level scores were updated'],
    ['score', 'Item level scores were updated'],
    ['moduleType', 'Module type was changed'],
    ['layout', 'Module layout was changed'],
    ['userType', 'Module user type was changed'],
    ['assessmentMetadata', 'Assessment Metadata was changed'],
    ['autoProgressionOn', 'Module feature auto progression was changed'],
    ['evaluationModule', 'Progress Bar toggle was changed'],
    ['instructionalContent', 'Customized fly-out instructions were changed'],
    ['en_US', 'Text in the item or module updated or changed (other than score)'],
    ['localeWiseMedia', 'Media added, updated or removed in an item'],
    ['poolVersionId', 'Item Pool version ID was changed'],
    ['itemBankId', 'Item Bank version ID was changed'],
    ['muppItemBankId', 'Item bank was added or updated'],
    ['.scoreItemRemoved', 'Scoring item was removed from a page'],
    ['.scoreItemAdded', 'Scoring item was added to a page'],
    ['.addPages', 'A new page was added'],
    ['.addItems', 'A new item was added'],
    ['.removePages', 'A page was removed'],
    ['.removeItems', 'An item was removed'],
    ['.translationChange', 'Translation locales or texts were added or changed'],
    ['.pageSettings', 'Page setting was changed (e.g. Description, Randomize, Label etc.)'],
    ['.itemSettings', 'Item setting was changed (e.g. Optional, Preserve Order etc.)'],
    ['.pagesOrder', 'Order of pages was changed'],
    ['.itemsOrder', 'Order of items was changed'],
    ['.pageOrItemOrder', 'Order of items or pages changed'],
    ['experimentalItemPoolID', 'Experimental Item Pool version ID was changed'],
    ['BucketsAndCupsBasedSelectionGroupDTO', 'Buckets and Cups group configuration was updated'],
    ['BucketsAndCupsBasedSelectionGroupDTO.Added', 'Buckets and Cups group page was added'],
    ['BucketsAndCupsBasedSelectionGroupDTO.Removed', 'Buckets and Cups group page was removed'],
    ['RandomSelectionGroupDTO', 'Random selection group configuration was updated'],
    ['RandomSelectionGroupDTO.Added', 'Random selection group page was added'],
    ['RandomSelectionGroupDTO.Removed', 'Random selection group page was removed'],
    ['MUPPExamDTO', 'Work styles exam configuration was updated'],
    ['MUPPExamDTO.Added', 'Work styles exam page was added'],
    ['MUPPExamDTO.Removed', 'Work styles exam page was removed'],
    ['AdaptiveEngineSelectionGroupDTO', 'Adaptive engine configuration was updated'],
    ['AdaptiveEngineSelectionGroupDTO.Added', 'Adaptive engine group was added'],
    ['AdaptiveEngineSelectionGroupDTO.Removed', 'Adaptive engine group was removed'],
    [
        '.audioScoringModel',
        'Scoring ML model or Transcription ML model was changed in Audio Evaluation item',
    ],
])

export const audioScoringConfig = [
    '.scoringMLModelVersion',
    '.transcriptionMLModel',
    '.scoringMLModel',
    '.transcriptionMLModelVersion',
]

export const pageSettingConfig = [
    '.randomizationEnabled',
    '.description',
    '.branchingConditions',
    '.labels',
]

export const commonSettingConfig = [
    '.en_US',
    '.scores',
    '.score',
    '.selection',
    '.mediaManagerMediaVersionIds',
    '.localeWiseMedia',
    '.poolVersionId',
    '.itemBankId',
    '.experimentalItemPoolID',
]

export const bucketsAndCupsConfig = [
    '.bucketAndCupSelectionStep',
    '.buckets',
    '.timingEnabled',
    '.generateScoringTemplate',
]

export const randomSelectionConfig = ['groupSize', 'numberOfItemsToPick']

export const workStyleExamConfig = ['pairingTableDTO.id']

export const adaptiveEngineConfig = ['examTerminationCriteria', 'itemPoolConfiguration']

export const itemSettingConfig = ['.optional', '.preserveOrder']
