import React, { useContext } from 'react'
import DOMPurify from 'dompurify'
import { parse, parseInline } from 'marked'

import { StencilContext } from '@amzn/stencil-react-components/context'
import { View } from '@amzn/stencil-react-components/layout'

// Based on how Candidate Website renders Markdown
export const parseAndSanitizeMarkdown = (content: string, inline?: boolean) => {
    const markdownParsed = inline ? parseInline(content) : parse(content)
    return DOMPurify.sanitize(markdownParsed, { ADD_ATTR: ['target', 'template-var'] })
}

export function Markdown({ markdown }: { markdown: string }) {
    const { theme } = useContext(StencilContext)
    return (
        <View
            css={{
                'h1, h2, h3, h4': {
                    fontWeight: 'bold',
                },
                h1: {
                    fontSize: '2.75rem',
                    lineHeight: 1.32,
                },
                h2: {
                    fontSize: '2.25rem',
                    lineHeight: 1.32,
                },
                h3: {
                    fontSize: '1.75rem',
                    lineHeight: 1.43,
                },
                h4: {
                    fontSize: '1.375rem',
                    lineHeight: 1.55,
                },
                blockquote: {
                    margin: '24px',
                    paddingLeft: '16px',
                    borderLeftWidth: '4px',
                    borderLeftColor: theme.color.primary50,
                    borderLeftStyle: 'solid',
                },
                ul: {
                    listStyleType: 'disc',
                },
                'ol, ul': {
                    display: 'block',
                    marginBlockStart: '1em',
                    marginBlockEnd: '1em',
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                    paddingInlineStart: '40px',
                },
                ol: {
                    listStyleType: 'decimal',
                },
            }}
            dangerouslySetInnerHTML={{
                __html: parseAndSanitizeMarkdown(markdown, false),
            }}
        />
    )
}
