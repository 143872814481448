import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface MediaStimulusItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.MediaStimulus
    optional: boolean
    titleI18N: LocalizedAttribute<string>
    limitPlayback: boolean
    maxPlaybackCount?: number
}

export const defaultMediaStimulusItemDTO = (): MediaStimulusItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.MediaStimulus,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: false,
        preserveOrder: false,
        ppt: ItemType.MediaStimulus,
        limitPlayback: false,
        titleI18N: LocalizeDefault<string>(''),
    }
}
