import { utils, WorkBook, writeFile } from 'xlsx-js-style'

import { AllScoresVariationEntry } from 'src/services/hook/ScoringTestingV2Service'

export class XlsxFileParser {
    public static XLSX_FILE_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    private static readonly LAST_DOT_REGEX = new RegExp(/\.(?=[^.]*$)/) // Will match the last dot in the string

    public static saveXlsxWorkBook(wb: WorkBook, fileName: string) {
        try {
            writeFile(wb, `${fileName}.xlsx`)
        } catch (e: unknown) {
            console.error(e as Error)
            throw e
        }
    }

    public static generateHeaderFile(allVariationScores: AllScoresVariationEntry[]): WorkBook {
        try {
            const workbook = utils.book_new()
            allVariationScores.forEach((variationEntry) => {
                const variationSheet = utils.aoa_to_sheet(this.convertToSheetJson(variationEntry))
                utils.book_append_sheet(workbook, variationSheet, variationEntry.scoringVariationId)
            })
            return workbook
        } catch (e: unknown) {
            console.error(e)
            throw e
        }
    }

    private static convertToSheetJson(variationScores: AllScoresVariationEntry): string[][] {
        const harmonizedInputs = variationScores.inputScores.map((inputScore) => {
            return inputScore.replace('assessment.', '').replace(XlsxFileParser.LAST_DOT_REGEX, '#')
        })
        const harmonizedOutputs: string[] = variationScores.internalScores.map(
            (outputScore) => `Output#${outputScore}`
        )
        return [[...harmonizedInputs, ...harmonizedOutputs]]
    }
}
