import React, { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Select } from '@amzn/stencil-react-components/form'
import { IconPencil } from '@amzn/stencil-react-components/icons'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { Link, LinkProps } from '@amzn/stencil-react-components/link'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import {
    SortDirection,
    SortState,
    Table,
    TableColumn,
    useSort,
} from '@amzn/stencil-react-components/table'
import { H3, Text } from '@amzn/stencil-react-components/text'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

import { Button } from 'src/components/Button'
import { getStatusValue } from 'src/helper/featureFlagHelper'
import { ModuleGroupMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleListSortCriteria } from 'src/models/ListModulesRequest'
import { ModuleMetaData, SearchResultResponse } from 'src/models/SearchResultResponse'
import { moduleViewerRoute } from 'src/pages/module-review'
import { Authenticator } from 'src/services/Authenticator'

export interface CustomHeaderComponentProps {
    currentRecordsNum: number
    totalRecordsNum: number
}

export interface ModuleDisplayTableProps {
    searchResults: SearchResultResponse
    searchTerm: string
    onPageChange?: (offset: number, numberOfRecordsPerPage: number) => void
    CustomHeaderComponent?: (props: CustomHeaderComponentProps) => JSX.Element
    onUpdateNumberOfItemsPerPage?: (option: number) => void
    offset?: number
    onSortCriteriaChange?: (
        sortField: 'status' | 'moduleName' | 'moduleType' | 'modifiedAt',
        sortDirection: SortDirection,
        offset: number,
        numberOfRecordsPerPage: number
    ) => void
    sortCriteria?: ModuleListSortCriteria
    embeddedInModal?: boolean
    selectModuleAction?: TableColumn<ModuleMetaData>
    noTypeColumn?: boolean
}

export enum LAYOUT_DIRECTION {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}

const OPTIONS = ['10', '25', '50']

export const useLayoutDirection = () => {
    const { matches } = useBreakpoints()

    if (matches.s) {
        return LAYOUT_DIRECTION.MOBILE
    }

    if (matches.m) {
        return LAYOUT_DIRECTION.TABLET
    }

    return LAYOUT_DIRECTION.DESKTOP
}

export const BasicModuleCell = ({ data }: { data: string }) => (
    <Text textAlign='left'> {data} </Text>
)

const EditorButton = ({ data }: { data: ModuleMetaData }) => {
    const navigate = useNavigate()
    return (
        <Button
            variant={ButtonVariant.Secondary}
            disabled={
                ModuleStatus[data.status as keyof typeof ModuleStatus] ===
                    ModuleStatus.DRAFT_UNVALIDATED && data.owner !== Authenticator.getDefaultUser()
            }
            icon={<IconPencil title='Edit module' />}
            dataTestId={`edit-button-${data.moduleName}`}
            onClick={() => {
                navigate(`/module-builder?moduleVersionId=${data.moduleVersionId}`)
            }}
        />
    )
}

// ignoring this for now because there is no known fix for this issue
// @ts-ignore: Expression produces a union type that is too complex to represent error
export const LinkWithTooltip = withTooltip<Omit<LinkProps, 'ref'>>({
    useTriggerWrapper: false,
})(Link)

export const ModuleDateCell = ({ data }: { data: ModuleMetaData | ModuleGroupMetadata }) => {
    const fullDate = new Date(Date.parse(data.modifiedAt))
    const calendarDate = fullDate.toDateString().substring(4)
    const timeStamp = fullDate.toLocaleTimeString()

    return (
        <Col>
            <Text textAlign='left'> {calendarDate} </Text>
            <Text dataTestId='modified-date-id' textAlign='left' color='neutral70' fontSize='T100'>
                {' '}
                by{' '}
                <LinkWithTooltip
                    target='_blank'
                    href={`https://phonetool.amazon.com/users/${data.modifiedBy}`}
                    tooltipText='Opens user in Phone Tool'
                >
                    {data.modifiedBy}
                </LinkWithTooltip>
                {' at '}
                {timeStamp}{' '}
            </Text>
        </Col>
    )
}
const ModuleDisplayTableDefaultHeader = (props: {
    searchTerm: string
    pageSize: number
    totalRecords: number
}) => {
    const layout = useLayoutDirection()

    return (
        <View display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
            {layout !== LAYOUT_DIRECTION.MOBILE && (
                <H3>
                    <strong> Search results for: </strong> {`"${props.searchTerm}"`}
                </H3>
            )}

            <Text>
                Showing {props.pageSize} of {props.totalRecords} results
            </Text>
        </View>
    )
}

const statusColumn = {
    header: 'Status',
    accessor: ({ data }: { data: ModuleMetaData }) => getStatusValue(data.status),
    cellComponent: BasicModuleCell,
    sortId: 'status',
    width: '15%',
}

const typeColumn = {
    header: 'Module Type',
    accessor: 'moduleType',
    cellComponent: BasicModuleCell,
    sortId: 'moduleType',
    width: '20%',
}

const actionColumn = {
    header: <ScreenReaderOnly>Edit action</ScreenReaderOnly>,
    cellComponent: EditorButton,
    width: '10%',
}

const modifiedColumn = {
    header: 'Last Modified',
    cellComponent: ModuleDateCell,
    sortId: 'modifiedAt',
    width: '25%',
}

const MIN_PAGE_SIZE = 10

export const ModuleDisplayTable = ({
    searchResults,
    searchTerm,
    onPageChange,
    CustomHeaderComponent,
    onUpdateNumberOfItemsPerPage,
    offset,
    onSortCriteriaChange,
    sortCriteria,
    embeddedInModal,
    selectModuleAction,
    noTypeColumn,
}: ModuleDisplayTableProps) => {
    const [pageSize, setPageSize] = useState<number>(searchResults.numberOfRecordsPerPage)
    const numberOfPages = Math.ceil(searchResults.totalRecords / pageSize)
    const [selectedPage, setSelectedPage] = useState(searchResults.offset / pageSize + 1)
    const layout = useLayoutDirection()

    const isFullSetOfResults = onPageChange === undefined

    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: sortCriteria ? sortCriteria.sortDirection : SortDirection.Descending,
        defaultActiveSortId: sortCriteria ? sortCriteria.sortField : 'modifiedAt',
    })

    const compare = (a: ModuleMetaData, b: ModuleMetaData) => {
        const dataA: string | boolean = a[activeSortId as keyof ModuleMetaData]
        const dataB: string | boolean = b[activeSortId as keyof ModuleMetaData]
        return dataA < dataB ? -1 : 1
    }

    const sortedData =
        sortDirection === SortDirection.Descending
            ? searchResults.data.sort(compare).reverse()
            : searchResults.data.sort(compare)

    const nameColumn = useMemo(
        () => ({
            header: 'Module Name',
            sortId: 'moduleName',
            cellComponent: ({ data }: { data: ModuleMetaData }) => {
                return (
                    <Col>
                        {embeddedInModal ? (
                            <Text>{data.moduleName}</Text>
                        ) : (
                            <Text textAlign='left' color='#0077D9'>
                                {' '}
                                <RouterLink
                                    to={moduleViewerRoute({
                                        moduleVersionId: data.moduleVersionId,
                                    })}
                                >
                                    <strong>{data.moduleName}</strong>
                                </RouterLink>{' '}
                            </Text>
                        )}
                        <Text textAlign='left' color='neutral70' fontSize='T100'>
                            Owner:{' '}
                            <LinkWithTooltip
                                target='_blank'
                                href={`https://phonetool.amazon.com/users/${data.owner}`}
                                tooltipText='Opens user in Phone Tool'
                            >
                                {data.owner}
                            </LinkWithTooltip>
                        </Text>
                    </Col>
                )
            },
            width: `${layout === LAYOUT_DIRECTION.MOBILE ? '90%' : '30%'}`,
        }),
        [layout, embeddedInModal]
    )

    const defaultViewColumns = useMemo(
        () => [
            statusColumn,
            nameColumn,
            ...(noTypeColumn ? [modifiedColumn] : [typeColumn, modifiedColumn]),
            ...(selectModuleAction ? [selectModuleAction] : [actionColumn]),
        ],
        [nameColumn, noTypeColumn, selectModuleAction]
    )

    const mobileViewColumns = useMemo(
        () => [nameColumn, ...(selectModuleAction ? [selectModuleAction] : [actionColumn])],
        [selectModuleAction, nameColumn]
    )

    const columns = layout === LAYOUT_DIRECTION.MOBILE ? mobileViewColumns : defaultViewColumns
    const numberOfRecords =
        selectedPage === numberOfPages && searchResults.data.length % pageSize !== 0
            ? Math.max(1, searchResults.data.length % pageSize)
            : pageSize

    const handleSort = (sortState: SortState) => {
        const sortField = sortState.sortId
        const direction = sortState.sortDirection
        onSortCriteriaChange?.(
            sortField as 'status' | 'moduleName' | 'moduleType' | 'modifiedAt',
            direction,
            (selectedPage - 1) * pageSize,
            pageSize
        )
        onSort(sortState)
    }

    const renderOption = (option: string) => <Text>{option} items per page</Text>

    useEffect(() => {
        if (offset) setSelectedPage(offset / pageSize + 1)
    }, [offset, pageSize])

    return (
        <Col gridGap='16px' width='100%'>
            {CustomHeaderComponent ? (
                <CustomHeaderComponent
                    currentRecordsNum={numberOfRecords}
                    totalRecordsNum={searchResults.totalRecords}
                />
            ) : (
                <ModuleDisplayTableDefaultHeader
                    pageSize={numberOfRecords}
                    searchTerm={searchTerm}
                    totalRecords={searchResults.totalRecords}
                />
            )}

            <Table
                columns={columns}
                activeSortId={activeSortId}
                sortDirection={sortDirection}
                onSort={handleSort}
                data={
                    !isFullSetOfResults
                        ? sortedData
                        : sortedData.slice((selectedPage - 1) * pageSize, selectedPage * pageSize)
                }
                dataTestId='search-results-table'
            />

            {(numberOfPages > 1 || pageSize > MIN_PAGE_SIZE || selectedPage > 1) && (
                <View
                    display='flex'
                    flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                    justifyContent='space-between'
                    width='100%'
                >
                    <Select
                        id='items-per-page-select'
                        labelId='items-per-page-select'
                        data-test-id='items-per-page-select'
                        width={layout === LAYOUT_DIRECTION.MOBILE ? '100%' : '30%'}
                        onChange={(option: string) => {
                            setPageSize(Number(option))
                            setSelectedPage(1)

                            if (onPageChange) {
                                onPageChange(0, Number(option))
                            }

                            if (onUpdateNumberOfItemsPerPage) {
                                onUpdateNumberOfItemsPerPage(Number(option))
                            }
                        }}
                        options={OPTIONS}
                        renderOption={renderOption}
                        value={pageSize}
                    />
                    {numberOfPages > 1 && (
                        <Pagination
                            numberOfPages={numberOfPages}
                            onPageSelect={(page) => {
                                setSelectedPage(page)
                                if (onPageChange) {
                                    onPageChange((page - 1) * pageSize, pageSize)
                                }
                            }}
                            selectedPage={selectedPage}
                            dataTestId='results-table-pagination'
                        />
                    )}
                </View>
            )}
        </Col>
    )
}
