export enum ResearchWorkflowThemeName {
    Unbranded = 'Unbranded',
    Branded = 'Branded',
    Blackbird = 'Blackbird',
}

export interface ResearchWorkflowResource {
    evaluation: boolean
    moduleVersionId: string
}

export enum ResearchWorkflowEnvironment {
    GAMMA = 'GAMMA',
    DELTA = 'DELTA',
}

export interface ResearchWorkflowDTO {
    theme: ResearchWorkflowThemeName
    modules: ResearchWorkflowResource[]
    environment?: ResearchWorkflowEnvironment
}

export const defaultResearchWorkflowDTO = () => {
    return {
        theme: ResearchWorkflowThemeName.Branded,
        modules: [],
    } as ResearchWorkflowDTO
}
