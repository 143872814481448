import React, { useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form'
import { IconAlertTriangleFill, IconSize } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, RenderModalFunction, WithModal } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { ModuleGroupDeploymentProgress } from 'src/components/module-groups/ModuleGroupDeploymentProgress'
import { ModuleDeploymentProgress } from 'src/components/moduleDeployment/ModuleDeploymentProgress'
import { getReviewersList } from 'src/config.app'
import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { InitiateUATApprovalProcessInput, UATReviewDTO } from 'src/models/dto/approval/UATReviewDTO'
import { ApprovalStatus } from 'src/models/dto/ApprovalStatus'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ModuleGroupStatus } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleDeploymentTargetStage } from 'src/models/dto/ModuleDeployment'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { Authenticator } from 'src/services/Authenticator'

export interface SelfServicePublishingComponentProps {
    workflowDefinitionURL: string
    versionId: string
    close: () => void
    onClose: () => void
    onDeployed?: (successfullyDeployed: boolean) => void
}

export interface SelfServicePublishingContentProps {
    workflowDefinitionURL: string
    versionIdToConfirm: string
    setVersionIdToConfirm: (versionIdToConfirm: string) => void
    isInProgress: boolean
    versionIdMismatch: boolean
    setVersionIdMismatch: (versionIdMismatch: boolean) => void
    entityName: string
}

export interface SelfServiceButtonsProps {
    versionId: string
    versionIdToConfirm: string
    setIsInProgress: (isInProgress: boolean) => void
    isInProgress: boolean
    setVersionIdMismatch: (versionIdMismatch: boolean) => void
    deployed: boolean
    onClose: () => void
    close: () => void
}

export const SelfServiceButtons = (props: SelfServiceButtonsProps) => {
    const {
        deployed,
        isInProgress,
        versionIdToConfirm,
        setVersionIdMismatch,
        setIsInProgress,
        versionId,
    } = props

    if (deployed) {
        return (
            <Button
                key='publish-module-modal-close-button'
                dataTestId='publish-module-modal-close-button'
                variant={ButtonVariant.Primary}
                onClick={() => {
                    props.onClose()
                    props.close()
                }}
            >
                Close
            </Button>
        )
    }

    return (
        <Button
            key='publish-module-modal-publish-button'
            dataTestId='publish-module-modal-publish-button'
            variant={ButtonVariant.Primary}
            onClick={() => {
                if (isInProgress) {
                    return
                }
                if (versionIdToConfirm.length === 0 || versionIdToConfirm !== versionId) {
                    return setVersionIdMismatch(true)
                }

                setIsInProgress(true)
            }}
        >
            Submit
        </Button>
    )
}

export const SelfServicePublishingContent = (props: SelfServicePublishingContentProps) => {
    const {
        workflowDefinitionURL,
        versionIdToConfirm,
        setVersionIdToConfirm,
        isInProgress,
        versionIdMismatch,
        setVersionIdMismatch,
        entityName,
    } = props

    return (
        <>
            <Col gridGap='S100' backgroundColor='neutral0'>
                <Text fontSize={'T400'} color={'neutral90'} fontWeight={'bold'}>
                    Workflow Definition:{' '}
                    <a href={workflowDefinitionURL} target={'_blank'} rel='noreferrer'>
                        {workflowDefinitionURL}
                    </a>
                </Text>

                <Spacer height={'S400'} />
                <Row gridGap={'S200'} alignItems={'center'}>
                    <IconAlertTriangleFill
                        title={''}
                        color={'yellow50'}
                        size={IconSize.Large}
                        aria-hidden={true}
                    />
                    <Text fontSize={'T300'} color={'neutral90'}>
                        Please copy and paste the {entityName} version id to publish from the
                        workflow definition to ensure that you are publishing a {entityName} version
                        up-to-date.
                    </Text>
                </Row>

                <InputWrapper
                    id='self-service-publish-version-id'
                    labelText=''
                    required={true}
                    error={versionIdMismatch}
                    footer={
                        versionIdMismatch
                            ? `The ${entityName} version id you entered is missing or mismatched with actual version
                        id. Please make sure if you are attempting to publish a correct version.`
                            : undefined
                    }
                >
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            value={versionIdToConfirm}
                            disabled={isInProgress}
                            dataTestId='self-service-publish-version-id-input'
                            onChange={(e) => {
                                setVersionIdToConfirm(e.target.value)
                                setVersionIdMismatch(false)
                            }}
                        />
                    )}
                </InputWrapper>
            </Col>

            <Spacer height={'S400'} />
        </>
    )
}

export const SelfServiceModuleGroupPublishingComponent = (
    props: SelfServicePublishingComponentProps
) => {
    const [isInProgress, setIsInProgress] = useState(false)
    const [versionIdToConfirm, setVersionIdToConfirm] = useState('')
    const [versionIdMismatch, setVersionIdMismatch] = useState(false)
    const [deployed, setDeployed] = useState(false)

    return (
        <ModalContent
            titleText='Publish module group to production'
            buttons={[
                <Button
                    key='close-publish-prod-modal'
                    dataTestId='close-publish-prod-modal'
                    onClick={props.close}
                >
                    Cancel
                </Button>,
                <SelfServiceButtons
                    key={'self-service-buttons'}
                    versionId={props.versionId}
                    versionIdToConfirm={versionIdToConfirm}
                    setIsInProgress={setIsInProgress}
                    isInProgress={isInProgress}
                    setVersionIdMismatch={setVersionIdMismatch}
                    deployed={deployed}
                    onClose={props.onClose}
                    close={props.close}
                />,
            ]}
            maxWidth='60vw'
        >
            <SelfServicePublishingContent
                workflowDefinitionURL={props.workflowDefinitionURL}
                versionIdToConfirm={versionIdToConfirm}
                setVersionIdToConfirm={setVersionIdToConfirm}
                isInProgress={isInProgress}
                versionIdMismatch={versionIdMismatch}
                setVersionIdMismatch={setVersionIdMismatch}
                entityName={'module group'}
            />
            <ModuleGroupDeploymentProgress
                moduleGroupVersionId={props.versionId}
                isInProgress={isInProgress}
                targetStage={ModuleDeploymentTargetStage.PRODUCTION}
                author={Authenticator.getDefaultUser()}
                comments={''}
                setDeployedStatus={setDeployed}
            />
        </ModalContent>
    )
}

export const SelfServiceModulePublishingComponent = (
    props: SelfServicePublishingComponentProps
) => {
    const [isInProgress, setIsInProgress] = useState(false)
    const [versionIdToConfirm, setVersionIdToConfirm] = useState('')
    const [versionIdMismatch, setVersionIdMismatch] = useState(false)
    const [deployed, setDeployed] = useState(false)

    return (
        <ModalContent
            titleText='Publish module to production'
            buttons={[
                <Button
                    key='close-publish-prod-modal'
                    dataTestId='close-publish-prod-modal'
                    onClick={props.close}
                >
                    Cancel
                </Button>,
                <SelfServiceButtons
                    key={'self-service-buttons'}
                    versionId={props.versionId}
                    versionIdToConfirm={versionIdToConfirm}
                    setIsInProgress={setIsInProgress}
                    isInProgress={isInProgress}
                    setVersionIdMismatch={setVersionIdMismatch}
                    deployed={deployed}
                    onClose={props.onClose}
                    close={props.close}
                />,
            ]}
            maxWidth='60vw'
        >
            <SelfServicePublishingContent
                workflowDefinitionURL={props.workflowDefinitionURL}
                versionIdToConfirm={versionIdToConfirm}
                setVersionIdToConfirm={setVersionIdToConfirm}
                isInProgress={isInProgress}
                versionIdMismatch={versionIdMismatch}
                setVersionIdMismatch={setVersionIdMismatch}
                entityName={'module'}
            />

            <ModuleDeploymentProgress
                moduleVersionId={props.versionId}
                isInProgress={isInProgress}
                targetStage={ModuleDeploymentTargetStage.PRODUCTION}
                author={Authenticator.getDefaultUser()}
                comments={''}
                setDeployedStatus={setDeployed}
            />
        </ModalContent>
    )
}

export interface InitiateUATApprovalModalContentProps {
    error: string
    workflowUrl: string
    setWorkflowUrl: (workflowUrl: string) => void
    description: string
    setDescription: (description: string) => void
    submitting: boolean
    setReviewers: (reviewers: string[]) => void
    submit: () => void
    close: () => void
    workflowUrlError: boolean
    setWorkflowUrlError: (workflowUrlError: boolean) => void
    descriptionError: boolean
    setDescriptionError: (descriptionError: boolean) => void
}

// Core logic and buttons for initiate UAT Approval modal that is shared between different types.
export const InitiateUATApprovalModalContent = (props: InitiateUATApprovalModalContentProps) => {
    const {
        error,
        workflowUrl,
        setWorkflowUrl,
        description,
        setDescription,
        submitting,
        setReviewers,
        submit,
        close,
        workflowUrlError,
        setWorkflowUrlError,
        descriptionError,
        setDescriptionError,
    } = props

    const validateAndSubmit = () => {
        if (workflowUrl.length === 0) {
            setWorkflowUrlError(true)
        }
        if (description.length === 0) {
            setDescriptionError(true)
        }

        if (workflowUrl.length === 0 || description.length === 0) {
            return
        }
        submit()
    }

    return (
        <ModalContent
            titleText='Initiate UAT Sign-off Approval Process'
            buttons={[
                <Button key={0} data-test-id='initiate-uat-approval-close' onClick={close}>
                    Cancel
                </Button>,
                <Button
                    key={1}
                    dataTestId='initiate-uat-approval-submit'
                    variant={ButtonVariant.Primary}
                    onClick={validateAndSubmit}
                >
                    Submit
                </Button>,
            ]}
            maxWidth='80vw'
        >
            {error && (
                <MessageBanner dataTestId={'review-submit-error'} type={MessageBannerType.Error}>
                    {error}
                </MessageBanner>
            )}
            <Col gridGap='S400' minWidth='240px' width='50vw' height='40vh'>
                {submitting && <Spinner />}
                <InputWrapper
                    id='initiate-uat-approval-workflow-url-input'
                    labelText='Workflow Definition URL'
                    error={workflowUrlError}
                    footer={workflowUrlError ? 'Workflow Definition URL is required.' : undefined}
                    required={true}
                >
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            value={workflowUrl}
                            dataTestId='initiate-uat-approval-workflow-url-input'
                            onChange={(e) => {
                                setWorkflowUrlError(false)
                                setWorkflowUrl(e.target.value)
                            }}
                        />
                    )}
                </InputWrapper>

                <InputWrapper
                    id='initiate-uat-approval-description'
                    labelText='Description'
                    error={descriptionError}
                    footer={descriptionError ? 'Description is required.' : undefined}
                    required={true}
                >
                    {(inputProps) => (
                        <TextArea
                            {...inputProps}
                            value={description}
                            dataTestId='initiate-uat-approval-description-input'
                            onChange={(e) => {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }}
                        />
                    )}
                </InputWrapper>

                <InputWrapper id='initiate-uat-approval-reviewers' labelText='Reviewers'>
                    {(inputProps) => (
                        <Select
                            {...inputProps}
                            data-test-id='initiate-uat-approval-reviewers-select'
                            options={getReviewersList(Authenticator.getDefaultUserName())}
                            defaultValue={[]}
                            isMulti={true}
                            onChange={setReviewers}
                            placeholder='Select reviewers'
                            getTriggerText={(numberOfSelectedItems) =>
                                numberOfSelectedItems === 1
                                    ? '1 reviewer selected'
                                    : `${numberOfSelectedItems} reviewers selected`
                            }
                            listMaxHeight='20vh'
                        />
                    )}
                </InputWrapper>
            </Col>
        </ModalContent>
    )
}

export interface InitiateModuleUATApprovalModalProps {
    moduleVersionId: string
    onReviewCreated: (uatReview: UATReviewDTO) => void
    closeModal: () => void
}

export const InitiateModuleUATApprovalModal = (props: InitiateModuleUATApprovalModalProps) => {
    const [workflowUrl, setWorkflowUrl] = useState('')
    const [description, setDescription] = useState('')
    const [reviewers, setReviewers] = useState<string[]>([])

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState('')
    const [workflowUrlError, setWorkflowUrlError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const submit = async () => {
        try {
            const input: InitiateUATApprovalProcessInput = {
                versionId: props.moduleVersionId,
                requester: Authenticator.getDefaultUser(),
                workflowDefinitionUrl: workflowUrl,
                description: description,
                reviewers: reviewers,
                reviewUrl: window.location.origin.concat(`/module/viewer/${props.moduleVersionId}`),
            }
            setSubmitting(true)
            const res: UATReviewDTO = await ApprovalService.initiateUATApprovalProcess(input)
            setSubmitting(false)

            props.onReviewCreated(res)
            props.closeModal()
        } catch (e: unknown) {
            console.error('Error while requesting review: ', e)
            console.log(
                (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
            )

            setSubmitting(false)
            setError(
                `Error while initiating UAT approval process: ${
                    (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
                }`
            )
        }
    }

    return (
        <InitiateUATApprovalModalContent
            error={error}
            workflowUrl={workflowUrl}
            setWorkflowUrl={setWorkflowUrl}
            description={description}
            setDescription={setDescription}
            submitting={submitting}
            setReviewers={setReviewers}
            submit={submit}
            close={props.closeModal}
            descriptionError={descriptionError}
            setDescriptionError={setDescriptionError}
            workflowUrlError={workflowUrlError}
            setWorkflowUrlError={setWorkflowUrlError}
        />
    )
}

export interface UATApprovalComponentsProps {
    moduleVersionId: string
    workflowDefinitionUrl?: string
    uatApprovals?: ApprovalDTO[]
    onApprovalChange: () => void
    reviewId?: string
    onDeployModalClose: () => void
    setSubmitting: (submitting: boolean) => void
    submitting: boolean
    revisionNumber?: string
    renderPublishingModal: RenderModalFunction
}

// Core logic and buttons for the UAT Approval process that is shared between different types (module, module groups, etc.)
export const UATApprovalComponents = (props: UATApprovalComponentsProps) => {
    const userId = Authenticator.getDefaultUser()
    const showRevokeButton =
        props.uatApprovals &&
        props.uatApprovals.find(
            (approval) =>
                approval.approvalStatus === ApprovalStatus.Approved && approval.reviewer === userId
        )

    const isApproved = () => {
        let approvalNumber = 0
        props.uatApprovals?.forEach((review) => {
            if (review.approvalStatus === 'APPROVED') {
                ++approvalNumber
            }
        })
        return props.uatApprovals && approvalNumber >= 2
    }

    return (
        <>
            {props.submitting && <Spinner />}
            {showRevokeButton ? (
                <Button
                    dataTestId='uat-sign-off-revoke-button'
                    onClick={async () => {
                        props.setSubmitting(true)
                        await ApprovalService.putApproval({
                            reviewId: props.reviewId ?? '',
                            reviewer: Authenticator.getDefaultUser(),
                            approvalStatus: ApprovalStatus.Revoked,
                            revisionNumber: props.revisionNumber ?? '1',
                        })
                        props.setSubmitting(false)
                        props.onApprovalChange()
                    }}
                >
                    Revoke UAT Sign-off
                </Button>
            ) : (
                <Button
                    dataTestId='uat-sign-off-approve-button'
                    onClick={async () => {
                        props.setSubmitting(true)
                        await ApprovalService.putApproval({
                            reviewId: props.reviewId ?? '',
                            reviewer: Authenticator.getDefaultUser(),
                            approvalStatus: ApprovalStatus.Approved,
                            revisionNumber: props.revisionNumber ?? '1',
                        })
                        props.setSubmitting(false)
                        props.onApprovalChange()
                    }}
                >
                    Approve UAT Sign-off
                </Button>
            )}

            <WithModal
                renderModal={props.renderPublishingModal}
                onClose={() => props.onDeployModalClose()}
            >
                {({ open }) => (
                    <Button
                        dataTestId='open-publish-module-to-prod-modal-button'
                        aria-disabled={!isApproved()}
                        aria-describedby={
                            'Need approvals to publish module to production environment'
                        }
                        onClick={open}
                    >
                        Publish to production
                    </Button>
                )}
            </WithModal>
        </>
    )
}

export interface UATModuleApprovalComponentsProps {
    moduleVersionId?: string
    uatReview?: UATReviewDTO
    moduleStatus: ModuleStatus
    uatApprovals?: ApprovalDTO[]
    onApprovalChange: () => void
    onReviewCreated: (uatReview: UATReviewDTO) => void
    onDeployModalClose: () => void
}

export const UATModuleApprovalComponents = (props: UATModuleApprovalComponentsProps) => {
    const [submitting, setSubmitting] = useState(false)

    if (props.moduleStatus !== ModuleStatus.UAT || !props.moduleVersionId) {
        return null
    }

    const moduleVersionId = props.moduleVersionId ?? ''

    const renderModulePublishingModal = ({ close }: { close: () => void }) => {
        return (
            <SelfServiceModulePublishingComponent
                versionId={moduleVersionId}
                workflowDefinitionURL={props.uatReview?.workflowDefinitionUrl || ''}
                close={close}
                onClose={props.onDeployModalClose}
            />
        )
    }

    const renderInitiateUATApprovalModal = ({ close }: { close: () => void }) => {
        return (
            <InitiateModuleUATApprovalModal
                moduleVersionId={moduleVersionId}
                onReviewCreated={props.onReviewCreated}
                closeModal={close}
            />
        )
    }

    if (!props.uatReview) {
        return (
            <WithModal renderModal={renderInitiateUATApprovalModal}>
                {({ open }) => (
                    <Button dataTestId='initiate-uat-approval-process-button' onClick={open}>
                        Initiate UAT approval process
                    </Button>
                )}
            </WithModal>
        )
    }

    return (
        <UATApprovalComponents
            moduleVersionId={moduleVersionId}
            onApprovalChange={props.onApprovalChange}
            onDeployModalClose={props.onDeployModalClose}
            setSubmitting={setSubmitting}
            submitting={submitting}
            uatApprovals={props.uatApprovals}
            workflowDefinitionUrl={props.uatReview?.workflowDefinitionUrl || ''}
            reviewId={props.uatReview?.reviewId || ''}
            renderPublishingModal={renderModulePublishingModal}
        />
    )
}

export const UATApprovalBanner = (props: { uatApprovals: ApprovalDTO[] }) => {
    const approvals = props.uatApprovals.filter(
        (approval) => approval.approvalStatus === ApprovalStatus.Approved
    )

    if (approvals.length === 0) {
        return (
            <MessageBanner
                type={MessageBannerType.Error}
                dataTestId={'uat-approval-banner-require-two-approvals'}
            >
                Requires at least two approvals to publish module
            </MessageBanner>
        )
    }

    const uatReviewers = approvals.map((approval) => approval.reviewer).join(', ')
    if (props.uatApprovals.length > 1) {
        return (
            <MessageBanner
                type={MessageBannerType.Success}
            >{`UAT sign-off is approved by ${uatReviewers}`}</MessageBanner>
        )
    } else {
        return (
            <MessageBanner
                type={MessageBannerType.Warning}
                dataTestId={'uat-approval-banner-require-one-more-approval'}
            >{`UAT sign-off is approved by ${uatReviewers}. Need one more approval to publish module`}</MessageBanner>
        )
    }
}

export const ModuleUATApprovalBanner = (props: {
    moduleStatus: ModuleStatus
    uatApprovals?: ApprovalDTO[]
}) => {
    if (!props.uatApprovals || props.moduleStatus !== ModuleStatus.UAT) {
        return null
    }

    return <UATApprovalBanner uatApprovals={props.uatApprovals} />
}

export const ModuleGroupUATApprovalBanner = (props: {
    moduleGroupStatus: ModuleGroupStatus
    uatApprovals?: ApprovalDTO[]
}) => {
    const isUATStage =
        ModuleGroupStatus[props.moduleGroupStatus as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW ||
        ModuleGroupStatus[props.moduleGroupStatus as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW_APPROVED

    if (!props.uatApprovals || !isUATStage) {
        return null
    }
    return <UATApprovalBanner uatApprovals={props.uatApprovals} />
}
