import React from 'react'

import { View } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

export const ModuleGroupRenderOption = (option) => (
    <View height='25px'>
        <Text textAlign='center'>{option || '(Empty)'}</Text>
    </View>
)
