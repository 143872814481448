import React from 'react'

import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { Features } from 'src/config.app'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { isFeatureEnabled } from 'src/helper/featureFlagHelper'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    AudioEvaluationItemDTO,
    ScoringMLModelType,
    TranscriptionMLModelType,
} from 'src/models/dto/items/AudioEvaluationItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    GenericItemEditorSelectInput,
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { AudioEvaluationHandler } from 'src/services/EntityServices/ItemUpdateHandlers/AudioEvaluationHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const transcriptionModelTypeDisplayNames = new Map<TranscriptionMLModelType, string>([
    [TranscriptionMLModelType.ACE_SALES_TRANSCRIPTION_MODEL, 'Ace Sales Transcription'],
    [TranscriptionMLModelType.AA_Sales_Transcribe_en_US, 'AA Sales Transcribe EN US'],
])

const scoringModelTypeDisplayNames = new Map<ScoringMLModelType, string>([
    [ScoringMLModelType.AA_ACE_Sales_NLP_Scoring, 'Ace Sales NLP Scoring'],
    [ScoringMLModelType.ACE_SALES_SCORING_MODEL, 'AA Sales Scoring'],
    [ScoringMLModelType.AA_Sales_RoW, 'AA Sales RoW'],
    [ScoringMLModelType.AA_Sales_NATAM, 'AA Sales NATAM'],
    [ScoringMLModelType.Customer_Facing, 'Customer Facing'],
    ...(isFeatureEnabled(Features.LLM_MIXTRAL_CRA_SCORING)
        ? [[ScoringMLModelType.LLM_Mixtral_CRA_Scoring, 'LLM_Mixtral_CRA_Scoring'] as const]
        : []),
])

export const getOptions = <T,>(displayNames: Map<T, string>): string[] => {
    return Array.from(displayNames.values())
}

const transcriptionsModelTypeOptions: string[] = getOptions(transcriptionModelTypeDisplayNames)
const scoringModelTypeOptions: string[] = getOptions(scoringModelTypeDisplayNames)

export const disallowedMlModelVersionsForSoundCheck = new Set(['1.0'])
export const mlModelVersionsOptions: string[] = ['1.0', '2.0']
export const mlModelVersionsOptionsForTranscription: string[] = ['1.0']
export const mlModelVersionsOptionsForScoring: string[] = ['1.0', '1.1', '2.0', '3.0']
export const maxNumAttemptsOptions: string[] = ['1', '2', '3', '4', '5']
export const maxRecordingTimeOptions: string[] = [
    '15',
    '30',
    '45',
    '60',
    '75',
    '90',
    '105',
    '120',
    '135',
    '150',
    '165',
    '180',
    '195',
    '210',
    '225',
    '240',
    '255',
    '270',
    '285',
    '300',
]

const transcriptionDisplayNamesToValues: Map<string, TranscriptionMLModelType> = new Map<
    string,
    TranscriptionMLModelType
>(
    Array.from(transcriptionModelTypeDisplayNames).map((rec) => {
        rec.reverse()
        return rec as [string, TranscriptionMLModelType]
    })
)

const scoringDisplayNamesToValues: Map<string, ScoringMLModelType> = new Map<
    string,
    ScoringMLModelType
>(
    Array.from(scoringModelTypeDisplayNames).map((rec) => {
        rec.reverse()
        return rec as [string, ScoringMLModelType]
    })
)

export const maxNumAttemptsToValues: Map<string, number> = new Map<string, number>(
    Array.from(maxNumAttemptsOptions).map((rec) => {
        return [rec, +rec] as [string, number]
    })
)

export const maxNumAttemptsToName: Map<number, string> = new Map<number, string>(
    Array.from(maxNumAttemptsOptions).map((rec) => {
        return [+rec, rec] as [number, string]
    })
)

export const maxRecordingToValues: Map<string, number> = new Map<string, number>(
    Array.from(maxRecordingTimeOptions).map((rec) => {
        return [rec, +rec] as [string, number]
    })
)

export const maxRecordingToName: Map<number, string> = new Map<number, string>(
    Array.from(maxRecordingTimeOptions).map((rec) => {
        return [+rec, rec] as [number, string]
    })
)

export const mlModelVersionsToValues: Map<string, string> = new Map<string, string>(
    Array.from(mlModelVersionsOptions).map((rec) => {
        return [rec, rec] as [string, string]
    })
)

export const mlModelVersionsToValuesForScoring: Map<string, string> = new Map<string, string>(
    Array.from(mlModelVersionsOptionsForScoring).map((rec) => {
        return [rec, rec] as [string, string]
    })
)

export const mlModelVersionsToValuesForTranscription: Map<string, string> = new Map<string, string>(
    Array.from(mlModelVersionsOptionsForTranscription).map((rec) => {
        return [rec, rec] as [string, string]
    })
)

export const AudioEvaluationItemEditor = ({
    itemId,
    locale,
    showValidationError,
    workflowEntityId,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<AudioEvaluationItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    const { matches } = useBreakpoints()

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const flexItemWidth = matches.s ? '100%' : 'default'
    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '200px'
    const CHECKBOX_FLEX_BASIS = `0 0 ${matches.s ? '100%' : '80px'}`
    const SELECT_INPUT_FLEX_BASIS = `2 0 ${matches.s ? '100%' : '200px'}`

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '30px'}` }}
                    flex={CHECKBOX_FLEX_BASIS}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'experimental'}
                            dataTestId={'experimental-checkbox'}
                            itemId={itemDTO.id}
                            labelText={'Experimental'}
                            value={itemDTO.experimental}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            setValue={(nextValue: boolean) => {
                                AudioEvaluationHandler.updateExperimental(itemId, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='Is this item used for research and not being scored?' />
                    </Row>
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={CHECKBOX_FLEX_BASIS}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            AudioEvaluationHandler.updateOptional(itemId, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText={'Preserve order'}
                            dataTestId={'preserve-order-checkbox'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                AudioEvaluationHandler.updatePreserveOrder(itemId, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxNumAttemptsToName}
                        inputId={'audio-eval-max-attempts'}
                        dataTestId={'max-number-of-attempts'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Attempts'}
                        options={maxNumAttemptsOptions}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        displayNamesToValues={maxNumAttemptsToValues}
                        value={itemDTO.maxNumberOfAttempts}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateMaxNumberOfAttempts(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxNumberOfAttempts ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxRecordingToName}
                        inputId={'audio-eval-max-recording-time'}
                        dataTestId={'max-recording-time-in-seconds'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Recording Time (sec)'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={maxRecordingTimeOptions}
                        displayNamesToValues={maxRecordingToValues}
                        value={itemDTO.maxRecordingTimeInSeconds}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateMaxRecordingTimeInSeconds(
                                itemId,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxRecordingTimeInSeconds ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={transcriptionModelTypeDisplayNames}
                        inputId={'audio-eval-transcription-ml-model'}
                        dataTestId={'transcription-ml-model'}
                        itemId={itemDTO.id}
                        labelText={'Transcription ML Model'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={transcriptionsModelTypeOptions}
                        displayNamesToValues={transcriptionDisplayNamesToValues}
                        value={itemDTO.transcriptionMLModel}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateTranscriptionMlModel(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.transcriptionMLModel ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={mlModelVersionsToValues}
                        inputId={'audio-eval-transcription-ml-model-version'}
                        dataTestId={'transcription-ml-model-version'}
                        itemId={itemDTO.id}
                        labelText={'Transcription ML Model Version'}
                        options={mlModelVersionsOptionsForTranscription}
                        displayNamesToValues={mlModelVersionsToValuesForTranscription}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.transcriptionMLModelVersion}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateTranscriptionMlModelVersion(
                                itemId,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.transcriptionMLModelVersion ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={scoringModelTypeDisplayNames}
                        inputId={'audio-eval-scoring-ml-model'}
                        dataTestId={'scoring-ml-model'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        labelText={'Scoring ML Model'}
                        options={scoringModelTypeOptions}
                        displayNamesToValues={scoringDisplayNamesToValues}
                        value={itemDTO.scoringMLModel}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateScoringMlModel(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.scoringMLModel ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={mlModelVersionsToValuesForScoring}
                        inputId={'audio-eval-scoring-ml-model-version'}
                        dataTestId={'scoring-ml-model-version'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        labelText={'Scoring ML Model Version'}
                        options={mlModelVersionsOptionsForScoring}
                        displayNamesToValues={mlModelVersionsToValuesForScoring}
                        value={itemDTO.scoringMLModelVersion}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateScoringMlModelVersion(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.scoringMLModelVersion ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'situation'}
                        locale={locale}
                        dataTestId={'audio-evaluation-situation-editor'}
                        value={itemDTO.situationI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioEvaluationHandler.updateSituation(itemId, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.situationI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Situation'}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'task'}
                        locale={locale}
                        dataTestId={'audio-evaluation-task-editor'}
                        value={itemDTO.taskI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioEvaluationHandler.updateTask(itemId, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.taskI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Task'}
                    />
                </Col>
            </Row>
        </>
    )
}
