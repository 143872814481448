import { PreviewTTL, usePreview, UsePreviewResults } from 'src/hooks/usePreview'
import { ITEM_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ItemEntityService'

export function useItemPreview({
    itemId,
    onPreviewUnexpectedError,
}: {
    itemId: string
    onPreviewUnexpectedError?: () => void
}) {
    const usePreviewResults: UsePreviewResults = usePreview({
        entityId: itemId,
        saved: false,
        entityStoreSelector: ITEM_ENTITY_STORE_SELECTOR,
        onPreviewUnexpectedError: onPreviewUnexpectedError,
    })
    return {
        ...usePreviewResults,
        executePreview: () => usePreviewResults.executePreview(PreviewTTL.THIRTY_MINUTES),
    }
}
