import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Col, Container, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2 } from '@amzn/stencil-react-components/text'

import { LocaleDropdown, LocaleSelectorContainer } from 'src/components/LocaleSelector'
import { MastheadContainer } from 'src/components/MastheadContainer'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/components/ModuleDisplayTable'
import { LastModifiedSubtitle } from 'src/components/ModuleMasthead'
import { ModulePreview } from 'src/components/ModulePreview'
import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { useTitle } from 'src/hooks/useTitle'
import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { CommentDTO, LocationId } from 'src/models/dto/approval/CommentDTO'
import { ApprovalProcessActionInput } from 'src/models/dto/approval/ReviewDTO'
import { ApprovalStatus } from 'src/models/dto/ApprovalStatus'
import { Locale } from 'src/models/dto/Locale'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import {
    useEnsureModuleReviewRouteConsistency,
    useLoadModuleReviewDataBasedOnParams,
} from 'src/pages/module-review/hooks'
import { moduleViewerRoute, useAsyncWithReload } from 'src/pages/module-review/index'
import { ModuleApprovalMessage } from 'src/pages/module-review/ModuleApprovalMessage'
import { ModuleReviewBox } from 'src/pages/module-review/ModuleReviewBox'
import { ModuleReviewError } from 'src/pages/module-review/ModuleReviewError'
import { ModuleReviewPageButtons } from 'src/pages/module-review/ModuleReviewPageButtons'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { Authenticator } from 'src/services/Authenticator'
import { ModuleService } from 'src/services/backend/ModuleService'

const pageTimer = new TimerStopwatch('ModuleReviewPageTimer')
const REVIEW_STAGE_NAME = 'REVIEW'

export const ModuleReviewPage = () => {
    const params = useParams()
    const { error: reviewError } = useEnsureModuleReviewRouteConsistency()
    const reviewData = useLoadModuleReviewDataBasedOnParams()
    useTitle(
        () =>
            `Rev ${params.revisionNumber ?? ''} - ${
                reviewData.module?.name ?? '<unknown>'
            } - Module Review`,
        [reviewData.module, params.revisionNumber]
    )

    const showReview = true
    const { moduleVersionId = '', reviewId = '', revisionNumber = '1' } = params
    const {
        review,
        module: moduleDTO,
        lastModified,
        selectedRev,
        stageApprovalList,
        approverMembers,
        error: loadError,
        isLoading,
    } = reviewData
    const layoutDirection = useLayoutDirection()
    const navigate = useNavigate()
    const [previewError, setPreviewError] = useState<string | null>(null)
    const [locale, setLocale] = useState<Locale>(Locale.en_US)

    const syncInProgress = lastModified.status === ModuleStatus.REVIEW_APPROVED

    const approvalAllowed =
        approverMembers !== null
            ? approverMembers.some((m) => m.alias === Authenticator.getDefaultUserName())
            : true

    const stageReviewers = approverMembers
        ? approverMembers.filter((s) => s.assigned).map((m) => m.alias)
        : undefined

    const changeRevision = useCallback(
        (rev: string) => {
            navigate(moduleViewerRoute({ moduleVersionId, reviewId, revisionNumber: rev }))
        },
        [moduleVersionId, navigate, reviewId]
    )

    const error = reviewError || loadError
    const [approvals, setApprovals] = useState<ApprovalDTO[]>([])

    const [comments, setComments] = useState<CommentDTO[]>()
    const reloadCommentsRef = useRef<() => Promise<void>>(() => Promise.resolve())
    const reloadComments0 = useCallback(
        async (didCancel: { value: boolean }) => {
            if (!review) {
                return
            }

            setComments([])
            const res = await ApprovalService.listAllCommentsForReview(review)
            if (!didCancel.value) {
                setComments(res || [])
            }
        },
        [review]
    )
    const [isCommentsLoading, setIsCommentsLoading] = useState(false)
    const [_commentsError, setCommentsError] = useState<unknown>(null)
    useEffect(() => {
        const didCancel: { value: boolean } = { value: false }
        reloadCommentsRef.current = async () => {
            try {
                setIsCommentsLoading(true)
                await reloadComments0(didCancel)
            } catch (e: unknown) {
                if (!didCancel.value) {
                    setCommentsError(e)
                }
            } finally {
                if (!didCancel.value) {
                    setIsCommentsLoading(false)
                }
            }
        }
        return () => {
            didCancel.value = true
        }
    }, [reloadComments0, reloadCommentsRef])

    const [reloads, induceCommentReload] = useReducer((x: number) => x + 1, 0)
    const reloadComments = reloadCommentsRef.current

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.ModuleReview, CommonUserEventKeys.Open)

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.ModuleReview, pageTimer.value)
        }
    }, [])

    useEffect(() => {
        if (moduleDTO) {
            ModuleService.populateModule(moduleDTO)
        }
    }, [moduleDTO])

    useEffect(() => {
        void reloadComments()
    }, [reloads, reloadComments])

    useEffect(() => {
        if (reloads === 0 && review) induceCommentReload()
    }, [reloads, review])

    const { data: approvalsList, reload: reloadApprovalsList } = useAsyncWithReload(() => {
        // Extract and return stage approvers list if Fast Track process
        if (stageApprovalList) {
            return new Promise<ApprovalDTO[]>((resolve) => resolve(stageApprovalList))
        }

        // In the case that review is using the old process, we must fetch from the approversList from Approval Service
        return ApprovalService.listApproval({ reviewId, revisionNumber })
    }, [reviewId, revisionNumber])

    useEffect(() => {
        if (review) {
            reloadApprovalsList()
        }
    }, [reloadApprovalsList, review])

    useEffect(() => {
        setApprovals(
            (approvalsList ?? []).filter((a) => a.approvalStatus === ApprovalStatus.Approved)
        )
    }, [approvalsList])

    const [submittingApproval, setSubmittingApproval] = useState(false)

    const approve = useCallback(() => {
        return ApprovalService.putApproval({
            reviewId,
            revisionNumber,
            reviewer: Authenticator.getDefaultUser(),
            approvalStatus: ApprovalStatus.Approved,
        }).then(reloadApprovalsList)
    }, [reloadApprovalsList, reviewId, revisionNumber])

    const revoke = useCallback(() => {
        return ApprovalService.putApproval({
            reviewId,
            revisionNumber,
            reviewer: Authenticator.getDefaultUser(),
            approvalStatus: ApprovalStatus.Revoked,
        }).then(reloadApprovalsList)
    }, [reloadApprovalsList, reviewId, revisionNumber])

    const approveFastTrack = useCallback(() => {
        setSubmittingApproval(true)
        return ApprovalService.approvalProcessAction({
            versionId: moduleDTO.version,
            reviewId,
            revisionNumber,
            stageName: REVIEW_STAGE_NAME,
            reviewer: Authenticator.getDefaultUser(),
            comment: '',
            approvalStatus: ApprovalStatus.Approved,
        } as ApprovalProcessActionInput).then((res) => {
            setApprovals(res.approvals.filter((a) => a.approvalStatus === ApprovalStatus.Approved))
            setSubmittingApproval(false)
            if (res.status === 'APPROVED') {
                window.location.reload()
            }
        })
    }, [moduleDTO.version, reviewId, revisionNumber])

    const revokeFastTrack = useCallback(() => {
        return ApprovalService.approvalProcessAction({
            reviewId,
            revisionNumber,
            stageName: REVIEW_STAGE_NAME,
            reviewer: Authenticator.getDefaultUser(),
            comment: '',
            approvalStatus: ApprovalStatus.Revoked,
        } as ApprovalProcessActionInput).then((res) => {
            setApprovals(res.approvals.filter((a) => a.approvalStatus === ApprovalStatus.Approved))
        })
    }, [reviewId, revisionNumber])

    const isApproved = useMemo(() => {
        return approvals.some(
            (a) =>
                a.approvalStatus === ApprovalStatus.Approved &&
                a.reviewer === Authenticator.getDefaultUser()
        )
    }, [approvals])

    const approvers = useMemo(() => approvals.map((a) => a.reviewer), [approvals])

    // useEffect(() => {
    //     publishUserEventMetrics(UserEventMethodNames.ModuleReview, 'open')
    //     pageTimer.start()
    //     return () => {
    //         pageTimer.stop()
    //         publishTimeSpentMetrics(UserEventMethodNames.ModuleReview, pageTimer.value)
    //     }
    // }, [])

    const commentsByLocation: Record<LocationId, CommentDTO[]> = React.useMemo(
        () => _.groupBy(comments, (x) => x.locationId ?? ''),
        [comments]
    )

    const context = useMemo(
        () => ({
            reviewId,
            revisionNumber,
            comments,
            commentsByLocation,
            isCommentsLoading,
            reloadComments,
        }),
        [reviewId, revisionNumber, comments, commentsByLocation, isCommentsLoading, reloadComments]
    )

    const moduleStatus = lastModified.status
    const fastTrackReview =
        moduleStatus === ModuleStatus.REVIEW_MAJOR || moduleStatus === ModuleStatus.REVIEW_MINOR
    const fastTrackProcess = selectedRev && selectedRev.stages && selectedRev.stages.length > 0

    if (isLoading) {
        return (
            <Container
                backgroundColor='#f2f2f2'
                minHeight='100vh'
                className='module-preview-container'
            >
                <Col gridGap='S200'>
                    <Row justifyContent='center' gridGap='S200' margin='S200'>
                        <Spinner />
                    </Row>
                </Col>
            </Container>
        )
    }
    const errorPart = (
        <ModuleReviewError {...{ review, error, revisionNumber, isLoading, previewError }} />
    )

    return (
        <ModuleReviewContext.Provider value={context}>
            {module && (
                <Col
                    backgroundColor='neutral0'
                    width='100%'
                    margin={0}
                    padding={0}
                    dataTestId='module-review-page'
                >
                    <MastheadContainer>
                        <View>
                            <H2 fontSize='T500'>{moduleDTO.name}</H2>
                            {review && <LastModifiedSubtitle lastModified={lastModified} />}
                        </View>
                        <ModuleReviewPageButtons
                            {...{
                                review,
                                revisionNumber,
                                moduleDTO,
                                changeRevision,
                                setPreviewError,
                                approve: fastTrackReview ? approveFastTrack : approve,
                                revoke: fastTrackReview ? revokeFastTrack : revoke,
                                isApproved,
                                moduleStatus,
                                approvalAllowed,
                                submittingApproval,
                                fastTrackProcess,
                            }}
                        />
                    </MastheadContainer>
                    <LocaleSelectorContainer>
                        <LocaleDropdown
                            id='module-review-locales'
                            {...{ locale, setLocale }}
                            options={moduleDTO.availableLocales}
                        />
                    </LocaleSelectorContainer>
                </Col>
            )}
            <Container
                backgroundColor='neutral05'
                width='100%'
                paddingHorizontal={0}
                minHeight='100vh'
            >
                {syncInProgress && fastTrackProcess && (
                    <MessageBanner isDismissible={true} type={MessageBannerType.Informational}>
                        {
                            'Module synchronization is in progress, please refresh the page for the latest status.'
                        }
                    </MessageBanner>
                )}
                <Spacer height='S300' />
                <Col gridGap='S300'>
                    {errorPart}
                    {approvals?.length ? (
                        <ModuleApprovalMessage
                            approvalStatus={ApprovalStatus.Approved}
                            reviewers={approvers}
                        />
                    ) : null}
                </Col>
                <Spacer height='S300' />
                {module && (
                    <Flex
                        gridGap='S400'
                        flexDirection={
                            layoutDirection === LAYOUT_DIRECTION.DESKTOP ? 'row' : 'column'
                        }
                        padding={
                            layoutDirection === LAYOUT_DIRECTION.DESKTOP
                                ? { left: 'S400', right: 'S400' }
                                : undefined
                        }
                    >
                        <View flex='1'>
                            <ModulePreview {...{ moduleDTO, locale }} />
                        </View>
                        {showReview && (
                            <View
                                flex='1'
                                maxWidth={
                                    layoutDirection === LAYOUT_DIRECTION.DESKTOP ? '420px' : '100%'
                                }
                            >
                                {review && (
                                    <ModuleReviewBox
                                        {...{
                                            moduleVersionId,
                                            review,
                                            revisionNumber,
                                            stageReviewers,
                                        }}
                                    />
                                )}
                            </View>
                        )}
                    </Flex>
                )}
            </Container>
        </ModuleReviewContext.Provider>
    )
}
