import { LocalizedAttribute } from 'src/models/dto/Locale'

export enum MediaLayoutType {
    StaticView = 'StaticView',
}

export enum MimeTypes {
    'image/png' = 'image/png',
    'image/gif' = 'image/gif',
    'image/jpeg' = 'image/jpeg',
    'audio/mpeg' = 'audio/mpeg',
    'video/mp4' = 'video/mp4',
}

export interface MediaLayout {
    type: MediaLayoutType
    layoutName: string
}

export interface MediaContent {
    id: string
    relativePathI18N: LocalizedAttribute<string>
    mimeType: string
    mediaLabel: string
    locale?: string
    altTextI18N: LocalizedAttribute<string>
    captionPathI18N?: LocalizedAttribute<string>
    transcriptPathI18N?: LocalizedAttribute<string>
    optional?: boolean
}

export interface DeliveryVersionId {
    versionId: string
}

export type MediaTemplateVersionIds = Partial<LocalizedAttribute<DeliveryVersionId>>

export interface MediaManagerMedia {
    mediaManagerVersionId: string
    mediaTemplateVersionIds: MediaTemplateVersionIds
}

export interface Medias {
    mediaList: MediaContent[]
    layout: MediaLayout
    mediaManagerMediaVersionIds?: MediaManagerMedia[]
}

/**
 * if an item props contains media, we just need to extend
 * this interface
 */
export interface MediaElement {
    localeWiseMedia?: Medias
}

export const defaultMedias = () =>
    ({
        layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
        mediaList: [],
    } as Medias)
