import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Col, Hr, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'

import { TextAreaWithCharacterLimit } from 'src/components/TextAreaWithCharacterLimit'
import {
    createInitialModuleSubstituteScore,
    MESGroupDTO,
    PrimaryScoreLabelWithSubstitute,
    SubstituteModuleScore,
} from 'src/models/dto/module-groups/MESGroupDTO'
import {
    ModuleGroupDTO,
    ModuleGroupModuleMetadata,
    ModuleGroupType,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import {
    MESValidationDTO,
    PECValidationDTO,
} from 'src/models/dto/module-groups/ModuleGroupValidations'
import {
    FunctionalScoreDTO,
    ModuleVersionRuleSetDTO,
    PECGroupDTO,
    RuleDTO,
    RuleSetCategory,
    RuleSetDTO,
    RuleType,
} from 'src/models/dto/module-groups/PECGroupDTO'
import { MESModuleGroupAddModule } from 'src/pages/module-groups/MESModuleGroupAddModule'
import { sortRulesByPriority } from 'src/pages/module-groups/ModuleGroupHelper'
import { ModuleGroupUtility } from 'src/pages/module-groups/ModuleGroupUtility'
import { ModuleRuleButtons } from 'src/pages/module-groups/ModuleRuleButtons'
import { ModuleVersionSelectAndDisplay } from 'src/pages/module-groups/ModuleVersionSelectAndDisplay'
import { PECModuleGroupAddModule } from 'src/pages/module-groups/PECModuleGroupAddModule'
import { SkipModuleToggle } from 'src/pages/module-groups/SkipModuleToggle'
import { moduleGroupViewerRoute } from 'src/pages/module-review'
import { ModuleGroupEntityService } from 'src/services/EntityServices/ModuleGroupEntityService'
import {
    ModuleGroupService,
    UpdateModuleGroupRequest,
} from 'src/services/module-groups/ModuleGroupService'

export const ModuleGroupAddModuleModal = ({
    showAddModuleModal,
    setShowAddModuleModal,
    moduleGroup,
    moduleGroupType,
    editModuleIndex,
    duplicateModuleIndex,
    setEditModuleIndex,
    setDuplicateModuleIndex,
}: {
    showAddModuleModal: boolean
    setShowAddModuleModal: (show: boolean) => void
    moduleGroup: ModuleGroupDTO
    moduleGroupType: ModuleGroupType
    editModuleIndex?: number
    duplicateModuleIndex?: number
    setEditModuleIndex: (moduleIndex: number | undefined) => void
    setDuplicateModuleIndex: (moduleIndex: number | undefined) => void
}) => {
    const [selectedModule, setSelectedModule] = useState<ModuleGroupModuleMetadata | undefined>()
    const [equivalencyDescription, setEquivalencyDescription] = useState<string>('')
    const [isOverCharacterLimit, setIsOverCharacterLimit] = useState<boolean>(false)
    const [rules, setRules] = useState<RuleDTO[]>([])
    const [moduleScores, setModuleScores] = useState<string[]>([])
    const [selectedModuleVersionId, setSelectedModuleVersionId] = useState<string>('')
    const [updatedFunctionalScores, setUpdatedFunctionalScores] = useState<
        FunctionalScoreDTO[] | undefined
    >([])
    const [updatedModuleSubstituteScore, setUpdatedModuleSubstituteScore] =
        useState<SubstituteModuleScore>(createInitialModuleSubstituteScore())
    const [updateModuleGroupError, setUpdateModuleGroupError] = useState<boolean>(false)
    const [isInUATOrMore, setIsInUATOrMore] = useState<boolean>(true)
    const [currentSubstituteScores, setCurrentSubstituteScores] = useState<
        PrimaryScoreLabelWithSubstitute[]
    >([])
    const [validationMessage, setValidationMessage] = useState<string>('')
    const [moduleValidation, setModuleValidation] = useState<string | undefined>()
    const [pecValidations, setPecValidations] = useState<PECValidationDTO | undefined>()
    const [mesValidations, setMesValidations] = useState<MESValidationDTO | undefined>()
    const [equivalencyValidation, setEquivalencyValidation] = useState<string>('')
    const [modalTitle, setModalTitle] = useState<string>('')

    const skipRuleEnabled =
        ModuleGroupUtility.isSkippedModule(selectedModule?.versionId) ||
        ModuleGroupUtility.isSkippedModule(selectedModuleVersionId)

    const getEquivalencyReasonPlaceholder = (mgType: ModuleGroupType) => {
        if (mgType === ModuleGroupType.PEC && skipRuleEnabled) {
            return 'What is the reason this job metadata requires this module to be skipped? (eg. country restrictions, module relevance, etc)?'
        }

        if (mgType === ModuleGroupType.PEC) {
            return 'How does this module vary compared to the PEC group (eg., references different LOBs, different languages allowed, etc.)?'
        }

        return 'Are these the exact same items, or different items measuring the same construct? Any important nuances (eg., shared applicant pools, data establishing equivalence, etc.)?'
    }

    const navigate = useNavigate()

    const getInitialModuleSubstituteScore = useCallback(
        (
            selectedVersionId: string,
            oldSubstituteModule: SubstituteModuleScore
        ): SubstituteModuleScore => {
            const newSubstituteScores: PrimaryScoreLabelWithSubstitute[] =
                oldSubstituteModule.substituteScores.map((substituteScore) => {
                    if (!moduleScores.includes(substituteScore.substituteScoreLabel)) {
                        return { ...substituteScore, substituteScoreLabel: '' }
                    } else {
                        return substituteScore
                    }
                })
            return {
                substituteModuleVersion: selectedVersionId,
                equivalencyReason: oldSubstituteModule.equivalencyReason,
                substituteScores: newSubstituteScores,
            }
        },
        [moduleScores]
    )

    useEffect(() => {
        moduleGroup.moduleMappings.pec?.functionalScores
            ? setUpdatedFunctionalScores(moduleGroup.moduleMappings.pec.functionalScores)
            : setUpdatedFunctionalScores([])
    }, [moduleGroup.moduleMappings.pec?.functionalScores])

    useEffect(() => {
        if (selectedModule && moduleGroup.moduleMappings.mes) {
            let alreadyExistingModuleSubScore =
                moduleGroup.moduleMappings.mes.substituteModuleScores.find(
                    (moduleSubScore) =>
                        moduleSubScore.substituteModuleVersion === selectedModule.versionId
                )
            if (!alreadyExistingModuleSubScore) {
                alreadyExistingModuleSubScore =
                    editModuleIndex === undefined
                        ? createInitialModuleSubstituteScore(selectedModule.versionId)
                        : getInitialModuleSubstituteScore(
                              selectedModule.versionId,
                              moduleGroup.moduleMappings.mes.substituteModuleScores[editModuleIndex]
                          )
            }
            setUpdatedModuleSubstituteScore(alreadyExistingModuleSubScore)
            setEquivalencyDescription(alreadyExistingModuleSubScore.equivalencyReason)
        }
    }, [
        editModuleIndex,
        getInitialModuleSubstituteScore,
        moduleGroup.moduleMappings.mes,
        moduleScores,
        selectedModule,
        updatedModuleSubstituteScore.equivalencyReason,
    ])

    useEffect(() => {
        const moduleIndex = editModuleIndex ?? duplicateModuleIndex
        if (moduleIndex !== undefined) {
            if (moduleGroupType === ModuleGroupType.MES) {
                const mesGroup = moduleGroup.moduleMappings.mes as MESGroupDTO
                setSelectedModuleVersionId(
                    mesGroup.substituteModuleScores[moduleIndex].substituteModuleVersion
                )
            } else {
                const pecGroup = moduleGroup.moduleMappings.pec as PECGroupDTO
                if (
                    ModuleGroupUtility.isSkippedModule(
                        pecGroup.moduleVersionRuleSetList[moduleIndex].moduleVersionId
                    )
                ) {
                    setSelectedModuleVersionId(ModuleGroupUtility.STATIC_SKIPPED_MODULE.versionId)
                    setSelectedModule(ModuleGroupUtility.STATIC_SKIPPED_MODULE)
                } else {
                    setSelectedModuleVersionId(
                        pecGroup.moduleVersionRuleSetList[moduleIndex].moduleVersionId
                    )
                }
                setRules(pecGroup.moduleVersionRuleSetList[moduleIndex].ruleSet.rules)
                setEquivalencyDescription(
                    pecGroup.moduleVersionRuleSetList[moduleIndex].equivalencyReason ?? ''
                )
            }
        }
    }, [
        duplicateModuleIndex,
        editModuleIndex,
        moduleGroup.moduleMappings.mes,
        moduleGroup.moduleMappings.pec,
        moduleGroup.moduleMetadataList,
        moduleGroupType,
    ])

    const cleanFields = useCallback(() => {
        setSelectedModule(undefined)
        setEquivalencyDescription('')
        setRules([])
        setModuleScores([])
        setSelectedModuleVersionId('')
        setIsOverCharacterLimit(false)
        setMesValidations(undefined)
        setPecValidations(undefined)
        setModuleValidation(undefined)
    }, [])

    useEffect(() => {
        if (!showAddModuleModal) {
            cleanFields()
        }
    }, [cleanFields, showAddModuleModal])

    const validateMESModuleAdd = () => {
        const moduleEquivalentScoresError = new Map<string, string>()
        let validationError = false
        let substituteScoresError = ''

        if (!selectedModule) {
            setModuleValidation('There must be a valid selected module id.')
            return true
        }

        if (!isInUATOrMore) {
            return true
        }

        if (isOverCharacterLimit) {
            setEquivalencyValidation('Equivalency reason must not exceed 200 characters.')
            validationError = true
        }

        if (updatedModuleSubstituteScore.substituteScores.length <= 0) {
            substituteScoresError = 'There must be at least one substitute score selected.'
            validationError = true
        }

        if (currentSubstituteScores.length > 0) {
            currentSubstituteScores.forEach((subScore) => {
                if (!subScore.substituteScoreLabel) {
                    moduleEquivalentScoresError.set(
                        subScore.primaryScoreLabel,
                        `Module equivalent score for ${subScore.primaryScoreLabel} must be selected.`
                    )
                    validationError = true
                }
            })
        }

        setMesValidations({
            substituteScores: substituteScoresError,
            moduleEquivalentScores:
                moduleEquivalentScoresError.size > 0 ? moduleEquivalentScoresError : undefined,
        })

        return validationError
    }

    const validatePECModuleAdd = () => {
        const categoriesError = new Map<RuleType, string>()
        const functionalEquivalencyError = new Map<string, string>()
        let metadataCategoriesError = ''
        let validationError = false

        if (!selectedModule) {
            setModuleValidation('There must be a valid selected module id.')
            return true
        }

        if (!isInUATOrMore) {
            return true
        }

        if (isOverCharacterLimit) {
            setEquivalencyValidation('Equivalency reason must not exceed 200 characters.')
            validationError = true
        }

        if (!(rules && rules.length > 0)) {
            metadataCategoriesError = 'At least one metadata category must be selected.'
            validationError = true
        }

        if (rules && rules.length > 0) {
            rules.forEach((rule) => {
                if (rule.values.length <= 0) {
                    categoriesError.set(rule.type, `At least one ${rule.type} must be selected.`)
                    validationError = true
                }
            })
        }

        if (selectedModule && updatedFunctionalScores && !skipRuleEnabled) {
            updatedFunctionalScores.forEach((functionalScore) => {
                if (!functionalScore.moduleVersionScoreLabelMap.get(selectedModule.versionId)) {
                    functionalEquivalencyError.set(
                        functionalScore.functionalScoreLabel,
                        `Equivalent module scoring for ${functionalScore.functionalScoreLabel} must be selected.`
                    )
                    validationError = true
                }
            })
        }

        setPecValidations({
            metadataCategories: metadataCategoriesError,
            categories: categoriesError.size > 0 ? categoriesError : undefined,
            functionalEquivalency:
                functionalEquivalencyError.size > 0 ? functionalEquivalencyError : undefined,
        })
        return validationError
    }

    const submitPecChange = async (close: boolean) => {
        if (validatePECModuleAdd()) {
            setValidationMessage(
                'One or more required fields are missing or contain an error, please try again.'
            )
            return
        }

        if (selectedModule && moduleGroup.moduleMappings.pec) {
            setUpdateModuleGroupError(false)
            const ruleSet: RuleSetDTO = {
                rules: rules,
                category: RuleSetCategory.JOB_METADATA,
            }
            const updatedRuleSetList: ModuleVersionRuleSetDTO[] =
                [...moduleGroup.moduleMappings.pec.moduleVersionRuleSetList] || []
            // editing an existing module rule
            if (editModuleIndex !== undefined) {
                const oldModuleVersionId = updatedRuleSetList[editModuleIndex].moduleVersionId
                updatedRuleSetList.splice(editModuleIndex, 1, {
                    moduleVersionId: selectedModule.versionId,
                    ruleSet: ruleSet,
                    equivalencyReason: equivalencyDescription,
                })
                sortRulesByPriority(updatedRuleSetList)

                const isModuleStillInGroup = updatedRuleSetList.some(
                    (moduleRuleSet) => moduleRuleSet.moduleVersionId === oldModuleVersionId
                )

                if (
                    updatedFunctionalScores &&
                    updatedFunctionalScores.length > 0 &&
                    !isModuleStillInGroup &&
                    oldModuleVersionId !== selectedModule.versionId
                ) {
                    setUpdatedFunctionalScores(
                        updatedFunctionalScores.map((score) => {
                            score.moduleVersionScoreLabelMap.delete(oldModuleVersionId)
                            return score
                        })
                    )
                }
            } else {
                // creating a new rule
                updatedRuleSetList.push({
                    moduleVersionId: selectedModule.versionId,
                    ruleSet: ruleSet,
                    equivalencyReason: equivalencyDescription,
                })
                sortRulesByPriority(updatedRuleSetList)
            }
            let updatedPECGroup: PECGroupDTO = {
                ...moduleGroup.moduleMappings.pec,
                moduleVersionRuleSetList: updatedRuleSetList,
            }
            if (updatedFunctionalScores && updatedFunctionalScores.length > 0) {
                updatedPECGroup = {
                    ...updatedPECGroup,
                    functionalScores: updatedFunctionalScores,
                }
            }
            const newRequest = {
                lastKnownTimeToken: moduleGroup.savedTimeToken,
                versionId: moduleGroup.versionId,
                moduleMappings: {
                    pec: updatedPECGroup,
                },
            } as UpdateModuleGroupRequest
            try {
                const updateResponseData = await ModuleGroupService.updateModuleGroupVersionDTO(
                    newRequest
                )
                ModuleGroupEntityService.update(updateResponseData)
                cleanFields()
                setShowAddModuleModal(!close)
                if (!close) {
                    // Resets the ModuleIndex, so that the next change is adding a module rather than editing
                    // the same module.
                    setEditModuleIndex(undefined)
                    setDuplicateModuleIndex(undefined)
                }
                if (updateResponseData && updateResponseData.versionId !== newRequest.versionId) {
                    navigate(
                        moduleGroupViewerRoute({
                            moduleGroupVersionId: updateResponseData.versionId,
                        })
                    )
                }
            } catch (e) {
                console.error(e)
                setUpdateModuleGroupError(true)
            }
        }
    }

    const replaceOrAddSubstituteModuleScore = (): SubstituteModuleScore[] => {
        const mesGroup = moduleGroup.moduleMappings.mes as MESGroupDTO
        const newlyAddedModuleSubScore = {
            ...updatedModuleSubstituteScore,
            equivalencyReason: equivalencyDescription,
        }
        if (editModuleIndex || editModuleIndex === 0) {
            // Editing a module
            return mesGroup.substituteModuleScores.map((substituteScore, index) => {
                if (index === editModuleIndex) {
                    return {
                        ...updatedModuleSubstituteScore,
                        equivalencyReason: equivalencyDescription,
                    }
                } else {
                    return substituteScore
                }
            })
        } else {
            // Adding a new one
            return [...mesGroup.substituteModuleScores, newlyAddedModuleSubScore]
        }
    }

    const submitMesChange = async (close: boolean) => {
        if (validateMESModuleAdd()) {
            setValidationMessage(
                'One or more required fields are missing or contain an error, please try again.'
            )
            return
        }

        if (selectedModule && moduleGroup.moduleMappings.mes) {
            setUpdateModuleGroupError(false)
            const newRequest = {
                lastKnownTimeToken: moduleGroup.savedTimeToken,
                versionId: moduleGroup.versionId,
                moduleMappings: {
                    mes: {
                        ...moduleGroup.moduleMappings.mes,
                        substituteModuleScores: replaceOrAddSubstituteModuleScore(),
                    },
                },
            } as UpdateModuleGroupRequest

            try {
                const updateResponseData = await ModuleGroupService.updateModuleGroupVersionDTO(
                    newRequest
                )
                ModuleGroupEntityService.update(updateResponseData)
                cleanFields()
                setShowAddModuleModal(!close)
                if (!close) {
                    // Resets the ModuleIndex, so that the next change is adding a module rather than editing
                    // the same module.
                    setEditModuleIndex(undefined)
                    setDuplicateModuleIndex(undefined)
                }
                if (updateResponseData && updateResponseData.versionId !== newRequest.versionId) {
                    navigate(
                        moduleGroupViewerRoute({
                            moduleGroupVersionId: updateResponseData.versionId,
                        })
                    )
                }
            } catch (e) {
                console.error(e)
                setUpdateModuleGroupError(true)
            }
        }
    }

    const updateFunctionalScores = (functionalScore: FunctionalScoreDTO, index: number) => {
        if (selectedModule && updatedFunctionalScores) {
            setUpdatedFunctionalScores([
                ...updatedFunctionalScores.map((currFunctionalScore, currIndex) => {
                    if (currIndex === index) {
                        return functionalScore
                    }
                    return currFunctionalScore
                }),
            ])
        }
    }

    useEffect(() => {
        if (skipRuleEnabled && (editModuleIndex || editModuleIndex === 0)) {
            setModalTitle('Edit Skip Rule')
        } else if (skipRuleEnabled) {
            setModalTitle('Add a Skip Rule')
        } else if (editModuleIndex || editModuleIndex === 0) {
            setModalTitle('Edit a Module Rule')
        } else {
            setModalTitle('Add a Module Rule')
        }
    }, [editModuleIndex, skipRuleEnabled])

    useEffect(() => {
        if (moduleGroupType === ModuleGroupType.MES) {
            if (!selectedModule?.versionId) {
                setCurrentSubstituteScores([])
            } else {
                setCurrentSubstituteScores(updatedModuleSubstituteScore.substituteScores)
            }
        }
    }, [moduleGroupType, selectedModule?.versionId, updatedModuleSubstituteScore.substituteScores])

    useEffect(() => {
        setValidationMessage('')
    }, [
        selectedModule,
        updatedFunctionalScores,
        updatedModuleSubstituteScore,
        moduleScores,
        rules,
        currentSubstituteScores,
        isOverCharacterLimit,
    ])

    useEffect(() => {
        setPecValidations(undefined)
        setMesValidations(undefined)
    }, [selectedModule])

    const renderModal = () => {
        return (
            <ModalContent dataTestId='add-module-modal' titleText={modalTitle} maxWidth='50vw'>
                <Col gridGap='S300'>
                    {updateModuleGroupError && (
                        <MessageBanner type={MessageBannerType.Error}>
                            Something went wrong while saving your changes, please try again
                        </MessageBanner>
                    )}
                    {moduleGroupType === ModuleGroupType.PEC && (
                        <SkipModuleToggle
                            skipRuleEnabled={skipRuleEnabled}
                            setSelectedModuleVersionId={setSelectedModuleVersionId}
                            setSelectedModule={setSelectedModule}
                        />
                    )}
                    {!skipRuleEnabled && (
                        <ModuleVersionSelectAndDisplay
                            selectedModule={selectedModule}
                            setSelectedModule={setSelectedModule}
                            setModuleScores={setModuleScores}
                            selectedModuleVersionId={selectedModuleVersionId}
                            setSelectedModuleVersionId={setSelectedModuleVersionId}
                            setIsInUATOrMore={setIsInUATOrMore}
                            isInUATOrMore={isInUATOrMore}
                            moduleValidation={moduleValidation}
                            setModuleValidation={setModuleValidation}
                        />
                    )}
                    <Spacer height='S200' />
                    <TextAreaWithCharacterLimit
                        textValue={equivalencyDescription}
                        characterLimit={200}
                        setTextValue={setEquivalencyDescription}
                        labelText={
                            skipRuleEnabled ? 'Reason for skipping module' : 'Why is it equivalent?'
                        }
                        isRequired={false}
                        setIsOverCharacterLimit={setIsOverCharacterLimit}
                        dataTestId='add-module-equivalency-description'
                        placeholder={getEquivalencyReasonPlaceholder(moduleGroupType)}
                        textAreaValidation={equivalencyValidation}
                        setTextAreaValidation={setEquivalencyValidation}
                    />
                    <Hr size='wide' />
                    {moduleGroupType === ModuleGroupType.PEC && (
                        <PECModuleGroupAddModule
                            moduleScores={moduleScores}
                            rules={rules}
                            setRules={setRules}
                            functionalScores={updatedFunctionalScores}
                            moduleVersionId={selectedModuleVersionId}
                            oldModuleVersionId={
                                editModuleIndex !== undefined
                                    ? moduleGroup.moduleMappings.pec?.moduleVersionRuleSetList[
                                          editModuleIndex
                                      ].moduleVersionId
                                    : undefined
                            }
                            setFunctionalScore={updateFunctionalScores}
                            pecValidations={pecValidations}
                            setPecValidations={setPecValidations}
                        />
                    )}
                    {moduleGroupType === ModuleGroupType.MES && (
                        <MESModuleGroupAddModule
                            moduleVersionId={selectedModule?.versionId}
                            moduleScores={moduleScores}
                            setSubstituteModuleScore={setUpdatedModuleSubstituteScore}
                            substituteScoreList={
                                moduleGroup.moduleMappings.mes
                                    ?.selectedPrimaryScoreLabels as string[]
                            }
                            substituteModuleScore={updatedModuleSubstituteScore}
                            mesValidations={mesValidations}
                            setMesValidations={setMesValidations}
                            currentSubstituteScores={currentSubstituteScores}
                            setCurrentSubstituteScores={setCurrentSubstituteScores}
                        />
                    )}
                    <Hr size='narrow' />

                    {validationMessage && (
                        <MessageBanner
                            type={MessageBannerType.Error}
                            dataTestId='add-edit-modal-validation-banner'
                        >
                            {validationMessage}
                        </MessageBanner>
                    )}

                    <Spacer height='S300' />
                    <ModuleRuleButtons
                        setShowAddModuleModal={setShowAddModuleModal}
                        moduleGroupType={moduleGroupType}
                        submitMesChange={submitMesChange}
                        submitPecChange={submitPecChange}
                        skipRuleEnabled={skipRuleEnabled}
                        moduleGroup={moduleGroup}
                    />
                </Col>
            </ModalContent>
        )
    }

    return (
        <Modal
            isOpen={showAddModuleModal}
            close={() => setShowAddModuleModal(false)}
            isScrollable={false}
        >
            {renderModal()}
        </Modal>
    )
}
