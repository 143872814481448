import { CancelToken } from 'axios'
import { v4 } from 'uuid'

import { APP_CONFIG } from 'src/config.app'
import {
    APTDimensionPair,
    CATElementType,
    DimensionPair,
    PairingTableDTO,
} from 'src/models/dto/activities/MUPPExamDTO'
import { Locale } from 'src/models/dto/Locale'
import { DimensionPairConstraintType } from 'src/models/dto/mupp/PairingTableDTO'
import { ResponseBodyType } from 'src/models/ResponseBodyType'
import { ADAxios } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

export interface DimensionPairConstraint {
    dimensionPair: DimensionPair
    type: DimensionPairConstraintType
    value: number
}

export interface AutoPairingTablesRequest {
    dimensionCount?: number
    dimensions: string[]
    forwardLookingWindow?: string
    pairingTableCount?: number
    shouldUniDimensionsAppearFirst?: boolean
    dimensionPairConstraints?: DimensionPairConstraint[]
}

export type AutoPairingTableResponse = {
    responseBodyType: ResponseBodyType.Success
    pairingTables: PairingTableDTO[]
}

export class AutoPairingTablesService {
    private static num(x: number | string) {
        return typeof x === 'number' ? x : parseInt(x)
    }

    public static async generateAutoPairingTables(
        req0: AutoPairingTablesRequest,
        cancelToken: CancelToken
    ) {
        try {
            const req = {
                ...req0,
                dimensionCount: req0.dimensionCount
                    ? AutoPairingTablesService.num(req0.dimensionCount)
                    : null,
                forwardLookingWindow: req0.forwardLookingWindow
                    ? AutoPairingTablesService.num(req0.forwardLookingWindow)
                    : null,
                pairingTableCount: req0.pairingTableCount
                    ? AutoPairingTablesService.num(req0.pairingTableCount)
                    : null,
            }

            const { data }: { data: { pairingTables: { dimensionPairs: APTDimensionPair[] }[] } } =
                await ADAxios.post(APP_CONFIG.aptUrl, req, {
                    headers: { 'Content-Type': 'application/json' },
                    cancelToken,
                })

            const pairingTables: PairingTableDTO[] = data.pairingTables.map((pt, i) => {
                const id = v4()
                return {
                    id,
                    name: `pairingTable${i}`,
                    type: CATElementType.MUPP_PAIRING_TABLE,
                    locale: Locale.en_US,
                    dimensionPairs: pt.dimensionPairs.map((dp) => {
                        return {
                            first: dp.firstDimension,
                            second: dp.secondDimension,
                        }
                    }),
                }
            })
            return {
                pairingTables,
                responseBodyType: ResponseBodyType.Success,
            } as AutoPairingTableResponse
        } catch (error: unknown) {
            handleAxiosError(error)
        }
    }
}
