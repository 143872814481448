import React, { useMemo } from 'react'

export const WithFixedFooter = ({
    footerHeight,
    children,
    renderFooter,
}: {
    footerHeight: string | number
    children: React.ReactNode
    renderFooter: () => JSX.Element
}) => {
    const containerStyle = useMemo(
        () => ({
            overflow: 'auto',
            width: '100%',
            height: `calc(100% - ${footerHeight})`,
        }),
        [footerHeight]
    )
    const footerStyle = useMemo(
        () => ({
            height: `${footerHeight}`,
        }),
        [footerHeight]
    )
    return (
        <>
            <div style={containerStyle}>{children}</div>
            <div style={footerStyle}>{renderFooter()}</div>
        </>
    )
}
