import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Checkbox, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { H3, H4, Label, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { ModuleEntity, ModuleLayout, ModuleType, ModuleUserType } from 'src/models/dto/ModuleDTO'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'
import { InstructionalContentEntityService } from 'src/services/EntityServices/InstructionalContentEntityService'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

export function DeleteContextBoxesModal({
    onClose,
    moduleId,
    nextLayout,
}: {
    onClose: () => void
    moduleId: string
    nextLayout: ModuleLayout
}) {
    const { entity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    return (
        <ModalContent
            dataTestId={'change-content-type-modal'}
            titleText='Change Context Type'
            buttons={[
                <Button
                    key='change-context-type-modal-submit'
                    dataTestId='delete-context-type-modal-submit'
                    onClick={() => {
                        entity.workflowIds.forEach((id) => {
                            ItemGroupHandler.removeContextBox(id)
                        })
                        ModuleEntityService.updateLayout(moduleId, nextLayout)
                        onClose()
                    }}
                    isDestructive
                >
                    Yes, delete
                </Button>,
                <Button
                    key='close-modal'
                    dataTestId='close-modal'
                    onClick={() => {
                        onClose()
                    }}
                    variant={ButtonVariant.Primary}
                >
                    No, keep
                </Button>,
            ]}
        >
            <Col gridGap='S400'>
                <Text>
                    Disabling context boxes will remove all context boxes from your module. You will
                    not be able to restore them. Are you sure you wish to proceed?
                </Text>
            </Col>
        </ModalContent>
    )
}

interface ModuleBasicDetailsProps {
    moduleId: string
    locale: Locale
    editDisabled: boolean
}

export function ModuleBasicDetails({ moduleId, locale, editDisabled }: ModuleBasicDetailsProps) {
    const { entity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    const { lockScoringEditing } = useContext(ModuleBuilderContext)

    const SELECTABLE_LAYOUTS = useMemo(() => Object.values(ModuleLayout), [])

    const [nextLayout, setNextLayout] = useState<ModuleLayout>(
        entity.layout ?? ModuleLayout.Default
    )

    const {
        moduleType,
        userType,
        layout,
        autoProgressionOn,
        instructionalContentId,
        evaluationModule,
        assessmentMetadata,
        moduleTimerConfig,
    } = entity

    const setModuleType = (type: ModuleType) => {
        // By default for evaluation type enable progressBar
        ModuleEntityService.updateProgressBar(moduleId, type === ModuleType.Evaluation)
        ModuleEntityService.updateModuleType(entity.version, type)
    }

    const setUserType = (type: ModuleUserType) => {
        ModuleEntityService.updateUserType(entity.version, type)
    }

    const setProgressBar = (checked: boolean) => {
        ModuleEntityService.updateProgressBar(entity.version, checked)
    }

    const setAutoProgressionOn = (checked: boolean) => {
        ModuleEntityService.updateAutoProgressionOn(entity.version, checked)
    }

    const setFlyoutInstructions = (checked: boolean) => {
        if (checked) {
            const instructionalContent = InstructionalContentEntityService.create()
            ModuleEntityService.updateInstructionalContent(entity.version, instructionalContent.id)
        } else {
            ModuleEntityService.updateInstructionalContent(entity.version, '')
        }
    }

    const setMturkPaymentCode = (checked: boolean) => {
        if (checked) {
            const paymentCodeItem = ItemEntityService.create(ItemType.CompletionCode)
            ModuleEntityService.updateMturkPaymentCode(entity.version, paymentCodeItem.id)
        } else {
            ModuleEntityService.updateMturkPaymentCode(entity.version, '')
        }
    }

    const setAssessmentMetadataOn = (checked: boolean) => {
        ModuleEntityService.updateAssessmentMetadataEnabled(entity.version, checked)
    }

    const setModuleTimerOn = (checked: boolean) => {
        ModuleEntityService.updateModuleTimerEnabled(entity.version, checked)
    }

    useEffect(() => {
        if (entity.userType !== ModuleUserType.MTurkWorker) {
            ModuleEntityService.updateMturkPaymentCode(entity.version, '')
        }
    }, [entity.userType, entity.version])

    const renderDeleteContextBoxes = ({ close }: { close: () => void }) => (
        <DeleteContextBoxesModal
            onClose={() => {
                close()
            }}
            moduleId={moduleId}
            nextLayout={nextLayout}
        />
    )

    return (
        <Col
            backgroundColor='white'
            padding={{ top: 'S300', left: 'S200', right: 'S200', bottom: 'S300' }}
            dataTestId='module-basic-details'
        >
            <Row alignItems='center'>
                <Spacer width='S300' />
                <H3 fontSize='T400'>Basic details</H3>
            </Row>
            <View padding={{ left: 'S300', right: 'S300' }}>
                <Text>Tell us a bit more about the type of module you are creating.</Text>
            </View>
            <View padding={{ left: 'S300', right: 'S300' }}>
                <Spacer height='S400' />
                <Hr size='wide' color='neutral20' />
                <Spacer height='S400' />
                <ResponsiveRow gridGap='S300'>
                    <View flex='1'>
                        <InputWrapper labelText='Module type' id='basic-details-module-type'>
                            {(props) => (
                                <Select
                                    dataTestId={'module-type-select'}
                                    options={Object.values(ModuleType)}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    placeholder='Select one'
                                    {...props}
                                    value={moduleType}
                                    onChange={setModuleType}
                                />
                            )}
                        </InputWrapper>
                    </View>
                    <View flex='1'>
                        <InputWrapper labelText='Who is the user?' id='basic-details-user-type'>
                            {(props) => (
                                <Select
                                    dataTestId={'module-user-type-select'}
                                    options={Object.values(ModuleUserType)}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    placeholder='Select one'
                                    {...props}
                                    value={userType}
                                    onChange={setUserType}
                                />
                            )}
                        </InputWrapper>
                    </View>

                    <WithModal renderModal={renderDeleteContextBoxes}>
                        {({ open }) => (
                            <View flex='1'>
                                <InputWrapper
                                    labelText='What layout will users see?'
                                    id='basic-details-layout'
                                >
                                    {(props) => (
                                        <Select
                                            dataTestId={'module-layout-select'}
                                            options={SELECTABLE_LAYOUTS}
                                            disabled={!isDefaultLocale(locale) || editDisabled}
                                            placeholder='Select one'
                                            {...props}
                                            value={layout}
                                            onChange={(type: ModuleLayout) => {
                                                // When removing context boxes, add the popup confirming
                                                if (
                                                    entity.layout === ModuleLayout.CustomerFacing &&
                                                    type !== ModuleLayout.CustomerFacing
                                                ) {
                                                    setNextLayout(type)
                                                    open()
                                                    // When adding context boxes, update the entity
                                                } else if (
                                                    entity.layout !== ModuleLayout.CustomerFacing &&
                                                    type === ModuleLayout.CustomerFacing
                                                ) {
                                                    entity.workflowIds.forEach((id) => {
                                                        const itemGroup = ItemGroupHandler.get(id)
                                                        if (!itemGroup.contextBoxId) {
                                                            const contextBoxEntity =
                                                                ContextBoxEntityService.create()
                                                            ItemGroupHandler.setContextBoxId(
                                                                id,
                                                                contextBoxEntity.id
                                                            )
                                                        }
                                                    })
                                                    ModuleEntityService.updateLayout(
                                                        entity.version,
                                                        ModuleLayout.CustomerFacing
                                                    )
                                                } else {
                                                    ModuleEntityService.updateLayout(
                                                        entity.version,
                                                        type
                                                    )
                                                }
                                            }}
                                        />
                                    )}
                                </InputWrapper>
                            </View>
                        )}
                    </WithModal>
                </ResponsiveRow>
                <Spacer height='S300' />
                <H4>Module features</H4>
                <Spacer height='S300' />
                <ResponsiveRow gridGap='S300' flexWrap='wrap'>
                    <View>
                        <Row alignItems='center' gridGap='S200'>
                            <Checkbox
                                id='basic-details-evaluation-scores'
                                checked={entity.compositeScores?.length > 0}
                                disabled={
                                    !isDefaultLocale(locale) || editDisabled || lockScoringEditing
                                }
                                onChange={(e) => {
                                    ModuleEntityService.updateEvaluationScores(
                                        moduleId,
                                        e.target.checked
                                    )
                                }}
                            />
                            <Label htmlFor='basic-details-evaluation-scores'>
                                Evaluation scores
                            </Label>
                        </Row>
                    </View>
                    <View>
                        <Row alignItems='center' gridGap='S200'>
                            <Checkbox
                                id='basic-details-customize-flyout'
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                checked={!!instructionalContentId}
                                onChange={(e) => setFlyoutInstructions(e.target.checked)}
                            />
                            <Label htmlFor='basic-details-customize-flyout'>
                                Customize fly-out instructions
                            </Label>
                        </Row>
                    </View>
                    <View>
                        <Row alignItems='center' gridGap='S200'>
                            <Checkbox
                                id='basic-details-auto-progression'
                                checked={autoProgressionOn || false}
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                onChange={(e) => setAutoProgressionOn(e.target.checked)}
                            />
                            <Label htmlFor='basic-details-auto-progression'>Auto-progression</Label>
                            <IconWithTooltip
                                isInline
                                // @ts-ignore: Property 'title' does not exist
                                title='Help for auto-progression'
                                tooltipText='When auto-progression is on, candidates will be automatically moved to the next question.'
                            />
                        </Row>
                    </View>
                    <View>
                        <Row alignItems='center' gridGap='S200'>
                            <Checkbox
                                id='basic-details-assessment-metadata'
                                dataTestId='basic-details-assessment-metadata'
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                checked={!!assessmentMetadata || false}
                                onChange={(e) => setAssessmentMetadataOn(e.target.checked)}
                            />
                            <Label htmlFor='basic-details-assessment-metadata'>
                                Assessment metadata
                            </Label>
                        </Row>
                    </View>
                    <View>
                        <Row alignItems='center' gridGap='S200'>
                            <Checkbox
                                id='basic-details-timer'
                                dataTestId='basic-details-timer'
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                checked={!!moduleTimerConfig || false}
                                onChange={(e) => setModuleTimerOn(e.target.checked)}
                            />
                            <Label htmlFor='basic-details-timer'>Timer</Label>
                        </Row>
                    </View>
                    {entity.moduleType === ModuleType.Evaluation && (
                        <>
                            <View>
                                <Row alignItems='center' gridGap='S200'>
                                    <Checkbox
                                        id='basic-details-progress-bar'
                                        dataTestId={'basic-details-progress-bar'}
                                        checked={evaluationModule}
                                        disabled={!isDefaultLocale(locale) || editDisabled}
                                        onChange={(e) => setProgressBar(e.target.checked)}
                                    />
                                    <Label htmlFor='basic-details-progress-bar'>Progress bar</Label>
                                </Row>
                            </View>
                            <Spacer width='19px' />
                        </>
                    )}
                    {userType === ModuleUserType.MTurkWorker && (
                        <>
                            <View>
                                <Row alignItems='center' gridGap='S200'>
                                    <Checkbox
                                        id='Mturk-payment-code'
                                        checked={!!entity.mturkPaymentCodeItemId}
                                        disabled={!isDefaultLocale(locale) || editDisabled}
                                        onChange={(e) => setMturkPaymentCode(e.target.checked)}
                                    />
                                    <Label htmlFor='Mturk-payment-code'>Mturk payment code</Label>
                                </Row>
                            </View>
                        </>
                    )}
                </ResponsiveRow>
                <Spacer height='S300' />
            </View>
        </Col>
    )
}
