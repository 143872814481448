import React, { useCallback, useEffect, useState } from 'react'

import { ButtonIconPosition, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { DropdownButton } from 'src/components/DropdownButton'
import { RemoveConditionalFlagModal } from 'src/components/ErrorModals'
import { RemoveBranchingConditionsModal } from 'src/components/RemoveBranchingConditionsModal'
import { SmartExpanderContent } from 'src/components/SmartExpander'
import { APP_CONFIG, STAGE } from 'src/config.app'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import {
    EDITABLE_ITEM_TYPES,
    EXCLUDED_FROM_PRODUCTION_ITEM_TYPES,
    getItemTitle,
    ItemType,
} from 'src/models/dto/items/ItemDTO'
import { StaticContentLayoutType } from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale, LocaleProp } from 'src/models/dto/Locale'
import { ModuleEntity, ModuleLayout } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ContextBoxEditor } from 'src/pages/module-builder/context-box/ContextBoxEditor'
import { focusOnId, itemFocusId } from 'src/pages/module-builder/focus'
import ItemEditorContainer from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { BranchingConditions } from 'src/pages/module-builder/page-editor/BranchingConditions'
import { EndOfPageCard } from 'src/pages/module-builder/page-editor/EndOfPageCard'
import { PageHeader } from 'src/pages/module-builder/page-editor/PageHeader'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

import './PageEditor.scss'

export interface PageEditorProps {
    pageIndex: number
    locale: Locale
    moduleEntityId: string
    workflowEntityId: string
    isLastPage: boolean
    showValidationError?: boolean
    defaultExpanded?: boolean
    editDisabled?: boolean
    moduleStatus?: ModuleStatus
}

const AddItemHorizontalRuler = ({
    values,
    pageIndex,
    itemIndex,
    disabled,
}: {
    values
    pageIndex: number
    itemIndex: number
    disabled?: boolean
}) => {
    return (
        <Row width={'100%'} justifyContent='center'>
            <div className='dashed-add'>
                <div className='dashed-line' />
                <DropdownButton
                    title='Add item'
                    values={values}
                    variant={ButtonVariant.Primary}
                    renderIcon={() => <IconPlus aria-hidden={true} />}
                    iconPosition={ButtonIconPosition.Leading}
                    ariaLabel={`Add item on page ${pageIndex + 1} at index ${itemIndex + 2}.`}
                    disabled={disabled}
                />
            </div>
        </Row>
    )
}

export const PageEditor = ({
    pageIndex,
    moduleEntityId,
    workflowEntityId,
    isLastPage,
    showValidationError,
    locale,
    defaultExpanded,
    editDisabled,
}: PageEditorProps & LocaleProp) => {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const [isExpanded, setIsExpanded] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setIsExpanded(defaultExpanded ?? true)
        }, 0)
    }, [defaultExpanded])

    const isFirstPage = pageIndex === 0

    const { entity } = useActivityEntity<ItemGroupEntity>({
        workflowEntityId,
        moduleEntityId,
    })

    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleEntityId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    useEffect(() => {
        // if we find that this item group only contains 1 item and that item seems to match the code
        // completion that means this is not a page we should display to the user.
        if (
            entity.itemIds.length === 1 &&
            entity.itemIds[0] === moduleEntity.mturkPaymentCodeItemId
        ) {
            ModuleEntityService.removeActivity(moduleEntityId, workflowEntityId)
        }
    }, [entity.itemIds, moduleEntity.mturkPaymentCodeItemId, moduleEntityId, workflowEntityId])

    if (!ValidationErrorEntityService.has(workflowEntityId)) {
        ValidationErrorEntityService.create(workflowEntityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [showError, setShowError] = useState(showValidationError)

    useEffect(() => {
        setShowError(showValidationError ?? false)
    }, [showValidationError])

    useEffect(() => {
        if (moduleEntity.layout !== ModuleLayout.VerticalEvaluation) {
            // if they switch off the VerticalEvaluation layout we need to make sure that we reset header and body
            ItemGroupHandler.updateHeader(workflowEntityId, locale, '')
            ItemGroupHandler.updateBody(workflowEntityId, locale, '')
        }
    }, [locale, moduleEntity.layout, workflowEntityId])

    const [hasVirtualMeeting, setHasVirtualMeeting] = useState<boolean>(false)

    useEffect(() => {
        setHasVirtualMeeting(
            entity.itemIds.some((itemId) => {
                const item = ItemEntityService.get(itemId)
                return item.itemType === ItemType.VirtualMeeting
            })
        )
    }, [entity.itemIds, setHasVirtualMeeting])

    const dropdownValues = useCallback(
        (itemIndex: number) => {
            const tempList = [...EDITABLE_ITEM_TYPES, ...Object.values(StaticContentLayoutType)]
            // this is for adding items that are not ready for release on prod
            if ([STAGE.BETA, STAGE.LOCAL, STAGE.GAMMA].includes(APP_CONFIG.stage)) {
                tempList.push(...EXCLUDED_FROM_PRODUCTION_ITEM_TYPES)
            }
            return Object.values(tempList)
                .filter((itemType) => {
                    // If a virtual meeting item has already been added to the page, filter it so it does not
                    // appear in the add content dropdown as there can only be one in a page.
                    if (hasVirtualMeeting) {
                        return itemType !== ItemType.VirtualMeeting
                    } else {
                        return true
                    }
                })
                .map((itemType) => {
                    return {
                        name: getItemTitle(itemType),
                        dataTestId: `add-${itemType}`,
                        onClick: () => {
                            const itemDTO = ItemEntityService.create(itemType)

                            // If added item type is virtual meeting, put it at top of page and focus there
                            // Otherwise, add new item type to bottom of page and focus there
                            if (itemType === ItemType.VirtualMeeting) {
                                ItemGroupHandler.addItem(workflowEntityId, itemDTO.id, 0)
                                focusOnId(itemFocusId(pageIndex + 1, 1))
                            } else {
                                ItemGroupHandler.addItem(
                                    workflowEntityId,
                                    itemDTO.id,
                                    itemIndex + 1
                                )
                                focusOnId(itemFocusId(pageIndex + 1, itemIndex + 2))
                            }
                        },
                    }
                })
        },
        [hasVirtualMeeting, workflowEntityId, pageIndex]
    )

    const [isConditional, setIsConditional] = useState(
        entity.branchingConditions !== undefined && entity.branchingConditions != null
    )
    const [removeConditional, setRemoveConditional] = useState(false)

    useEffect(() => {
        if (isConditional && (isFirstPage || isLastPage)) {
            ItemGroupHandler.removeBranchingConditions(workflowEntityId)
            setRemoveConditional(true)
            setIsConditional(false)
        }
    }, [pageIndex, isConditional, workflowEntityId, isLastPage, isFirstPage])

    const itemIds = entity.itemIds
    return (
        <Col gridGap='S300' dataTestId={`page-editor-${pageIndex}`}>
            <PageHeader
                isLastPage={isLastPage}
                moduleEntityId={moduleEntityId}
                workflowEntityId={workflowEntityId}
                pageIndex={pageIndex}
                isExpanded={isExpanded}
                onclick={() => {
                    setIsExpanded(!isExpanded)
                }}
                showValidationError={showError}
                onErrorCheck={setShowError}
                activityName={isConditional ? '(conditional page)' : undefined}
                editDisabled={editDisabled}
            />
            <RemoveConditionalFlagModal
                title={'Removed conditional flag'}
                close={() => setRemoveConditional(false)}
                removeConditional={removeConditional}
            />
            <SmartExpanderContent isExpanded={isExpanded}>
                {() => (
                    <Col gridGap='S300'>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showError && (
                            <MessageBanner type={MessageBannerType.Error}>
                                <ul>
                                    {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                        (message, index) => (
                                            <li key={index}>{message}</li>
                                        )
                                    )}
                                </ul>
                            </MessageBanner>
                        )}
                        <Card>
                            <Flex
                                gridGap='16px'
                                flexDirection={small ? 'column' : 'row'}
                                width='100%'
                                flexWrap={'wrap'}
                            >
                                <Col flex={'2'}>
                                    <ItemEditorTextInput
                                        inputId={`page-${pageIndex + 1}-description-input`}
                                        disabled={!isDefaultLocale(locale) || editDisabled}
                                        value={entity.description ?? ''}
                                        placeholder={'A small description of the page'}
                                        setValue={(description: string) => {
                                            ItemGroupHandler.updateDescription(
                                                workflowEntityId,
                                                description
                                            )
                                        }}
                                        labelText={'Page description'}
                                        itemId={`page-${pageIndex + 1}-description-input`}
                                    />
                                </Col>
                                <Col>
                                    {!small && (isLastPage || isFirstPage) ? (
                                        <Spacer height='S500' />
                                    ) : (
                                        <Spacer height='S300' />
                                    )}
                                    <ItemEditorCheckboxInput
                                        inputId={`page-${pageIndex + 1}-randomization-input`}
                                        labelText={'Randomize page content'}
                                        disabled={!isDefaultLocale(locale) || editDisabled}
                                        value={entity.randomizationEnabled}
                                        setValue={(randomizationEnabled: boolean) => {
                                            ItemGroupHandler.updateRandomization(
                                                workflowEntityId,
                                                randomizationEnabled
                                            )
                                        }}
                                        itemId={`page-${pageIndex + 1}-randomization-input`}
                                    />
                                    {!(isLastPage || isFirstPage) && (
                                        <RemoveBranchingConditionsModal
                                            workflowEntityId={workflowEntityId}
                                            pageIndex={pageIndex}
                                            locale={locale}
                                            isConditional={isConditional}
                                            setIsConditional={setIsConditional}
                                            editDisabled={editDisabled}
                                        />
                                    )}
                                </Col>
                                <Col flex={'1'}>
                                    <ItemEditorTextInput
                                        inputId={`page-${pageIndex + 1}-labels-input`}
                                        disabled={!isDefaultLocale(locale) || editDisabled}
                                        value={
                                            entity.labels && entity.labels[0]
                                                ? entity.labels[0]
                                                : ''
                                        }
                                        placeholder={'A label for the page'}
                                        setValue={(label: string) => {
                                            ItemGroupHandler.updateLabels(workflowEntityId, [label])
                                        }}
                                        labelText={'Page label'}
                                        itemId={`page-${pageIndex + 1}-label-input`}
                                    />
                                </Col>
                                {moduleEntity.layout === ModuleLayout.VerticalEvaluation && (
                                    <Col width='100%'>
                                        <ItemEditorTextInput
                                            inputId={'header-input'}
                                            value={
                                                entity.headerI18N
                                                    ? entity.headerI18N[locale] ?? ''
                                                    : ''
                                            }
                                            disabled={editDisabled}
                                            placeholder={'An optional header for the page'}
                                            labelText={'Page header'}
                                            itemId={`page-${pageIndex + 1}`}
                                            setValue={(header: string) => {
                                                ItemGroupHandler.updateHeader(
                                                    workflowEntityId,
                                                    locale,
                                                    header
                                                )
                                            }}
                                        />
                                    </Col>
                                )}
                                {moduleEntity.layout === ModuleLayout.VerticalEvaluation && (
                                    <Col width='100%'>
                                        <ItemEditorTextInput
                                            dataTestId={''}
                                            disabled={editDisabled}
                                            inputId={'body-input'}
                                            value={
                                                entity.bodyI18N ? entity.bodyI18N[locale] ?? '' : ''
                                            }
                                            placeholder={'An optional body for the page'}
                                            labelText={'Page body'}
                                            itemId={`page-${pageIndex + 1}`}
                                            setValue={(body: string) => {
                                                ItemGroupHandler.updateBody(
                                                    workflowEntityId,
                                                    locale,
                                                    body
                                                )
                                            }}
                                        />
                                    </Col>
                                )}
                                {isConditional && (
                                    <Col width='100%'>
                                        <BranchingConditions
                                            pageIndex={pageIndex}
                                            locale={locale}
                                            moduleEntityId={moduleEntityId}
                                            workflowEntityId={workflowEntityId}
                                            showValidationError={showValidationError}
                                            editDisabled={editDisabled}
                                        />
                                    </Col>
                                )}
                            </Flex>
                        </Card>
                        <Hr size='narrow' color='#B9C0C8' />
                        {moduleEntity.layout === ModuleLayout.CustomerFacing &&
                            entity.contextBoxId && (
                                <>
                                    <ContextBoxEditor
                                        locale={locale}
                                        contextBoxId={entity.contextBoxId}
                                        itemGroupId={entity.id}
                                        pageIndex={pageIndex}
                                        showValidationIssuesBanner={showError ?? false}
                                        workflowEntityId={workflowEntityId}
                                        editDisabled={editDisabled}
                                    />
                                    <AddItemHorizontalRuler
                                        values={dropdownValues(-1)}
                                        pageIndex={pageIndex}
                                        itemIndex={-1}
                                        disabled={editDisabled}
                                    />
                                </>
                            )}
                        {itemIds.map((itemId, itemIndex) => {
                            return (
                                <React.Fragment key={itemId}>
                                    <ItemEditorContainer
                                        itemEntityId={itemId}
                                        workflowEntityId={workflowEntityId}
                                        itemIndex={itemIndex}
                                        pageIndex={pageIndex}
                                        isEndOfPage={itemIndex === itemIds.length - 1}
                                        locale={locale}
                                        key={itemId}
                                        containerDataTestId={`page-${pageIndex}-item-${itemIndex}`}
                                        showValidationError={showError}
                                        moduleLayout={moduleEntity.layout}
                                        editDisabled={editDisabled}
                                    />
                                    {itemIndex < itemIds.length - 1 ? (
                                        <AddItemHorizontalRuler
                                            values={dropdownValues(itemIndex)}
                                            pageIndex={pageIndex}
                                            itemIndex={itemIndex}
                                            disabled={editDisabled}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </React.Fragment>
                            )
                        })}
                        <EndOfPageCard
                            pageIndex={pageIndex}
                            moduleEntityId={moduleEntityId}
                            workflowEntityId={workflowEntityId}
                            numberOfItems={itemIds.length}
                            hasVirtualMeeting={hasVirtualMeeting}
                            editDisabled={editDisabled}
                        />
                        <Spacer height={'S400'} />
                    </Col>
                )}
            </SmartExpanderContent>
        </Col>
    )
}
