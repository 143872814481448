import * as queryString from 'querystring'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconSettings } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Hr, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import {
    OnSortCallback,
    SortDirection,
    TableColumn,
    useSort,
} from '@amzn/stencil-react-components/table'
import { H1, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { APP_CONFIG } from 'src/config.app'
import { Locale } from 'src/models/dto/Locale'
import {
    SearchBar,
    SearchResultsFilterSidebar,
    useRenderFlyout,
} from 'src/pages/asset-library/SearchBar'
import { SearchResults } from 'src/pages/asset-library/SearchResults'
import {
    DisplayType,
    MediaTemplate,
    OrderBy,
    PageOfMedia,
    QueryParams,
    SearchQuery,
} from 'src/pages/asset-library/types'
import { UploadButton } from 'src/pages/asset-library/UploadButton'
import usePrevious from 'src/pages/module-review/hooks'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/pages/search/SearchResultsPage'

function ErrorBanner({ errorMessage }: { errorMessage?: string }) {
    if (errorMessage) {
        return (
            <>
                <MessageBanner
                    type={MessageBannerType.Error}
                    dismissButtonAltText={'Hide errors'}
                    dataTestId={'unexpected-error-banner'}
                >
                    <Text>{errorMessage}</Text>
                </MessageBanner>
            </>
        )
    }

    return null
}

const defaultPageSize = 10

export const AssetLibraryPageBody = ({
    actionsColumn,
    displayType,
    embedded,
    pageSize,
    selectUse,
    selectView,
    lockedFileTypes,
}: {
    actionsColumn?: TableColumn<MediaTemplate>
    displayType: DisplayType
    embedded: boolean
    pageSize?: number
    selectUse?: (mediaVersionId: string) => void
    selectView?: (mediaVersionId: string) => void
    lockedFileTypes?: string[]
}) => {
    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)

    const [isFilterOpenForMobileEmbedded, setIsFilterOpenForMobileEmbedded] = useState(false)

    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    const [reactStateSearchParams, setReactStateSearchParams] = useState<URLSearchParams>(
        () => new URLSearchParams()
    )

    // If it's embedded in a modal, we use locale state to switch between searches, otherwise we use URL query parameters
    const searchParams = embedded ? reactStateSearchParams : urlSearchParams
    const setSearchParams = embedded ? setReactStateSearchParams : setUrlSearchParams

    const pageIndexSearchParam = searchParams.get(QueryParams.PAGE_INDEX) ?? undefined
    const numberPageIndex = pageIndexSearchParam ? +pageIndexSearchParam : 0
    const resultTableRef = useRef<HTMLDivElement | null>(null)

    const searchQueryFromUrlQueryParameters = useCallback(() => {
        let nextSearchQuery: SearchQuery = {
            search: searchParams.get(QueryParams.SEARCH) ?? '',
            pageIndex: numberPageIndex,
            createdBy: searchParams.get(QueryParams.CREATED_BY) ?? undefined,
            createdAfterOrAt: searchParams.get(QueryParams.CREATED_AFTER_OR_AT) ?? undefined,
            createdBeforeOrAt: searchParams.get(QueryParams.CREATED_BEFORE_OR_AT) ?? undefined,
            defaultFileName: searchParams.get(QueryParams.DEFAULT_FILENAME) ?? undefined,
            fileType: lockedFileTypes ?? searchParams.getAll(QueryParams.FILE_TYPE) ?? [],
            locale: (searchParams.getAll(QueryParams.LOCALE) as Locale[]) ?? [],
            pageSize: pageSize ?? defaultPageSize,
        }
        const englishPublished = searchParams.get(QueryParams.ENGLISH_PUBLISHED)

        if (englishPublished === 'true' || displayType === DisplayType.ASSIGN_TO_MODULE_PAGE) {
            nextSearchQuery = {
                ...nextSearchQuery,
                englishPublished: true,
            }
        }

        const orderBy = searchParams.get(QueryParams.ORDER_BY) ?? undefined

        const orderDirection =
            searchParams.get(QueryParams.ORDER_DIRECTION) === null
                ? undefined
                : (searchParams.get(QueryParams.ORDER_DIRECTION) as SortDirection)

        return {
            nextSearchQuery,
            orderBy: orderBy ?? OrderBy.TITLE,
            orderDirection: orderDirection ?? SortDirection.Ascending,
        }
    }, [searchParams, numberPageIndex, lockedFileTypes, pageSize, displayType])

    const [nextSearchQuery, setNextSearchQuery] = useState<SearchQuery>(
        () => searchQueryFromUrlQueryParameters().nextSearchQuery
    )

    const [currentSearchQuery, setCurrentSearchQuery] = useState<SearchQuery>(nextSearchQuery)

    const {
        activeSortId,
        sortDirection,
        onSort: onSortInner,
    } = useSort({
        defaultSortDirection: SortDirection.Ascending,
        defaultActiveSortId: OrderBy.TITLE,
    })

    const [{ loading, data: currentPage, error }, executeSearchRequest] = useAxios<PageOfMedia>(
        {},
        { useCache: false, manual: true }
    )

    const [triggeredSearch, setTriggerSearch] = useState<boolean>()

    const setSearchQueryParameters = useCallback(
        (
            searchQuery: SearchQuery,
            sort: string = OrderBy.TITLE,
            sortDir: SortDirection = SortDirection.Ascending,
            isNewSearch: boolean
        ) => {
            const queryParams: string[][] = [
                [QueryParams.SEARCH, searchQuery.search],
                [QueryParams.PAGE_INDEX, String(isNewSearch ? 0 : searchQuery.pageIndex)],
                [QueryParams.PAGE_SIZE, String(searchQuery.pageSize ?? defaultPageSize)],
                [QueryParams.ORDER_BY, sort ?? OrderBy.TITLE],
                [QueryParams.ORDER_DIRECTION, sortDir ?? SortDirection.Ascending],
            ]

            if (searchQuery.createdAfterOrAt) {
                queryParams.push([QueryParams.CREATED_AFTER_OR_AT, searchQuery.createdAfterOrAt])
            }

            if (searchQuery.createdBeforeOrAt) {
                queryParams.push([QueryParams.CREATED_BEFORE_OR_AT, searchQuery.createdBeforeOrAt])
            }

            if (searchQuery.createdBy) {
                queryParams.push([QueryParams.CREATED_BY, searchQuery.createdBy])
            }

            if (searchQuery.defaultFileName) {
                queryParams.push([QueryParams.DEFAULT_FILENAME, searchQuery.defaultFileName])
            }

            if (lockedFileTypes) {
                lockedFileTypes.forEach((f) => {
                    queryParams.push([QueryParams.FILE_TYPE, f])
                })
            } else if (searchQuery.fileType && searchQuery.fileType.length > 0) {
                searchQuery.fileType.forEach((f) => {
                    queryParams.push([QueryParams.FILE_TYPE, f])
                })
            }

            if (searchQuery.locale && searchQuery.locale.length > 0) {
                searchQuery.locale.forEach((l) => {
                    queryParams.push([QueryParams.LOCALE, l])
                })
            }

            if (
                searchQuery.englishPublished === true ||
                displayType === DisplayType.ASSIGN_TO_MODULE_PAGE
            ) {
                queryParams.push([QueryParams.ENGLISH_PUBLISHED, 'true'])
            }

            const newSearchParams = new URLSearchParams(queryParams)
            if (searchParams.toString() !== newSearchParams.toString()) {
                setSearchParams(newSearchParams)
            }
            setTriggerSearch(true)
        },
        [lockedFileTypes, displayType, searchParams, setSearchParams]
    )

    const onSort: OnSortCallback = ({ sortId: sortIdInner, sortDirection: sortDirectionInner }) => {
        setSearchQueryParameters(currentSearchQuery, sortIdInner, sortDirectionInner, false)
    }

    const onPageSelect = useCallback(
        (page) => {
            setSearchQueryParameters(
                {
                    ...currentSearchQuery,
                    pageIndex: page - 1,
                },
                activeSortId,
                sortDirection,
                false
            )
        },
        [setSearchQueryParameters, activeSortId, sortDirection, currentSearchQuery]
    )

    // Bit strange, but setSearchParams only works from the second render onwards, and this is a hack to enforce that.
    // It's caused by useEffect in setSearchParams being called in the parent component.

    const previousSearchParams = usePrevious(searchParams)

    useEffect(() => {
        if (triggeredSearch || previousSearchParams !== searchParams) {
            setTriggerSearch(false)

            const {
                nextSearchQuery: nextQuery,
                orderBy,
                orderDirection,
            } = searchQueryFromUrlQueryParameters()

            if (JSON.stringify(nextQuery) !== JSON.stringify(currentSearchQuery)) {
                setCurrentSearchQuery(nextQuery)
                setNextSearchQuery(nextQuery)
            }

            if (activeSortId !== orderBy || sortDirection != orderDirection) {
                onSortInner({
                    sortId: orderBy,
                    sortDirection: orderDirection,
                })
            }

            const { fileType, locale, ...nonArrayArguments } = nextQuery

            const arrayArguments = queryString.stringify({
                ...(fileType ? { fileType: fileType } : {}),
                ...(locale ? { locale: locale } : {}),
            })

            void executeSearchRequest({
                method: 'GET',
                url:
                    `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media?` +
                    (arrayArguments != '&' ? arrayArguments : ''),
                params: {
                    ...nonArrayArguments,
                    [QueryParams.PAGE_SIZE]: String(nextQuery.pageSize ?? defaultPageSize),
                    [QueryParams.ORDER_BY]: orderBy,
                    [QueryParams.ORDER_DIRECTION]: orderDirection,
                },
            })
                .then(() => resultTableRef?.current?.focus())
                .catch(() => {})
        }
    }, [
        setNextSearchQuery,
        setCurrentSearchQuery,
        onSortInner,
        searchQueryFromUrlQueryParameters,
        currentSearchQuery,
        activeSortId,
        sortDirection,
        executeSearchRequest,
        searchParams,
        previousSearchParams,
        triggeredSearch,
    ])

    const containerRef = useRef<HTMLDivElement | null>(null)

    const layout = useLayoutDirection()

    useEffect(() => {
        if (containerRef) {
            containerRef.current?.focus()
        }
    }, [containerRef])

    const [madeInitialRequest, setMadeInitialRequest] = useState<boolean>(false)

    useEffect(() => {
        if (!madeInitialRequest) {
            const {
                nextSearchQuery: nextSearch,
                orderBy,
                orderDirection,
            } = searchQueryFromUrlQueryParameters()

            setCurrentSearchQuery(nextSearch)
            setNextSearchQuery(nextSearch)
            setSearchQueryParameters(nextSearch, orderBy, orderDirection, false)
            setMadeInitialRequest(true)
        }
    }, [
        setMadeInitialRequest,
        madeInitialRequest,
        setCurrentSearchQuery,
        setNextSearchQuery,
        setSearchQueryParameters,
        searchQueryFromUrlQueryParameters,
    ])

    const isMobileOrTablet =
        layout === LAYOUT_DIRECTION.MOBILE || layout === LAYOUT_DIRECTION.TABLET

    const executeSearch = () => {
        setSearchQueryParameters(nextSearchQuery, activeSortId, sortDirection, true)
    }

    const renderFlyout = useRenderFlyout({
        executeSearch,
        nextSearchQuery,
        setNextSearchQuery,
        lockedFileTypes,
    })

    if (isFilterOpenForMobileEmbedded) {
        const close = () => setIsFilterOpenForMobileEmbedded(false)
        return (
            <Container
                ref={containerRef}
                backgroundColor='#F8F8F8'
                width='100%'
                minHeight='100%'
                paddingHorizontal={0}
            >
                {renderFlyout({ close })}
            </Container>
        )
    }

    return (
        <Container
            ref={containerRef}
            backgroundColor='#FFF'
            width='100%'
            minHeight='100%'
            paddingHorizontal={0}
        >
            <ErrorBanner errorMessage={unexpectedError} />
            <View padding={{ top: 'S300', left: 'S300', right: 'S300' }}>
                <H1>Asset Library</H1>
            </View>
            <Col gridGap='S300' padding='15px' flex={'1 1 auto'}>
                {displayType === DisplayType.ASSET_LIBRARY_PAGE && (
                    <>
                        <UploadButton setError={setUnexpectedError} />
                        <Spacer height={'S200'} />
                    </>
                )}
                <SearchBar
                    nextSearchQuery={nextSearchQuery}
                    setNextSearchQuery={setNextSearchQuery}
                    displayType={displayType}
                    executeSearch={executeSearch}
                />
                <Hr size='narrow' color='#B9C0C8' />
                <Flex gridGap='16px' flexDirection={isMobileOrTablet ? 'column' : 'row'}>
                    {isMobileOrTablet && embedded ? (
                        <View width='100%'>
                            <Button
                                style={{ width: '100%' }}
                                variant={ButtonVariant.Secondary}
                                icon={<IconSettings aria-hidden />}
                                onClick={() => setIsFilterOpenForMobileEmbedded(true)}
                            >
                                Filters
                            </Button>
                        </View>
                    ) : (
                        <View minWidth={240}>
                            <SearchResultsFilterSidebar
                                executeSearch={() => {
                                    setSearchQueryParameters(
                                        nextSearchQuery,
                                        activeSortId,
                                        sortDirection,
                                        true
                                    )
                                }}
                                lockedFileTypes={lockedFileTypes}
                                nextSearchQuery={nextSearchQuery}
                                setNextSearchQuery={setNextSearchQuery}
                            />
                        </View>
                    )}
                    <View
                        dataTestId={'media-search-result-container'}
                        tabIndex={-1}
                        display={'flex'}
                        flex={1}
                        ref={resultTableRef}
                    >
                        <SearchResults
                            loading={loading}
                            error={!!error}
                            currentPage={
                                currentPage ?? {
                                    mediaTemplates: [],
                                    pageIndex: currentSearchQuery.pageIndex,
                                    pageSize: pageSize ?? defaultPageSize,
                                    numberOfPages: 0,
                                    totalNumberOfResults: 0,
                                }
                            }
                            currentSearchQuery={currentSearchQuery}
                            activeSortId={activeSortId}
                            sortDirection={sortDirection}
                            onSort={onSort}
                            actionsColumn={actionsColumn}
                            displayType={displayType}
                            onPageSelect={onPageSelect}
                            selectUse={selectUse}
                            selectView={selectView}
                        />
                    </View>
                </Flex>
            </Col>
        </Container>
    )
}

export const AssetLibraryPage = () => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (containerRef) {
            containerRef.current?.focus()
        }
    }, [containerRef])

    return (
        <Container
            ref={containerRef}
            backgroundColor='#F8F8F8'
            width='100%'
            minHeight='100vh'
            paddingHorizontal={0}
        >
            <AssetLibraryPageBody displayType={DisplayType.ASSET_LIBRARY_PAGE} embedded={false} />
        </Container>
    )
}
