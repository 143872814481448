import React from 'react'
import styled from '@emotion/styled'

import { RankingCellSubType } from 'src/models/dto/items/RankingItemDTO'
import { TableScaleArrowScaleType } from 'src/pages/module-builder/item-editors/table-editor/TableEditor'

function Arrow({ type, className }: { type: RankingCellSubType; className?: string }) {
    const pathProps = {
        strokeWidth: 1.5,
        fill: 'none',
        stroke: 'currentColor',
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        strokeLinecap: 'round' as 'round',
    }
    const width = 14,
        half = 7,
        r = 4.5

    return (
        <svg
            width={width + 3}
            height={width + 3}
            data-test-id={`ArrowValue:${type}`}
            className={className}
            aria-hidden={true}
        >
            <g transform='translate(1.5, 1.5)'>
                <path d={`M ${half} 0 L ${half} ${width}`} {...pathProps} />
                <path
                    d={`M ${half - r} ${width - r} l ${r} ${r} l ${r} ${-r}`}
                    {...pathProps}
                    transform={
                        type === RankingCellSubType.ArrowUp
                            ? `translate(0, ${width}) scale(1, -1)`
                            : ''
                    }
                />
            </g>
        </svg>
    )
}

export const ArrowValue = styled(Arrow)({
    verticalAlign: 'center',
    color: '#4862c8',
})

function Arrow2({ type, className }: { type: TableScaleArrowScaleType; className?: string }) {
    const pathProps = {
        strokeWidth: 1.5,
        fill: 'none',
        stroke: 'currentColor',
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        strokeLinecap: 'round' as 'round',
    }
    const width = 14,
        half = 7,
        r = 4.5
    return (
        <svg
            width={width + 3}
            height={width + 3}
            data-test-id={`ArrowValue:${type}`}
            className={className}
            aria-hidden={true}
        >
            <g transform='translate(1.5, 1.5)'>
                <path d={`M ${half} 0 L ${half} ${width}`} {...pathProps} />
                <path
                    d={`M ${half - r} ${width - r} l ${r} ${r} l ${r} ${-r}`}
                    {...pathProps}
                    transform={
                        type === TableScaleArrowScaleType.ArrowUp
                            ? `translate(0, ${width}) scale(1, -1)`
                            : ''
                    }
                />
            </g>
        </svg>
    )
}

export const ArrowValue2 = styled(Arrow2)({
    verticalAlign: 'center',
    color: '#4862c8',
})
