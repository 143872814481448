import React from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconAlertCircleFill, IconChevronDown } from '@amzn/stencil-react-components/icons'

import { DropdownButton } from 'src/components/DropdownButton'
import { RevisionDTO } from 'src/models/dto/approval/RevisionDTO'

export interface RevisionSelectorProps {
    revisions: RevisionDTO[]
    selectedRevision?: string
    onRevisionSelect?: (revisionNumber: string, reviewUrl?: string) => void
}

export function RevisionSelector({
    revisions,
    selectedRevision,
    onRevisionSelect,
}: RevisionSelectorProps) {
    const invalid =
        !selectedRevision || !revisions.find((r) => r.revisionNumber === selectedRevision)
    return (
        <DropdownButton
            dataTestId='revision-selector'
            title={`Rev ${selectedRevision ?? '1'}`}
            renderIcon={() =>
                invalid ? (
                    <IconAlertCircleFill title='Error' />
                ) : (
                    <IconChevronDown title='' aria-hidden />
                )
            }
            variant={ButtonVariant.Primary}
            values={(revisions || []).map(({ reviewUrl, revisionNumber }) => {
                return {
                    name: `Rev ${revisionNumber}`,
                    dataTestId: `rev-${revisionNumber}`,
                    onClick: () => {
                        reviewUrl
                            ? onRevisionSelect?.(revisionNumber, reviewUrl)
                            : onRevisionSelect?.(revisionNumber)
                    },
                }
            })}
        />
    )
}
