import AWS from 'aws-sdk'

import { APP_CONFIG } from 'src/config.app'
import { Authenticator } from 'src/services/Authenticator'
import { retryAuth } from 'src/services/AxiosInterceptor'

export async function setUpAwsConfig() {
    const userSession = await retryAuth(() => Authenticator.getValidUserSession())
    if (!userSession) {
        throw new Error('User session not found')
    }
    const userJwtToken: string = userSession.getIdToken().getJwtToken()
    const cognitoLoginURL = `cognito-idp.us-west-2.amazonaws.com/${
        APP_CONFIG.auth.UserPoolId || ''
    }`

    AWS.config.region = 'us-west-2'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: APP_CONFIG.identityPoolId,
        Logins: { [cognitoLoginURL]: userJwtToken },
    })

    await Promise.resolve()
}

/**
 * upload file to S3 using the given path
 */
export async function uploadFileToS3(localFile: File, path: string) {
    await setUpAwsConfig()

    // use S3 ManagedUpload class as it supports multipart uploads
    return new AWS.S3.ManagedUpload({
        params: {
            Bucket: APP_CONFIG.s3MediaBucketAccessPointARN,
            Key: path,
            Body: localFile,
        },
    }).promise()
}

export async function downloadFileFromS3(path: string) {
    await setUpAwsConfig()

    // use S3 ManagedUpload class as it supports multipart uploads
    return new AWS.S3()
        .getObject({
            Bucket: APP_CONFIG.s3MediaBucketAccessPointARN,
            Key: path,
        })
        .promise()
}
