import React, { useContext } from 'react'

import { StencilContext } from '@amzn/stencil-react-components/context'
import { Container, Flex, FlexProps } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

export function MastheadContainer({
    children,
    warningMessages,
}: {
    children: FlexProps['children']
    warningMessages?: string[]
}) {
    const { theme } = useContext(StencilContext)
    const {
        matches: { s: isSmall },
    } = useBreakpoints()
    const wide = !isSmall
    const minHeight = 130
    return (
        <Container
            paddingTop='S400'
            paddingBottom='S400'
            paddingHorizontal='S400'
            style={{ borderBottom: '1px solid ' + theme.color.neutral30 }}
            dataTestId={'module-masthead'}
        >
            <Flex
                justifyContent='space-between'
                alignItems={wide ? 'center' : 'flex-start'}
                flexDirection={wide ? 'row' : 'column'}
                minHeight={wide ? minHeight : undefined}
                gridGap={wide ? 'S400' : 'S300'}
            >
                {children}
            </Flex>
            {warningMessages &&
                warningMessages.map((m, i) => (
                    <MessageBanner
                        key={`warning-message-${i}`}
                        dataTestId={`masthead-warning-${i}`}
                        type={MessageBannerType.Warning}
                    >
                        {m}
                    </MessageBanner>
                ))}
        </Container>
    )
}
