import React, { useCallback, useEffect } from 'react'

import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { Change } from 'src/hooks/DTOEditor'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { ContentItemDTO } from 'src/models/dto/items/ContentItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { ModuleLayout } from 'src/models/dto/ModuleDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { ContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/ContentHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const ContentItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    moduleLayout,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<ContentItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    useEffect(() => {
        if (moduleLayout !== ModuleLayout.VerticalEvaluation)
            ContentHandler.updateTitle(itemDTO.id, locale, '')
    }, [itemDTO.id, locale, moduleLayout])

    const setLocaleWiseMediaOpt = useCallback(
        (change: Change<Medias | undefined>) => {
            ItemEntityService.updateLocaleWiseMediaOpt(itemId, change)
        },
        [itemId]
    )

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ContentHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            ContentHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            value={itemDTO.preserveOrder}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            setValue={(nextValue: boolean) => {
                                ContentHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </ResponsiveRow>
            {moduleLayout === ModuleLayout.VerticalEvaluation && (
                <>
                    <Spacer height={'S200'} />
                    <ResponsiveRow gridGap='S300' alignItems={'flex-start'}>
                        <Col width={'100%'}>
                            <ItemEditorTextInput
                                inputId={'title'}
                                value={itemDTO.titleI18N ? itemDTO.titleI18N[locale] ?? '' : ''}
                                placeholder={'An optional title for the item'}
                                disabled={editDisabled}
                                labelText={'Title'}
                                itemId={itemDTO.id}
                                setValue={(nextValue: string) => {
                                    ContentHandler.updateTitle(itemDTO.id, locale, nextValue)
                                }}
                            />
                        </Col>
                    </ResponsiveRow>
                </>
            )}
            <Spacer height={'S200'} />
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex='1'>
                    <ItemEditorTextArea
                        inputId={'text'}
                        dataTestId={'content-text-editor'}
                        locale={locale}
                        value={itemDTO.textI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ContentHandler.updateText(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.textI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Text'}
                    />
                </Col>
            </ResponsiveRow>
            <Spacer height='S400' />
            <Hr />
            <MediaManager
                itemId={itemDTO.id}
                locale={locale}
                localeWiseMediaOpt={itemDTO.localeWiseMedia}
                setLocaleWiseMediaOpt={setLocaleWiseMediaOpt}
                disabled={editDisabled}
            />
        </>
    )
}
