import React from 'react'

import { Label } from '@amzn/stencil-react-components/dist/submodules/text'
import { InputFooter } from '@amzn/stencil-react-components/form'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { applyChange } from 'src/hooks/DTOEditor'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useUpdateRowLayoutDTO } from 'src/hooks/useUpdateRowLayoutDTO'
import {
    StaticContentLayoutDTO,
    TextWithMediaRowDTO,
} from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { VALIDATION_ERROR_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ValidationErrorEntityService'

export interface TextWithMediaRowEditorProps {
    textWithMediaRow: TextWithMediaRowDTO
    itemId: string
    workflowEntityId: string
    showValidationError?: boolean
    rowIndex: number
    locale: Locale
    editDisabled?: boolean
}

export const TextWithMediaRowEditor = (props: TextWithMediaRowEditorProps) => {
    const { entity: staticContentLayoutDTO } = useItemEntity<StaticContentLayoutDTO>({
        entityId: props.itemId,
        workflowEntityId: props.workflowEntityId,
    })

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const updateRow = useUpdateRowLayoutDTO<TextWithMediaRowDTO>(props.itemId, props.rowIndex)

    return (
        <Col gridGap={10} flex={1} data-media-row={props.rowIndex + 1}>
            <ItemEditorTextInput
                inputId={`stimulus-index-${props.rowIndex}`}
                dataTestId={`title-${props.rowIndex}-input`}
                value={props.textWithMediaRow.titleI18N[props.locale] ?? ''}
                disabled={props.editDisabled}
                placeholder={'Enter the title'}
                itemId={staticContentLayoutDTO.id}
                setValue={(next: string) => {
                    updateRow((r) => ({
                        ...r,
                        titleI18N: {
                            ...r.titleI18N,
                            [props.locale]: next,
                        } as LocalizedAttribute<string>,
                    }))
                }}
                validationErrorMessage={(
                    validationErrorEntity.validationErrors[`rows[${props.rowIndex}].titleI18N`] ??
                    []
                ).join(', ')}
                showError={props.showValidationError}
                labelText={'Title'}
                maxCharacterLimit={36}
            />

            <Col width={'100%'}>
                <ItemEditorTextArea
                    inputId={`text-index-${props.rowIndex}`}
                    disabled={props.editDisabled}
                    locale={props.locale}
                    value={props.textWithMediaRow.textI18N[props.locale] ?? ''}
                    itemId={props.itemId}
                    setValue={(next: string) => {
                        updateRow((r) => ({
                            ...r,
                            textI18N: {
                                ...r.textI18N,
                                [props.locale]: next,
                            } as LocalizedAttribute<string>,
                        }))
                    }}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors[
                            `rows[${props.rowIndex}].textI18N`
                        ] ?? []
                    ).join(', ')}
                    showError={props.showValidationError}
                    labelText={'Text'}
                    maxCharacterLimit={190}
                />
            </Col>

            <Col>
                <Row alignItems='center'>
                    <Label>
                        <Text fontSize={'T100'} color={'neutral70'}>
                            Media
                        </Text>
                    </Label>
                    <IconWithTooltip
                        title='Show tooltip for Media'
                        color='primary70'
                        tooltipText='The Media that will appear in this row. It will appear to the left of the title and text.'
                    />
                </Row>
                {validationErrorEntity.validationErrors[`rows[${props.rowIndex}].mediaI18N`] && (
                    <InputFooter
                        style={{
                            visibility: validationErrorEntity.validationErrors[
                                `rows[${props.rowIndex}].mediaI18N`
                            ]
                                ? 'visible'
                                : 'hidden',
                        }}
                        error
                        id={'text-with-media-row-media-error-footer'}
                    >
                        {validationErrorEntity.validationErrors[
                            `rows[${props.rowIndex}].mediaI18N`
                        ].join(', ')}
                    </InputFooter>
                )}
            </Col>
            <MediaManager
                inputId={`media-files-add-image-path-index-${props.rowIndex}`}
                itemId={props.itemId}
                localeWiseMediaOpt={props.textWithMediaRow.localeWiseMedia}
                maxNumberOfMedia={1}
                shouldExpandOnMount
                locale={props.locale}
                disableMediaExpander
                setLocaleWiseMediaOpt={(next) => {
                    updateRow((r) => ({
                        ...r,
                        localeWiseMedia: applyChange(r.localeWiseMedia, next),
                    }))
                }}
                disabled={props.editDisabled}
            />
        </Col>
    )
}
