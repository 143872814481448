import { CognitoAuthOptions } from 'amazon-cognito-auth-js'

type FeatureFlags = Record<Features, boolean>

export enum Features {
    FAST_TRACK_APPROVAL = 'FAST_TRACK_APPROVAL',
    LLM_MIXTRAL_CRA_SCORING = 'LLM_MIXTRAL_CRA_SCORING',
}

export const ALL_FEATURE_FLAGS_DISABLED: FeatureFlags = Object.freeze(
    Object.values(Features).reduce((o, k) => ({ ...o, [k]: true }), {})
) as never as FeatureFlags

interface AppConfig {
    stage: STAGE
    auth: CognitoAuthOptions
    identityPoolId: string
    s3MediaBucketAccessPointARN: string
    // API V2
    backendAPIBaseUrl: string
    // API V1 (old)
    backendAPIV1BaseUrl: string
    deliveryPreviewBaseUrl: string
    aptUrl: string
    approvalUrl: string
    isItDownDashboardName: string

    //Scoring Test Project
    hookConfig: {
        s3InputBucketName: string
        hookAPIUrl: string
        hookIdentityPoolId: string
    }
    hookAuth: CognitoAuthOptions
    featureFlags?: FeatureFlags
}

export enum STAGE {
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod',
    LOCAL = 'local',
}

export const QA_AUTOMATION_STS_ASSUME_ROLE =
    'arn:aws:iam::382110919776:role/RxtHofAuthoringServiceRole'

export const CONFIGS_BY_ENVIRONMENT: { [environment: string]: AppConfig } = {
    local: {
        // Designer app
        stage: STAGE.LOCAL,
        auth: {
            AppWebDomain: 'assessment-designer-api-beta.auth.us-west-2.amazoncognito.com',
            ClientId: '5cs0lm7551h8gmcoj2knuael37',
            RedirectUriSignIn:
                window.location.origin === 'http://0.0.0.0:3000'
                    ? 'http://localhost:3000'
                    : window.location.origin,
            RedirectUriSignOut:
                window.location.origin === 'http://0.0.0.0:3000'
                    ? 'http://localhost:3000'
                    : window.location.origin,
            TokenScopesArray: [],
            UserPoolId: 'us-west-2_ty6POd3dn',
        },
        identityPoolId: 'us-west-2:f9f2c16f-b417-41d5-9c35-e7b06710546b',
        s3MediaBucketAccessPointARN:
            'arn:aws:s3:us-west-2:335240694869:accesspoint/media-bucket-access-point-beta',
        backendAPIBaseUrl:
            'https://api.beta.workbench.assessments.recruiting.people-tech.a2z.com/v2',
        backendAPIV1BaseUrl:
            'https://api.beta.workbench.assessments.recruiting.people-tech.a2z.com',
        deliveryPreviewBaseUrl: 'https://delta.assessments.amazon.jobs',
        aptUrl: 'https://wmme50ds9g.execute-api.us-west-2.amazonaws.com/beta/generate',
        approvalUrl: 'https://bhypaol693.execute-api.us-west-2.amazonaws.com/beta',
        isItDownDashboardName: 'aa-designer-beta',

        hookConfig: {
            s3InputBucketName: 'hook-scoring-testing-alpha',
            hookAPIUrl: 'https://backend-alpha.hook.recruiting.people-tech.a2z.com',
            hookIdentityPoolId: 'us-west-2:6916bfa3-76f6-4c51-a94d-361fe37c7854',
        },
        hookAuth: {
            AppWebDomain: 'hook-admin-api-alpha.auth.us-west-2.amazoncognito.com',
            ClientId: '2t7pjv6gg2bek4tad3oj6r99kq',
            RedirectUriSignIn: 'http://localhost:3000/scoring-test-v2',
            RedirectUriSignOut: 'http://localhost:3000/scoring-test-v2',
            TokenScopesArray: [
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.search',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.v2',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.getAllVariationsScores',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.validation',
                'openid',
            ],
            UserPoolId: 'us-west-2_rEtDEmuAQ',
        },
        featureFlags: {
            [Features.FAST_TRACK_APPROVAL]: true,
            [Features.LLM_MIXTRAL_CRA_SCORING]: true,
        },
    },
    beta: {
        stage: STAGE.BETA,
        auth: {
            AppWebDomain: 'assessment-designer-api-beta.auth.us-west-2.amazoncognito.com',
            ClientId: '5cs0lm7551h8gmcoj2knuael37',
            RedirectUriSignIn: window.location.origin,
            RedirectUriSignOut: window.location.origin,
            TokenScopesArray: [],
            UserPoolId: 'us-west-2_ty6POd3dn',
        },
        identityPoolId: 'us-west-2:f9f2c16f-b417-41d5-9c35-e7b06710546b',
        s3MediaBucketAccessPointARN:
            'arn:aws:s3:us-west-2:335240694869:accesspoint/media-bucket-access-point-beta',
        backendAPIBaseUrl:
            'https://api.beta.workbench.assessments.recruiting.people-tech.a2z.com/v2',
        backendAPIV1BaseUrl:
            'https://api.beta.workbench.assessments.recruiting.people-tech.a2z.com',
        deliveryPreviewBaseUrl: 'https://delta.assessments.amazon.jobs',
        aptUrl: 'https://wmme50ds9g.execute-api.us-west-2.amazonaws.com/beta/generate',
        approvalUrl: 'https://bhypaol693.execute-api.us-west-2.amazonaws.com/beta',
        isItDownDashboardName: 'aa-designer-beta',

        hookConfig: {
            s3InputBucketName: 'hook-scoring-testing-beta',
            hookAPIUrl: 'https://backend-beta.hook.recruiting.people-tech.a2z.com',
            hookIdentityPoolId: 'us-west-2:1448c891-4707-4302-b451-bd89325c3488',
        },
        hookAuth: {
            AppWebDomain: 'hook-admin-api-beta.auth.us-west-2.amazoncognito.com',
            ClientId: '2bmp5sodimlpvdgqjod1p5dm51',
            RedirectUriSignIn:
                'https://designer-beta.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            RedirectUriSignOut:
                'https://designer-beta.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            TokenScopesArray: [
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.search',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.v2',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.getAllVariationsScores',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.validation',
                'openid',
            ],
            UserPoolId: 'us-west-2_f2Ufnl9Ll',
        },
        featureFlags: {
            [Features.FAST_TRACK_APPROVAL]: true,
            [Features.LLM_MIXTRAL_CRA_SCORING]: true,
        },
    },
    gamma: {
        stage: STAGE.GAMMA,
        auth: {
            AppWebDomain: 'assessment-designer-api-gamma.auth.us-west-2.amazoncognito.com',
            ClientId: '516t5jjuvm0k6svcvh0icj5m5p',
            RedirectUriSignIn: window.location.origin,
            RedirectUriSignOut: window.location.origin,
            TokenScopesArray: [],
            UserPoolId: 'us-west-2_X61gwICsr',
        },
        identityPoolId: 'us-west-2:edf8343d-0967-40fe-b193-5b66efaab98b',
        s3MediaBucketAccessPointARN:
            'arn:aws:s3:us-west-2:048964474750:accesspoint/media-bucket-access-point-gamma',
        backendAPIBaseUrl:
            'https://api.gamma.workbench.assessments.recruiting.people-tech.a2z.com/v2',
        backendAPIV1BaseUrl:
            'https://api.gamma.workbench.assessments.recruiting.people-tech.a2z.com',
        deliveryPreviewBaseUrl: 'https://delta.assessments.amazon.jobs',
        aptUrl: 'https://nlengv73ji.execute-api.us-west-2.amazonaws.com/gamma/generate',
        approvalUrl: 'https://gl5v1eu8ll.execute-api.us-west-2.amazonaws.com/gamma',
        isItDownDashboardName: 'aa-designer-gamma',

        hookConfig: {
            s3InputBucketName: 'hook-scoring-testing-gamma',
            hookAPIUrl: 'https://backend-gamma.hook.recruiting.people-tech.a2z.com',
            hookIdentityPoolId: 'us-west-2:2463a9fa-3830-457d-9cdc-c433b8219f2a',
        },
        hookAuth: {
            AppWebDomain: 'hook-admin-api-gamma.auth.us-west-2.amazoncognito.com',
            ClientId: '41euqhg1jqdoblb6hi4ndm70e',
            RedirectUriSignIn:
                'https://designer-gamma.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            RedirectUriSignOut:
                'https://designer-gamma.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            TokenScopesArray: [
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.search',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.v2',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.getAllVariationsScores',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.validation',
                'openid',
            ],
            UserPoolId: 'us-west-2_nrdpK6BZ6',
        },
        featureFlags: {
            [Features.FAST_TRACK_APPROVAL]: true,
            [Features.LLM_MIXTRAL_CRA_SCORING]: true,
        },
    },
    prod: {
        stage: STAGE.PROD,
        auth: {
            AppWebDomain: 'assessment-designer-api-prod.auth.us-west-2.amazoncognito.com',
            ClientId: '33nl9ea286uulkvhu25hlrj1md',
            RedirectUriSignIn: window.location.origin,
            RedirectUriSignOut: window.location.origin,
            TokenScopesArray: [],
            UserPoolId: 'us-west-2_5AV7M76kz',
        },
        identityPoolId: 'us-west-2:384076f6-2c61-40a3-bd7a-6737e7d0401f',
        s3MediaBucketAccessPointARN:
            'arn:aws:s3:us-west-2:993859354546:accesspoint/media-bucket-access-point-prod',
        backendAPIBaseUrl:
            'https://api.prod.workbench.assessments.recruiting.people-tech.a2z.com/v2',
        backendAPIV1BaseUrl:
            'https://api.prod.workbench.assessments.recruiting.people-tech.a2z.com',
        deliveryPreviewBaseUrl: 'https://delta.assessments.amazon.jobs',
        aptUrl: 'https://sjrkv8xnb5.execute-api.us-west-2.amazonaws.com/prod/generate',
        approvalUrl: 'https://ocx8wnfcr3.execute-api.us-west-2.amazonaws.com/prod',
        isItDownDashboardName: 'aa-designer',

        hookConfig: {
            s3InputBucketName: 'hook-scoring-testing-prod',
            hookAPIUrl: 'https://backend.hook.recruiting.people-tech.a2z.com',
            hookIdentityPoolId: 'us-west-2:4d770183-6294-4cf1-929c-a6045cf6d7b8',
        },
        hookAuth: {
            AppWebDomain: 'hook-admin-api.auth.us-west-2.amazoncognito.com',
            ClientId: '5du14d1c4abbr7oce5fn1pc2m3',
            RedirectUriSignIn:
                'https://assessment-designer.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            RedirectUriSignOut:
                'https://assessment-designer.assessments.recruiting.people-tech.a2z.com/scoring-test-v2',
            TokenScopesArray: [
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.search',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.v2',
                'https://backend.hook.recruiting.people-tech.a2z.com/workflows.getAllVariationsScores',
                'https://backend.hook.recruiting.people-tech.a2z.com/scoring.testing.validation',
                'openid',
            ],
            UserPoolId: 'us-west-2_j2kGfmsnt',
        },
        featureFlags: {
            [Features.FAST_TRACK_APPROVAL]: true,
            [Features.LLM_MIXTRAL_CRA_SCORING]: false,
        },
    },
}

const uriMap: { [uri: string]: string } = {
    'localhost:3000': 'local',
    '0.0.0.0:3000': 'local',
    'duiyv5gwzq9us.cloudfront.net': 'beta',
    'd1gxf9vr826jj.cloudfront.net': 'gamma',
    'd33updycsknwr8.cloudfront.net': 'prod',
    'designer-beta.assessments.recruiting.people-tech.a2z.com': 'beta',
    'designer-gamma.assessments.recruiting.people-tech.a2z.com': 'gamma',
    'assessment-designer.assessments.recruiting.people-tech.a2z.com': 'prod',
}

const APP_CONFIGS: { [url: string]: AppConfig } = {}

Object.keys(uriMap).forEach((uri: string) => {
    APP_CONFIGS[uri] = CONFIGS_BY_ENVIRONMENT[uriMap[uri]]
})

const CURRENT_CONFIG: AppConfig = APP_CONFIGS[window.location.host] || ({} as AppConfig)
export const APP_CONFIG: AppConfig = CURRENT_CONFIG

export const ON_TEST_ENVIRONMENT = () => {
    const host = window.location.host
    const environment = uriMap[host]

    return [undefined, 'local', 'alpha', 'beta', 'gamma'].includes(environment)
}

export const modulePreviewUrlForUAT =
    'https://delivery-gamma.assessments.recruiting.people-tech.a2z.com/#/internal/moduleTemplate'

const designerTeamAliases = [
    'amrgn',
    'dhirama',
    'imrada',
    'gsmakkar',
    'mtthammi',
    'trankn',
    'alexlego',
    'tkmin',
    'bnwong',
    'tommtang',
    'zyanzhen',
    'sulinski',
]

export function getReviewersList(currentUserName: string) {
    let reviewAliases = [
        'aimtripp',
        'adryster',
        'annehans',
        'arunvall',
        'blagad',
        'cantonik',
        'chrhonts',
        'creddock',
        'damlep',
        'dtomeh',
        'elisela',
        'flemkels',
        'gcdanna',
        'greenjp',
        'grieko',
        'jccapman',
        'jennharv',
        'jgaetano',
        'jgeimer',
        'jhoult',
        'josehare',
        'julcap',
        'juliesam',
        'kayosady',
        'kefa',
        'krbarr',
        'leemattj',
        'lwasko',
        'lwisjn',
        'markowmm',
        'rbalyeat',
        'rbrusso',
        'rucpate',
        'rugakris',
        'shreay',
        'stemera',
        'suharini',
        'taferotr',
        'tcotter',
        'tlagan',
        'usshahid',
        'yashnqsh',
    ]

    if (designerTeamAliases.includes(currentUserName)) {
        reviewAliases = reviewAliases.concat(designerTeamAliases)
    }

    return reviewAliases.filter((value) => value != currentUserName).sort()
}

export const ADMIN_OVERRIDE_LIST = [
    ...designerTeamAliases,
    'markowmm',
    'krbarr',
    'edingtok',
    'josehare',
]
