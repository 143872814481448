import React from 'react'

import { ArrowValue } from 'src/components/CustomIcons/ArrowValue'
import { HarveyBallValue } from 'src/components/CustomIcons/HarveyBall'
import { RankingCellType, RankingRatingScale } from 'src/models/dto/items/RankingItemDTO'

export function RatingIcon({ value }: { value: RankingRatingScale }) {
    switch (value.type) {
        case RankingCellType.HarveyBall:
            return <HarveyBallValue type={value.subType} />
        case RankingCellType.Arrow:
            return <ArrowValue type={value.subType} />
    }
    return null
}
