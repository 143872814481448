import { SortDirection } from '@amzn/stencil-react-components/table'

import { MESGroupDTO } from 'src/models/dto/module-groups/MESGroupDTO'
import { PECGroupDTO } from 'src/models/dto/module-groups/PECGroupDTO'

export enum ModuleGroupType {
    MES = 'Measurement Equivalent Scoring',
    PEC = 'Presentation Equivalent Content',
}

export enum QueryParams {
    OFFSET = 'offset',
    RECORDS_PER_PAGE = 'numberOfRecordsPerPage',
    SEARCH = 'search',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    OWNER = 'owner',
    MODULE_VERSION_ID = 'moduleVersionId',
    GROUP_TYPE = 'moduleGroupType',
    GROUP_STATUS = 'moduleGroupStatus',
    SORT_FIELD = 'sortField',
    SORT_DIRECTION = 'sortDirection',
    LAST_MODIFIED_BY = 'lastModifiedBy',
    SELECTED_PAGE = 'selectedPage',
}

export interface ModuleGroupSortCriteria {
    sortField: string
    sortDirection: SortDirection
}

export interface SearchQuery {
    search: string
    owner?: string
    moduleVersionId?: string
    lastModifiedBy?: string
    startDate?: string
    endDate?: string
    moduleGroupType?: ModuleGroupType[]
    moduleGroupStatus?: ModuleGroupStatus[]
    offset: number
    numberOfRecordsPerPage: number
    sortField?: string
    sortDirection?: string
}

export interface PageOfModuleGroups {
    moduleGroupMetadataList: ModuleGroupMetadata[]
    offset: number
    numberOfRecordsPerPage: number
    totalRecords: number
}

export interface ModuleGroupMetadata {
    status: string
    moduleGroupId: string
    moduleGroupVersionId: string
    moduleGroupName: string
    moduleGroupType: string
    owner: string
    createdAt: string
    modifiedBy: string
    modifiedAt: string
    moduleVersions: string[]
    purpose?: string
    archived?: boolean
}

export enum ModuleGroupStatus {
    DRAFT_UNVALIDATED = 'Draft (Unvalidated)',
    DRAFT_VALIDATED = 'Draft (Validated)',
    REVIEW = 'Review',
    REVIEW_APPROVED = 'Review (Approved)',
    APPROVAL_REVOKED = 'Review (Revoked)',
    UAT = 'UAT',
    UAT_REVIEW = 'UAT (In Review)',
    UAT_REVIEW_APPROVED = 'UAT (Signed Off)',
    PUBLISHED = 'Published',
    LIVE = 'Live',
}

export const getModuleStatusKeyByValue = (value: ModuleGroupStatus) => {
    const keys = Object.keys(ModuleGroupStatus)
    for (const key of keys) {
        if (ModuleGroupStatus[key] === value) {
            return key
        }
    }
    return '' // Value not found in the enum
}

export const isModuleGroupInDraftMode = (moduleGroup: ModuleGroupDTO): boolean => {
    return (
        ModuleGroupStatus[moduleGroup.status as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.DRAFT_VALIDATED ||
        ModuleGroupStatus[moduleGroup.status as keyof ModuleGroupStatus] ===
            ModuleGroupStatus.DRAFT_UNVALIDATED
    )
}

export interface ModuleGroupDTO {
    id: string
    versionId: string
    name: string
    moduleMetadataList: ModuleGroupModuleMetadata[]
    moduleMappings: ModuleMappings
    status?: string
    author?: string
    updatedAt?: string
    savedTimeToken: number
    purpose: string
    review?: Review
    signOffDocument?: string
    successorVersionId?: string
}

export interface Review {
    reviewId: string
    revisionNumber: number
}

export interface ModuleMappings {
    pec?: PECGroupDTO
    mes?: MESGroupDTO
}

export interface ModuleGroupModuleMetadata {
    id: string
    name: string
    versionId: string
}
