import React, { useCallback, useEffect, useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import {
    IconAlertCircleFill,
    IconCheckCircleFill,
    IconChevronDown,
    IconChevronUp,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { PageOptions, PageOptionsProps } from 'src/components/PageOptions'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { ModuleValidationErrorMessage } from 'src/hooks/usePreview'
import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    focusOnId,
    moveDownFocusId,
    moveUpFocusId,
    pageFocusId,
} from 'src/pages/module-builder/focus'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleEntityService } from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

interface PageEditorHeaderProps {
    pageIndex: number
    moduleEntityId: string
    workflowEntityId: string
    isLastPage: boolean
    isExpanded: boolean
    onclick: () => void
    activityName?: string
    showValidationError?: boolean
    onErrorCheck?: (hasValidationError: boolean) => void
    editDisabled?: boolean
    runErrorCheckDisabled?: boolean
    moduleStatus?: ModuleStatus
    extraPageOptions?: PageOptionsProps['extraPageOptions']
}

export const PageHeader = React.memo(
    ({
        pageIndex,
        moduleEntityId,
        workflowEntityId,
        isLastPage,
        isExpanded,
        onclick,
        activityName,
        showValidationError,
        onErrorCheck,
        editDisabled,
        runErrorCheckDisabled,
        moduleStatus,
        extraPageOptions,
    }: PageEditorHeaderProps) => {
        const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Page ${pageIndex + 1}`
        const {
            matches: { s: small },
        } = useBreakpoints()

        if (!ValidationErrorEntityService.has(workflowEntityId)) {
            ValidationErrorEntityService.create(workflowEntityId)
        }

        const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
            entityId: workflowEntityId,
            selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
        })
        const { entity } = useActivityEntity<ItemGroupEntity>({ workflowEntityId, moduleEntityId })
        const [isChanged, setIsChanged] = useState(false)
        useEffect(() => setIsChanged(true), [entity])

        const [showError, setShowError] = useState(showValidationError)

        const [previewUnexpectedError, setPreviewUnexpectedError] = useState<string | undefined>(
            undefined
        )

        useEffect(() => {
            setShowError(showValidationError ?? false)
        }, [showValidationError])

        const invalid = ErrorCheckService.getTotalActivityError(validationErrorEntity.id) > 0

        const handleErrorCheck = useCallback(
            (hasValidationError: boolean) => {
                setShowError(hasValidationError)
                onErrorCheck?.(hasValidationError)
            },
            [onErrorCheck]
        )

        const moveUpButton = (
            <Button
                dataTestId={`move-page-${pageIndex + 1}-up`}
                variant={ButtonVariant.Secondary}
                aria-label={`Move page ${pageIndex + 1} up.`}
                id={moveUpFocusId(pageFocusId(pageIndex + 1))}
                onClick={() => {
                    ModuleEntityService.moveActivity(
                        moduleEntityId,
                        workflowEntityId,
                        pageIndex - 1
                    )
                    const prevPageFocusId = pageFocusId(pageIndex)
                    focusOnId(moveUpFocusId(prevPageFocusId), prevPageFocusId)
                }}
                icon={<IconChevronUp aria-hidden />}
                aria-disabled={pageIndex === 0 || editDisabled}
            />
        )

        const moveDownButton = (
            <Button
                dataTestId={`move-page-${pageIndex + 1}-down`}
                variant={ButtonVariant.Secondary}
                id={moveDownFocusId(pageFocusId(pageIndex + 1))}
                onClick={() => {
                    ModuleEntityService.moveActivity(
                        moduleEntityId,
                        workflowEntityId,
                        pageIndex + 1
                    )
                    const nextPageFocusId = pageFocusId(pageIndex + 2)
                    focusOnId(moveDownFocusId(nextPageFocusId), nextPageFocusId)
                }}
                aria-label={`Move page ${pageIndex + 1} down.`}
                icon={<IconChevronDown aria-hidden={true} />}
                aria-disabled={isLastPage || editDisabled}
            />
        )

        return (
            <Col>
                {previewUnexpectedError && (
                    <MessageBanner
                        type={MessageBannerType.Error}
                        dismissButtonAltText={'Hide errors'}
                        dataTestId={'unexpected-error-banner'}
                        isDismissible={true}
                    >
                        {previewUnexpectedError}
                    </MessageBanner>
                )}
                <Row alignItems='center' gridGap='S200' padding='S300'>
                    <Flex
                        flexDirection={small ? 'column' : 'row'}
                        gridGap={small ? 'S300' : 'S200'}
                        justifyContent='space-between'
                        width='100%'
                    >
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                isExpanded={isExpanded}
                                onClick={onclick}
                                role={'button'}
                                aria-label={triggerButtonName}
                                iconAltText={triggerButtonName}
                                id={pageFocusId(pageIndex + 1)}
                            />
                            <Text fontSize='T400'>
                                <h3>
                                    Page {pageIndex + 1} {activityName || ''}
                                </h3>
                            </Text>
                        </Row>
                        {showError && (
                            <Row
                                justifyContent={small ? 'flex-start' : 'flex-end'}
                                gridGap='S200'
                                margin={small ? 'S200' : 0}
                            >
                                {invalid ? (
                                    <Row
                                        color={'red70'}
                                        gridGap={'S100'}
                                        alignItems={'center'}
                                        dataTestId={'page-error-banner'}
                                    >
                                        <IconAlertCircleFill aria-hidden />
                                        {ErrorCheckService.getTotalActivityError(
                                            validationErrorEntity.id
                                        )}{' '}
                                        issues
                                    </Row>
                                ) : (
                                    !isChanged && (
                                        <Row
                                            color={'green70'}
                                            gridGap={'S100'}
                                            alignItems={'center'}
                                        >
                                            <IconCheckCircleFill aria-hidden />
                                            No issues
                                        </Row>
                                    )
                                )}
                            </Row>
                        )}
                        <Row
                            justifyContent='flex-end'
                            gridGap='S200'
                            width={small ? '100%' : undefined}
                        >
                            <div>{moveUpButton}</div>
                            <div>{moveDownButton}</div>
                            <View flex='1 1'>
                                <PageOptions
                                    pageIndex={pageIndex}
                                    moduleEntityId={moduleEntityId}
                                    activityId={workflowEntityId}
                                    onErrorCheck={handleErrorCheck}
                                    onPreviewUnexpectedError={() => {
                                        setPreviewUnexpectedError(
                                            'An error has occurred while previewing the page. Please try again later.'
                                        )
                                    }}
                                    onPreviewValidationError={(
                                        validationErrors: ModuleValidationErrorMessage[]
                                    ) => {
                                        setPreviewUnexpectedError(
                                            validationErrors
                                                .map((error) => error.message)
                                                .join(', ')
                                        )
                                    }}
                                    runErrorCheckDisabled={runErrorCheckDisabled}
                                    editDisabled={editDisabled}
                                    moduleStatus={moduleStatus}
                                    extraPageOptions={extraPageOptions}
                                />
                            </View>
                        </Row>
                    </Flex>
                </Row>
            </Col>
        )
    }
)

PageHeader.displayName = 'PageHeader'
