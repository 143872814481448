import { AxiosError } from 'axios'

import { APP_CONFIG, CONFIGS_BY_ENVIRONMENT, STAGE } from 'src/config.app'
import { hookBackendAxiosClient } from 'src/services/AxiosInterceptor'

export interface ScoringTestAPIResponse {
    fileUrl: string
    status?: string
    error?: string
}

export interface HookSearchWorkflowIDsResponse {
    workflows: HookWorkflowObject[]
    error?: string
}

export interface HookWorkflowObject {
    id: string
    lastModifiedAt?: string
    lastModifiedBy?: string
}

interface HookError extends Error {
    type: string
    message: string
}

export function handleHookError(e: unknown) {
    const axiosError = e as AxiosError | undefined
    if (axiosError?.response?.data) {
        return axiosError?.response?.data as HookError | undefined
    }
}

export interface WorkflowIdSearchParams {
    query: string
    offset: number
    limit: number
}

export async function callScoringTestAPI(workflowId: string, csvFileName: string, country: string) {
    const config = {
        headers: { 'Content-Type': 'application/json' },
    }
    const body = {
        workflowId: workflowId,
        csvFileName: csvFileName,
        country: country,
    }
    try {
        const { data, status } = await hookBackendAxiosClient.post(
            APP_CONFIG.hookConfig.hookAPIUrl + '/scoringTesting',
            body,
            config
        )
        return {
            fileUrl: data['outputFileUrl'],
            status: status.toString(),
        } as ScoringTestAPIResponse
    } catch (e: unknown) {
        const hookError = handleHookError(e)
        console.error(hookError)
        return { fileUrl: '', error: hookError?.message } as ScoringTestAPIResponse
    }
}

export async function searchWorkflowIds(searchParams: WorkflowIdSearchParams, stage?: STAGE) {
    const queryString = `limit=${searchParams.limit}&offset=${
        searchParams.offset
    }&query=${encodeURIComponent(searchParams.query)}`
    try {
        const { data } = await hookBackendAxiosClient.get(
            `${
                stage
                    ? CONFIGS_BY_ENVIRONMENT[stage].hookConfig.hookAPIUrl
                    : APP_CONFIG.hookConfig.hookAPIUrl
            }/workflows?${queryString}`
        )
        const workflows: HookWorkflowObject[] = Array.from(data['items'])
        return { workflows: workflows } as HookSearchWorkflowIDsResponse
    } catch (e: unknown) {
        const hookError = handleHookError(e)
        console.error(hookError)
        return {
            workflows: [],
            error: 'Something went wrong while searching for workflow IDs',
        } as HookSearchWorkflowIDsResponse
    }
}
