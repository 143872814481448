import { Locale } from 'src/models/dto/Locale'

export enum QueryParams {
    PAGE_INDEX = 'pageIndex',
    PAGE_SIZE = 'pageSize',
    SEARCH = 'search',
    CREATED_AFTER_OR_AT = 'createdAfterOrAt',
    CREATED_BEFORE_OR_AT = 'createdBeforeOrAt',
    CREATED_BY = 'createdBy',
    DEFAULT_FILENAME = 'defaultFileName',
    FILE_TYPE = 'fileType',
    LOCALE = 'locale',
    ORDER_DIRECTION = 'orderDirection',
    ORDER_BY = 'orderBy',
    ENGLISH_PUBLISHED = 'englishPublished',
}

export enum DisplayType {
    ASSET_LIBRARY_PAGE = 'ASSET_LIBRARY_PAGE',
    ASSIGN_TO_MODULE_PAGE = 'ASSIGN_TO_MODULE_PAGE',
}

export interface MediaTemplate {
    versionId: string
    title: string
    fileType: string
    defaultFileName: string
    defaultFilePath: string
    i18NContent: MediaTemplateLanguage[]
    createdAt: string
    createdBy: string
    updatedAt: string
    updatedBy: string
    used: number
}

export interface MediaTemplateLanguage {
    published: boolean
    locale: Locale
}

export interface PageOfMedia {
    mediaTemplates: MediaTemplate[]
    pageIndex: number
    pageSize: number
    numberOfPages: number
    totalNumberOfResults: number
}

export interface SearchQuery {
    search: string
    defaultFileName?: string
    fileType?: string[]
    locale?: Locale[]
    createdBy?: string
    createdAfterOrAt?: string
    createdBeforeOrAt?: string
    pageIndex: number
    englishPublished?: boolean
    pageSize: number
}

export enum OrderBy {
    TITLE = 'title',
    ENGLISH_PUBLISHED = 'englishPublished',
    DEFAULT_FILENAME = 'defaultFileName',
    CREATED_ON = 'createdAt',
    USED = 'used',
}
