export interface ErrorResponse {
    message: string
    category: number
    code: number
}

export class ErrorResponseThrowable extends Error {
    errorResponse: ErrorResponse

    constructor(errorResponse: ErrorResponse) {
        super(errorResponse?.message)
        this.errorResponse = errorResponse
    }
}
