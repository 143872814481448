import React, { useEffect, useState } from 'react'

import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { IconCross, IconDownload, IconSize } from '@amzn/stencil-react-components/icons'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { SortDirection, Table } from '@amzn/stencil-react-components/table'
import { TabBar, TabValue } from '@amzn/stencil-react-components/tabs'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { TableDataFormat } from './TableDataFormat'

interface CommonProps {
    fileTableList: Map<string, TableDataFormat>
    tabList: string[]
    tabGroup: string
    pageSize: number
    onFileDelete?: (filename: string) => void
}
type ConditionalProps =
    | { downloadable?: false; urlList?: never }
    | { downloadable: true; urlList: Map<string, string> }

type CSVPreviewTablesProps = CommonProps & ConditionalProps

export function CSVPreviewTables({
    fileTableList,
    tabList,
    tabGroup,
    pageSize,
    downloadable,
    urlList,
    onFileDelete,
}: CSVPreviewTablesProps) {
    const [selectedPage, setSelectedPage] = useState(1)
    const [selectedTab, setSelectedTab] = useState<TabValue>()
    let sortedData: any[] = []

    const TabDeleteButton = styledWithTheme(Button)`
  padding: 0;
  border: none;
  color: ${({ theme }) => theme.selectors.color('red60')};
  :focus {
    :before {
        border: none;
    }
    color: ${({ theme }) => theme.selectors.color('red90')};
  }
`

    const tabDeleteButtonComponent = (filename: string) => {
        return (
            <TabDeleteButton
                icon={<IconCross aria-hidden='true' size={IconSize.ExtraSmall} />}
                data-test-id={`delete-button-${filename}`}
                onClick={(event) => {
                    setSelectedTab(undefined)
                    onFileDelete ? onFileDelete(filename) : undefined
                    event.stopPropagation()
                }}
            />
        )
    }

    const displayTabs = () => {
        return tabList.map((filename) => {
            return {
                label: (
                    <Row alignItems='center'>
                        <Text>{filename}</Text>
                        {tabDeleteButtonComponent(filename)}
                    </Row>
                ),
                value: filename,
            }
        })
    }

    //Set initial selected tab
    if (!selectedTab && tabList.length != 0) {
        setSelectedTab(tabList[0])
    }

    //Change selected tab when uploaded files changes
    useEffect(() => {
        setSelectedTab(tabList[0])
    }, [tabList])

    const activeSortId = 'Match#Overall_Decision'
    const sortDirection = SortDirection.Descending

    const compare = (a: string[], b: string[]) => {
        const dataA = a[activeSortId]
        const dataB = b[activeSortId]
        return dataA < dataB ? -1 : 1
    }

    if (selectedTab && Array.from(fileTableList.keys()).length > 0) {
        sortedData = fileTableList?.get(String(selectedTab))!.data

        if (downloadable && downloadable == true) {
            sortedData = sortedData.sort(compare).reverse()
        }
    }

    return (
        <>
            {selectedTab && Array.from(fileTableList.keys()).length > 0 && (
                <Col gridGap='S200' width='100%' height='100%'>
                    <H2 id='table-label' fontSize='T400'>
                        File Preview
                    </H2>

                    {downloadable && downloadable == true && (
                        <>
                            <Row gridGap='S200' width='100%' height='100%'>
                                {/* @ts-ignore: Expression produces a union type that is too complex to represent error */}
                                <Button
                                    icon={<IconDownload />}
                                    type='submit'
                                    id='download-button'
                                    data-test-id='download-button'
                                    onClick={() => {
                                        window.location.assign(urlList.get(selectedTab as string)!)
                                    }}
                                >
                                    Download this file
                                </Button>
                            </Row>
                            <TabBar
                                tabs={tabList}
                                selectedTab={selectedTab}
                                onTabSelect={setSelectedTab}
                                tabGroup={tabGroup}
                            />
                            <Table
                                maxHeight='100%'
                                maxWidth='100%'
                                dataTestId={`csv-table-${selectedTab.toString().split('-')[0]}`}
                                hasStickyHeader={true}
                                activeSortId={activeSortId}
                                sortDirection={sortDirection}
                                columns={fileTableList.get(String(selectedTab))!.columns}
                                data={sortedData.slice(
                                    (selectedPage - 1) * pageSize,
                                    selectedPage * pageSize
                                )}
                            />
                            <Pagination
                                numberOfPages={Math.ceil(
                                    fileTableList.get(String(selectedTab))!.data.length / pageSize
                                )}
                                onPageSelect={(page) => {
                                    setSelectedPage(page)
                                }}
                                selectedPage={selectedPage}
                            />
                        </>
                    )}

                    {!downloadable && (
                        <>
                            <TabBar
                                tabs={displayTabs()}
                                selectedTab={selectedTab}
                                onTabSelect={setSelectedTab}
                                tabGroup={tabGroup}
                            />
                            <Table
                                maxHeight='100%'
                                maxWidth='100%'
                                dataTestId={`csv-table-${selectedTab}`}
                                hasStickyHeader={true}
                                columns={fileTableList.get(String(selectedTab))!.columns}
                                data={fileTableList
                                    ?.get(String(selectedTab))!
                                    .data.slice(
                                        (selectedPage - 1) * pageSize,
                                        selectedPage * pageSize
                                    )}
                            />
                            <Pagination
                                numberOfPages={Math.ceil(
                                    fileTableList.get(String(selectedTab))!.data.length / pageSize
                                )}
                                onPageSelect={(page) => {
                                    setSelectedPage(page)
                                }}
                                selectedPage={selectedPage}
                            />
                        </>
                    )}
                </Col>
            )}
        </>
    )
}
