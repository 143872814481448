import {
    AdaptiveEngineItemParametersDTO,
    AdaptiveEngineSelectionGroupEntity,
    ItemPoolDistributionDTO,
    LastStandardErrorThresh,
} from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'
import { STORE_ACTION } from 'src/services/Store'

const activityType = ActivityType.LaunchAdaptiveEngine

export enum TerminationCriteriaMode {
    StandardErrorThreshold = 'StandardErrorThreshold',
    MaxLength = 'MaxLength',
}

export class AdaptiveEngineHandler {
    static init() {
        factories.set(activityType, () => AdaptiveEngineHandler.create())
    }

    static get(entityId: string): AdaptiveEngineSelectionGroupEntity {
        return ActivityEntityService.get(entityId) as AdaptiveEngineSelectionGroupEntity
    }

    static create(): AdaptiveEngineSelectionGroupEntity {
        const newActivity = activity()
        return {
            ...newActivity,
            type: activityType,
            ppt: activityType,
            name: newActivity.id,
            adaptiveEngineItemParameters: [],
            itemPoolDistributions: [
                {
                    itemPoolId: '',
                    percentage: 0,
                    minItems: 0,
                },
            ],
            experimentalItemPoolID: '',
            examTerminationCriteria: {
                maxLength: 0,
                standardErrorThresh: null,
            },
        } as AdaptiveEngineSelectionGroupEntity
    }

    private static update(payload: AdaptiveEngineSelectionGroupEntity) {
        ActivityEntityService.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: payload.id,
            payload,
        })
    }

    static setParameters(
        entityId: string,
        adaptiveEngineItemParameters: AdaptiveEngineItemParametersDTO[]
    ) {
        const entity = this.get(entityId)
        this.update({
            ...entity,
            adaptiveEngineItemParameters,
        })
    }

    private static changeItemPoolDistribution(
        entityId: string,
        index: number,
        change: (value: ItemPoolDistributionDTO) => ItemPoolDistributionDTO
    ) {
        const entity = this.get(entityId)
        const itemPoolDistribution = entity.itemPoolDistributions[index]
        /* istanbul ignore next */
        if (!itemPoolDistribution) {
            return
        }
        this.update({
            ...entity,
            itemPoolDistributions: [
                ...entity.itemPoolDistributions.slice(0, index),
                change(itemPoolDistribution),
                ...entity.itemPoolDistributions.slice(index + 1),
            ],
        })
    }

    static setItemPoolId(entityId: string, index: number, itemPoolId: string) {
        return AdaptiveEngineHandler.changeItemPoolDistribution(
            entityId,
            index,
            (itemPoolDistribution) => ({
                ...itemPoolDistribution,
                itemPoolId,
            })
        )
    }

    static setItemPoolDistributionPercentage(entityId: string, index: number, percentage: number) {
        AdaptiveEngineHandler.changeItemPoolDistribution(
            entityId,
            index,
            (itemPoolDistribution) => ({
                ...itemPoolDistribution,
                percentage,
            })
        )
    }

    static setItemPoolDistributionMinItems(entityId: string, index: number, minItems: number) {
        AdaptiveEngineHandler.changeItemPoolDistribution(
            entityId,
            index,
            (itemPoolDistribution) => ({
                ...itemPoolDistribution,
                minItems,
            })
        )
    }

    static addItemPool(entityId: string, itemPoolId = '') {
        const entity = this.get(entityId)
        this.update({
            ...entity,
            itemPoolDistributions: [
                ...entity.itemPoolDistributions,
                {
                    itemPoolId,
                    percentage: 0,
                    minItems: 0,
                },
            ],
        })
    }

    static removeItemPool(entityId: string, index: number) {
        const entity = this.get(entityId)
        /* istanbul ignore next */
        if (index < 0 || index >= entity.itemPoolDistributions.length) {
            return
        }
        this.update({
            ...entity,
            itemPoolDistributions: [
                ...entity.itemPoolDistributions.slice(0, index),
                ...entity.itemPoolDistributions.slice(index + 1),
            ],
        })
    }

    static getTerminationCriteriaMode(
        entity: AdaptiveEngineSelectionGroupEntity
    ): TerminationCriteriaMode {
        return typeof entity.examTerminationCriteria.standardErrorThresh === 'number'
            ? TerminationCriteriaMode.StandardErrorThreshold
            : TerminationCriteriaMode.MaxLength
    }

    static setTerminationCriteriaMode(entityId: string, mode: TerminationCriteriaMode) {
        const entity = this.get(entityId)
        const {
            maxLength,
            standardErrorThresh,
            [LastStandardErrorThresh]: lastStandardErrorThresh,
        } = entity.examTerminationCriteria
        this.update({
            ...entity,
            examTerminationCriteria:
                mode === TerminationCriteriaMode.MaxLength
                    ? {
                          maxLength: maxLength ?? 0,
                          standardErrorThresh: null,
                          [LastStandardErrorThresh]: standardErrorThresh ?? null,
                      }
                    : {
                          maxLength: maxLength ?? 0,
                          standardErrorThresh: standardErrorThresh ?? lastStandardErrorThresh ?? 0,
                      },
        })
    }

    static setStandardErrorThreshold(entityId: string, standardErrorThresh: number) {
        const entity = this.get(entityId)
        this.update({
            ...entity,
            examTerminationCriteria: {
                ...entity.examTerminationCriteria,
                standardErrorThresh,
            },
        })
    }

    static setMaxLength(entityId: string, maxLength: number) {
        const entity = this.get(entityId)
        this.update({
            ...entity,
            examTerminationCriteria: {
                ...entity.examTerminationCriteria,
                maxLength,
            },
        })
    }

    static setExperimentalItemPoolId(entityId: string, itemPoolId: string) {
        const entity = this.get(entityId)
        this.update({
            ...entity,
            experimentalItemPoolID: itemPoolId,
        })
    }

    static inferItemPoolId({
        itemPoolDistributions: dists,
        experimentalItemPoolID: experimental,
    }: AdaptiveEngineSelectionGroupEntity) {
        return dists.length > 0 ? dists[0].itemPoolId : experimental
    }
}
