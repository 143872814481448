import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'

export class ModuleGroupUtility {
    static readonly STATIC_SKIPPED_MODULE: ModuleGroupModuleMetadata = {
        id: 'SKIPPED_MODULE',
        name: 'SKIPPED_MODULE',
        versionId: 'SKIPPED_MODULE',
    }

    static isSkippedModule(moduleId?: string): boolean {
        return !!moduleId && moduleId.startsWith(ModuleGroupUtility.STATIC_SKIPPED_MODULE.id)
    }
}
