import { ItemGroupDTO } from 'src/models/dto/activities/ItemGroupDTO'

export enum ActivityType {
    LaunchItem = 'LAUNCH_ITEM',
    LaunchItemGroup = 'LAUNCH_ITEM_GROUP',
    LaunchCAT = 'LAUNCH_CAT',
    LaunchRandomSelection = 'LAUNCH_RANDOM_SELECTION',
    LaunchBucketsAndCups = 'LAUNCH_BUCKETS_AND_CUPS_SELECTION',
    LaunchAdaptiveEngine = 'LAUNCH_ADAPTIVE_ENGINE_SELECTION',
}

export interface ActivityDTO {
    moduleChild: WorkflowItem
    endOfModule?: boolean
}

export type WorkflowItem = {
    id: string
    type: ActivityType
    ppt: string
} & Partial<ItemGroupDTO>

export interface ActivityEntity {
    type: ActivityType
    ppt: string
    id: string

    endOfModule?: boolean
    isNewActivity?: boolean
}

/**
 * ActivityFlow Entity represents an entity that describes the order of a set of
 * workflow items (aka activities) within a module
 */
export type ActivityFlowEntity = {
    moduleId: string
    workflowIds: string[]
}
