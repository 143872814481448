import { DimensionPairConstraint } from 'src/services/mupp/AutoPairingTablesService'

export interface TableDimension {
    friendlyName: string
    systemName: string
    shortForm: string
    hexColour: string
}
export const Dimensions: TableDimension[] = [
    {
        friendlyName: 'Assertiveness',
        systemName: 'Assertiveness',
        shortForm: 'AS',
        hexColour: '#DCB357',
    },
    {
        friendlyName: 'Ambition',
        systemName: 'Ambition',
        shortForm: 'AM',
        hexColour: '#54C1AE',
    },
    {
        friendlyName: 'Caution',
        systemName: 'Caution',
        shortForm: 'CA',
        hexColour: '#D66BAB',
    },
    {
        friendlyName: 'Composure',
        systemName: 'Composure',
        shortForm: 'CS',
        hexColour: '#AB86BB',
    },
    {
        friendlyName: 'Control',
        systemName: 'Control',
        shortForm: 'CO',
        hexColour: '#FEEB5B',
    },
    {
        friendlyName: 'Cooperation',
        systemName: 'Cooperation',
        shortForm: 'CP',
        hexColour: '#5465BC',
    },
    {
        friendlyName: 'Detail Orientation',
        systemName: 'DetailOrientation',
        shortForm: 'DO',
        hexColour: '#6DBE45',
    },
    {
        friendlyName: 'Empathy',
        systemName: 'Empathy',
        shortForm: 'EM',
        hexColour: '#B1FBA7',
    },
    {
        friendlyName: 'Gratitude',
        systemName: 'Gratitude',
        shortForm: 'GR',
        hexColour: '#D1BEA3',
    },
    {
        friendlyName: 'Industriousness',
        systemName: 'Industriousness',
        shortForm: 'ID',
        hexColour: '#ADBCCF',
    },
    {
        friendlyName: 'Initiative',
        systemName: 'Initiative',
        shortForm: 'IN',
        hexColour: '#F43D3C',
    },
    {
        friendlyName: 'Integrity',
        systemName: 'Integrity',
        shortForm: 'HO',
        hexColour: '#0F8C6E',
    },
    {
        friendlyName: 'Learning Curiosity',
        systemName: 'LearningCuriosity',
        shortForm: 'LC',
        hexColour: '#4091C3',
    },
    {
        friendlyName: 'Openness',
        systemName: 'Openness',
        shortForm: 'OP',
        hexColour: '#F2B6B6',
    },
    {
        friendlyName: 'Persuasion',
        systemName: 'Persuasion',
        shortForm: 'PS',
        hexColour: '#C9E8E2',
    },
    {
        friendlyName: 'Planfulness',
        systemName: 'Planfulness',
        shortForm: 'PL',
        hexColour: '#FF7CB2',
    },
    {
        friendlyName: 'Resilience',
        systemName: 'Resilience',
        shortForm: 'RS',
        hexColour: '#FE2FD0',
    },
    {
        friendlyName: 'Risk',
        systemName: 'Risk',
        shortForm: 'RT',
        hexColour: '#00E5CF',
    },
    {
        friendlyName: 'Sociability',
        systemName: 'Sociability',
        shortForm: 'SC',
        hexColour: '#DE833D',
    },
]

export enum DimensionPairConstraintType {
    AT_LEAST = 'AT_LEAST',
    AT_MOST = 'AT_MOST',
    EXACT = 'EXACT',
}

export interface DimensionPairRangeConstraint {
    type: DimensionPairConstraintType
    value: number
}

export interface PairingTableConstraints {
    dimensionCount: number
    forwardLookingWindow: string
    pairingTableCount: number
    shouldUniDimensionsAppearFirst: boolean
    dimensionPairConstraints: DimensionPairConstraint[]
}

export const PAIRING_TABLE_CSV_HEADER =
    'System Generated Pairing Table ID, Reference ID, Name, Locale, First Dimension, Second Dimension\n'
