import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconInformationFill, IconSize } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { Text } from '@amzn/stencil-react-components/text'

import { FunctionalScoresEdit } from 'src/components/module-groups/FunctionalScoresEdit'
import { SubstituteScoresEdit } from 'src/components/module-groups/SubstituteScoresEdit'
import { TextAreaWithCharacterLimit } from 'src/components/TextAreaWithCharacterLimit'
import { MESGroupDTO } from 'src/models/dto/module-groups/MESGroupDTO'
import {
    isModuleGroupInDraftMode,
    ModuleGroupDTO,
    ModuleGroupModuleMetadata,
    ModuleGroupType,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import {
    FunctionalScoreValidationDTO,
    MESCreateEditValidationDTO,
    PECCreateEditValidationDTO,
} from 'src/models/dto/module-groups/ModuleGroupValidations'
import {
    FunctionalScoreDTO,
    ModuleVersionRuleSetDTO,
    PECGroupDTO,
} from 'src/models/dto/module-groups/PECGroupDTO'
import { ModuleSearchSelect } from 'src/pages/module-groups/ModuleSearchSelect'
import { SubstituteScoresCreate } from 'src/pages/module-groups/SubstituteScoresCreate'
import { ToggleFunctionalScore } from 'src/pages/module-groups/ToggleFunctionalScore'
import { ModuleGroupEntityService } from 'src/services/EntityServices/ModuleGroupEntityService'
import {
    ModuleGroupEditUtils,
    ModuleGroupInformationChangeSummary,
} from 'src/services/module-groups/ModuleGroupEditUtils'
import {
    ModuleGroupService,
    SaveModuleGroupVersionRequest,
    UpdateModuleGroupRequest,
} from 'src/services/module-groups/ModuleGroupService'

const equivalencyFooterMessage = (equivalencyType: ModuleGroupType | undefined) => {
    switch (equivalencyType) {
        case ModuleGroupType.MES:
            return 'The Reference Module is a placeholder to define the scoring equivalences within this group. All scores defined will be equivalent when using this group.'
        case ModuleGroupType.PEC:
            return 'The module presented to a candidate differs based on job metadata'
        default:
            return 'Once you choose this equivalency type, you can’t change it. Make sure it is correct before saving.'
    }
}

const getPurposePlaceholder = (
    equivalencyType: ModuleGroupType | undefined,
    functionalScoreEnabled: boolean
) => {
    switch (equivalencyType) {
        case ModuleGroupType.MES:
            return 'Why are the scores equivalent? Exact same items, or different items measuring the same construct?'
        case ModuleGroupType.PEC:
            if (functionalScoreEnabled) {
                return 'Why did you vary the content (region, job family, department)? What makes the scores functionally similar?'
            }
            return 'Why did you vary the content (region, job family, department)?'
        default:
            return 'Choose equivalency type to get more information on what should be included in the description'
    }
}

export const RenderModuleGroupBuilderModal = ({
    setShowModuleGroupBuilder,
    moduleGroupEditEntity,
    setRenderOptionModal,
}: {
    setShowModuleGroupBuilder?: (show: boolean) => void
    moduleGroupEditEntity?: ModuleGroupDTO
    setRenderOptionModal?: () => void
}) => {
    const [groupName, setGroupName] = useState<string>('')
    const [equivalencyType, setEquivalencyType] = useState<ModuleGroupType>()
    const [defaultOrPrimaryModule, setDefaultOrPrimaryModule] =
        useState<ModuleGroupModuleMetadata>()
    const [functionalScoresEnabled, setFunctionalScoresEnabled] = useState<boolean>(false)
    const [functionalScores, setFunctionalScores] = useState<FunctionalScoreDTO[]>([])
    const [groupPurpose, setGroupPurpose] = useState<string>('')
    const [isOverCharacterLimit, setIsOverCharacterLimit] = useState<boolean>(false)
    const [saveModuleGroupError, setSaveModuleGroupError] = useState<Error | undefined>()
    const [substituteScores, setSubstituteScores] = useState<string[]>([])
    const [moduleScores, setModuleScores] = useState<string[]>([])
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [loadModuleError, setLoadModuleError] = useState<boolean>(false)
    const [editInformationChangeSummary, setEditInformationChangeSummary] =
        useState<ModuleGroupInformationChangeSummary>()
    const [editPECValidations, setEditPECValidations] = useState<
        PECCreateEditValidationDTO | undefined
    >()
    const [mesValidations, setMesValidations] = useState<MESCreateEditValidationDTO | undefined>()
    const [groupNameValidation, setGroupNameValidation] = useState<string>('')
    const [equivalencyTypeValidation, setEquivalencyTypeValidation] = useState<string>('')
    const [moduleValidation, setModuleValidation] = useState<string>('')
    const [groupPurposeValidation, setGroupPurposeValidation] = useState<string>('')
    const [validationMessage, setValidationMessage] = useState<string>('')
    const navigate = useNavigate()
    const isInEditMode = !!moduleGroupEditEntity

    const validatePECModuleAdd = () => {
        let validationError = false

        const newFuncEquivalencyError = new Array<FunctionalScoreValidationDTO>()

        if (isInEditMode) {
            if (editInformationChangeSummary) {
                return ModuleGroupEditUtils.validatePECFields(
                    setEditPECValidations,
                    editInformationChangeSummary,
                    functionalScores,
                    functionalScoresEnabled,
                    setValidationMessage,
                    defaultOrPrimaryModule
                )
            }
        } else {
            const functionalScoresSet = new Set<string>()
            functionalScores.forEach((funcScore, index) => {
                const [scoreLabelError, moduleScoreError] =
                    ModuleGroupEditUtils.validateNewFunctionalScores(
                        funcScore,
                        functionalScoresSet,
                        defaultOrPrimaryModule
                    )

                if (scoreLabelError || moduleScoreError) {
                    validationError = true
                }

                newFuncEquivalencyError[index] = {
                    scoreLabel: scoreLabelError,
                    moduleScore: moduleScoreError,
                } as FunctionalScoreValidationDTO
            })
        }

        setEditPECValidations({
            newFunctionalEquivalency: newFuncEquivalencyError,
        } as PECCreateEditValidationDTO)

        return [validationError, false]
    }

    const validateMESModuleAdd = () => {
        let validationError = false

        if (isInEditMode) {
            if (editInformationChangeSummary) {
                return ModuleGroupEditUtils.validateMESFields(
                    setMesValidations,
                    editInformationChangeSummary,
                    setValidationMessage,
                    substituteScores
                )
            }
        } else {
            const arraySubScoresError = new Array<string>()
            if (substituteScores.length <= 0) {
                arraySubScoresError[0] = 'There must be at least one module score selected.'
                validationError = true
            }

            substituteScores.forEach((subScores, idx) => {
                if (subScores.length <= 0) {
                    arraySubScoresError[idx] = 'A module score must be selected.'
                    validationError = true
                }
            })

            setMesValidations({
                newSubstituteScores: arraySubScoresError,
            })
        }

        return [validationError, false]
    }

    //Used to set all the state in case of a module group details edit
    useEffect(() => {
        const moduleGroupType = ModuleGroupService.getModuleGroupType(moduleGroupEditEntity)
        if (moduleGroupEditEntity && moduleGroupType) {
            const primaryOrDefaultModuleVersionId =
                moduleGroupType === ModuleGroupType.MES
                    ? (moduleGroupEditEntity.moduleMappings.mes as MESGroupDTO)
                          .primaryModuleVersionId
                    : (moduleGroupEditEntity.moduleMappings.pec as PECGroupDTO)
                          .defaultModuleVersionId
            setEditInformationChangeSummary(
                ModuleGroupEditUtils.createEmptySummary(primaryOrDefaultModuleVersionId)
            )
            setGroupName(moduleGroupEditEntity.name)
            setEquivalencyType(moduleGroupType)
            setDefaultOrPrimaryModule(
                moduleGroupEditEntity.moduleMetadataList.find(
                    (moduleMetadata) => moduleMetadata.versionId === primaryOrDefaultModuleVersionId
                )
            )
            setGroupPurpose(moduleGroupEditEntity.purpose)
            if (
                moduleGroupType === ModuleGroupType.PEC &&
                moduleGroupEditEntity.moduleMappings.pec?.functionalScores
            ) {
                setFunctionalScoresEnabled(
                    moduleGroupEditEntity.moduleMappings.pec.functionalScores.length > 0
                )
                setFunctionalScores(moduleGroupEditEntity.moduleMappings.pec.functionalScores)
            } else if (
                moduleGroupType === ModuleGroupType.MES &&
                moduleGroupEditEntity.moduleMappings.mes
            ) {
                setSubstituteScores(
                    moduleGroupEditEntity.moduleMappings.mes.selectedPrimaryScoreLabels
                )
            }
        }
    }, [moduleGroupEditEntity])

    // Used to clean functional and substitute scores if not in module group information edit
    useEffect(() => {
        if (!defaultOrPrimaryModule && !moduleGroupEditEntity) {
            setFunctionalScores([])
            setFunctionalScoresEnabled(false)
            setSubstituteScores([])
        }
    }, [defaultOrPrimaryModule, moduleGroupEditEntity])

    // Used to understand if the update contains a default/primary module change
    useEffect(() => {
        let newDefaultOrPrimaryVersionId: string | undefined
        const newReplaceMap = new Map<string, string>()
        if (!editInformationChangeSummary) return
        const defaultOrPrimaryChangeOld = editInformationChangeSummary.defaultOrPrimaryChange.old
        const defaultOrPrimaryChangeNew = editInformationChangeSummary.defaultOrPrimaryChange.new

        if (defaultOrPrimaryModule?.versionId !== defaultOrPrimaryChangeOld) {
            newDefaultOrPrimaryVersionId = defaultOrPrimaryModule?.versionId
            if (equivalencyType == ModuleGroupType.MES) {
                substituteScores.forEach((subScore) => newReplaceMap.set(subScore, ''))
            } else {
                functionalScores.forEach((funcScore) => {
                    if (funcScore.moduleVersionScoreLabelMap.has(defaultOrPrimaryChangeOld)) {
                        newReplaceMap.set(
                            funcScore.moduleVersionScoreLabelMap.get(
                                defaultOrPrimaryChangeOld
                            ) as string,
                            ''
                        )
                    }
                })
            }
        } else if (defaultOrPrimaryChangeNew) {
            newDefaultOrPrimaryVersionId = undefined
        }
        if (newDefaultOrPrimaryVersionId !== defaultOrPrimaryChangeNew) {
            setEditInformationChangeSummary({
                ...editInformationChangeSummary,
                replacedScores: newDefaultOrPrimaryVersionId
                    ? newReplaceMap
                    : new Map<string, string>(),
                removedScores: [],
                addedPrimaryScores: [],
                defaultOrPrimaryChange: {
                    ...editInformationChangeSummary.defaultOrPrimaryChange,
                    new: newDefaultOrPrimaryVersionId,
                },
            })
        }
    }, [
        defaultOrPrimaryModule,
        editInformationChangeSummary,
        equivalencyType,
        functionalScores,
        substituteScores,
    ])

    const renderEquivalencyFooter = () => (
        <Row
            gridGap='S200'
            margin={{ top: 'S100' }}
            backgroundColor={equivalencyType ? 'neutral05' : 'yellow05'}
            padding={{ top: 'S300', left: 'S200', right: 'S300', bottom: 'S300' }}
        >
            <IconInformationFill
                size={IconSize.Small}
                color={equivalencyType ? 'neutral70' : 'yellow70'}
                aria-hidden={true}
            />
            <Text fontSize='T100' color='neutral90' lineHeight={1.3}>
                {equivalencyTypeValidation
                    ? 'There must be an equivalency type.'
                    : equivalencyFooterMessage(equivalencyType)}
            </Text>
        </Row>
    )

    const handleError = (e: Error) => {
        setSaveModuleGroupError(e)
        setGroupNameValidation(e.message)
        setValidationMessage('There is required field missing or error. Please try again.')
        console.error(e)
    }

    const createModuleGroup = async () => {
        setSaveModuleGroupError(undefined)
        try {
            let moduleGroupSaveRequest: SaveModuleGroupVersionRequest
            if (equivalencyType === ModuleGroupType.PEC) {
                moduleGroupSaveRequest = {
                    lastKnownTimeToken: Date.now(),
                    name: groupName,
                    purpose: groupPurpose,
                    moduleMappings: {
                        pec: {
                            defaultModuleVersionId: (
                                defaultOrPrimaryModule as ModuleGroupModuleMetadata
                            )?.versionId,
                            functionalScoresEnabled: functionalScoresEnabled,
                            functionalScores: functionalScores,
                            moduleVersionRuleSetList: [] as ModuleVersionRuleSetDTO[],
                        } as PECGroupDTO,
                    },
                }
            } else if (equivalencyType === ModuleGroupType.MES) {
                moduleGroupSaveRequest = {
                    lastKnownTimeToken: Date.now(),
                    name: groupName,
                    purpose: groupPurpose,
                    moduleMappings: {
                        mes: {
                            primaryModuleVersionId: defaultOrPrimaryModule?.versionId,
                            selectedPrimaryScoreLabels: substituteScores,
                            substituteModuleScores: [],
                        } as MESGroupDTO,
                    },
                }
            } else {
                console.error('Equivalency Type not recognized')
                return
            }
            const response = await ModuleGroupService.saveModuleGroupVersion(moduleGroupSaveRequest)
            ModuleGroupEntityService.insert(response)
            navigate(`/module-groups/${response.versionId}`)
        } catch (e) {
            handleError(e as Error)
        }
    }

    const saveNewModuleGroupInformation = async () => {
        if (moduleGroupEditEntity && editInformationChangeSummary && defaultOrPrimaryModule) {
            let updateModuleGroupRequest: UpdateModuleGroupRequest
            if (equivalencyType === ModuleGroupType.MES) {
                const updatedSubstituteModuleScores = ModuleGroupEditUtils.applyModuleSubScoreEdits(
                    (moduleGroupEditEntity.moduleMappings.mes as MESGroupDTO)
                        .substituteModuleScores,
                    editInformationChangeSummary.removedScores,
                    editInformationChangeSummary.replacedScores
                )
                const newSubScoreLabelList: string[] =
                    ModuleGroupEditUtils.getUpdatedSelectedPrimaryScoreLabels(
                        editInformationChangeSummary.addedPrimaryScores,
                        editInformationChangeSummary.replacedScores,
                        editInformationChangeSummary.removedScores,
                        substituteScores
                    )

                updateModuleGroupRequest = {
                    versionId: moduleGroupEditEntity.versionId,
                    lastKnownTimeToken: moduleGroupEditEntity.savedTimeToken,
                    purpose: groupPurpose,
                    moduleMappings: {
                        mes: {
                            primaryModuleVersionId: defaultOrPrimaryModule.versionId,
                            selectedPrimaryScoreLabels: newSubScoreLabelList,
                            substituteModuleScores: updatedSubstituteModuleScores,
                        },
                    },
                }
            } else {
                const updatedFunctionalScores = ModuleGroupEditUtils.applyFunctionalScoreEdits(
                    moduleGroupEditEntity.moduleMappings.pec?.functionalScores ?? [],
                    editInformationChangeSummary.removedScores,
                    editInformationChangeSummary.replacedScores,
                    editInformationChangeSummary.addedFunctionalScores,
                    editInformationChangeSummary.defaultOrPrimaryChange.old,
                    defaultOrPrimaryModule.versionId
                )

                updateModuleGroupRequest = {
                    versionId: moduleGroupEditEntity.versionId,
                    lastKnownTimeToken: moduleGroupEditEntity.savedTimeToken,
                    purpose: groupPurpose,
                    moduleMappings: {
                        pec: {
                            ...(moduleGroupEditEntity.moduleMappings.pec as PECGroupDTO),
                            defaultModuleVersionId: defaultOrPrimaryModule.versionId,
                            functionalScoresEnabled: functionalScoresEnabled,
                            functionalScores: functionalScoresEnabled
                                ? updatedFunctionalScores
                                : undefined,
                        },
                    },
                }
            }

            try {
                setSaveModuleGroupError(undefined)
                const response = await ModuleGroupService.updateModuleGroupVersionDTO(
                    updateModuleGroupRequest
                )
                setEditInformationChangeSummary(undefined)
                ModuleGroupEntityService.insertOrUpdate(response)
                navigate(`/module-groups/${response.versionId}`)
                if (setRenderOptionModal) {
                    setRenderOptionModal()
                }
            } catch (e) {
                handleError(e as Error)
            }
        }
    }

    const validateAndSaveModuleGroup = async () => {
        let validationError = false
        let noChangeError = false

        if (!groupName) {
            setGroupNameValidation('There must be a module group name.')
        }

        if (!equivalencyType) {
            setEquivalencyTypeValidation('There must be an equivalency type.')
        }

        if (!defaultOrPrimaryModule) {
            setModuleValidation('There must be a default or reference module.')
        }

        if (!groupName || !equivalencyType || !defaultOrPrimaryModule) {
            setValidationMessage(
                'There are required fields missing or fields containing an error. Please try again.'
            )
            return
        }

        if (equivalencyType === ModuleGroupType.MES) {
            ;[validationError, noChangeError] = validateMESModuleAdd()
        }

        if (equivalencyType === ModuleGroupType.PEC) {
            ;[validationError, noChangeError] = validatePECModuleAdd()
        }

        if (noChangeError) {
            return
        }

        if (!groupPurpose) {
            setGroupPurposeValidation('There must be a group purpose.')
        }

        if (isOverCharacterLimit) {
            setGroupPurposeValidation('Group purpose cannot be over 200 character limit.')
        }

        if (validationError || !groupPurpose || isOverCharacterLimit) {
            setValidationMessage(
                'There are required fields missing or fields containing an error. Please try again.'
            )
            return
        }

        if (isInEditMode) {
            await saveNewModuleGroupInformation()
        } else {
            await createModuleGroup()
        }
    }

    const getOldModuleDisplayInfo = () => {
        const oldModule = moduleGroupEditEntity?.moduleMetadataList.find(
            (module) =>
                module.versionId === editInformationChangeSummary?.defaultOrPrimaryChange.old
        )

        return oldModule ? `${oldModule.name} - ${oldModule?.versionId}` : ''
    }

    const getModuleSearchFooterInfo = () => {
        const hasNewModule = editInformationChangeSummary?.defaultOrPrimaryChange.new
        let warningNewModuleMessage: string | undefined = undefined
        if (equivalencyType === ModuleGroupType.MES) {
            warningNewModuleMessage =
                'Changing the Reference Module will require new scores to be defined. Select cancel to reset this module group.'
        } else if (
            equivalencyType === ModuleGroupType.PEC &&
            functionalScores &&
            functionalScoresEnabled
        ) {
            warningNewModuleMessage =
                'Changing the Default Module will require new scores to be defined. Select cancel to reset this module group.'
        }
        return hasNewModule ? warningNewModuleMessage : undefined
    }

    useEffect(() => {
        setMesValidations(undefined)
        setEditPECValidations(undefined)
    }, [defaultOrPrimaryModule])

    useEffect(() => {
        setValidationMessage('')
    }, [
        groupName,
        groupPurpose,
        defaultOrPrimaryModule,
        functionalScores,
        functionalScoresEnabled,
        equivalencyType,
        substituteScores,
    ])

    const onCancelPressed = () => {
        if (setShowModuleGroupBuilder) {
            setShowModuleGroupBuilder(false)
        } else if (setRenderOptionModal) {
            setRenderOptionModal()
        }
        setDefaultOrPrimaryModule(undefined)
        setGroupName('')
        setEquivalencyType(undefined)
        setGroupPurpose('')
    }

    return (
        <ModalContent
            dataTestId='module-group-builder-modal'
            titleText={isInEditMode ? 'Edit Module Group' : 'Create Module Group'}
            maxWidth='50vw'
        >
            <Col gridGap='S300'>
                {saveModuleGroupError && (
                    <MessageBanner type={MessageBannerType.Error} dataTestId='save-error-message'>
                        {saveModuleGroupError.message
                            ? saveModuleGroupError.message
                            : 'An error occurred while saving your module group, please try again later'}
                    </MessageBanner>
                )}
                <InputWrapper
                    id='module-group-name-input-wrapper'
                    labelText='Module group name'
                    required
                    error={!!groupNameValidation}
                    footer={groupNameValidation ? groupNameValidation : undefined}
                >
                    {(inputProps) => (
                        <Input
                            dataTestId='module-group-name-input-create'
                            {...inputProps}
                            value={groupName}
                            onChange={(e) => {
                                setGroupName(e.target.value)
                                setGroupNameValidation('')
                            }}
                            disabled={isInEditMode}
                        />
                    )}
                </InputWrapper>
                <InputWrapper
                    id='module-group-equivalency-input-wrapper'
                    labelText='Type of equivalency'
                    error={!!equivalencyTypeValidation}
                    footer={renderEquivalencyFooter}
                    required
                >
                    {(inputProps) => (
                        <Select
                            key={equivalencyType}
                            dataTestId='module-group-equivalency-input'
                            {...inputProps}
                            value={
                                moduleGroupEditEntity
                                    ? ModuleGroupService.getModuleGroupType(moduleGroupEditEntity)
                                    : equivalencyType
                            }
                            options={Object.values(ModuleGroupType)}
                            onChange={(value) => {
                                setEquivalencyType(value as ModuleGroupType)
                                setEquivalencyTypeValidation('')
                            }}
                            disabled={isInEditMode}
                        />
                    )}
                </InputWrapper>
                {!moduleGroupEditEntity && (
                    <MessageBanner
                        type={MessageBannerType.Warning}
                        isDismissible={true}
                        dataTestId='warning-ats-banner'
                    >
                        Hire is the only ATS that can utilize this type of equivalency.
                    </MessageBanner>
                )}
                {editInformationChangeSummary?.defaultOrPrimaryChange.new && (
                    <InputWrapper
                        id='old=module-name'
                        labelText={
                            equivalencyType === ModuleGroupType.MES
                                ? 'Current Reference Module'
                                : 'Current Default Module'
                        }
                        warning={!!getModuleSearchFooterInfo()}
                        footer={getModuleSearchFooterInfo()}
                    >
                        {(inputProps) => (
                            <Input {...inputProps} value={getOldModuleDisplayInfo()} disabled />
                        )}
                    </InputWrapper>
                )}
                {equivalencyType && (
                    <ModuleSearchSelect
                        equivalencyType={equivalencyType}
                        defaultOrPrimaryModule={defaultOrPrimaryModule}
                        setDefaultOrPrimaryModule={setDefaultOrPrimaryModule}
                        setModuleScores={setModuleScores}
                        setIsFetching={setIsFetching}
                        setLoadModuleError={setLoadModuleError}
                        moduleValidation={moduleValidation}
                        setModuleValidation={setModuleValidation}
                    />
                )}
                {defaultOrPrimaryModule && moduleScores.length === 0 && !isFetching && (
                    <MessageBanner
                        type={MessageBannerType.Warning}
                        dataTestId='warning-scores-banner'
                    >
                        This module does not have scores.
                    </MessageBanner>
                )}
                {!isInEditMode && (
                    <>
                        {equivalencyType === ModuleGroupType.MES && (
                            <SubstituteScoresCreate
                                substituteScores={
                                    substituteScores && substituteScores.length > 0
                                        ? substituteScores
                                        : ['']
                                }
                                setSubstituteScores={setSubstituteScores}
                                primaryModuleScores={moduleScores}
                                loadPrimaryModuleError={loadModuleError}
                                primaryModuleVersionId={defaultOrPrimaryModule?.versionId}
                                mesValidation={mesValidations}
                                setMesValidation={setMesValidations}
                            />
                        )}
                        {equivalencyType === ModuleGroupType.PEC &&
                            defaultOrPrimaryModule &&
                            !isFetching && (
                                <ToggleFunctionalScore
                                    functionalScoreEnabled={functionalScoresEnabled}
                                    defaultAssessmentModule={defaultOrPrimaryModule}
                                    functionalScores={functionalScores}
                                    setFunctionalScoreEnabled={setFunctionalScoresEnabled}
                                    setFunctionalScores={setFunctionalScores}
                                    moduleScores={moduleScores}
                                    pecValidations={editPECValidations}
                                    setPecValidations={setEditPECValidations}
                                    setValidationMessage={setValidationMessage}
                                />
                            )}
                    </>
                )}
                {isInEditMode && editInformationChangeSummary && (
                    <>
                        {equivalencyType === ModuleGroupType.MES && defaultOrPrimaryModule && (
                            <SubstituteScoresEdit
                                editModuleGroupInformationSummary={editInformationChangeSummary}
                                setEditModuleGroupInformationSummary={
                                    setEditInformationChangeSummary
                                }
                                primaryModuleVersionId={defaultOrPrimaryModule.versionId}
                                primaryModuleScores={moduleScores}
                                existingSubstituteScores={substituteScores}
                                mesValidation={mesValidations}
                                setMesValidation={setMesValidations}
                                setValidationMessage={setValidationMessage}
                            />
                        )}
                        {equivalencyType === ModuleGroupType.PEC && defaultOrPrimaryModule && (
                            <FunctionalScoresEdit
                                editInformationSummary={editInformationChangeSummary}
                                setEditInformationSummary={setEditInformationChangeSummary}
                                functionalScores={functionalScores}
                                defaultModuleVersionId={defaultOrPrimaryModule.versionId}
                                defaultModuleScores={moduleScores}
                                functionalScoresEnabled={functionalScoresEnabled}
                                setFunctionalScoresEnabled={setFunctionalScoresEnabled}
                                editPECValidations={editPECValidations}
                                setEditPECValidations={setEditPECValidations}
                                setValidationMessage={setValidationMessage}
                            />
                        )}
                    </>
                )}
                <TextAreaWithCharacterLimit
                    textValue={groupPurpose}
                    characterLimit={200}
                    setTextValue={setGroupPurpose}
                    labelText='Purpose of group'
                    isRequired={true}
                    setIsOverCharacterLimit={setIsOverCharacterLimit}
                    dataTestId={'group-purpose-text-area'}
                    placeholder={getPurposePlaceholder(equivalencyType, functionalScoresEnabled)}
                    textAreaValidation={groupPurposeValidation}
                    setTextAreaValidation={setGroupPurposeValidation}
                />
                {validationMessage && (
                    <MessageBanner
                        type={MessageBannerType.Error}
                        dataTestId='add-edit-modal-validation-banner'
                    >
                        {validationMessage}
                    </MessageBanner>
                )}
                <Spacer height='S300' />
                <Row gridGap='S400'>
                    <Spacer width='40%' />
                    <Button onClick={onCancelPressed}>Cancel</Button>

                    <Button
                        dataTestId={'save-module-group-button'}
                        onClick={() => validateAndSaveModuleGroup()}
                        variant={ButtonVariant.Primary}
                    >
                        {!moduleGroupEditEntity || isModuleGroupInDraftMode(moduleGroupEditEntity)
                            ? 'Save group'
                            : 'Create New Draft'}
                    </Button>
                </Row>
            </Col>
        </ModalContent>
    )
}

export const ModuleGroupBuilderModal = ({
    showModuleGroupBuilder,
    setShowModuleGroupBuilder,
}: {
    showModuleGroupBuilder: boolean
    setShowModuleGroupBuilder: (show: boolean) => void
}) => {
    return (
        <>
            <Modal
                isOpen={showModuleGroupBuilder}
                close={() => setShowModuleGroupBuilder(false)}
                isScrollable={false}
            >
                {RenderModuleGroupBuilderModal({
                    setShowModuleGroupBuilder: setShowModuleGroupBuilder,
                })}
            </Modal>
        </>
    )
}
