import { v4 } from 'uuid'

import { ConditionalBranchingLogicDTO } from 'src/models/dto/ConditionalBranchingLogicDTO'
import { ContextBoxDTO } from 'src/models/dto/ContextBoxDTO'
import { LocalizedAttribute } from 'src/models/dto/Locale'
import { ActivityEntity, ActivityType } from '../ActivityDTO'
import { ItemDTO } from '../items/ItemDTO'

export type ItemGroupEntity = ActivityEntity &
    Omit<ItemGroupDTO, 'items' | 'contextBox'> & {
        itemIds: string[]
        contextBoxId?: string
    }

export interface ItemGroupDTO {
    id: string
    name: string // This is referenceId
    items: ItemDTO[]
    description?: string
    randomizationEnabled: boolean
    type: ActivityType
    ppt: string
    labels?: string[]
    headerI18N?: LocalizedAttribute<string>
    bodyI18N?: LocalizedAttribute<string>
    branchingConditions?: ConditionalBranchingLogicDTO
    contextBox?: ContextBoxDTO
}

export const defaultItemGroupDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        items: [],
        randomizationEnabled: false,
        type: ActivityType.LaunchItemGroup,
        ppt: ActivityType.LaunchItemGroup,
        labels: [],
    }
}
