export interface TemplateValidationError {
    versionId: string
    message: string
    attributePath?: string
}

export const GENERIC_ERROR_KEY = 'GENERIC_ERROR_KEY'

export interface ValidationErrorEntity {
    id: string
    validationErrors: Record<string, string[]> // a map of attributes path and list of error messages
}
