import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface RatingScaleDTO {
    name: string
    nameI18N: LocalizedAttribute<string>
    score: number
}

export const defaultRatingScale = () =>
    ({
        name: '',
        nameI18N: LocalizeDefault(''),
        score: 0,
    } as RatingScaleDTO)

export const MAX_RATING_SCALE = 6
export const MIN_RATING_SCALE = 4

export interface RatingItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.Rating
    preserveOrder: boolean
    questionI18N: LocalizedAttribute<string>
    scales: RatingScaleDTO[]
}

export const defaultRatingItem = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.Rating,
        ppt: ItemType.Rating,
        preserveOrder: false,
        questionI18N: LocalizeDefault(''),
        scales: [
            defaultRatingScale(),
            defaultRatingScale(),
            defaultRatingScale(),
            defaultRatingScale(),
        ],
    } as RatingItemDTO
}
