import { cloneDeep } from 'lodash'

import {
    defaultLikertGroupDTO,
    defaultLikertStimulusDTO,
    defaultStimulusReferenceDTO,
    LikertGroupDTO,
    LikertItemDTO,
} from 'src/models/dto/items/LikertItemDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemDTO, ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.LikertGroup

export class LikertGroupHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => LikertGroupHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): LikertGroupDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as LikertGroupDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): LikertGroupDTO {
        return defaultLikertGroupDTO()
    }

    private static update(entity: LikertGroupDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as LikertGroupDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as LikertGroupDTO)
    }

    static updateStatement(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.header.statementI18N[locale] = nextValue
        this.update(entity)
    }

    static updateScale(id: string, nextValue: LocalizedAttribute<string>[]) {
        this.update({
            ...this.getEntity(id),
            header: {
                ...this.getEntity(id).header,
                scaleI18N: nextValue,
            },
        } as LikertGroupDTO)
    }

    static updateScores(id: string, index: number, nextValue: string[]) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.stimulus)
        entity.stimulus[index].stimulusV2DTO.scores = nextValue
        this.update(entity)
    }

    static addStimulus(id: string) {
        const entity = this.getEntity(id)
        entity.stimulus.push(defaultStimulusReferenceDTO())
        this.update(entity)
    }

    private static checkArrayBounds<T>(index: number, arr: T[]) {
        if (arr && (index < 0 || index >= arr.length)) {
            throw new Error(`Index out of bounds: Index: ${index}, Size: ${arr.length}`)
        }
    }

    static updateStimulus(id: string, index: number, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.stimulus)
        entity.stimulus[index].stimulusV2DTO.stimulusI18N[locale] = nextValue
        this.update(entity)
    }

    static updateStimulusLabel(id: string, index: number, nextValue: string) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.stimulus)
        entity.stimulus[index].stimulusV2DTO.label = nextValue
        this.update(entity)
    }

    static updateStimulusOptional(id: string, index: number, nextValue: boolean) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.stimulus)
        entity.stimulus[index].optional = nextValue
        this.update(entity)
    }

    static deleteStimulus(id: string, index: number) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.stimulus)
        entity.stimulus.splice(index, 1)
        this.update(entity)
    }

    static updateGroupSize(id: string, nextValue: number | undefined) {
        this.update({
            ...this.getEntity(id),
            groupSize: nextValue,
        } as LikertGroupDTO)
    }

    static mapOldLikert(likertDTO: LikertItemDTO): LikertGroupDTO {
        const likertGroupDTO = defaultLikertGroupDTO()
        ;(likertGroupDTO.optional = likertDTO.optional), (likertGroupDTO.label = likertDTO.label)
        likertGroupDTO.locale = likertDTO.locale
        likertGroupDTO.localeWiseMedia = cloneDeep(likertDTO.localeWiseMedia)
        likertGroupDTO.groupSize = likertDTO.rowStatementObjects.length
        likertGroupDTO.header.statementI18N = cloneDeep(likertDTO.statementI18N)
        likertGroupDTO.header.scaleI18N = likertDTO.scales.map((scale) => cloneDeep(scale.nameI18N))
        likertGroupDTO.stimulus = likertDTO.rowStatementObjects.map((rowStatement) => {
            const stimulusDTO = defaultLikertStimulusDTO()
            stimulusDTO.label = cloneDeep(rowStatement.rowStatementLabel)
            stimulusDTO.stimulusI18N = cloneDeep(rowStatement.rowStatementI18N)
            stimulusDTO.scores = rowStatement.rowStatementScores.map((row) => row.score.toString())
            return {
                stimulusV2DTO: stimulusDTO,
                optional: false,
            }
        })

        return likertGroupDTO
    }
}
