import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface WritingExerciseItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.WritingExercise
    titleI18N: LocalizedAttribute<string>
    instructionI18N: LocalizedAttribute<string>
    wordLimit: number
    minWordLimit: number
}

export const defaultWritingExerciseItemDTO = (): WritingExerciseItemDTO => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        minWordLimit: 0,
        wordLimit: 8000,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.WritingExercise,
        ppt: ItemType.WritingExercise,
        titleI18N: LocalizeDefault<string>(''),
        instructionI18N: LocalizeDefault<string>(''),
    }
}
