import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ModuleGroupDTO } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { moduleGroupViewerRoute } from 'src/pages/module-review'
import { ModuleGroupEntityService } from 'src/services/EntityServices/ModuleGroupEntityService'
import {
    ModuleGroupService,
    SaveModuleGroupVersionRequest,
} from 'src/services/module-groups/ModuleGroupService'

export interface DuplicateModuleGroupModalProps {
    close: () => void
    selectedModuleGroupName: string
    selectedModuleGroupId: string
}

export function DuplicateModuleGroupModal({
    close,
    selectedModuleGroupName,
    selectedModuleGroupId,
}: DuplicateModuleGroupModalProps) {
    const [newModuleGroupName, setNewModuleGroupName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showError, setShowError] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const submit = useCallback(async () => {
        if (!newModuleGroupName) {
            setErrorMessage('There must be a module group name.')
            return
        }
        setLoading(true)
        try {
            const moduleGroupCached = ModuleGroupEntityService.has(selectedModuleGroupId)
            if (!moduleGroupCached) {
                const dto: ModuleGroupDTO = await ModuleGroupService.loadModuleGroupVersionDTO(
                    selectedModuleGroupId
                )
                ModuleGroupEntityService.insert(dto)
            }
            const moduleGroup = ModuleGroupEntityService.get(selectedModuleGroupId)
            const moduleGroupSaveRequest: SaveModuleGroupVersionRequest = {
                lastKnownTimeToken: Date.now(),
                name: newModuleGroupName,
                purpose: moduleGroup.purpose,
                moduleMappings: moduleGroup.moduleMappings,
            }
            const response = await ModuleGroupService.saveModuleGroupVersion(moduleGroupSaveRequest)
            setLoading(false)
            close()
            navigate(
                moduleGroupViewerRoute({
                    moduleGroupVersionId: response.versionId,
                })
            )
        } catch (e: unknown) {
            const err = (e as ErrorResponseThrowable).errorResponse

            if (err && err.message) {
                setErrorMessage(err.message)
            } else {
                setShowError(true)
            }
            setLoading(false)
        }
    }, [close, navigate, newModuleGroupName, selectedModuleGroupId])

    return (
        <ModalContent
            titleText='Duplicate module group'
            buttons={[
                <Button key='close-modal' dataTestId='close-modal' onClick={close}>
                    Cancel
                </Button>,
                <Button
                    key='submit'
                    dataTestId='submit-duplicate-module'
                    variant={ButtonVariant.Primary}
                    onClick={submit}
                >
                    {loading ? (
                        <Spinner dataTestId='duplicate-spinner' key='spinner' />
                    ) : (
                        <>Yes, duplicate</>
                    )}
                </Button>,
            ]}
        >
            <Col gridGap='S300'>
                {showError && (
                    <MessageBanner type={MessageBannerType.Error} isDismissible={true}>
                        <Text fontSize={'T100'}>Something went wrong, please try again.</Text>
                    </MessageBanner>
                )}
                <MessageBanner type={MessageBannerType.Informational}>
                    Module group to duplicate: <strong>{selectedModuleGroupName}</strong>
                </MessageBanner>
                <Text>
                    Do you want to create a copy of this module group version? You must provide a
                    new unique name.
                    <Spacer height='S200' />
                </Text>
                <InputWrapper
                    id='duplicate-module-group-new-module-group-name'
                    labelText='Unique module group name'
                    tooltipText='Once set, the module group name cannot be changed or updated.'
                    required={true}
                    error={!!errorMessage}
                    footer={errorMessage ? errorMessage : undefined}
                >
                    {(props) => (
                        <Input
                            dataTestId='duplicate-module-group-new-module-group-name'
                            type='text'
                            name={'new-module-group-text'}
                            value={newModuleGroupName}
                            onChange={(e) => {
                                setNewModuleGroupName(e.target.value)
                                setErrorMessage('')
                            }}
                            width='100%'
                            placeholder='Enter a module group name'
                            {...props}
                        />
                    )}
                </InputWrapper>
            </Col>
        </ModalContent>
    )
}
