import React, { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { DateTime } from 'luxon'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import {
    createSvg,
    IconAlertCircleFill,
    IconMenu,
    IconSize,
    SVGIcon,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Link, LinkProps } from '@amzn/stencil-react-components/link'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import {
    defaultCardRenderer,
    OnSortCallback,
    RowData,
    SortDirection,
    Table,
    TableColumn,
} from '@amzn/stencil-react-components/table'
import { H3, H4, Text } from '@amzn/stencil-react-components/text'
import { ToggleButton, ToggleButtonGroup } from '@amzn/stencil-react-components/toggle-button'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

import { RawMediaView } from 'src/components/media/RawMediaView'
import { APP_CONFIG } from 'src/config.app'
import { Locale, LocaleDescription } from 'src/models/dto/Locale'
import { MediaTemplateDTO, MediaUsedByDTO } from 'src/models/media/MediaTemplateDTO'
import {
    DisplayType,
    MediaTemplate,
    OrderBy,
    PageOfMedia,
    SearchQuery,
} from 'src/pages/asset-library/types'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/pages/search/SearchResultsPage'
import { ApiActionNames } from 'src/services/AxiosInterceptor'
import { MediaService } from 'src/services/media/MediaService'
import { MediaFileType } from '../../../cypress/pages/assetlibrary/MediaFileType'

// @ts-ignore: Expression produces a union type that is too complex to represent error
const LinkWithTooltip = withTooltip<Omit<LinkProps, 'ref'>>({
    useTriggerWrapper: false,
})(Link)
const MAX_LENGTH = 12
const STANDARD_SIZE = 20

export function LocalesCell({ data }: { data: Locale[] }) {
    const formatted = data.join(', ')
    const trimmed = `${formatted.substring(0, MAX_LENGTH)}...`

    const toolTipText = data
        .map((l) => LocaleDescription[l])
        .sort()
        .join(', ')

    return (
        <LinkWithTooltip tooltipText={toolTipText} color='primary70'>
            {trimmed}
        </LinkWithTooltip>
    )
}

function PublishedCell({ data: { published } }: { data: { published: boolean } }) {
    const {
        matches: { s: small },
    } = useBreakpoints()

    return (
        <Col alignItems={small ? 'start' : 'center'}>{published ? 'Published' : 'Unpublished'}</Col>
    )
}

function UsedCell({ data: { used, versionId } }: { data: { used: number; versionId: string } }) {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const [{ loading, data: currentMedia }, executeGetMedia] = useAxios<MediaTemplateDTO>(
        {
            method: 'GET',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media-templates/${versionId}`,
            apiActionName: ApiActionNames.GetMediaTemplate,
        },
        { useCache: false, manual: true }
    )

    const Content = () => {
        if (currentMedia) {
            if (currentMedia.usedByCount === 0) {
                return <Text>This asset is not used by any Modules yet.</Text>
            }
            return (
                <Table
                    columns={[
                        {
                            header: 'Module Name',
                            accessor: ({ data }: RowData<MediaUsedByDTO>) => data.moduleName,
                        },
                        {
                            header: 'Module Version',
                            accessor: ({ data }: RowData<MediaUsedByDTO>) => ({
                                moduleVersionId: data.moduleVersionId,
                            }),
                            cellComponent: ({
                                data: { moduleVersionId },
                            }: {
                                data: { moduleVersionId: string }
                            }) => {
                                return (
                                    <RouterLink
                                        target={'_blank'}
                                        to={`/module-builder?moduleVersionId=${moduleVersionId}`}
                                    >
                                        <strong>{moduleVersionId}</strong>
                                    </RouterLink>
                                )
                            },
                        },
                    ]}
                    data={currentMedia.usedByList}
                />
            )
        } else if (loading) {
            return <Spinner />
        }

        return (
            <MessageBanner type={MessageBannerType.Error}>
                An error occurred while trying to fetch asset with ID: {versionId}, please try
                again.
            </MessageBanner>
        )
    }

    const renderModal = ({ close }) => (
        <ModalContent
            titleText='Modules Using This Asset'
            buttons={[
                <Button
                    key='submit'
                    dataTestId='close-used-by-modules-button'
                    onClick={close}
                    variant={ButtonVariant.Primary}
                >
                    Close
                </Button>,
            ]}
        >
            <Content />
        </ModalContent>
    )

    return (
        <WithModal renderModal={renderModal}>
            {({ open }) => (
                <Col alignItems={small ? 'start' : 'center'}>
                    <Button
                        aria-label={`Asset used by ${used} modules`}
                        size={ButtonSize.Small}
                        variant={ButtonVariant.Tertiary}
                        onClick={async () => {
                            open()
                            await executeGetMedia().catch((err) =>
                                console.error('Something went wrong:', err)
                            )
                        }}
                    >
                        <Text fontSize={'T100'}>{used}</Text>
                    </Button>
                </Col>
            )}
        </WithModal>
    )
}

export function ModifiedCell({ data: { date, user } }: { data: { date: string; user: string } }) {
    const formattedDate = DateTime.fromISO(date, {
        zone: 'UTC',
    })
        .setZone('system')
        .toLocaleString({ month: 'short', day: '2-digit', year: 'numeric' })

    return (
        <Col>
            <Text fontSize='T100'> {formattedDate} </Text>
            <Text color='neutral70' fontSize='T100'>
                {' '}
                by{' '}
            </Text>
            <Text fontSize='T100'>
                {user !== 'migrationBackfill' ? (
                    <LinkWithTooltip
                        target='_blank'
                        href={`https://phonetool.amazon.com/users/${user}`}
                        tooltipText='Opens user in Phone Tool'
                    >
                        {user}
                    </LinkWithTooltip>
                ) : (
                    <Text fontSize='T100'>
                        <LinkWithTooltip tooltipText='Created by the Designer Team backfilling script'>
                            {user}
                        </LinkWithTooltip>
                    </Text>
                )}
            </Text>
        </Col>
    )
}

const assignMediaToModuleColumns = [
    {
        header: 'File Name',
        sortId: OrderBy.DEFAULT_FILENAME,
        accessor: 'defaultFileName',
        textAlign: 'left',
        width: '15%',
    },
    {
        header: 'Locales',
        cellComponent: LocalesCell,
        accessor: ({ data }: RowData<MediaTemplate>) => data.i18NContent.map((c) => c.locale),
        width: '18%',
        textAlign: 'center',
    },
    {
        header: 'Created',
        sortId: OrderBy.CREATED_ON,
        cellComponent: ModifiedCell,
        accessor: ({ data }: RowData<MediaTemplate>) => ({
            date: data.createdAt,
            user: data.createdBy,
        }),
        textAlign: 'right',
    },
]

const publishedColumn = {
    header: 'Published',
    sortId: OrderBy.ENGLISH_PUBLISHED,
    textAlign: 'center',
    cellComponent: PublishedCell,
    accessor: ({ data }: RowData<MediaTemplate>) =>
        data.i18NContent.find((x) => x.locale === Locale.en_US) ?? { published: false },
}

const usedColumn = {
    header: 'Used',
    sortId: OrderBy.USED,
    textAlign: 'left',
    cellComponent: UsedCell,
    accessor: ({ data }: RowData<MediaTemplate>) => ({
        used: data.used,
        versionId: data.versionId,
    }),
}

export const IconThumbnail = createSvg(
    'IconPlus', // name
    new SVGIcon(
        [
            // paths
            'M 0.613281 10.746094 L 8.398438 10.746094 C 8.730469 10.746094 9.003906 11.066406 9.003906 11.460938 L 9.003906 19.019531 C 9.003906 19.414062 8.730469 19.738281 8.398438 19.738281 L 0.613281 19.738281 C 0.28125 19.738281 0.0117188 19.414062 0.0117188 19.019531 L 0.0117188 11.460938 C 0.0117188 11.066406 0.28125 10.746094 0.613281 10.746094 Z M 11.582031 0 L 19.367188 0 C 19.699219 0 19.96875 0.324219 19.96875 0.71875 L 19.96875 8.277344 C 19.96875 8.671875 19.699219 8.992188 19.367188 8.992188 L 11.582031 8.992188 C 11.25 8.992188 10.976562 8.671875 10.976562 8.277344 L 10.976562 0.71875 C 10.976562 0.324219 11.25 0 11.582031 0 Z M 0.605469 0 L 8.390625 0 C 8.722656 0 8.992188 0.324219 8.992188 0.71875 L 8.992188 8.277344 C 8.992188 8.671875 8.722656 8.992188 8.390625 8.992188 L 0.605469 8.992188 C 0.273438 8.992188 0 8.671875 0 8.277344 L 0 0.71875 C 0 0.324219 0.273438 0 0.605469 0 Z M 11.589844 10.9375 L 19.375 10.9375 C 19.707031 10.9375 19.980469 11.257812 19.980469 11.65625 L 19.980469 19.210938 C 19.980469 19.605469 19.707031 19.929688 19.375 19.929688 L 11.589844 19.929688 C 11.257812 19.929688 10.988281 19.605469 10.988281 19.210938 L 10.988281 11.65625 C 10.988281 11.257812 11.257812 10.9375 11.589844 10.9375 Z M 11.589844 10.9375',
        ],
        { width: '20', height: '20' },
        STANDARD_SIZE,
        STANDARD_SIZE
    )
)

const SearchResultsHeader = (props: {
    searchTerm: string
    shownResults: number
    totalResults: number
    option: string
    handleOption(event, selectedOptions: string): void
}) => {
    const { shownResults, totalResults } = props
    const layout = useLayoutDirection()

    return (
        <View
            data-search-term={props.searchTerm}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='100%'
        >
            {layout !== LAYOUT_DIRECTION.MOBILE && (
                <H3 dataTestId='search-results-header-title'>
                    <strong>{`Showing ${shownResults} out of ${totalResults} results for:`}</strong>
                    {` "${props.searchTerm}"`}
                </H3>
            )}
            <Spacer flex={1} />
            <ToggleButtonGroup onChange={props.handleOption} value={props.option}>
                <ToggleButton
                    value='list'
                    icon={<IconMenu aria-hidden />}
                    dataTestId={'toggle-list'}
                />
                <ToggleButton
                    value='thumbnail'
                    icon={<IconThumbnail aria-hidden />}
                    dataTestId={'toggle-thumbnail'}
                />
            </ToggleButtonGroup>
        </View>
    )
}

const Thumbnail = ({ media }: { media: MediaTemplate }) => {
    const [fileUrl, setFileUrl] = useState<string>('')
    const [assetLoading, setAssetLoading] = useState<boolean>(true)
    const [errorLoadingAsset, setErrorLoadingAsset] = useState<boolean>(false)

    useEffect(() => {
        if (media.defaultFilePath && media.defaultFilePath.length > 0) {
            MediaService.getFromS3(media.defaultFilePath)
                .then((val) => {
                    setFileUrl(val)
                    setAssetLoading(false)
                    setErrorLoadingAsset(false)
                })
                .catch((e) => {
                    console.error(e)
                    setAssetLoading(false)
                    setErrorLoadingAsset(true)
                })
        }
    }, [media.defaultFilePath])

    if (assetLoading) {
        return <Spinner dataTestId={'thumbnail-asset-loading'} />
    }

    if (errorLoadingAsset) {
        return (
            <MessageBanner type={MessageBannerType.Error}>
                Error previewing asset. Please try refreshing the page.
            </MessageBanner>
        )
    }

    return (
        <RawMediaView
            url={fileUrl}
            mimeType={MediaFileType[media.fileType]}
            controls
            startExpanded={true}
            preload={'none'}
        />
    )
}

const ThumbnailView = ({
    data,
    displayType,
    selectView,
    selectUse,
}: {
    data: MediaTemplate[]
    displayType: string
    selectUse?: (mediaVersionId: string) => void
    selectView?: (mediaVersionId: string) => void
}) => {
    const Title = (titleProps: { media: MediaTemplate }) => {
        if (displayType === DisplayType.ASSET_LIBRARY_PAGE) {
            return (
                <RouterLink to={`/asset-library/assets/${titleProps.media.versionId}`}>
                    <Text wordBreak={'break-all'} fontSize={'T200'}>
                        <strong>{titleProps.media.title}</strong>
                    </Text>
                </RouterLink>
            )
        }
        return (
            <Text wordBreak={'break-all'} fontSize={'T200'}>
                <strong>{titleProps.media.title}</strong>
            </Text>
        )
    }

    return (
        <>
            <Row gridGap={'S200'} maxWidth={'100%'} overflow={'scroll'} flexWrap={'wrap'}>
                {data.map((media) => {
                    return (
                        <Card minHeight={220} width={200} padding={'S200'} key={media.versionId}>
                            <Col width='100%'>
                                <Flex
                                    height={'60%'}
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                >
                                    <Thumbnail media={media} />
                                </Flex>
                                <Spacer flex={1} />
                                <Title media={media} />
                                <Row>
                                    <Text fontSize={'T200'}>
                                        Added:{' '}
                                        {new Intl.DateTimeFormat('en-US').format(
                                            new Date(media.createdAt)
                                        )}
                                    </Text>
                                </Row>
                                {selectUse && selectView && (
                                    <Row>
                                        <Button
                                            size={ButtonSize.Small}
                                            variant={ButtonVariant.Tertiary}
                                            dataTestId='use-search-result'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                selectUse(media.versionId)
                                            }}
                                        >
                                            Use
                                        </Button>
                                        <Button
                                            size={ButtonSize.Small}
                                            variant={ButtonVariant.Tertiary}
                                            dataTestId='view-search-result'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                selectView(media.versionId)
                                            }}
                                        >
                                            View
                                        </Button>
                                    </Row>
                                )}
                            </Col>
                        </Card>
                    )
                })}
            </Row>
        </>
    )
}

export function SearchResults({
    loading,
    currentPage,
    currentSearchQuery,
    activeSortId,
    sortDirection,
    onSort,
    error,
    actionsColumn,
    displayType,
    onPageSelect,
    selectUse,
    selectView,
}: {
    loading: boolean
    currentPage: PageOfMedia
    currentSearchQuery: SearchQuery
    activeSortId?: string
    sortDirection: SortDirection
    onSort: OnSortCallback
    error: boolean
    actionsColumn?: TableColumn<MediaTemplate>
    displayType: DisplayType
    onPageSelect: (page: number) => void
    selectUse?: (mediaVersionId: string) => void
    selectView?: (mediaVersionId: string) => void
}) {
    const titleColumn = useMemo(
        () => ({
            header: 'Media Title',
            sortId: OrderBy.TITLE,
            textAlign: 'left',
            cellComponent: ({
                data: { versionId, title },
            }: {
                data: { versionId: string; title: string }
            }) => {
                if (displayType === DisplayType.ASSET_LIBRARY_PAGE) {
                    return (
                        <RouterLink to={`/asset-library/assets/${versionId}`}>
                            <strong>{title}</strong>
                        </RouterLink>
                    )
                }
                return <strong>{title}</strong>
            },
            accessor: ({ data }: RowData<MediaTemplate>) => ({
                versionId: data.versionId,
                title: data.title,
            }),
            width: '30%',
        }),
        [displayType]
    )

    const columns = useMemo(
        () => [
            ...(displayType === DisplayType.ASSET_LIBRARY_PAGE ? [publishedColumn] : []),
            usedColumn,
            titleColumn,
            ...assignMediaToModuleColumns,
            ...(actionsColumn ? [actionsColumn] : []),
        ],
        [titleColumn, actionsColumn, displayType]
    )
    const renderCardForRow = defaultCardRenderer({
        // @ts-ignore: Bug with type check where it thinks textAlign is invalid as a string
        columns,
        useCellComponents: true,
    })
    const [option, setOptions] = useState('list')

    const handleOption = (event, selectedOptions: string) => {
        setOptions(selectedOptions)
    }

    useEffect(() => {
        if (!option) {
            setOptions('list')
        }
    }, [option])

    if (error) {
        return (
            <Col
                display='flex'
                justifyContent='flex-start'
                width='100%'
                alignItems='center'
                gridGap='S200'
                padding={{ top: 'S200' }}
            >
                <Text fontSize={'T500'} textAlign={'center'} color='neutral90'>
                    Oops, an unexpected error occurred on our end.
                </Text>
                <Text fontSize={'T400'} color='neutral70'>
                    Please try searching again.
                </Text>
                <IconAlertCircleFill color={'red70'} size={IconSize.Medium} />
            </Col>
        )
    }

    if (loading) {
        return (
            <View
                display='flex'
                justifyContent='center'
                width='100%'
                alignItems='center'
                height='100%'
            >
                <Spinner dataTestId='search-results-table-spinner' />
            </View>
        )
    }

    if (currentPage.mediaTemplates.length === 0) {
        return (
            <View display='flex' justifyContent='center' width='100%' alignItems='center'>
                <H4
                    color='neutral70'
                    textAlign='center'
                    data-search-term={currentSearchQuery.search}
                    data-no-search-results
                >
                    {'No results found'} <br /> {'Please search again.'}
                </H4>
            </View>
        )
    }

    return (
        <View tabIndex={-1} flex={1}>
            <Col gridGap='16px' width='100%'>
                <SearchResultsHeader
                    searchTerm={currentSearchQuery.search}
                    option={option}
                    handleOption={handleOption}
                    shownResults={currentPage.mediaTemplates.length}
                    totalResults={currentPage.totalNumberOfResults}
                />
                {option === 'list' && (
                    <Table
                        maxWidth='100%'
                        // @ts-ignore: Bug with type check where it thinks textAlign is invalid as a string
                        columns={columns}
                        data={currentPage?.mediaTemplates ?? []}
                        activeSortId={activeSortId}
                        sortDirection={sortDirection}
                        onSort={onSort}
                        renderCardForRow={renderCardForRow}
                        dataTestId='search-results-table'
                    />
                )}
                {option === 'thumbnail' && (
                    <ThumbnailView
                        data={currentPage?.mediaTemplates ?? []}
                        displayType={displayType}
                        selectUse={selectUse}
                        selectView={selectView}
                    />
                )}
                {(currentPage?.numberOfPages ?? 0) > 1 && (
                    <Pagination
                        numberOfPages={currentPage?.numberOfPages ?? 0}
                        onPageSelect={onPageSelect}
                        selectedPage={currentPage?.pageIndex + 1}
                    />
                )}
            </Col>
        </View>
    )
}
