import React from 'react'

import { Breadcrumbs } from '@amzn/stencil-react-components/breadcrumbs'

export const ScoringTestingBreadCrumbs = ({
    scoringTestingResult,
    workflowId,
}: {
    scoringTestingResult?: string
    workflowId?: string
}) => {
    const getHeaderBreadCrumbs = () => {
        const breadCrumbs = [
            {
                title: 'Workbench',
                url: '/',
            },
            {
                title: 'Automated scoring tool',
                url: '/scoring-test-v2',
            },
        ]
        if (scoringTestingResult && workflowId) {
            breadCrumbs.push({
                title: 'Test results',
                url: `/scoring-test-v2/${scoringTestingResult}`,
            })
        }
        return breadCrumbs
    }

    return <Breadcrumbs crumbs={getHeaderBreadCrumbs()} />
}
