import React from 'react'

import { Row } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import { useEntity } from 'src/hooks/useEntity'
import { MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import { PairingTableCellSummary } from 'src/pages/pairing-table-generator/PairingTableCellVisual'
import { ACTIVITY_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ActivityEntityService'

export interface SelectedMUPPPairingTableProps {
    muppExamId: string
}

export const SelectedMUPPPairingTable = (props: SelectedMUPPPairingTableProps) => {
    const { entity: muppExamDTO } = useEntity<MUPPExamEntity>({
        entityId: props.muppExamId,
        selector: ACTIVITY_ENTITY_STORE_SELECTOR,
    })

    if (muppExamDTO.selection.pairingTableDTO.dimensionPairs.length > 0) {
        return (
            <Row dataTestId={'selectedPairingTable'}>
                <PairingTableCellSummary data={muppExamDTO.selection.pairingTableDTO} />
            </Row>
        )
    }

    return (
        <Text fontSize='T200' textAlign='center' dataTestId={'no-selected-table-text'}>
            No Pairing Table Selected, Please Create and Select One...{' '}
        </Text>
    )
}
