import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { H3, H4 } from '@amzn/stencil-react-components/text'

export const ClickableH3 = styledWithTheme(H3)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.selectors.color('neutral90'),
    ':hover': {
        color: theme.selectors.color('primary80'),
    },
}))

export const ClickableH4 = styledWithTheme(H4)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.selectors.color('neutral90'),
    ':hover': {
        color: theme.selectors.color('primary80'),
    },
}))
