import { v4 } from 'uuid'

import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'
import { ItemDTO, ItemType } from './ItemDTO'

export const FlyoutSectionTypeList = [
    'Overview',
    'Inbox',
    'TechnicalSupport',
    'MultipleChoice',
    'PairwisePreference',
    'CompletionCode',
    'Likert',
    'Rating',
    'RankingResponseTable',
    'Tier1RuleSlotPicker',
    'Tier1RuleRankingResponseTable',
    'AudioCheck',
    'AudioEvaluation',
    'VirtualMeeting',
    'WritingExercise',
    'Rules',
]

export interface FlyoutInstructionalContentItemDTO extends ItemDTO {
    itemType: ItemType.FlyoutInstructionalContentItem
    sectionType: string
    sectionNameI18N: LocalizedAttribute<string>
    textI18N: LocalizedAttribute<string>
}

export const defaultFlyoutInstructionContentItemDTO = (): FlyoutInstructionalContentItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.FlyoutInstructionalContentItem,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: false,
        preserveOrder: false,
        ppt: ItemType.FlyoutInstructionalContentItem,
        sectionNameI18N: LocalizeDefault<string>(''),
        sectionType: FlyoutSectionTypeList[0],
        textI18N: LocalizeDefault<string>(''),
    }
}
