import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export enum SoundCheckMLModelType {
    ACE_SALES_SOUND_CHECK_MODEL = 'ACE_SALES_SOUND_CHECK_MODEL',
    AA_SOUND_CHECK = 'AA_SOUND_CHECK',
}

export interface AudioCheckItemDTO extends ItemDTO {
    itemType: ItemType.AudioCheck
    promptI18N: LocalizedAttribute<string>
    sentenceI18N: LocalizedAttribute<string>
    maxNumberOfAttempts: number
    maxRecordingTimeInSeconds: number
    optional: boolean
    soundCheckMLModel: SoundCheckMLModelType
    soundCheckMLModelVersion: string
}

export const defaultAudioCheckDTO = (): AudioCheckItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.AudioCheck,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: true,
        preserveOrder: false,
        ppt: ItemType.AudioCheck,
        maxNumberOfAttempts: 3,
        maxRecordingTimeInSeconds: 60,
        soundCheckMLModel: SoundCheckMLModelType.AA_SOUND_CHECK,
        soundCheckMLModelVersion: '2.0',
        promptI18N: LocalizeDefault<string>(''),
        sentenceI18N: LocalizeDefault<string>(''),
    }
}
