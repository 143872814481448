import React, { useContext, useEffect, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { Expander } from '@amzn/stencil-react-components/expander'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconBin, IconCheck, IconCross, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { RowAttributes, Table } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import { MESCreateEditValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import { focusOnId } from 'src/pages/module-builder/focus'

export interface SubstituteScoresEditProps {
    substituteScores: string[]
    setSubstituteScores: (substiteScores: string[]) => void
    primaryModuleVersionId?: string
    primaryModuleScores: string[]
    loadPrimaryModuleError: boolean
    mesValidation?: MESCreateEditValidationDTO
    setMesValidation?: (mesValidation: MESCreateEditValidationDTO) => void
}

interface ModuleScoreTableData {
    moduleScoreLabel: string
    selected: boolean
}

export const SubstituteScoresCreate = (props: SubstituteScoresEditProps) => {
    const [moduleSelectedScores, setModuleSelectedScores] = useState<string[]>([''])
    const [moduleScoreTableData, setModuleScoreTableData] = useState<ModuleScoreTableData[]>([])

    const { selectors } = useContext(StencilContext)

    const newSubstituteScores = props.mesValidation?.newSubstituteScores

    const moduleScoreColumns = [
        {
            header: 'Module Score Label',
            accessor: 'moduleScoreLabel',
            width: '50%',
        },
        {
            header: 'Selected',
            cellComponent: ({ data }: { data: ModuleScoreTableData }) => {
                return (
                    <>
                        {data.selected ? (
                            <IconCheck dataTestId={`${data.moduleScoreLabel}-check`} />
                        ) : (
                            <IconCross dataTestId={`${data.moduleScoreLabel}-cross`} />
                        )}
                    </>
                )
            },
            width: '50%',
        },
    ]

    const moduleScoreTableRowAttributes = ({
        data,
    }: {
        data: ModuleScoreTableData
    }): RowAttributes => {
        return {
            css: {
                backgroundColor: data.selected
                    ? selectors.color('green05')
                    : selectors.color('yellow05'),
            },
        } as RowAttributes
    }

    useEffect(() => {
        setModuleSelectedScores(props.substituteScores)
    }, [props.substituteScores])

    const updateSelectedScores = (scoreLabel: string, index: number) => {
        const updatedSubstituteScores = [...props.substituteScores]
        updatedSubstituteScores[index] = scoreLabel
        props.setSubstituteScores(updatedSubstituteScores)
    }

    const removeSubstituteScore = (index: number) => {
        const updatedSubstituteScores = [...props.substituteScores]
        updatedSubstituteScores.splice(index, 1)
        props.setSubstituteScores(updatedSubstituteScores)
    }

    const clearNewSubstituteError = (index: number) => {
        if (props.setMesValidation && props.mesValidation && newSubstituteScores) {
            const updatedNewSubstituteScores = newSubstituteScores
            updatedNewSubstituteScores[index] = ''
            props.setMesValidation({
                ...props.mesValidation,
                newSubstituteScores: updatedNewSubstituteScores,
            })
        }
    }

    const deleteNewSubstituteError = (index: number) => {
        if (props.setMesValidation && props.mesValidation && newSubstituteScores) {
            const updatedNewSubstituteScores = newSubstituteScores
            updatedNewSubstituteScores.splice(index, 1)
            props.setMesValidation({
                ...props.mesValidation,
                newSubstituteScores: updatedNewSubstituteScores,
            })
        }
    }

    useEffect(() => {
        setModuleScoreTableData(
            props.primaryModuleScores.map((moduleScore) => {
                return {
                    moduleScoreLabel: moduleScore,
                    selected: moduleSelectedScores.includes(moduleScore),
                }
            })
        )
    }, [moduleSelectedScores, props.primaryModuleScores])

    return (
        <Col gridGap='S200'>
            {props.loadPrimaryModuleError && (
                <MessageBanner type={MessageBannerType.Error}>
                    Something went wrong while loading the reference module scores, please try again
                </MessageBanner>
            )}
            {!props.loadPrimaryModuleError && props.primaryModuleVersionId && (
                <>
                    <Spacer height='S200' />
                    <Text fontSize='T300'>
                        Select scores from the reference module you want to create equivalencies
                        with:
                    </Text>
                    {props.primaryModuleScores.length === 0 && (
                        <MessageBanner
                            type={MessageBannerType.Warning}
                            dataTestId='no-primary-score-warning'
                        >
                            The selected Reference Module does not have any scores
                        </MessageBanner>
                    )}
                    <ul>
                        {props.substituteScores.map((substituteScore: string, index) => (
                            <li
                                key={`${substituteScore}-${index}`}
                                aria-label={`Added substitute Score ${index + 1}`}
                            >
                                <Row gridGap='S200'>
                                    <View width='90%'>
                                        <InputWrapper
                                            id={`substitute-score-${index}-wrapper`}
                                            labelText={`Score ${index + 1}`}
                                            error={
                                                newSubstituteScores
                                                    ? !!newSubstituteScores[index]
                                                    : false
                                            }
                                            footer={
                                                newSubstituteScores && newSubstituteScores[index]
                                                    ? newSubstituteScores[index]
                                                    : undefined
                                            }
                                        >
                                            {(inputProps) => (
                                                <Select
                                                    {...inputProps}
                                                    renderOption={ModuleGroupRenderOption}
                                                    options={[
                                                        '',
                                                        ...props.primaryModuleScores.filter(
                                                            (score) =>
                                                                !moduleSelectedScores.includes(
                                                                    score
                                                                )
                                                        ),
                                                    ]}
                                                    value={substituteScore || ''}
                                                    dataTestId={`substitute-${index}-select`}
                                                    onChange={(value: string) => {
                                                        updateSelectedScores(value, index)
                                                        clearNewSubstituteError(index)
                                                        focusOnId(
                                                            `substitute-${index}-select-toggle-button`
                                                        )
                                                    }}
                                                    id={`substitute-${index}-select`}
                                                />
                                            )}
                                        </InputWrapper>
                                    </View>
                                    <Col>
                                        <Spacer height='S500' />
                                        <Button
                                            icon={<IconBin title='Delete item' />}
                                            isDestructive
                                            dataTestId={`substitute-${index}-delete`}
                                            variant={ButtonVariant.Tertiary}
                                            onClick={() => {
                                                removeSubstituteScore(index)
                                                focusOnId(
                                                    `substitute-${
                                                        index !== 0 ? index - 1 : 0
                                                    }-select-toggle-button`
                                                )
                                                deleteNewSubstituteError(index)
                                            }}
                                            aria-disabled={moduleSelectedScores.length === 1}
                                        />
                                    </Col>
                                </Row>
                            </li>
                        ))}
                    </ul>
                    {!(moduleSelectedScores.length === props.primaryModuleScores.length) && (
                        <View justifyContent='left'>
                            <Button
                                dataTestId='add-substitute-score-button'
                                variant={ButtonVariant.Tertiary}
                                size={ButtonSize.Small}
                                onClick={() => {
                                    props.setSubstituteScores([...props.substituteScores, ''])
                                    focusOnId(
                                        `substitute-${
                                            props.primaryModuleScores.length - 1
                                        }-select-toggle-button`
                                    )
                                }}
                                disabled={
                                    moduleSelectedScores.length === props.primaryModuleScores.length
                                }
                            >
                                <Row gridGap='S300'>
                                    <IconPlus />
                                    <Text fontSize='T100' lineHeight={1.3}>
                                        Add another Equivalent Score
                                    </Text>
                                </Row>
                            </Button>
                        </View>
                    )}
                    <Expander titleText='View Module Scores' dataTestId='module-scores-expander'>
                        <Col flex={1}>
                            <Table
                                columns={moduleScoreColumns}
                                data={moduleScoreTableData}
                                getRowAttributes={moduleScoreTableRowAttributes}
                                dataTestId='module-scores-table'
                            />
                        </Col>
                    </Expander>
                </>
            )}
        </Col>
    )
}
