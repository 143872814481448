import React, { useCallback, useState } from 'react'

import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Flex } from '@amzn/stencil-react-components/layout'
import { Tag } from '@amzn/stencil-react-components/tag'

export interface TagsInputProps {
    disabled?: boolean
    id: string
    tags: string[]
    setTags: (newTags: string[]) => void
}

export function TagsInput(props: TagsInputProps) {
    const { id, disabled, tags, setTags } = props
    const [currentTag, setCurrentTag] = useState('')
    const handleOnKeyDown = useCallback(
        (key: string) => {
            if (key === 'Enter' && currentTag.trim().length > 0) {
                setTags([...tags, currentTag])
                setCurrentTag('')
            }
        },
        [tags, currentTag, setTags]
    )

    const removeTag = useCallback(
        (tagIndex: number) => {
            if (tagIndex > -1) {
                const newTags = [...tags]
                newTags.splice(tagIndex, 1)
                setTags(newTags)
            }
        },
        [tags, setTags]
    )

    return (
        <>
            <InputWrapper id={id} labelText='Tags'>
                {(inputProps) => (
                    <Input
                        id={inputProps.id}
                        type='text'
                        value={currentTag}
                        placeholder='Type the tag then hit enter'
                        onChange={(e) => {
                            setCurrentTag(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            handleOnKeyDown(e.key)
                        }}
                        disabled={disabled}
                    />
                )}
            </InputWrapper>

            <Col gridGap={5}>
                <ul style={{ listStyleType: 'none', paddingLeft: 0, margin: 0 }}>
                    <Flex
                        width='100%'
                        justifyContent='left'
                        flexDirection='row'
                        gridGap='5px'
                        flexWrap='wrap'
                    >
                        {tags.map((tag: string, index: number) => {
                            return (
                                <Tag
                                    key={`${tag}-${index}`}
                                    onDismiss={() => removeTag(index)}
                                    title={tag}
                                >
                                    {tag}
                                </Tag>
                            )
                        })}
                    </Flex>
                </ul>
            </Col>
        </>
    )
}
