// TODO refactor to call this function instead in multiple places of this codebase
export function downloadAsFile(data: string | Blob, filename: string) {
    const url = URL.createObjectURL(data instanceof Blob ? data : new Blob([data]))
    // @ts-ignore Cypress
    const hasCypress = !!window.Cypress
    let a: HTMLAnchorElement | null = null
    try {
        a = document.createElement('a')
        a.setAttribute('data-cy', 'downloadAsFile')
        a.href = url
        a.download = filename
        if (hasCypress && typeof data === 'string') {
            a.setAttribute('data-string', data)
        }
        document.body.appendChild(a)
        if (!hasCypress) {
            a.click()
        }
    } finally {
        if (!hasCypress) {
            URL.revokeObjectURL(url)
            if (a) {
                document.body.removeChild(a)
            }
        }
    }
}
