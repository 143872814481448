import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { Input, InputFooter, InputWrapper } from '@amzn/stencil-react-components/form'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import {
    IconAlertCircleFill,
    IconBin,
    IconChevronDown,
    IconChevronUp,
    IconPlus,
} from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { H2 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { Locale } from 'src/models/dto/Locale'
import { EvaluationScoreDTO, ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

interface ScoreItemProps {
    scoreItemIndex: number
    scoreDTO: EvaluationScoreDTO
    removeScore(score: EvaluationScoreDTO): void
    isLast: boolean
    entityVersion: string
    validationErrorEntity: ValidationErrorEntity
    locale: Locale
    scoresLength: number
    editDisabled?: boolean
    lockScoringEditing?: boolean
}

const createNewEvaluationScore = (entityVersion: string) => {
    const newEvaluationScore: EvaluationScoreDTO = {
        compositeScoreLabel: '',
        compositeScoreExpression: '',
    }
    ModuleEntityService.addEvaluationScore(entityVersion, newEvaluationScore)
    return newEvaluationScore
}

const Score = ({
    scoreItemIndex,
    scoreDTO,
    removeScore,
    isLast,
    entityVersion,
    validationErrorEntity,
    locale,
    scoresLength,
    editDisabled,
    lockScoringEditing,
}: ScoreItemProps) => {
    const compositeLabelIsValid =
        validationErrorEntity.validationErrors.compositeScoreLabel?.length > 0
    const compositeScoreIsValid =
        validationErrorEntity.validationErrors.compositeScoreExpression?.length > 0

    return (
        <ResponsiveRow gridGap={'S200'} width={'100%'}>
            <Col flex={1}>
                <InputWrapper id={`score-label-${scoreItemIndex}-label`} labelText={'Score Label'}>
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            dataTestId={`score-label-${scoreItemIndex}-input`}
                            placeholder='Enter score label'
                            disabled={
                                !isDefaultLocale(locale) || editDisabled || lockScoringEditing
                            }
                            value={scoreDTO.compositeScoreLabel}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                scoreDTO.compositeScoreLabel = event.target.value
                                ModuleEntityService.updateEvaluationScoreLabel(
                                    entityVersion,
                                    scoreItemIndex,
                                    scoreDTO.compositeScoreLabel
                                )
                            }}
                        />
                    )}
                </InputWrapper>
                {compositeLabelIsValid && (
                    <InputFooter
                        style={{ visibility: compositeLabelIsValid ? 'visible' : 'hidden' }}
                        error
                        id={'composite-score-validation-footer'}
                    >
                        {validationErrorEntity.validationErrors.compositeScoreLabel}
                    </InputFooter>
                )}
            </Col>
            <Col flex={1}>
                <InputWrapper
                    id={`score-expression-${scoreItemIndex}-label`}
                    labelText={'Score expression'}
                >
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            dataTestId={`score-expression-${scoreItemIndex}-input`}
                            placeholder='Enter score expression'
                            disabled={
                                !isDefaultLocale(locale) || editDisabled || lockScoringEditing
                            }
                            value={scoreDTO.compositeScoreExpression}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                scoreDTO.compositeScoreExpression = event.target.value
                                ModuleEntityService.updateEvaluationScoreExpression(
                                    entityVersion,
                                    scoreItemIndex,
                                    scoreDTO.compositeScoreExpression
                                )
                            }}
                        />
                    )}
                </InputWrapper>
                {compositeScoreIsValid && (
                    <InputFooter
                        style={{ visibility: compositeScoreIsValid ? 'visible' : 'hidden' }}
                        error
                        id={'composite-score-validation-footer'}
                    >
                        {validationErrorEntity.validationErrors.compositeScoreExpression}
                    </InputFooter>
                )}
            </Col>
            <Col padding={{ top: 'S500' }}>
                <Button
                    dataTestId={`move-score-${scoreItemIndex + 1}-up`}
                    variant={ButtonVariant.Secondary}
                    aria-label={`Move composite ${scoreItemIndex + 1} up.`}
                    onClick={() => {
                        ModuleEntityService.moveCompositeScore(
                            entityVersion,
                            scoreItemIndex,
                            scoreItemIndex - 1
                        )
                    }}
                    icon={<IconChevronUp aria-hidden />}
                    aria-disabled={
                        scoreItemIndex === 0 ||
                        scoresLength <= 1 ||
                        editDisabled ||
                        lockScoringEditing
                    }
                />
            </Col>
            <Col padding={{ top: 'S500' }}>
                <Button
                    dataTestId={`move-score-${scoreItemIndex + 1}-down`}
                    variant={ButtonVariant.Secondary}
                    onClick={() => {
                        ModuleEntityService.moveCompositeScore(
                            entityVersion,
                            scoreItemIndex,
                            scoreItemIndex + 1
                        )
                    }}
                    aria-label={`Move composite score ${scoreItemIndex + 1} down.`}
                    icon={<IconChevronDown aria-hidden={true} />}
                    aria-disabled={
                        scoreItemIndex === scoresLength - 1 ||
                        scoresLength <= 1 ||
                        editDisabled ||
                        lockScoringEditing
                    }
                />
            </Col>
            <Col padding={{ top: 'S500' }}>
                <Button
                    dataTestId={`delete-score-${scoreItemIndex}`}
                    variant={ButtonVariant.Tertiary}
                    isDestructive={true}
                    aria-label={`Delete score ${scoreItemIndex}.`}
                    icon={<IconBin aria-hidden={true} />}
                    onClick={() => removeScore(scoreDTO)}
                    aria-disabled={isLast || editDisabled || lockScoringEditing}
                />
            </Col>
        </ResponsiveRow>
    )
}

export const EvaluationScores = ({
    moduleId,
    locale,
    showValidationError,
    editDisabled,
}: {
    moduleId: string
    locale: Locale
    showValidationError?: boolean
    editDisabled?: boolean
}) => {
    const { lockScoringEditing } = useContext(ModuleBuilderContext)

    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })
    if (!ValidationErrorEntityService.has(moduleId)) {
        ValidationErrorEntityService.create(moduleId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: moduleId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Evaluation Scores`
    const [scoresMap, setScoresMap] = useState<EvaluationScoreDTO[]>([])

    const removeScore = (score: EvaluationScoreDTO) => {
        const index = scoresMap.indexOf(score)
        if (index > -1) {
            ModuleEntityService.deleteEvaluationScore(moduleId, index)
        }
    }

    const topLevelErrorList: string[] = useMemo(
        () => validationErrorEntity.validationErrors.compositeScore || [],
        [validationErrorEntity.validationErrors]
    )

    const filteredErrorList = useMemo(() => {
        return [
            ...(validationErrorEntity.validationErrors.compositeScore || []),
            ...(validationErrorEntity.validationErrors.compositeScoreExpression || []),
            ...(validationErrorEntity.validationErrors.compositeScoreLabel || []),
        ]
    }, [validationErrorEntity.validationErrors])

    useEffect(() => {
        setScoresMap([...moduleEntity.compositeScores])
    }, [moduleId, moduleEntity.compositeScores])

    return (
        <>
            <Col
                gridGap='S300'
                padding={{ top: 'S300', left: 'S400', right: 'S400', bottom: 'S300' }}
                backgroundColor='#FFFFFF'
            >
                <Row alignItems='center' gridGap='S200' dataTestId={'evaluation-header'}>
                    <Col rowGap={0}>
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                dataTestId={`${
                                    isExpanded ? 'collapse' : 'expand'
                                }-evaluation-scores-button`}
                                iconAltText={triggerButtonName}
                                aria-label={triggerButtonName}
                                isExpanded={isExpanded}
                                onClick={() => setIsExpanded(!isExpanded)}
                                role={'button'}
                            />
                            <H2
                                fontSize='T400'
                                fontWeight='bold'
                                color={
                                    filteredErrorList?.length > 0 && showValidationError
                                        ? 'red70'
                                        : 'neutral90'
                                }
                            >
                                Evaluation Scores
                            </H2>
                        </Row>
                    </Col>
                    {filteredErrorList?.length > 0 && showValidationError && (
                        <>
                            <Spacer flex={1} />
                            <Col padding={{ right: 'S200' }}>
                                <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                                    <IconAlertCircleFill aria-hidden />
                                    {filteredErrorList?.length} issues
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
                {topLevelErrorList && topLevelErrorList?.length > 0 && showValidationError && (
                    <MessageBanner type={MessageBannerType.Error}>
                        <ul>
                            {topLevelErrorList?.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </MessageBanner>
                )}
                {lockScoringEditing && (
                    <MessageBanner type={MessageBannerType.Warning}>
                        <ul>
                            A version of this module has reached Published status, so evaluation
                            scores cannot be changed. Duplicate this module to create a new version
                            with editable evaluation scores.
                        </ul>
                    </MessageBanner>
                )}
                <ExpanderContent isExpanded={isExpanded}>
                    <View width={'100%'} padding={{ top: 'S300', bottom: 'S400' }}>
                        <Hr size='wide' />
                    </View>
                    {scoresMap.map((score: EvaluationScoreDTO, scoreIndex: number) => {
                        return (
                            <Score
                                scoreItemIndex={scoreIndex}
                                key={scoreIndex}
                                scoreDTO={score}
                                locale={locale}
                                removeScore={removeScore}
                                isLast={scoresMap.length === 1}
                                entityVersion={moduleId}
                                validationErrorEntity={validationErrorEntity}
                                scoresLength={scoresMap.length}
                                editDisabled={editDisabled}
                                lockScoringEditing={lockScoringEditing}
                            />
                        )
                    })}
                    <Spacer height={'S200'} />
                    <Row alignSelf={'flex-end'}>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {
                                setScoresMap([
                                    ...scoresMap,
                                    createNewEvaluationScore(moduleEntity.version),
                                ])
                            }}
                            dataTestId={'new-evaluation-score-button'}
                            aria-disabled={editDisabled || lockScoringEditing}
                        >
                            <IconPlus aria-hidden={true} />
                            Add new composite score
                        </Button>
                    </Row>
                </ExpanderContent>
            </Col>
            <Spacer height='S400' />
        </>
    )
}
