import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Container } from '@amzn/stencil-react-components/layout'

import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { HomeMasthead } from 'src/pages/home/HomeMasthead'
import { MyModules } from 'src/pages/home/MyModules'

const pageTimer = new TimerStopwatch('HomePageTimer')

export const HomePage = () => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const navigate = useNavigate()

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.HomePage, CommonUserEventKeys.Open)

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.HomePage, pageTimer.value)
        }
    }, [])

    const navigateToSearch = () => {
        navigate({
            pathname: '/search',
            search: `?${createSearchParams({ search: searchTerm }).toString()}`,
        })
    }

    return (
        <>
            <Container backgroundColor='#232F3E'>
                <HomeMasthead
                    searchTerm={searchTerm}
                    onSearchTermChange={(updatedSearchTerm) => setSearchTerm(updatedSearchTerm)}
                    onHitSearch={() => navigateToSearch()}
                />
            </Container>
            <Container>
                <MyModules
                    onCreateModule={() => {
                        navigate('/module-builder')
                    }}
                />
            </Container>
        </>
    )
}
