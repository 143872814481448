import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface CompletionCodeItemDTO extends ItemDTO {
    itemType: ItemType.CompletionCode
    statementI18N: LocalizedAttribute<string>
}

export const defaultCompletionCodeItemDTO = (): CompletionCodeItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.CompletionCode,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: false,
        preserveOrder: false,
        ppt: ItemType.CompletionCode,
        statementI18N: LocalizeDefault<string>(''),
    }
}
