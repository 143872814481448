import React from 'react'
import _ from 'lodash'

import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'

import { ReviewDTO } from 'src/models/dto/approval/ReviewDTO'

export interface ModuleReviewErrorProps {
    review?: ReviewDTO | null
    error: string
    revisionNumber?: string | null
    isLoading: boolean
    previewError?: string | null
}

export function ModuleReviewError({
    review,
    error,
    revisionNumber,
    isLoading,
    previewError,
}: ModuleReviewErrorProps) {
    const latestRevision = review && _.maxBy(review.revisionList, 'createdAt')
    const notTheLatestRevision =
        !error && latestRevision && revisionNumber !== latestRevision.revisionNumber
    const warning =
        !isLoading && notTheLatestRevision
            ? 'This is not the latest revision. Please select the latest revision from the revisions dropdown.'
            : ''

    if (!(previewError || error || warning)) {
        return null
    }

    return (
        <>
            {previewError && (
                <MessageBanner type={MessageBannerType.Error}>{previewError}</MessageBanner>
            )}
            {error && <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner>}
            {warning && (
                <MessageBanner
                    type={MessageBannerType.Warning}
                    dataTestId='not-the-latest-revision'
                >
                    {warning}
                </MessageBanner>
            )}
        </>
    )
}
