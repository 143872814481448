import { PreviewTTL, usePreview, UsePreviewResults } from 'src/hooks/usePreview'
import { ModuleLayout } from 'src/models/dto/ModuleDTO'
import { ACTIVITY_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ActivityEntityService'

export function usePagePreview({
    activityId,
    onPreviewUnexpectedError,
    moduleLayout,
}: {
    activityId: string
    onPreviewUnexpectedError?: () => void
    moduleLayout: ModuleLayout
}) {
    const usePreviewResults: UsePreviewResults = usePreview({
        entityId: activityId,
        saved: false,
        entityStoreSelector: ACTIVITY_ENTITY_STORE_SELECTOR,
        onPreviewUnexpectedError: onPreviewUnexpectedError,
        moduleLayout: moduleLayout,
    })
    return {
        ...usePreviewResults,
        executePreview: () => usePreviewResults.executePreview(PreviewTTL.THIRTY_MINUTES),
    }
}
