import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import useAxios from 'axios-hooks'

import { Avatar, AvatarSize } from '@amzn/stencil-react-components/avatar'
import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Col, Container, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { Table, TableSpacing } from '@amzn/stencil-react-components/table'
import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs'
import { H1, Text } from '@amzn/stencil-react-components/text'

import { APP_CONFIG } from 'src/config.app'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import {
    ModuleDiffResponse,
    ModuleHistoryDTO,
    ModuleHistoryResponse,
    ModuleVersionDrafts,
} from 'src/models/dto/ModuleHistoryDTO'
import { ApiActionNames } from 'src/services/AxiosInterceptor'

const MAX_VERSION_LENGTH = 7

const groupBy = <K, V>(array: V[], grouper: (item: V) => K): [K, V[]][] =>
    Array.from(
        array.reduce((map, cur) => {
            const key = grouper(cur)
            map.set(key, (map.get(key) || []).concat(cur))
            return map
        }, new Map<K, V[]>()),
        ([key, value]) => [key, value]
    )

const tabs = [
    { label: 'What changed?', value: 'tab-1' },
    { label: 'What was removed?', value: 'tab-2' },
    { label: 'What was added?', value: 'tab-3' },
]

const AvatarCell = ({ data }: { data: ModuleHistoryDTO }) => (
    <Avatar username={data.author} size={AvatarSize.Small} />
)

const DateCell = ({ data }: { data: ModuleHistoryDTO }) => (
    <Text fontSize={'T200'}>
        {new Date(parseInt(data.createdAt)).toLocaleDateString()}{' '}
        {new Date(parseInt(data.createdAt)).toLocaleTimeString()}
    </Text>
)

const DraftVersionCell = ({ data }: { data: ModuleHistoryDTO }) => (
    <Text fontSize={'T200'}>{data.draftVersion.substring(0, MAX_VERSION_LENGTH)}</Text>
)

const changedColumns = [
    { header: 'Key', accessor: 'attributePath' },
    { header: 'Older value', accessor: 'valueLeft' },
    { header: 'Newer value', accessor: 'valueRight' },
]

const removedColumns = [
    { header: 'Key', accessor: 'attributePath' },
    { header: 'Removed Value', accessor: 'value' },
]

const addedColumns = [
    { header: 'Key', accessor: 'attributePath' },
    { header: 'New Value', accessor: 'value' },
]

const sortF = () => {
    return (a: ModuleHistoryDTO, b: ModuleHistoryDTO) =>
        parseInt(a.createdAt) - parseInt(b.createdAt)
}

const EmptyTab = () => (
    <Container>
        <View>
            <Text fontSize={'T200'}>There is no difference to display on this tab.</Text>
        </View>
    </Container>
)

export const ModuleHistoryPage = () => {
    const { moduleId } = useParams()
    const { tabBarProps, tabSwitcherProps } = useTabs({ tabs })

    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)
    const [selectedVersions, setSelectedVersions] = useState<ModuleHistoryDTO[]>([])
    const [moduleHistoryList, setModuleHistoryList] = useState<ModuleVersionDrafts[]>(
        new Array<ModuleVersionDrafts>()
    )

    const [diff, setDiff] = useState<ModuleDiffResponse>({
        commons: [],
        leftEntries: [],
        rightEntries: [],
    } as ModuleDiffResponse)

    const [
        { error: historyError, loading: loadingHistory, data: historyData },
        executeGetModuleHistory,
    ] = useAxios<ModuleHistoryResponse>({}, { manual: true })

    const [{ error: diffError, loading: loadingDiff, data: diffData }, executeGetDiff] =
        useAxios<ModuleDiffResponse>({}, { manual: true })

    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeDiffModal = () => {
        setIsModalOpen(false)
        setSelectedVersions([])
    }

    useEffect(() => {
        if (moduleId) {
            void executeGetModuleHistory({
                method: 'GET',
                url: `${APP_CONFIG.backendAPIBaseUrl}/modules/${moduleId}/history`,
                apiActionName: ApiActionNames.GetModuleHistory,
            }).catch(() => {})
        }
    }, [executeGetModuleHistory, moduleId])

    useEffect(() => {
        if (historyData) {
            setModuleHistoryList(
                groupBy(historyData.history.reverse(), (h) => h.versionId).map((v) => {
                    return {
                        versionId: v[0],
                        drafts: v[1],
                    } as ModuleVersionDrafts
                })
            )
        }
    }, [historyData])

    useEffect(() => {
        if (diffData) {
            setDiff(diffData)
        }
    }, [diffData])

    useEffect(() => {
        const error = (historyError || diffError) as AxiosError
        if (error) {
            console.error('API Error: ', error)
            setUnexpectedError(
                (error.response &&
                    (error.response.data as ErrorResponseThrowable)?.errorResponse?.message) ??
                    'An unexpected error has occurred, please try again'
            )
        }
    }, [historyError, diffError])

    useEffect(() => {
        if (selectedVersions.length === 2) {
            void executeGetDiff({
                method: 'GET',
                url: `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${selectedVersions[0].draftVersion}/diff/${selectedVersions[1].draftVersion}`,
                apiActionName: ApiActionNames.GetDiff,
            }).catch(() => {})
            setIsModalOpen(true)
        }
    }, [executeGetDiff, selectedVersions])

    const SelectButton = ({ data }: { data: ModuleHistoryDTO }) => {
        const selectedLength = selectedVersions.filter(
            (x) => x.draftVersion === data.draftVersion
        ).length
        return (
            <Button
                dataTestId={`select-draft-${data.draftVersion}`}
                variant={selectedLength === 0 ? ButtonVariant.Secondary : ButtonVariant.Primary}
                size={ButtonSize.Small}
                onClick={() => {
                    setSelectedVersions([...selectedVersions, data].sort(sortF()))
                }}
            >
                {selectedLength === 0 ? 'Compare' : 'Comparing'}
            </Button>
        )
    }

    const columns = [
        { header: 'Date', accessor: DateCell },
        { header: 'Draft Version', cellComponent: DraftVersionCell },
        { header: 'Author', cellComponent: AvatarCell },
        { header: 'Comments', accessor: 'saveComments' },
        // The status will be enabled later
        // { header: 'Status', accessor: 'status' },
        { header: 'Actions', cellComponent: SelectButton },
    ]

    if (loadingHistory) {
        return (
            <Container>
                <View padding={{ top: 'S300', left: 'S300', right: 'S300' }}>
                    <Spinner dataTestId={'loading-history-spinner'} size={SpinnerSize.Large} />
                </View>
            </Container>
        )
    }

    const ModalView = () => {
        if (loadingDiff) {
            return (
                <Col>
                    <View padding={{ top: 'S300', left: 'S300', right: 'S300' }}>
                        <Spinner dataTestId={'loading-diff-spinner'} size={SpinnerSize.Large} />
                    </View>
                </Col>
            )
        }
        return (
            <Col gridGap={'S200'}>
                <TabBar {...tabBarProps} />
                <TabSwitcher {...tabSwitcherProps}>
                    <TabPanel value='tab-1'>
                        {diff.commons.length == 0 && <EmptyTab />}
                        {diff.commons.length > 0 && (
                            <>
                                <Text fontSize={'T200'}>
                                    These values are in the older draft and newer draft but the
                                    value has changed.
                                </Text>
                                <Spacer height={'S200'} />
                                <Col>
                                    <Table
                                        data={diff.commons}
                                        columns={changedColumns}
                                        spacing={TableSpacing.Reduced}
                                        isStriped={true}
                                    />
                                </Col>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel value='tab-2'>
                        {diff.leftEntries.length == 0 && <EmptyTab />}
                        {diff.leftEntries.length > 0 && (
                            <>
                                <Text fontSize={'T200'}>
                                    These values were in the older draft but no longer appear in the
                                    newer draft.
                                </Text>
                                <Spacer height={'S200'} />
                                <Col>
                                    <Table
                                        data={diff.leftEntries}
                                        columns={removedColumns}
                                        spacing={TableSpacing.Reduced}
                                        isStriped={true}
                                    />
                                </Col>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel value='tab-3'>
                        {diff.rightEntries.length == 0 && <EmptyTab />}
                        {diff.rightEntries.length > 0 && (
                            <>
                                <Text fontSize={'T200'}>
                                    These values are in the newer draft but were not in the older
                                    draft.
                                </Text>
                                <Spacer height={'S200'} />
                                <Col>
                                    <Table
                                        data={diff.rightEntries}
                                        columns={addedColumns}
                                        spacing={TableSpacing.Reduced}
                                        isStriped={true}
                                    />
                                </Col>
                            </>
                        )}
                    </TabPanel>
                </TabSwitcher>
            </Col>
        )
    }

    return (
        <>
            {unexpectedError && (
                <MessageBanner
                    type={MessageBannerType.Error}
                    dismissButtonAltText={'Hide errors'}
                    dataTestId={'history-page-unexpected-error-banner'}
                    isDismissible={true}
                    onDismissed={() => setUnexpectedError(undefined)}
                >
                    {unexpectedError}
                </MessageBanner>
            )}
            <Container>
                <View padding={{ top: 'S300', left: 'S300', right: 'S300' }}>
                    <H1>Module History</H1>
                </View>
                <Col gridGap={'S200'} padding={'S400'}>
                    {moduleHistoryList.map((md) => {
                        return (
                            <Card key={md.versionId}>
                                <Col
                                    data-testid={`test-${md.versionId}`}
                                    padding={{ bottom: 'S200' }}
                                    flex={1}
                                >
                                    <Text fontSize={'S300'}>
                                        <RouterLink
                                            to={`/module-builder?moduleVersionId=${md.versionId}`}
                                            target={'_blank'}
                                        >
                                            <strong>{md.versionId}</strong>
                                        </RouterLink>
                                        {` - ${new Date(
                                            parseInt(md.drafts[0].createdAt)
                                        ).toDateString()} - ${md.drafts[0].status}`}
                                    </Text>
                                    <Col padding='S200'>
                                        <Table
                                            isStriped={true}
                                            columns={columns}
                                            data={md.drafts}
                                            spacing={TableSpacing.Reduced}
                                        />
                                    </Col>
                                </Col>
                            </Card>
                        )
                    })}
                </Col>
            </Container>
            <Modal
                dataTestId='diff-model'
                isOpen={isModalOpen}
                isScrollable
                close={closeDiffModal}
                shouldCloseOnClickOutside={false}
            >
                <ModalContent
                    titleText='Difference between selected drafts'
                    maxWidth='80vw'
                    buttons={[
                        <Button
                            key={'close-diff-modal'}
                            onClick={closeDiffModal}
                            variant={ButtonVariant.Primary}
                        >
                            Done
                        </Button>,
                    ]}
                >
                    <ModalView />
                </ModalContent>
            </Modal>
        </>
    )
}
