import React, { useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker'
import { Input, InputHeader, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col } from '@amzn/stencil-react-components/layout'
import { H3 } from '@amzn/stencil-react-components/text'

import { ModuleType } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'

export enum FILTER_DATA_IDS {
    OWNER_INPUT = 'owner-input',
    MODIFIED_BY_INPUT = 'modified-by-input',
    START_DATE_INPUT = 'start-date-input',
    END_DATE_INPUT = 'end-date-input',
    MODULE_TYPE_SELECT = 'module-type-select',
    MODULE_STATUS_SELECT = 'module-status-select',
}

interface ModuleListFilterProps {
    title?: string
    owner: string
    moduleStatus: ModuleStatus[]
    moduleTypes: ModuleType[]
    lastModifiedBy: string
    startDate: string
    endDate: string
    updateOwner: (owner: string) => void
    updateLastModifiedBy: (lastModifiedBy: string) => void
    updateStartDate: (start: string) => void
    updateEndDate: (end: string) => void
    updateModuleTypes: (moduleTypes: ModuleType[]) => void
    updateModuleStatus: (moduleStatuses: ModuleStatus[]) => void
    syncState: boolean
    updateSyncState: (syncState: boolean) => void
    applyFilters?: () => void
    lockedModuleStatus?: ModuleStatus[]
    disableFilters?: FILTER_DATA_IDS[]
}

export const ModuleListFilter = (props: ModuleListFilterProps) => {
    const [moduleType, setModuleType] = useState<ModuleType[]>([])
    const [status, setStatus] = useState<ModuleStatus[]>(props.lockedModuleStatus ?? [])
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [owner, setOwner] = useState<string>('')
    const [lastModifiedBy, setLastModifiedBy] = useState<string>('')

    const setFieldsInParent = () => {
        props.updateModuleStatus(status)
        props.updateModuleTypes(moduleType)
        props.updateEndDate(endDate)
        props.updateStartDate(startDate)
        props.updateOwner(owner)
        props.updateLastModifiedBy(lastModifiedBy)
    }

    useEffect(() => {
        if (props.syncState) {
            props.updateSyncState(false)
            setStartDate(props.startDate)
            setEndDate(props.endDate)
            setModuleType(props.moduleTypes)
            setLastModifiedBy(props.lastModifiedBy)
            setOwner(props.owner)
            setStatus(props.moduleStatus)
        }
    }, [
        props,
        props.endDate,
        props.moduleTypes,
        props.moduleStatus,
        props.startDate,
        props.lastModifiedBy,
        props.syncState,
    ])

    return (
        <Col gridGap='S300'>
            {props.title && <H3>Filters</H3>}

            <Col gridGap='S300' aria-label='Filters'>
                <InputHeader
                    labelText='Owner'
                    htmlFor='owner-input'
                    tooltipText='user alias of module owner'
                />
                <Input
                    id='owner-input'
                    dataTestId={FILTER_DATA_IDS.OWNER_INPUT}
                    placeholder='Anyone'
                    aria-label='Module owner'
                    value={owner}
                    onChange={(e) => {
                        setOwner(e.target.value)
                    }}
                />

                <InputWrapper id='modified-by-input' labelText='Last modified by'>
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            dataTestId={FILTER_DATA_IDS.MODIFIED_BY_INPUT}
                            placeholder='Anyone'
                            aria-label='Modified by'
                            value={lastModifiedBy}
                            onChange={(e) => {
                                setLastModifiedBy(e.target.value)
                            }}
                        />
                    )}
                </InputWrapper>

                <PopupDatePicker
                    id='start-date-input'
                    dataTestId={FILTER_DATA_IDS.START_DATE_INPUT}
                    labelText='Start date'
                    inputProps={{ width: '100%' }}
                    value={startDate}
                    onChange={setStartDate}
                />

                <PopupDatePicker
                    id='end-date-input'
                    dataTestId={FILTER_DATA_IDS.END_DATE_INPUT}
                    labelText='End date'
                    inputProps={{ width: '100%' }}
                    value={endDate}
                    onChange={setEndDate}
                />

                <InputWrapper id='module-type-input' labelText='Module type'>
                    {(inputProps) => (
                        <Select
                            {...inputProps}
                            dataTestId={FILTER_DATA_IDS.MODULE_TYPE_SELECT}
                            options={[
                                ModuleType.Content,
                                ModuleType.Evaluation,
                                ModuleType.Instructional,
                                ModuleType.ItemsContainer,
                                ModuleType.Landing,
                                ModuleType.Transition,
                                ModuleType.Other,
                            ]}
                            isMulti={true}
                            onChange={setModuleType}
                            placeholder='Any module type'
                            value={moduleType}
                            disabled={
                                props.disableFilters &&
                                props.disableFilters.includes(FILTER_DATA_IDS.MODULE_TYPE_SELECT)
                            }
                            getTriggerText={(numberOfSelectedItems) =>
                                numberOfSelectedItems === 1
                                    ? '1 type selected'
                                    : `${numberOfSelectedItems} types selected`
                            }
                        />
                    )}
                </InputWrapper>

                <InputWrapper id='module-status-input' labelText='Module status'>
                    {(inputProps) => (
                        <Select
                            {...inputProps}
                            dataTestId={FILTER_DATA_IDS.MODULE_STATUS_SELECT}
                            options={[
                                ModuleStatus.DRAFT_UNVALIDATED,
                                ModuleStatus.DRAFT_VALIDATED,
                                ModuleStatus.REVIEW,
                                ModuleStatus.REVIEW_MAJOR,
                                ModuleStatus.REVIEW_MINOR,
                                ModuleStatus.REVIEW_APPROVED,
                                ModuleStatus.UAT,
                                ModuleStatus.TRANSLATION_REQUESTED,
                                ModuleStatus.MEDIA_TRANSLATION_REQUIRED,
                                ModuleStatus.TRANSLATION_RECEIVED,
                                ModuleStatus.PUBLISHED,
                                ModuleStatus.LIVE,
                            ]}
                            isMulti={true}
                            disabled={!!props.lockedModuleStatus}
                            onChange={setStatus}
                            value={props.lockedModuleStatus ?? status}
                            placeholder='Any module statuses'
                            getTriggerText={(numberOfSelectedItems) =>
                                numberOfSelectedItems === 1
                                    ? '1 status selected'
                                    : `${numberOfSelectedItems} statuses selected`
                            }
                        />
                    )}
                </InputWrapper>
            </Col>

            {props.applyFilters && (
                <Button
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                        if (props.applyFilters) {
                            setFieldsInParent()
                            props.applyFilters()
                        }
                    }}
                    aria-label='Apply Filters'
                    data-test-id='apply-filters-button'
                >
                    Apply filters
                </Button>
            )}
        </Col>
    )
}
