import { PoolResults } from 'src/pages/module-builder/item-pool-editor/ItemPoolEditor'

export class GeneratedPoolsStorage {
    private static generatedIds: PoolResults[] = []

    public static reset() {
        GeneratedPoolsStorage.generatedIds = []
    }

    public static getResults(): PoolResults[] {
        return GeneratedPoolsStorage.generatedIds
    }

    public static setResults(item: PoolResults): void {
        GeneratedPoolsStorage.generatedIds.push(item)
    }
}
