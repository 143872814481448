export interface MESGroupDTO {
    primaryModuleVersionId: string
    selectedPrimaryScoreLabels: string[]
    substituteModuleScores: SubstituteModuleScore[]
}

export interface SubstituteModuleScore {
    substituteModuleVersion: string
    equivalencyReason: string
    substituteScores: PrimaryScoreLabelWithSubstitute[]
}

export interface PrimaryScoreLabelWithSubstitute {
    primaryScoreLabel: string
    substituteScoreLabel: string
}

export const createInitialModuleSubstituteScore = (moduleVersionId?: string) => {
    return {
        substituteModuleVersion: moduleVersionId || '',
        equivalencyReason: '',
        substituteScores: [],
    } as SubstituteModuleScore
}
