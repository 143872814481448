import { v4 } from 'uuid'

import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'

export enum MediaFileType {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WAV = 'audio/wav',
    MP3 = 'audio/mpeg',
    MP4 = 'video/mp4',
    MOV = 'video/quicktime',
}

export enum MediaFileGroups {
    AUDIO = 'audio',
    VIDEO = 'video',
    IMAGE = 'image',
}

export const getListOfFileTypes = (fileGroup: MediaFileGroups) => {
    if (fileGroup === MediaFileGroups.IMAGE) {
        return ['PNG', 'JPEG', 'GIF']
    } else if (fileGroup === MediaFileGroups.VIDEO) {
        return ['MP4', 'MOV']
    } else if (fileGroup === MediaFileGroups.AUDIO) {
        return ['MP3', 'WAV']
    }
}

export interface MediaLanguageDTO {
    alternateText: string
    filePath: string
    fileName: string
    fileType: string
    fileCreatedBy: string
    fileCreatedAt: string
    published: boolean
    captionsPath: string
    transcriptsPath: string
    captionsFileName: string
    transcriptsFileName: string
    languageTemplateVersionId?: string
}

export interface MediaUsedByDTO {
    moduleVersionId: string
    moduleName: string
    usedIn: string
}

export interface MediaTemplateDTO {
    title: string
    defaultFileName: string
    defaultFilePath: string
    fileRequiresTranslation: boolean
    hideTranscriptInMedia: boolean
    createdAt: string
    createdBy: string
    lastUpdatedAt: string
    lastUpdatedBy: string
    fileType: string
    languages: Partial<LocalizedAttribute<MediaLanguageDTO>>
    usedByCount: number
    usedByList: MediaUsedByDTO[]
}

export const defaultMediaLanguageDTO = () => {
    return {
        alternateText: '',
        filePath: '',
        fileName: '',
        fileType: '',
        fileCreatedBy: '',
        fileCreatedAt: '',
        published: false,
        captionsPath: '',
        transcriptsPath: '',
        captionsFileName: '',
        transcriptsFileName: '',
    } as MediaLanguageDTO
}

export const defaultMediaUsedByDTO = () => {
    return {
        moduleVersionId: '',
        moduleName: '',
        usedIn: '',
    }
}

export const defaultMediaTemplateDTO = () => {
    return {
        versionId: v4(),
        title: '',
        defaultFileName: '',
        defaultFilePath: '',
        fileRequiresTranslation: false,
        hideTranscriptInMedia: false,
        lastUpdatedBy: '',
        lastUpdatedAt: '',
        createdBy: 'default',
        createdAt: '',
        fileType: '',
        languages: {
            [Locale.en_US]: defaultMediaLanguageDTO(),
        },
        usedByCount: 0,
        usedByList: [defaultMediaUsedByDTO()],
    } as MediaTemplateDTO
}
