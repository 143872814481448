import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface InformationImagesItemDTO extends ItemDTO {
    itemType: ItemType.InformationImages
    optional: boolean
    images: InformationImageDTO[]
    informationTitle: LocalizedAttribute<string>
}

export interface InformationImageDTO {
    label: LocalizedAttribute<string>
    media?: Medias
}

export const defaultInformationImage = (): InformationImageDTO => {
    return {
        label: LocalizeDefault<string>(''),
    }
}

export const defaultInformationImagesItemDTO = (): InformationImagesItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.InformationImages,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: false,
        preserveOrder: false,
        ppt: ItemType.InformationImages,
        images: [defaultInformationImage()],
        informationTitle: LocalizeDefault<string>(''),
    }
}
