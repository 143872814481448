import React from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputFooter } from '@amzn/stencil-react-components/form'
import {
    IconBin,
    IconChevronDown,
    IconChevronUp,
    IconPlus,
} from '@amzn/stencil-react-components/icons'
import { Col, Container, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    InformationImageDTO,
    InformationImagesItemDTO,
} from 'src/models/dto/items/InformationImagesItemDTO'
import { ItemDTO } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { getListOfFileTypes, MediaFileGroups } from 'src/models/media/MediaTemplateDTO'
import { FocusId, focusOnId, moveDownFocusId, moveUpFocusId } from 'src/pages/module-builder/focus'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { InformationImagesHandler } from 'src/services/EntityServices/ItemUpdateHandlers/InformationImagesHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export function calculateImageFocusId(itemDTO: ItemDTO, imageIndex: number) {
    return `${itemDTO.id}-information-image-${imageIndex}` as FocusId
}

const InformationImageEditor = ({
    image,
    imageIndex,
    itemDTO,
    locale,
    showValidationError,
    exchangeValidationErrorMessages,
    validationErrorEntity,
    editDisabled,
}: {
    image: InformationImageDTO
    imageIndex: number
    itemDTO: InformationImagesItemDTO
    locale: Locale
    showValidationError?: boolean
    exchangeValidationErrorMessages: (index1: number, index2: number) => void
    validationErrorEntity: ValidationErrorEntity
    editDisabled?: boolean
}) => {
    const { matches } = useBreakpoints()

    const imageFocusId = calculateImageFocusId(itemDTO, imageIndex)

    const imageListId = `${itemDTO.id}-information-images-list` as FocusId

    const deleteImage = () => {
        InformationImagesHandler.deleteImage(itemDTO.id, imageIndex)
        focusOnId(imageListId)
    }

    return (
        <Container
            border={'3px solid neutral05'}
            role={'listitem'}
            dataTestId={`image-${imageIndex}`}
        >
            <Row
                backgroundColor={'neutral05'}
                padding={{ top: 'S200', bottom: 'S200', left: 'S200' }}
                alignItems={'center'}
            >
                <Text fontSize={'T200'}>Image {imageIndex + 1}</Text>
                <Spacer flex={1} />
                <Button
                    id={moveUpFocusId(imageFocusId)}
                    dataTestId={`move-image-${imageIndex}-up`}
                    variant={ButtonVariant.Secondary}
                    aria-label={`Move image ${imageIndex + 1} up.`}
                    onClick={() => {
                        exchangeValidationErrorMessages(imageIndex, imageIndex - 1)
                        InformationImagesHandler.moveImageUp(itemDTO.id, imageIndex)
                        focusOnId(moveUpFocusId(calculateImageFocusId(itemDTO, imageIndex - 1)))
                    }}
                    icon={<IconChevronUp aria-hidden />}
                    aria-disabled={imageIndex === 0 || itemDTO.images.length <= 1 || editDisabled}
                />
                <Spacer width={'S200'} />
                <Button
                    id={moveDownFocusId(imageFocusId)}
                    dataTestId={`move-image-${imageIndex}-down`}
                    variant={ButtonVariant.Secondary}
                    aria-label={`Move image ${imageIndex + 1} down.`}
                    onClick={() => {
                        exchangeValidationErrorMessages(imageIndex, imageIndex + 1)
                        InformationImagesHandler.moveImageDown(itemDTO.id, imageIndex)
                        focusOnId(moveDownFocusId(calculateImageFocusId(itemDTO, imageIndex + 1)))
                    }}
                    icon={<IconChevronDown aria-hidden />}
                    aria-disabled={
                        imageIndex === itemDTO.images.length - 1 ||
                        itemDTO.images.length <= 1 ||
                        editDisabled
                    }
                />
                <Spacer width={'S200'} />
                {!matches.s && (
                    <>
                        <Button
                            dataTestId={`image-${imageIndex}-delete-button`}
                            icon={<IconBin title={`Delete image ${imageIndex + 1}`} />}
                            aria-disabled={itemDTO.images.length <= 1 || editDisabled}
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                            aria-label={`Delete image ${imageIndex + 1}`}
                            isDestructive
                            onClick={() => {
                                deleteImage()
                            }}
                        />
                        <Spacer width={'S200'} />
                    </>
                )}
            </Row>
            <ResponsiveRow padding={'S200'} gridGap={matches.s ? undefined : 'S400'}>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        placeholder={'E.g. Jacket'}
                        inputId={`image-${imageIndex}-description`}
                        disabled={editDisabled}
                        labelText={'Image description'}
                        dataTestId={`image-${imageIndex}-description`}
                        itemId={itemDTO.id}
                        value={image.label[locale] ?? ''}
                        setValue={(imageValue) => {
                            InformationImagesHandler.updateImageLabel(
                                itemDTO.id,
                                imageIndex,
                                imageValue,
                                locale
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors[`images[${imageIndex}].label`] ??
                            []
                        ).join(', ')}
                        showError={showValidationError}
                        maxCharacterLimit={25}
                    />
                </Col>
                <Col flex={1} padding={{ top: 'S200' }} width={'100%'}>
                    {validationErrorEntity.validationErrors.images && showValidationError && (
                        <MessageBanner type={MessageBannerType.Error}>
                            {validationErrorEntity.validationErrors.images.join(', ')}
                        </MessageBanner>
                    )}
                    <MediaManager
                        itemId={itemDTO.id}
                        locale={locale}
                        localeWiseMediaOpt={itemDTO.images[imageIndex].media}
                        setLocaleWiseMediaOpt={(change) => {
                            InformationImagesHandler.updateImage(itemDTO.id, change, imageIndex)
                        }}
                        disableMediaExpander={true}
                        maxNumberOfMedia={1}
                        buttonText={'Select an image'}
                        buttonVariant={ButtonVariant.Secondary}
                        lockedFileTypes={getListOfFileTypes(MediaFileGroups.IMAGE)}
                        disabled={editDisabled}
                    />
                </Col>
                {matches.s && (
                    <Col flex={1}>
                        <Button
                            dataTestId={`image-${imageIndex}-delete-button`}
                            aria-disabled={itemDTO.images.length <= 1 || editDisabled}
                            aria-label={`Delete image ${imageIndex + 1}`}
                            variant={ButtonVariant.Secondary}
                            isDestructive
                            onClick={() => {
                                deleteImage()
                            }}
                        >
                            Delete image {imageIndex + 1}
                        </Button>
                    </Col>
                )}
            </ResponsiveRow>
            {validationErrorEntity.validationErrors[`images[${imageIndex}].media`] && (
                <ResponsiveRow padding={'S200'}>
                    <InputFooter error id={`image-${imageIndex}-validation-footer`} flex={1}>
                        {validationErrorEntity.validationErrors[`images[${imageIndex}].media`].join(
                            ', '
                        )}
                    </InputFooter>
                </ResponsiveRow>
            )}
        </Container>
    )
}

export const InformationImagesItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<InformationImagesItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const listId = `${itemDTO.id}-information-images-list` as FocusId

    const exchangeValidationErrors = (index1: number, index2: number) => {
        const labelValidationErrors1: string[] =
            validationErrorEntity.validationErrors[`images[${index1}].label`]
        const labelValidationErrors2: string[] =
            validationErrorEntity.validationErrors[`images[${index2}].label`]
        const mediaValidationErrors1: string[] =
            validationErrorEntity.validationErrors[`images[${index1}].media`]
        const mediaValidationErrors2: string[] =
            validationErrorEntity.validationErrors[`images[${index2}].media`]

        validationErrorEntity.validationErrors[`images[${index1}].label`] = labelValidationErrors2
        validationErrorEntity.validationErrors[`images[${index2}].label`] = labelValidationErrors1
        validationErrorEntity.validationErrors[`images[${index1}].media`] = mediaValidationErrors2
        validationErrorEntity.validationErrors[`images[${index2}].media`] = mediaValidationErrors1
    }

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            InformationImagesHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            InformationImagesHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? 'S200' : '29px'}` }}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            dataTestId={'preserve-order-checkbox'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                InformationImagesHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </ResponsiveRow>
            <Spacer height={'S200'} />
            <ResponsiveRow>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        inputId={'information title'}
                        dataTestId={'information-title'}
                        value={itemDTO.informationTitle[locale] ?? ''}
                        placeholder={'Some human readable content'}
                        itemId={itemDTO.id}
                        disabled={editDisabled}
                        setValue={(nextValue: string) => {
                            InformationImagesHandler.updateInformationTitle(
                                itemDTO.id,
                                locale,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.informationTitle ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Information Title'}
                    />
                </Col>
            </ResponsiveRow>
            <Spacer height={'S200'} />
            <Container>
                <Col width={'100%'}>
                    <Text fontSize={'T200'} fontWeight={'bold'}>
                        Images
                    </Text>
                    <Spacer height={'S200'} />
                    <View role={'list'} id={listId} tabIndex={-1}>
                        {/*For each image, display header, image description, and add image feature*/}
                        {itemDTO.images.map((image: InformationImageDTO, imageIndex: number) => {
                            return (
                                <InformationImageEditor
                                    image={image}
                                    imageIndex={imageIndex}
                                    itemDTO={itemDTO}
                                    locale={locale}
                                    showValidationError={showValidationError}
                                    key={imageIndex}
                                    exchangeValidationErrorMessages={exchangeValidationErrors}
                                    validationErrorEntity={validationErrorEntity}
                                    editDisabled={editDisabled}
                                />
                            )
                        })}
                    </View>
                </Col>
                <ResponsiveRow padding={'S200'} justifyContent={'center'}>
                    {!matches.s && <Spacer flex={1} />}
                    <Button
                        icon={<IconPlus />}
                        variant={ButtonVariant.Tertiary}
                        aria-disabled={itemDTO.images.length >= 4 || editDisabled}
                        dataTestId={'add-image'}
                        onClick={() => {
                            InformationImagesHandler.addNewInformationImage(itemDTO.id)
                            focusOnId(listId)
                        }}
                    >
                        Add Image
                    </Button>
                </ResponsiveRow>
            </Container>
        </>
    )
}
