import React from 'react'

import { Flex, FlexProps } from '@amzn/stencil-react-components/layout'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

export function ResponsiveRow(props: Omit<FlexProps, 'flexDirection' | 'css'>) {
    const {
        matches: { s: small },
    } = useBreakpoints()
    return (
        <Flex
            {...props}
            width={small ? '100%' : props.width}
            flexDirection={small ? 'column' : 'row'}
        />
    )
}
