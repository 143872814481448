import { AxiosError } from 'axios'

import { CONFIGS_BY_ENVIRONMENT, STAGE } from 'src/config.app'
import { hookBackendAxiosClient } from 'src/services/AxiosInterceptor'

export interface ScoringTestingV2ValidationObject {
    scoringVariation: string
    incorrectVariationId?: boolean
    incorrectInputScores?: string[]
    incorrectOutputScores?: string[]
    missingInputScores?: string[]
    incorrectHeaders?: string[]
}

export interface AllVariationsScoresOutput {
    allVariationsScores: AllScoresVariationEntry[]
}

export interface AllScoresVariationEntry {
    scoringVariationId: string
    internalScores: string[]
    inputScores: string[]
}

export interface HookError extends Error {
    type: string
    message: string
    fileValidationInformation: ScoringTestingV2ValidationObject[]
}

export interface WorkflowIdSearchParams {
    query: string
    offset: number
    limit: number
}

export class ScoringTestingV2Service {
    private static readonly SCORING_ENDPOINT_NAME: string = '/scoringTestingV2'
    private static readonly TEST_ENDPOINT_NAME: string = '/testScoring'
    private static readonly VALIDATION_ENDPOINT_NAME: string = '/validateTestFile'
    private static readonly WORKFLOW_ENDPOINT_PREFIX: string = '/workflows'
    private static readonly ALL_VARIATION_EDNPOINT_NAME: string =
        '/versions/draft/allVariationsScores'

    public static async callScoringTestingV2(
        workflowId: string,
        xlsxFileName: string,
        stage: STAGE
    ): Promise<string> {
        const config = {
            headers: { 'Content-Type': 'application/json' },
        }
        try {
            const { data } = await hookBackendAxiosClient.post(
                this.callScoringPathBuilder(workflowId, false, stage),
                { xlsxFileName },
                config
            )
            return data['outputFileUrl'] as string
        } catch (e: unknown) {
            console.error(e)
            throw this.handleHookError(e)
        }
    }

    public static async callFileValidation(
        workflowId: string,
        xlsxFileName: string,
        stage: STAGE
    ): Promise<void> {
        try {
            await hookBackendAxiosClient.get(
                this.callScoringPathBuilder(workflowId, true, stage, xlsxFileName),
                { apiActionName: 'scoring.testing.validation' }
            )
        } catch (e: unknown) {
            console.error(e)
            throw this.handleHookError(e)
        }
    }

    public static async callGetAllVariationsScores(
        workflowId: string,
        stage: STAGE
    ): Promise<AllVariationsScoresOutput> {
        const callPath = `${CONFIGS_BY_ENVIRONMENT[stage].hookConfig.hookAPIUrl}${ScoringTestingV2Service.WORKFLOW_ENDPOINT_PREFIX}/${workflowId}${ScoringTestingV2Service.ALL_VARIATION_EDNPOINT_NAME}`
        try {
            const { data } = await hookBackendAxiosClient.get(callPath, {
                apiActionName: 'workflows.getAllVariationsScores',
            })
            return data as AllVariationsScoresOutput
        } catch (e: unknown) {
            console.error(e)
            throw this.handleHookError(e)
        }
    }

    private static callScoringPathBuilder(
        workflowId: string,
        isValidationOnly: boolean,
        stage: STAGE,
        xlsxFileName?: string
    ): string {
        if (isValidationOnly) {
            return `${CONFIGS_BY_ENVIRONMENT[stage].hookConfig.hookAPIUrl}${
                this.SCORING_ENDPOINT_NAME
            }/${workflowId}${this.VALIDATION_ENDPOINT_NAME}?xlsxFileName=${xlsxFileName ?? ''}`
        } else {
            return `${CONFIGS_BY_ENVIRONMENT[stage].hookConfig.hookAPIUrl}${this.SCORING_ENDPOINT_NAME}/${workflowId}${this.TEST_ENDPOINT_NAME}`
        }
    }

    private static handleHookError(e: unknown): HookError {
        const axiosError = e as AxiosError | undefined
        if (axiosError?.response?.data) {
            return axiosError.response.data as HookError
        }
        return new Error('Unexpected error encountered, please try again later.') as HookError
    }
}
