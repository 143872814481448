import React, { useEffect, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'

import { Label } from '@amzn/stencil-react-components/dist/submodules/text'
import {
    Checkbox,
    Input,
    InputFooter,
    InputInsetElement,
    Select,
} from '@amzn/stencil-react-components/form'
import { Row, Spacer } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import { MarkdownEditor, ToolbarControls } from 'src/components/MarkdownEditor'
import { Locale } from 'src/models/dto/Locale'
import { CellMode } from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { SymbolSelectInput } from 'src/pages/module-builder/item-editors/SymbolLegendInput'

export enum LabelSize {
    Small = 'Small',
    Normal = 'Normal',
}

export type FontWeight = 'bold' | 'medium' | 'light' | 'regular'

const LabelSizeAttributes: Record<
    LabelSize,
    { fontSize?: string; color?: string; fontWeight?: FontWeight }
> = {
    Small: {
        fontSize: 'T100',
        fontWeight: 'regular',
        color: 'neutral70',
    },
    Normal: {
        fontSize: 'T300',
        fontWeight: 'bold',
        color: 'neutral90',
    },
}

interface ItemEditorInputProps<T> {
    disabled?: boolean
    inputId: string
    itemId: string
    dataTestId?: string
    labelText?: string
    labelSize?: LabelSize
    cellMode?: CellMode
    value: T
    setValue: (nextValue: T) => void
    showError?: boolean
    setOnBlur?: boolean // a boolean to change the policy to only set the value on blur as opposed to on change
}

interface ItemEditorTextInputProps extends ItemEditorInputProps<string> {
    placeholder: string
    dataTestId?: string
    validationErrorMessage?: string
    insetElementLeading?: InputInsetElement
    type?: string
    maxCharacterLimit?: number
}

interface ItemEditorLabelProps {
    labelSize?: LabelSize
    id: string
    labelId?: string
    labelText?: string | React.ReactNode
    cellMode?: CellMode
}

export const ItemEditorLabel = ({
    labelSize = LabelSize.Small,
    id,
    labelId,
    labelText,
    cellMode,
}: ItemEditorLabelProps) => {
    return (
        <Label htmlFor={id} id={labelId}>
            <Text
                fontSize={LabelSizeAttributes[labelSize]?.fontSize}
                fontWeight={LabelSizeAttributes[labelSize]?.fontWeight}
                color={LabelSizeAttributes[labelSize]?.color}
                style={cellMode === CellMode.Table ? { visibility: 'hidden' } : {}}
            >
                {labelText}
            </Text>
        </Label>
    )
}

interface ItemEditorFooterProps {
    invalid: boolean
    validationErrorMessage?: string
    maxCharacterLimit?: number
    textCharacterLength: number
    footerId: string
}

const ItemEditorFooter = ({
    invalid,
    maxCharacterLimit,
    textCharacterLength,
    validationErrorMessage,
    footerId,
}: ItemEditorFooterProps) => {
    if (invalid) {
        return (
            <InputFooter error id={footerId}>
                {validationErrorMessage}
            </InputFooter>
        )
    } else if (maxCharacterLimit) {
        if (textCharacterLength > maxCharacterLimit) {
            return (
                <InputFooter error id={footerId}>
                    You&#39;ve exceeded {maxCharacterLimit} characters
                </InputFooter>
            )
        } else if (textCharacterLength > 0) {
            return (
                <InputFooter id={footerId}>
                    {textCharacterLength}/{maxCharacterLimit} characters
                </InputFooter>
            )
        }
        return <InputFooter id={footerId}>Max {maxCharacterLimit} characters</InputFooter>
    }
    return <></>
}

const ItemEditorTextInputToExport = React.memo((props: ItemEditorTextInputProps) => {
    const invalid: boolean =
        (props.showError ?? false) &&
        props.validationErrorMessage !== undefined &&
        props.validationErrorMessage?.length > 0

    const [localValue, setLocalValue] = useState(props.value)

    useEffect(() => {
        setLocalValue(props.value)
    }, [props.value])

    const id = `${props.inputId}-${props.itemId}`
    const footerId = `${id}-validation-footer`
    const isCell = props.cellMode === CellMode.Table

    function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        props.setValue(e?.target?.value ?? '')
    }

    return (
        <>
            {!isCell && (
                <ItemEditorLabel
                    labelSize={props.labelSize}
                    labelText={props.labelText}
                    cellMode={props.cellMode}
                    id={id}
                />
            )}
            <Spacer height={'S100'} />
            <Input
                dataTestId={props.dataTestId ?? ''}
                value={localValue}
                disabled={props.disabled}
                type={(props.type as 'number' | 'text') ?? 'text'}
                placeholder={props.placeholder}
                onChange={(e) => {
                    setLocalValue(e?.target?.value || '')
                    if (!props.setOnBlur) {
                        changeHandler(e)
                    }
                }}
                onBlur={(e) => {
                    if (props.setOnBlur) {
                        changeHandler(e)
                    }
                }}
                error={invalid}
                id={id}
                aria-label={isCell ? props.labelText : undefined}
                aria-describedby={invalid ? footerId : undefined}
                insetElementLeading={props.insetElementLeading}
            />
            <ItemEditorFooter
                footerId={footerId}
                invalid={invalid}
                maxCharacterLimit={props.maxCharacterLimit}
                textCharacterLength={localValue.length}
                validationErrorMessage={props.validationErrorMessage}
            />
        </>
    )
})

ItemEditorTextInputToExport.displayName = 'ItemEditorTextInput'

export const ItemEditorTextInput = ItemEditorTextInputToExport

interface ItemEditorTextAreaProps extends ItemEditorInputProps<string> {
    validationErrorMessage?: string
    fallbackToTextArea?: boolean
    locale: Locale
    placeholder?: string
    height?: number | string
    maxCharacterLimit?: number
    toolbarControls?: ToolbarControls
}

const ItemEditorTextAreaToExport = (props: ItemEditorTextAreaProps) => {
    const invalid: boolean =
        (props.showError ?? false) &&
        props.validationErrorMessage !== undefined &&
        props.validationErrorMessage?.length > 0

    const id = `${props.inputId}-${props.itemId}`
    const footerId = `${id}-validation-footer`
    const isCell = props.cellMode === CellMode.Table

    return (
        <>
            {!isCell && (
                <ItemEditorLabel
                    labelSize={props.labelSize ?? LabelSize.Normal}
                    labelText={props.labelText ?? ''}
                    cellMode={props.cellMode ?? CellMode.Card}
                    id={id}
                />
            )}
            <Spacer height={'S100'} />
            <MarkdownEditor
                key={props.locale}
                id={id}
                value={props.value}
                onChange={props.setValue}
                locale={props.locale}
                disabled={props.disabled ?? false}
                fallbackToTextArea={props.fallbackToTextArea ?? false}
                dataTestId={props.dataTestId ?? id}
                placeholder={props.placeholder}
                height={props.height}
                toolbarControls={props.toolbarControls}
            />
            <ItemEditorFooter
                footerId={footerId}
                invalid={invalid}
                maxCharacterLimit={props.maxCharacterLimit}
                textCharacterLength={props.value.replace(/<\/?[^>]+(>|$)/g, '').length}
                validationErrorMessage={props.validationErrorMessage}
            />
        </>
    )
}

ItemEditorTextAreaToExport.displayName = 'ItemEditorTextArea'

export const ItemEditorTextArea = ItemEditorTextAreaToExport

const ItemEditorCheckboxInputToExport = React.memo((props: ItemEditorInputProps<boolean>) => {
    const id = `${props.inputId}-${props.itemId}`

    return (
        <>
            <Row>
                <Checkbox
                    dataTestId={props.dataTestId ?? ''}
                    id={id}
                    checked={props.value}
                    disabled={props.disabled}
                    onChange={() => props.setValue(!props.value)}
                />
                <Spacer width={'S100'} />
                <Label htmlFor={id}>{props.labelText}</Label>
            </Row>
        </>
    )
})

ItemEditorCheckboxInputToExport.displayName = 'ItemEditorCheckboxInput'

export const ItemEditorCheckboxInput = ItemEditorCheckboxInputToExport

interface ItemEditorNumberInputProps extends ItemEditorInputProps<number | null> {
    placeholder: string
    validationErrorMessage?: string
    insetElementLeading?: InputInsetElement
    min?: number
    max?: number
}

const ItemEditorNumberInputToExport = React.memo((props: ItemEditorNumberInputProps) => {
    const defaultValue: string =
        props.value === null || typeof props.value === 'undefined' ? '' : props.value.toString()

    const [localValue, setLocalValue] = useState<string>(defaultValue)

    const [localValidation, setLocalValidation] = useState<string>('')

    useEffect(() => {
        setLocalValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (!props.setOnBlur) {
            setLocalValue(defaultValue)
        }
    }, [props.setOnBlur, defaultValue])

    const invalid: boolean =
        (props.showError ?? false) &&
        props.validationErrorMessage !== undefined &&
        props.validationErrorMessage?.length > 0

    const id = `${props.inputId}-${props.itemId}`

    const footerId = `${id}-validation-footer`
    const validationMessage = invalid ? props.validationErrorMessage : localValidation
    const isCell = props.cellMode === CellMode.Table

    /** Parses a string value into a number, null (empty string), or undefined (not a number) */
    const parseValue = (value: string): number | undefined | null => {
        const n = Number.parseFloat(value)
        if (value === '') {
            return null
        } else if (isNaN(n)) {
            return undefined
        } else {
            return n
        }
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseValue(e?.target?.value || '')
        if (!isUndefined(val)) {
            if (val === null) {
                props.setValue(val)
                setLocalValidation('')
            } else {
                if (
                    (!isUndefined(props.min) && val < props.min) ||
                    (!isUndefined(props.max) && val > props.max)
                ) {
                    if (isUndefined(props.min) && !isUndefined(props.max)) {
                        setLocalValidation(`Value should be lesser than or equal to ${props.max}`)
                    } else if (!isUndefined(props.min) && isUndefined(props.max)) {
                        setLocalValidation(`Value should be greater than or equal to ${props.min}`)
                    } else if (!isUndefined(props.min) && !isUndefined(props.max)) {
                        setLocalValidation(
                            `Value should be between ${props.min} and ${props.max} inclusive`
                        )
                    }
                } else {
                    setLocalValidation('')
                    props.setValue(val)
                }
            }
        }
    }
    return (
        <>
            {!isCell && (
                <ItemEditorLabel
                    labelSize={props.labelSize}
                    labelText={props.labelText}
                    cellMode={props.cellMode}
                    id={id}
                />
            )}
            <Spacer height={'S100'} />
            <Input
                dataTestId={props.dataTestId ?? ''}
                value={localValue}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const number = parseValue(e?.target?.value || '')
                    if (!isUndefined(number)) {
                        if (number === null) {
                            setLocalValue('')
                        } else {
                            setLocalValue(e.target.value)
                        }
                    } else {
                        setLocalValue('')
                    }
                    if (!props.setOnBlur) {
                        changeHandler(e)
                    }
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.setOnBlur) {
                        changeHandler(e)
                    }
                }}
                error={invalid}
                id={id}
                aria-label={isCell ? props.labelText : undefined}
                aria-describedby={validationMessage ? footerId : undefined}
            />
            {validationMessage ? (
                <InputFooter
                    style={{ visibility: validationMessage ? 'visible' : 'hidden' }}
                    error
                    id={footerId}
                >
                    {validationMessage}
                </InputFooter>
            ) : null}
        </>
    )
})

ItemEditorNumberInputToExport.displayName

export const ItemEditorNumberInput = ItemEditorNumberInputToExport

interface ItemEditorSelectProps<T> extends ItemEditorInputProps<T> {
    valueToDisplayNames: Map<T, string>
    isSymbolLegend?: boolean
}

const GenericSelectInput = <T,>({
    value,
    displayNamesToValues,
    setValue,
    labelId,
    id,
    options,
    dataTestId,
    disabled,
}: {
    value: string | undefined
    displayNamesToValues: Map<string, T>
    setValue: (nextValue: T) => void
    options: string[]
    id: string
    labelId?: string
    dataTestId: string
    disabled?: boolean
}) => {
    return (
        <Select
            dataTestId={dataTestId}
            labelId={labelId}
            id={id}
            options={options}
            disabled={disabled}
            value={value}
            placeholder='Select one'
            onChange={(nextValue: string) => {
                if (nextValue) {
                    const nextConvertedToCorrectType = displayNamesToValues.get(nextValue)
                    if (nextConvertedToCorrectType) {
                        setValue(nextConvertedToCorrectType)
                    }
                }
            }}
        />
    )
}

const ItemEditorSelectInputToExport = React.memo(<T,>(props: ItemEditorSelectProps<T>) => {
    const options: string[] = useMemo(
        () => Array.from(props.valueToDisplayNames.values()),
        [props.valueToDisplayNames]
    )

    const displayNamesToValues: Map<string, T> = useMemo(() => {
        return new Map<string, T>(
            Array.from(props.valueToDisplayNames).map((rec) => {
                rec.reverse()
                return rec as never as [string, T]
            })
        )
    }, [props.valueToDisplayNames])

    const id = `${props.inputId}-${props.itemId}`
    const labelId = `${id}-label`
    const isCell = props.cellMode === CellMode.Table

    return (
        <>
            {!isCell && (
                <ItemEditorLabel
                    labelSize={LabelSize.Small}
                    labelText={props.labelText}
                    cellMode={props.cellMode}
                    id={id}
                    labelId={labelId}
                />
            )}
            <Spacer height={'S100'} />
            {props.isSymbolLegend ? (
                <SymbolSelectInput
                    dataTestId={props.dataTestId ?? ''}
                    aria-label={props.labelText}
                    labelId={isCell ? undefined : labelId}
                    id={id}
                    options={options}
                    disabled={props.disabled}
                    value={props.valueToDisplayNames.get(props.value)}
                    displayNamesToValues={displayNamesToValues}
                    setValue={props.setValue}
                />
            ) : (
                <GenericSelectInput
                    dataTestId={props.dataTestId ?? ''}
                    aria-label={props.labelText}
                    labelId={isCell ? undefined : labelId}
                    id={id}
                    options={options}
                    disabled={props.disabled}
                    value={props.valueToDisplayNames.get(props.value)}
                    displayNamesToValues={displayNamesToValues}
                    setValue={props.setValue}
                />
            )}
        </>
    )
})

ItemEditorSelectInputToExport.displayName = 'ItemEditorSelectInput'

export const ItemEditorSelectInput = ItemEditorSelectInputToExport as never as <T>(
    props: ItemEditorSelectProps<T>
) => JSX.Element

interface GenericItemEditorSelectProps<T> extends ItemEditorInputProps<T> {
    valueToDisplayNames: Map<T, string>
    options: string[]
    displayNamesToValues: Map<string, T>
    validationErrorMessage?: string
    disallowedOptions?: Set<T>
    disallowedErrorMessage?: string
}

const ItemEditorGenericSelectInputToExport = React.memo(
    <T,>(props: GenericItemEditorSelectProps<T>) => {
        const disallowedOptions = useMemo(
            () => props.disallowedOptions ?? new Set([]),
            [props.disallowedOptions]
        )
        const id = `${props.inputId}-${props.itemId}`
        const isDisallowed = props.disallowedOptions && disallowedOptions.has(props.value)
        const invalid: boolean =
            isDisallowed ||
            ((props.showError ?? false) &&
                props.validationErrorMessage !== undefined &&
                props.validationErrorMessage?.length > 0)

        const adjustedOptions = useMemo(() => {
            return isDisallowed
                ? props.options
                : props.options.filter(
                      (o) => !disallowedOptions.has(props.displayNamesToValues.get(o) as never)
                  )
        }, [props.options, isDisallowed, disallowedOptions, props.displayNamesToValues])

        const labelId = `${id}-label`
        const footerId = `${id}-validation-footer`
        const isCell = props.cellMode === CellMode.Table
        const disallowedMessage = props.disallowedErrorMessage ?? 'This option is not allowed.'

        return (
            <>
                {!isCell && (
                    <ItemEditorLabel
                        labelSize={LabelSize.Small}
                        labelText={props.labelText}
                        cellMode={props.cellMode}
                        labelId={labelId}
                        id={id}
                    />
                )}
                <Spacer height='S100' />
                <GenericSelectInput
                    labelId={isCell ? undefined : labelId}
                    aria-label={props.labelText}
                    id={id}
                    options={adjustedOptions}
                    disabled={props.disabled}
                    value={props.valueToDisplayNames.get(props.value)}
                    displayNamesToValues={props.displayNamesToValues}
                    setValue={props.setValue}
                    dataTestId={props.dataTestId ?? ''}
                />
                {invalid ? (
                    <InputFooter
                        style={{ visibility: invalid ? 'visible' : 'hidden' }}
                        error
                        id={footerId}
                    >
                        {isDisallowed ? disallowedMessage : props.validationErrorMessage}
                    </InputFooter>
                ) : null}
            </>
        )
    }
)

ItemEditorGenericSelectInputToExport.displayName = 'GenericItemEditorSelectInput'

export const GenericItemEditorSelectInput: <T>(
    props: GenericItemEditorSelectProps<T>
) => JSX.Element = ItemEditorGenericSelectInputToExport as never
