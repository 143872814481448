import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'

export enum FreeTextDisplayType {
    SingleLine = 'SingleLine',
    MultiLine = 'MultiLine',
}

export interface FreeTextItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.FreeText
    questionI18N: LocalizedAttribute<string>
    characterLimit: number | null
    freeTextDisplayType: FreeTextDisplayType
}

export const defaultFreeTextItem = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        freeTextDisplayType: FreeTextDisplayType.SingleLine,
        characterLimit: 50,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.FreeText,
        ppt: ItemType.FreeText,
        questionI18N: {
            [Locale.en_US]: '',
        },
    }
}
