export enum RuleSetCategory {
    JOB_METADATA = 'JOB_METADATA',
}

export enum RuleType {
    JOB_CODE = 'JOB_CODE',
    JOB_FAMILY = 'JOB_FAMILY',
    JOB_LEVEL = 'JOB_LEVEL',
    JOB_COUNTRY_CODE = 'JOB_COUNTRY_CODE',
    JOB_DEPARTMENT_ID = 'JOB_DEPARTMENT_ID',
    JOB_BUSINESS_PURPOSE = 'JOB_BUSINESS_PURPOSE',
    JOB_HIRE_TYPE_ID = 'JOB_HIRE_TYPE_ID',
    JOB_ROLE_KIND = 'JOB_ROLE_KIND',
    JOB_EMPLOYEE_CLASS_ID = 'JOB_EMPLOYEE_CLASS_ID',
}

export enum RuleSubType {
    INCLUDE = 'INCLUDE',
    EXCLUDE = 'EXCLUDE',
}

export interface PECGroupDTO {
    defaultModuleVersionId: string
    moduleVersionRuleSetList: ModuleVersionRuleSetDTO[]
    functionalScoresEnabled: boolean
    functionalScores?: FunctionalScoreDTO[]
}

export interface ModuleVersionRuleSetDTO {
    moduleVersionId: string
    ruleSet: RuleSetDTO
    equivalencyReason?: string
}

export interface RuleSetDTO {
    category: RuleSetCategory
    rules: RuleDTO[]
}

export interface RuleDTO {
    type: RuleType
    subType: RuleSubType
    values: string[]
}

export interface FunctionalScoreDTO {
    functionalScoreLabel: string
    moduleVersionScoreLabelMap: Map<string, string>
}
