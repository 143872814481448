export interface Country {
    readonly alpha3Code: string
    readonly name: string
}
export const Countries: Country[] = [
    {
        alpha3Code: 'ALB',
        name: 'Albania',
    },
    {
        alpha3Code: 'ARG',
        name: 'Argentina',
    },
    {
        alpha3Code: 'ARM',
        name: 'Armenia',
    },
    {
        alpha3Code: 'AUS',
        name: 'Australia',
    },
    {
        alpha3Code: 'AUT',
        name: 'Austria',
    },
    {
        alpha3Code: 'BHR',
        name: 'Bahrain',
    },
    {
        alpha3Code: 'BLR',
        name: 'Belarus',
    },
    {
        alpha3Code: 'BEL',
        name: 'Belgium',
    },
    {
        alpha3Code: 'BRA',
        name: 'Brazil',
    },
    {
        alpha3Code: 'BGR',
        name: 'Bulgaria',
    },
    {
        alpha3Code: 'CAN',
        name: 'Canada',
    },
    {
        alpha3Code: 'CHL',
        name: 'Chile',
    },
    {
        alpha3Code: 'CHN',
        name: 'China',
    },
    {
        alpha3Code: 'COL',
        name: 'Colombia',
    },
    {
        alpha3Code: 'CRI',
        name: 'Costa Rica',
    },
    {
        alpha3Code: 'HRV',
        name: 'Croatia',
    },
    {
        alpha3Code: 'CZE',
        name: 'Czech Republic',
    },
    {
        alpha3Code: 'DNK',
        name: 'Denmark',
    },
    {
        alpha3Code: 'EGY',
        name: 'Egypt',
    },
    {
        alpha3Code: 'FIN',
        name: 'Finland',
    },
    {
        alpha3Code: 'FRA',
        name: 'France',
    },
    {
        alpha3Code: 'DEU',
        name: 'Germany',
    },
    {
        alpha3Code: 'GRC',
        name: 'Greece',
    },
    {
        alpha3Code: 'HKG',
        name: 'Hong Kong',
    },
    {
        alpha3Code: 'IND',
        name: 'India',
    },
    {
        alpha3Code: 'IDN',
        name: 'Indonesia',
    },
    {
        alpha3Code: 'IRL',
        name: 'Ireland',
    },
    {
        alpha3Code: 'ISR',
        name: 'Israel',
    },
    {
        alpha3Code: 'ITA',
        name: 'Italy',
    },
    {
        alpha3Code: 'JAM',
        name: 'Jamaica',
    },
    {
        alpha3Code: 'JPN',
        name: 'Japan',
    },
    {
        alpha3Code: 'JOR',
        name: 'Jordan',
    },
    {
        alpha3Code: 'LUX',
        name: 'Luxembourg',
    },
    {
        alpha3Code: 'MYS',
        name: 'Malaysia',
    },
    {
        alpha3Code: 'MEX',
        name: 'Mexico',
    },
    {
        alpha3Code: 'MAR',
        name: 'Morocco',
    },
    {
        alpha3Code: 'NAM',
        name: 'Namibia',
    },
    {
        alpha3Code: 'NLD',
        name: 'Netherlands',
    },
    {
        alpha3Code: 'NZL',
        name: 'New Zealand',
    },
    {
        alpha3Code: 'NGA',
        name: 'Nigeria',
    },
    {
        alpha3Code: 'NOR',
        name: 'Norway',
    },
    {
        alpha3Code: 'PER',
        name: 'Peru',
    },
    {
        alpha3Code: 'PHL',
        name: 'Philippines',
    },
    {
        alpha3Code: 'POL',
        name: 'Poland',
    },
    {
        alpha3Code: 'PRT',
        name: 'Portugal',
    },
    {
        alpha3Code: 'PRI',
        name: 'Puerto Rico',
    },
    {
        alpha3Code: 'QAT',
        name: 'Qatar',
    },
    {
        alpha3Code: 'ROU',
        name: 'Romania',
    },
    {
        alpha3Code: 'RUS',
        name: 'Russia',
    },
    {
        alpha3Code: 'SAU',
        name: 'Saudi Arabia',
    },
    {
        alpha3Code: 'SRB',
        name: 'Serbia',
    },
    {
        alpha3Code: 'SGP',
        name: 'Singapore',
    },
    {
        alpha3Code: 'SVK',
        name: 'Slovakia',
    },
    {
        alpha3Code: 'SVN',
        name: 'Slovenia',
    },
    {
        alpha3Code: 'ZAF',
        name: 'South Africa',
    },
    {
        alpha3Code: 'KOR',
        name: 'South Korea',
    },
    {
        alpha3Code: 'KWT',
        name: 'Kuwait',
    },
    {
        alpha3Code: 'LBN',
        name: 'Lebanon',
    },
    {
        alpha3Code: 'ESP',
        name: 'Spain',
    },
    {
        alpha3Code: 'SWE',
        name: 'Sweden',
    },
    {
        alpha3Code: 'CHE',
        name: 'Switzerland',
    },
    {
        alpha3Code: 'TWN',
        name: 'Taiwan',
    },
    {
        alpha3Code: 'THA',
        name: 'Thailand',
    },
    {
        alpha3Code: 'TUR',
        name: 'Turkey',
    },
    {
        alpha3Code: 'UKR',
        name: 'Ukraine',
    },
    {
        alpha3Code: 'ARE',
        name: 'United Arab Emirates',
    },
    {
        alpha3Code: 'GBR',
        name: 'United Kingdom',
    },
    {
        alpha3Code: 'USA',
        name: 'United States',
    },
    {
        alpha3Code: 'URY',
        name: 'Uruguay',
    },
    {
        alpha3Code: 'UZB',
        name: 'Uzbekistan',
    },
    {
        alpha3Code: 'VEN',
        name: 'Venezuela',
    },
    {
        alpha3Code: 'VNM',
        name: 'Vietnam',
    },
    {
        alpha3Code: 'MUS',
        name: 'Mauritius',
    },
]
