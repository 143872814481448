import { AxiosResponse } from 'axios'
import PapaParse from 'papaparse'

import { APP_CONFIG } from 'src/config.app'
import {
    CreateItemPoolResponse,
    GeneratedItemPoolConfig,
    ItemContainerMetadataDTO,
    ItemPoolMetadataDTO,
    ItemPoolType,
    ManualItemPoolConfig,
} from 'src/models/dto/item-pools/ItemPoolMetadataDTO'
import { ItemPoolGeneratorResponse } from 'src/models/ItemPoolGeneratorResponse'
import { BUCKETSANDCUPSCSVHEADERS } from 'src/pages/module-builder/buckets-and-cups-editor/BucketsAndCupsCSVImporter'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError, ModuleService } from 'src/services/backend/ModuleService'

export enum GetProgressResponseStatus {
    IN_PROGRESS,
    IN_ERROR,
    COMPLETED,
}

export interface GetProgressResponse {
    status: GetProgressResponseStatus
    message: string
}

export class ItemPoolService {
    public static async generateItemPool(
        config: GeneratedItemPoolConfig
    ): Promise<CreateItemPoolResponse> {
        try {
            const { data }: AxiosResponse<ItemPoolGeneratorResponse> = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/item-pools`,
                {
                    type: ItemPoolType.GENERATED,
                    generatedItemPoolConfig: config,
                },
                {
                    apiActionName: ApiActionNames.CreateItemPool,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            return data
        } catch (e) {
            handleAxiosError(e)
        }
    }

    public static async manualItemPool(
        config: ManualItemPoolConfig
    ): Promise<CreateItemPoolResponse> {
        try {
            const { data }: AxiosResponse<ItemPoolGeneratorResponse> = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/item-pools`,
                {
                    type: ItemPoolType.MANUAL,
                    manualItemPoolConfig: config,
                },
                {
                    apiActionName: ApiActionNames.CreateItemPool,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            return data
        } catch (e) {
            handleAxiosError(e)
        }
    }

    public static async getProgress(versionId: string): Promise<GetProgressResponse> {
        try {
            const { data }: AxiosResponse<GetProgressResponse> = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/item-pools/${versionId}`,
                {
                    apiActionName: ApiActionNames.GetItemPool,
                }
            )

            const status: GetProgressResponseStatus = this.getStatus(data)

            return {
                status: status,
                message: data.status.toString(),
            }
        } catch (e) {
            return {
                status: GetProgressResponseStatus.IN_ERROR,
                message: 'An unexpected error',
            }
        }
    }

    public static async getItemPoolItems(
        versionId: string
    ): Promise<ItemContainerMetadataDTO[] | undefined> {
        try {
            const { data }: AxiosResponse<ItemPoolMetadataDTO> = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/item-pools/${versionId}`,
                {
                    apiActionName: ApiActionNames.GetItemPool,
                }
            )
            return data.itemContainers
        } catch (e) {
            handleAxiosError(e)
        }
    }

    public static async exportBucketsAndCupsCSV(itemPoolContainerVersionIds: string[]) {
        const labels = await this.getItemPoolItemLabels(itemPoolContainerVersionIds)
        if (!labels || labels.length < 1) {
            return ''
        }
        const csvFields = [
            BUCKETSANDCUPSCSVHEADERS.BUCKET_RANK,
            BUCKETSANDCUPSCSVHEADERS.BUCKET,
            BUCKETSANDCUPSCSVHEADERS.BUCKET_MAX_FREQUENCY,
            BUCKETSANDCUPSCSVHEADERS.CUP_RANK,
            BUCKETSANDCUPSCSVHEADERS.CUP,
            BUCKETSANDCUPSCSVHEADERS.ITEM_ID,
        ]
        const csvData = labels.map((label) => {
            return ['', '', '', '', '', label]
        })
        const csv = PapaParse.unparse({ fields: csvFields, data: csvData })
        return new Blob([csv], { type: 'text/csv' })
    }
    public static async getItemPoolItemLabels(itemPoolContainerVersionIds: string[]) {
        const itemPoolLabels: string[] = []
        for (const versionId of itemPoolContainerVersionIds) {
            const moduleLabels = await ModuleService.getModuleItemGroupLabels(versionId)
            for (const label of moduleLabels) {
                itemPoolLabels.push(label)
            }
        }
        return itemPoolLabels
    }

    private static getStatus(r: GetProgressResponse): GetProgressResponseStatus {
        if (r.status.toString().toLowerCase() === 'completed') {
            return GetProgressResponseStatus.COMPLETED
        } else {
            return r.status.toString().toLowerCase().indexOf('error') === -1
                ? GetProgressResponseStatus.IN_PROGRESS
                : GetProgressResponseStatus.IN_ERROR
        }
    }

    public static async exportItemPool(versionId: string): Promise<Blob> {
        try {
            const resp: AxiosResponse<Blob> = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/item-pools/${versionId}/export`,
                {
                    responseType: 'blob',
                    apiActionName: ApiActionNames.ExportItemPool,
                }
            )

            return new Blob([resp.data], { type: 'application/zip' })
        } catch (e) {
            handleAxiosError(e)
        }
    }
}
