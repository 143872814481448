import React, { SetStateAction, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { IconMinus, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { applyChange as apply } from 'src/hooks/DTOEditor'
import {
    GraphConstruction,
    GraphConstructionType,
    ItemCodeGenerationData,
    Leaf,
} from 'src/pages/GraphConstructionEditor/index'
import { NodeDataEditor } from 'src/pages/GraphConstructionEditor/NodeDataEditor'

const treeBorder = '2px solid rgb(23, 104, 201)'
const noBorder = '2px solid transparent'
const indentWidthSpacing = 'S200'
const iconWidth = 20

const TreeEditorList = styledWithTheme('ul')(({ theme }) => ({
    listStylePosition: 'outside',
    listStyle: 'none',
    paddingLeft: `calc(${
        theme.selectors.space(indentWidthSpacing) ?? '16'
    }px + 0.5*${iconWidth}px - 1px)`,
}))

const ListKey = Symbol('ListKey')

const BorderSpacer = styledWithTheme(Spacer)(({ theme }) => ({
    width: `${theme.selectors.space(indentWidthSpacing) ?? '16'}px`,
    height: '50%',
}))

type HasListKey = { [ListKey]?: string | number }

function AddChildIcon() {
    return (
        <Col aria-hidden>
            <BorderSpacer style={{ borderLeft: treeBorder }} />
            <BorderSpacer style={{ borderTop: treeBorder }} />
        </Col>
    )
}

function NodeIconInner({
    isLeaf,
    type,
    onClick,
    isExpanded,
}: {
    isLeaf: boolean
    type: GraphConstructionType
    onClick: () => void
    isExpanded: boolean
}) {
    const d = iconWidth
    return (
        <>
            <Col aria-hidden>
                <BorderSpacer />
                <BorderSpacer style={{ borderTop: treeBorder }} />
            </Col>
            <Col aria-hidden justifyContent='center' alignItems='center'>
                <Spacer width={0} flex='1 0 0' style={{ borderLeft: noBorder }} />
                <Row
                    flex={`0 0 ${d}`}
                    width={d}
                    height={d}
                    alignItems='center'
                    justifyContent='center'
                    style={{
                        borderRadius: type === GraphConstructionType.Alt ? 0 : d,
                        border: treeBorder,
                    }}
                >
                    {!isLeaf ? (
                        <Button
                            aria-label='Expand/collapse this node'
                            aria-expanded={isExpanded}
                            icon={
                                isExpanded ? (
                                    <IconMinus title='Collapse' />
                                ) : (
                                    <IconPlus title='Expand' />
                                )
                            }
                            onClick={onClick}
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                        />
                    ) : null}
                </Row>
                <Spacer
                    width={0}
                    flex='1 0 0'
                    style={{ borderLeft: !isLeaf ? treeBorder : noBorder }}
                />
            </Col>
        </>
    )
}

const NodeIcon = React.memo(NodeIconInner)

function AddChildButton({ addChild }: { addChild: () => void }) {
    return (
        <li role='none' key='+'>
            <Row alignItems='stretch' role='treeitem'>
                <AddChildIcon />
                <Spacer width='4px' />
                <Button
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Small}
                    dataTestId='add-child'
                    onClick={addChild}
                >
                    Add Child
                </Button>
            </Row>
        </li>
    )
}

export interface TreeEditorProps {
    type: GraphConstructionType
    isRoot?: boolean
    value: GraphConstruction
    hideWeight?: boolean
    totalWeight?: number
    setValue: (newValue: SetStateAction<GraphConstruction>) => void
    removeSelf?: () => void
    moveToParent?: () => void
}

function makeChild(data?: ItemCodeGenerationData): Leaf {
    return {
        type: GraphConstructionType.Leaf,
        data: data ?? { regex: ['A-Z'], weight: 1 },
    }
}

function NodeDescInner({
    data = {},
    hideWeight = false,
    leaf,
    totalWeight = 1,
    typeDesc,
    weight,
}: {
    data?: { regex?: string[]; shuffle?: boolean; pick?: number }
    hideWeight?: boolean
    weight: number
    totalWeight?: number
    leaf: boolean
    typeDesc: string
}) {
    const { pick, regex, shuffle } = data
    return (
        <>
            {!hideWeight && (
                <Text key='weight' style={{ background: '#EEE' }}>
                    <ScreenReaderOnly>Weight: </ScreenReaderOnly>
                    <span title='Proportion of this branch being taken (rounded)'>
                        {/* eslint-disable-next-line no-magic-numbers */}
                        {`(${((100 * weight) / totalWeight).toFixed(1)}%)`}
                    </span>
                </Text>
            )}
            <Text key='desc'>
                {leaf ? (
                    <span>
                        {pick === -1 ? 'Pick all of ' : `Pick ${pick ?? 1} of `}
                        <code style={{ fontFamily: 'monospace' }}>
                            [{(regex ?? []).join(', ')}]
                        </code>
                        {(regex ?? []).length > 1 && (shuffle ? ' with shuffle' : ' in order')}
                    </span>
                ) : (
                    <span className='tree-editor-desc tree-editor-type-desc'>{typeDesc}</span>
                )}
            </Text>
        </>
    )
}

const NodeDesc = React.memo(NodeDescInner)

const actionsPadding = { left: 'S200' }

const NodeActions = ({
    openEditor,
    split,
    removeSelf,
    moveToParent,
}: Partial<Record<'openEditor' | 'split' | 'removeSelf' | 'moveToParent', () => void>>) => {
    return (
        <Row key='actions' padding={actionsPadding} aria-label='Actions'>
            {openEditor && (
                <Button
                    key='edit'
                    variant={ButtonVariant.Tertiary}
                    size={ButtonSize.Small}
                    dataTestId='edit-node'
                    onClick={openEditor}
                    title='Edit this node'
                >
                    Edit
                </Button>
            )}
            {split && (
                <Button
                    key='split'
                    variant={ButtonVariant.Tertiary}
                    size={ButtonSize.Small}
                    dataTestId='split-node'
                    onClick={split}
                >
                    Split
                </Button>
            )}
            {removeSelf && (
                <Button
                    key='remove'
                    variant={ButtonVariant.Tertiary}
                    size={ButtonSize.Small}
                    isDestructive
                    dataTestId='remove-node'
                    onClick={removeSelf}
                    title='Remove this node'
                >
                    Remove
                </Button>
            )}
            {moveToParent && (
                <Button
                    key='unsplit'
                    variant={ButtonVariant.Tertiary}
                    size={ButtonSize.Small}
                    dataTestId='unsplit-node'
                    onClick={moveToParent}
                >
                    Unsplit
                </Button>
            )}
        </Row>
    )
}

// eslint-disable-next-line prefer-const
let TreeEditorRec: typeof TreeEditorInner

/**
 * Tree-based graph construction editor.
 */
function TreeEditorInner({
    value,
    type,
    isRoot,
    totalWeight = 1,
    hideWeight = false,
    removeSelf,
    moveToParent,
    setValue,
}: TreeEditorProps) {
    function desc(type1: GraphConstructionType) {
        return type1 === GraphConstructionType.Seq ? 'In sequence: ' : 'One of: '
    }
    const setChildren = useCallback(
        (change: SetStateAction<GraphConstruction[]>) => {
            setValue((value0: GraphConstruction) => {
                if (
                    value0.type === GraphConstructionType.Seq ||
                    value0.type === GraphConstructionType.Alt
                ) {
                    return { ...value0, children: apply(value0?.children ?? [], change) as never[] }
                }
                return value0
            })
        },
        [setValue]
    )

    const setData = useCallback(
        (change: SetStateAction<unknown>) => {
            setValue((value0) => {
                return { ...value0, data: apply(value0?.data, change) as never }
            })
        },
        [setValue]
    )

    const children: GraphConstruction[] = useMemo(() => value?.children ?? [], [value?.children])
    const data: ItemCodeGenerationData | undefined = useMemo(() => value?.data, [value.data])
    const isLeaf = children.length === 0
    const singleChild = children.length === 1
    const weight = data?.weight ?? 1

    const addChild = useCallback(() => {
        setChildren((c) => [...c, makeChild()])
    }, [setChildren])

    const split = useCallback(() => {
        setValue((value0: GraphConstruction) => {
            if (value0.type !== GraphConstructionType.Leaf) {
                return value0
            }
            return {
                type,
                children: [...(value0.children || []), makeChild(value0?.data)],
                data: { weight },
            } as GraphConstruction
        })
    }, [setValue, type, weight])

    const moveOnlyChildToParent = useCallback(() => {
        setValue((value0: GraphConstruction) => {
            if (
                value0?.type === GraphConstructionType.Leaf ||
                (value0?.children?.length ?? 0) !== 1
            ) {
                console.log('unsplit: failed: ', { value0 })
                return value0
            }
            const firstChild = (value0?.children as never as [GraphConstruction])[0]
            if (firstChild.type !== GraphConstructionType.Leaf) {
                console.log('unsplit: failed: ', { value0, firstChild })
                return value0
            }

            return {
                type: firstChild.type,
                data: firstChild.data,
                children: firstChild.children,
            }
        })
    }, [setValue])

    const typeDesc = desc(type)
    const oppositeType: GraphConstructionType =
        type === GraphConstructionType.Seq ? GraphConstructionType.Alt : GraphConstructionType.Seq
    const hideWeightForChildren = type === GraphConstructionType.Seq
    const [isEditOpen, setEditOpen] = useState(false)
    const showEdit = isLeaf || type !== GraphConstructionType.Alt
    const [isExpanded, toggle] = useReducer((x: boolean) => !x, true)

    const removeSelfForIndex = useMemo(
        () =>
            _.range(children.length).map((index: number) => () => {
                setChildren((c) => {
                    const c1 = [...c]
                    c1.splice(index, 1)
                    return c1
                })
            }),
        [setChildren, children.length]
    )

    const onChildChangeForIndex = useMemo(
        () =>
            _.range(children.length).map(
                (index: number) => (updated: SetStateAction<GraphConstruction>) => {
                    setChildren((c) => {
                        if (typeof updated === 'function') {
                            const newChildren = [...c]
                            newChildren[index] = apply(newChildren[index], updated)
                            return newChildren
                        }

                        const prev = c[index]
                        if (prev === updated || JSON.stringify(prev) === JSON.stringify(updated)) {
                            return c
                        }
                        const newChildren = [...c]
                        newChildren[index] = updated
                        return newChildren
                    })
                }
            ),
        [setChildren, children.length]
    )

    useEffect(() => {
        if (isLeaf && value.type !== GraphConstructionType.Leaf) {
            setValue({ type: GraphConstructionType.Leaf, data } as Leaf)
        } else if (!isLeaf && value.type === GraphConstructionType.Leaf) {
            setValue({ type, children: children ?? [], data } as never)
        }
    }, [children, data, isLeaf, setValue, type, value.type])

    const totalWeightForChildren = useMemo(
        () => _.sumBy(children, (c) => c?.data?.weight ?? 1),
        [children]
    )

    const update = useCallback(
        (change: SetStateAction<ItemCodeGenerationData>) => {
            setEditOpen(false)
            setData(change)
        },
        [setEditOpen, setData]
    )

    const closeEditor = useCallback(() => {
        setEditOpen(false)
    }, [setEditOpen])

    const openEditor = useCallback(() => {
        setEditOpen(true)
    }, [setEditOpen])

    const spacerStyle = useMemo(() => ({ borderLeft: isRoot ? noBorder : treeBorder }), [isRoot])

    return (
        <Row alignItems='stretch'>
            <Spacer aria-hidden width='0' style={spacerStyle} />
            <Col>
                <NodeDataEditor
                    isOpen={isEditOpen}
                    isNode={isLeaf}
                    hideWeight={type === GraphConstructionType.Alt}
                    initialData={data as never}
                    setData={update}
                    close={closeEditor}
                    key='editor'
                />
                <Row
                    data-pick={data?.pick}
                    data-choices={data?.regex}
                    alignItems='stretch'
                    gridGap='0'
                    key='line'
                >
                    <NodeIcon
                        isLeaf={isLeaf}
                        type={type}
                        isExpanded={isExpanded}
                        onClick={toggle}
                    />
                    <Spacer width='S200' />
                    <Row alignItems='center' gridGap='S100' flexWrap='wrap' role='treeitem'>
                        <NodeDesc
                            hideWeight={hideWeight}
                            weight={weight}
                            totalWeight={totalWeight}
                            leaf={isLeaf}
                            data={data}
                            typeDesc={typeDesc}
                        />
                        <NodeActions
                            openEditor={showEdit ? openEditor : undefined}
                            split={isLeaf ? split : undefined}
                            removeSelf={isRoot ? undefined : removeSelf}
                            moveToParent={moveToParent}
                        />
                    </Row>
                </Row>
                {!isLeaf && isExpanded && (
                    <TreeEditorList role='tree' key='list'>
                        {children.map((child, i) => {
                            if (!(child as never as HasListKey)[ListKey]) {
                                ;(child as never as HasListKey)[ListKey] = v4()
                            }
                            return (
                                <li
                                    role='presentation'
                                    key={(child as never as HasListKey)[ListKey] ?? i}
                                >
                                    <TreeEditorRec
                                        value={child}
                                        type={oppositeType}
                                        hideWeight={hideWeightForChildren}
                                        removeSelf={removeSelfForIndex[i]}
                                        totalWeight={totalWeightForChildren}
                                        moveToParent={
                                            singleChild ? moveOnlyChildToParent : undefined
                                        }
                                        setValue={onChildChangeForIndex[i]}
                                    />
                                </li>
                            )
                        })}
                        <AddChildButton key='+' addChild={addChild} />
                    </TreeEditorList>
                )}
            </Col>
        </Row>
    )
}

TreeEditorRec = React.memo(TreeEditorInner) as never

export const TreeEditor = TreeEditorRec
