import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { configure } from 'axios-hooks'

import { MainWithSkipLink } from '@amzn/stencil-react-components/a11y'
import { IsItDownBanner } from '@amzn/stencil-react-components/is-it-down-banner'

import { ItemBankSubmissionPage } from 'src/components/ItemBankSubmissionPage'
import { NavigationBar } from 'src/components/NavigationBar'
import { APP_CONFIG } from 'src/config.app'
import { useInterval } from 'src/hooks/useInterval'
import { AssetLibraryPage } from 'src/pages/asset-library/AssetLibraryPage'
import { EditAssetPage } from 'src/pages/asset-library/AssetUploadedPage'
import { AutomationTestsPage } from 'src/pages/automation-tests-request/AutomationTestsPage'
import { GraphConstructionEditorPage } from 'src/pages/GraphConstructionEditor/GraphConstructionEditorPage'
import { HomePage } from 'src/pages/home/HomePage'
import { ItemPoolEditor } from 'src/pages/module-builder/item-pool-editor/ItemPoolEditor'
import { ModuleHistoryPage } from 'src/pages/module-builder/module-history/ModuleHistoryPage'
import { ModuleBuilderPage } from 'src/pages/module-builder/ModuleBuilderPage'
import { MUPPItemBankManager } from 'src/pages/module-builder/mupp-exam-editor/MUPPItemBankManager'
import { ModuleGroupsPage } from 'src/pages/module-groups/ModuleGroupsPage'
import { ModuleGroupViewerPage } from 'src/pages/module-groups/ModuleGroupViewerPage'
import { ModuleReviewPage } from 'src/pages/module-review/ModuleReviewPage'
import { ModuleViewerPage } from 'src/pages/module-review/ModuleViewerPage'
import { RedirectToReviewPage } from 'src/pages/module-review/RedirectToReviewPage'
import PairingTableGenerator from 'src/pages/pairing-table-generator/PairingTableGenerator'
import { ResearchWorkflowPage } from 'src/pages/research-workflow/ResearchWorkflowPage'
import { ScoringTest } from 'src/pages/scoring-test-ui/ScoringTestPage'
import { ScoringTestingV2Landing } from 'src/pages/scoring-testing-v2/ScoringTestingV2Landing'
import { ScoringTestingV2OutputPage } from 'src/pages/scoring-testing-v2/ScoringTestingV2OutputPage'
import { SearchResultsPage } from 'src/pages/search/SearchResultsPage'
import { ADAxios } from 'src/services/AxiosInterceptor'

configure({
    axios: ADAxios,
})

export const App = () => {
    const [outageKey, setOutageKey] = useState(0)
    const TEN_MINUTES = 600000
    useInterval(() => {
        setOutageKey(outageKey + 1)
    }, TEN_MINUTES)

    return (
        <>
            <NavigationBar />
            {APP_CONFIG.isItDownDashboardName && (
                <>
                    <IsItDownBanner
                        dashboardName={APP_CONFIG.isItDownDashboardName}
                        componentId={'outage'}
                        key={outageKey}
                    />
                    <IsItDownBanner
                        dashboardName={APP_CONFIG.isItDownDashboardName}
                        componentId={'release-notes'}
                        isDismissible
                    />
                </>
            )}
            <MainWithSkipLink>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/apt' element={<PairingTableGenerator />} />
                    <Route path='/tree-editor' element={<GraphConstructionEditorPage />} />
                    <Route path='/search' element={<SearchResultsPage />} />
                    <Route path='/scoring-test-ui' element={<ScoringTest />} />
                    <Route path='/scoring-test-v2' element={<ScoringTestingV2Landing />} />
                    <Route
                        path='/scoring-test-v2/:workflowId/:outputFileName'
                        element={<ScoringTestingV2OutputPage />}
                    />
                    <Route path='/module-builder' element={<ModuleBuilderPage />}>
                        <Route path=':moduleId' element={<ModuleBuilderPage />} />
                    </Route>
                    <Route path='/module/viewer/:moduleVersionId' element={<ModuleViewerPage />} />
                    <Route
                        path='/module/viewer/:moduleVersionId/review'
                        element={<RedirectToReviewPage />}
                    />
                    <Route
                        path='/module/viewer/:moduleVersionId/review/:reviewId'
                        element={<ModuleReviewPage />}
                    />
                    <Route
                        path='/module/viewer/:moduleVersionId/review/:reviewId/revisions/:revisionNumber'
                        element={<ModuleReviewPage />}
                    />
                    <Route path='/item-pool-generator' element={<ItemPoolEditor />}>
                        <Route path=':itemPoolId' element={<ItemPoolEditor />} />
                    </Route>
                    <Route path='/history/:moduleId' element={<ModuleHistoryPage />}>
                        <Route path=':moduleId' element={<ModuleHistoryPage />} />
                    </Route>
                    <Route path='/research-workflow' element={<ResearchWorkflowPage />} />
                    <Route path='/workflow-automation-tests' element={<AutomationTestsPage />} />
                    <Route path='/asset-library' element={<AssetLibraryPage />} />
                    <Route path='/asset-library/create-asset' element={<EditAssetPage />} />
                    <Route path='/asset-library/assets/:versionId' element={<EditAssetPage />} />
                    <Route path='/item-bank-submission' element={<ItemBankSubmissionPage />} />
                    <Route path='/item-bank-manager' element={<MUPPItemBankManager />} />
                    <Route path='/module-groups' element={<ModuleGroupsPage />} />
                    <Route
                        path='/module-groups/:moduleGroupVersionId'
                        element={<ModuleGroupViewerPage />}
                    />
                    <Route
                        path='/module-groups/:moduleGroupVersionId/review'
                        element={<RedirectToReviewPage />}
                    />
                    <Route
                        path='/module-groups/:moduleGroupVersionId/review/:reviewId/revisions/:revisionNumber'
                        element={<ModuleGroupViewerPage />}
                    />
                </Routes>
            </MainWithSkipLink>
        </>
    )
}
