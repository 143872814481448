import { ChatItemDTO, ChatMessage, defaultChatItemDTO } from 'src/models/dto/items/ChatItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, STORE_ACTION, Stores } from 'src/services/Store'

const itemType = ItemType.Chat

export class ChatHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => ChatHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): ChatItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as ChatItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): ChatItemDTO {
        return defaultChatItemDTO()
    }

    private static update(entity: ChatItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as ChatItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as ChatItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as ChatItemDTO)
    }

    static updateMessageHeader(id: string, locale: Locale, index: number, nextValue: string) {
        const entity = this.getEntity(id)
        entity.chatMessages[index].messageHeaderI18N[locale] = nextValue
        this.update(entity)
    }

    static updateMessage(id: string, locale: Locale, index: number, nextValue: string) {
        const entity = this.getEntity(id)
        entity.chatMessages[index].messageI18N[locale] = nextValue
        this.update(entity)
    }

    static updateChatHeader(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        if (entity.chatHeader) {
            entity.chatHeader.headerStringI18N[locale] = nextValue
            this.update(entity)
        } else {
            this.update({
                ...this.getEntity(id),
                chatHeader: {
                    headerStringI18N: {
                        [locale]: nextValue,
                    },
                },
            } as ChatItemDTO)
        }
    }

    static deleteMessage(id: string, index: number) {
        const entity = this.getEntity(id)
        const newMessages = [...entity.chatMessages]
        newMessages.splice(index, 1)

        this.update({
            ...this.getEntity(id),
            chatMessages: newMessages,
        } as ChatItemDTO)
    }

    static addMessage(id: string) {
        const entity = this.getEntity(id)

        entity.chatMessages.push({
            messageHeaderI18N: LocalizeDefault(''),
            messageI18N: LocalizeDefault(''),
        } as ChatMessage)

        this.update(entity)
    }
}
