import { CognitoAuthSession } from 'amazon-cognito-auth-js'
import AWS from 'aws-sdk'
import PapaParse from 'papaparse'

import { APP_CONFIG, CONFIGS_BY_ENVIRONMENT, STAGE } from 'src/config.app'
import { HookAuthenticator } from 'src/pages/scoring-test-ui/authentication/HookAuthenticator'
import { TableDataFormat } from 'src/pages/scoring-test-ui/TableDataFormat'

export interface CSVParseResult {
    data: []
    errors: []
    meta: { [key: string]: any }
}

/**
 * Set up auth
 */
export async function setUpHookAwsConfig(stage?: STAGE) {
    const userSession: CognitoAuthSession | null = HookAuthenticator.getValidUserSession()
    if (!userSession) {
        throw new Error('User session not found')
    }
    const userJwtToken: string = userSession.getIdToken().getJwtToken()

    const cognitoLoginURL = `cognito-idp.us-west-2.amazonaws.com/${
        stage
            ? CONFIGS_BY_ENVIRONMENT[stage].hookAuth.UserPoolId || ''
            : APP_CONFIG.hookAuth.UserPoolId || ''
    }`

    AWS.config.region = 'us-west-2'

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: stage
            ? CONFIGS_BY_ENVIRONMENT[stage].hookConfig.hookIdentityPoolId
            : APP_CONFIG.hookConfig.hookIdentityPoolId,
        Logins: { [cognitoLoginURL]: userJwtToken },
    })
    await Promise.resolve()
}

/**
 * upload file to S3 using the given path
 */
export async function uploadFileToS3(localFile: File, path: string, stage?: STAGE) {
    await setUpHookAwsConfig(stage)
    // use S3 ManagedUpload class as it supports multipart uploads
    try {
        await new AWS.S3.ManagedUpload({
            params: {
                Bucket: stage
                    ? CONFIGS_BY_ENVIRONMENT[stage].hookConfig.s3InputBucketName
                    : APP_CONFIG.hookConfig.s3InputBucketName,
                Key: path,
                Body: localFile,
            },
        }).promise()
    } catch (e: unknown) {
        console.error((e as Error).message)
        throw new Error(`Upload of file "${localFile.name}" failed: ${(e as Error).message}`)
    }
}

/**
 * get file from S3 using the given path
 */
export async function downloadFileFromS3(path: string, stage?: STAGE) {
    await setUpHookAwsConfig(stage)

    // use S3 ManagedUpload class as it supports multipart uploads
    return new AWS.S3()
        .getObject({
            Bucket: stage
                ? CONFIGS_BY_ENVIRONMENT[stage].hookConfig.s3InputBucketName
                : APP_CONFIG.hookConfig.s3InputBucketName,
            Key: path,
        })
        .promise()
        .catch((err) => {
            console.error(err)
            throw new Error('Something went wrong while downloading the output file from S3')
        })
}

/**
 * parse response given by S3 GET call
 */
export async function parseS3GetResponse(response: string, outputFileName: string) {
    const localFileTableList = new Map<string, TableDataFormat>()
    await new Promise((resolve, reject) =>
        PapaParse.parse(response, {
            skipEmptyLines: true,
            header: true,
            complete: resolve,
            error: reject,
        })
    )
        .then((result) => {
            const resultObj = result as CSVParseResult
            const columns = (resultObj.meta['fields'] as string[]).map(function (x: string) {
                return {
                    header: x,
                    accessor: x,
                    sortId: x,
                }
            })

            localFileTableList.set(outputFileName, {
                columns: columns,
                data: resultObj.data,
            })
        })
        .catch((err) => {
            console.error(err)
            throw new Error(
                `Something went wrong while parsing your file: ${(err as Error).message}`
            )
        })
    return localFileTableList
}
