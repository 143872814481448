import { APP_CONFIG } from 'src/config.app'
import { ModuleReviewStatusDTO } from 'src/models/dto/ModuleVersionDTO'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

export class ModulePublishService {
    static async getModuleReviewStatusByReviewId(
        reviewId: string,
        abortController?: AbortController
    ): Promise<ModuleReviewStatusDTO[]> {
        try {
            // TODO not part of API V2 yet, so using V1
            const url = `${APP_CONFIG.backendAPIV1BaseUrl}/module/reviews/${reviewId}`
            const { data }: { data: { moduleReviewStatuses: ModuleReviewStatusDTO[] } } =
                await ADAxios.get(url, {
                    apiActionName: ApiActionNames.GetModuleReviewStatus,
                    signal: abortController?.signal,
                })
            return data.moduleReviewStatuses
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }
}
