import { useState } from 'react'
import axios from 'axios'
import useAxios from 'axios-hooks'

import { APP_CONFIG } from 'src/config.app'
import { useInterval } from 'src/hooks/useInterval'
import { UNPROCESSABLE_ENTITY } from 'src/hooks/usePreview'
import {
    ResearchWorkflowDTO,
    ResearchWorkflowEnvironment,
    ResearchWorkflowResource,
    ResearchWorkflowThemeName,
} from 'src/models/dto/ResearchWorkflowDTO'
import { ApiActionNames } from 'src/services/AxiosInterceptor'

export enum ResearchPreviewProgress {
    SUCCEEDED = 'SUCCEEDED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
}

interface SyncProgressResponse {
    status: SyncForResearchPreviewResponseStatus
}

enum SyncForResearchPreviewResponseStatus {
    SUCCEEDED = 'SUCCEEDED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
}

interface LaunchModuleRequest {
    operationId: string
}

export interface UseResearchPreviewResults {
    previewUrl: string | undefined
    previewValidationError: ResearchValidationErrorMessage[] | undefined
    executePreview: () => void
    generatingPreview: boolean
    unexpectedPreviewError: boolean
}

interface ResearchSyncRequest {
    theme: ResearchWorkflowThemeName
    modules: ResearchWorkflowResource[]
    environment?: ResearchWorkflowEnvironment
}

interface ResearchSyncResponse {
    operationId: string
}

interface UseResearchPreviewProps {
    researchWorkflow: ResearchWorkflowDTO
}

interface LaunchResearchResponse {
    url: string
}

export interface ResearchValidationErrorMessage {
    attributePath: string
    message: string
}

export interface ResearchWorkflowValidationErrorResponse {
    errors: ResearchValidationErrorMessage[]
}

export function useResearchPreview({
    researchWorkflow,
}: UseResearchPreviewProps): UseResearchPreviewResults {
    const ONE_SECOND = 1000

    const [inProgressOperationId, setInProgressOperationId] = useState<string | undefined>()
    const [generatingPreview, setGeneratingPreview] = useState<boolean>(false)
    const [validationError, setValidationError] = useState<
        ResearchValidationErrorMessage[] | undefined
    >()
    const [unexpectedError, setUnexpectedError] = useState<boolean>(false)
    const [previewUrl, setPreviewUrl] = useState<string | undefined>()

    const [{ loading: syncRequestExecuting }, executeSyncRequest] = useAxios<
        ResearchSyncResponse,
        ResearchSyncRequest,
        string
    >(
        {
            method: 'POST',
            url: `${APP_CONFIG.backendAPIBaseUrl}/research-workflow-previews/operations`,
            apiActionName: ApiActionNames.InitiateResearchWorkflow,
        },
        {
            manual: true,
        }
    )

    const [{ loading: syncProgressRequestExecuting }, executeSyncProgressRequest] = useAxios<
        SyncProgressResponse,
        any, //eslint-disable-line @typescript-eslint/no-explicit-any
        any //eslint-disable-line @typescript-eslint/no-explicit-any
    >(
        {
            apiActionName: ApiActionNames.GetResearchWorkflowProgress,
        },
        {
            manual: true,
        }
    )

    const [{ loading: launchRequestExecuting }, executeLaunchRequest] = useAxios<
        LaunchResearchResponse,
        LaunchModuleRequest,
        any //eslint-disable-line @typescript-eslint/no-explicit-any
    >(
        {
            method: 'POST',
            url: `${APP_CONFIG.backendAPIBaseUrl}/research-workflow-previews`,
            apiActionName: ApiActionNames.LaunchResearchWorkflow,
        },
        {
            manual: true,
        }
    )

    useInterval(() => {
        if (
            inProgressOperationId &&
            !syncRequestExecuting &&
            !syncProgressRequestExecuting &&
            !launchRequestExecuting
        ) {
            executeSyncProgressRequest({
                url: `${APP_CONFIG.backendAPIBaseUrl}/research-workflow-previews/operations/${inProgressOperationId}`,
            })
                .then((syncProgressResponse) => {
                    switch (syncProgressResponse.data.status) {
                        case SyncForResearchPreviewResponseStatus.FAILED:
                            setInProgressOperationId(undefined)
                            setGeneratingPreview(false)
                            setUnexpectedError(true)
                            return
                        case SyncForResearchPreviewResponseStatus.SUCCEEDED:
                            if (inProgressOperationId) {
                                executeLaunchRequest({
                                    data: {
                                        operationId: inProgressOperationId,
                                    },
                                })
                                    .then((launchResponse) => {
                                        setPreviewUrl(launchResponse.data.url)
                                        setInProgressOperationId(undefined)
                                        setGeneratingPreview(false)
                                    })
                                    .catch(() => {
                                        setInProgressOperationId(undefined)
                                        setGeneratingPreview(false)
                                        setUnexpectedError(true)
                                        setValidationError(undefined)
                                    })
                            }
                            return
                    }
                })
                .catch(() => {
                    setInProgressOperationId(undefined)
                    setGeneratingPreview(false)
                    setUnexpectedError(true)
                    setValidationError(undefined)
                })
        }
    }, ONE_SECOND)

    return {
        generatingPreview: generatingPreview,
        previewUrl: previewUrl,
        previewValidationError: validationError,
        unexpectedPreviewError: unexpectedError,
        executePreview: () => {
            if (!inProgressOperationId) {
                setPreviewUrl(undefined)
                setValidationError(undefined)
                setUnexpectedError(false)
                const syncPayload: ResearchSyncRequest = {
                    theme: researchWorkflow.theme,
                    modules: researchWorkflow.modules,
                    environment: researchWorkflow.environment,
                }
                setGeneratingPreview(true)
                executeSyncRequest({
                    data: syncPayload,
                })
                    .then((researchSyncResponse) => {
                        setInProgressOperationId(researchSyncResponse.data.operationId)
                    })
                    .catch((syncError) => {
                        setInProgressOperationId(undefined)
                        setGeneratingPreview(false)

                        if (
                            axios.isAxiosError(syncError) &&
                            syncError?.response?.status === UNPROCESSABLE_ENTITY
                        ) {
                            const validationErrorResponse = syncError.response
                                .data as ResearchWorkflowValidationErrorResponse
                            setValidationError(validationErrorResponse.errors)
                        } else {
                            setValidationError(undefined)
                            setUnexpectedError(true)
                        }
                    })
            }
        },
    }
}
