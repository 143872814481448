import './logger'
import 'reset-css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { css, Global } from '@emotion/react'

import { StencilProvider } from '@amzn/stencil-react-components/context'
import defaultTheme from '@amzn/stencil-react-theme-default'

import { App } from 'src/components/App'
import { AuthenticationFailure } from 'src/components/AuthenticationFailure'
import { Authenticator } from 'src/services/Authenticator'
import { initializeStoreServices } from './services/EntityServices'

// if (process.env.NODE_ENV === 'development') {
//     const { worker }: { worker: SetupWorkerApi } = require('./mocks/browser')
//     void worker.start()
// }

// https://stencil.a2z.com/for-developers/styling
const GlobalStyles = () => (
    <Global
        styles={css`
            * {
                box-sizing: border-box;
            }

            html,
            body,
            #root {
                min-height: 100vh;
            }

            html {
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-text-size-adjust: 100%;
                text-rendering: optimizeLegibility;
            }

            body {
                margin: 0;
            }

            div.section {
                &:first-of-type {
                    margin-top: 30px;
                }
                margin-bottom: 30px;
                opacity: 1;

                &.hidden {
                    opacity: 0;
                }

                &:not(.hidden) {
                    animation-name: fadeIn;
                    animation-duration: 100ms;
                }
            }

            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            .float-right {
                float: right;
            }

            ul[data-test-component='StencilSelectList'],
            [data-test-component='StencilPopover'] > ul[role='list'] {
                margin-bottom: 0;
            }

            .ProseMirror ul {
                list-style-type: disc;
            }

            .ProseMirror ol,
            .ProseMirror ul {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0;
                margin-inline-end: 0;
                padding-inline-start: 40px;
            }
            .ProseMirror ol {
                list-style-type: decimal;
            }

            a {
                cursor: pointer;
            }
        `}
    />
)

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

Authenticator.initCognitoAuth(() => {
    ReactDOM.render(
        <React.StrictMode>
            <AuthenticationFailure />
        </React.StrictMode>,
        document.getElementById('root')
    )
})

// initialize the store services
initializeStoreServices()
Authenticator.authenticate(() => {
    // bootstrap the app
    ReactDOM.render(
        <React.StrictMode>
            <StencilProvider
                theme={{
                    ...defaultTheme,
                    constants: {
                        ...defaultTheme.constants,
                        maxWidth: 1108,
                    },
                }}
            >
                <BrowserRouter>
                    <GlobalStyles />
                    <App />
                </BrowserRouter>
            </StencilProvider>
        </React.StrictMode>,
        document.getElementById('root')
    )
})
export { useAsyncAction } from 'src/hooks/useAsyncAction'
