export enum ConditionalBranchingType {
    AND = 'AND',
    OR = 'OR',
}

export interface ItemResponseTriggersDTO {
    itemLabel: string
    responseLabels: string[]
}

export interface ConditionalBranchingLogicDTO {
    itemConditions: ItemResponseTriggersDTO[]
    branchingType: ConditionalBranchingType
}
