import { useEffect, useRef, useState } from 'react'
import { Subscription } from 'rxjs'

import { ContextBoxEntity } from 'src/models/dto/ContextBoxDTO'
import { ModuleGroupDTO } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ACTIVITY_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ActivityEntityService'
import { CONTEXT_BOX_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ContextBoxEntityService'
import { INSTRUCTIONAL_CONTENT_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/InstructionalContentEntityService'
import { ITEM_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ItemEntityService'
import { MODULE_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ModuleEntityService'
import { MODULE_GROUP_ENTITY_SELECTOR } from 'src/services/EntityServices/ModuleGroupEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import { Store, StoreEvent, Stores } from '../services/Store'

export interface UseEntityProps {
    entityId: string
    selector: string
    parent?: {
        entityId: string
        selector: string
    }
}

/**
 *
 * @param props entityId: string representing the entity to observe changes, selector is the store to look for the entity
 * @returns { entity: T, dispatch: (evt: StoreEvent) => void } while the dispatch is exposed, recommend to not use it unless there is no other clean design
 */
export function useEntity<T>(props: UseEntityProps) {
    const { entityId, selector, parent } = props
    const store = useRef<Store<T>>(Stores.get(selector) as Store<T>)
    const [entity, setEntity] = useState<T>(() => store.current.get(entityId))

    const subscription = useRef<Subscription | undefined>()
    const childSubscription = useRef<Subscription | undefined>()

    const parentEntityId = parent?.entityId
    const parentSelector = parent?.selector
    useEffect(() => {
        setEntity(store.current.get(entityId))
        const notifyParent = (evt: unknown) => {
            if (!parentEntityId || !parentSelector) {
                return
            }

            Stores.get(parentSelector)?.dispatchChildChange(
                parentEntityId,
                { entityId, selector },
                evt
            )
        }

        // create new subscription
        subscription.current = store.current.observeId(entityId).subscribe((evt) => {
            setEntity(store.current.get(entityId))
            notifyParent(evt)
        })

        childSubscription.current = store.current.observeChildChanges(entityId).subscribe((evt) => {
            notifyParent(evt)
        })

        return () => {
            subscription.current?.unsubscribe()
            childSubscription.current?.unsubscribe()
        }
    }, [entityId, selector, parentEntityId, parentSelector])

    return {
        entity,
        dispatch: (evt: StoreEvent) => {
            store.current.dispatch(evt)
        },
    }
}

export function useItemEntity<T>(props: { entityId: string; workflowEntityId: string }) {
    return useEntity<T>({
        entityId: props.entityId,
        selector: ITEM_ENTITY_STORE_SELECTOR,
        parent: { entityId: props.workflowEntityId, selector: ACTIVITY_ENTITY_STORE_SELECTOR },
    })
}

export function useInstructionItemEntity<T>(props: {
    entityId: string
    instructionEntityId: string
}) {
    return useEntity<T>({
        entityId: props.entityId,
        selector: ITEM_ENTITY_STORE_SELECTOR,
        parent: {
            entityId: props.instructionEntityId,
            selector: INSTRUCTIONAL_CONTENT_ENTITY_STORE_SELECTOR,
        },
    })
}

export function useInstructionEntity<T>(props: {
    instructionEntityId: string
    moduleEntityId: string
}) {
    return useEntity<T>({
        entityId: props.instructionEntityId,
        selector: INSTRUCTIONAL_CONTENT_ENTITY_STORE_SELECTOR,
        parent: { entityId: props.moduleEntityId, selector: MODULE_ENTITY_STORE_SELECTOR },
    })
}

export function useActivityEntity<T>(props: { workflowEntityId: string; moduleEntityId: string }) {
    return useEntity<T>({
        entityId: props.workflowEntityId,
        selector: ACTIVITY_ENTITY_STORE_SELECTOR,
        parent: { entityId: props.moduleEntityId, selector: MODULE_ENTITY_STORE_SELECTOR },
    })
}

export function useContextBoxEntity(contextBoxId: string, workflowEntityId: string) {
    return useEntity<ContextBoxEntity>({
        entityId: contextBoxId,
        selector: CONTEXT_BOX_ENTITY_STORE_SELECTOR,
        parent: { entityId: workflowEntityId, selector: ACTIVITY_ENTITY_STORE_SELECTOR },
    })
}

export function useValidationErrorEntity(id: string) {
    if (!ValidationErrorEntityService.has(id)) {
        ValidationErrorEntityService.create(id)
    }

    return useEntity<ValidationErrorEntity>({
        entityId: id,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })
}

export function useModuleGroupEntity(moduleGroupVersionId: string) {
    return useEntity<ModuleGroupDTO>({
        entityId: moduleGroupVersionId,
        selector: MODULE_GROUP_ENTITY_SELECTOR,
    })
}
