import React, { useCallback, useEffect, useState } from 'react'

import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'

import { DropdownButton } from 'src/components/DropdownButton'
import { PreviewErrorModal } from 'src/components/ErrorModals'
import { useContextBoxPreview } from 'src/hooks/useContextBoxPreview'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'

interface ContextBoxOptionsProps {
    contextBoxId: string
    workflowEntityId: string
    onPreviewUnexpectedError?: () => void
    onErrorCheck?: (hasValidationError: boolean) => void
    editDisabled?: boolean
}

export function ContextBoxOptions({
    contextBoxId,
    onPreviewUnexpectedError,
    onErrorCheck,
    editDisabled,
}: ContextBoxOptionsProps) {
    const {
        matches: { s: small },
    } = useBreakpoints()

    const { generatingPreview, previewValidationError, previewUrl, executePreview } =
        useContextBoxPreview({
            contextBoxId: contextBoxId,
            onPreviewUnexpectedError: onPreviewUnexpectedError,
        })

    const [previewErrorCount, setPreviewErrorCount] = useState<number | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const runErrorCheck = useCallback(async () => {
        try {
            setLoading(true)
            await ErrorCheckService.templatesErrorCheck(ContextBoxEntityService.toDTO(contextBoxId))
            onErrorCheck?.(false)
            setLoading(false)
        } catch (e: unknown) {
            onErrorCheck?.(true)
            setLoading(false)
        }
    }, [contextBoxId, onErrorCheck])

    useEffect(() => {
        if (previewUrl) {
            window.open(
                previewUrl,
                'preview',
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
            )
        }
    }, [previewUrl])

    useEffect(() => {
        if (previewValidationError) {
            setPreviewErrorCount(previewValidationError.length)
            ;(async function () {
                await runErrorCheck()
            })().catch(console.error)
        }
    }, [previewValidationError, runErrorCheck])

    const dropdownValues = () => [
        {
            name: 'Preview context box',
            dataTestId: 'preview-context-box',
            onClick: () => executePreview(),
        },
        {
            name: 'Run error check',
            dataTestId: 'run-context-box-error-check',
            disabled: editDisabled,
            onClick: () => runErrorCheck(),
        },
    ]

    const showSpinner = generatingPreview || loading

    return (
        <>
            <PreviewErrorModal
                title={'Preview item'}
                close={() => setPreviewErrorCount(undefined)}
                errorCount={previewErrorCount}
            />
            <>
                {showSpinner ? <Spinner dataTestId='preview-spinner' /> : null}
                <div style={{ visibility: showSpinner ? 'hidden' : undefined }}>
                    <DropdownButton
                        title='Context box options'
                        values={dropdownValues()}
                        dataTestId='context-box-options-dropdown'
                        buttonProps={small ? { style: { width: '100%' } } : {}}
                    />
                </div>
            </>
        </>
    )
}
