import React, { useCallback, useEffect, useRef, useState } from 'react'

import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { InputHeader } from '@amzn/stencil-react-components/form'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent } from '@amzn/stencil-react-components/modal'

import { Button } from 'src/components/Button'
import { MediaEntry } from 'src/components/media/MediaEntry'
import { UpdateFields } from 'src/components/media/UseMediaButton'
import { Locale } from 'src/models/dto/Locale'
import {
    MediaValErrorResponseThrowable,
    MediaValidationErrorMessage,
} from 'src/models/media/MediaValidationError'
import {
    LocalMediaFilesService,
    LocalMediaFilesServiceFactory,
    S3FileState,
} from 'src/services/media/LocalMediaFilesService'
import { MediaService } from 'src/services/media/MediaService'
import {
    UserMediaInfo,
    UserMediaService,
    UserMediaServiceFactory,
} from 'src/services/media/UserMediaService'

export interface MediaUploadModalProps {
    isModuleBuilder?: boolean
    updateFields?: UpdateFields
    mediaIndex?: number
    close?: () => void
}

export function MediaUploadModal(props: MediaUploadModalProps) {
    const { close } = props

    const fileUploadRef = useRef<HTMLDivElement | null>(null)
    const [userMediaList, setUserMediaList] = useState<UserMediaInfo[]>([])
    const [fileLoadErrors, setFileLoadErrors] = useState<string[]>([])
    // const [exportSelectedErrors, setExportSelectedErrors] = useState('')
    const [uploadValErrors, setUploadValErrors] = useState<MediaValidationErrorMessage[]>([])
    const [uploadError, setUploadError] = useState('')
    const localMediaFilesService = useRef<LocalMediaFilesService>()
    const userMediaService = useRef<UserMediaService>()

    useEffect(() => {
        localMediaFilesService.current = LocalMediaFilesServiceFactory.loadExisting()
        userMediaService.current = UserMediaServiceFactory.loadExisting()
        setUserMediaList([...userMediaService.current.get()])
    }, [])

    // Work around the issue where it's not possible to assign an ID to Stencil FileUpload
    useEffect(() => {
        const fileInput = fileUploadRef.current?.querySelector('input[type=file]')
        if (fileInput) {
            fileInput.id = 'media-upload-file'
        }
    }, [fileUploadRef])

    function onAlternativeTextChange(id: number, newValue: string) {
        if (userMediaService.current) {
            const media = userMediaService.current.find(id)

            if (media) {
                media.assessmentMedia.alternativeText = newValue
                userMediaService.current.update(media)
                userMediaService.current.validateAlternativeText(newValue)
            }
        }
    }

    function onMediaLabelChange(id: number, newValue: string) {
        if (userMediaService.current) {
            const media = userMediaService.current.find(id)

            if (media) {
                media.assessmentMedia.label = newValue
                userMediaService.current.update(media)
                userMediaService.current.validateMediaLabel(newValue)
            }
        }
    }

    function onLocaleChange(id: number, newValue: string) {
        if (userMediaService.current) {
            const media = userMediaService.current.find(id)

            if (media) {
                media.assessmentMedia.locale = newValue
                userMediaService.current.update(media)
            }
        }
    }

    // function onSelectedChange(id: number, newValue: boolean) {
    //     if (userMediaService.current) {
    //         const media = userMediaService.current.find(id)

    //         if (media) {
    //             media.selected = newValue
    //             userMediaService.current.update(media)
    //         }
    //     }
    // }

    async function onUploadClicked(id: number) {
        if (userMediaService.current) {
            const media: UserMediaInfo | undefined = userMediaService.current.find(id)

            if (media && localMediaFilesService.current) {
                setUploadError('')
                setUploadValErrors([])
                try {
                    const relativeS3Path = localMediaFilesService.current.generateRelativeS3Path(
                        media.assessmentMedia.mediaFile
                    )

                    // upload local file to s3
                    media.s3State = S3FileState.Uploading
                    await localMediaFilesService.current.uploadToS3(
                        media.assessmentMedia.mediaFile,
                        relativeS3Path
                    )
                    media.assessmentMedia.relativePath = relativeS3Path
                    await MediaService.uploadMediaMetadata(media)
                    // update information in case of success
                    media.s3State = S3FileState.Uploaded
                    userMediaService.current.update(media)
                    return media.s3State
                } catch (e: unknown) {
                    // update information in case of error
                    media.s3State = S3FileState.NotUploaded
                    userMediaService.current.update(media)
                    if (
                        (e as MediaValErrorResponseThrowable).errorResponse
                            ?.mediaValidationErrorMessages
                    ) {
                        setUploadValErrors(
                            (e as MediaValErrorResponseThrowable).errorResponse
                                ?.mediaValidationErrorMessages ?? []
                        )
                    } else {
                        setUploadError((e as Error).message)
                    }
                }
            }
        }
        return S3FileState.NotUploaded
    }

    function onTagsChange(id: number, newValue: string[]) {
        if (userMediaService.current) {
            const media = userMediaService.current.find(id)

            if (media) {
                media.assessmentMedia.tags = newValue
                userMediaService.current.update(media)
            }
        }
    }

    // function exportSelectToCSV() {
    //     if (userMediaService.current) {
    //         try {
    //             const newCSVData = userMediaService.current.generateCSVData()
    //             console.log('exportSelectedToCSV', newCSVData)
    //             if (newCSVData.data.length !== 0) {
    //                 setCSVData(newCSVData)
    //             }
    //             setExportSelectedErrors('')
    //         } catch (e: unknown) {
    //             setCSVData(null)
    //             setExportSelectedErrors((e as Error).message)
    //         }
    //     }
    // }

    const onFileAttached = useCallback((files: File[]) => {
        const errorMessages: string[] = []
        if (files && userMediaService.current && localMediaFilesService.current) {
            for (const file of files) {
                try {
                    const localFile = localMediaFilesService.current.set(file)

                    userMediaService.current.add({
                        assessmentMedia: {
                            label: '',
                            locale: Locale.en_US,
                            alternativeText: '',
                            mediaFile: localFile.file.name,
                            mimeType: localFile.file.type,
                            author: MediaService.getDefaultAuthor(),
                            date: new Date().toISOString().split('T')[0],
                        },
                        url: URL.createObjectURL(localFile.file),
                        selected: false,
                        s3State: localFile.s3State,
                        id: userMediaService.current.getLastId() + 1,
                    })
                } catch (e: unknown) {
                    console.error('onFileAttached: error', { error: e, file })
                    errorMessages.push((e as Error).message)
                }
            }
            setFileLoadErrors(errorMessages)
            setUserMediaList([...userMediaService.current.get()])
        }
    }, [])

    return (
        <ModalContent
            maxWidth='90vw'
            titleText='Upload Media'
            buttons={[
                // Hiding for now
                // <Button key={0} onClick={exportSelectToCSV}>
                //     Export selected to CSV
                // </Button>,
                <Button key={1} onClick={close}>
                    Close
                </Button>,
            ]}
        >
            <Col gridGap='S200'>
                {/*exportSelectedErrors && (
                    <Row justifyContent='center'>
                        <MessageBanner type={MessageBannerType.Error}>
                            {exportSelectedErrors}
                        </MessageBanner>
                    </Row>
                )*/}
                {fileLoadErrors.length !== 0 && (
                    <Row justifyContent='center'>
                        <MessageBanner type={MessageBannerType.Error}>
                            {fileLoadErrors}
                        </MessageBanner>
                    </Row>
                )}
                {uploadError && (
                    <Row justifyContent='center'>
                        <MessageBanner type={MessageBannerType.Error}>{uploadError}</MessageBanner>
                    </Row>
                )}
                {uploadValErrors.length !== 0 && (
                    <MessageBanner type={MessageBannerType.Error}>
                        {uploadValErrors.map((errMessage, index) => (
                            <li key={index} data-cy='search-metadata-validation-error-message'>
                                {errMessage.message}
                            </li>
                        ))}
                    </MessageBanner>
                )}
                <Col gridGap='S200'>
                    <InputHeader
                        htmlFor='media-upload-file'
                        labelText='Add media from local storage'
                    />
                    <FileUpload
                        ref={fileUploadRef}
                        dataTestId='media-upload-file'
                        // this prop doesn't do anything despite being part of the type decl
                        id='media-upload-file'
                        accept='image/*, video/*, audio/*'
                        maxFiles={10}
                        isMulti
                        onFileAttached={onFileAttached}
                    />
                </Col>
                {userMediaList.map((m) => (
                    <MediaEntry
                        key={m.id}
                        userMediaInfo={m}
                        {...{
                            onAlternativeTextChange,
                            onMediaLabelChange,
                            onLocaleChange,
                            onTagsChange,
                            // onSelectedChange,
                            onUploadClicked,
                        }}
                        isModuleBuilder={props.isModuleBuilder}
                        updateFields={props.updateFields}
                    />
                ))}
            </Col>
        </ModalContent>
    )
}
