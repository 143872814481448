import React, { useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconArrowLeft, IconCopy, IconDownload } from '@amzn/stencil-react-components/icons'
import { Container, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator'
import { Text } from '@amzn/stencil-react-components/text'

import { useInterval } from 'src/hooks/useInterval'
import { GetProgressResponseStatus, ItemPoolService } from 'src/services/item-pools/ItemPoolService'

export interface ManualItemPoolBuilderFinalProps {
    poolVersionId: string
    poolItemContainers: string[]
    backClick: () => void
    setItemPoolVersionId?: (poolVersionId: string) => void
}

const DELAY_MS = 1000

export interface StatusWrapperProps {
    loading: boolean
    loadingMessage: string
}
export const StatusWrapper = ({ loading, loadingMessage }: StatusWrapperProps) => {
    if (loading) {
        return <StatusIndicator messageText={loadingMessage} status={Status.Loading} />
    } else if (loadingMessage === 'Done') {
        return <StatusIndicator messageText={loadingMessage} status={Status.Positive} />
    } else {
        return <></>
    }
}
export const ManualItemPoolBuilderFinal = ({
    poolVersionId,
    poolItemContainers,
    backClick,
    setItemPoolVersionId,
}: ManualItemPoolBuilderFinalProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingMessage, setLoadingMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [copied, setCopied] = useState<boolean>(false)
    const [downloaded, setDownloaded] = useState<boolean>(false)
    const [downloading, setDownloading] = useState<boolean>(false)

    const downloadPool = async () => {
        try {
            setDownloading(true)
            const blob = await ItemPoolService.exportBucketsAndCupsCSV(poolItemContainers)
            if (!blob) {
                console.log('Error Downloading Item Pool')
            } else {
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${poolVersionId}.csv`
                link.click()
                setDownloaded(true)
            }
        } catch (e: unknown) {
            console.error(e)
            setErrorMessage('Error when downloading buckets and cups csv')
        } finally {
            setDownloading(false)
        }
    }

    const VersionIdContainer = () => {
        return (
            <Container>
                <Row maxWidth={'100%'} columnGap={10}>
                    <Text>{poolVersionId}</Text>
                    <Button
                        dataTestId='copy-manual-version-id'
                        variant={ButtonVariant.Secondary}
                        icon={<IconCopy aria-hidden={true} />}
                        onClick={async () => {
                            await navigator.clipboard.writeText(poolVersionId)
                            setCopied(true)
                        }}
                    >
                        {copied ? 'Copied' : 'Copy'}
                    </Button>
                    <Button
                        dataTestId='select-manual-version-id'
                        variant={ButtonVariant.Secondary}
                        disabled={!setItemPoolVersionId}
                        onClick={() => {
                            if (setItemPoolVersionId) {
                                setItemPoolVersionId(poolVersionId)
                            }
                        }}
                    >
                        Select
                    </Button>
                    {downloading ? (
                        <Spinner />
                    ) : (
                        <Button
                            dataTestId='download-manual-version-id'
                            variant={ButtonVariant.Primary}
                            icon={<IconDownload aria-hidden={true} />}
                            onClick={downloadPool}
                        >
                            {downloaded ? 'Downloaded' : 'Download'}
                        </Button>
                    )}
                </Row>
            </Container>
        )
    }

    useInterval(async () => {
        if (loading) {
            const response = await ItemPoolService.getProgress(poolVersionId)
            if (response.status === GetProgressResponseStatus.IN_PROGRESS) {
                setLoadingMessage(`Creating item pool: ${response.message}`)
                return
            }

            setLoading(false)
            setLoadingMessage('')

            if (response.status === GetProgressResponseStatus.IN_ERROR) {
                setErrorMessage(response.message)
                setLoadingMessage(response.message)
            } else {
                setLoadingMessage('Done')
            }
        }
    }, DELAY_MS)

    return (
        <Container>
            {errorMessage === '' ? null : (
                <MessageBanner
                    type={MessageBannerType.Error}
                    isDismissible={false}
                    dataTestId={'manual-item-pool-creation-error-message-banner'}
                >
                    {errorMessage}
                </MessageBanner>
            )}
            <StatusWrapper loading={loading} loadingMessage={loadingMessage} />

            {!loading && loadingMessage === 'Done' ? <VersionIdContainer /> : null}

            <Button
                icon={<IconArrowLeft />}
                dataTestId='manual-item-pool-builder-back-button'
                variant={ButtonVariant.Secondary}
                disabled={loading}
                onClick={backClick}
            >
                Back
            </Button>
        </Container>
    )
}
