import { ModuleChange } from 'src/models/dto/approval/ReviewDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    adaptiveEngineConfig,
    audioScoringConfig,
    bucketsAndCupsConfig,
    commonSettingConfig,
    itemSettingConfig,
    moduleReviewConfiguration,
    pageSettingConfig,
    randomSelectionConfig,
    workStyleExamConfig,
} from 'src/pages/module-review/ModuleReviewConfiguration'

export const constructChangeMessageList = (changeMap: Map<string, number>) => {
    const messageList: string[] = []
    changeMap.forEach((value, key) => {
        messageList.push(`${key} (${value})`)
    })

    return messageList
}

const unknownChange = 'Unknown change detected'

// Example: workflow.7b0e1630-f490-41b3-9e93-d77693a38aec
const pageRegex = /^workflow\.[^\s.]+(?:[-\w\s]+)?$/
// Example: RandomSelectionGroupDTO.7b0e1630-f490-41b3-9e93-d77693a38aec
//          MUPPExamDTO.7b0e1630-f490-41b3-9e93-d77693a38aec
const specialPageRegex =
    /^(RandomSelectionGroupDTO|MUPPExamDTO|AdaptiveEngineSelectionGroupDTO|BucketsAndCupsBasedSelectionGroupDTO)\.[a-zA-Z0-9_-]+(?:-[a-zA-Z0-9]+)*$/
// Example: workflow.7b0e1630-f490-41b3-9e93-d77693a38aec.moduleChild.items.5e3294a0-c83b-4962-a07e-0594420db645
const itemRegex = /^workflow\.[a-zA-Z0-9_-]+\.moduleChild.items\.[a-zA-Z0-9_-]+$/
// Example: workflow.7b0e1630-f490-41b3-9e93-d77693a38aec.moduleChild.items
const itemRegexOrdering = /^workflow\.[a-zA-Z0-9_-]+\.moduleChild.items/
// Example: workflow.44e272ad-66d1-440a-b16b-8967558114d1.moduleChild.items.781b4286-f0c4-4197-9967-1c8d5dd49fef.responses.2
const itemRegexScoringItem =
    /^workflow\.[a-f0-9-]+\.moduleChild\.items\.[a-f0-9-]+\.(responses|scales|rankingTable.responseRows|stimulus)\.(?:[0-9]|[1-9][0-9]|100)$/
// Example: workflow.7b0e1630-f490-41b3-9e93-d77693a38aec.moduleChild.items.5e3294a0-c83b-4962-a07e-0594420db645.mediaTemplateVersionIds.en_US
const mediaTextRegex = /(?:[a-zA-Z0-9_-]+\.)*mediaTemplateVersionIds\.[a-zA-Z]{2}_[A-Z]{2}/
const localeList: string[] = Object.values(Locale).filter((locale) => locale !== 'en_US')

const ADDED_SUFFIX = '.Added'
const REMOVED_SUFFIX = '.Removed'

const BUCKETS_AND_CUPS_GROUP = 'BucketsAndCupsBasedSelectionGroupDTO'
const RANDOM_SELECTION_GROUP = 'RandomSelectionGroupDTO'
const WORK_STYLE_EXAM_GROUP = 'MUPPExamDTO'
const ADAPTIVE_ENGINE_GROUP = 'AdaptiveEngineSelectionGroupDTO'

const specialPageConfigMap = [
    { config: bucketsAndCupsConfig, group: BUCKETS_AND_CUPS_GROUP },
    { config: randomSelectionConfig, group: RANDOM_SELECTION_GROUP },
    { config: workStyleExamConfig, group: WORK_STYLE_EXAM_GROUP },
    { config: adaptiveEngineConfig, group: ADAPTIVE_ENGINE_GROUP },
]

const incrementMapEntry = (map: Map<string, number>, key: string) => {
    const currentValue = map.get(key) ?? 0
    map.set(key, currentValue + 1)
}

const isPageOrderingChange = (moduleChange: ModuleChange, tempKey: string) => {
    const changeType = moduleChange.changeTypes[0]
    return changeType === 'Ordering' && tempKey === 'workflow'
}

const isItemOrderingChange = (moduleChange: ModuleChange, tempKey: string) => {
    const changeType = moduleChange.changeTypes[0]
    return changeType === 'Ordering' && itemRegexOrdering.test(tempKey)
}

const getSettingChange = (keyPath: string) => {
    if (audioScoringConfig.some((audioScoring) => keyPath.endsWith(audioScoring)))
        return '.audioScoringModel'
    if (pageSettingConfig.some((pageSetting) => keyPath.endsWith(pageSetting)))
        return '.pageSettings'
    if (itemSettingConfig.some((itemSetting) => keyPath.endsWith(itemSetting)))
        return '.itemSettings'
    return null
}

const isAddedOperation = (operation?: string) => {
    return operation && operation === 'ADD'
}

const isRemoveOperation = (operation?: string) => {
    return operation && operation === 'REMOVE'
}

const isUpdateOperation = (operation?: string) => {
    return operation && operation === 'UPDATE'
}

const getItemOrPageChange = (
    tempKey: string,
    majorType: boolean,
    isSpecialPageChange: boolean,
    operation?: string
) => {
    if (pageRegex.test(tempKey)) {
        if (operation === 'ADD' && !isSpecialPageChange) return '.addPages'
        if (operation === 'REMOVE' && !isSpecialPageChange) return '.removePages'
    }
    if (itemRegex.test(tempKey)) {
        if (operation === 'ADD') return majorType ? '.scoreItemAdded' : '.addItems'
        if (operation === 'REMOVE') return majorType ? '.scoreItemRemoved' : '.removeItems'
    }
    return null
}

const isScoringItem = (keyPath: string) => {
    return itemRegexScoringItem.test(keyPath)
}

const getTranslationChange = (keyPath: string) => {
    if (
        localeList.some((translation) => keyPath.endsWith(translation)) ||
        keyPath === 'availableLocales'
    ) {
        return '.translationChange'
    }
    return null
}

export const constructChangeMap = (moduleChanges: ModuleChange[]) => {
    const changeMap: Map<string, number> = new Map()
    moduleChanges.forEach((moduleChange) => {
        let tempKey = moduleChange.keyPath
        const majorType = moduleChange.classification === 'Major'

        if (isPageOrderingChange(moduleChange, tempKey)) {
            tempKey = '.pagesOrder'
        }

        if (isItemOrderingChange(moduleChange, tempKey)) {
            tempKey = '.itemsOrder'
        }

        const isPageOrItemChange = pageRegex.test(tempKey) || itemRegex.test(tempKey)

        const commonChanges = commonSettingConfig.some((common) =>
            moduleChange.keyPath.endsWith(common)
        )

        if (commonChanges) {
            const tempArray = moduleChange.keyPath.split('.')
            tempKey = tempArray[tempArray.length - 1]
        }

        for (const { config, group } of specialPageConfigMap) {
            if (config.some((item) => moduleChange.keyPath.endsWith(item))) {
                tempKey = group
                break
            }
        }

        const specialPageChanges = specialPageRegex.test(moduleChange.keyPath)

        if (specialPageChanges) {
            const [groupName] = moduleChange.keyPath.split('.')
            if (isAddedOperation(moduleChange.operation)) {
                tempKey = groupName + ADDED_SUFFIX
            } else if (isRemoveOperation(moduleChange.operation)) {
                tempKey = groupName + REMOVED_SUFFIX
            } else {
                return
            }
        }

        if (
            isUpdateOperation(moduleChange.operation) &&
            isPageOrItemChange &&
            !specialPageChanges
        ) {
            return
        }

        if (isScoringItem(moduleChange.keyPath)) {
            if (isUpdateOperation(moduleChange.operation)) {
                return
            }

            if (
                isAddedOperation(moduleChange.operation) ||
                isRemoveOperation(moduleChange.operation)
            ) {
                tempKey = 'scores'
            }
        }

        const itemOrPageChange = getItemOrPageChange(
            moduleChange.keyPath,
            majorType,
            specialPageChanges,
            moduleChange.operation
        )
        if (itemOrPageChange) {
            tempKey = itemOrPageChange
        }

        const translationChange = getTranslationChange(moduleChange.keyPath)
        if (translationChange) {
            tempKey = translationChange
        }

        const settingChange = getSettingChange(moduleChange.keyPath)
        if (settingChange) {
            tempKey = settingChange
        }

        if (mediaTextRegex.test(moduleChange.keyPath)) {
            return
        }

        const reviewMessage = moduleReviewConfiguration.get(tempKey)
        incrementMapEntry(changeMap, reviewMessage ?? unknownChange)
    })

    return changeMap
}
