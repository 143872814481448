import React, { useState } from 'react'

import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { H2 } from '@amzn/stencil-react-components/text'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { useEntity } from 'src/hooks/useEntity'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    ItemEditorNumberInput,
    LabelSize,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

interface AssessmentMetadataInputProps {
    entityId: string
    placeholder: string
    idPostfix: string
    label: string
    value?: number
    showError: boolean
    errorMessage?: string
    onChange: (nextValue: number | null) => void
    editDisabled?: boolean
}

const ModuleAssessmentMetadataInput = (props: AssessmentMetadataInputProps) => {
    return (
        <ResponsiveRow gridGap={'S100'} width={'100%'}>
            <Col flex={1}>
                <ItemEditorNumberInput
                    itemId={props.entityId}
                    labelSize={LabelSize.Normal}
                    labelText={props.label}
                    disabled={props.editDisabled}
                    min={0}
                    inputId={`assessment-metadata-input-${props.idPostfix}`}
                    dataTestId={`assessment-metadata-input-${props.idPostfix}`}
                    placeholder={props.placeholder}
                    value={props.value ?? null}
                    setValue={props.onChange}
                    showError={props.showError}
                    validationErrorMessage={props.errorMessage}
                />
            </Col>
        </ResponsiveRow>
    )
}

export const ModuleAssessmentMetadata = ({
    moduleId,
    showValidationError,
    editDisabled,
}: {
    moduleId: string
    showValidationError?: boolean
    editDisabled?: boolean
}) => {
    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    if (!ValidationErrorEntityService.has(moduleId)) {
        ValidationErrorEntityService.create(moduleId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: moduleId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const shouldShowValidationError: boolean =
        !!showValidationError &&
        (!!validationErrorEntity.validationErrors.maxTimeToCompleteModuleInMinutes ||
            !!validationErrorEntity.validationErrors.minTimeToCompleteModuleInMinutes)

    const metadataInputMap: AssessmentMetadataInputProps[] = [
        {
            entityId: moduleId,
            idPostfix: 'minTimeToComplete',
            label: 'Minimum time to complete module in minutes',
            placeholder: 'Enter a value',
            value: moduleEntity.assessmentMetadata?.minTimeToCompleteModuleInMinutes,
            showError: shouldShowValidationError,
            errorMessage:
                validationErrorEntity.validationErrors?.minTimeToCompleteModuleInMinutes?.join(
                    ', '
                ),
            onChange: (nextValue: number | null) => {
                ModuleEntityService.updateMinTimeToCompleteInMinutes(
                    moduleId,
                    nextValue ?? undefined
                )
            },
        },
        {
            entityId: moduleId,
            idPostfix: 'maxTimeToComplete',
            label: 'Maximum time to complete module in minutes',
            placeholder: 'Enter a value',
            value: moduleEntity.assessmentMetadata?.maxTimeToCompleteModuleInMinutes,
            showError: shouldShowValidationError,
            errorMessage:
                validationErrorEntity.validationErrors?.maxTimeToCompleteModuleInMinutes?.join(
                    ', '
                ),
            onChange: (nextValue: number | null) => {
                ModuleEntityService.updateMaxTimeToCompleteInMinutes(
                    moduleId,
                    nextValue ?? undefined
                )
            },
        },
        {
            entityId: moduleId,
            idPostfix: 'resultValidityInDays',
            label: 'Result validity in days',
            placeholder: 'Enter a value',
            showError: shouldShowValidationError,
            value: moduleEntity.assessmentMetadata?.resultValidityInDays,
            onChange: (nextValue: number | null) => {
                ModuleEntityService.updateResultValidityInDays(moduleId, nextValue ?? undefined)
            },
        },
    ]

    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Assessment Metadata`

    return (
        <>
            <Col
                gridGap='S300'
                padding={{ top: 'S300', left: 'S400', right: 'S400', bottom: 'S300' }}
                backgroundColor='#FFFFFF'
            >
                <Row alignItems='center' gridGap='S200' dataTestId={'assessment-metadata-header'}>
                    <Col rowGap={0}>
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                dataTestId={`${
                                    isExpanded ? 'collapse' : 'expand'
                                }-assessment-metadata-expander-button`}
                                iconAltText={triggerButtonName}
                                aria-label={triggerButtonName}
                                isExpanded={isExpanded}
                                onClick={() => setIsExpanded(!isExpanded)}
                                role={'button'}
                            />
                            <H2
                                fontSize='T400'
                                fontWeight='bold'
                                color={shouldShowValidationError ? 'red70' : 'neutral90'}
                            >
                                Assessment Metadata
                            </H2>
                        </Row>
                    </Col>
                </Row>

                <ExpanderContent isExpanded={isExpanded}>
                    <View width={'100%'} padding={{ top: 'S300', bottom: 'S400' }}>
                        <Hr size='wide' />
                    </View>
                    <Col gridGap='S200'>
                        {metadataInputMap.map(
                            (metadataInputProps: AssessmentMetadataInputProps) => {
                                return (
                                    <ModuleAssessmentMetadataInput
                                        key={`assessment-metadata-input-key-${metadataInputProps.idPostfix}`}
                                        editDisabled={editDisabled}
                                        {...metadataInputProps}
                                    />
                                )
                            }
                        )}
                    </Col>
                    <Spacer height={'S200'} />
                </ExpanderContent>
            </Col>
            <Spacer height='S400' />
        </>
    )
}
