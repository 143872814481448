import React, { useMemo } from 'react'

import { IconCheck, IconDisabled } from '@amzn/stencil-react-components/icons'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'

import { ApprovalStatus } from 'src/models/dto/ApprovalStatus'

// TODO AA-17327 refactor the props
export function ModuleApprovalMessage(props: {
    approvalStatus: ApprovalStatus
    reviewers: string[]
}) {
    const { approvalStatus, reviewers } = props
    return useMemo(() => {
        const icon =
            approvalStatus === ApprovalStatus.Approved ? (
                <IconCheck size={32} title='' aria-hidden />
            ) : (
                <IconDisabled size={32} title='' aria-hidden />
            )

        return (
            <MessageBanner
                dataTestId='module-approval'
                data-approval-status={approvalStatus}
                icon={icon}
                type={
                    approvalStatus === ApprovalStatus.Approved
                        ? MessageBannerType.Success
                        : MessageBannerType.Warning
                }
            >
                {approvalStatus === ApprovalStatus.Approved ? 'Approved ' : 'Approval Revoked '}
                by {reviewers.join(', ')}.
            </MessageBanner>
        )
    }, [reviewers, approvalStatus])
}
