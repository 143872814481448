import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'

import {
    Button,
    ButtonIconPosition,
    ButtonSize,
    ButtonVariant,
} from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { IconChevronDown } from '@amzn/stencil-react-components/icons'
import { Col } from '@amzn/stencil-react-components/layout'
import { PageHeaderDropdownButton } from '@amzn/stencil-react-components/page'
import { PopoverController } from '@amzn/stencil-react-components/popover'
import { px } from '@amzn/stencil-react-components/utils'

export interface DropdownButtonProps {
    id?: string
    variant?: ButtonVariant
    title: string
    dataTestId?: string
    ariaLabel?: string
    disabled?: boolean
    renderIcon?: () => JSX.Element
    iconPosition?: ButtonIconPosition
    values: {
        name: string
        dataTestId?: string
        disabled?: boolean
        onClick?: () => void
    }[]
    buttonProps?: Record<string, unknown>
    smallText?: boolean
}

export const DropdownButton = ({
    id,
    variant = ButtonVariant.Secondary,
    values,
    dataTestId,
    ariaLabel,
    title,
    renderIcon,
    disabled,
    iconPosition = ButtonIconPosition.Trailing,
    buttonProps = {},
    smallText = false,
}: DropdownButtonProps) => {
    const { theme } = useContext(StencilContext)
    const [isOpen, setIsOpen] = useState(false)
    const target = useRef<HTMLButtonElement | null>(null)
    const items = useRef<Record<number, HTMLButtonElement | null>>({})
    const focusOnItem = useCallback((i: number) => items.current[i]?.focus(), [items])
    const toggle = useCallback(() => setIsOpen((val) => !val), [])
    const open = useCallback(() => {
        setIsOpen(true)
    }, [])
    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    // Keyboard events are implemented to imitate Stencil <Select> component. It is not possible to re-use any
    // code from Stencil to implement these keyboard events.
    // Another example for reference is https://w3c.github.io/aria-practices/examples/menubar/menubar-editor.html
    const buttonOnKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.ctrlKey || e.altKey || e.metaKey) {
                return
            }

            switch (e.key) {
                case ' ':
                case 'Enter':
                case 'Down':
                case 'ArrowDown':
                    open()
                    break
                case 'Up':
                case 'ArrowUp':
                    open()
                    break
                default:
                    return
            }

            e.preventDefault()
        },
        [open]
    )

    const getOnKeyDown = useCallback(
        (i: number, value: typeof values[0]) => {
            const n = values.length
            return (e: React.KeyboardEvent<HTMLButtonElement>) => {
                if (e.ctrlKey || e.altKey || e.metaKey) {
                    return
                }

                switch (e.key) {
                    case 'Home':
                        focusOnItem(0)
                        break
                    case 'End':
                        focusOnItem(n - 1)
                        break
                    case 'Tab':
                        close()
                        target.current?.focus()
                        break
                    case 'Up':
                    case 'ArrowUp':
                        if (i > 0) {
                            focusOnItem(i - 1)
                        }
                        break
                    case 'ArrowDown':
                    case 'Down':
                        if (i + 1 < n) {
                            focusOnItem(i + 1)
                        }
                        break
                    default:
                        // When a character is pressed, move to the next item (cyclic) that starts with the same character
                        if (e.key.match(/^\S$/)) {
                            const check = (name: string) => name.toLowerCase().startsWith(e.key)
                            const indexAfter = check(value.name)
                                ? values.findIndex(({ name }, i1) => check(name) && i1 > i)
                                : -1
                            const indexBefore = values.findIndex(({ name }) => check(name))
                            const index = indexAfter !== -1 ? indexAfter : indexBefore
                            if (index !== -1) {
                                focusOnItem(index)
                            }
                            break
                        } else {
                            return
                        }
                }
                e.preventDefault()
            }
        },
        [close, focusOnItem, values]
    )

    const onOpened = useCallback(() => {
        focusOnItem(0)
    }, [focusOnItem])

    const fontSize = smallText
        ? theme.font.primary.size.T100.l.fontSize
        : theme.font.primary.size.T200.l.fontSize

    const body = useMemo(
        () =>
            (values || []).map((value, i) => (
                <PageHeaderDropdownButton
                    key={value.name}
                    role='menuitem'
                    data-item-name={value.name}
                    style={{
                        width: '100%',
                        fontSize,
                        justifyContent: 'center',
                        margin: 0,
                        padding: px(theme.space.S200),
                    }}
                    onClick={() => {
                        close()
                        target.current?.focus()
                        value.onClick?.()
                    }}
                    ref={(el) => {
                        items.current[i] = el
                    }}
                    onKeyDown={getOnKeyDown(i, value)}
                    disabled={value.disabled}
                    data-test-id={value.dataTestId}
                    size={ButtonSize.Small}
                >
                    {value.name}
                </PageHeaderDropdownButton>
            )),
        [close, getOnKeyDown, fontSize, theme.space.S200, values]
    )

    if (!values || values.length === 0) {
        return null
    }

    return (
        <>
            <Button
                key={id}
                {...buttonProps}
                id={id}
                variant={variant}
                disabled={disabled}
                aria-haspopup='menu'
                aria-label={ariaLabel}
                icon={!renderIcon ? <IconChevronDown title='' aria-hidden /> : renderIcon()}
                iconPosition={
                    iconPosition !== undefined ? iconPosition : ButtonIconPosition.Trailing
                }
                onKeyDown={buttonOnKeyDown}
                onClick={() => {
                    toggle()
                }}
                ref={target}
                dataTestId={dataTestId}
            >
                {title}
            </Button>
            <PopoverController
                key='_popover'
                shouldFocusOnOpen
                shouldCloseOnFocusLeave
                onOpened={onOpened}
                {...{ target, isOpen, close }}
            >
                {isOpen ? <Col role='menu'>{body}</Col> : <div />}
            </PopoverController>
        </>
    )
}
