import JSZip, { JSZipObject } from 'jszip'
import PapaParse, { ParseResult } from 'papaparse'
import { v4 } from 'uuid'

import { MUPPDichotomousItemDTO, MUPPItemBankDTO } from 'src/models/dto/activities/MUPPItemBankDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'

export async function parseMUPPItemBankDTOFromJSONExport(zipFile: JSZip) {
    const catItemBank: {
        value: {
            id: string
            versionId: string
            description: string
            catItemVersionIds: string[]
        } | null
    } = { value: null }
    const catItems: Record<string, MUPPDichotomousItemDTO> = {}
    const availableLocales = new Set<Locale>()
    const availableDimensions = new Set<string>()
    const promises = Object.entries(zipFile.files).map(
        ([name, fileObject]: [string, JSZipObject]) => {
            const m = /^(catItemBank|catItem\/.*)\/(.*).json$/.exec(name)
            if (!m) {
                return Promise.resolve()
            }

            return fileObject.async('text').then((text: string) => {
                if (m[1] == 'catItemBank') {
                    catItemBank.value = JSON.parse(text)
                } else {
                    const {
                        versionId: version,
                        elementType: _et,
                        versionNumber: _vn,
                        statement: _s,
                        ...item
                    } = JSON.parse(text) as Omit<MUPPDichotomousItemDTO, 'version'> & {
                        versionId: string
                        elementType: unknown
                        versionNumber: unknown
                        statement: unknown
                    }
                    availableDimensions.add(item.dimension)
                    for (const key of Object.keys(item.statementI18N)) {
                        availableLocales.add(key as never as Locale)
                    }
                    catItems[m[2]] = { version, ...item }
                }
            })
        }
    )
    await Promise.all(promises)

    if (catItemBank.value) {
        const { catItemVersionIds, versionId, ...value } = catItemBank.value
        return {
            ...value,
            version: versionId,
            availableLocales: Array.from(availableLocales.values()),
            availableDimensions: Array.from(availableDimensions.values()),
            catItems: catItemVersionIds.map((v) => catItems[v]).filter((x) => !!x),
        } as MUPPItemBankDTO | null
    }

    return null
}

export async function parseMUPPItemBankDTOFromCSVExport(zipFile: JSZip) {
    const catItemBank: Record<string, unknown> = {
        id: v4(),
        elementType: 'CAT_ITEM_BANK',
        itemType: 'MUPP_ITEM_BANK',
        type: 'MUPP_ITEM_BANK',
        versionNumber: '1.0',
    }
    const catItems: Record<string, MUPPDichotomousItemDTO> = {}
    const availableLocales = new Set<Locale>()
    const availableDimensions = new Set<string>()
    if (!('catItemBankTemplate.csv' in zipFile.files)) {
        return null
    }

    const csvText = await zipFile.files['catItemBankTemplate.csv'].async('text')
    const templateRows: ParseResult<Record<string, string>> = await new Promise((complete) =>
        PapaParse.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            delimiter: ',',
            complete,
        })
    )

    if (templateRows.errors.length) {
        console.warn('CSV Parse Error', 'catItemBankTemplate.csv', templateRows.errors)
        return null
    }

    if (!templateRows.data.length) {
        console.warn('File is empty: ', 'catItemBankTemplate.csv', templateRows)
        console.warn('CSV Text', csvText)
        return null
    }

    for (const row of templateRows.data) {
        const label = row['Statement Label']
        const dimension = row['Dimension']
        catItems[label] = {
            id: v4(),
            version: row['System Generated Statement ID'],
            label,
            type: 'MUPP_DICHOTOMOUS_ITEM',
            locale: row['Locale'] as never,
            statementI18N: LocalizeDefault(''),
            dimension,
            socialDesirability: parseFloat(row['Social Desirability']),
            exposure: parseFloat(row['Exposure']),
            delta: parseFloat(row['Delta']),
            alpha: parseFloat(row['Alpha']),
            tau: parseFloat(row['Tau']),
        }
        catItemBank.version = row['System Generated Item Bank ID']
        catItemBank.description = row['Item Bank Description']
        catItemBank.referenceId = row['Item Bank Reference ID']
        availableDimensions.add(dimension)
    }

    const promises = Object.entries(zipFile.files).map(
        ([name, fileObject]: [string, JSZipObject]) => {
            const m = /^catItemBankTemplate_([a-zA-Z0-9-_]+).csv$/.exec(name)
            if (!m) {
                return Promise.resolve()
            }

            return (async () => {
                const text = await fileObject.async('text')
                const localizationRows: ParseResult<Record<string, string>> = await new Promise(
                    (complete) =>
                        PapaParse.parse(text, {
                            header: true,
                            skipEmptyLines: true,
                            delimiter: ',',
                            complete,
                        })
                )

                if (localizationRows.errors.length) {
                    console.warn(
                        'CSV Parse Error for localization file',
                        name,
                        localizationRows.errors
                    )
                    return
                }

                for (const row of localizationRows.data) {
                    const label = row['Statement Label']
                    if (catItems[label]) {
                        const locale = row['Locale'] as never as Locale
                        availableLocales.add(locale)
                        catItems[label].statementI18N[locale] = row['Statement']
                    }
                }
            })()
        }
    )
    await Promise.all(promises)

    if (catItemBank.version) {
        return {
            ...catItemBank,
            availableLocales: Array.from(availableLocales.values()),
            availableDimensions: Array.from(availableDimensions.values()),
            catItems: Object.values(catItems).filter((x) => x),
        } as MUPPItemBankDTO | null
    }

    return null
}
