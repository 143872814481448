import { LocalizedAttribute } from '../Locale'

export enum TableCellType {
    TEXT = 'TEXT',
    AVAILABILITY = 'AVAILABILITY',
    ICON = 'ICON',
    SYMBOL = 'SYMBOL',
}

export enum TableIconType {
    HARVEY_BALL_EMPTY = 'HARVEY_BALL_EMPTY',
    HARVEY_BALL_HALF_FULL = 'HARVEY_BALL_HALF_FULL',
    HARVEY_BALL_FULL = 'HARVEY_BALL_FULL',
}

export enum TableSymbolType {
    CHIME = 'CHIME',
    PLAY = 'PLAY',
    RECORD = 'RECORD',
    STOP = 'STOP',
    DIAMOND_STOP = 'DIAMOND_STOP',
    STAR = 'STAR',
    CROSS = 'CROSS',
    CHECK = 'CHECK',
    ARROW_UP = 'ARROW_UP',
    ARROW_DOWN = 'ARROW_DOWN',
    SORT = 'SORT',
    PLUS_CROSS = 'PLUS_CROSS',
}

export interface TableHeader {
    headerI18N: LocalizedAttribute<string>
}

export interface TableCell {
    dataI18N: LocalizedAttribute<string>
    type: TableCellType
}

export interface TableRow {
    cells: (TableCell | undefined)[]
}

export interface TableLegend {
    iconType: TableIconType | TableSymbolType | undefined
    textI18N: LocalizedAttribute<string>
}

export interface BaseTableDTO {
    headers: TableHeader[]
    rows: TableRow[]
    legends: TableLegend[]
    harveyBallLegendEnabled: boolean
    symbolLegendEnabled: boolean
}

export interface SymbolLegendDefinition {
    symbolType?: TableSymbolType
    symbolLegendText?: string
    index: number
}

export interface SymbolLegend {
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    onDefTextChange: (value: string, index: number) => void
    onDefSymbolTypeChange: (symbolType: TableSymbolType, index: number) => void
    createEmptySymbolLegend: () => void
    onLegendDelete: (index: number) => void
    legendHumanReadableName: string
    legendDataTestId: string
    legendDefinitions: SymbolLegendDefinition[]
    numOfSymbols: number
}
