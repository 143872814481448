import { TableItemDTO } from 'src/models/dto/items/TableItemDTO'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'
import { factories, item, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemDTO, ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.Table

export class TableHandler extends TableOperations<TableItemDTO> {
    public static readonly INSTANCE = new TableHandler()

    private constructor() {
        super()
    }

    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => TableHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    public static create(): TableItemDTO {
        const defaultNumberOfColumns = 4
        const defaultNumberOfRows = 5
        return {
            ...item(),
            itemType,
            ppt: 'Table',
            headers: TableOperations.createEmptyHeaders(defaultNumberOfColumns),
            rows: TableOperations.createEmptyRows(defaultNumberOfRows, defaultNumberOfColumns),
            legends: [],
            symbolLegendEnabled: false,
            harveyBallLegendEnabled: false,
        }
    }

    private getEntity(id: string): TableItemDTO {
        if (TableHandler.store().has(id)) {
            return TableHandler.store().get(id) as TableItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    protected getTable(id: string): TableItemDTO {
        return this.getEntity(id)
    }

    protected updateTable(id: string, entity: TableItemDTO) {
        const tableItem = TableHandler.store().get(id)

        TableHandler.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: {
                ...tableItem,
                ...entity,
            },
        })
    }

    public updatePreserveOrder(id: string, nextValue: boolean) {
        this.updateTable(id, {
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as TableItemDTO)
    }
}
