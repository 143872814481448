import React, { useCallback, useMemo } from 'react'

import {
    ButtonIconPosition,
    ButtonSize,
    ButtonVariant,
} from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { EmotionTheme, styledWithTheme } from '@amzn/stencil-react-components/context'
import {
    IconAlertCircleFill,
    IconBin,
    IconCopy,
    IconPencil,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { RawMediaView } from 'src/components/media/RawMediaView'
import { useMediaSrc } from 'src/components/MediaContentsEditor'
import { MediaModal } from 'src/components/MediaContentsEditor/MediaModal'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { MediaContent } from 'src/models/dto/items/MediaDTO'
import { Locale } from 'src/models/dto/Locale'

export interface MediaCardProps {
    mediaContent: MediaContent
    setMediaContent: React.Dispatch<React.SetStateAction<MediaContent>>
    mediaIndex: number
    deleteMediaContent: () => void
    locale: Locale
}

const MediaCardContainer = styledWithTheme(Card)(
    ({ error, theme }: { error?: boolean; theme: EmotionTheme }) => `
    background-color: ${theme.selectors.color(error ? 'red05' : 'primary05')};
    border-radius: 5px;
    border: none;
`
)

export function MediaCard(props: MediaCardProps) {
    const { mediaContent, deleteMediaContent } = props
    const {
        matches: { s: isS, m: isM },
    } = useBreakpoints()
    const isSmall = isS || isM

    const {
        altTextI18N: { [props.locale]: altText = '' },
        relativePathI18N: { [props.locale]: url = '' },
        mimeType,
    } = mediaContent
    const { name, mediaSrc, size } = useMediaSrc(url)

    const missingAltText = (altText || '').trim() === ''
    const renderModal = useCallback(
        ({ close }: { close: () => void }) => <MediaModal {...{ close, ...props }} />,
        [props]
    )

    const flexProps = useMemo(
        () => ({
            width: '100%',
            flexDirection: isSmall ? 'column' : 'row',
            gridGap: isSmall ? '0' : 'S200',
        }),
        [isSmall]
    )

    return (
        <MediaCardContainer
            error={missingAltText}
            role='group'
            dataTestId={`media-item-${props.mediaIndex}`}
        >
            <WithModal renderModal={renderModal}>
                {({ open: onClick }) => (
                    <Flex {...flexProps}>
                        {!isS && (
                            <Row
                                style={{ background: '#4A4A4A', width: 64, height: 64 }}
                                margin={isM ? { left: 'S200' } : {}}
                                justifyContent='center'
                            >
                                {mediaSrc && (
                                    <RawMediaView
                                        url={mediaSrc}
                                        mimeType={mimeType}
                                        altText='Preview of media'
                                    />
                                )}
                            </Row>
                        )}
                        <Col alignItems='flex-start'>
                            <Button
                                size={ButtonSize.Small}
                                variant={ButtonVariant.Tertiary}
                                icon={<IconPencil title='' aria-hidden />}
                                iconPosition={ButtonIconPosition.Trailing}
                                title={`Edit image ${name}`}
                                aria-label={`Edit image ${name}`}
                                onClick={onClick}
                                dataTestId='media-files-open-media-modal'
                            >
                                <Text>{name}</Text>
                            </Button>
                            <Col margin={{ left: '1em' }}>
                                <Text dataTestId='media-files-media-size'>
                                    <ScreenReaderOnly>File size: </ScreenReaderOnly>
                                    {size}
                                </Text>
                            </Col>
                        </Col>
                        <Spacer flex='0%' />
                        {missingAltText && (
                            <Col alignItems='flex-start' justifyContent='center'>
                                <Button
                                    size={ButtonSize.Small}
                                    variant={ButtonVariant.Tertiary}
                                    isDestructive
                                    icon={
                                        <IconAlertCircleFill title='' aria-hidden color='red90' />
                                    }
                                    title={`Edit image ${name}`}
                                    onClick={onClick}
                                    dataTestId='media-files-alt-text-missing'
                                >
                                    Alt text missing
                                </Button>
                            </Col>
                        )}
                        {isSmall && <Hr />}
                        <Row alignItems='center' justifyContent='space-between'>
                            <Col justifyContent='center'>
                                <Button
                                    size={ButtonSize.Small}
                                    variant={ButtonVariant.Tertiary}
                                    icon={<IconCopy title='' aria-hidden />}
                                    onClick={() => navigator.clipboard?.writeText(url)}
                                    title={`Copy image URL ${name}`}
                                    dataTestId='media-files-copy-url'
                                >
                                    Copy URL
                                </Button>
                            </Col>
                            <Col justifyContent='center'>
                                <Button
                                    size={ButtonSize.Small}
                                    variant={ButtonVariant.Tertiary}
                                    isDestructive
                                    icon={<IconBin title='' aria-hidden color='red90' />}
                                    title={`Delete image ${name}`}
                                    aria-label={`Delete image ${name}`}
                                    disabled={!isDefaultLocale(props.locale)}
                                    onClick={deleteMediaContent}
                                    dataTestId='media-files-delete-media'
                                >
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    </Flex>
                )}
            </WithModal>
        </MediaCardContainer>
    )
}
