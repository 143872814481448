import { useCallback } from 'react'

import { Change, StateAndCallback } from 'src/hooks/DTOEditor/index'

/**
 * Given an optional value, autofill with a default value.
 */
export function useAutoFillOptionalEditor<T>(
    props: StateAndCallback<T | undefined>,
    getDefault: () => T
) {
    const value = props.value ?? getDefault()
    const onChangeForOptional = props.onChange
    const onChange = useCallback(
        (change: Change<T>) => {
            onChangeForOptional((prevValue: T | undefined) => {
                if (typeof change === 'function') {
                    return (change as (prevValue: T) => T)(prevValue ?? getDefault())
                } else {
                    return change as T | undefined
                }
            })
        },
        [onChangeForOptional, getDefault]
    )
    return { value, onChange }
}
