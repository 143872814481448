import _ from 'lodash'

export enum ChoiceType {
    CharRange = 'CHAR_RANGE',
    ListChoice = 'LIST_CHOICE',
    NumericRange = 'NUMERIC_RANGE',
}

export interface BaseChoice {
    type: ChoiceType
}

export interface CharRangeChoice extends BaseChoice {
    type: ChoiceType.CharRange
    start: string
    end: string
}

export interface NumericRangeChoice extends BaseChoice {
    type: ChoiceType.NumericRange
    start: number
    end: number
}

export interface ListChoice extends BaseChoice {
    type: ChoiceType.ListChoice
    list: string[]
}

export type Choice = CharRangeChoice | NumericRangeChoice | ListChoice

const digit = /^[0-9]$/

export function parseFromRegex(regex: string): Choice {
    if (regex.length === 3 && regex[1] === '-') {
        const start = regex[0]
        const end = regex[2]
        const startIsDigit = digit.test(start)
        const endIsDigit = digit.test(end)
        if (startIsDigit && endIsDigit) {
            return {
                type: ChoiceType.NumericRange,
                start: parseInt(start, 10) || 0,
                end: parseInt(end, 10) || 0,
            }
        } else {
            return {
                type: ChoiceType.CharRange,
                start,
                end,
            }
        }
    } else {
        return {
            type: ChoiceType.ListChoice,
            list: regex.split(''),
        }
    }
}

function charBetween(start: string, end: string) {
    const [code1, code2] = [start.charCodeAt(0), end.charCodeAt(0)]
    return String.fromCharCode(_.random(code1, code2, false))
}

// For more details on choices, pick and shuffle, consult them "Combination" class from RxtHufAuthoringService
export function sampleChoices(params: { choices: Choice[]; pick?: number; shuffle?: boolean }) {
    const { choices: choices0, pick, shuffle } = params
    if (choices0.length === 0) {
        return ''
    }

    const count = (pick ?? -1) === -1 ? choices0.length : pick ?? -1
    const choices = shuffle ? _.shuffle(choices0) : [...choices0]
    const res: string[] = []
    for (let i = 0; i < count; i++) {
        const choice = choices[i % choices.length]
        switch (choice.type) {
            case ChoiceType.CharRange:
                res.push(charBetween(choice.start, choice.end))
                break
            case ChoiceType.NumericRange:
                res.push(charBetween(choice.start.toString(), choice.end.toString()))
                break
            case ChoiceType.ListChoice:
                res.push(_.shuffle([...choice.list])[0])
                break
        }
    }
    return res.join('')
}
