import React, { useEffect } from 'react'

import {
    InputWrapper,
    RecommendedLengthFooter,
    TextArea,
} from '@amzn/stencil-react-components/form'
import { TokenType, useRecommendedLength } from '@amzn/stencil-react-components/hooks'
import { View } from '@amzn/stencil-react-components/layout'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

export interface TextAreaWithCharacterLimitProps {
    textValue: string
    characterLimit: number
    setTextValue: (value: string) => void
    labelText: string
    isRequired: boolean
    setIsOverCharacterLimit: (isOverCharacterLayer: boolean) => void
    dataTestId: string
    placeholder: string
    textAreaValidation?: string
    setTextAreaValidation?: (textAreaValidation: string) => void
}

export const TextAreaWithCharacterLimit = (props: TextAreaWithCharacterLimitProps) => {
    const { footerText, warning, error, progress } = useRecommendedLength({
        value: props.textValue,
        tokenType: TokenType.Characters,
        recommendedLength: props.characterLimit,
    })

    const { matches } = useBreakpoints()

    useEffect(() => {
        props.setIsOverCharacterLimit(warning)
    }, [props, warning])

    return (
        <View width={matches.s ? '90vw' : '40vw'}>
            <InputWrapper
                id='text-area-with-limit'
                data-test-id='text-area-with-limit'
                labelText={props.labelText}
                error={error || warning || !!props.textAreaValidation}
                required={props.isRequired}
                footer={(footerProps) => (
                    <RecommendedLengthFooter progress={progress} {...footerProps}>
                        {props.textAreaValidation ? props.textAreaValidation : footerText}
                    </RecommendedLengthFooter>
                )}
            >
                {(inputProps) => (
                    <TextArea
                        data-test-id={props.dataTestId}
                        {...inputProps}
                        value={props.textValue}
                        onChange={(e) => {
                            props.setTextValue(e.target.value)
                            if (props.setTextAreaValidation) {
                                props.setTextAreaValidation('')
                            }
                        }}
                        height='10vh'
                        placeholder={props.placeholder}
                    />
                )}
            </InputWrapper>
        </View>
    )
}
