import React from 'react'

import { Card } from '@amzn/stencil-react-components/card'
import { Col, Flex, Spacer } from '@amzn/stencil-react-components/layout'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { useEntity } from 'src/hooks/useEntity'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { END_OF_MODULE_FOCUS_ID } from 'src/pages/module-builder/focus'
import { ActivityDropDown } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { MODULE_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ModuleEntityService'

interface EndOfModuleCardProps {
    moduleEntityId: string
    editDisabled?: boolean
}

const EndOfModuleCardToExport = React.memo((props: EndOfModuleCardProps) => {
    const { matches } = useBreakpoints()

    const { entity: workflowEntity } = useEntity<ModuleEntity>({
        entityId: props.moduleEntityId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    const title = 'End of Module'

    return (
        <>
            <Card>
                <Flex
                    dataTestId={'end-of-module-card'}
                    gridGap='16px'
                    justifyContent='space-between'
                    width='100%'
                    flexDirection={matches.s ? 'column' : 'row'}
                >
                    <Col flex={'1 1 0%'}>
                        <Text fontSize='T400'>
                            <h4>
                                <strong>{title}</strong>
                            </h4>
                        </Text>
                        <Text color='neutral70'>Add some more pages to get started.</Text>
                    </Col>
                    <Col>
                        <Spacer width={'S400'} />
                        <ActivityDropDown
                            id={END_OF_MODULE_FOCUS_ID}
                            dataTestId={'end-of-module-add-new-page-button'}
                            moduleEntityId={props.moduleEntityId}
                            pageIndex={workflowEntity.workflowIds.length}
                            editDisabled={props.editDisabled}
                        />
                    </Col>
                </Flex>
            </Card>
        </>
    )
})

EndOfModuleCardToExport.displayName = 'EndOfModuleCard'

export const EndOfModuleCard = EndOfModuleCardToExport
