import React, { useCallback, useRef, useState } from 'react'

import {
    Expander,
    ExpanderContent,
    ExpanderContentProps,
    ExpanderProps,
} from '@amzn/stencil-react-components/expander'

export interface SmartExpanderProps
    extends Omit<ExpanderProps, 'children' | 'isExpanded' | 'onToggle'> {
    children: () => JSX.Element
}

export function SmartExpander({ children, ...props }: SmartExpanderProps) {
    const body = useRef<JSX.Element | null>(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const onToggle = useCallback(
        (expanded: boolean) => {
            setIsExpanded(expanded)
            if (expanded && !body.current) {
                body.current = children()
            }
        },
        [body, children]
    )
    return (
        <Expander isExpanded={isExpanded} onToggle={onToggle} {...props}>
            {body.current}
        </Expander>
    )
}

export interface SmartExpanderContentProps extends Omit<ExpanderContentProps, 'children'> {
    children: () => JSX.Element
}

export function SmartExpanderContent({ children, ...props }: SmartExpanderContentProps) {
    const body = useRef<JSX.Element | null>(null)

    if (props.isExpanded) {
        body.current = children()
    }

    return <ExpanderContent {...props}>{body.current}</ExpanderContent>
}
