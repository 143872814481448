import { DependencyList, useReducer } from 'react'

import { useAsync } from '@amzn/stencil-react-components/hooks'

export interface ModuleViewerParams {
    moduleVersionId: string
    reviewId: string
    revisionNumber: string
}

export interface ModuleGroupViewerParams {
    moduleGroupVersionId: string
    reviewId: string
    revisionNumber: string
}

// TODO move
export function moduleViewerRoute({
    moduleVersionId = '',
    reviewId = '',
    revisionNumber = '',
}: Partial<ModuleViewerParams>) {
    if (!reviewId) {
        return `/module/viewer/${moduleVersionId}`
    }

    return `/module/viewer/${moduleVersionId}/review/${reviewId}/revisions/${revisionNumber || ''}`
}

export function moduleGroupViewerRoute({
    moduleGroupVersionId = '',
    reviewId = '',
    revisionNumber = '',
}: Partial<ModuleGroupViewerParams>) {
    if (!reviewId) {
        return `/module-groups/${moduleGroupVersionId}`
    }

    return `/module-groups/${moduleGroupVersionId}/review/${reviewId}/revisions/${
        revisionNumber || ''
    }`
}

/**
 * Like useAsync, except provides a callback to explicitly reload the data
 */
export function useAsyncWithReload<T>(func: () => Promise<T>, deps: DependencyList) {
    const [reloads, reload] = useReducer((x: number) => x + 1, 0)
    const ret = useAsync(func, [reloads, ...deps])
    return { reload, ...ret }
}
