export enum ModuleDeploymentTargetStage {
    PREVIEW = 'PREVIEW',
    UAT = 'UAT',
    PRODUCTION = 'PRODUCTION',
}

export enum ModuleDeploymentStatus {
    NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
    PUBLISHING = 'PUBLISHING',
    FAILED_TO_PUBLISH = 'FAILED_TO_PUBLISH',
    SUCCESSFULLY_PUBLISHED = 'SUCCESSFULLY_PUBLISHED',
    TIMED_OUT = 'TIMED_OUT',
}

export interface DeployModuleVersionDTO {
    stage: ModuleDeploymentTargetStage
    comment?: string
    retry?: boolean
}

export interface DeployModuleVersionResponse {
    versionId: string
    stage: ModuleDeploymentTargetStage
    deploymentStatus: ModuleDeploymentStatus
    message: string
}
