import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Checkbox, Input, InputWrapper, LabelPosition } from '@amzn/stencil-react-components/form'
import { IconCheck, IconCopy, IconMail } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { DropdownButton } from 'src/components/DropdownButton'
import { PreviewErrorModal, ValidationErrorModal } from 'src/components/ErrorModals'
import { ModuleDeploymentModalContent } from 'src/components/moduleDeployment/ModuleDeploymentModalContent'
import {
    constructChangeMap,
    constructChangeMessageList,
} from 'src/components/ModuleDifferenceUtils'
import { ModuleTranslationRequestModalContent } from 'src/components/ModuleTranslationRequestModalContent'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { Features, modulePreviewUrlForUAT } from 'src/config.app'
import { isFeatureEnabled } from 'src/helper/featureFlagHelper'
import { useInterval } from 'src/hooks/useInterval'
import { useModulePreview } from 'src/hooks/useModulePreview'
import { ModuleValidationErrorMessage, PreviewTTL } from 'src/hooks/usePreview'
import { CreateModuleReviewInput, ReviewType } from 'src/models/dto/approval/ReviewDTO'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ModuleDeploymentTargetStage } from 'src/models/dto/ModuleDeployment'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewStatusDTO, ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { ModuleGroupReviewForm } from 'src/pages/module-groups/ModuleGroupReviewForm'
import { moduleViewerRoute } from 'src/pages/module-review'
import { getApprovalRequired, ModuleReviewForm } from 'src/pages/module-review/ModuleReviewBox'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { DownloadTemplatesType, ModuleService } from 'src/services/backend/ModuleService'
import { ModuleEntityService } from 'src/services/EntityServices/ModuleEntityService'

const MODAL_TITLE_ID = 'stencil-modal-title'

const MODULE_NAME_CHARACTER_LIMIT = 47

enum ModuleOptionsModal {
    REQUEST_REVIEW = 'REQUEST_REVIEW',
    REQUEST_TRANSLATIONS = 'REQUEST_TRANSLATIONS',
    RUN_ERROR_CHECK = 'RUN_ERROR_CHECK',
    DUPLICATE_MODULE = 'DUPLICATE_MODULE',
    COPY_MODULE_IDS = 'MODULE_IDS',
    DEPLOY_MODULE_UAT = 'DEPLOY_MODULE_UAT',
    DEPLOY_MODULE_PRODUCTION = 'DEPLOY_MODULE_PRODUCTION',
    ARCHIVE_MODULE = 'ARCHIVE_MODULE',
    ADMIN_OVERRIDE = 'ADMIN_OVERRIDE',
}

export enum OptionValues {
    LAUNCH_PREVIEW = 'LAUNCH_PREVIEW',
    SHARE_PREVIEW = 'SHARE_PREVIEW',
    COPY_MODULE_ID = 'COPY_MODULE_ID',
    OPEN_REVIEW = 'OPEN_REVIEW',
    BACK_TO_VIEWER = 'BACK_TO_VIEWER',
    DUPLICATE_MODULE = 'DUPLICATE_MODULE',
    DEPLOY_UAT = 'DEPLOY_UAT',
    DEPLOY_PROD = 'DEPLOY_PROD',
    ERROR_CHECK = 'ERROR_CHECK',
    OPEN_UAT = 'OPEN_UAT',
    OPEN_EDITOR = 'OPEN_EDITOR',
    REQUEST_TRANSLATIONS = 'REQUEST_TRANSLATIONS',
    ARCHIVE_MODULE = 'ARCHIVE_MODULE',
    ADMIN_OVERRIDE = 'ADMIN_OVERRIDE',
    DOWNLOAD_CSV = 'DOWNLOAD_CSV',
    DOWNLOAD_JSON = 'DOWNLOAD_JSON',
}

export interface RenderModalProps {
    moduleEntity: ModuleEntity
    moduleStatus: ModuleStatus
    reviewStatus?: ModuleReviewStatusDTO | undefined
    close: () => void
    onPreviewValidationError?: (validationErrors: ModuleValidationErrorMessage[]) => void
    onPreviewUnexpectedError?: () => void
    onErrorCheck?: (hasValidationError: boolean) => void
    duplicateModule?: (newModuleId: string, afterSaveHook: () => void) => void
    validateModuleName?: (name: string, moduleVersionId: string) => Promise<boolean>
    editDisabled?: boolean
    onAdminOverride?: () => void
}

async function runErrorCheck(
    moduleEntity: ModuleEntity,
    includeScoring: boolean,
    includeTranslation: boolean,
    onErrorCheck?: (hasValidationError: boolean) => void
) {
    try {
        const dto = ModuleService.getFullModuleDTO(
            ModuleService.serializeModuleDTO(moduleEntity.version)
        )
        await ErrorCheckService.moduleVersionErrorCheck(
            dto,
            Date.now(),
            !includeScoring,
            !includeTranslation
        )
        onErrorCheck?.(false)
    } catch (e) {
        onErrorCheck?.(true)
    }
}

function RunErrorCheckModal({ close, moduleEntity, onErrorCheck }: RenderModalProps) {
    const [includeScoring, setIncludeScoring] = useState(true)
    const [includeTranslation, setIncludeTranslation] = useState(true)
    const [loading, setLoading] = useState(false)

    const runErrorCheckCallback = useCallback(async () => {
        setLoading(true)
        await runErrorCheck(moduleEntity, includeScoring, includeTranslation, onErrorCheck)
        setLoading(false)
        close()
    }, [includeScoring, includeTranslation, close, moduleEntity, onErrorCheck])

    return (
        <ModalContent
            key={'run-error-check'}
            titleText='Run error check'
            buttons={[
                <Button key='close-modal' dataTestId='close-modal' onClick={close}>
                    Cancel
                </Button>,
                loading ? (
                    <Spinner key='spinner' />
                ) : (
                    <Button
                        key='submit'
                        dataTestId='submit-run-error-check'
                        icon={<IconCheck title='' aria-hidden />}
                        variant={ButtonVariant.Primary}
                        onClick={runErrorCheckCallback}
                    >
                        Run error check
                    </Button>
                ),
            ]}
        >
            <Col gridGap='S400'>
                <Text>
                    All errors must be resolved before publishing modules, but you can resolve them
                    in advance.
                </Text>
                <Text>
                    <b>When checking required fields, also include...</b>
                </Text>
            </Col>
            <Spacer height='S400' />
            <ResponsiveRow gridGap='S200'>
                <Row alignItems='center' gridGap='S200'>
                    <Checkbox
                        id='modal-check-scoring-fields'
                        data-test-id={'modal-check-scoring-fields'}
                        defaultChecked={true}
                        onChange={(e) => setIncludeScoring(e.target.checked)}
                    />
                    <Label htmlFor='modal-check-scoring-fields'>Scoring fields</Label>
                </Row>
                <Row alignItems='center' gridGap='S200'>
                    <Checkbox
                        id='modal-missing-translation-strings'
                        data-test-id={'modal-missing-translation-strings'}
                        defaultChecked={true}
                        onChange={(e) => setIncludeTranslation(e.target.checked)}
                    />
                    <Label htmlFor='modal-missing-translation-strings'>
                        Missing translation strings
                    </Label>
                </Row>
            </ResponsiveRow>
        </ModalContent>
    )
}

function DuplicateModuleModal({
    close,
    moduleEntity,
    duplicateModule,
    validateModuleName,
    editDisabled: showWarning,
}: RenderModalProps) {
    const [newModuleName, setNewModuleName] = useState('')
    const [nameValidationError, setNameValidationError] = useState(false)
    const [nameValidationErrorMessage, setNameValidationErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const validationError = newModuleName.length < 1

    const submit = useCallback(async () => {
        if (validateModuleName) {
            if (newModuleName.length > MODULE_NAME_CHARACTER_LIMIT) {
                setNameValidationError(true)
                setNameValidationErrorMessage('The module name must be shorter than 47 characters')
                return
            }
            setLoading(true)
            const duplicateEntity = ModuleEntityService.duplicate(
                moduleEntity.version,
                newModuleName
            )
            const isNameValid = await validateModuleName(newModuleName, duplicateEntity.version)

            if (isNameValid) {
                if (duplicateModule) {
                    duplicateModule(duplicateEntity.version, () => {
                        setLoading(false)
                        close()
                    })
                }
            } else {
                setLoading(false)
                setNameValidationError(true)
                setNameValidationErrorMessage('This module name is already in use')
            }
        }
    }, [moduleEntity, duplicateModule, close, validateModuleName, newModuleName])

    return (
        <ModalContent
            titleText='Duplicate module'
            buttons={[
                <Button key='close-modal' dataTestId='close-modal' onClick={close}>
                    Cancel
                </Button>,
                loading ? (
                    <Spinner key='spinner' />
                ) : (
                    <Button
                        key='submit'
                        dataTestId='submit-duplicate-module'
                        variant={ButtonVariant.Primary}
                        onClick={submit}
                        disabled={validationError}
                    >
                        Yes, duplicate
                    </Button>
                ),
            ]}
        >
            <Col gridGap='S300'>
                {showWarning && (
                    <MessageBanner type={MessageBannerType.Informational}>
                        <Text fontSize={'T100'}>
                            Duplicated module will not have new translations applied
                        </Text>
                    </MessageBanner>
                )}
                <Text>
                    Do you want to create a copy of this module version? You must provide a new
                    unique name.
                    <br />
                </Text>
                <InputWrapper
                    id='duplicate-module-new-module-name'
                    labelText='Unique module name'
                    tooltipText='Once set, the module name cannot be changed or updated.'
                    required={true}
                    error={nameValidationError}
                    footer={nameValidationError ? nameValidationErrorMessage : undefined}
                >
                    {(props) => (
                        <Input
                            dataTestId='duplicate-module-new-module-name'
                            type='text'
                            value={newModuleName}
                            onChange={(e) => setNewModuleName(e.target.value)}
                            width='100%'
                            placeholder='Enter a module name'
                            {...props}
                        />
                    )}
                </InputWrapper>
            </Col>
        </ModalContent>
    )
}

function CopyModuleIdsModal({ close, moduleEntity: { id, version } }: RenderModalProps) {
    return (
        <ModalContent
            titleText='Copy Module IDs'
            buttons={[
                <Button
                    key='submit'
                    dataTestId='submit-copy-module-ids-modal'
                    onClick={close}
                    variant={ButtonVariant.Primary}
                >
                    Close
                </Button>,
            ]}
        >
            <Col width={'100%'}>
                <Spacer height={'S200'} />
                <Row>
                    <Text>
                        <strong>Module ID:</strong>
                    </Text>
                </Row>
                <Row alignItems={'center'} gridGap={'S200'}>
                    <Text dataTestId={'module-id'}>{id}</Text>
                    <Button
                        icon={<IconCopy />}
                        onClick={async () => await navigator.clipboard.writeText(id)}
                        variant={ButtonVariant.Tertiary}
                    />
                </Row>
                <Spacer height={'S200'} />
                <Row>
                    <Text>
                        <strong>Module Version ID:</strong>
                    </Text>
                </Row>
                <Row alignItems={'center'} gridGap={'S200'}>
                    <Text dataTestId={'module-version-id'}>{version}</Text>
                    <Button
                        icon={<IconCopy />}
                        onClick={async () => await navigator.clipboard.writeText(version)}
                        variant={ButtonVariant.Tertiary}
                    />
                </Row>
                <Spacer height={'S400'} />
            </Col>
        </ModalContent>
    )
}

function SharePreviewModal({
    close,
    previewUrl,
}: {
    close: () => void
    previewUrl: string | undefined
}) {
    const cancelBtn = useRef<HTMLButtonElement | null>(null)
    const [copied, setCopied] = useState(false)
    const buttonText = copied ? 'Copied' : 'Copy public URL'
    const copy = useCallback(() => {
        if (previewUrl) {
            void navigator.clipboard.writeText(previewUrl)
            setCopied(true)
        }
    }, [previewUrl])

    if (!previewUrl) {
        return null
    }

    return (
        <Modal close={close} isOpen={true}>
            <ModalContent
                titleText={'Share Module Instance'}
                buttons={[
                    <Button
                        key='close-modal'
                        dataTestId='close-modal'
                        onClick={() => close()}
                        ref={cancelBtn}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key='submit'
                        dataTestId='submit-copy-module-ids-modal'
                        onClick={copy}
                        icon={<IconCopy title='' aria-hidden />}
                        variant={ButtonVariant.Primary}
                    >
                        {buttonText}
                    </Button>,
                ]}
            >
                <Col gridGap='S400'>
                    <Text>Before sharing, please note:</Text>
                    <Text>
                        <ul style={{ listStyle: 'disc none inside' }}>
                            <li>
                                This link is only <b>valid for one day.</b>
                            </li>
                            <li>
                                This link is <b>public</b>. Do not share it with anyone who should
                                not use it.
                            </li>
                            <li>
                                If you progress through the module, you will not be able to return
                                to the start. Instead, a new link must be generated.
                            </li>
                        </ul>
                    </Text>
                    <Row
                        backgroundColor='neutral10'
                        gridGap='S300'
                        alignItems='center'
                        padding='S200'
                        justifyContent='space-between'
                    >
                        <Text dataTestId='preview-url' wordBreak='break-word' color='primary80'>
                            {previewUrl}
                        </Text>
                    </Row>
                </Col>
            </ModalContent>
        </Modal>
    )
}

function ArchiveModuleModal({
    close,
    moduleEntity,
    isArchived,
    onArchive,
}: {
    close: () => void
    moduleEntity: ModuleEntity
    isArchived: boolean
    onArchive?: (isArchived: boolean) => void
}) {
    const [latestLiveOrPublishedVersion, setLatestLiveOrPublishedVersion] = useState<string>('')
    const [getHistoryError, setGetHistoryError] = useState<boolean>(false)
    const [hasAckLiveVersion, setHasAckLiveVersion] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [archiveModuleError, setArchiveModuleError] = useState<string>('')

    useEffect(() => {
        const geLatestLiveOrPublished = async () => {
            try {
                setGetHistoryError(false)
                const latestVersion =
                    (await ModuleService.latestLiveOrPublishedVersion(moduleEntity.id)) ?? ''
                setLatestLiveOrPublishedVersion(latestVersion)
            } catch (e) {
                setGetHistoryError(true)
            }
        }
        if (!isArchived) {
            void geLatestLiveOrPublished()
        }
    }, [isArchived, moduleEntity.id])

    const submitArchival = async () => {
        setIsSubmitting(true)
        setArchiveModuleError('')
        const shouldAllowSubmit = latestLiveOrPublishedVersion ? hasAckLiveVersion : true
        if (shouldAllowSubmit) {
            try {
                setIsSubmitting(false)
                const result = await ModuleService.archiveModule(moduleEntity.version, !isArchived)
                if (onArchive) {
                    onArchive(result)
                }
                close()
            } catch (e: unknown) {
                setArchiveModuleError(
                    (e as Error).message ??
                        'Something went wrong while archiving the module, please try again'
                )
            }
        }
    }

    const informationalText = () => {
        if (isArchived) {
            return (
                <>
                    <Text>Before un-archiving, please note:</Text>
                    <Text>
                        <ul style={{ listStyle: 'disc none inside' }}>
                            <li>
                                This action will restore the module on search and My Module View
                            </li>
                            <li>
                                This action will not have any impact on modules that are already
                                published or synced to hook
                            </li>
                            <li>
                                This action will enable edits and the approval process on the module
                            </li>
                        </ul>
                    </Text>
                </>
            )
        } else {
            return (
                <>
                    <Text>Before archiving, please note:</Text>
                    <Text>
                        <ul style={{ listStyle: 'disc none inside' }}>
                            <li>
                                This action will not delete the module or any of versions of this
                                module
                            </li>
                            <li>
                                This action will not have any impact on modules that are already
                                published or synced to hook
                            </li>
                            <li>
                                This action will only hide the module from search and your module
                                view
                            </li>
                            <li>
                                All edits will be blocked on an archived module until it is
                                un-archived
                            </li>
                        </ul>
                    </Text>
                </>
            )
        }
    }

    return (
        <ModalContent
            titleText={
                isArchived
                    ? `Un-archive Module: ${moduleEntity.name}`
                    : `Archive Module: ${moduleEntity.name}`
            }
            buttons={[
                <Button
                    key={'close-archive-modal'}
                    dataTestId='close-archive-modal'
                    onClick={close}
                    variant={ButtonVariant.Tertiary}
                >
                    Cancel
                </Button>,
                <Button
                    key={'submit-archive-modal'}
                    dataTestId='submit-archival-action'
                    onClick={() => submitArchival()}
                    icon={<IconMail title='' aria-hidden />}
                    variant={ButtonVariant.Primary}
                    aria-disabled={getHistoryError}
                >
                    {isArchived ? 'Un-archive Module' : 'Archive Module'}
                </Button>,
            ]}
            dataTestId='archive-actions-modal'
        >
            <Col gridGap='S400'>
                {(getHistoryError || archiveModuleError) && (
                    <MessageBanner type={MessageBannerType.Error}>
                        {getHistoryError
                            ? 'An error occured while getting the module history, please try again later'
                            : archiveModuleError}
                    </MessageBanner>
                )}
                {informationalText()}
                {!isArchived && latestLiveOrPublishedVersion && (
                    <Col gridGap='S200' alignItems='center' padding='S200'>
                        <Text dataTestId='published-version-warning'>
                            This module has a{' '}
                            <RouterLink
                                to={moduleViewerRoute({
                                    moduleVersionId: latestLiveOrPublishedVersion,
                                })}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                published version
                            </RouterLink>
                            . Are you sure you want to archive?
                        </Text>
                        <InputWrapper
                            labelPosition={LabelPosition.Trailing}
                            labelText='I understand this module has a live or published version and still want to archive'
                            id='live-published-ack'
                            required
                            error={isSubmitting && !hasAckLiveVersion}
                        >
                            {(inputProps) => (
                                <Checkbox
                                    {...inputProps}
                                    dataTestId='confirm-archive-checkbox'
                                    name='live-publish-ack-checkbox'
                                    onChange={(e) => setHasAckLiveVersion(e.target.checked)}
                                    checked={hasAckLiveVersion}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                )}
            </Col>
        </ModalContent>
    )
}

function RequestReviewModal({ moduleEntity, moduleStatus, close }: RenderModalProps) {
    const [reviewInput, setReviewInput] = useState<CreateModuleReviewInput | null>(null)
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState('')
    const [reviewStatus] = useState(undefined)
    const [attemptsToGetStatus, setAttemptsToGetStatus] = useState<number>(1)
    const [validationError, setValidationError] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [reviewType, setReviewType] = useState<ReviewType>(ReviewType.PATCH)
    const [recommendedReviewType, setRecommendedReviewType] = useState<ReviewType>(ReviewType.PATCH)
    const [changeListLoading, setChangeListLoading] = useState(
        isFeatureEnabled(Features.FAST_TRACK_APPROVAL)
    )
    const [moduleChangeTextList, setModuleChangeTextList] = useState<string[]>([])
    const [diffApiError, setDiffApiError] = useState(false)
    const [firstTimeReview, setFirstTimeReview] = useState(false)

    const reviewRequired = reviewType === ReviewType.MAJOR || reviewType === ReviewType.MINOR

    const ONE_SECOND = 1000
    const REVIEW_CREATION_TIMEOUT = 60

    const navigate = useNavigate()

    useInterval(async () => {
        if (!reviewStatus && submitting && !error) {
            setAttemptsToGetStatus(attemptsToGetStatus + 1)
            if (attemptsToGetStatus > REVIEW_CREATION_TIMEOUT) {
                setError('Review creation timeout')
                return
            }

            const response: ModuleVersionDTO = await ModuleService.loadModuleVersionDTO(
                moduleEntity.version,
                true
            )
            const updatedReviewStatus = response.moduleReviewStatus
            if (updatedReviewStatus) {
                if (
                    isFeatureEnabled(Features.FAST_TRACK_APPROVAL) &&
                    updatedReviewStatus.status === 'APPROVED'
                ) {
                    await ModuleService.deployModuleVersion(response.versionId, {
                        comment: reviewInput?.description ?? '',
                        stage: ModuleDeploymentTargetStage.PRODUCTION,
                        retry: false,
                    })
                }

                setSubmitting(false)
                close()
                navigate(
                    moduleViewerRoute({
                        moduleVersionId: moduleEntity.version,
                        reviewId: updatedReviewStatus.reviewId,
                        revisionNumber: updatedReviewStatus.revisionNumber.toString(),
                    })
                )
            }
        }
    }, ONE_SECOND)

    const getModuleDifferences = useCallback(async () => {
        try {
            const latestApproved = await ModuleService.getLatestApprovedOrPublishedVersion(
                moduleEntity.id
            )

            if (!latestApproved) {
                setRecommendedReviewType(ReviewType.MAJOR)
                setReviewType(ReviewType.MAJOR)
                setFirstTimeReview(true)
                setChangeListLoading(false)
                return
            }

            const differences = await ModuleService.getModuleDifferences(
                moduleEntity.version,
                latestApproved
            )

            const recReviewType = differences.differenceType as unknown as ReviewType
            const changeMap = constructChangeMap(differences.moduleChangeList)
            const changeMessageList = constructChangeMessageList(changeMap)
            setModuleChangeTextList(changeMessageList)
            setRecommendedReviewType(recReviewType)
            setReviewType(recReviewType)
            setChangeListLoading(false)
        } catch (e) {
            setDiffApiError(true)
            setRecommendedReviewType(ReviewType.MAJOR)
            setReviewType(ReviewType.MAJOR)
            setChangeListLoading(false)
            console.error(e)
        }
    }, [moduleEntity.id, moduleEntity.version])

    useEffect(() => {
        if (changeListLoading) {
            void getModuleDifferences()
        }
    }, [changeListLoading, getModuleDifferences])

    const submit = useCallback(async () => {
        if (
            ModuleStatus[moduleStatus as unknown as keyof ModuleStatus] ===
                ModuleStatus.DRAFT_UNVALIDATED ||
            moduleStatus === ModuleStatus.DRAFT_UNVALIDATED
        ) {
            setValidationError('Cannot create a review when module is in unvalidated state.')
            return
        }

        if (!reviewInput || reviewInput.description === '') {
            setDescriptionError(true)
            return
        }

        if (reviewInput.reviewers.length < getApprovalRequired(reviewType)) {
            setValidationError('You must select minimum reviewers required.')
            return
        }

        setSubmitting(true)
        try {
            await ApprovalService.initiateApprovalProcess(reviewInput)
        } catch (e: unknown) {
            console.error('Error while requesting review: ', e)
            setSubmitting(false)
            setError(
                `Error while requesting module review: ${
                    (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
                }`
            )
        }
    }, [moduleStatus, reviewInput, reviewType])

    return (
        <ModalContent
            titleText='Request Review'
            buttons={[
                <Button
                    key={0}
                    dataTestId='request-review-cancel'
                    variant={ButtonVariant.Tertiary}
                    onClick={close}
                >
                    Cancel
                </Button>,
                <Button
                    key={1}
                    disabled={changeListLoading}
                    dataTestId='request-review-submit'
                    variant={ButtonVariant.Primary}
                    onClick={submit}
                >
                    {!isFeatureEnabled(Features.FAST_TRACK_APPROVAL) || reviewRequired
                        ? 'Request review'
                        : 'Deploy module'}
                </Button>,
            ]}
        >
            <Col
                backgroundColor='neutral0'
                gridGap={'S200'}
                maxWidth='100%'
                padding='S100'
                width={544}
            >
                {error && (
                    <MessageBanner
                        dataTestId={'review-submit-error'}
                        type={MessageBannerType.Error}
                    >
                        {error}
                    </MessageBanner>
                )}
                {validationError && (
                    <Flex flexDirection={'column'}>
                        <MessageBanner
                            dataTestId={'review-submit-error'}
                            type={MessageBannerType.Error}
                        >
                            {validationError}
                        </MessageBanner>
                    </Flex>
                )}
                {ModuleStatus[moduleStatus as unknown as keyof ModuleStatus] ===
                    ModuleStatus.DRAFT_UNVALIDATED && (
                    <Flex flexDirection={'column'}>
                        <MessageBanner
                            dataTestId={'review-submit-error'}
                            type={MessageBannerType.Warning}
                        >
                            You will not be able to request review for unvalidated draft.
                            <br />
                            Please make sure your changes are validated and saved before requesting
                            review.
                        </MessageBanner>
                    </Flex>
                )}
                {submitting && <Spinner />}
                {isFeatureEnabled(Features.FAST_TRACK_APPROVAL) ? (
                    <ModuleReviewForm
                        versionId={moduleEntity.version}
                        onCreateReviewInputChange={setReviewInput}
                        moduleName={moduleEntity.name}
                        descriptionError={descriptionError}
                        reviewType={reviewType}
                        recommendedReviewType={recommendedReviewType}
                        reviewRequired={reviewRequired}
                        changeListLoading={changeListLoading}
                        moduleChangeTextList={moduleChangeTextList}
                        diffApiError={diffApiError}
                        firstTimeReview={firstTimeReview}
                        setReviewType={setReviewType}
                        setDescriptionError={setDescriptionError}
                    />
                ) : (
                    <ModuleGroupReviewForm
                        versionId={moduleEntity.version}
                        onCreateReviewInputChange={setReviewInput}
                        moduleName={moduleEntity.name}
                        moduleReview={true}
                        descriptionError={descriptionError}
                        setDescriptionError={setDescriptionError}
                    />
                )}
            </Col>
        </ModalContent>
    )
}

const AdminOverrideModal = ({ close, onAdminOverride }: RenderModalProps) => {
    return (
        <ModalContent
            titleText='Admin Override'
            buttons={[
                <Button
                    key={'close'}
                    dataTestId='close-admin-override'
                    onClick={close}
                    variant={ButtonVariant.Secondary}
                >
                    Close
                </Button>,
                <Button
                    key={'submit'}
                    dataTestId='admit-override-button'
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                        if (onAdminOverride) {
                            onAdminOverride()
                        }
                        close()
                    }}
                >
                    Override
                </Button>,
            ]}
        >
            <Col width={'100%'}>
                <Spacer height={'S200'} />
                <Row>
                    <Text>
                        This override will allow you to fully edit the module again.
                        <Spacer height={'S200'} />
                        <strong>
                            Making any changes to this module and saving will create a new version.
                        </strong>
                        <Spacer height={'S200'} />
                        The translations <strong> will not</strong> be applied to the new version.
                    </Text>
                </Row>
            </Col>
        </ModalContent>
    )
}

export function ModuleOptions({
    moduleEntity,
    moduleStatus,
    reviewStatus,
    onPreviewUnexpectedError,
    onPreviewValidationError,
    onErrorCheck,
    duplicateModule,
    validateModuleName,
    optionsToInclude,
    editDisabled,
    onAdminOverride,
    onArchive,
}: {
    moduleEntity: ModuleEntity
    moduleStatus: ModuleStatus
    reviewStatus: ModuleReviewStatusDTO | undefined
    onPreviewValidationError?: (validationErrors: ModuleValidationErrorMessage[]) => void
    onPreviewUnexpectedError?: () => void
    onErrorCheck?: (hasValidationError: boolean) => void
    duplicateModule?: (versionId: string, afterSaveHook: () => void) => void
    validateModuleName?: (name: string, moduleVersionId: string) => Promise<boolean>
    optionsToInclude: Set<OptionValues>
    editDisabled?: boolean
    onAdminOverride?: () => void
    onArchive?: (isArchived: boolean) => void
}) {
    const [sharingPreview, setSharingPreview] = useState<boolean>(false)
    const [sharedPreviewUrl, setSharedPreviewUrl] = useState<string | undefined>()
    const [allowPopup, setAllowPopup] = useState<boolean>(true)

    const [previewErrorCount, setPreviewErrorCount] = useState<number | undefined>(undefined)
    const [validationErrorCount, setValidationErrorCount] = useState<number | undefined>(undefined)

    function openUATModule(moduleVersionId: string) {
        window.open(
            modulePreviewUrlForUAT + '/' + moduleVersionId,
            'UAT Module in Gamma',
            'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
        )
    }

    const isArchived = moduleStatus === ModuleStatus.ARCHIVED

    const { generatingPreview, previewValidationError, previewUrl, executePreview } =
        useModulePreview({
            previewTTL: PreviewTTL.THIRTY_MINUTES,
            moduleId: moduleEntity.version,
            onPreviewUnexpectedError: onPreviewUnexpectedError,
        })

    useEffect(() => {
        if (previewUrl) {
            if (sharingPreview) {
                setSharedPreviewUrl(previewUrl)
            } else {
                window.open(
                    previewUrl,
                    'preview',
                    'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
                )
            }
        }
    }, [previewUrl, sharingPreview])

    useEffect(() => {
        if (previewValidationError && allowPopup) {
            setPreviewErrorCount(previewValidationError.length)
            ;(async function () {
                await runErrorCheck(moduleEntity, true, true, onErrorCheck)
            })().catch(console.error)

            if (onPreviewValidationError) {
                onPreviewValidationError(previewValidationError)
            }
        }
    }, [previewValidationError, onPreviewValidationError, moduleEntity, onErrorCheck, allowPopup])

    const onRunErrorCheck = useCallback(
        (hasValidationError: boolean) => {
            if (hasValidationError) {
                // Wait for the first modal to be closed completely before showing the next one, so focus
                // can be handed back correctly
                const showNextModal = async () => {
                    const waitMs = 400
                    while (document.querySelector(`#${MODAL_TITLE_ID}`)) {
                        await new Promise((f) => setTimeout(f, waitMs))
                    }
                    setValidationErrorCount(
                        ErrorCheckService.getTotalModuleError(moduleEntity.version)
                    )
                }
                void showNextModal()
            }
            onErrorCheck?.(hasValidationError)
        },
        [moduleEntity, onErrorCheck]
    )

    const [openModal, setOpenModal] = useState<ModuleOptionsModal | null>(null)
    const navigate = useNavigate()
    const dropdownValues = useCallback(
        (open: () => void) => {
            const launchModulePreview = {
                name: 'Launch Module Preview',
                dataTestId: 'launch-module-preview',
                onClick: () => {
                    setSharingPreview(false)
                    setAllowPopup(true)
                    executePreview(PreviewTTL.THIRTY_MINUTES)
                },
            }

            const launchReview = {
                name: reviewStatus ? 'Open review' : 'Request review',
                dataTestId: 'request-review-option',
                disabled: isArchived,
                onClick: () => {
                    if (reviewStatus) {
                        navigate(
                            moduleViewerRoute({
                                moduleVersionId: moduleEntity.version,
                                reviewId: reviewStatus.reviewId,
                                revisionNumber: reviewStatus.revisionNumber.toString(),
                            })
                        )
                    } else {
                        setOpenModal(ModuleOptionsModal.REQUEST_REVIEW)
                        open()
                    }
                },
            }

            const backToViewer = {
                name: 'Back to Viewer',
                onClick: () => {
                    navigate(
                        moduleViewerRoute({
                            moduleVersionId: moduleEntity.version,
                        })
                    )
                },
            }

            const launchErrorCheck = {
                name: 'Run error check',
                dataTestId: 'run-error-check-option',
                disabled: !isArchived && editDisabled,
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.RUN_ERROR_CHECK)
                    open()
                },
            }

            const launchDuplicateModule = {
                name: 'Duplicate module',
                dataTestId: 'duplicate-module-option',
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.DUPLICATE_MODULE)
                    open()
                },
            }

            const launchShareModulePreview = {
                name: 'Share Module Preview',
                dataTestId: 'share-module-preview',
                onClick: () => {
                    setSharingPreview(true)
                    setAllowPopup(true)
                    executePreview(PreviewTTL.ONE_DAY)
                },
            }

            const launchCopyModule = {
                name: 'Copy module IDs',
                dataTestId: 'copy-module-ids',
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.COPY_MODULE_IDS)
                    open()
                },
            }

            const launchDeployUAT = {
                name: 'Deploy module for UAT',
                dataTestId: 'deploy-modules-uat',
                disabled: isArchived,
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.DEPLOY_MODULE_UAT)
                    open()
                },
            }

            const launchRequestTranslations = {
                name: 'Request translations',
                disabled: isArchived,
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.REQUEST_TRANSLATIONS)
                    open()
                },
            }

            const launchDeployProd = {
                name: 'Deploy module for Production',
                dataTestId: 'deploy-modules-production',
                disabled: isArchived,
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.DEPLOY_MODULE_PRODUCTION)
                    open()
                },
            }

            const launchOpenUAT = {
                name: 'Open Module UAT',
                disabled: isArchived,
                onClick: () => {
                    openUATModule(moduleEntity.version)
                },
            }

            const launchOpenEditor = {
                name: 'Open module editor',
                dataTestId: 'open-module-editor-option',
                onClick: () => {
                    navigate(`/module-builder?moduleVersionId=${moduleEntity.version}`)
                },
            }

            const launchModuleArchival = {
                name: isArchived ? 'Un-archive Module' : 'Archive Module',
                dataTestId: 'archive-actions-option',
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.ARCHIVE_MODULE)
                    open()
                },
            }

            const launchModuleOverride = {
                name: 'Admin override',
                onClick: () => {
                    setOpenModal(ModuleOptionsModal.ADMIN_OVERRIDE)
                    open()
                },
            }

            const downloadTemplates = async (type: DownloadTemplatesType) => {
                const moduleVersionId = moduleEntity.version
                const data: Blob = await ModuleService.downloadTemplates(moduleVersionId, type)
                const element = document.createElement('a')
                element.href = URL.createObjectURL(data)
                element.download = `${type.toUpperCase()} Templates - ${moduleVersionId}.zip`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            }

            const launchDownloadJSON = {
                name: 'Download JSON',
                dataTestId: 'export-module-json',
                onClick: () => downloadTemplates(DownloadTemplatesType.JSON),
            }

            const launchDownloadCSV = {
                name: 'Download CSV',
                dataTestId: 'export-module-csv',
                onClick: () => downloadTemplates(DownloadTemplatesType.CSV),
            }

            const values: { name: string; onClick: () => void; dataTestId?: string }[] = []

            // As options to include is a set, by adding to values one by one can ensure consistent order in the
            // drop down regardless of which page you look at the module options from (i.e. viewer, builder, review page)
            if (optionsToInclude.has(OptionValues.LAUNCH_PREVIEW)) {
                values.push(launchModulePreview)
            }

            if (optionsToInclude.has(OptionValues.OPEN_REVIEW)) {
                values.push(launchReview)
            }

            if (optionsToInclude.has(OptionValues.OPEN_EDITOR)) {
                values.push(launchOpenEditor)
            }

            if (optionsToInclude.has(OptionValues.BACK_TO_VIEWER)) {
                values.push(backToViewer)
            }

            if (optionsToInclude.has(OptionValues.ERROR_CHECK)) {
                values.push(launchErrorCheck)
            }

            if (optionsToInclude.has(OptionValues.DUPLICATE_MODULE)) {
                values.push(launchDuplicateModule)
            }

            if (optionsToInclude.has(OptionValues.SHARE_PREVIEW)) {
                values.push(launchShareModulePreview)
            }

            if (optionsToInclude.has(OptionValues.COPY_MODULE_ID)) {
                values.push(launchCopyModule)
            }

            if (optionsToInclude.has(OptionValues.DEPLOY_UAT)) {
                values.push(launchDeployUAT)
            }

            if (optionsToInclude.has(OptionValues.REQUEST_TRANSLATIONS)) {
                values.push(launchRequestTranslations)
            }

            if (optionsToInclude.has(OptionValues.DEPLOY_PROD)) {
                values.push(launchDeployProd)
            }

            if (optionsToInclude.has(OptionValues.OPEN_UAT)) {
                values.push(launchOpenUAT)
            }

            if (optionsToInclude.has(OptionValues.ARCHIVE_MODULE)) {
                values.push(launchModuleArchival)
            }

            if (optionsToInclude.has(OptionValues.ADMIN_OVERRIDE)) {
                values.push(launchModuleOverride)
            }

            if (moduleEntity.status != ModuleStatus.DRAFT_UNVALIDATED) {
                if (optionsToInclude.has(OptionValues.DOWNLOAD_CSV)) {
                    values.push(launchDownloadCSV)
                }

                if (optionsToInclude.has(OptionValues.DOWNLOAD_JSON)) {
                    values.push(launchDownloadJSON)
                }
            }

            return values
        },
        [
            reviewStatus,
            isArchived,
            editDisabled,
            optionsToInclude,
            moduleEntity.status,
            moduleEntity.version,
            executePreview,
            navigate,
        ]
    )

    const renderModals: Record<ModuleOptionsModal, React.FC<RenderModalProps>> = useMemo(
        () => ({
            [ModuleOptionsModal.REQUEST_REVIEW]: (props) => <RequestReviewModal {...props} />,
            [ModuleOptionsModal.RUN_ERROR_CHECK]: (props) => (
                <RunErrorCheckModal {...props} onErrorCheck={onRunErrorCheck} />
            ),
            [ModuleOptionsModal.DUPLICATE_MODULE]: (props) => (
                <DuplicateModuleModal
                    {...props}
                    duplicateModule={duplicateModule}
                    validateModuleName={validateModuleName}
                />
            ),
            [ModuleOptionsModal.COPY_MODULE_IDS]: (props) => <CopyModuleIdsModal {...props} />,
            [ModuleOptionsModal.DEPLOY_MODULE_UAT]: (props) => (
                <ModuleDeploymentModalContent
                    moduleVersionId={props.moduleEntity.version}
                    targetStage={ModuleDeploymentTargetStage.UAT}
                    close={props.close}
                />
            ),
            [ModuleOptionsModal.DEPLOY_MODULE_PRODUCTION]: (props) => (
                <ModuleDeploymentModalContent
                    moduleVersionId={props.moduleEntity.version}
                    targetStage={ModuleDeploymentTargetStage.PRODUCTION}
                    close={props.close}
                />
            ),
            [ModuleOptionsModal.REQUEST_TRANSLATIONS]: (props) => (
                <ModuleTranslationRequestModalContent
                    {...props}
                    versionId={props.moduleEntity.version}
                    moduleName={props.moduleEntity.name}
                />
            ),
            [ModuleOptionsModal.ARCHIVE_MODULE]: (props) => (
                <ArchiveModuleModal {...props} onArchive={onArchive} isArchived={isArchived} />
            ),
            [ModuleOptionsModal.ADMIN_OVERRIDE]: (props) => (
                <AdminOverrideModal {...props} onAdminOverride={onAdminOverride} />
            ),
        }),
        [
            onRunErrorCheck,
            duplicateModule,
            validateModuleName,
            onArchive,
            isArchived,
            onAdminOverride,
        ]
    )

    const renderModal = useCallback(
        ({ close }: { close: () => void }) => {
            return openModal ? (
                renderModals[openModal]({
                    close,
                    moduleEntity,
                    moduleStatus,
                    editDisabled,
                    reviewStatus,
                    onErrorCheck,
                })
            ) : (
                <></>
            )
        },
        [
            moduleEntity,
            moduleStatus,
            editDisabled,
            reviewStatus,
            openModal,
            renderModals,
            onErrorCheck,
        ]
    )

    if (generatingPreview) {
        return <Spinner dataTestId='preview-spinner' />
    }

    return (
        <>
            <SharePreviewModal
                close={() => setSharedPreviewUrl(undefined)}
                previewUrl={sharedPreviewUrl}
            />
            <PreviewErrorModal
                title={'Preview module'}
                close={() => {
                    setPreviewErrorCount(undefined)
                    setAllowPopup(false)
                }}
                errorCount={previewErrorCount}
            />
            <ValidationErrorModal
                title={'Run error check'}
                close={() => setValidationErrorCount(undefined)}
                errorCount={validationErrorCount}
            />
            <WithModal renderModal={renderModal}>
                {({ open }) => (
                    <DropdownButton
                        title='Options'
                        values={dropdownValues(open)}
                        dataTestId={'module-options'}
                    />
                )}
            </WithModal>
        </>
    )
}
