import { LocalizedAttribute } from '../Locale'
import { ItemDTO, ItemType } from './ItemDTO'

export enum RankingCellType {
    Text = 'Text',
    HarveyBall = 'HarveyBall',
    Arrow = 'Arrow',
}

export enum RankingCellSubType {
    Text = 'Text',
    HarveyBallEmpty = 'EmptyBall',
    HarveyBallHalfFull = 'HalfFullBall',
    HarveyBallFull = 'FullBall',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
}

export interface RankingColumn {
    headerI18N: LocalizedAttribute<string>
}

export interface RankingRatingScale {
    type: RankingCellType
    subType: RankingCellSubType
    valueI18N: LocalizedAttribute<string>
}

export interface RankingCellValue {
    type: RankingCellType
    subType: RankingCellSubType
    valueI18N: LocalizedAttribute<string>
}

export interface RankingResponseCell {
    values: RankingCellValue[]
}

export interface RankingResponseScore {
    score: number
}

export interface RankingResponse {
    responseCells: RankingResponseCell[]
    responseScores: RankingResponseScore[]
    responseLabel: string
}

export interface RankingResponseOrder {
    responseOrder: string[]
}

export interface ExcludedRankingResponseOrders {
    responseOrders: RankingResponseOrder[]
}

export interface RankingTable {
    columns: RankingColumn[]
    ratingScales: RankingRatingScale[]
    responseRows: RankingResponse[]
}

export interface RankingResponseTableItemDTO extends ItemDTO {
    itemType: ItemType.RankingResponseTable
    statementI18N: LocalizedAttribute<string>
    rankingTable: RankingTable
    responseOrderRandomizationEnabled: boolean
    excludedRankingResponseOrders?: ExcludedRankingResponseOrders
}
