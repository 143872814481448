import React, { useCallback, useMemo, useReducer } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { DetailedRadio, Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { WithModal } from '@amzn/stencil-react-components/modal'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { H3, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { SmartExpanderContent } from 'src/components/SmartExpander'
import { useAdaptiveEngineEntity } from 'src/hooks/useAdaptiveEngineEntity'
import { ItemPoolDistributionDTO } from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import {
    AdaptiveEngineCSVImporter,
    rowsToCSV,
    useRenderCSVImporter,
} from 'src/pages/module-builder/adaptive-engine/AdaptiveEngineCSVImporter'
import { AdaptiveEngineItemPoolDistributionEditor } from 'src/pages/module-builder/adaptive-engine/AdaptiveEngineItemPoolDistributionEditor'
import { activityDisplayNameMap } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { PageEditorProps } from 'src/pages/module-builder/page-editor/PageEditor'
import { PageHeader } from 'src/pages/module-builder/page-editor/PageHeader'
import { downloadAsFile } from 'src/services/downloadAsFile'
import {
    AdaptiveEngineHandler,
    TerminationCriteriaMode,
} from 'src/services/EntityServices/ActivityUpdateHandlers/AdaptiveEngineHandler'

export type AdaptiveEngineEditorProps = PageEditorProps

export function TerminationCriteriaEditor(props: {
    workflowEntityId: string
    moduleEntityId: string
    scoredItemPool?: ItemPoolDistributionDTO | null
}) {
    const { workflowEntityId, moduleEntityId, scoredItemPool } = props
    const idPrefix = workflowEntityId
    const { entity: adaptiveEngine } = useAdaptiveEngineEntity({
        workflowEntityId,
        moduleEntityId,
    })
    const terminationCriteriaMode = AdaptiveEngineHandler.getTerminationCriteriaMode(adaptiveEngine)
    return (
        <Col>
            <fieldset>
                <legend>
                    <Text fontSize='T200'>Terminating criteria</Text>
                </legend>
                <Spacer height='S300' />
                <Row gridGap='S300'>
                    <DetailedRadio
                        id={`${idPrefix}-termination-criteria-mode-max-length`}
                        dataTestId='termination-criteria-mode-max-length'
                        details='The assessment will terminate when the specified number of items is reached.'
                        name={`${idPrefix}-terminationCriteriaMode`}
                        titleText='Minimum number of items completed'
                        value={TerminationCriteriaMode.MaxLength}
                        checked={terminationCriteriaMode === TerminationCriteriaMode.MaxLength}
                        onChange={(e) => {
                            if (e.target.checked) {
                                AdaptiveEngineHandler.setTerminationCriteriaMode(
                                    workflowEntityId,
                                    TerminationCriteriaMode.MaxLength
                                )
                            }
                        }}
                    />
                    <DetailedRadio
                        id={`${idPrefix}-termination-criteria-mode-standard-error-threshold`}
                        dataTestId='termination-criteria-mode-standard-error-threshold'
                        details='The assessment will terminate if the maximum number of items is reached even if the standard error score is not met.'
                        name={`${idPrefix}-terminationCriteriaMode`}
                        titleText='Standard error'
                        value={TerminationCriteriaMode.StandardErrorThreshold}
                        checked={
                            terminationCriteriaMode ===
                            TerminationCriteriaMode.StandardErrorThreshold
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                AdaptiveEngineHandler.setTerminationCriteriaMode(
                                    workflowEntityId,
                                    TerminationCriteriaMode.StandardErrorThreshold
                                )
                            }
                        }}
                    />
                </Row>
            </fieldset>
            {terminationCriteriaMode === TerminationCriteriaMode.MaxLength && (
                <ResponsiveRow gridGap='S300'>
                    <InputWrapper
                        id={`${idPrefix}-termination-criteria-min-items-administered`}
                        labelText='Minimum items administered'
                        footer='Based on item pool reference. Cannot be smaller'
                    >
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                dataTestId='termination-criteria-min-items-administered'
                                type='number'
                                min={scoredItemPool?.minItems ?? 0}
                                step={1}
                                value={
                                    adaptiveEngine.examTerminationCriteria.maxLength ??
                                    scoredItemPool?.minItems ??
                                    0
                                }
                                onChange={(e) =>
                                    AdaptiveEngineHandler.setMaxLength(
                                        workflowEntityId,
                                        parseInt(e.target.value, 10) ?? 0
                                    )
                                }
                                placeholder={'Select an item pool reference first'}
                            />
                        )}
                    </InputWrapper>
                </ResponsiveRow>
            )}
            {terminationCriteriaMode === TerminationCriteriaMode.StandardErrorThreshold && (
                <ResponsiveRow gridGap='S300'>
                    <InputWrapper
                        id={`${idPrefix}-termination-criteria-standard-error-threshold`}
                        labelText='Standard error score'
                    >
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                dataTestId='termination-criteria-standard-error-threshold'
                                type='number'
                                value={
                                    adaptiveEngine.examTerminationCriteria.standardErrorThresh ?? 0
                                }
                                placeholder='Enter a value (up to TK decimals)'
                                min={0}
                                step={0.001}
                                onChange={(e) => {
                                    AdaptiveEngineHandler.setStandardErrorThreshold(
                                        workflowEntityId,
                                        parseFloat(e.target.value) ?? 0
                                    )
                                }}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper
                        id={`${idPrefix}-termination-criteria-max-number-of-items-allowed`}
                        labelText='Maximum number of items allowed'
                    >
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                dataTestId='termination-criteria-max-number-of-items-allowed'
                                type='number'
                                value={adaptiveEngine.examTerminationCriteria.maxLength ?? 0}
                                placeholder='Enter a value'
                                min={0}
                                step={1}
                                onChange={(e) => {
                                    AdaptiveEngineHandler.setMaxLength(
                                        workflowEntityId,
                                        parseInt(e.target.value, 10) ?? 0
                                    )
                                }}
                            />
                        )}
                    </InputWrapper>
                </ResponsiveRow>
            )}
        </Col>
    )
}

export function AdaptiveEngineParametersEditor({
    moduleEntityId,
    workflowEntityId,
}: {
    moduleEntityId: string
    workflowEntityId: string
}) {
    const { entity: adaptiveEngine } = useAdaptiveEngineEntity({
        workflowEntityId,
        moduleEntityId,
    })
    const { experimentalItemPoolID: experimentalItemPoolId } = adaptiveEngine

    const scoredItemPoolPart = useMemo(
        () =>
            adaptiveEngine.itemPoolDistributions.map((itemPoolDistribution, index) => (
                <fieldset key={index} data-test-id={`item-pool-distribution-${index}`}>
                    <legend>
                        <ScreenReaderOnly>{`Item pool distribution ${index + 1}`}</ScreenReaderOnly>
                    </legend>
                    <AdaptiveEngineItemPoolDistributionEditor
                        itemPoolDistribution={itemPoolDistribution}
                        setItemPoolVersionId={(value) =>
                            AdaptiveEngineHandler.setItemPoolId(workflowEntityId, index, value)
                        }
                        setMinItems={(minItems: number) =>
                            AdaptiveEngineHandler.setItemPoolDistributionMinItems(
                                workflowEntityId,
                                index,
                                minItems
                            )
                        }
                        setPercentage={(percentage: number) => {
                            AdaptiveEngineHandler.setItemPoolDistributionPercentage(
                                workflowEntityId,
                                index,
                                percentage
                            )
                        }}
                        removeItemPoolDistribution={() =>
                            AdaptiveEngineHandler.removeItemPool(workflowEntityId, index)
                        }
                        isScored={true}
                        idPrefix={`item-pool-distribution-${workflowEntityId}-${index}`}
                    />
                </fieldset>
            )),
        [adaptiveEngine.itemPoolDistributions, workflowEntityId]
    )
    return (
        <Col gridGap='S500' padding='S300'>
            <Col gridGap='S500'>
                <H3 fontSize='T400'>Scored item pool</H3>
                {scoredItemPoolPart}
                <div>
                    <Button
                        id={`item-pool-distribution-${workflowEntityId}-add-scored-item-pool`}
                        dataTestId='add-scored-item-pool'
                        variant={ButtonVariant.Secondary}
                        icon={<IconPlus aria-hidden />}
                        onClick={() => AdaptiveEngineHandler.addItemPool(workflowEntityId)}
                    >
                        Add item pool
                    </Button>
                </div>
                <Hr />
            </Col>
            <Col gridGap='S500' dataTestId='unscored-item-pool'>
                <H3 fontSize='T400'>Unscored item pool</H3>
                <AdaptiveEngineItemPoolDistributionEditor
                    itemPoolDistribution={useMemo<ItemPoolDistributionDTO>(
                        () => ({
                            itemPoolId: experimentalItemPoolId,
                            minItems: 0,
                            percentage: 0,
                        }),
                        [experimentalItemPoolId]
                    )}
                    setItemPoolVersionId={useCallback(
                        (value: string) =>
                            AdaptiveEngineHandler.setExperimentalItemPoolId(
                                workflowEntityId,
                                value
                            ),
                        [workflowEntityId]
                    )}
                    isScored={false}
                    idPrefix={`item-pool-distribution-unscored-${workflowEntityId}`}
                />
                <Hr />
            </Col>
            <TerminationCriteriaEditor
                workflowEntityId={workflowEntityId}
                moduleEntityId={moduleEntityId}
                scoredItemPool={adaptiveEngine.itemPoolDistributions[0] ?? undefined}
            />
        </Col>
    )
}

const getReuploadOption = (open: () => void) => ({
    name: 'Reupload CSV',
    dataTestId: 'adaptive-engine-reupload-csv',
    onClick: open,
})

export function AdaptiveEngineEditor(props: AdaptiveEngineEditorProps) {
    const entityId = useMemo(
        () => ({
            moduleEntityId: props.moduleEntityId,
            workflowEntityId: props.workflowEntityId,
        }),
        [props.moduleEntityId, props.workflowEntityId]
    )
    const { entity: adaptiveEngine } = useAdaptiveEngineEntity(entityId)
    const [isExpanded, toggleIsExpanded] = useReducer(
        (x: boolean) => !x,
        props.defaultExpanded ?? false
    )

    const renderEditor = useCallback(
        () => <AdaptiveEngineParametersEditor {...entityId} />,
        [entityId]
    )
    const isEmpty = (adaptiveEngine.adaptiveEngineItemParameters.length ?? 0) === 0
    const downloadOption = useMemo(
        () => ({
            name: 'Download CSV',
            dataTestId: 'adaptive-engine-download-csv',
            onClick: () => {
                downloadAsFile(
                    rowsToCSV(adaptiveEngine.adaptiveEngineItemParameters),
                    `AdaptiveEngine_${AdaptiveEngineHandler.inferItemPoolId(adaptiveEngine)}.csv`
                )
            },
        }),
        [adaptiveEngine]
    )
    return (
        <Col gridGap='S300' dataTestId={`page-editor-${props.pageIndex}`}>
            <Spacer height='S300' />
            <Col gridGap='S300' padding='S400' backgroundColor='white'>
                <WithModal renderModal={useRenderCSVImporter(entityId.workflowEntityId)}>
                    {({ open }) => (
                        <PageHeader
                            {...props}
                            isExpanded={isExpanded}
                            onclick={toggleIsExpanded}
                            activityName={activityDisplayNameMap.get(adaptiveEngine.type)}
                            extraPageOptions={
                                isEmpty ? [] : [downloadOption, getReuploadOption(open)]
                            }
                        />
                    )}
                </WithModal>

                {isEmpty ? (
                    <Row justifyContent='center'>
                        <AdaptiveEngineCSVImporter {...entityId} />
                    </Row>
                ) : (
                    <SmartExpanderContent isExpanded={isExpanded}>
                        {renderEditor}
                    </SmartExpanderContent>
                )}
            </Col>
        </Col>
    )
}
