import React from 'react'

import { CommentDTO, LocationId } from 'src/models/dto/approval/CommentDTO'

export interface ModuleReviewContextProps {
    reloadComments?: () => Promise<void>
    reviewId?: string
    revisionNumber?: string
    comments?: CommentDTO[] | null
    commentsByLocation: Record<LocationId, CommentDTO[]>
    isCommentsLoading: boolean
}

export const ModuleReviewContext = React.createContext<ModuleReviewContextProps>({
    comments: [],
    isCommentsLoading: false,
    commentsByLocation: {},
})
