import React, { useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper, TextArea } from '@amzn/stencil-react-components/form'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { ModalContent } from '@amzn/stencil-react-components/modal'

import { ModuleGroupDeploymentProgress } from 'src/components/module-groups/ModuleGroupDeploymentProgress'
import { ModuleDeploymentTargetStage } from 'src/models/dto/ModuleDeployment'
import { Authenticator } from 'src/services/Authenticator'

export interface ModuleGroupDeploymentModalContentProps {
    moduleGroupVersionId: string
    targetStage: ModuleDeploymentTargetStage
    close: () => void
}

export const ModuleGroupMapTargetStageToTitle: Record<ModuleDeploymentTargetStage, string> = {
    PREVIEW: 'Deploy Module Group for Preview',
    UAT: 'Deploy Module Group for UAT',
    PRODUCTION: 'Deploy Module Group for Production',
}

export function ModuleGroupDeploymentModalContent(props: ModuleGroupDeploymentModalContentProps) {
    const [comments, setComments] = useState<string>('')
    const [isInProgress, setIsInProgress] = useState<boolean>(false)
    const [successfullyDeployed, setIsSuccessfullyDeployed] = useState<boolean>(false)

    const author = Authenticator.getDefaultUser()

    const startPublishing = () => {
        setIsInProgress(true)
    }

    if (successfullyDeployed) {
        window.location.reload()
    }

    return (
        <ModalContent titleText={ModuleGroupMapTargetStageToTitle[props.targetStage]}>
            <View backgroundColor='neutral0' minWidth='450px'>
                <Col gridGap='S300'>
                    <InputWrapper id='publish-author' labelText='Author'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                value={author}
                                disabled={true}
                                data-test-id='push-module-author'
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper id='publish-comments' labelText='Comments'>
                        {(inputProps) => (
                            <TextArea
                                {...inputProps}
                                value={comments}
                                minHeight='150px'
                                disabled={isInProgress}
                                data-test-id='push-module-group-comments'
                                onChange={(e) => setComments(e.target.value)}
                            />
                        )}
                    </InputWrapper>
                    <ModuleGroupDeploymentProgress
                        moduleGroupVersionId={props.moduleGroupVersionId}
                        isInProgress={isInProgress}
                        targetStage={props.targetStage}
                        author={author}
                        comments={comments}
                        setDeployedStatus={setIsSuccessfullyDeployed}
                    />
                    <Button
                        variant={ButtonVariant.Primary}
                        data-test-id='push-module-button-on-modal'
                        onClick={startPublishing}
                        disabled={isInProgress}
                    >
                        Publish
                    </Button>
                    <Button data-test-id='push-module-close-button' onClick={props.close}>
                        Cancel
                    </Button>
                </Col>
            </View>
        </ModalContent>
    )
}
