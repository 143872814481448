import { applyChange, Change } from 'src/hooks/DTOEditor'
import { BaseTableDTO } from 'src/models/dto/items/BaseTableDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import {
    defaultMultipleChoiceItemDTO,
    MultipleChoiceDisplayType,
    MultipleChoiceItemDTO,
    MultipleChoiceResponseDTO,
    MultipleChoiceType,
} from 'src/models/dto/items/MultipleChoiceItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'
import { Store, STORE_ACTION, Stores } from 'src/services/Store'

const itemType = ItemType.MultipleChoice

export class MultipleChoiceHandler extends TableOperations<BaseTableDTO> {
    public static readonly INSTANCE = new MultipleChoiceHandler()

    private constructor() {
        super()
    }

    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => defaultMultipleChoiceItemDTO())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    public getEntity(id: string): MultipleChoiceItemDTO {
        if (MultipleChoiceHandler.store().has(id)) {
            return MultipleChoiceHandler.store().get(id) as MultipleChoiceItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    private update(entity: MultipleChoiceItemDTO) {
        MultipleChoiceHandler.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    public updateLabel(id: string, label: string) {
        const entity = this.getEntity(id)
        entity.label = label
        this.update(entity)
    }

    public updateOptional(id: string, optional: boolean) {
        const entity = this.getEntity(id)
        entity.optional = optional
        this.update(entity)
    }

    public updatePreserveOrder(id: string, nextValue: boolean) {
        const entity = this.getEntity(id)
        entity.preserveOrder = nextValue
        this.update(entity)
    }

    public updateQuestion(id: string, locale: Locale, question: string) {
        const entity = this.getEntity(id)
        entity.questionI18N = { ...(entity.questionI18N ?? {}), [locale]: question }
        this.update(entity)
    }

    public updateMultipleChoiceType(id: string, multipleChoiceType: MultipleChoiceType) {
        const entity = this.getEntity(id)
        entity.multipleChoiceType = multipleChoiceType
        this.checkAndFixNumRequiredResponses(entity)
        this.update(entity)
    }

    public updateDisplayType(id: string, displayType: MultipleChoiceDisplayType) {
        if (!MultipleChoiceHandler.showFreeTextResponse(displayType)) {
            this.updateFreeTextCheckbox(id, false)
        }

        if (displayType === MultipleChoiceDisplayType.Image) {
            this.removeExtraResponses(id)
        }

        this.removeResponseImages(id)

        if (displayType === MultipleChoiceDisplayType.TABLE) {
            this.setNumberOfRows(id, this.getEntity(id).responses.length)
            this.setNumberOfColumns(id, 4)
            this.update({
                ...this.getEntity(id),
                displayType,
            })
        } else {
            this.update({
                ...this.getEntity(id),
                displayType,
                tableResponses: {
                    legends: [],
                    rows: [],
                    headers: [],
                    harveyBallLegendEnabled: false,
                    symbolLegendEnabled: false,
                },
            })
        }
    }

    public updateNumRequiredResponses(
        id: string,
        numRequiredResponses?: number | null | undefined
    ) {
        const entity = this.getEntity(id)
        entity.numRequiredResponses = numRequiredResponses ?? undefined
        this.checkAndFixNumRequiredResponses(entity)
        this.update(entity)
    }

    public addResponse(id: string) {
        const entity = this.getEntity(id)
        entity.responses.push({
            responseI18N: LocalizeDefault(''),
            score: null,
            responseLabel: '',
        })
        this.update(entity)
    }

    public checkArrayBounds<T>(index: number, arr: T[]) {
        if (arr && (index < 0 || index >= arr.length)) {
            throw new Error(`Index out of bounds: Index: ${index}, Size: ${arr.length}`)
        }
    }

    public checkAndFixNumRequiredResponses(entity: MultipleChoiceItemDTO) {
        if (entity.multipleChoiceType === MultipleChoiceType.MultiSelect) {
            if (typeof entity.numRequiredResponses === 'number') {
                if (entity.numRequiredResponses > entity.responses.length) {
                    entity.numRequiredResponses = entity.responses.length
                } else if (entity.numRequiredResponses < 1) {
                    entity.numRequiredResponses = undefined
                }
            }
        } else if (entity.multipleChoiceType === MultipleChoiceType.SingleSelect) {
            entity.numRequiredResponses = undefined
        }
    }

    public deleteResponse(id: string, index: number) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.responses)
        entity.responses.splice(index, 1)
        this.checkAndFixNumRequiredResponses(entity)
        this.update(entity)
    }

    public updateResponseOption(id: string, index: number, locale: Locale, response: string) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.responses)
        entity.responses[index].responseI18N[locale] = response
        this.update(entity)
    }

    public updateResponseLabel(id: string, index: number, response: string) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.responses)
        entity.responses[index].responseLabel = response
        this.update(entity)
    }

    public updateScore(id: string, index: number, score?: number | null) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.responses)
        entity.responses[index].score = score
        this.update(entity)
    }

    public updateResponseLocaleWiseMediaOpt(
        id: string,
        change: Change<Medias | undefined>,
        index: number
    ) {
        const entity = this.getEntity(id)
        const { localeWiseMedia } = entity.responses[index]
        entity.responses[index].localeWiseMedia = applyChange(localeWiseMedia, change)
        this.update(entity)
    }

    public updateResponseOrderRandomizationEnabled(
        id: string,
        responseOrderRandomizationEnabled: boolean
    ) {
        const entity = this.getEntity(id)
        entity.responseOrderRandomizationEnabled = responseOrderRandomizationEnabled
        this.update(entity)
    }

    protected getTable(id: string): BaseTableDTO {
        return this.getEntity(id).tableResponses
    }

    protected updateTable(id: string, table: BaseTableDTO): void {
        const multipleChoiceEntity = this.getEntity(id)
        this.update({
            ...multipleChoiceEntity,
            tableResponses: table,
        })
    }

    public setNumberOfRows(itemId: string, nextNumberOfRows: number) {
        super.setNumberOfRows(itemId, nextNumberOfRows)
        const multipleChoiceItemDTO = this.getEntity(itemId)
        const numberOfResponses = multipleChoiceItemDTO.responses.length
        const differenceInResponses = nextNumberOfRows - numberOfResponses
        if (differenceInResponses > 0) {
            for (let i = 0; i < differenceInResponses; i++) {
                this.addResponse(itemId)
            }
        } else {
            for (let i = differenceInResponses; i < 0; i++) {
                this.deleteResponse(itemId, this.getEntity(itemId).responses.length - 1)
            }
        }
    }

    public updateFreeTextCheckbox(id: string, responseEnabled: boolean) {
        const entity = this.getEntity(id)

        if (!responseEnabled) {
            entity.freeTextResponse.freeTextResponseI18N = LocalizeDefault('')
        }

        entity.freeTextResponse.responseEnabled = responseEnabled

        this.update(entity)
    }

    public updateFreeTextResponse(id: string, locale: Locale, response: string) {
        const entity = this.getEntity(id)

        entity.freeTextResponse.freeTextResponseI18N = {
            ...(entity.freeTextResponse?.freeTextResponseI18N ?? {}),
            [locale]: response,
        }

        this.update(entity)
    }

    static showFreeTextResponse(displayType: MultipleChoiceDisplayType) {
        return (
            displayType !== MultipleChoiceDisplayType.Image &&
            displayType !== MultipleChoiceDisplayType.TABLE
        )
    }

    private removeResponseImages(id: string) {
        const entity = this.getEntity(id)

        for (let index = 0; index < entity.responses.length; index++) {
            this.updateResponseLocaleWiseMediaOpt(id, undefined, index)
        }
    }

    static checkResponsesHaveImage(responses: MultipleChoiceResponseDTO[]) {
        return responses.some((response) => {
            if (!response.localeWiseMedia) {
                return false
            }

            return response.localeWiseMedia.mediaManagerMediaVersionIds?.length || 0 > 0
        })
    }

    private removeExtraResponses(id: string) {
        const entity = this.getEntity(id)

        for (let index = entity.responses.length - 1; index > 3; index--) {
            this.deleteResponse(id, index)
        }
    }

    static checkTableLayoutUpdated(table: BaseTableDTO) {
        const headersUpdated = table.headers.some((header) => {
            return JSON.stringify(header.headerI18N) !== JSON.stringify(LocalizeDefault<string>(''))
        })

        const responsesUpdated = table.rows.some((row) => {
            return row.cells.some((cell) => {
                if (!cell) {
                    return false
                }
                return cell.dataI18N !== LocalizeDefault<string>('')
            })
        })

        return responsesUpdated || headersUpdated
    }
}
