import React, { useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Row } from '@amzn/stencil-react-components/layout'

import {
    isModuleGroupInDraftMode,
    ModuleGroupDTO,
    ModuleGroupType,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'

export const ModuleRuleButtons = ({
    setShowAddModuleModal,
    moduleGroupType,
    submitMesChange,
    submitPecChange,
    skipRuleEnabled,
    moduleGroup,
}: {
    setShowAddModuleModal: (showAddModuleModal: boolean) => void
    moduleGroupType: ModuleGroupType
    submitMesChange: (close: boolean) => void
    submitPecChange: (close: boolean) => void
    skipRuleEnabled: boolean
    moduleGroup: ModuleGroupDTO
}) => {
    const [buttonText, setButtonText] = useState<string>('')

    useEffect(() => {
        if (skipRuleEnabled) {
            setButtonText('Save Skip Rule')
        } else if (isModuleGroupInDraftMode(moduleGroup)) {
            setButtonText('Save Module Rule')
        } else {
            setButtonText('Save Module Rule and Create New Draft')
        }
    }, [moduleGroup, skipRuleEnabled])

    return (
        <Row gridGap='S300' justifyContent='right'>
            <Button variant={ButtonVariant.Tertiary} onClick={() => setShowAddModuleModal(false)}>
                Cancel
            </Button>
            <Button
                onClick={() =>
                    moduleGroupType === ModuleGroupType.MES
                        ? submitMesChange(true)
                        : submitPecChange(true)
                }
            >
                {buttonText}
            </Button>
            {isModuleGroupInDraftMode(moduleGroup) && (
                <Button
                    onClick={() =>
                        moduleGroupType === ModuleGroupType.MES
                            ? submitMesChange(false)
                            : submitPecChange(false)
                    }
                    variant={ButtonVariant.Primary}
                >
                    Save and add another
                </Button>
            )}
        </Row>
    )
}
