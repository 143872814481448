import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Col, Spacer } from '@amzn/stencil-react-components/layout'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table'

import { BucketDTO, CupDTO } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'

const numberOfItemsSort = (c: CupDTO) => c.itemIds.length

function sortData(
    activeSortID: string,
    sortDirection: SortDirection,
    cups: CupDTO[],
    bucketForCup: Record<string, BucketDTO>
): CupDTO[] {
    const direction = sortDirection === SortDirection.Ascending ? 'asc' : 'desc'

    const bucketIdSort = (a: CupDTO) => bucketForCup[a.cupId]?.bucketId

    switch (activeSortID) {
        case 'cupId':
            return _.orderBy(
                cups,
                ['cupId', 'rank', numberOfItemsSort, bucketIdSort],
                [direction, direction]
            )
        case 'rank':
            return _.orderBy(
                cups,
                ['rank', 'cupId', numberOfItemsSort, bucketIdSort],
                [direction, direction]
            )
        case 'number':
            return _.orderBy(
                cups,
                [numberOfItemsSort, 'cupId', 'rank', bucketIdSort],
                [direction, direction]
            )
        case 'bucket':
            return _.orderBy(
                cups,
                [bucketIdSort, 'cupId', 'rank', numberOfItemsSort],
                [direction, direction]
            )
    }

    throw new Error(`Unsupported active sort ID - ${activeSortID}`)
}

export interface BucketsTableProps {
    buckets: BucketDTO[]
    numberOfImports: number
}

export const CupsTable = React.memo(
    ({ buckets }: BucketsTableProps) => {
        const [selectedPage, setSelectedPage] = useState(1)
        const [bucketForCup, setBucketForCup] = useState<Record<string, BucketDTO>>({})
        const [allCups, setAllCups] = useState<CupDTO[]>([])

        const { activeSortId, sortDirection, onSort } = useSort({
            defaultSortDirection: SortDirection.Ascending,
            defaultActiveSortId: 'cupId',
        })

        useEffect(() => {
            setAllCups(_.flatten(buckets.map((b) => b.cups)))

            const nextBucketForCup: Record<string, BucketDTO> = {}

            buckets.forEach((bucket) => {
                bucket.cups.forEach((cup) => {
                    nextBucketForCup[cup.cupId] = bucket
                })
            })

            setBucketForCup(nextBucketForCup)
        }, [buckets, setAllCups, setBucketForCup])

        const pageSize = 10

        const cups = Object.values(allCups)

        const sortedData = sortData(activeSortId ?? '', sortDirection, cups, bucketForCup)

        const columns: TableColumn<CupDTO>[] = [
            { header: 'Cup', accessor: 'cupId', sortId: 'cupId', width: '24%' },
            { header: 'Rank', accessor: 'rank', sortId: 'rank', width: '24%' },
            {
                header: 'Number of Items',
                accessor: ({ data }) => data.itemIds.length,
                sortId: 'number',
                width: '24%',
            },
            {
                header: 'Bucket',
                sortId: 'bucket',
                accessor: ({ data }) => bucketForCup[data.cupId]?.bucketId ?? 'none',
                width: '24%',
            },
        ]

        const numberOfPages = Math.ceil(sortedData.length / pageSize)

        const page = sortedData.slice(
            (selectedPage - 1) * pageSize,
            (selectedPage - 1) * pageSize + pageSize
        )

        return (
            <Col gridGap='S200' dataTestId='cups-table'>
                <Table
                    activeSortId={activeSortId}
                    sortDirection={sortDirection}
                    onSort={onSort}
                    columns={columns}
                    data={page}
                />
                <Spacer height={'S200'} />
                <Pagination
                    dataTestId='cups-table-pagination'
                    numberOfPages={numberOfPages}
                    onPageSelect={(pageIndex) => {
                        setSelectedPage(pageIndex)
                    }}
                    selectedPage={selectedPage}
                />
            </Col>
        )
    },
    (previous, next) => {
        return previous.numberOfImports === next.numberOfImports
    }
)

CupsTable.displayName = 'CupsTable'
