import {
    AudioEvaluationItemDTO,
    defaultAudioEvaluationDTO,
    ScoringMLModelType,
    TranscriptionMLModelType,
} from 'src/models/dto/items/AudioEvaluationItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.AudioEvaluation

export class AudioEvaluationHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => AudioEvaluationHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): AudioEvaluationItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as AudioEvaluationItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): AudioEvaluationItemDTO {
        return defaultAudioEvaluationDTO()
    }

    private static update(entity: AudioEvaluationItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateExperimental(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            experimental: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateMaxNumberOfAttempts(id: string, nextValue: number) {
        this.update({
            ...this.getEntity(id),
            maxNumberOfAttempts: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateMaxRecordingTimeInSeconds(id: string, nextValue: number) {
        this.update({
            ...this.getEntity(id),
            maxRecordingTimeInSeconds: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateScoringMlModel(id: string, nextValue: ScoringMLModelType) {
        this.update({
            ...this.getEntity(id),
            scoringMLModel: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateScoringMlModelVersion(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            scoringMLModelVersion: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateTranscriptionMlModel(id: string, nextValue: TranscriptionMLModelType) {
        this.update({
            ...this.getEntity(id),
            transcriptionMLModel: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateTranscriptionMlModelVersion(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            transcriptionMLModelVersion: nextValue,
        } as AudioEvaluationItemDTO)
    }

    static updateTask(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.taskI18N[locale] = nextValue
        this.update(entity)
    }

    static updateSituation(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.situationI18N[locale] = nextValue
        this.update(entity)
    }
}
