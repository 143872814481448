/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react'
import _ from 'lodash'

import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { Table } from '@amzn/stencil-react-components/table'
import { px } from '@amzn/stencil-react-components/utils'

import { Markdown } from 'src/components/Markdown'
import { SmartExpander } from 'src/components/SmartExpander'
import { Locale } from 'src/models/dto/Locale'

export enum StructuralPreviewKind {
    Atomic = 'Atomic',
    ComplexList = 'ComplexList',
    GridTable = 'GridTable',
    ObjectTable = 'ObjectTable',
    Object = 'Object',
}

export type ClassifyPreview =
    | { kind: StructuralPreviewKind.Atomic; value: string | null }
    | { kind: StructuralPreviewKind.ComplexList; value: unknown[] }
    | { kind: StructuralPreviewKind.GridTable; value: unknown[][] }
    | { kind: StructuralPreviewKind.ObjectTable; value: Record<string, unknown>[] }
    | { kind: StructuralPreviewKind.Object; value: Record<string, unknown> }

function isAtomic(v: unknown, locale: Locale) {
    return classifyPreview(v, locale).kind === StructuralPreviewKind.Atomic
}

export function classifyPreview(v: unknown, locale: Locale): ClassifyPreview {
    if (_.isArray(v)) {
        if (v.length === 0) {
            return { kind: StructuralPreviewKind.Atomic, value: '(empty)' }
        } else if (_.every(v, _.isArray)) {
            return { kind: StructuralPreviewKind.GridTable, value: v }
        } else if (_.every(v, (n) => _.isString(n) || _.isBoolean(n) || _.isEmpty(n))) {
            return {
                kind: StructuralPreviewKind.ObjectTable,
                value: v.map((n: string | boolean) => ({ value: `${n.toString()}` })),
            }
        } else if (
            _.every(
                v,
                (x: unknown) =>
                    _.isObject(x) && _.every(Object.values(x), (y: unknown) => isAtomic(y, locale))
            )
        ) {
            return {
                kind: StructuralPreviewKind.ObjectTable,
                value: v,
            }
        }
        return { kind: StructuralPreviewKind.ComplexList, value: v }
    } else if (_.isObject(v)) {
        if (Object.keys(v).some((l: string) => (Locale as Record<string, string>)[l])) {
            return classifyPreview((v as Record<Locale, unknown>)[locale] ?? null, locale)
        } else if (Object.keys(v).length === 0) {
            return { kind: StructuralPreviewKind.Atomic, value: '(empty)' }
        } else if (Object.keys(v).length === 1) {
            return classifyPreview(Object.values(v)[0], locale)
        }
        return { kind: StructuralPreviewKind.Object, value: v as Record<string, unknown> }
    } else if (_.isString(v) || _.isNumber(v) || v === true || v === false) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return { kind: StructuralPreviewKind.Atomic, value: `${v}` }
    } else if (!v) {
        return { kind: StructuralPreviewKind.Atomic, value: null }
    }
    throw new Error(`classify: unknown value: typeof v === ${typeof v}`)
}

function decomposeObject(v: Record<string, unknown>, locale: Locale) {
    const atomic: Record<string, unknown> = {}
    const structural: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(v)) {
        if (isAtomic(value, locale)) {
            if (classifyPreview(value, locale).value !== null) {
                atomic[key] = value
            }
        } else {
            structural[key] = value
        }
    }
    return { atomic, structural }
}

function label(key: string, locale: string) {
    const [isLocalized, key1]: [boolean, string] = key.endsWith('I18N')
        ? [true, key.replace(/I18N$/, '')]
        : [false, key]
    return _.startCase(key1) + (isLocalized ? '[' + locale + ']' : '')
}

function PreviewObjectTableInner({
    value,
    locale,
}: {
    value: Record<string, unknown>[]
    locale: Locale
}) {
    const columns = useMemo(() => {
        if (!value.length) {
            return []
        }
        const combined: Record<string, unknown> = {}
        value.forEach((v) => Object.keys(v).forEach((k: string) => (combined[k] = 1)))
        return [
            {
                hasReducedSpacing: true,
                accessor: () => null,
                header: '#',
                cellComponent: ({ index }: { index: number }) => <>{index + 1}</>,
            },
            ...Object.keys(combined).map((key) => ({
                accessor: key,
                header: label(key, locale),
                cellComponent: ({ data }: { data: unknown }) => (
                    <StructuralPreview value={data} locale={locale} />
                ),
            })),
        ]
    }, [locale, value])
    return value.length ? (
        <Col>
            <Table columns={columns} data={value} />
        </Col>
    ) : null
}

const PreviewObjectTable = React.memo(PreviewObjectTableInner)

function PreviewObjectGridTableInner({ value, locale }: { value: unknown[][]; locale: Locale }) {
    const value1 = useMemo(
        () =>
            value.map((entry) => {
                const obj: Record<string, unknown> = {}
                entry.forEach((c, i) => {
                    obj[`col${i}`] = c
                })
                return obj
            }),
        [value]
    )
    return <PreviewObjectTable value={value1} locale={locale} />
}

const PreviewObjectGridTable = React.memo(PreviewObjectGridTableInner)

function PreviewObjectInner({ value, locale }: { value: Record<string, unknown>; locale: Locale }) {
    const entries = useMemo(
        () => Object.entries(value).map(([key, v]) => ({ key, value: v })),
        [value]
    )
    return entries.length ? (
        <Col>
            <Table
                columns={[
                    {
                        header: 'Field',
                        accessor: ({ data }: { data: { key: string } }) => label(data.key, locale),
                    },
                    {
                        header: 'Value',
                        accessor: ({ data }: { data: { value: unknown } }) => data.value,
                        cellComponent: ({ data }) => (
                            <StructuralPreview value={data} locale={locale} />
                        ),
                    },
                ]}
                data={entries}
            />
        </Col>
    ) : null
}

const PreviewObject = React.memo(PreviewObjectInner)

const BodyWrapper = styledWithTheme(View)(
    ({ theme }) => `
    margin-left: ${px(theme.values.space.S100)};
    padding-left: ${px(theme.values.space.S100)};
    border-left: 1px solid ${theme.selectors.color('neutral20')};
    border-bottom: 1px solid ${theme.selectors.color('neutral20')};
`
)

export function StructuralPreview({ value, locale }: { value: unknown; locale: Locale }) {
    const res = useMemo(() => classifyPreview(value, locale), [value, locale])
    switch (res.kind) {
        case StructuralPreviewKind.Atomic:
            return <Markdown markdown={res.value === null ? '(null)' : res.value} />
        case StructuralPreviewKind.ComplexList:
            return (
                <Col>
                    {res.value.map((v, i) => {
                        if (isAtomic(v, locale)) {
                            return <StructuralPreview key={i} value={v} locale={locale} />
                        }

                        return (
                            <SmartExpander titleText={`Entry ${i + 1}`} key={i}>
                                {() => (
                                    <BodyWrapper>
                                        <StructuralPreview value={v} locale={locale} />
                                    </BodyWrapper>
                                )}
                            </SmartExpander>
                        )
                    })}
                </Col>
            )
        case StructuralPreviewKind.GridTable:
            return (
                <Col>
                    <PreviewObjectGridTable value={res.value} locale={locale} />
                </Col>
            )
        case StructuralPreviewKind.ObjectTable:
            return (
                <Col>
                    <PreviewObjectTable value={res.value} locale={locale} />
                </Col>
            )
        case StructuralPreviewKind.Object: {
            const { atomic, structural } = decomposeObject(res.value, locale)
            return (
                <Col>
                    <PreviewObject value={atomic} locale={locale} />
                    {Object.entries(structural)
                        .filter(([, v]) => v)
                        .map(([key, v]) => (
                            <SmartExpander titleText={label(key, locale)} key={key}>
                                {() => (
                                    <BodyWrapper>
                                        <StructuralPreview value={v} locale={locale} />
                                    </BodyWrapper>
                                )}
                            </SmartExpander>
                        ))}
                </Col>
            )
        }
    }
}
