import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

import { Col, Hr, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { HarveyBallValue2 } from 'src/components/CustomIcons/HarveyBall'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    RankingCellSubType,
    RankingCellType,
    RankingCellValue,
    RankingResponseCell,
    RankingResponseScore,
    RankingResponseTableItemDTO,
} from 'src/models/dto/items/RankingItemDTO'
import { LocalizeDefault } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    DefaultArrowLegend,
    DefaultHarveyBallLegend,
    EditCellComponentProps,
    Legend,
    TableEditor,
    TableScaleHarveyBallScaleType,
} from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { RankingResponseTableHandler } from 'src/services/EntityServices/ItemUpdateHandlers/RankingResponseTableHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import { ItemEditorProps } from './ItemEditorContainer'
import { ItemEditorCheckboxInput, ItemEditorTextInput } from './ItemEditorInputs'
import {
    cellContentOptions,
    RankingResponseTableCellEditor,
    ValueCell,
} from './RankingResponseTableCellEditor'

const defaultCellData = () => ({ values: [] })

export const RankingResponseTableItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<RankingResponseTableItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    const { responseRows, columns, ratingScales } = itemDTO.rankingTable

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '400px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    const COL_PADDING = { top: `${matches.s ? '8px' : '35px'}` }

    const includeArrow = useMemo(
        () => ratingScales.some((s) => s.type === RankingCellType.Arrow),
        [ratingScales]
    )
    const includeHarveyBall = useMemo(
        () => ratingScales.some((s) => s.type === RankingCellType.HarveyBall),
        [ratingScales]
    )

    const options = useMemo(
        () =>
            cellContentOptions.filter((option) =>
                option.value.every((v) => {
                    if (v.type === RankingCellType.HarveyBall && !includeHarveyBall) {
                        return false
                    } else if (v.type === RankingCellType.Arrow && !includeArrow) {
                        return false
                    }
                    return true
                })
            ),
        [includeArrow, includeHarveyBall]
    )

    const legends: Legend<RankingCellSubType>[] = useMemo(() => {
        return [
            DefaultHarveyBallLegend<RankingCellSubType>(
                includeHarveyBall,
                (nextValue: boolean) => {
                    RankingResponseTableHandler.toggleSymbol(
                        itemId,
                        RankingCellType.HarveyBall,
                        nextValue
                    )
                },
                (['HarveyBallEmpty', 'HarveyBallHalfFull', 'HarveyBallFull'] as const).map(
                    (name) => ({
                        scaleType: RankingCellSubType[name],
                        insetElementLeading: (
                            <HarveyBallValue2 type={TableScaleHarveyBallScaleType[name]} />
                        ),
                        value: ratingScales.find((s) => s.subType === RankingCellSubType[name])
                            ?.valueI18N[locale],
                        scaleDataTestId: _.kebabCase(name) + '-scale-input',
                    })
                ),
                (subType, value) => {
                    RankingResponseTableHandler.updateScaleValue(
                        itemId,
                        { type: RankingCellType.HarveyBall, subType },
                        locale,
                        value
                    )
                }
            ),
            DefaultArrowLegend(
                RankingCellSubType,
                includeArrow,
                (nextValue: boolean) => {
                    RankingResponseTableHandler.toggleSymbol(
                        itemId,
                        RankingCellType.Arrow,
                        nextValue
                    )
                },
                (subType, value) => {
                    RankingResponseTableHandler.updateScaleValue(
                        itemId,
                        { type: RankingCellType.Arrow, subType },
                        locale,
                        value
                    )
                }
            ),
        ] as never[]
    }, [includeArrow, includeHarveyBall, ratingScales, itemId, locale])

    const CellViewComponent = useCallback(
        ({ cellData }: { cellData: RankingResponseCell }) => (
            <div data-test-id='ranking-table-cell'>
                <ValueCell cell={cellData} locale={locale} disabled={editDisabled} />
            </div>
        ),
        [editDisabled, locale]
    )

    const [scoresState, setScores] = useState<RankingResponseScore[]>(() =>
        responseRows.map(() => ({ score: 0 }))
    )
    const [responseRowLabelState, setResponseRowLabel] = useState('')
    const [onCellClear, setOnCellClear] = useState<{
        callback: (p: { rowNum: number; colNum: number }) => void
    }>({
        callback: _.noop,
    })
    const CellEditComponent = useCallback(
        (props: EditCellComponentProps<RankingResponseCell>) => {
            return (
                <RankingResponseTableCellEditor
                    {...props}
                    responseRows={responseRows}
                    setScores={setScores}
                    setResponseLabel={setResponseRowLabel}
                    options={options}
                    locale={locale}
                    setOnCellClear={setOnCellClear}
                />
            )
        },
        [responseRows, options, locale, setOnCellClear]
    )

    const onClear = useCallback(
        ({ rowNum, colNum }: { rowNum: number; colNum: number }) => {
            setScores(
                responseRows[rowNum].responseScores.map(() => {
                    return { score: 0 } as RankingResponseScore
                })
            )
            setResponseRowLabel('')
            onCellClear.callback?.({ rowNum, colNum })
        },
        [responseRows, onCellClear]
    )

    const headers = columns.map((t) => ({
        headerName: t.headerI18N,
        editable: true,
    }))

    const tableRows = useMemo(() => responseRows.map((r) => r.responseCells), [responseRows])

    const dataTestId = useMemo(
        () => ({
            table: 'RankingTable',
            warningModal: 'ranking-table-mobile-warning-modal',
            deleteModal: 'ranking-table-delete-content-modal',
            headerCell: 'ranking-table-header-cell',
            row: 'ranking-table-response-row',
            cell: 'ranking-table-cell',
        }),
        []
    )

    const setNumberOfColumns = useCallback(
        (nextValue: number) => {
            const colsToAdd = nextValue - columns.length
            if (colsToAdd > 0) {
                for (let i = 0; i < colsToAdd; i++) {
                    const newColumn = {
                        headerI18N: LocalizeDefault<string>('', locale),
                    }
                    RankingResponseTableHandler.addTableColumn(itemId, newColumn)
                }
            } else if (colsToAdd < 0) {
                RankingResponseTableHandler.sliceTableColumn(itemId, nextValue)
            }
            setScores(responseRows.map(() => ({ score: 0 })))
            setResponseRowLabel('')
        },
        [locale, itemId, columns.length, responseRows]
    )

    const setNumberOfRows = useCallback(
        (nextValue: number) => {
            const rowsToAdd = nextValue - responseRows.length
            if (rowsToAdd > 0) {
                const numCols = columns.length
                for (let i = 0; i < rowsToAdd; i++) {
                    const newRow = RankingResponseTableHandler.generateEmptyResponseRow(
                        numCols,
                        nextValue
                    )
                    RankingResponseTableHandler.addResponseRow(itemId, newRow)
                }
            } else if (rowsToAdd < 0) {
                RankingResponseTableHandler.sliceResponseRow(itemId, nextValue)
            }
            setScores(_.range(responseRows.length).map(() => ({ score: 0 })))
            setResponseRowLabel('')
        },
        [itemId, responseRows.length, columns.length]
    )

    const setColumnName = useCallback(
        (columnIndex: number, columnName: string) => {
            RankingResponseTableHandler.updateColumnHeader(itemId, columnIndex, columnName, locale)
        },
        [itemId, locale]
    )

    const setCellData = useCallback(
        ({
            rowNum,
            colNum,
            cellData,
        }: {
            rowNum: number
            colNum: number
            cellData: { values: RankingCellValue[] }
        }) => {
            const { values } = cellData
            RankingResponseTableHandler.updateCellValue(
                itemId,
                rowNum,
                colNum,
                values,
                scoresState,
                responseRowLabelState
            )
        },
        [itemId, scoresState, responseRowLabelState]
    )

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        itemId={itemId}
                        setValue={(nextValue: string) => {
                            ItemEntityService.updateLabel(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={COL_PADDING}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemId}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            ItemEntityService.updateOptional(itemId, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemId}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                RankingResponseTableHandler.updatePreserveOrder(itemId, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </Row>
            {/* stimulus*/}
            <Row gridGap='S300'>
                <Col flex='0 0 100%' padding={COL_PADDING}>
                    <ItemEditorTextInput
                        inputId={'table-stimulus'}
                        dataTestId={'table-stimulus'}
                        itemId={itemId}
                        disabled={editDisabled}
                        labelText={'Table stimulus'}
                        placeholder='E.g. Rank the order of the vendors'
                        value={itemDTO.statementI18N[locale] || ''}
                        setValue={(nextValue: string) => {
                            RankingResponseTableHandler.updateStatementI18N(itemId, {
                                ...itemDTO.statementI18N,
                                [locale]: nextValue,
                            })
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.statementI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Hr />
            <Col gridGap='S300'>
                {/* table layout settings */}
                <Row gridGap='S300' alignItems='flex-start'>
                    <Col padding={'34px 0'}>
                        <ItemEditorCheckboxInput
                            inputId={'randomized-responses'}
                            dataTestId={'randomized-responses'}
                            itemId={itemId}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText={'Randomize table responses'}
                            value={itemDTO.responseOrderRandomizationEnabled}
                            setValue={(nextValue: boolean) => {
                                RankingResponseTableHandler.updateResponseOrderRandomizationEnabled(
                                    itemId,
                                    nextValue
                                )
                            }}
                        />
                    </Col>
                </Row>

                {/* The actual table */}
                <TableEditor<RankingResponseCell, RankingCellSubType>
                    itemId={itemId}
                    dataTestId={dataTestId}
                    headers={headers}
                    rows={tableRows}
                    defaultCellData={defaultCellData}
                    setNumberOfColumns={setNumberOfColumns}
                    setNumberOfRows={setNumberOfRows}
                    legends={legends}
                    locale={locale}
                    setColumnName={setColumnName}
                    onClear={onClear}
                    setCellData={setCellData}
                    editCellComponent={CellEditComponent}
                    cellViewComponent={CellViewComponent}
                    disabled={editDisabled}
                />
            </Col>
        </>
    )
}
