import React, { useState } from 'react'

import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { H2 } from '@amzn/stencil-react-components/text'

import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { Locale } from 'src/models/dto/Locale'
import { ModuleEntity, ModuleTimerWarningDTO } from 'src/models/dto/ModuleDTO'
import {
    ItemEditorCheckboxInput,
    ItemEditorLabel,
    ItemEditorNumberInput,
    LabelSize,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import {
    CellType,
    ItemEditorTable,
    ItemEditorTableColumns,
} from 'src/pages/module-builder/item-editors/ItemEditorTable'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

interface WarningTimerData {
    label: string
    data: ModuleTimerWarningDTO
}

export const ModuleTimerEditor = ({
    moduleId,
    locale,
    showValidationError,
    editDisabled,
}: {
    moduleId: string
    locale: Locale
    showValidationError?: boolean
    editDisabled?: boolean
}) => {
    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    // Temporary use the generic errors for timer validation for now
    const shouldShowValidationError = false

    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Assessment Metadata`

    const warningTimerData: WarningTimerData[] = [
        {
            label: 'Yellow',
            data: ModuleEntityService.getModuleWarningConfig(moduleId, 0),
        },
        {
            label: 'Red',
            data: ModuleEntityService.getModuleWarningConfig(moduleId, 1),
        },
    ]

    const warningTimerTableColumns: ItemEditorTableColumns<WarningTimerData> = [
        {
            header: 'Label',
            headerLabel: 'Label',
            width: '50%',
            disabled: true,
            cellType: CellType.TextInput,
            accessor: (warningData) => warningData.label,
        },
        {
            header: 'Timer remaining',
            headerLabel: 'Timer remaining',
            width: '50%',
            disabled: !isDefaultLocale(locale) || editDisabled,
            cellType: CellType.NumberInput,
            placeHolder: 'Enter number value in seconds',
            accessor: (warningData) => warningData.data.timeRemaining,
            setValue: (nextValue, index) =>
                ModuleEntityService.updateWarningTimer(moduleId, index, nextValue as number),
        },
    ]

    return (
        <>
            <Col
                gridGap='S300'
                padding={{ top: 'S300', left: 'S400', right: 'S400', bottom: 'S300' }}
                backgroundColor='#FFFFFF'
            >
                <Row alignItems='center' gridGap='S200' dataTestId={'module-timer-header'}>
                    <Col rowGap={0}>
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                dataTestId={`${
                                    isExpanded ? 'collapse' : 'expand'
                                }-module-timer-expander-button`}
                                iconAltText={triggerButtonName}
                                aria-label={triggerButtonName}
                                isExpanded={isExpanded}
                                onClick={() => setIsExpanded(!isExpanded)}
                                role={'button'}
                            />
                            <H2
                                fontSize='T400'
                                fontWeight='bold'
                                color={shouldShowValidationError ? 'red70' : 'neutral90'}
                            >
                                Module Timer
                            </H2>
                        </Row>
                    </Col>
                </Row>

                <ExpanderContent isExpanded={isExpanded}>
                    <View width={'100%'} padding={{ top: 'S300', bottom: 'S400' }}>
                        <Hr size='wide' />
                    </View>
                    <Col gridGap='S200'>
                        <ItemEditorNumberInput
                            dataTestId={'module-timer-time-limit'}
                            inputId={'module-timer-time-limit'}
                            itemId={moduleId}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText='Time limit'
                            labelSize={LabelSize.Normal}
                            value={moduleEntity.moduleTimerConfig?.timeLimit ?? null}
                            placeholder='Enter number value in seconds'
                            setValue={(nextValue: number | null) => {
                                ModuleEntityService.updateTimeLimit(
                                    moduleId,
                                    nextValue ?? undefined
                                )
                            }}
                        />
                        <ItemEditorCheckboxInput
                            itemId={moduleId}
                            dataTestId={'enable-warning-configs'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            inputId={'enable-warning-configs'}
                            labelText={'Add timer warning'}
                            value={moduleEntity.moduleTimerConfig?.warningConfigs != null}
                            setValue={(nextValue: boolean) => {
                                ModuleEntityService.updateWarningTimerEnabled(moduleId, nextValue)
                                if (nextValue) {
                                    warningTimerData.forEach(() =>
                                        ModuleEntityService.addWarningTimer(moduleId, 0)
                                    )
                                }
                            }}
                        />
                    </Col>
                    {moduleEntity.moduleTimerConfig?.warningConfigs != null && (
                        <>
                            <Spacer height='S400' />
                            <Col width={'100%'}>
                                <ItemEditorLabel
                                    id={'warning-timer-config'}
                                    labelText={'Warning configs'}
                                    labelSize={LabelSize.Normal}
                                />
                                <Spacer height={'S400'} />
                                <ItemEditorTable
                                    itemId={moduleId}
                                    columns={warningTimerTableColumns}
                                    data={warningTimerData}
                                    dataTestId='warning-timer-config-table'
                                    showError={showValidationError}
                                />
                            </Col>
                        </>
                    )}
                </ExpanderContent>
            </Col>
            <Spacer height='S400' />
        </>
    )
}
