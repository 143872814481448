import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { v4 } from 'uuid'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { WithModal } from '@amzn/stencil-react-components/modal'
import { H4 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { DropdownButton } from 'src/components/DropdownButton'
import { MediaSearchModal } from 'src/components/media/MediaSearchModal'
import { MediaUploadModal } from 'src/components/media/MediaUploadModal'
import { UpdateFields } from 'src/components/media/UseMediaButton'
import { MediaCard } from 'src/components/MediaContentsEditor/MediaCard'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useListEditor } from 'src/hooks/DTOEditor/listEditor'
import { useObjectEditor } from 'src/hooks/DTOEditor/objectEditor'
import { useAutoFillOptionalEditor } from 'src/hooks/DTOEditor/optionalEditor'
import { defaultMedias, MediaContent, Medias } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { ItemEditorTextInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'

function createMediaContent(relativePath: string, mimeType?: string): MediaContent {
    const id = 'media' + v4()
    return {
        id,
        relativePathI18N: LocalizeDefault(relativePath, Locale.en_US),
        altTextI18N: LocalizeDefault('', Locale.en_US),
        mimeType: mimeType ?? 'image/png',
        mediaLabel: id,
    }
}

export interface MediaContentsEditorProps {
    inputId?: string
    itemId?: string
    localeWiseMediaOpt?: Medias
    setLocaleWiseMediaOpt: React.Dispatch<React.SetStateAction<Medias | undefined>>
    shouldExpandOnMount?: boolean
    disableMediaExpander?: boolean
    maxNumberOfMedia?: number
    validationErrorMessage?: string
    showError?: boolean
    locale: Locale
    buttonText?: string
    buttonVariant?: ButtonVariant
    lockedFileTypes?: string[]
    tableAriaLabel?: string
    disabled?: boolean
}

function MissingAltTextBanner({ missingAltTextCount }: { missingAltTextCount: number }) {
    if (!missingAltTextCount) {
        return null
    }

    return (
        <MessageBanner
            aria-live='off'
            type={MessageBannerType.Error}
            isDismissible
            dataTestId='media-files-error'
        >
            {missingAltTextCount > 1 ? (
                <>{missingAltTextCount} images are missing alt text.</>
            ) : (
                <>1 image is missing alt text.</>
            )}
        </MessageBanner>
    )
}

const MediaOptions = ({
    getUpdateFields,
    locale,
}: {
    getUpdateFields: (close: () => void) => UpdateFields
    locale: Locale
}) => {
    const dropdown = useCallback(
        ({
            openMediaUploadModal,
            openMediaSearchModal,
        }: Record<'openMediaUploadModal' | 'openMediaSearchModal', () => void>) => ({
            title: 'Media options',
            disabled: !isDefaultLocale(locale),
            values: [
                { name: 'Search media library', onClick: openMediaSearchModal },
                { name: 'Upload new image', onClick: openMediaUploadModal },
            ],
            variant: ButtonVariant.Secondary,
        }),
        [locale]
    )

    const renderSearchModal = useCallback(
        ({ close }: { close: () => void }) => (
            <MediaSearchModal close={close} updateFields={getUpdateFields(close)} isModuleBuilder />
        ),
        [getUpdateFields]
    )

    const renderMediaUploadModal = useCallback(
        ({ close }: { close: () => void }) => (
            <MediaUploadModal close={close} updateFields={getUpdateFields(close)} isModuleBuilder />
        ),
        [getUpdateFields]
    )

    return (
        <WithModal renderModal={renderSearchModal}>
            {({ open: openMediaSearchModal }) => (
                <WithModal renderModal={renderMediaUploadModal}>
                    {({ open: openMediaUploadModal }) => (
                        <>
                            <Spacer flex='1' />
                            <DropdownButton
                                {...dropdown({
                                    openMediaSearchModal,
                                    openMediaUploadModal,
                                })}
                            />
                        </>
                    )}
                </WithModal>
            )}
        </WithModal>
    )
}

const MediaContentsEditorInner = ({
    inputId,
    itemId,
    localeWiseMediaOpt: localeWiseMediaOptPrev,
    setLocaleWiseMediaOpt,
    shouldExpandOnMount = false,
    disableMediaExpander,
    maxNumberOfMedia,
    showError,
    validationErrorMessage,
    locale,
}: MediaContentsEditorProps) => {
    const localeWiseMediaOpt = useMemo(
        () => localeWiseMediaOptPrev,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(localeWiseMediaOptPrev)]
    )
    const localeWiseMediaProps = useAutoFillOptionalEditor(
        { value: localeWiseMediaOpt, onChange: setLocaleWiseMediaOpt },
        defaultMedias
    )

    const getPropsForField = useObjectEditor<Medias>(localeWiseMediaProps, ['mediaList', 'layout'])
    const { value: mediaContents } = getPropsForField.mediaList
    const { listManipulation } = useListEditor(getPropsForField.mediaList)
    const [imagePathToAdd, setImagePathToAdd] = useState('')
    const addImage = useCallback(() => {
        if (!imagePathToAdd) {
            return
        }
        listManipulation.addItem(createMediaContent(imagePathToAdd))
        setImagePathToAdd('')
    }, [imagePathToAdd, listManipulation])

    const missingAltText = useMemo(
        () => mediaContents.filter((f) => (f.altTextI18N.en_US || '').trim() === ''),
        [mediaContents]
    )

    const [isExpanded, toggle] = useReducer((x: boolean) => !x, shouldExpandOnMount)

    const listManipulationRef = useRef(listManipulation)
    useEffect(() => {
        listManipulationRef.current = listManipulation
    }, [listManipulation, listManipulationRef])

    const getUpdateFields = useCallback(
        (close?: () => void) => (path: string, mimeType?: string) => {
            listManipulationRef.current.addItem(createMediaContent(path, mimeType))
            close?.()
        },
        [listManipulationRef]
    )

    return (
        <Col padding={{ top: 'S300', bottom: 'S300' }}>
            <Row alignItems='center' gridGap='S100' style={{ marginLeft: '-8px' }}>
                {!disableMediaExpander && (
                    <>
                        <TriggerButton
                            isExpanded={isExpanded}
                            onClick={toggle}
                            iconAltText={'Expand/collapse media'}
                        />
                        <H4 fontSize='T400' fontWeight='bold'>
                            Media
                        </H4>
                    </>
                )}
                <MediaOptions getUpdateFields={getUpdateFields} locale={locale} />
            </Row>
            {isExpanded && !disableMediaExpander && <Spacer height='S400' />}
            <ResponsiveRow width='100%' gridGap='S200'>
                <Col flex='1' minWidth={140}>
                    <ItemEditorTextInput
                        inputId={inputId ?? 'media-files-add-image-path'}
                        itemId={itemId ?? 'media'}
                        disabled={!isDefaultLocale(locale)}
                        value={imagePathToAdd}
                        setValue={(value: string) => setImagePathToAdd(value)}
                        placeholder='Enter the image path from a previously uploaded file'
                        labelText='Add image path'
                        dataTestId='media-files-add-image-path'
                        validationErrorMessage={validationErrorMessage}
                        showError={showError}
                    />
                </Col>
                <Col>
                    <Spacer height={'S400'} />
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={addImage}
                        dataTestId='media-files-add-image'
                        disabled={
                            (maxNumberOfMedia !== undefined &&
                                mediaContents.length >= maxNumberOfMedia) ||
                            !isDefaultLocale(locale)
                        }
                    >
                        Add image
                    </Button>
                </Col>
            </ResponsiveRow>
            {mediaContents.length === 0 ? null : (
                <>
                    <Spacer height='S400' />
                    <Hr />
                    <Spacer height='S400' />
                    <Col backgroundColor='white' gridGap='S300' role='group'>
                        {mediaContents.map((mediaContent, i) => (
                            <MediaCard
                                mediaIndex={i}
                                mediaContent={mediaContent}
                                locale={locale}
                                setMediaContent={listManipulation.onItemChange(i)}
                                deleteMediaContent={() => listManipulation.deleteItem(i)}
                                key={listManipulation.getKey(mediaContent, i)}
                            />
                        ))}
                        <MissingAltTextBanner missingAltTextCount={missingAltText.length} />
                    </Col>
                </>
            )}
        </Col>
    )
}

export const MediaContentsEditor = React.memo(MediaContentsEditorInner)
