import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { StencilProvider, styledWithTheme } from '@amzn/stencil-react-components/context'
import { IconExternalLink } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Link } from '@amzn/stencil-react-components/link'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2, H3, Text } from '@amzn/stencil-react-components/text'
import defaultTheme from '@amzn/stencil-react-theme-default'

import { EvaluationScores } from 'src/components/EvaluationScores'
import { FlyoutInstructions } from 'src/components/FlyoutInstructions'
import { LocaleSelector } from 'src/components/LocaleSelector'
import { ModuleAssessmentMetadata } from 'src/components/ModuleAssessmentMetadata'
import { ModuleBasicDetails } from 'src/components/ModuleBasicDetails'
import { ModuleFooter } from 'src/components/ModuleFooter'
import { ModuleMasthead } from 'src/components/ModuleMasthead'
import { ModuleTimerEditor } from 'src/components/ModuleTimerEditor'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useTitle } from 'src/hooks/useTitle'
import {
    CommonUserEventKeys,
    ModuleBuilderEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { ActivityEntity, ActivityType } from 'src/models/dto/ActivityDTO'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { Locale } from 'src/models/dto/Locale'
import { ModuleEntity } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewStatusDTO } from 'src/models/dto/ModuleVersionDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { AdaptiveEngineEditor } from 'src/pages/module-builder/adaptive-engine/AdaptiveEngineEditor'
import { BucketsAndCupsGroupPage } from 'src/pages/module-builder/buckets-and-cups-editor/BucketAndCupsGroupPage'
import { EndOfModuleCard } from 'src/pages/module-builder/EndOfModuleCard'
import { CompletionCodeItemEditor } from 'src/pages/module-builder/item-editors/CompletionCodeItemEditor'
import { MUPPExamEditor } from 'src/pages/module-builder/mupp-exam-editor/MUPPExamEditor'
import { PageEditor, PageEditorProps } from 'src/pages/module-builder/page-editor/PageEditor'
import { RandomSelectionGroupEditor } from 'src/pages/module-builder/page-editor/RandomSelectionGroupEditor'
import { Authenticator } from 'src/services/Authenticator'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleService } from 'src/services/backend/ModuleService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import { Store, STORE_ACTION, Stores } from 'src/services/Store'

const PageEditorContainer = styledWithTheme(Container)(
    ({ theme }) => `
    background: linear-gradient(180deg, white 0px, white 70px, ${theme.selectors.color(
        'neutral05'
    )} 250px)
`
)

const pageTimer = new TimerStopwatch('ModuleBuilderPageTimer')

const ModuleActivity = (props: {
    moduleVersionId: string
    workflowId: string
    moduleLength: number
    workflowIndex: number
    locale: Locale
    showValidationError?: boolean
    editDisabled?: boolean
    moduleStatus?: ModuleStatus
}) => {
    const { workflowId, workflowIndex } = props
    const { entity: activityEntity } = useActivityEntity<ActivityEntity>({
        workflowEntityId: workflowId,
        moduleEntityId: props.moduleVersionId,
    })
    const defaultExpanded = props.moduleLength < 5 || (activityEntity.isNewActivity ?? false)
    const pageEditorProps: PageEditorProps = {
        pageIndex: workflowIndex,
        workflowEntityId: workflowId,
        moduleEntityId: props.moduleVersionId,
        isLastPage: workflowIndex + 1 === props.moduleLength,
        showValidationError: props.showValidationError,
        locale: props.locale,
        defaultExpanded: defaultExpanded,
        editDisabled: props.editDisabled,
    }
    return (
        <React.Fragment>
            <Hr />
            <PageEditorContainer width='100%' paddingHorizontal={0}>
                {activityEntity.type === ActivityType.LaunchItemGroup && (
                    <PageEditor {...pageEditorProps} />
                )}

                {activityEntity.type === ActivityType.LaunchCAT && (
                    <MUPPExamEditor {...pageEditorProps} />
                )}

                {activityEntity.type === ActivityType.LaunchRandomSelection && (
                    <RandomSelectionGroupEditor {...pageEditorProps} />
                )}

                {activityEntity.type === ActivityType.LaunchBucketsAndCups && (
                    <BucketsAndCupsGroupPage {...pageEditorProps} />
                )}

                {activityEntity.type === ActivityType.LaunchAdaptiveEngine && (
                    <AdaptiveEngineEditor {...pageEditorProps} />
                )}
            </PageEditorContainer>
        </React.Fragment>
    )
}

const ModulePages = (props: {
    moduleVersionId: string
    showValidationError?: boolean
    locale: Locale
    editDisabled?: boolean
    moduleStatus?: ModuleStatus
}) => {
    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: props.moduleVersionId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    return (
        <>
            {moduleEntity.workflowIds.map((workflowId: string, workflowIndex: number) => {
                return (
                    <ModuleActivity
                        key={workflowId}
                        moduleVersionId={props.moduleVersionId}
                        workflowId={workflowId}
                        moduleLength={moduleEntity.workflowIds.length}
                        workflowIndex={workflowIndex}
                        locale={props.locale}
                        showValidationError={props.showValidationError}
                        editDisabled={props.editDisabled}
                        moduleStatus={props.moduleStatus}
                    />
                )
            })}
        </>
    )
}

function Notice({
    title,
    children,
    dataTestId,
}: {
    title: string
    dataTestId: string
    children?: ReactNode
}) {
    return (
        <>
            <Hr style={{ width: '100%' }} />
            <Container
                backgroundColor='neutral05'
                width='100%'
                paddingHorizontal={0}
                height='100vh'
                dataTestId={dataTestId}
            >
                <Col justifyContent='center'>
                    <Spacer height='15vh' />
                    <H3 textAlign='center'>{title}</H3>
                    <Text textAlign='center'>{children}</Text>
                </Col>
                <Spacer height='S300' />
            </Container>
        </>
    )
}

function PleaseNameModuleNotice() {
    return (
        <Notice title='Name this draft module.' dataTestId={'name-module-notice'}>
            Module names must be unique and <strong>cannot be changed</strong> once set.
            <br />
            Use the Module Naming Conventions defined by Talent Assessment and start your draft.
        </Notice>
    )
}

function MissingBasicDetailsNotice() {
    return (
        <Notice title='Please complete basic details' dataTestId={'missing-basic-details-notice'}>
            Before you create module content, choose your module type, user and layout.
        </Notice>
    )
}

function BasicDetailsPart({
    moduleId,
    showValidationError,
    locale,
    editDisabled,
}: {
    moduleId: string
    showValidationError?: boolean
    locale: Locale
    editDisabled: boolean
}) {
    const { entity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })
    const {
        matches: { s, m },
    } = useBreakpoints()
    const narrow = s || m

    const link = (
        // @ts-ignore: Expression produces a union type that is too complex to represent error
        <Link
            href='https://w.amazon.com/bin/view/CMAX/Amazon_Assessments/'
            target='_blank'
            icon={<IconExternalLink title='Opens in a new tab' />}
        >
            Assessments Authoring wiki
        </Link>
    )

    const fileTicketLink = (
        // @ts-ignore: Expression produces a union type that is too complex to represent error
        <Link
            href='https://t.corp.amazon.com/create/templates/d32b2f7a-14ec-453a-b606-eb66c4b314a5'
            target='_blank'
            icon={<IconExternalLink title='Opens in a new tab' />}
        >
            File a TT
        </Link>
    )

    return (
        <>
            <Container backgroundColor='neutral05' width='100%' paddingHorizontal={0}>
                <Spacer height='S500' />
                <Flex gridGap='S400' flexDirection={narrow ? 'column' : 'row'}>
                    <View flex='3'>
                        <ModuleBasicDetails
                            moduleId={moduleId}
                            locale={locale}
                            editDisabled={editDisabled}
                        />
                    </View>
                    <View
                        flex='1'
                        maxWidth={narrow ? '100%' : '340px'}
                        padding={{ left: 'S400', right: 'S400' }}
                    >
                        <Row alignItems='center' justifyContent='space-between' gridGap='S100'>
                            <Text>
                                <strong>Need support?</strong>
                            </Text>
                            <Text>{fileTicketLink}</Text>
                        </Row>
                        <Spacer height='S300' />
                        <Hr />
                        <Spacer height='S300' />
                        <Text>
                            If you have further questions or feature suggestions, please review the{' '}
                            {link}.
                        </Text>
                    </View>
                </Flex>
                <Spacer height='S500' />
            </Container>
            <Container backgroundColor='neutral05' width='100%' paddingHorizontal={0}>
                {entity.instructionalContentId && (
                    <FlyoutInstructions
                        instructionalContentId={entity.instructionalContentId}
                        showValidationError={showValidationError}
                        locale={locale}
                        moduleId={moduleId}
                        editDisabled={editDisabled}
                    />
                )}

                {entity.compositeScores?.length > 0 && (
                    <EvaluationScores
                        moduleId={moduleId}
                        locale={locale}
                        showValidationError={showValidationError}
                        editDisabled={editDisabled}
                    />
                )}

                {entity.mturkPaymentCodeItemId && (
                    <>
                        <CompletionCodeItemEditor
                            itemId={entity.mturkPaymentCodeItemId}
                            moduleId={moduleId}
                            locale={locale}
                            showValidationError={showValidationError}
                            editDisabled={editDisabled}
                        />
                    </>
                )}

                {entity.assessmentMetadata && (
                    <>
                        <ModuleAssessmentMetadata
                            moduleId={moduleId}
                            showValidationError={showValidationError}
                            editDisabled={editDisabled}
                        />
                    </>
                )}

                {entity.moduleTimerConfig && (
                    <>
                        <ModuleTimerEditor
                            moduleId={moduleId}
                            locale={locale}
                            showValidationError={showValidationError}
                            editDisabled={editDisabled}
                        />
                    </>
                )}
            </Container>
        </>
    )
}

function useOnModuleEntityChange(onChange: () => void, entityId: string) {
    const selector = MODULE_ENTITY_STORE_SELECTOR
    const store = useRef<Store<ModuleEntity>>(Stores.get(selector) as Store<ModuleEntity>)
    const subscription = useRef<Subscription | undefined>()
    const childSubscription = useRef<Subscription | undefined>()

    useEffect(() => {
        subscription.current = store.current.observeId(entityId).subscribe((next) => {
            if (
                next.action !== STORE_ACTION.FAILED_CREATE &&
                next.action !== STORE_ACTION.FAILED_UPDATE &&
                next.action !== STORE_ACTION.FAILED_DELETE
            ) {
                onChange()
            }
        })
        childSubscription.current = store.current.observeChildChanges(entityId).subscribe(() => {
            onChange()
        })

        return () => {
            subscription.current?.unsubscribe()
            childSubscription.current?.unsubscribe()
        }
    }, [entityId, selector, onChange])
}

export const ModuleBuilderPage = () => {
    const [moduleId, setModuleId] = useState<string>(
        () => ModuleEntityService.createBlank().version
    )
    const { entity: moduleEntity } = useEntity<ModuleEntity>({
        entityId: moduleId,
        selector: MODULE_ENTITY_STORE_SELECTOR,
    })

    useEffect(() => {
        console.log('ModuleBuilderPage:: moduleId: ', moduleId)
        console.log('ModuleBuilderPage:: moduleEntity: ', moduleEntity)
    }, [moduleId, moduleEntity])

    if (!ValidationErrorEntityService.has(moduleId)) {
        ValidationErrorEntityService.create(moduleId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: moduleId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const MODULE_VERSION_ID_PARAM_KEY = 'moduleVersionId'

    useTitle(() => 'Module Builder', [])
    const [locale, setLocale] = useState<Locale>(Locale.en_US)
    const [isDraftSaved, setIsDraftSaved] = useState(false)
    const [isSaveDraftError, setIsSaveDraftError] = useState(false)
    const [isDraftSaving, setIsDraftSaving] = useState(false)

    const [reviewStatus, setReviewStatus] = useState<ModuleReviewStatusDTO | undefined>(undefined)

    const [lastKnownSavedTimeToken, setLastKnownSavedTimeToken] = useState<number>(Date.now())
    const [author, setAuthor] = useState<string>(Authenticator.getDefaultUser())
    const [modifiedAt, setModifiedAt] = useState<Date>(new Date())
    const [moduleStatus, setModuleStatus] = useState(ModuleStatus.DRAFT_UNVALIDATED)
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
    const [isMigrated, setIsMigrated] = useState<boolean | undefined>(undefined)
    const [lockScoringEditing, setLockScoringEditing] = useState<boolean>(false)

    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)
    const [latestVersionIdWarning, setLatestVersionIdWarning] = useState<string | undefined>(
        undefined
    )

    useOnModuleEntityChange(() => {
        setHasUnsavedChanges(true)
    }, moduleId)

    const [showValidationResultsBanner, setShowValidationResultsBanner] = useState(false)

    const [validationErrorCount, setValidationErrorCount] = useState(0)

    const lastModified = useMemo(
        () => ({
            author: author,
            date: modifiedAt,
            status: moduleStatus,
        }),
        [author, modifiedAt, moduleStatus]
    )

    const [initialLoading, setInitialLoading] = useState(false)

    const queryParams = useQueryParams()
    const navigate = useNavigate()

    const handleAPIError = useCallback((e: unknown) => {
        console.error('API Error: ', e)
        setUnexpectedError(
            (e as ErrorResponseThrowable)?.errorResponse?.message ??
                'An unexpected error has occurred, please try again'
        )
    }, [])

    const checkLatestVersion = useCallback(async (moduleIdSearch: string, currentVersionId) => {
        const latestVersionId = await ModuleService.getNewestModuleVersion(moduleIdSearch)

        if (latestVersionId !== currentVersionId) {
            setLatestVersionIdWarning(latestVersionId)
        } else {
            setLatestVersionIdWarning(undefined)
        }
    }, [])

    const loadModuleVersion = useCallback(
        async (moduleVersionId: string) => {
            try {
                const moduleVersionDTO = await ModuleService.loadModuleVersionDTO(moduleVersionId)

                ModuleService.populateModule(moduleVersionDTO.content)

                setModuleId(moduleVersionDTO.versionId)
                setAuthor(moduleVersionDTO.author)
                setModifiedAt(moduleVersionDTO.updatedAt)
                setLastKnownSavedTimeToken(moduleVersionDTO.savedTimeToken)
                setModuleStatus(
                    moduleVersionDTO.archived
                        ? ModuleStatus.ARCHIVED
                        : ModuleStatus[moduleVersionDTO.status]
                )
                setReviewStatus(moduleVersionDTO.moduleReviewStatus)
                setIsDraftSaved(true)
                setLockScoringEditing(moduleVersionDTO.lockScoringEditing)

                console.log('****Loaded module version')

                if (
                    queryParams.get(MODULE_VERSION_ID_PARAM_KEY) &&
                    queryParams.get(MODULE_VERSION_ID_PARAM_KEY) !== moduleVersionDTO.versionId
                ) {
                    navigate(`/module-builder?moduleVersionId=${moduleVersionDTO.versionId}`)
                }

                await checkLatestVersion(moduleVersionDTO.id, moduleVersionDTO.versionId)

                return moduleVersionDTO
            } catch (e) {
                handleAPIError(e)
            }
        },
        [navigate, queryParams, handleAPIError, checkLatestVersion]
    )

    const saveModuleDTO = useCallback(
        async (message: string, moduleVersionId?: string, migrateMedia?: boolean) => {
            try {
                setUnexpectedError(undefined)
                setShowValidationResultsBanner(false)
                const dto = ModuleService.getFullModuleDTO(
                    ModuleService.serializeModuleDTO(moduleVersionId ?? moduleEntity.version)
                )
                console.log(JSON.stringify(dto, null, 2), message)

                // save the module
                const saveModuleResponse = await ModuleService.saveModuleVersion(
                    dto,
                    message,
                    lastKnownSavedTimeToken,
                    undefined,
                    migrateMedia
                )

                // Get the updated ModuleDTO together with status
                await loadModuleVersion(saveModuleResponse.versionId)

                // Update URL to reference to new or existing module versionId
                navigate(`/module-builder?moduleVersionId=${saveModuleResponse.versionId}`, {
                    replace: true,
                })

                return true
            } catch (e) {
                handleAPIError(e)
                return false
            }
        },
        [
            moduleEntity,
            lastKnownSavedTimeToken,
            loadModuleVersion,
            navigate,
            handleAPIError,
            setUnexpectedError,
        ]
    )

    const saveModuleDTOCallback = useCallback(
        async (message: string, migrateMedia?: boolean) => {
            return await saveModuleDTO(message, undefined, migrateMedia)
        },
        [saveModuleDTO]
    )

    const duplicateModule = useCallback(
        async (newModuleId: string, afterSaveHook: () => void) => {
            const oldModuleVersionId = moduleEntity.version
            if (await saveModuleDTO(`Duplicated from ${oldModuleVersionId}`, newModuleId)) {
                afterSaveHook()
                setInitialLoading(true)
                setHasUnsavedChanges(false)
            } else {
                afterSaveHook()
                setUnexpectedError('Unexpected error duplicating module, please try again.')
            }
        },
        [setHasUnsavedChanges, moduleEntity, saveModuleDTO]
    )

    const moduleVersionId = queryParams.get(MODULE_VERSION_ID_PARAM_KEY)

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.ModuleBuilder, CommonUserEventKeys.Open)
        if (moduleVersionId) {
            publishUserEventMetrics(
                UserEventMethodNames.ModuleBuilder,
                ModuleBuilderEventKeys.ContinueEditingModule
            )
        } else {
            publishUserEventMetrics(
                UserEventMethodNames.ModuleBuilder,
                ModuleBuilderEventKeys.CreateNewModule
            )
        }

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.ModuleBuilder, pageTimer.value)
        }
    }, [moduleVersionId])

    useEffect(() => {
        if (moduleVersionId) {
            ;(async function () {
                setInitialLoading(true)
                await loadModuleVersion(moduleVersionId)
                setInitialLoading(false)
            })().catch(handleAPIError)
        }
    }, [moduleVersionId, queryParams, loadModuleVersion, handleAPIError])

    const onErrorCheck = (_hasValidationError: boolean) => {
        setShowValidationResultsBanner(true)
        setValidationErrorCount(ErrorCheckService.getTotalModuleError(moduleEntity.version))
    }

    const newTheme = useMemo<typeof defaultTheme>(
        () => ({
            ...defaultTheme,
            constants: {
                ...defaultTheme.constants,
                maxWidth: 1108,
            },
        }),
        []
    )

    const hasModuleVersionId = (queryParams.get(MODULE_VERSION_ID_PARAM_KEY) ?? '').length > 0
    const missingBasicDetails =
        (!moduleEntity?.userType || !moduleEntity?.layout || !moduleEntity?.moduleType) &&
        (!moduleStatus || moduleStatus === ModuleStatus.DRAFT_UNVALIDATED)

    const filteredErrorList: string[] = useMemo(
        () => validationErrorEntity.validationErrors[GENERIC_ERROR_KEY],
        [validationErrorEntity.validationErrors]
    )

    // We don't have any specific input to highlight, so most of the errors on module level will go on top
    const topLevelErrors = useMemo(() => {
        return [
            ...(filteredErrorList || []),
            ...(validationErrorEntity.validationErrors.name || []),
            ...(validationErrorEntity.validationErrors.workflow || []),
            ...(validationErrorEntity.validationErrors.locales || []),
        ]
    }, [validationErrorEntity.validationErrors, filteredErrorList])

    const moduleBuilderContext = useMemo(
        () => ({
            moduleVersionId: moduleVersionId || '',
            moduleUsingMediaManager: !!moduleEntity.usingMediaManager,
            lockScoringEditing: lockScoringEditing,
        }),
        [moduleEntity, moduleVersionId, lockScoringEditing]
    )

    useEffect(() => {
        if (!moduleEntity.usingMediaManager) {
            setIsMigrated(false)
        }
    }, [moduleEntity.usingMediaManager])

    const [editDisabled, setEditDisabled] = useState(
        [ModuleStatus.TRANSLATION_REQUESTED, ModuleStatus.ARCHIVED].includes(moduleStatus)
    )

    useEffect(() => {
        setEditDisabled(
            [ModuleStatus.TRANSLATION_REQUESTED, ModuleStatus.ARCHIVED].includes(moduleStatus)
        )
    }, [moduleStatus])

    const onAdminOverride = () => {
        setEditDisabled(false)
    }

    return (
        <ModuleBuilderContext.Provider value={moduleBuilderContext}>
            <StencilProvider theme={newTheme}>
                {unexpectedError && (
                    <MessageBanner
                        type={MessageBannerType.Error}
                        dismissButtonAltText={'Hide errors'}
                        dataTestId={'unexpected-error-banner'}
                        isDismissible={true}
                        onDismissed={() => setUnexpectedError(undefined)}
                    >
                        {unexpectedError}
                    </MessageBanner>
                )}
                {latestVersionIdWarning && (
                    <MessageBanner
                        type={MessageBannerType.Warning}
                        dismissButtonAltText={'Hide errors'}
                        dataTestId={'latest-version-warning-banner'}
                        isDismissible={true}
                        onDismissed={() => setLatestVersionIdWarning(undefined)}
                    >
                        This is not the latest version of this module. To visit the latest version,
                        click{' '}
                        <RouterLink
                            to={`/module-builder?moduleVersionId=${latestVersionIdWarning}`}
                        >
                            <strong>here</strong>
                        </RouterLink>{' '}
                    </MessageBanner>
                )}
                {initialLoading ? (
                    <>
                        <Container backgroundColor='neutral0' minHeight='100vh'>
                            <Col gridGap='S200'>
                                <Row justifyContent='center' gridGap='S200' margin='S200'>
                                    <Spinner />
                                </Row>
                            </Col>
                        </Container>
                    </>
                ) : (
                    <>
                        <Col
                            backgroundColor='neutral0'
                            width='100%'
                            margin={0}
                            padding={0}
                            dataTestId={'module-builder-page'}
                        >
                            <ModuleMasthead
                                moduleId={moduleEntity.version}
                                moduleLastModified={{ ...lastModified }}
                                moduleStatus={moduleStatus}
                                saveModuleDTO={saveModuleDTOCallback}
                                reviewStatus={reviewStatus}
                                isDraftSaved={isDraftSaved}
                                setIsDraftSaved={setIsDraftSaved}
                                isDraftSaving={isDraftSaving}
                                setIsDraftSaving={setIsDraftSaving}
                                isMigrated={isMigrated}
                                setIsMigrated={setIsMigrated}
                                onErrorCheck={onErrorCheck}
                                onPreviewUnexpectedError={() =>
                                    setUnexpectedError(
                                        'An error has occurred while previewing the module. Please try again later.'
                                    )
                                }
                                loadModuleVersion={loadModuleVersion}
                                hasUnsavedChanges={hasUnsavedChanges}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                duplicateModule={duplicateModule}
                                setIsSaveDraftError={setIsSaveDraftError}
                                editDisabled={editDisabled}
                                onAdminOverride={onAdminOverride}
                            />
                        </Col>
                        {hasModuleVersionId ? (
                            <>
                                <LocaleSelector
                                    locale={locale}
                                    setLocale={setLocale}
                                    moduleId={moduleEntity.version}
                                    editDisabled={editDisabled}
                                />
                                {showValidationResultsBanner && (
                                    <Container
                                        backgroundColor='neutral05'
                                        width='100%'
                                        paddingHorizontal={0}
                                    >
                                        <Spacer height='S500' />
                                        <View aria-live='assertive'>
                                            {validationErrorCount > 0 ? (
                                                <MessageBanner
                                                    dataTestId={'validation-error-summary'}
                                                    type={MessageBannerType.Error}
                                                    dismissButtonAltText={'Hide errors'}
                                                    isDismissible
                                                    onDismissed={() =>
                                                        setShowValidationResultsBanner(false)
                                                    }
                                                >
                                                    There are {validationErrorCount} errors to
                                                    resolve
                                                </MessageBanner>
                                            ) : (
                                                <MessageBanner
                                                    dataTestId='module-generic-no-error'
                                                    type={MessageBannerType.Success}
                                                    dismissButtonAltText={'Dismiss'}
                                                    isDismissible
                                                    onDismissed={() =>
                                                        setShowValidationResultsBanner(false)
                                                    }
                                                >
                                                    <Text>There are no validation errors.</Text>
                                                </MessageBanner>
                                            )}
                                        </View>

                                        {topLevelErrors.length > 0 && (
                                            <>
                                                <Spacer height='S500' />
                                                <MessageBanner
                                                    dataTestId={'module-generic-error'}
                                                    type={MessageBannerType.Error}
                                                >
                                                    <ul>
                                                        {topLevelErrors.map((message, index) => (
                                                            <li key={index}>{message}</li>
                                                        ))}
                                                    </ul>
                                                </MessageBanner>
                                            </>
                                        )}
                                    </Container>
                                )}
                                <BasicDetailsPart
                                    moduleId={moduleEntity.version}
                                    showValidationError={showValidationResultsBanner}
                                    locale={locale}
                                    editDisabled={editDisabled}
                                />

                                {missingBasicDetails ? (
                                    <MissingBasicDetailsNotice />
                                ) : (
                                    <>
                                        <Container
                                            backgroundColor='neutral05'
                                            width='100%'
                                            paddingHorizontal={0}
                                        >
                                            <Row>
                                                <H2>Module content</H2>
                                            </Row>
                                            <Spacer height='S300' />
                                        </Container>

                                        <ModulePages
                                            moduleVersionId={moduleEntity.version}
                                            showValidationError={showValidationResultsBanner}
                                            locale={locale}
                                            editDisabled={editDisabled}
                                            moduleStatus={moduleStatus}
                                        />
                                        <Container
                                            backgroundColor='neutral05'
                                            width='100%'
                                            paddingHorizontal={0}
                                            minHeight='100vh'
                                        >
                                            <Spacer height='S500' />
                                            <EndOfModuleCard
                                                moduleEntityId={moduleEntity.version}
                                                editDisabled={editDisabled}
                                            />
                                        </Container>
                                    </>
                                )}
                                <ModuleFooter
                                    lastModified={lastModified}
                                    saveModuleDTO={saveModuleDTOCallback}
                                    moduleStatus={moduleStatus}
                                    isDraftSaved={isDraftSaved}
                                    setIsDraftSaved={setIsDraftSaved}
                                    isDraftSaving={isDraftSaving}
                                    setIsDraftSaving={setIsDraftSaving}
                                    hasUnsavedChanges={hasUnsavedChanges}
                                    setHasUnsavedChanges={setHasUnsavedChanges}
                                    isSaveDraftError={isSaveDraftError}
                                    setIsSaveDraftError={setIsSaveDraftError}
                                    editDisabled={editDisabled}
                                />
                            </>
                        ) : (
                            <PleaseNameModuleNotice />
                        )}
                    </>
                )}
            </StencilProvider>
        </ModuleBuilderContext.Provider>
    )
}
