import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'

import { publishApiCallMetrics, publishErrorEventMetrics } from 'src/metrics'
import { HookAuthenticator } from 'src/pages/scoring-test-ui/authentication/HookAuthenticator'
import { Authenticator } from './Authenticator'

export const MAX_RETRIES = 3
export const DEFAULT_API_TIMEOUT = 20000 // 20s for now, until we have better data on latency after load test
export const TIMEOUT_ERROR_MESSAGE = 'timeout' // 20s for now, until we have better data on latency after load test
export const VALIDATION_ERROR_STATUS_CODE = 422
const RETRY_DELAY = 100

// extend AxiosRequestConfig to have a requestTime and apiActionName attribute
declare module 'axios' {
    export interface AxiosRequestConfig {
        requestTime?: number
        apiActionName?: string
    }
}

export enum ApiActionNames {
    GetModuleHistory = 'GetModuleHistory',
    GetModuleVersion = 'GetModuleVersion',
    SaveModuleVersion = 'SaveModuleVersion',
    ValidateTemplate = 'ValidateTemplate',
    GetMyModules = 'GetMyModules',
    InitiateApprovalProcess = 'InitiateApprovalProcess',
    GetModuleReviewStatus = 'GetModuleReviewStatus',
    SearchModuleVersion = 'SearchModuleVersion',
    DeployModuleVersionPreview = 'DeployModuleVersionPreview',
    DeployModuleVersionUAT = 'DeployModuleVersionUAT',
    DeployModuleVersionProduction = 'DeployModuleVersionProduction',
    DeployModuleGroupVersionPreview = 'DeployModuleGroupVersionPreview',
    DeployModuleGroupVersionUAT = 'DeployModuleGroupVersionUAT',
    DeployModuleGroupVersionProduction = 'DeployModuleGroupVersionProduction',
    GenerateRankingItemPool = 'GenerateRankingItemPool',
    GenerateSlotPickerItemPool = 'GenerateSlotPickerItemPool',
    ExportRankingItemPool = 'ExportRankingItemPool',
    ExportSlotPickerItemPool = 'ExportSlotPickerItemPool',
    UploadMedia = 'UploadMedia',
    SearchMedia = 'SearchMedia',
    SearchMediaViaMediaManager = 'SearchMediaViaMediaManager',
    RequestTranslation = 'RequestTranslation',
    CreateMediaTemplate = 'CreateMediaTemplate',
    GetMediaTemplate = 'GetMediaTemplate',
    UpdateMediaTemplate = 'UpdateMediaTemplate',
    InitiateUATApprovalProcess = 'InitiateUATApprovalProcess',
    CreateItemPool = 'CreateItemPool',
    GetItemPool = 'GetItemPool',
    ExportItemPool = 'ExportItemPool',
    GetBucketsAndCupsCSV = 'GetBucketsAndCupsCSV',
    InitiatePreview = 'InitiatePreview',
    PreviewProgress = 'GetPreviewProgress',
    LaunchPreview = 'LaunchPreview',
    InitiateResearchWorkflow = 'InitiateResearchWorkflow',
    GetResearchWorkflowProgress = 'GetResearchWorkflowProgress',
    LaunchResearchWorkflow = 'LaunchResearchWorkflow',
    CompleteMediaTranslation = 'CompleteMediaTranslation',
    ExportModuleCSV = 'ExportModuleCSV',
    ExportModuleJSON = 'ExportModuleJSON',
    GetModuleGroupVersion = 'GetModuleGroupVersion',
    SaveModuleGroupVersion = 'SaveModuleGroupVersion',
    UpdateModuleGroupVersion = 'UpdateModuleGroupVersion',
    GetModuleGroup = 'GetModuleGroup',
    GetJobMetadata = 'GetJobMetadata',
    GetDiff = 'GetModuleDiff',
    ValidateModuleGroup = 'ValidateModuleGroup',
    ArchiveModule = 'ArchiveModule',
    ApprovalProcessAction = 'ApprovalProcessAction',
    GetModuleDifferences = 'GetModuleDifferences',
}

export enum ApprovalServiceApiActions {
    StartApprovalProcess = 'ApprovalService/StartProcess',
    GetRevision = 'ApprovalService/GetRevision',
    GetReview = 'ApprovalService/GetReview',
    CreateComment = 'ApprovalService/CreateComment',
    ListComment = 'ApprovalService/ListComment',
    ListApproval = 'ApprovalService/ListApproval',
    PutApproval = 'ApprovalService/PutApproval',
}

export enum ErrorMessages {
    GENERAL_ERROR = 'Something went wrong, please check your internet connection',
    GENERAL_MODULE_SAVE_ERROR = 'Something went wrong saving your module',
    GENERAL_ITEM_BANK_SAVE_ERROR = 'Something went wrong saving your item bank',
    GENERAL_ITEM_BANK_LOAD_ERROR = 'Something went wrong loading your item bank',
    GENERAL_PREVIEW_ERROR = 'Something went wrong previewing the information',
    GENERAL_LOCALE_SAVE_ERROR = 'Something went wrong adding locales to your module',
    GENERAL_MEDIA_UPLOAD_ERROR = 'Something went wrong uploading your file',
    GENERAL_MEDIA_SEARCH_ERROR = 'Something went wrong searching for media',
    GENERAL_GENERATING_PAIRING_TABLES_ERROR = 'Something went wrong generating pairing tables',
}

export async function retryAuth<T>(getValidUserSession: () => T | null) {
    const WAIT = 500
    const ATTEMPTS = 3
    let userSession: T | null = null
    for (let i = 0; !userSession && i < ATTEMPTS; i++) {
        userSession = getValidUserSession()
        if (userSession) {
            return userSession
        }
        await new Promise((resolve) => setTimeout(resolve, WAIT))
        console.log('retryAuth: ', i + 1)
    }

    if (!userSession) {
        throw new Error('User unauthenticated')
    }

    return userSession
}

const requestAuthInterceptor = async (config: AxiosRequestConfig) => {
    const userSession = await retryAuth(() => Authenticator.getValidUserSession())

    if (config.headers) {
        config.headers.Authorization = userSession?.getIdToken().getJwtToken()
    }

    return config
}

const requestHookAuthInterceptor = async (config: AxiosRequestConfig) => {
    const userSession = await retryAuth(() => HookAuthenticator.getValidUserSession())

    if (config.headers) {
        config.headers.Authorization = userSession?.getAccessToken().getJwtToken()
    }

    return config
}

const saveRequestTime = (config: AxiosRequestConfig) => {
    config.requestTime = new Date().getTime()
    return config
}

const publishApiMetric = (response: AxiosResponse<unknown>) => {
    if (response.config.apiActionName) {
        const timeNow = new Date().getTime()
        const requestTime = response.config.requestTime ?? timeNow
        publishApiCallMetrics(response.config.apiActionName, timeNow - requestTime)
    }
    return response
}

const publishApiErrorMetric = (error: AxiosError) => {
    if (error.message === TIMEOUT_ERROR_MESSAGE) {
        publishErrorEventMetrics(TIMEOUT_ERROR_MESSAGE)
    }

    const response: AxiosResponse | undefined = error.response
    const config = response?.config
    if (config) {
        const timeNow = new Date().getTime()
        const requestTime: number = config.requestTime ?? timeNow
        publishApiCallMetrics(
            config.apiActionName as string,
            timeNow - requestTime,
            true,
            response.status == VALIDATION_ERROR_STATUS_CODE
        )
    }
    return Promise.reject(error)
}

function initializeAxiosClient(): AxiosInstance {
    const instance = axios.create()
    instance.defaults.timeout = DEFAULT_API_TIMEOUT
    instance.defaults.timeoutErrorMessage = TIMEOUT_ERROR_MESSAGE

    // Request interceptors
    instance.interceptors.request.use(requestAuthInterceptor, Promise.reject)
    instance.interceptors.request.use(saveRequestTime, Promise.reject)

    // Response interceptors
    instance.interceptors.response.use(publishApiMetric, publishApiErrorMetric)

    axiosRetry(instance, {
        retries: MAX_RETRIES,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: isNetworkOrIdempotentRequestError,
    })

    return instance
}

function initializeHookAxiosClient(): AxiosInstance {
    const adminUiAxiosInstance = axios.create()
    adminUiAxiosInstance.interceptors.request.use(requestHookAuthInterceptor, Promise.reject)

    axiosRetry(adminUiAxiosInstance, {
        retries: MAX_RETRIES,
        retryDelay: () => RETRY_DELAY,
        retryCondition: isNetworkOrIdempotentRequestError,
    })

    return adminUiAxiosInstance
}

export const ADAxios: AxiosInstance = initializeAxiosClient()
export const hookBackendAxiosClient: AxiosInstance = initializeHookAxiosClient()
