import { cloneDeep } from 'lodash'
import { v4 } from 'uuid'

import { AdaptiveEngineSelectionGroupEntity } from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import { BucketsAndCupsGroupGroupEntity } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import { MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import { RandomSelectionGroupEntity } from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityEntity, ActivityType } from 'src/models/dto/ActivityDTO'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { Store, STORE_ACTION } from '../Store'

export const ACTIVITY_ENTITY_STORE_SELECTOR = 'ActivityEntity'

/**
 *
 * @returns base parameters of an ActivityEntity with a randomly generated itemVersionId
 */
export const activity = (): Omit<ActivityEntity, 'type'> => {
    const newId = v4()
    return {
        id: newId,
        ppt: '', // legacy field, we don't use this in the BE
    }
}

/**
 * A Map of factories for generating default ActivityEntity by specific type
 */
export const factories = new Map<ActivityType, () => ActivityEntity>()

export class ActivityEntityService {
    static store: Store<ActivityEntity>

    static init() {
        this.store = new Store<ActivityEntity>(ACTIVITY_ENTITY_STORE_SELECTOR)
    }

    static get(entityId: string): ActivityEntity {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static create(
        type = ActivityType.LaunchItemGroup
    ):
        | ActivityEntity
        | ItemGroupEntity
        | RandomSelectionGroupEntity
        | MUPPExamEntity
        | BucketsAndCupsGroupGroupEntity
        | AdaptiveEngineSelectionGroupEntity {
        const factory = factories.get(type)
        let entity: ActivityEntity

        if (factory) {
            entity = factory()
        } else {
            entity = {
                ...activity(),
                type,
                ppt: type,
            }
        }
        entity.isNewActivity = true
        this.insert(entity)
        return entity
    }

    static preformAction(entity: ActivityEntity, action: STORE_ACTION) {
        this.store.dispatch({
            action: action,
            entityId: entity.id,
            payload: entity,
        })
    }

    static insert(entity: ActivityEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static remove(entityId: string) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_DELETE,
            entityId,
            payload: null,
        })
    }

    /**
     * Duplicates an entity with a new randomly generated id
     * @param entityId
     * @returns
     */
    static duplicate(entityId: string): ActivityEntity {
        const entity = this.store.get(entityId)

        const newId = v4()
        const duplicate = { ...cloneDeep(entity), id: newId, name: newId }

        if (entity.type === ActivityType.LaunchItemGroup) {
            const itemGroup: ItemGroupEntity = duplicate as ItemGroupEntity
            itemGroup.itemIds = ItemEntityService.duplicateItems(itemGroup.itemIds).map((i) => i.id)
        }

        this.insert(duplicate)
        return duplicate
    }
}
