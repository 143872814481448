import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import useAxios from 'axios-hooks'
import _ from 'lodash'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import {
    TableElement,
    TbodyElement,
    TdElement,
    TheadElement,
    ThElement,
    TrElement,
} from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { MediaPreviewModal } from 'src/components/MediaContentsEditor/MediaPreviewModal'
import { APP_CONFIG } from 'src/config.app'
import { MediaManagerMedia, MediaTemplateVersionIds } from 'src/models/dto/items/MediaDTO'
import { Locale } from 'src/models/dto/Locale'
import { MediaTemplateDTO } from 'src/models/media/MediaTemplateDTO'
import { ApiActionNames } from 'src/services/AxiosInterceptor'

function DeleteMediaButton({
    index,
    deleteRow,
    disabled,
}: {
    index: number
    deleteRow: () => void
    disabled?: boolean
}) {
    const { theme } = useContext(StencilContext)
    const red = theme.color.red70

    const renderModal = useCallback(
        ({ close }: { close: () => void }) => {
            return (
                <ModalContent
                    dataTestId='delete-media-modal'
                    titleText={`Delete Media ${index + 1}`}
                    buttons={[
                        <Button
                            key='delete-media'
                            isDestructive
                            dataTestId='yes-delete-media-button'
                            onClick={(e) => {
                                e.preventDefault()
                                deleteRow()
                                close()
                            }}
                        >
                            Yes, delete media
                        </Button>,
                        <Button key='close-media' onClick={close} variant={ButtonVariant.Primary}>
                            No, keep media
                        </Button>,
                    ]}
                >
                    <Col gridGap='S400'>
                        <Text>
                            Do you want to remove the media at row {index + 1}? This cannot be
                            undone.
                        </Text>
                    </Col>
                </ModalContent>
            )
        },
        [index, deleteRow]
    )

    return (
        <Row justifyContent='center'>
            <WithModal renderModal={renderModal}>
                {({ open }) => {
                    return (
                        <Button
                            key='delete-media-button'
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                            isDestructive
                            dataTestId='delete-media-file'
                            onClick={(e) => {
                                e.preventDefault()
                                open()
                            }}
                            color={red}
                            aria-disabled={disabled}
                        >
                            Delete
                        </Button>
                    )
                }}
            </WithModal>
        </Row>
    )
}

const centerAlign = {
    textAlign: 'center',
} as const

const MediaRow = (props: {
    media: MediaManagerMedia
    index: number
    deleteMedia(): void
    setMediaTemplateVersionIds(newValue: MediaTemplateVersionIds): void
    disabled?: boolean
}) => {
    const { media, deleteMedia, index, setMediaTemplateVersionIds } = props
    const { mediaTemplateVersionIds, mediaManagerVersionId: mediaVersionId } = media
    const [{ data, loading, error }] = useAxios<MediaTemplateDTO & { versionId: string }>(
        {
            method: 'GET',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media-templates/${mediaVersionId}`,
            apiActionName: ApiActionNames.GetMediaTemplate,
        },
        {
            useCache: false,
        }
    )

    useEffect(() => {
        if (!data || loading || error) {
            return
        }

        const deliveryVersionIds: MediaTemplateVersionIds = {}

        ;(Object.keys(data.languages || {}) as Locale[]).forEach((language: Locale) => {
            const languageTemplateVersionId = data.languages[language]?.languageTemplateVersionId

            if (languageTemplateVersionId) {
                deliveryVersionIds[language] = {
                    versionId: languageTemplateVersionId,
                }
            }
        })

        if (_.isEqual(mediaTemplateVersionIds, deliveryVersionIds)) {
            return
        }

        setMediaTemplateVersionIds(deliveryVersionIds)
    }, [loading, data, error, setMediaTemplateVersionIds, mediaTemplateVersionIds])

    const deletePart = (
        <TdElement>
            <DeleteMediaButton index={index} deleteRow={deleteMedia} disabled={props.disabled} />
        </TdElement>
    )

    if (error) {
        return (
            <TrElement data-test-id='media-files-table-row-error'>
                <TdElement colSpan={3}>
                    <MessageBanner type={MessageBannerType.Error}>
                        Error fetching media
                    </MessageBanner>
                </TdElement>
                {deletePart}
            </TrElement>
        )
    }

    if (loading || !data) {
        return (
            <TrElement data-test-id='media-files-table-row-loading'>
                <TdElement colSpan={3}>
                    <Row width='100%' justifyContent='center' alignItems='center'>
                        <Spinner aria-label='Media information is being loaded' />
                    </Row>
                </TdElement>
                {deletePart}
            </TrElement>
        )
    }

    return (
        <TrElement data-test-id='media-files-table-row'>
            <TdElement>{data.title}</TdElement>
            <TdElement>{data.fileType}</TdElement>
            <TdElement>
                <Row justifyContent='center'>
                    <MediaPreviewModal mediaVersionId={mediaVersionId} />
                </Row>
            </TdElement>
            {deletePart}
        </TrElement>
    )
}

export interface MediaFilesTableV2Props {
    mediaList: MediaManagerMedia[]
    addMediaEntry(media: MediaManagerMedia): void
    deleteMediaEntry(index: number): void
    updateMediaTemplateVersionIds(
        index: number,
        mediaTemplateVersionIds: MediaTemplateVersionIds
    ): void
    tableAriaLabel?: string
    disabled?: boolean
}

const MediaFilesTableV2Inner = ({
    mediaList,
    deleteMediaEntry,
    updateMediaTemplateVersionIds,
    tableAriaLabel,
    disabled,
}: MediaFilesTableV2Props) => {
    const [deletes, increment] = useReducer((x: number) => x + 1, 0)
    const versionIds = new Map<string, number>()
    function getMediaKey(media: MediaManagerMedia) {
        const key = media.mediaManagerVersionId
        versionIds.set(key, (versionIds.get(key) ?? 0) + 1)
        return `${media.mediaManagerVersionId}-${versionIds.get(key) ?? ''}-${deletes}`
    }

    const rows = mediaList.map((media, index) => (
        <MediaRow
            key={getMediaKey(media)}
            index={index}
            media={media}
            disabled={disabled}
            deleteMedia={() => {
                deleteMediaEntry(index)
                increment()
            }}
            setMediaTemplateVersionIds={(newValue: MediaTemplateVersionIds) =>
                updateMediaTemplateVersionIds(index, newValue)
            }
        />
    ))

    if (mediaList.length === 0) {
        return null
    }

    return (
        <TableElement data-test-id='media-files-table' aria-label={tableAriaLabel}>
            <TheadElement>
                <TrElement>
                    <ThElement>Media Title</ThElement>
                    <ThElement>File Type</ThElement>
                    <ThElement style={centerAlign}>Preview</ThElement>
                    <ThElement style={centerAlign}>Delete</ThElement>
                </TrElement>
            </TheadElement>
            <TbodyElement>{rows}</TbodyElement>
        </TableElement>
    )
}

export const MediaFilesTableV2 = React.memo(MediaFilesTableV2Inner)
