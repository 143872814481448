import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { IconChevronRight } from '@amzn/stencil-react-components/icons'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ItemBankDTO, MUPPDichotomousItemDTO } from 'src/models/dto/mupp/MUPPItemBankMetadataDTO'
import { MUPPItemBankItemTable } from 'src/pages/module-builder/mupp-exam-editor/MUPPItemBankItemTable'
import { MUPPItemBankService } from 'src/services/mupp/MUPPItemBankService'

export const listRows = (list: string[]) => {
    const result: JSX.Element[] = []
    let rowCols: JSX.Element[] = []
    let count = 0

    while (count < list.length) {
        rowCols.push(
            <Col key={list[count]}>
                <Row alignItems='center'>
                    <IconChevronRight aria-hidden={true} key={`${list[count]}-label`} />{' '}
                    <Text
                        fontSize='T200'
                        dataTestId={`${list[count]}-available`}
                        key={`${list[count]}-text`}
                    >
                        {list[count]}
                    </Text>
                </Row>
            </Col>
        )

        count++
        if (count % 3 === 0 || count === list.length) {
            result.push(
                <Row gridGap={10} key={`${list[count]}-row`}>
                    {rowCols}
                </Row>
            )
            rowCols = []
        }
    }

    return <Col>{result}</Col>
}

export interface ItemBankDetailsModalProps {
    itemBankId: string
    isOpen: boolean
    close: (value: boolean) => void
}

export const ItemBankDetailsModal = (props: ItemBankDetailsModalProps) => {
    const [itemBank, setItemBank] = useState<ItemBankDTO>()
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingItemBankErrorMessage, setLoadingItemBankErrorMessage] = useState<string>('')
    const [itemBankStatements, setItemBankStatements] = useState<
        Map<string, MUPPDichotomousItemDTO>
    >(new Map())
    useEffect(() => {
        setLoading(true)
        let componentUnmounted = false
        const source = axios.CancelToken.source()

        const fetchData = async () => {
            const res = await MUPPItemBankService.getItemBankDTO(props.itemBankId, source.token)
            if (componentUnmounted) {
                return
            }
            setItemBank(res)
            const map = new Map(res.catItems.map((i) => [i.version, i]))
            setItemBankStatements(map)
            setLoadingItemBankErrorMessage('')
            setLoading(false)
        }
        fetchData().catch((err: ErrorResponseThrowable) => {
            if (componentUnmounted) {
                return
            }
            setLoadingItemBankErrorMessage(
                err.errorResponse?.message || 'Something went wrong loading your item bank'
            )
            setLoading(false)
        })

        return () => {
            componentUnmounted = true
            source.cancel()
        }
    }, [props.itemBankId])

    return (
        <Modal
            isOpen={props.isOpen}
            close={() => props.close(false)}
            shouldCloseOnClickOutside={false}
        >
            <ModalContent
                maxWidth='100vw'
                titleText={'Item Bank Details'}
                buttons={[
                    <Button
                        key='close-button'
                        dataTestId={'close-button'}
                        onClick={() => props.close(false)}
                    >
                        close
                    </Button>,
                ]}
            >
                {loadingItemBankErrorMessage !== '' && (
                    <MessageBanner
                        type={MessageBannerType.Error}
                        dataTestId={'item-bank-details-error'}
                    >
                        Error loading item bank: {loadingItemBankErrorMessage}
                    </MessageBanner>
                )}
                {loading && (
                    <Spinner
                        loadingText={'Loading'}
                        dataTestId={'item-bank-manager-page-spinner'}
                    />
                )}

                {itemBank !== undefined && (
                    <Col flex={1} gridGap={32}>
                        <Col flex={1}>
                            <InputWrapper id={'item-bank-description-input'} labelText={'Name'}>
                                {(inputProps) => (
                                    <Input
                                        dataTestId='item-bank-description-input'
                                        disabled={true}
                                        {...inputProps}
                                        value={itemBank.description}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1}>
                            <InputWrapper id={'item-bank-id-input'} labelText={'ID'}>
                                {(inputProps) => (
                                    <Input
                                        dataTestId='item-bank-id-input'
                                        disabled={true}
                                        {...inputProps}
                                        value={itemBank.id}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1}>
                            <InputWrapper id={'item-bank-version-id-input'} labelText={'Version'}>
                                {(inputProps) => (
                                    <Input
                                        dataTestId='item-bank-version-id-input'
                                        disabled={true}
                                        {...inputProps}
                                        value={itemBank.version}
                                    />
                                )}
                            </InputWrapper>
                        </Col>

                        <Row flex={1} gridGap={30}>
                            <Col>
                                <Row gridGap={20}>
                                    <Text fontSize='T200'>Available Locales:</Text>
                                    {listRows(itemBank.availableLocales)}
                                </Row>
                            </Col>

                            <Col>
                                <Row gridGap={20}>
                                    <Text fontSize='T200'>Available Dimensions:</Text>
                                    {listRows(itemBank.availableDimensions)}
                                </Row>
                            </Col>
                        </Row>

                        <MUPPItemBankItemTable itemBank={itemBank} items={itemBankStatements} />
                    </Col>
                )}
            </ModalContent>
        </Modal>
    )
}
