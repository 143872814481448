import React from 'react'

import {
    Button as StencilButton,
    ButtonBaseProps,
    ButtonProps,
} from '@amzn/stencil-react-components/button'

// Workaround for TypeScript error on buttons
export const Button: React.FC<
    { dataTestId?: string } & ButtonProps & Omit<ButtonBaseProps, 'theme'>
> = StencilButton as never
