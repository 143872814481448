import React from 'react'

import { HarveyBallValue2 } from 'src/components/CustomIcons/HarveyBall'
import { BaseTableDTO, TableIconType } from 'src/models/dto/items/BaseTableDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    LegendType,
    TableScaleHarveyBallScaleType,
} from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'

export function getHarveyBallLegend({
    itemId,
    baseTableDTO,
    tableOperations,
    locale,
}: {
    itemId: string
    baseTableDTO: BaseTableDTO
    tableOperations: TableOperations<BaseTableDTO>
    locale: Locale
}) {
    return {
        enabled: baseTableDTO.harveyBallLegendEnabled,
        setEnabled: (enabled: boolean) => {
            tableOperations.toggleHarveyBallLegend(itemId, enabled)
        },
        legendType: LegendType.HARVEY_BALL,
        legendHumanReadableName: 'Harvey Ball',
        legendDataTestId: 'harvey-ball-checkbox',
        scales: baseTableDTO.harveyBallLegendEnabled
            ? [
                  {
                      scaleType: TableScaleHarveyBallScaleType.HarveyBallEmpty,
                      insetElementLeading: (
                          <HarveyBallValue2 type={TableScaleHarveyBallScaleType.HarveyBallEmpty} />
                      ),
                      value: baseTableDTO.legends.find(
                          (l) => l.iconType === TableIconType.HARVEY_BALL_EMPTY
                      )?.textI18N[locale],
                      scaleDataTestId: 'harvey-ball-empty-scale-input',
                  },
                  {
                      scaleType: TableScaleHarveyBallScaleType.HarveyBallHalfFull,
                      insetElementLeading: (
                          <HarveyBallValue2
                              type={TableScaleHarveyBallScaleType.HarveyBallHalfFull}
                          />
                      ),
                      value: baseTableDTO.legends.find(
                          (l) => l.iconType === TableIconType.HARVEY_BALL_HALF_FULL
                      )?.textI18N[locale],
                      scaleDataTestId: 'harvey-ball-half-full-scale-input',
                  },
                  {
                      scaleType: TableScaleHarveyBallScaleType.HarveyBallFull,
                      insetElementLeading: (
                          <HarveyBallValue2 type={TableScaleHarveyBallScaleType.HarveyBallFull} />
                      ),
                      value: baseTableDTO.legends.find(
                          (l) => l.iconType === TableIconType.HARVEY_BALL_FULL
                      )?.textI18N[locale],
                      scaleDataTestId: 'harvey-ball-full-scale-input',
                  },
              ]
            : [],
        onScaleChange: (scaleType, value: string) => {
            switch (scaleType) {
                case TableScaleHarveyBallScaleType.HarveyBallEmpty:
                    tableOperations.setScaleName(
                        itemId,
                        TableIconType.HARVEY_BALL_EMPTY,
                        locale,
                        value
                    )
                    break
                case TableScaleHarveyBallScaleType.HarveyBallHalfFull:
                    tableOperations.setScaleName(
                        itemId,
                        TableIconType.HARVEY_BALL_HALF_FULL,
                        locale,
                        value
                    )
                    break
                case TableScaleHarveyBallScaleType.HarveyBallFull:
                    tableOperations.setScaleName(
                        itemId,
                        TableIconType.HARVEY_BALL_FULL,
                        locale,
                        value
                    )
                    break
            }
        },
    }
}
