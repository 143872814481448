import React from 'react'

import { Flex, FlexProps } from '@amzn/stencil-react-components/layout'

export function ModuleReviewButtonsWrapper({ children }: { children: FlexProps['children'] }) {
    return (
        <Flex
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='flex-end'
            gridGap='S300'
            style={{ minHeight: 44 }}
        >
            {children}
        </Flex>
    )
}
