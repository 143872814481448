import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout'
import { Checkbox, Input, InputWrapper, LabelPosition } from '@amzn/stencil-react-components/form'
import { IconSearch, IconSettings } from '@amzn/stencil-react-components/icons'
import { Col, Container, Flex, Hr, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { SortDirection, TableColumn } from '@amzn/stencil-react-components/table'
import { H2, H4, Label } from '@amzn/stencil-react-components/text'

import { ModuleDisplayTable } from 'src/components/ModuleDisplayTable'
import { FILTER_DATA_IDS, ModuleListFilter } from 'src/components/ModuleListFilter'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { ModuleType, QueryParams } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import {
    ModuleListFilters,
    ModuleListSortCriteria,
    PaginationConfig,
} from 'src/models/ListModulesRequest'
import { ModuleMetaData, SearchResultResponse } from 'src/models/SearchResultResponse'
import { SearchService } from 'src/services/backend/SearchService'

const DEFAULT_PAGE_SIZE = 10

export enum LAYOUT_DIRECTION {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}

export const useLayoutDirection = () => {
    const { matches } = useBreakpoints()

    if (matches.s) {
        return LAYOUT_DIRECTION.MOBILE
    }

    if (matches.m) {
        return LAYOUT_DIRECTION.TABLET
    }

    return LAYOUT_DIRECTION.DESKTOP
}

const pageTimer = new TimerStopwatch('SearchResultPageTimer')

export const SearchResultsPage = ({
    embeddedInModal,
    lockedModuleStatus,
    selectModuleAction,
    disabledFilters,
    lockedModuleTypes,
    noTypeColumn,
}: {
    embeddedInModal?: boolean
    lockedModuleStatus?: ModuleStatus[]
    selectModuleAction?: TableColumn<ModuleMetaData>
    disabledFilters?: FILTER_DATA_IDS[]
    lockedModuleTypes?: ModuleType[]
    noTypeColumn?: boolean
}) => {
    const [search, setSearch] = useState<string>('')
    const [latestSearchTerm, setLatestSearchTerm] = useState<string>(search)
    const [loadingResults, setLoadingResults] = useState<boolean>(false)
    const [owner, setOwner] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [lastModifiedBy, setLastModifiedBy] = useState<string>('')
    const [searchArchived, setSearchArchived] = useState<boolean>(false)
    const [syncFilter, setSyncFilter] = useState<boolean>(false)
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<number>(DEFAULT_PAGE_SIZE)
    const [itemOffset, setItemOffset] = useState<number>(0)
    const [sortCriteria, setSortCriteria] = useState<ModuleListSortCriteria>({
        sortField: 'modifiedAt',
        sortDirection: SortDirection.Descending,
    })

    const [moduleTypes, setModuleTypes] = useState<ModuleType[]>(lockedModuleTypes ?? [])

    const [moduleStatus, setModuleStatus] = useState<ModuleStatus[]>(lockedModuleStatus ?? [])

    const [searchResults, setSearchResults] = useState<SearchResultResponse>(
        {} as SearchResultResponse
    )
    const layout = useLayoutDirection()
    const [hasLoadedResults, setHasLoadedResults] = useState(true)

    const callSearchAPI = useCallback(
        async (
            offset = itemOffset,
            numberOfRecordsPerPage = numberOfItemsPerPage,
            moduleSortCriteria?: ModuleListSortCriteria,
            archived?: boolean
        ) => {
            setLoadingResults(true)
            const filters: ModuleListFilters = {
                owner,
                lastModifiedBy,
                startDate,
                endDate,
                moduleTypes,
                moduleStatuses: moduleStatus,
            }

            const pageConfig: PaginationConfig = {
                offset,
                numberOfRecordsPerPage,
            }

            const response: SearchResultResponse = await SearchService.searchModules(
                search,
                filters,
                pageConfig,
                moduleSortCriteria ?? sortCriteria,
                undefined,
                archived ?? searchArchived
            )

            if (!embeddedInModal) {
                setUrlSearchParams(
                    SearchService.constructQueryString(
                        search,
                        archived ?? searchArchived,
                        filters,
                        pageConfig,
                        moduleSortCriteria ?? sortCriteria,
                        true
                    )
                )
            }
            setLatestSearchTerm(search)
            setSearchResults(response)
            setLoadingResults(false)
        },
        [
            itemOffset,
            numberOfItemsPerPage,
            owner,
            lastModifiedBy,
            startDate,
            endDate,
            moduleTypes,
            moduleStatus,
            search,
            sortCriteria,
            searchArchived,
            embeddedInModal,
            setUrlSearchParams,
        ]
    )

    useEffect(() => {
        if (embeddedInModal) return
        const currentOffsetSearchParam = urlSearchParams.get(QueryParams.OFFSET) ?? undefined
        const currentPageSizeSearchParam =
            urlSearchParams.get(QueryParams.RECORDS_PER_PAGE) ?? undefined
        const urlSortField = urlSearchParams.get(QueryParams.SORT_FIELD) ?? 'modifiedAt'
        const urlSortDirection =
            urlSearchParams.get(QueryParams.SORT_DIRECTION) === SortDirection.Ascending.toString()
                ? SortDirection.Ascending
                : SortDirection.Descending
        setSearch(urlSearchParams.get(QueryParams.SEARCH) ?? '')
        setStartDate(urlSearchParams.get(QueryParams.START_DATE) ?? '')
        setEndDate(urlSearchParams.get(QueryParams.END_DATE) ?? '')
        setOwner(urlSearchParams.get(QueryParams.OWNER) ?? '')
        setLastModifiedBy(urlSearchParams.get(QueryParams.LAST_MODIFIED_BY) ?? '')
        setModuleStatus((urlSearchParams.getAll(QueryParams.STATUS) as ModuleStatus[]) ?? [])
        setModuleTypes((urlSearchParams.getAll(QueryParams.TYPE) as ModuleType[]) ?? [])
        setSearchArchived(urlSearchParams.get(QueryParams.ARCHIVED) === 'true')
        setItemOffset(currentOffsetSearchParam ? +currentOffsetSearchParam : 0)
        setNumberOfItemsPerPage(
            currentPageSizeSearchParam ? +currentPageSizeSearchParam : DEFAULT_PAGE_SIZE
        )
        setSortCriteria({ sortField: urlSortField, sortDirection: urlSortDirection })
        setSyncFilter(true)
    }, [embeddedInModal, urlSearchParams])

    const resultTableRef = useRef<HTMLDivElement | null>(null)

    const searchThenFocus = useCallback(
        async (
            offset = 0,
            numberOfRecordsPerPage = numberOfItemsPerPage,
            moduleSortCriteria?: ModuleListSortCriteria
        ) => {
            await callSearchAPI(offset, numberOfRecordsPerPage, moduleSortCriteria)
            resultTableRef?.current?.focus()
        },
        [resultTableRef, callSearchAPI, numberOfItemsPerPage]
    )

    const handleSortCriteriaChange = async (
        sortField: 'status' | 'moduleName' | 'moduleType' | 'modifiedAt',
        sortDirection: SortDirection,
        offset: number,
        numberOfRecordsPerPage: number
    ) => {
        setSortCriteria({ sortField, sortDirection })
        await callSearchAPI(offset, numberOfRecordsPerPage, { sortField, sortDirection })
    }

    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.SearchResult, CommonUserEventKeys.Open)

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.SearchResult, pageTimer.value)
        }
    }, [])

    useEffect(() => {
        if (containerRef) {
            containerRef.current?.focus()
        }
    }, [containerRef])

    useEffect(() => {
        if (!hasLoadedResults) {
            ;(async function () {
                await callSearchAPI()
                setHasLoadedResults(true)
            })().catch(console.error)
        }
    }, [callSearchAPI, hasLoadedResults])

    useEffect(() => {
        setHasLoadedResults(false)
        setSyncFilter(true)
    }, [])

    const renderFlyout = ({ close }: { close: () => void }) => (
        <FlyoutContent titleText='Filters' onCloseButtonClick={close}>
            <ModuleListFilter
                owner={owner}
                lastModifiedBy={lastModifiedBy}
                startDate={startDate}
                endDate={endDate}
                moduleTypes={moduleTypes}
                moduleStatus={moduleStatus}
                syncState={syncFilter}
                updateOwner={setOwner}
                updateLastModifiedBy={setLastModifiedBy}
                updateStartDate={setStartDate}
                updateEndDate={setEndDate}
                updateModuleTypes={setModuleTypes}
                updateModuleStatus={setModuleStatus}
                lockedModuleStatus={lockedModuleStatus}
                updateSyncState={setSyncFilter}
                applyFilters={() => {
                    setItemOffset(0)
                    setHasLoadedResults(false)
                    setSyncFilter(true)
                    close()
                }}
            />
        </FlyoutContent>
    )

    const displaySearchResults = () => {
        if (loadingResults) {
            return (
                <View display='flex' justifyContent='center' width='100%' alignItems='center'>
                    <Spinner size={SpinnerSize.Large} dataTestId={'module-search-data-spinner'} />
                </View>
            )
        }

        // No search results
        if (!searchResults.data || searchResults.data.length === 0) {
            return (
                <View display='flex' justifyContent='center' width='100%' alignItems='center'>
                    <H4 color='neutral70' textAlign='center' data-no-search-results>
                        {`No results found for "${latestSearchTerm}"`} <br />{' '}
                        {'Please search again.'}
                    </H4>
                </View>
            )
        }

        // Display search results
        return (
            <View tabIndex={-1} ref={resultTableRef}>
                <ModuleDisplayTable
                    searchResults={searchResults}
                    searchTerm={latestSearchTerm}
                    offset={itemOffset}
                    onUpdateNumberOfItemsPerPage={setNumberOfItemsPerPage}
                    onPageChange={async (offset: number, numberOfRecordsPerPage: number) => {
                        setItemOffset(offset)
                        await callSearchAPI(offset, numberOfRecordsPerPage)
                    }}
                    onSortCriteriaChange={handleSortCriteriaChange}
                    sortCriteria={sortCriteria}
                    embeddedInModal={embeddedInModal}
                    selectModuleAction={selectModuleAction}
                    noTypeColumn={noTypeColumn}
                />
            </View>
        )
    }

    return (
        <Container
            ref={containerRef}
            backgroundColor='#F8F8F8'
            width='100%'
            minHeight='100vh'
            paddingHorizontal={0}
        >
            <Col gridGap='S300' padding='15px'>
                <Label htmlFor='module-search-input'>
                    <H2>Search</H2>
                </Label>
                <Flex
                    gridGap='16px'
                    flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                >
                    <Input
                        id='module-search-input'
                        key={'module-search-input'}
                        data-test-id='module-search-input'
                        name='Module Search Input'
                        width={layout === LAYOUT_DIRECTION.MOBILE ? '100%' : '50%'}
                        defaultValue={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setItemOffset(0)
                                setHasLoadedResults(false)
                            }
                        }}
                        insetElementLeading={{
                            element: <IconSearch aria-hidden={true} color='neutral60' />,
                        }}
                    />
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={async () => {
                            setItemOffset(0)
                            await searchThenFocus()
                        }}
                        aria-label='Search'
                        data-test-id='search-button'
                    >
                        Search
                    </Button>
                </Flex>
                <ResponsiveRow>
                    <InputWrapper
                        id='searched-archived-input'
                        labelText={'Search archived modules'}
                        labelPosition={LabelPosition.Trailing}
                    >
                        {(inputProps) => (
                            <Checkbox
                                {...inputProps}
                                checked={searchArchived}
                                onChange={(e) => {
                                    setItemOffset(0)
                                    setSearchArchived(e.target.checked)
                                    setHasLoadedResults(false)
                                }}
                            />
                        )}
                    </InputWrapper>
                </ResponsiveRow>
                <Hr size='narrow' color='#B9C0C8' />
                {searchArchived && (
                    <MessageBanner type={MessageBannerType.Informational}>
                        You are searching archived modules. Only archived modules will appear.
                    </MessageBanner>
                )}
                <Flex
                    gridGap='16px'
                    flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                >
                    {layout === LAYOUT_DIRECTION.MOBILE ? (
                        <WithFlyout renderFlyout={renderFlyout}>
                            {({ open }) => (
                                <Button
                                    variant={ButtonVariant.Secondary}
                                    icon={<IconSettings aria-hidden={true} />}
                                    onClick={() => {
                                        setSyncFilter(true)
                                        open()
                                    }}
                                >
                                    Filters
                                </Button>
                            )}
                        </WithFlyout>
                    ) : (
                        <Col width='30%'>
                            <ModuleListFilter
                                title={'Filters'}
                                owner={owner}
                                lastModifiedBy={lastModifiedBy}
                                startDate={startDate}
                                endDate={endDate}
                                moduleTypes={moduleTypes}
                                moduleStatus={moduleStatus}
                                syncState={syncFilter}
                                updateOwner={setOwner}
                                updateLastModifiedBy={setLastModifiedBy}
                                updateStartDate={setStartDate}
                                updateEndDate={setEndDate}
                                updateModuleTypes={setModuleTypes}
                                updateModuleStatus={setModuleStatus}
                                updateSyncState={setSyncFilter}
                                applyFilters={() => {
                                    setItemOffset(0)
                                    setSyncFilter(true)
                                    setHasLoadedResults(false)
                                }}
                                lockedModuleStatus={lockedModuleStatus}
                                disableFilters={disabledFilters}
                            />
                        </Col>
                    )}
                    {displaySearchResults()}
                </Flex>
            </Col>
        </Container>
    )
}
