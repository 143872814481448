import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { ChatItemDTO } from 'src/models/dto/items/ChatItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ChatHandler } from 'src/services/EntityServices/ItemUpdateHandlers/ChatHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const ChatItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<ChatItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '350px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`} width={matches.s ? '100%' : '410px'}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ChatHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            ChatHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserveOrder'}
                            dataTestId={'preserve-order-checkbox'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                ChatHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </Row>
            <Spacer height='S400' />
            <Col width={'100%'}>
                <ItemEditorTextInput
                    itemId={itemDTO.id}
                    dataTestId={'chat-item-header'}
                    disabled={editDisabled}
                    inputId='chat-header'
                    labelText='Chat Header'
                    placeholder='Enter conversation header'
                    value={itemDTO.chatHeader?.headerStringI18N[locale] || ''}
                    setValue={(nextValue: string) =>
                        ChatHandler.updateChatHeader(itemDTO.id, locale, nextValue)
                    }
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors.chatHeader ?? []
                    ).join(', ')}
                    showError={showValidationError}
                />
            </Col>

            {itemDTO.chatMessages.map((chatMessage, index) => {
                return (
                    <Col key={index} dataTestId={`chat-item-message-row-${index}`}>
                        <Spacer height='S400' />
                        <Hr size='wide' color='neutral20' />
                        <Spacer height='S400' />
                        <Row justifyContent={'space-between'}>
                            <Text fontSize={'T300'} color={'neutral90'} fontWeight={'bold'}>
                                {' '}
                                Chat Message {index + 1}
                            </Text>
                            <Button
                                aria-label={`delete-message-${index + 1}`}
                                aria-disabled={
                                    itemDTO.chatMessages.length === 1 ||
                                    !isDefaultLocale(locale) ||
                                    editDisabled
                                }
                                dataTestId={`chat-item-message-${index}-delete-button`}
                                icon={<IconBin aria-hidden={true} />}
                                isDestructive={true}
                                variant={ButtonVariant.Tertiary}
                                onClick={() => {
                                    ChatHandler.deleteMessage(itemDTO.id, index)
                                }}
                            />
                        </Row>
                        <Col width={'100%'}>
                            <ItemEditorTextInput
                                inputId={`chat-${index}-message-header`}
                                value={chatMessage.messageHeaderI18N[locale] || ''}
                                dataTestId={`chat-item-message-${index}-header`}
                                placeholder={'Enter chat sender'}
                                disabled={editDisabled}
                                itemId={itemDTO.id}
                                setValue={(nextValue: string) => {
                                    ChatHandler.updateMessageHeader(
                                        itemDTO.id,
                                        locale,
                                        index,
                                        nextValue
                                    )
                                }}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors.chatMessages ?? []
                                ).join(', ')}
                                showError={showValidationError}
                                labelText={'From (Chat sender)'}
                            />
                        </Col>
                        <Spacer height='S400' />
                        <Col width={'100%'}>
                            <ItemEditorTextInput
                                inputId={`chat-${index}-message`}
                                value={chatMessage.messageI18N[locale] || ''}
                                placeholder={'Enter the chat message'}
                                dataTestId={`chat-item-message-${index}-message`}
                                disabled={editDisabled}
                                itemId={itemDTO.id}
                                setValue={(nextValue: string) => {
                                    ChatHandler.updateMessage(itemDTO.id, locale, index, nextValue)
                                }}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors.chatMessages ?? []
                                ).join(', ')}
                                showError={showValidationError}
                                labelText={'Chat message'}
                            />
                        </Col>
                    </Col>
                )
            })}

            <Spacer height='S400' />
            <Row justifyContent={'flex-end'}>
                <Button
                    aria-label={'add-message'}
                    dataTestId='add-message'
                    icon={<IconPlus aria-hidden={true} />}
                    variant={ButtonVariant.Tertiary}
                    onClick={() => {
                        ChatHandler.addMessage(itemDTO.id)
                    }}
                    aria-disabled={editDisabled}
                >
                    Add new message
                </Button>
            </Row>
        </>
    )
}
