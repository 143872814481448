import React from 'react'

import { Container, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Span } from '@amzn/stencil-react-components/text'

import { SaveDraft } from 'src/components/ModuleMasthead'
import { ModuleLastModifiedDTO } from 'src/models/dto/ModuleLastModifiedDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'

interface ModuleFooterProps {
    lastModified: ModuleLastModifiedDTO
    saveModuleDTO: (message: string) => Promise<boolean>
    moduleStatus?: ModuleStatus
    isDraftSaved: boolean
    setIsDraftSaved: (value: ((prevState: boolean) => boolean) | boolean) => void
    isSaveDraftError: boolean
    setIsSaveDraftError: (value: ((prevState: boolean) => boolean) | boolean) => void
    isDraftSaving: boolean
    setIsDraftSaving: (value: ((prevState: boolean) => boolean) | boolean) => void
    hasUnsavedChanges: boolean
    setHasUnsavedChanges: (value: ((prevState: boolean) => boolean) | boolean) => void
    editDisabled?: boolean
}

export const ModuleFooter = ({
    lastModified,
    saveModuleDTO,
    moduleStatus,
    isDraftSaved,
    setIsDraftSaved,
    isDraftSaving,
    setIsDraftSaving,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    isSaveDraftError,
    setIsSaveDraftError,
    editDisabled,
}: ModuleFooterProps) => {
    // This simulates saving the module then reloading it's draft status.
    // Expect this code to be changed and/or deleted entirely when we integrate with the new backend.
    const saveDraft = async (message: string) => {
        setIsDraftSaving(true)
        setIsSaveDraftError(false)
        const saved = await saveModuleDTO(message)

        if (saved) {
            setIsDraftSaving(false)
            setIsDraftSaved(true)
            setHasUnsavedChanges(false)
        } else {
            setIsDraftSaving(false)
            setIsSaveDraftError(true)
        }
    }

    return (
        <footer>
            <Container
                height='75px'
                backgroundColor='#232F3E'
                paddingTop='S300'
                style={{ position: 'fixed', bottom: 0, zIndex: 9 }}
                dataTestId={'module-builder-footer'}
            >
                <Row alignItems='center' justifyContent='center'>
                    <Span dataTestId='last-modified-footer-date' fontSize='T200' color='#FFFFFF'>
                        Last Saved:{' '}
                        {new Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'numeric',
                        }).format(lastModified.date)}{' '}
                        at{' '}
                        {new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                        }).format(lastModified.date)}
                    </Span>
                    <Spacer flex={1} />
                    {isDraftSaving ? (
                        <Spinner />
                    ) : (
                        <SaveDraft
                            saveDraft={saveDraft}
                            isDraftSaved={isDraftSaved}
                            hasUnsavedChanges={hasUnsavedChanges}
                            moduleStatus={moduleStatus}
                            lastModified={lastModified}
                            buttonDataTestIdPrefix={'footer-'}
                            saveError={isSaveDraftError}
                            editDisabled={editDisabled}
                        />
                    )}
                </Row>
            </Container>
        </footer>
    )
}
