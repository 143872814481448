import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'

import { PageHeader, PageHeaderLink, PageHeaderList } from '@amzn/stencil-react-components/page'
import { H1 } from '@amzn/stencil-react-components/text'

import homeLogo from 'src/images/home-logo.png'

interface PageHeaderProps {
    name: string | ReactElement
    key: string
    path: string
    match?: RegExp
    extraProps: object
}

export const NavigationBar = () => {
    const location: Location = useLocation()
    const pageHeadersMap: PageHeaderProps[] = [
        {
            name: (
                <H1>
                    <img
                        src={homeLogo}
                        style={{ minWidth: 0 }}
                        alt='home logo'
                        height='25px'
                        width='168px'
                    />
                </H1>
            ),
            key: 'home',
            path: '/',
            extraProps: {
                role: 'heading',
                'aria-level': 1,
                'aria-label': 'home',
                dataTestId: 'home-page-button',
            },
        },
        {
            name: 'Module Builder',
            path: '/module-builder',
            key: 'module-builder',
            extraProps: {
                dataTestId: 'module-builder-button',
            },
        },
        {
            name: 'Asset Library',
            path: '/asset-library',
            key: 'asset-library',
            extraProps: {
                dataTestId: 'asset-library-button',
            },
        },
        {
            name: 'Module Groups',
            path: '/module-groups',
            key: 'module-groups',
            extraProps: {
                dataTestId: 'module-groups-button',
            },
        },
    ]

    return (
        <PageHeader>
            <nav
                aria-label='Site'
                style={{
                    display: 'flex',
                    flex: 1,
                }}
            >
                <PageHeaderList
                    items={[
                        pageHeadersMap.map((pageHeader) => {
                            const pattern =
                                pageHeader.path === '/'
                                    ? /^\/$/
                                    : new RegExp(
                                          '^' + pageHeader.path.replace('/', '\\/') + '($|\\/.*$)'
                                      )
                            const isSelected = pattern.test(location.pathname)
                            return (
                                <PageHeaderLink
                                    {...pageHeader.extraProps}
                                    key={pageHeader.key}
                                    href={pageHeader.path}
                                    isSelected={isSelected}
                                >
                                    {pageHeader.name}
                                </PageHeaderLink>
                            )
                        }),
                    ]}
                />
            </nav>
        </PageHeader>
    )
}
