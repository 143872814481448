import { SetStateAction } from 'react'

export type Change<T> = SetStateAction<T>

/**
 * Callback for changing the value being edited. The first parameter is the updated value or an updater function.
 */
export type SetStateCallback<T> = (change: SetStateAction<T>) => void

export interface StateAndCallback<T> {
    value: T
    onChange: SetStateCallback<T>
}

export function applyChange<T>(prevValue: T, change: Change<T>) {
    if (typeof change === 'function') {
        return (change as (prevState: T) => T)(prevValue)
    }
    return change
}
