import { PreviewTTL, usePreview, UsePreviewResults } from 'src/hooks/usePreview'
import { MODULE_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ModuleEntityService'

export function useModulePreview({
    moduleId,
    alreadyPersisted,
    onPreviewUnexpectedError,
}: {
    moduleId: string
    previewTTL: PreviewTTL
    alreadyPersisted?: boolean
    onPreviewUnexpectedError?: () => void
}): UsePreviewResults {
    return usePreview({
        entityId: moduleId,
        saved: alreadyPersisted,
        entityStoreSelector: MODULE_ENTITY_STORE_SELECTOR,
        onPreviewUnexpectedError: onPreviewUnexpectedError,
    })
}
