import { ModuleVersionRuleSetDTO, RuleType } from 'src/models/dto/module-groups/PECGroupDTO'

const HIGHEST_PRIORITY = Object.keys(RuleType).length
const SKIPPED_MODULE = 'SKIPPED_MODULE'

export const getRulePriority = (mvRuleSet: ModuleVersionRuleSetDTO) => {
    if (mvRuleSet.moduleVersionId === SKIPPED_MODULE) {
        return HIGHEST_PRIORITY + mvRuleSet.ruleSet.rules.length
    }
    return mvRuleSet.ruleSet.rules.length
}

export const sortRulesByPriority = (updatedRuleSetList: ModuleVersionRuleSetDTO[]) => {
    return updatedRuleSetList.sort(
        (ruleSetA, ruleSetB) => getRulePriority(ruleSetB) - getRulePriority(ruleSetA)
    )
}
