import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { ModuleGroupDTO } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { moduleGroupViewerRoute, moduleViewerRoute } from 'src/pages/module-review/index'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ModuleGroupService } from 'src/services/module-groups/ModuleGroupService'

export const RedirectToReviewPage = () => {
    const { moduleVersionId, moduleGroupVersionId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!moduleVersionId && !moduleGroupVersionId) {
            return
        }

        if (moduleVersionId) {
            void (async () => {
                const moduleVersionDTO = await ModuleService.loadModuleVersionDTO(moduleVersionId)
                const reviewId = moduleVersionDTO.moduleReviewStatus?.reviewId
                if (!reviewId) {
                    navigate(moduleViewerRoute({ moduleVersionId }))
                    return
                }

                const review = await ApprovalService.getReview(reviewId)
                if (!review) {
                    navigate(moduleViewerRoute({ moduleVersionId }))
                    return
                }

                const latestRevision = _.maxBy(review.revisionList, 'createdAt')
                navigate(
                    moduleViewerRoute({
                        moduleVersionId,
                        reviewId: review.reviewId,
                        revisionNumber: latestRevision?.revisionNumber ?? '',
                    })
                )
            })().catch((e: unknown) => {
                // Since there's no review data, redirect to module viewer instead
                console.error(e)
                navigate(moduleViewerRoute({ moduleVersionId }))
            })
        } else if (moduleGroupVersionId) {
            void (async () => {
                const moduleGroupVersionDTO: ModuleGroupDTO =
                    await ModuleGroupService.loadModuleGroupVersionDTO(moduleGroupVersionId)
                const reviewId = moduleGroupVersionDTO.review?.reviewId
                if (!reviewId) {
                    navigate(moduleGroupViewerRoute({ moduleGroupVersionId }))
                    return
                }

                const review = await ApprovalService.getReview(reviewId)
                if (!review) {
                    navigate(moduleGroupViewerRoute({ moduleGroupVersionId }))
                    return
                }

                const latestRevision = _.maxBy(review.revisionList, 'createdAt')
                navigate(
                    moduleGroupViewerRoute({
                        moduleGroupVersionId,
                        reviewId: review.reviewId,
                        revisionNumber: latestRevision?.revisionNumber ?? '',
                    })
                )
            })().catch((e: unknown) => {
                // Since there's no review data, redirect to module viewer instead
                console.error(e)
                navigate(moduleGroupViewerRoute({ moduleGroupVersionId }))
            })
        }
    }, [moduleGroupVersionId, moduleVersionId, navigate])

    return null
}
