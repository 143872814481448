import React, { useState } from 'react'

import { Button } from '@amzn/stencil-react-components/button'
import { WithModal } from '@amzn/stencil-react-components/modal'

import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { ModuleGroupReviewRequest } from 'src/models/dto/approval/ReviewDTO'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ModuleGroupStatus } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import {
    InitiateUATApprovalModalContent,
    SelfServiceModuleGroupPublishingComponent,
    UATApprovalComponents,
} from 'src/pages/module-review/UATApprovalComponents'
import { ApprovalService } from 'src/services/approval/ApprovalService'

export interface InitiateModuleGroupUATApprovalModalProps {
    moduleGroupVersionId: string
    moduleGroupName: string
    onDeployModalClose: () => void
    closeModal: () => void
}

export const InitiateModuleGroupUATApprovalModal = (
    props: InitiateModuleGroupUATApprovalModalProps
) => {
    const [workflowUrl, setWorkflowUrl] = useState('')
    const [description, setDescription] = useState('')
    const [reviewers, setReviewers] = useState<string[]>([])

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState('')
    const [workflowUrlError, setWorkflowUrlError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const submit = async () => {
        try {
            const input: ModuleGroupReviewRequest = {
                versionId: props.moduleGroupVersionId,
                title: props.moduleGroupName,
                description: description,
                reviewUrl: window.location.origin.concat(
                    `/module-groups/${props.moduleGroupVersionId}/review`
                ),
                reviewers: reviewers,
                signOffDocument: workflowUrl,
            }

            setSubmitting(true)
            await ApprovalService.initiateModuleGroupReviewProcess(input)
            props.onDeployModalClose()
            setSubmitting(false)
            props.closeModal()
        } catch (e: unknown) {
            console.error('Error while requesting review: ', e)
            console.log(
                (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
            )

            setSubmitting(false)
            setError(
                `Error while initiating UAT approval process: ${
                    (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
                }`
            )
        }
    }

    return (
        <InitiateUATApprovalModalContent
            error={error}
            workflowUrl={workflowUrl}
            setWorkflowUrl={setWorkflowUrl}
            description={description}
            setDescription={setDescription}
            submitting={submitting}
            setReviewers={setReviewers}
            submit={submit}
            close={props.closeModal}
            descriptionError={descriptionError}
            setDescriptionError={setDescriptionError}
            workflowUrlError={workflowUrlError}
            setWorkflowUrlError={setWorkflowUrlError}
        />
    )
}

export interface UATModuleGroupApprovalProps {
    moduleGroupVersionId?: string
    signOffDocument?: string
    reviewId?: string
    moduleGroupStatus: ModuleGroupStatus
    uatApprovals?: ApprovalDTO[]
    onApprovalChange: () => void
    onDeployModalClose: () => void
    moduleGroupName: string
    revisionNumber: string
}

export const UATModuleGroupApproval = (props: UATModuleGroupApprovalProps) => {
    const [submitting, setSubmitting] = useState(false)
    const isUAT =
        ModuleGroupStatus[props.moduleGroupStatus as keyof typeof ModuleGroupStatus] ===
        ModuleGroupStatus.UAT

    const isPostUAT =
        isUAT ||
        ModuleGroupStatus[props.moduleGroupStatus as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW ||
        ModuleGroupStatus[props.moduleGroupStatus as keyof typeof ModuleGroupStatus] ===
            ModuleGroupStatus.UAT_REVIEW_APPROVED

    if (!isPostUAT || !props.moduleGroupVersionId) {
        return null
    }

    const moduleGroupVersionId = props.moduleGroupVersionId ?? ''

    const renderModuleGroupPublishingModal = ({ close }: { close: () => void }) => {
        return (
            <SelfServiceModuleGroupPublishingComponent
                versionId={moduleGroupVersionId}
                workflowDefinitionURL={props.signOffDocument || ''}
                close={close}
                onClose={props.onDeployModalClose}
            />
        )
    }

    const renderInitiateUATApprovalModal = ({ close }: { close: () => void }) => {
        return (
            <InitiateModuleGroupUATApprovalModal
                moduleGroupVersionId={moduleGroupVersionId}
                closeModal={close}
                moduleGroupName={props.moduleGroupName}
                onDeployModalClose={props.onDeployModalClose}
            />
        )
    }

    if (isUAT) {
        return (
            <WithModal renderModal={renderInitiateUATApprovalModal}>
                {({ open }) => (
                    <Button dataTestId='initiate-uat-approval-process-button' onClick={open}>
                        Initiate UAT approval process
                    </Button>
                )}
            </WithModal>
        )
    }

    return (
        <UATApprovalComponents
            moduleVersionId={moduleGroupVersionId}
            onApprovalChange={props.onApprovalChange}
            onDeployModalClose={props.onDeployModalClose}
            setSubmitting={setSubmitting}
            submitting={submitting}
            uatApprovals={props.uatApprovals}
            workflowDefinitionUrl={props.signOffDocument || ''}
            reviewId={props.reviewId || ''}
            revisionNumber={props.revisionNumber}
            renderPublishingModal={renderModuleGroupPublishingModal}
        />
    )
}
