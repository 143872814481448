import React from 'react'

import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useSetLocaleWiseMediaOpt } from 'src/hooks/useSetLocaleWiseMediaOpt'
import { FreeTextDisplayType, FreeTextItemDTO } from 'src/models/dto/items/FreeTextItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorSelectInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const displayTypeDisplayNames = new Map<FreeTextDisplayType, string>([
    [FreeTextDisplayType.SingleLine, 'Single line'],
    [FreeTextDisplayType.MultiLine, 'Multiple lines'],
])

export const FreeTextItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError = false,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<FreeTextItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const flexItemWidth = matches.s ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '350px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'
    const FREE_TEXT_DISPLAY_TYPE_FLEX_BASIS = matches.s ? '100%' : '300px'

    const setLocaleWiseMediaOpt = useSetLocaleWiseMediaOpt(itemDTO)

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.update({
                                ...itemDTO,
                                label: nextValue,
                            } as FreeTextItemDTO)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId={'optional-checkbox'}
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            ItemEntityService.update({
                                ...itemDTO,
                                optional: nextValue,
                            } as FreeTextItemDTO)
                        }}
                    />
                </Col>
                <Col padding={{ top: `${matches.s ? '8px' : '29px'}` }} flex={'0 0 170px'}>
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId={'preserve-order-checkbox'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                ItemEntityService.update({
                                    ...itemDTO,
                                    preserveOrder: nextValue,
                                } as FreeTextItemDTO)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col flex={`3 0 ${matches.s ? '100%' : '200px'}`}>
                    <ItemEditorNumberInput
                        dataTestId={'character-limit'}
                        inputId={'response-character-limit'}
                        itemId={itemDTO.id}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        labelText='Response Character Limit'
                        value={itemDTO.characterLimit === 0 ? null : itemDTO.characterLimit}
                        placeholder='500'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.characterLimit ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        setValue={(nextValue: number | null) => {
                            ItemEntityService.update({
                                ...itemDTO,
                                characterLimit: nextValue ?? 0,
                            } as FreeTextItemDTO)
                        }}
                    />
                </Col>
                <Col flex={`2 0 ${FREE_TEXT_DISPLAY_TYPE_FLEX_BASIS}`}>
                    <ItemEditorSelectInput
                        dataTestId={'free-text-display-type'}
                        valueToDisplayNames={displayTypeDisplayNames}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        inputId={'free-text-display-type'}
                        itemId={itemDTO.id}
                        labelText={'Item Layout'}
                        value={itemDTO.freeTextDisplayType}
                        setValue={(nextValue: FreeTextDisplayType) => {
                            ItemEntityService.update({
                                ...itemDTO,
                                freeTextDisplayType: nextValue,
                            } as FreeTextItemDTO)
                        }}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextInput
                        dataTestId={'stimulus'}
                        disabled={editDisabled}
                        inputId={'stimulus'}
                        value={itemDTO.questionI18N[locale] ?? ''}
                        placeholder={'Enter the stimulus question'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.update({
                                ...itemDTO,
                                questionI18N: {
                                    ...itemDTO.questionI18N,
                                    [locale]: nextValue,
                                },
                            } as FreeTextItemDTO)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.questionI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Stimulus'}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <Hr />
            <MediaManager
                itemId={itemDTO.id}
                locale={locale}
                localeWiseMediaOpt={itemDTO.localeWiseMedia}
                setLocaleWiseMediaOpt={setLocaleWiseMediaOpt}
                disabled={editDisabled}
            />
        </>
    )
}
