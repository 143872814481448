import { APP_CONFIG } from 'src/config.app'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import {
    ModuleListFilters,
    ModuleListSortCriteria,
    PaginationConfig,
} from 'src/models/ListModulesRequest'
import { ModuleMetaData, SearchResultResponse } from 'src/models/SearchResultResponse'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

export class SearchService {
    public static async searchModules(
        searchTerm: string,
        filters: ModuleListFilters,
        pageConfig: PaginationConfig,
        sortCriteria: ModuleListSortCriteria,
        abortController?: AbortController,
        searchArchived = false
    ): Promise<SearchResultResponse> {
        try {
            const queryString = this.constructQueryString(
                searchTerm,
                searchArchived,
                filters,
                pageConfig,
                sortCriteria
            )
            const {
                data,
            }: { data: SearchResultResponse & { moduleMetadataList: ModuleMetaData[] } } =
                await ADAxios.get(
                    `${APP_CONFIG.backendAPIBaseUrl}/modules-versions?${queryString}`,
                    {
                        apiActionName: ApiActionNames.SearchModuleVersion,
                        signal: abortController?.signal,
                    }
                )

            return {
                ...data,
                data: data.moduleMetadataList,
            }
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static constructQueryString(
        searchTerm: string,
        searchArchived: boolean,
        filters: ModuleListFilters,
        pageConfig: PaginationConfig,
        sortCriteria: ModuleListSortCriteria,
        urlQueryParams = false
    ): string {
        let searchParams: Record<string, string> = {
            search: searchTerm,
            offset: pageConfig.offset.toString(),
            numberOfRecordsPerPage: pageConfig.numberOfRecordsPerPage.toString(),
            owner: filters.owner ?? '',
            lastModifiedBy: filters.lastModifiedBy ?? '',
            startDate: filters.startDate ?? '',
            endDate: filters.endDate ?? '',
            sortField: sortCriteria.sortField,
            sortDirection: sortCriteria.sortDirection,
            archived: searchArchived.toString(),
        }

        // removes params without a value, just cleans up the url
        searchParams = Object.entries(searchParams).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value
            }
            return acc
        }, {} as Record<string, string>)
        const queryString = new URLSearchParams(searchParams)
        filters.moduleTypes?.forEach((type) => {
            queryString.append('moduleType', type)
        })
        filters.moduleStatuses?.forEach((status) => {
            if (!urlQueryParams) {
                queryString.append(
                    'moduleStatus',
                    Object.keys(ModuleStatus).find(
                        (k) => ModuleStatus[k as keyof typeof ModuleStatus] === status
                    ) as string
                )
            } else {
                queryString.append('moduleStatus', status)
            }
        })
        return queryString.toString()
    }
}
