import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement, MediaLayoutType } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface StaticContentLayoutDTO extends ItemDTO {
    itemType: ItemType.StaticContentLayout
    statementI18N: LocalizedAttribute<string>
    layoutType: StaticContentLayoutType
    rows: RowLayoutDTO[]
}

export enum StaticContentLayoutType {
    STATIC_SINGLE_MEDIA = 'STATIC_SINGLE_MEDIA',
    STATIC_MEDIA_LIST = 'STATIC_MEDIA_LIST',
    STATIC_TEXT_WRAPPED_MEDIA = 'STATIC_TEXT_WRAPPED_MEDIA',
}

export enum RowLayoutType {
    IMAGE_CLUSTER = 'IMAGE_CLUSTER',
    TITLE = 'TITLE',
    MEDIA = 'MEDIA',
    TEXT_BLOCK = 'TEXT_BLOCK',
    TEXT_WITH_MEDIA = 'TEXT_WITH_MEDIA',
}

export interface RowLayoutDTO {
    rowLayoutType: RowLayoutType
}

export interface MediaRowDTO extends RowLayoutDTO, MediaElement {}

export interface TextBlockRowDTO extends RowLayoutDTO {
    textI18N: LocalizedAttribute<string>
}

export interface TitleRowDTO extends RowLayoutDTO {
    titleI18N: LocalizedAttribute<string>
}

export enum MediaRowPosition {
    LEADING = 'LEADING',
    TRAILING = 'TRAILING',
}

export interface TextWithMediaRowDTO extends RowLayoutDTO, MediaElement {
    textI18N: LocalizedAttribute<string>
    titleI18N: LocalizedAttribute<string>
    mediaRowPosition: MediaRowPosition
}

export const getDefaultSingleMediaDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        label: '',
        locale: Locale.en_US,
        optional: false,
        preserveOrder: false,
        itemType: ItemType.StaticContentLayout,
        ppt: ItemType.StaticContentLayout,
        layoutType: StaticContentLayoutType.STATIC_SINGLE_MEDIA,
        rows: [
            {
                rowLayoutType: RowLayoutType.TITLE,
                titleI18N: LocalizeDefault<string>(''),
            } as TitleRowDTO,
            {
                rowLayoutType: RowLayoutType.MEDIA,
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
            } as MediaRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_BLOCK,
                textI18N: LocalizeDefault<string>(''),
            } as TextBlockRowDTO,
        ],
    } as ItemDTO
}

export const getDefaultTextWrappedMediaDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        label: '',
        locale: Locale.en_US,
        optional: false,
        preserveOrder: false,
        itemType: ItemType.StaticContentLayout,
        ppt: ItemType.StaticContentLayout,
        layoutType: StaticContentLayoutType.STATIC_TEXT_WRAPPED_MEDIA,
        rows: [
            {
                rowLayoutType: RowLayoutType.TITLE,
                titleI18N: LocalizeDefault<string>(''),
            } as TitleRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_BLOCK,
                textI18N: LocalizeDefault<string>(''),
            } as TextBlockRowDTO,
            {
                rowLayoutType: RowLayoutType.IMAGE_CLUSTER,
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
            } as MediaRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_BLOCK,
                textI18N: LocalizeDefault<string>(''),
            } as TextBlockRowDTO,
            {
                rowLayoutType: RowLayoutType.MEDIA,
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
            } as MediaRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_BLOCK,
                textI18N: LocalizeDefault<string>(''),
            } as TextBlockRowDTO,
        ],
    } as ItemDTO
}

export const getDefaultMediaListDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        label: '',
        locale: Locale.en_US,
        optional: false,
        preserveOrder: false,
        itemType: ItemType.StaticContentLayout,
        ppt: ItemType.StaticContentLayout,
        layoutType: StaticContentLayoutType.STATIC_MEDIA_LIST,
        rows: [
            {
                rowLayoutType: RowLayoutType.TITLE,
                titleI18N: LocalizeDefault<string>(''),
            } as TitleRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_BLOCK,
                textI18N: LocalizeDefault<string>(''),
            } as TextBlockRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_WITH_MEDIA,
                textI18N: LocalizeDefault<string>(''),
                titleI18N: LocalizeDefault<string>(''),
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
                mediaRowPosition: MediaRowPosition.TRAILING,
            } as TextWithMediaRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_WITH_MEDIA,
                textI18N: LocalizeDefault<string>(''),
                titleI18N: LocalizeDefault<string>(''),
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
                mediaRowPosition: MediaRowPosition.TRAILING,
            } as TextWithMediaRowDTO,
            {
                rowLayoutType: RowLayoutType.TEXT_WITH_MEDIA,
                textI18N: LocalizeDefault<string>(''),
                titleI18N: LocalizeDefault<string>(''),
                localeWiseMedia: {
                    mediaList: [],
                    layout: { type: MediaLayoutType.StaticView, layoutName: 'Default' },
                },
                mediaRowPosition: MediaRowPosition.TRAILING,
            } as TextWithMediaRowDTO,
        ],
    } as ItemDTO
}
