export enum ModuleStatus {
    DRAFT_UNVALIDATED = 'Draft (Unvalidated)',
    DRAFT_VALIDATED = 'Draft (Validated)',
    REVIEW = 'Review',
    REVIEW_MAJOR = 'Review Major',
    REVIEW_MINOR = 'Review Minor',
    REVIEW_APPROVED = 'Approved',
    UAT = 'UAT',
    TRANSLATION_REQUESTED = 'Translation Requested',
    MEDIA_TRANSLATION_REQUIRED = 'Media Translation Required',
    TRANSLATION_RECEIVED = 'Translation Received',
    PUBLISHED = 'Workflow Ready',
    LIVE = 'Live',
    ARCHIVED = 'Archived',
}

export type ModuleStatusName = string & keyof typeof ModuleStatus
