import React, { ReactNode } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Popover, PopoverPosition } from '@amzn/stencil-react-components/popover'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { H5 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import StaticMediaList from 'src/images/static_media_list.png'
import StaticSingleMedia from 'src/images/static_single_media.png'
import StaticTextWrapped from 'src/images/static_text_wrapped_media.png'
import {
    MediaRowDTO,
    RowLayoutDTO,
    RowLayoutType,
    StaticContentLayoutDTO,
    StaticContentLayoutType,
    TextBlockRowDTO,
    TextWithMediaRowDTO,
    TitleRowDTO,
} from 'src/models/dto/items/StaticContentLayoutDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { MediaRowEditor } from 'src/pages/module-builder/item-editors/StaticContentLayouts/MediaRowEditor'
import { TextBlockRowEditor } from 'src/pages/module-builder/item-editors/StaticContentLayouts/TextRowEditor'
import { TextWithMediaRowEditor } from 'src/pages/module-builder/item-editors/StaticContentLayouts/TextWithMediaRowEditor'
import { TitleRowEditor } from 'src/pages/module-builder/item-editors/StaticContentLayouts/TitleRowEditor'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const LAYOUT_TYPE_ERROR_KEY = 'LayoutType'

export const StaticContentLayoutEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError = false,
    editDisabled,
}: ItemEditorProps) => {
    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { entity: staticContentLayoutDTO } = useItemEntity<StaticContentLayoutDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    const {
        matches: { s: isS },
    } = useBreakpoints()

    const flexItemWidth = isS ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = isS ? '100%' : '400px'

    const topLevelErrors = [
        ...(validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] || []),
        ...(validationErrorEntity.validationErrors[LAYOUT_TYPE_ERROR_KEY] || []),
    ]

    return (
        <>
            {topLevelErrors.length > 0 && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {topLevelErrors.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems='center' flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={staticContentLayoutDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={staticContentLayoutDTO.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.update({
                                ...staticContentLayoutDTO,
                                label: nextValue,
                            } as StaticContentLayoutDTO)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S500'}
                    padding={{ top: `${isS ? '8px' : '25px'}`, left: `${isS ? '2px' : '5px'}` }}
                    flex={'0 0 180px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={staticContentLayoutDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            dataTestId={'preserve-order-checkbox'}
                            value={staticContentLayoutDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                ItemEntityService.update({
                                    ...staticContentLayoutDTO,
                                    preserveOrder: nextValue,
                                } as StaticContentLayoutDTO)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col>
                    <Spacer height='S400' />
                    <Popover
                        triggerText={'Example'}
                        dataTestId={'example-layout'}
                        shouldFocusOnOpen
                        shouldCloseOnFocusLeave
                        position={PopoverPosition.TopLeading}
                    >
                        {({ close }) => (
                            <Col>
                                {staticContentLayoutDTO.layoutType ===
                                StaticContentLayoutType.STATIC_SINGLE_MEDIA ? (
                                    <img
                                        src={StaticSingleMedia}
                                        alt={
                                            'Example of Single Media Layout. A Single Media Layout consists of a title, ' +
                                            'then a media, then a text block describing the media.'
                                        }
                                    />
                                ) : staticContentLayoutDTO.layoutType ===
                                  StaticContentLayoutType.STATIC_MEDIA_LIST ? (
                                    <img
                                        src={StaticMediaList}
                                        alt={
                                            'Example of Media List Layout. A Media List Layout consists of a title, ' +
                                            'then a text block, then three medias with their respective text contents.'
                                        }
                                    />
                                ) : (
                                    <img
                                        src={StaticTextWrapped}
                                        alt={
                                            'Example of Text Wrapped Layout. ' +
                                            'A Text Wrapped Layout consists of a title, then a text block, ' +
                                            'then a horizontal cluster of images, then a text block, ' +
                                            'then a media, and then a text block'
                                        }
                                    />
                                )}
                                <Button
                                    variant={ButtonVariant.Tertiary}
                                    dataTestId={'close-button'}
                                    onClick={close}
                                >
                                    Close
                                </Button>
                            </Col>
                        )}
                    </Popover>
                </Col>
            </Row>
            <Spacer height='S400' />
            <Col
                gridGap={10}
                flex={1}
                width={flexItemWidth}
                data-test-id='static-content-layout-information'
            >
                {staticContentLayoutDTO.rows
                    .filter(
                        (r: RowLayoutDTO) =>
                            r.rowLayoutType === RowLayoutType.TITLE ||
                            r.rowLayoutType === RowLayoutType.MEDIA ||
                            r.rowLayoutType === RowLayoutType.IMAGE_CLUSTER ||
                            r.rowLayoutType === RowLayoutType.TEXT_WITH_MEDIA ||
                            r.rowLayoutType === RowLayoutType.TEXT_BLOCK
                    )
                    .map((r: RowLayoutDTO, index: number) => {
                        let row: ReactNode
                        if (
                            r.rowLayoutType === RowLayoutType.MEDIA ||
                            r.rowLayoutType === RowLayoutType.IMAGE_CLUSTER
                        ) {
                            row = (
                                <MediaRowEditor
                                    mediaRow={r as MediaRowDTO}
                                    dataTestId={`row-${index}`}
                                    locale={locale}
                                    itemId={itemId}
                                    rowIndex={index}
                                    workflowEntityId={workflowEntityId}
                                    editDisabled={editDisabled}
                                />
                            )
                        } else if (r.rowLayoutType === RowLayoutType.TITLE) {
                            row = (
                                <TitleRowEditor
                                    rowIndex={index}
                                    titleRow={r as TitleRowDTO}
                                    locale={locale}
                                    itemId={itemId}
                                    workflowEntityId={workflowEntityId}
                                    showValidationError={showValidationError}
                                    editDisabled={editDisabled}
                                />
                            )
                        } else if (r.rowLayoutType === RowLayoutType.TEXT_WITH_MEDIA) {
                            row = (
                                <TextWithMediaRowEditor
                                    textWithMediaRow={r as TextWithMediaRowDTO}
                                    locale={locale}
                                    itemId={itemId}
                                    rowIndex={index}
                                    workflowEntityId={workflowEntityId}
                                    showValidationError={showValidationError}
                                    editDisabled={editDisabled}
                                />
                            )
                        } else {
                            row = (
                                <TextBlockRowEditor
                                    textBlockRow={r as TextBlockRowDTO}
                                    locale={locale}
                                    itemId={itemId}
                                    rowIndex={index}
                                    workflowEntityId={workflowEntityId}
                                    showValidationError={showValidationError}
                                    editDisabled={editDisabled}
                                />
                            )
                        }

                        const rowName = `Row ${index + 1} - ${
                            r.rowLayoutType === RowLayoutType.IMAGE_CLUSTER
                                ? 'Image Cluster'
                                : r.rowLayoutType === RowLayoutType.TEXT_WITH_MEDIA
                                ? 'Text With Media'
                                : r.rowLayoutType === RowLayoutType.TEXT_BLOCK
                                ? 'Text Block'
                                : r.rowLayoutType === RowLayoutType.TITLE
                                ? 'Title'
                                : 'Media'
                        }`

                        return (
                            <Col key={`row-${index}-col`} gridGap={0} flex={1}>
                                <Hr />
                                <H5>{rowName}</H5>
                                <Spacer height='S100' />
                                {row}
                            </Col>
                        )
                    })}
            </Col>
        </>
    )
}
