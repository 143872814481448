import { ApprovalDTO } from 'src/models/dto/approval/ApprovalDTO'
import { RevisionDTO } from 'src/models/dto/approval/RevisionDTO'

export interface ReviewDTO {
    reviewId: string
    title: string
    reviewers: string[]
    requester: string
    createdAt: number
    modifiedBy: string
    modifiedAt: number
    revisionList: RevisionDTO[]
}

export enum ReviewType {
    PATCH = 'PATCH',
    MINOR = 'MINOR',
    MAJOR = 'MAJOR',
}

export interface ReviewerMember {
    alias: string
    assigned: boolean
}

export interface CreateModuleReviewInput {
    moduleVersionId: string
    reviewId?: string
    title: string
    description: string
    requester: string
    reviewers: string[]
    reviewUrl: string
    reviewType?: string
    reviewerMemberList?: ReviewerMember[]
}

export interface ModuleGroupReviewRequest {
    versionId: string
    title: string
    description: string
    reviewUrl: string
    reviewers: string[]
    signOffDocument?: string
}

export interface ApprovalProcessActionInput {
    versionId: string
    reviewId: string
    revisionNumber: string
    stageName: string
    reviewer: string
    comment: string
    approvalStatus: string
}

export interface ApprovalProcessActionResponse {
    versionId: string
    status: string
    approvals: ApprovalDTO[]
}

export interface ModuleChange {
    keyPath: string
    changeTypes: string[]
    operation?: string
    classification: string
}

export interface ModuleVersionDiff {
    differenceType: string
    moduleChangeList: ModuleChange[]
    labelsMissing: boolean
    unclassifiedChanges: string[]
}
