import React, { useState } from 'react'
import styled from '@emotion/styled'

import { RankingCellSubType } from 'src/models/dto/items/RankingItemDTO'
import { TableScaleHarveyBallScaleType } from 'src/pages/module-builder/item-editors/table-editor/TableEditor'

function HarveyBall({ type, className }: { type: RankingCellSubType; className?: string }) {
    const [clipId] = useState(`harvey-ball-value-clip-${Math.random()}`)
    const r = 10
    const circleProps = { cx: r, cy: r, r, role: '' }
    let number = 0

    switch (type) {
        case RankingCellSubType.HarveyBallEmpty:
            number = 1
            break
        case RankingCellSubType.HarveyBallHalfFull:
            number = 2
            break
        case RankingCellSubType.HarveyBallFull:
            number = 3
            break
    }

    const fr = (number - 1) / 2
    return (
        <svg
            width={r + r + 2}
            height={r + r + 2}
            data-test-id={`HarveyBallValue:${type}`}
            className={className}
            aria-hidden={true}
        >
            <g transform='translate(1, 1)'>
                <circle fill='none' stroke='currentColor' {...circleProps} />
                <clipPath id={clipId}>
                    <rect x={0} y={2 * r * (1 - fr)} width={r + r + 2} height={2 * r * fr} />
                </clipPath>
                <circle
                    clipPath={`url(#${clipId})`}
                    stroke='none'
                    fill='currentColor'
                    {...circleProps}
                />
            </g>
        </svg>
    )
}

export const HarveyBallValue = styled(HarveyBall)({
    verticalAlign: 'center',
    color: '#4862c8',
})

function HarveyBall2({
    type,
    className,
}: {
    type: TableScaleHarveyBallScaleType
    className?: string
}) {
    const [clipId] = useState(`harvey-ball-value-clip-${Math.random()}`)
    const r = 10
    const circleProps = { cx: r, cy: r, r, role: '' }
    let number = 0

    switch (type) {
        case TableScaleHarveyBallScaleType.HarveyBallEmpty:
            number = 1
            break
        case TableScaleHarveyBallScaleType.HarveyBallHalfFull:
            number = 2
            break
        case TableScaleHarveyBallScaleType.HarveyBallFull:
            number = 3
            break
    }

    const fr = (number - 1) / 2
    return (
        <svg
            width={r + r + 2}
            height={r + r + 2}
            data-test-id={`HarveyBallValue:${type}`}
            className={className}
            aria-hidden={true}
        >
            <g transform='translate(1, 1)'>
                <circle fill='none' stroke='currentColor' {...circleProps} />
                <clipPath id={clipId}>
                    <rect x={0} y={2 * r * (1 - fr)} width={r + r + 2} height={2 * r * fr} />
                </clipPath>
                <circle
                    clipPath={`url(#${clipId})`}
                    stroke='none'
                    fill='currentColor'
                    {...circleProps}
                />
            </g>
        </svg>
    )
}

export const HarveyBallValue2 = styled(HarveyBall2)({
    verticalAlign: 'center',
    color: '#4862c8',
})
