import { useState } from 'react'

import { useInterval } from 'src/hooks/useInterval'
import { ModuleGroupDTO, Review } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleGroupService } from 'src/services/module-groups/ModuleGroupService'

// All the logic for fetching on an interval and keeping track of whether it timed out or is submitting lives inside this Hook
export function useFetchModuleGroupOnInterval(versionId: string) {
    const [reviewStatus, setReviewStatus] = useState<Review | undefined>(undefined)
    const [fetchError, setFetchError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [attemptsToGetStatus, setAttemptsToGetStatus] = useState<number>(1)
    const [timedOut, setTimedOut] = useState<boolean>(false)

    const ONE_SECOND = 1000
    const REVIEW_CREATION_TIMEOUT = 60

    useInterval(async () => {
        if (!reviewStatus && submitting && !fetchError) {
            setAttemptsToGetStatus(attemptsToGetStatus + 1)
            if (attemptsToGetStatus > REVIEW_CREATION_TIMEOUT) {
                setTimedOut(true)
                return
            }

            try {
                const response: ModuleGroupDTO = await ModuleGroupService.loadModuleGroupVersionDTO(
                    versionId
                )
                const updatedReviewStatus = response.review
                if (updatedReviewStatus) {
                    setSubmitting(false)
                    setReviewStatus(updatedReviewStatus)
                }
            } catch (e: unknown) {
                setFetchError(true)
            }
        }
    }, ONE_SECOND)

    return {
        fetchError,
        timedOut,
        submitting,
        reviewStatus,
        setSubmitting,
    }
}
