import React, { useCallback, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { DropdownButton } from 'src/components/DropdownButton'
import { PreviewErrorModal } from 'src/components/ErrorModals'
import { useItemPreview } from 'src/hooks/useItemPreview'
import { ModuleValidationErrorMessage } from 'src/hooks/usePreview'
import { endOfPageFocusTargetId, focusOnId, itemFocusId } from 'src/pages/module-builder/focus'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'

enum ItemOptionsModal {
    DELETE_ITEM = 'DELETE_ITEM',
}

interface ItemOptionsProp {
    workflowEntityId: string
    itemId: string
    onPreviewValidationError?: (validationErrors: ModuleValidationErrorMessage[]) => void
    onPreviewUnexpectedError?: () => void
    itemIndex: number
    pageIndex: number
    onErrorCheck?: (hasValidationError: boolean) => void
    editDisabled?: boolean
}

interface RenderModalPropsItemOptions {
    onClose: () => void
    onDone: () => void
}

export function DeleteItemModal({ onClose, onDone }: RenderModalPropsItemOptions) {
    return (
        <ModalContent
            titleText='Delete item'
            buttons={[
                // @ts-ignore: Expression produces a union type that is too complex to represent error
                <Button
                    key='delete-item-modal-submit'
                    dataTestId='delete-item-modal-submit'
                    onClick={() => {
                        onDone()
                        onClose()
                    }}
                    isDestructive
                >
                    Yes, delete
                </Button>,
                <Button
                    key='close-modal'
                    dataTestId='close-modal'
                    onClick={onClose}
                    variant={ButtonVariant.Primary}
                >
                    No, keep item
                </Button>,
            ]}
        >
            <Col gridGap='S400'>
                <Text>Do you want to delete this item? This cannot be undone.</Text>
            </Col>
        </ModalContent>
    )
}

export function ItemOptions({
    workflowEntityId,
    itemId,
    itemIndex,
    pageIndex,
    onPreviewValidationError,
    onPreviewUnexpectedError,
    onErrorCheck,
    editDisabled,
}: ItemOptionsProp) {
    const [openModal, setOpenModal] = useState<ItemOptionsModal | null>(null)
    const {
        matches: { s: small },
    } = useBreakpoints()

    const { generatingPreview, previewValidationError, previewUrl, executePreview } =
        useItemPreview({
            itemId: itemId,
            onPreviewUnexpectedError: onPreviewUnexpectedError,
        })

    const [previewErrorCount, setPreviewErrorCount] = useState<number | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const nextItemFocusId = itemFocusId(pageIndex + 1, itemIndex + 2)
    const runErrorCheck = useCallback(async () => {
        try {
            const dto = ModuleService.serializeItemDTO(itemId)
            setLoading(true)
            await ErrorCheckService.templatesErrorCheck(dto)
            onErrorCheck?.(false)
            setLoading(false)
        } catch (e: unknown) {
            onErrorCheck?.(true)
            setLoading(false)
        }
    }, [itemId, onErrorCheck])

    useEffect(() => {
        if (previewUrl) {
            window.open(
                previewUrl,
                'preview',
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
            )
        }
    }, [previewUrl])

    useEffect(() => {
        if (previewValidationError) {
            setPreviewErrorCount(previewValidationError.length)
            ;(async function () {
                await runErrorCheck()
            })().catch(console.error)

            if (onPreviewValidationError) {
                onPreviewValidationError(previewValidationError)
            }
        }
    }, [previewValidationError, onPreviewValidationError, runErrorCheck])

    const dropdownValues = useCallback(
        (open: () => void) => [
            {
                name: 'Preview item',
                dataTestId: 'preview-item',
                onClick: () => executePreview(),
            },
            {
                name: 'Duplicate item',
                dataTestId: 'duplicate-item',
                disabled: editDisabled,
                onClick: () => {
                    const duplicatedItemId = ItemEntityService.duplicateItem(itemId).id
                    ItemGroupHandler.addItem(workflowEntityId, duplicatedItemId, itemIndex)
                    focusOnId(nextItemFocusId)
                },
            },
            {
                name: 'Run error check',
                dataTestId: 'run-item-error-check',
                disabled: editDisabled,
                onClick: () => runErrorCheck(),
            },
            {
                name: 'Delete item',
                dataTestId: 'delete-item',
                disabled: editDisabled,
                onClick: () => {
                    setOpenModal(ItemOptionsModal.DELETE_ITEM)
                    open()
                },
            },
        ],
        [
            editDisabled,
            executePreview,
            itemId,
            workflowEntityId,
            itemIndex,
            nextItemFocusId,
            runErrorCheck,
        ]
    )

    const renderModal = useCallback(
        ({ close }: { close: () => void }) => {
            return openModal === ItemOptionsModal.DELETE_ITEM ? (
                <DeleteItemModal
                    onClose={() => {
                        close()
                    }}
                    onDone={() => {
                        ItemGroupHandler.removeItem(workflowEntityId, itemId)
                        ItemEntityService.remove(itemId)
                        const prevItemFocusId = itemFocusId(pageIndex + 1, itemIndex)
                        const endOfPageFocusId = endOfPageFocusTargetId(pageIndex + 1)
                        focusOnId(prevItemFocusId, endOfPageFocusId)
                    }}
                />
            ) : (
                <></>
            )
        },
        [openModal, itemId, workflowEntityId, pageIndex, itemIndex]
    )

    const showSpinner = generatingPreview || loading
    const modalBody = useCallback(
        ({ open }: { open: () => void }) => (
            <>
                {showSpinner ? <Spinner dataTestId='preview-spinner' /> : null}
                <div style={{ visibility: showSpinner ? 'hidden' : undefined }}>
                    <DropdownButton
                        title='Item options'
                        values={dropdownValues(open)}
                        dataTestId='options-dropdown'
                        buttonProps={small ? { style: { width: '100%' } } : {}}
                    />
                </div>
            </>
        ),
        [small, dropdownValues, showSpinner]
    )

    return (
        <>
            <PreviewErrorModal
                title={'Preview item'}
                close={() => setPreviewErrorCount(undefined)}
                errorCount={previewErrorCount}
            />
            <WithModal isScrollable renderModal={renderModal}>
                {modalBody}
            </WithModal>
        </>
    )
}
