import React, { useEffect, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    Checkbox,
    Input,
    InputWrapper,
    LabelPosition,
    Select,
} from '@amzn/stencil-react-components/form'
import { IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'

import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import { MESCreateEditValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import { focusOnId } from 'src/pages/module-builder/focus'
import { ModuleGroupInformationChangeSummary } from 'src/services/module-groups/ModuleGroupEditUtils'

export interface SubstituteScoresEditProps {
    editModuleGroupInformationSummary: ModuleGroupInformationChangeSummary
    setEditModuleGroupInformationSummary: (newSummary: ModuleGroupInformationChangeSummary) => void
    primaryModuleVersionId: string
    primaryModuleScores: string[]
    existingSubstituteScores: string[]
    setValidationMessage: (validationMessage: string) => void
    mesValidation?: MESCreateEditValidationDTO
    setMesValidation?: (mesValidation: MESCreateEditValidationDTO) => void
}

export const SubstituteScoresEdit = (props: SubstituteScoresEditProps) => {
    const [selectedModuleScores, setSelectedModuleScores] = useState<string[]>()
    const [removedScores, setRemovedScores] = useState<string[]>(
        props.editModuleGroupInformationSummary.removedScores
    )
    const [addedScores, setAddedScores] = useState<string[]>(
        props.editModuleGroupInformationSummary.addedPrimaryScores
    )
    const [defaultOrPrimaryChange, setDefaultOrPrimaryChange] = useState(
        props.editModuleGroupInformationSummary.defaultOrPrimaryChange
    )
    const [replacedScores, setReplacedScores] = useState(
        props.editModuleGroupInformationSummary.replacedScores
    )

    const newSubstituteScores = props.mesValidation?.newSubstituteScores

    useEffect(() => {
        setRemovedScores([...props.editModuleGroupInformationSummary.removedScores])
        setAddedScores([...props.editModuleGroupInformationSummary.addedPrimaryScores])
        setReplacedScores(
            new Map<string, string>(props.editModuleGroupInformationSummary.replacedScores)
        )
        setDefaultOrPrimaryChange({
            ...props.editModuleGroupInformationSummary.defaultOrPrimaryChange,
        })
    }, [props.editModuleGroupInformationSummary])

    useEffect(() => {
        const replacedScoresValues = Array.from(replacedScores.values())
        const replacedScoresKeys = Array.from(replacedScores.keys())

        if (defaultOrPrimaryChange.new) {
            setSelectedModuleScores([...replacedScoresValues, ...addedScores])
        } else {
            const notRemovedOrReplacedScores = props.existingSubstituteScores.filter(
                (subScore) =>
                    !removedScores.includes(subScore) && !replacedScoresKeys.includes(subScore)
            )
            setSelectedModuleScores([
                ...notRemovedOrReplacedScores,
                ...addedScores,
                ...replacedScoresValues,
            ])
        }
    }, [
        addedScores,
        defaultOrPrimaryChange.new,
        props.existingSubstituteScores,
        removedScores,
        replacedScores,
    ])

    const replaceSubstituteScore = (newValue: string, substituteScore: string) => {
        const replaceMap = replacedScores
        replaceMap.set(substituteScore, newValue)
        props.setEditModuleGroupInformationSummary({
            ...props.editModuleGroupInformationSummary,
            replacedScores: replaceMap,
        })
    }

    const removeExistingSubstituteScore = (substituteScore: string) => {
        const replaceMap = replacedScores
        const updatedRemovedScores = removedScores
        updatedRemovedScores.push(substituteScore)
        if (replaceMap.has(substituteScore)) {
            replaceMap.delete(substituteScore)
        }
        props.setEditModuleGroupInformationSummary({
            ...props.editModuleGroupInformationSummary,
            removedScores: [...updatedRemovedScores],
            replacedScores: replaceMap,
        })
    }

    const editAddedSubScore = (substituteScore: string, index: number) => {
        const updatedAddedSubScores = addedScores
        updatedAddedSubScores[index] = substituteScore
        props.setEditModuleGroupInformationSummary({
            ...props.editModuleGroupInformationSummary,
            addedPrimaryScores: updatedAddedSubScores,
        })
    }

    const deleteAddedScore = (index: number) => {
        const updatedAddedSubScores = addedScores
        updatedAddedSubScores.splice(index, 1)
        props.setEditModuleGroupInformationSummary({
            ...props.editModuleGroupInformationSummary,
            addedPrimaryScores: updatedAddedSubScores,
        })
    }

    const getSelectValue = (substituteScore: string) => {
        const isScoreRemoved = removedScores.includes(substituteScore)
        return !isScoreRemoved ? replacedScores.get(substituteScore) || '' : 'Deleted'
    }

    const handleSubstituteScoreCheckboxChange = (checked: boolean, substituteScore: string) => {
        const replaceMap = replacedScores
        checked ? replaceMap.delete(substituteScore) : replaceMap.set(substituteScore, '')
        props.setEditModuleGroupInformationSummary({
            ...props.editModuleGroupInformationSummary,
            replacedScores: replaceMap,
        })
    }

    const getFooterLabel = (substituteScore: string) => {
        const isDeleted = removedScores.includes(substituteScore)
        return isDeleted ? 'This score has been deleted.' : undefined
    }

    const getInformationText = () => {
        return defaultOrPrimaryChange.new
            ? 'You need to choose the score that is equivalent in the new reference module. This will update the connections in all existing modules in this group.'
            : 'You can edit, replace or add equivalent scores below. Edited scores will automatically be replaced in your existing module equivalencies once you save.'
    }

    const shouldDisabledCheckbox = (substituteScore: string): boolean => {
        return (
            Array.from(replacedScores.values()).includes(substituteScore) ||
            addedScores.includes(substituteScore)
        )
    }

    const newSubsituteScoreSelectIsDisabled = (substituteScore: string) => {
        return (
            removedScores.includes(substituteScore) ||
            (!defaultOrPrimaryChange.new && !replacedScores.has(substituteScore))
        )
    }

    const clearSubstituteError = (index: number) => {
        if (props.setMesValidation && props.mesValidation?.substituteScores) {
            const updatedSubstituteScores = props.mesValidation.substituteScores
            updatedSubstituteScores.set(index, '')
            props.setMesValidation({
                ...props.mesValidation,
                substituteScores: updatedSubstituteScores,
            })
        }
        props.setValidationMessage('')
    }

    const clearNewSubstituteError = (index: number) => {
        if (props.setMesValidation && props.mesValidation && newSubstituteScores) {
            const updatedNewSubScores = newSubstituteScores
            updatedNewSubScores[index] = ''
            props.setMesValidation({
                ...props.mesValidation,
                newSubstituteScores: updatedNewSubScores,
            })
        }
        props.setValidationMessage('')
    }

    const deleteNewSubstituteError = (index: number) => {
        if (props.setMesValidation && props.mesValidation && newSubstituteScores) {
            const updatedNewSubScores = newSubstituteScores
            updatedNewSubScores.splice(index, 1)
            props.setMesValidation({
                ...props.mesValidation,
                newSubstituteScores: updatedNewSubScores,
            })
        }
        props.setValidationMessage('')
    }

    return (
        <Col gridGap='S200'>
            <Col gridGap='S300'>
                <Spacer height='S200' />
                <Text fontSize='T300'>{getInformationText()}</Text>
                {props.primaryModuleScores.length === 0 && (
                    <MessageBanner
                        type={MessageBannerType.Warning}
                        dataTestId='no-primary-score-warning'
                    >
                        The selected Reference Module does not have any scores
                    </MessageBanner>
                )}
                <ul>
                    <Col gridGap='S300'>
                        {props.existingSubstituteScores.map((substituteScore: string, index) => (
                            <li
                                key={`${substituteScore}`}
                                aria-label={`edit-substitute-${index + 1}`}
                            >
                                <Row gridGap='S300' flex={1}>
                                    <View flex={1}>
                                        <InputWrapper
                                            id={`old-substitute-score-wrapper-${substituteScore}`}
                                            labelText={`Current Score ${index + 1}`}
                                            warning={!!getFooterLabel(substituteScore)}
                                            footer={getFooterLabel(substituteScore)}
                                        >
                                            {(inputProps) => (
                                                <Input
                                                    {...inputProps}
                                                    value={substituteScore}
                                                    disabled
                                                />
                                            )}
                                        </InputWrapper>
                                    </View>
                                    <Col flex={1} gridGap='S100'>
                                        <InputWrapper
                                            id={`substitute-score-wrapper-${substituteScore}`}
                                            labelText={`New Score ${index + 1}`}
                                            required={
                                                !newSubsituteScoreSelectIsDisabled(substituteScore)
                                            }
                                            error={
                                                !!props.mesValidation?.substituteScores?.get(index)
                                            }
                                            footer={
                                                props.mesValidation?.substituteScores?.get(index)
                                                    ? props.mesValidation.substituteScores.get(
                                                          index
                                                      )
                                                    : undefined
                                            }
                                        >
                                            {(inputProps) => (
                                                <Select
                                                    {...inputProps}
                                                    options={[
                                                        '',
                                                        ...props.primaryModuleScores.filter(
                                                            (primaryScore) =>
                                                                !selectedModuleScores?.includes(
                                                                    primaryScore
                                                                )
                                                        ),
                                                    ]}
                                                    value={getSelectValue(substituteScore)}
                                                    onChange={(value: string) => {
                                                        replaceSubstituteScore(
                                                            value,
                                                            substituteScore
                                                        )
                                                        clearSubstituteError(index)
                                                    }}
                                                    disabled={newSubsituteScoreSelectIsDisabled(
                                                        substituteScore
                                                    )}
                                                    renderOption={ModuleGroupRenderOption}
                                                />
                                            )}
                                        </InputWrapper>
                                        {!defaultOrPrimaryChange.new &&
                                            !removedScores.includes(substituteScore) && (
                                                <InputWrapper
                                                    id={`keep-score-checkbox-${substituteScore}`}
                                                    labelText='Keep the same'
                                                    labelPosition={LabelPosition.Trailing}
                                                >
                                                    {(inputProps) => (
                                                        <Checkbox
                                                            dataTestId={`keep-score-${substituteScore}`}
                                                            name={`keep-score-${substituteScore}`}
                                                            checked={
                                                                !replacedScores.has(substituteScore)
                                                            }
                                                            onChange={(e) => {
                                                                handleSubstituteScoreCheckboxChange(
                                                                    e.target.checked,
                                                                    substituteScore
                                                                )
                                                                clearSubstituteError(index)
                                                            }}
                                                            {...inputProps}
                                                            disabled={shouldDisabledCheckbox(
                                                                substituteScore
                                                            )}
                                                        />
                                                    )}
                                                </InputWrapper>
                                            )}
                                    </Col>
                                    <Col>
                                        <Spacer height='S500' />
                                        <Button
                                            icon={<IconBin title='Delete item' />}
                                            isDestructive
                                            dataTestId={`edit-score-${substituteScore}-delete`}
                                            variant={ButtonVariant.Tertiary}
                                            onClick={() => {
                                                removeExistingSubstituteScore(substituteScore)
                                                clearSubstituteError(index)
                                            }}
                                            aria-disabled={removedScores.includes(substituteScore)}
                                        />
                                    </Col>
                                </Row>
                            </li>
                        ))}
                    </Col>
                </ul>
            </Col>
            <Col gridGap='S200'>
                <ul>
                    {addedScores.map((addedSubScore, index) => (
                        <li
                            key={`${addedSubScore}-${index}`}
                            aria-label={`Added substitute ${index + 1}`}
                        >
                            <Row gridGap='S200'>
                                <View width='90%'>
                                    <InputWrapper
                                        id={`added-score-wrapper-${index}`}
                                        labelText={`Added Score ${index + 1}`}
                                        required
                                        error={
                                            newSubstituteScores
                                                ? !!newSubstituteScores[index]
                                                : undefined
                                        }
                                        footer={
                                            newSubstituteScores && newSubstituteScores[index]
                                                ? newSubstituteScores[index]
                                                : undefined
                                        }
                                    >
                                        {(inputProps) => (
                                            <Select
                                                {...inputProps}
                                                renderOption={ModuleGroupRenderOption}
                                                options={[
                                                    '',
                                                    ...props.primaryModuleScores.filter(
                                                        (score) =>
                                                            !selectedModuleScores?.includes(score)
                                                    ),
                                                ]}
                                                value={addedSubScore || ''}
                                                id={`substitute-${index}-select`}
                                                dataTestId={`substitute-${index}-select`}
                                                onChange={(value: string) => {
                                                    editAddedSubScore(value, index)
                                                    focusOnId(
                                                        `substitute-${index}-select-toggle-button`
                                                    )
                                                    clearNewSubstituteError(index)
                                                }}
                                            />
                                        )}
                                    </InputWrapper>
                                </View>
                                <Col>
                                    <Spacer height='S500' />
                                    <Button
                                        icon={<IconBin title='Delete item' />}
                                        isDestructive
                                        dataTestId={`substitute-${index}-delete`}
                                        variant={ButtonVariant.Tertiary}
                                        onClick={() => {
                                            deleteAddedScore(index)
                                            focusOnId(
                                                `substitute-${
                                                    index !== 0 ? index - 1 : 0
                                                }-select-toggle-button`
                                            )
                                            deleteNewSubstituteError(index)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
                {!(selectedModuleScores?.length === props.primaryModuleScores.length) && (
                    <View justifyContent='left'>
                        <Button
                            dataTestId='add-substitute-score-button'
                            id='add-substitute-score-button'
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.Small}
                            onClick={() => {
                                props.setEditModuleGroupInformationSummary({
                                    ...props.editModuleGroupInformationSummary,
                                    addedPrimaryScores: [...addedScores, ''],
                                })
                                focusOnId(`substitute-${addedScores.length}-select-toggle-button`)
                            }}
                            aria-disabled={
                                selectedModuleScores?.length === props.primaryModuleScores.length
                            }
                        >
                            <Row gridGap='S300'>
                                <IconPlus />
                                <Text fontSize='T100' lineHeight={1.3}>
                                    Add another Equivalent Score
                                </Text>
                            </Row>
                        </Button>
                    </View>
                )}
            </Col>
        </Col>
    )
}
