import React, { useEffect } from 'react'

import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { View } from '@amzn/stencil-react-components/layout'

import { STAGE } from 'src/config.app'
import { HookAuthenticator } from 'src/pages/scoring-test-ui/authentication/HookAuthenticator'

export interface StageSelectionProps {
    designerEnv: STAGE
    showOnDesignerEnvs: STAGE[]
    hookStageOptions: STAGE[]
    selectedHookStage: STAGE
    setSelectedHookStage: (stage: STAGE) => void
    width?: string
}
export const StageSelection = (props: StageSelectionProps) => {
    useEffect(() => {
        HookAuthenticator.initCognitoAuth(props.selectedHookStage)
        HookAuthenticator.authenticate(() => {})
    }, [props.selectedHookStage])

    return (
        <View width={props.width ? props.width : undefined}>
            <InputWrapper labelText='Environment' id='stage-selection-wrapper'>
                {(inputProps) => (
                    <Select
                        {...inputProps}
                        value={props.selectedHookStage}
                        onChange={props.setSelectedHookStage}
                        options={props.hookStageOptions}
                        renderOption={(option: STAGE) => option.toUpperCase()}
                        disabled={!props.showOnDesignerEnvs.includes(props.designerEnv)}
                    />
                )}
            </InputWrapper>
        </View>
    )
}
