import { defaultEmailItemDTO, EmailContentItemDTO } from 'src/models/dto/items/EmailContentItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, STORE_ACTION, Stores } from 'src/services/Store'

const itemType = ItemType.EmailContent

export class EmailContentHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => EmailContentHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): EmailContentItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as EmailContentItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): EmailContentItemDTO {
        return defaultEmailItemDTO()
    }

    private static update(entity: EmailContentItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as EmailContentItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as EmailContentItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as EmailContentItemDTO)
    }

    static updateTo(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.toI18N[locale] = nextValue
        this.update(entity)
    }

    static updateFrom(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.fromI18N[locale] = nextValue
        this.update(entity)
    }

    static updateSubject(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.subjectI18N[locale] = nextValue
        this.update(entity)
    }

    static updateMessage(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.messageI18N[locale] = nextValue
        this.update(entity)
    }
}
