import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Table, TableColumn } from '@amzn/stencil-react-components/table'

import { Button } from 'src/components/Button'
import { useEntity } from 'src/hooks/useEntity'
import { MUPPExamEntity, PairingTableDTO } from 'src/models/dto/activities/MUPPExamDTO'
import { Dimensions } from 'src/models/dto/mupp/PairingTableDTO'
import { PairingTableCellSummary } from 'src/pages/pairing-table-generator/PairingTableCellVisual'
import { ACTIVITY_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ActivityEntityService'
import { MUPPExamHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/MUPPExamHandler'
import {
    AutoPairingTablesRequest,
    AutoPairingTablesService,
} from 'src/services/mupp/AutoPairingTablesService'

export interface GeneratedPairingTablesModalProps {
    muppExamID: string
    dimensionsValue: string[]
    isOpen: boolean
    close: (value: boolean) => void
}

export const GeneratedPairingTablesModal = (props: GeneratedPairingTablesModalProps) => {
    const { entity: muppExamDTO } = useEntity<MUPPExamEntity>({
        entityId: props.muppExamID,
        selector: ACTIVITY_ENTITY_STORE_SELECTOR,
    })

    const [pairingTablesLoading, setPairingTablesLoading] = useState<boolean>(false)
    const [generatingPairingTablesError, setGeneratingPairingTablesError] = useState<boolean>(false)
    const [generatedPairingTables, setGeneratedPairingTables] = useState<
        { pairingTable: PairingTableDTO; index: number; action: React.ReactElement }[]
    >([])

    useEffect(() => {
        const source = axios.CancelToken.source()
        let componentUnmounted = false
        setPairingTablesLoading(true)
        setGeneratingPairingTablesError(false)
        const fetchData = async () => {
            const res = await AutoPairingTablesService.generateAutoPairingTables(
                {
                    dimensions: props.dimensionsValue.map(
                        (d) => Dimensions.find((dim) => dim.friendlyName === d)?.systemName ?? d
                    ),
                } as AutoPairingTablesRequest,
                source.token
            )

            if (componentUnmounted) {
                return
            }

            setPairingTablesLoading(false)
            const returnedPairingTables =
                res.pairingTables?.map((pt: PairingTableDTO, index: number) => {
                    const action: React.ReactElement = (
                        <Button
                            key={`select-${index}`}
                            dataTestId={`select-${index}`}
                            onClick={() => {
                                props.close(false)
                                MUPPExamHandler.update({
                                    ...muppExamDTO,
                                    selection: {
                                        ...muppExamDTO.selection,
                                        pairingTableDTO: pt,
                                    },
                                })
                            }}
                        >
                            Select
                        </Button>
                    )

                    return {
                        pairingTable: pt,
                        index: index + 1,
                        action,
                    }
                }) || []

            setGeneratedPairingTables(returnedPairingTables)
        }

        fetchData().catch(() => {
            if (componentUnmounted) {
                return
            }

            setPairingTablesLoading(false)
            setGeneratingPairingTablesError(true)
        })

        return () => {
            componentUnmounted = true
            source.cancel()
        }
    }, [muppExamDTO, props])

    const getGeneratedPairingTablesView = () => {
        if (pairingTablesLoading) {
            return (
                <Row alignItems={'center'}>
                    <Spinner
                        loadingText={'Loading'}
                        dataTestId={'generating-pairing-tables-spinner'}
                    />
                    Generating Pairing Tables, this could take up to 30 seconds...
                </Row>
            )
        }

        if (!generatingPairingTablesError) {
            const columns: TableColumn<typeof generatedPairingTables[0]>[] = [
                { header: ' ', accessor: 'index' },
                {
                    header: 'Pairing Table',
                    accessor: 'pairingTable',
                    cellComponent: PairingTableCellSummary,
                },
                { header: 'Action', accessor: 'action' },
            ]

            return (
                <Table
                    dataTestId={'generated-pairing-tables-table'}
                    getRowAttributes={({ index }) => {
                        return {
                            dataTestId: `generated-pairing-tables-table-row-${index}`,
                            'aria-label': `generated-pairing-tables-table-row-${index}`,
                        }
                    }}
                    data={generatedPairingTables}
                    actionHeader=' '
                    columns={columns}
                />
            )
        } else
            return (
                <MessageBanner
                    type={MessageBannerType.Error}
                    key='error-message-banner'
                    dataTestId='server-error-message-banner'
                >
                    Something went wrong generating pairing tables
                </MessageBanner>
            )
    }
    return (
        <Modal
            isOpen={props.isOpen}
            close={() => props.close(false)}
            shouldCloseOnClickOutside={false}
        >
            <ModalContent
                maxWidth='100vw'
                titleText={'Generated Pairing Tables'}
                buttons={[
                    <Button
                        key='close-button'
                        dataTestId='close-button'
                        onClick={() => props.close(false)}
                    >
                        close
                    </Button>,
                ]}
            >
                {getGeneratedPairingTablesView()}
            </ModalContent>
        </Modal>
    )
}
