import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface EmailContentItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.EmailContent
    fromI18N: LocalizedAttribute<string>
    toI18N: LocalizedAttribute<string>
    subjectI18N: LocalizedAttribute<string>
    messageI18N: LocalizedAttribute<string>
}

export const defaultEmailItemDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.EmailContent,
        ppt: ItemType.EmailContent,
        fromI18N: LocalizeDefault(''),
        toI18N: LocalizeDefault(''),
        subjectI18N: LocalizeDefault(''),
        messageI18N: LocalizeDefault(''),
    } as EmailContentItemDTO
}
