import React from 'react'

import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { SingleSelectCheckboxItemDTO } from 'src/models/dto/items/SingleSelectCheckboxItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { SingleSelectCheckboxHandler } from 'src/services/EntityServices/ItemUpdateHandlers/SingleSelectCheckboxHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const SingleSelectCheckboxItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<SingleSelectCheckboxItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        setValue={(nextValue: string) => {
                            SingleSelectCheckboxHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            SingleSelectCheckboxHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                SingleSelectCheckboxHandler.updatePreserveOrder(
                                    itemDTO.id,
                                    nextValue
                                )
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </ResponsiveRow>
            <Spacer height={'S200'} />
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex='1'>
                    <ItemEditorTextArea
                        inputId={'statement'}
                        locale={locale}
                        value={itemDTO.statementI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            SingleSelectCheckboxHandler.updateStatement(
                                itemDTO.id,
                                locale,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.statementI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Statement'}
                    />
                </Col>
            </ResponsiveRow>
        </>
    )
}
