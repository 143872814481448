export interface JobMetadataResponse {
    category: string
    value: string
    description: string
}

// eslint-disable-next-line no-magic-numbers
export const JOB_LEVELS = [1, 2, 3, 4, 5, 6, 7, 8, 9]
export const HIRE_TYPES: JobMetadataResponse[] = [
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'ACQUISITION',
        description: 'Acquisition',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'BACKFILL_AHEAD',
        description: 'Backfill Ahead Of Attrition',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'BACKFILL_KNOWN',
        description: 'Backfill For Known Employee',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'CAMPUS_FTE',
        description: 'Campus Fte',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'CAMPUS_INTERN',
        description: 'Campus Intern',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'EXPLORATORY',
        description: 'Exploratory',
    },
    {
        category: 'JOB_HIRE_TYPE_ID',
        value: 'NEW_POSITION',
        description: 'New Position',
    },
]

export const ROLE_KINDS: JobMetadataResponse[] = [
    {
        category: 'JOB_ROLE_KIND',
        value: 'INDIVIDUAL_CONTRIBUTOR',
        description: 'Individual Contributor',
    },
    {
        category: 'JOB_ROLE_KIND',
        value: 'PEOPLE_MANAGER',
        description: 'People Manager',
    },
]

export const BUSINESS_PURPOSES: JobMetadataResponse[] = [
    {
        category: 'JOB_BUSINESS_PURPOSE',
        value: 'AWS_ECT',
        description: 'AWS - Early Career Talent',
    },
    {
        category: 'JOB_BUSINESS_PURPOSE',
        value: 'OPS_UNIVERSITY',
        description: 'Ops University',
    },
    {
        category: 'JOB_BUSINESS_PURPOSE',
        value: 'OTHER',
        description: 'Other',
    },
    {
        category: 'JOB_BUSINESS_PURPOSE',
        value: 'STUDENT_PROGRAMS',
        description: 'Student Programs',
    },
]
