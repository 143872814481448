import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { PopoverController } from '@amzn/stencil-react-components/popover'

import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { LocationId } from 'src/models/dto/approval/CommentDTO'
import { AddCommentForm } from 'src/pages/module-review/Comments'
import { ModuleReviewComments } from 'src/pages/module-review/ModuleReviewBox'

function CommentsPopoverInner(props: { locationId: LocationId }) {
    const l = props.locationId
    const { reviewId, revisionNumber, commentsByLocation } = useContext(ModuleReviewContext)
    const [isOpen, setIsOpen] = useState(false)
    const target = useRef<HTMLButtonElement | null>(null)

    const toggle = useCallback(() => {
        setIsOpen((b) => !b)
    }, [])
    const close = useCallback(() => {
        setIsOpen(false)
    }, [])
    const comments = useMemo(() => commentsByLocation[l] || [], [commentsByLocation, l])

    const commentRef = useRef<HTMLDivElement | null>(null)

    const onCommentPosted = useCallback(() => {
        commentRef?.current?.focus()
    }, [commentRef])

    return reviewId && revisionNumber ? (
        <span style={{ alignSelf: 'center' }}>
            <Button
                ref={target}
                onClick={toggle}
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                dataTestId='open-comments-popover'
                className={`open-comments-popover ${comments.length > 0 ? 'has-comments' : ''}`}
                title='View/add comments'
            >
                {`(${comments.length} comment${comments.length === 1 ? '' : 's'})`}
            </Button>
            <PopoverController
                shouldFocusOnOpen={true}
                target={target}
                isOpen={isOpen}
                close={close}
            >
                <Col
                    minWidth={400}
                    maxWidth='calc(min(800px, 100vw - 4px))'
                    padding='S200'
                    style={{ borderRadius: 10 }}
                    dataTestId='comments-popover'
                >
                    <AddCommentForm
                        {...{ reviewId, revisionNumber }}
                        locationId={l}
                        onCommentPosted={onCommentPosted}
                    />
                    <View
                        aria-label={`${comments?.length} comments, sorted by latest comment first`}
                        tabIndex={-1}
                        ref={commentRef}
                        padding='S200'
                        style={{ maxHeight: 300, overflow: 'auto' }}
                    >
                        <ModuleReviewComments comments={comments} />
                    </View>
                </Col>
            </PopoverController>
        </span>
    ) : null
}

export const CommentsPopover = React.memo(CommentsPopoverInner)
