import React from 'react'

import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { View } from '@amzn/stencil-react-components/layout'

import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleGetAndValidateInput } from 'src/pages/module-groups/ModuleGetAndValidateInput'

export const ModuleVersionSelectAndDisplay = ({
    selectedModule,
    setSelectedModule,
    setModuleScores,
    selectedModuleVersionId,
    setSelectedModuleVersionId,
    setIsInUATOrMore,
    isInUATOrMore,
    moduleValidation,
    setModuleValidation,
}: {
    selectedModule: ModuleGroupModuleMetadata | undefined
    setSelectedModule: (selectedModule: ModuleGroupModuleMetadata | undefined) => void
    setModuleScores: (moduleScores: string[]) => void
    selectedModuleVersionId: string
    setSelectedModuleVersionId: (selectedModuleVersionId: string) => void
    setIsInUATOrMore: (isInUATOrMore: boolean) => void
    isInUATOrMore: boolean
    moduleValidation: string | undefined
    setModuleValidation: (moduleValidation: string | undefined) => void
}) => {
    return (
        <>
            <View maxWidth='70%'>
                <ModuleGetAndValidateInput
                    setSelectedModule={setSelectedModule}
                    setModuleScores={setModuleScores}
                    moduleVersionId={selectedModuleVersionId}
                    setModuleVersionId={setSelectedModuleVersionId}
                    setIsInUATOrMore={setIsInUATOrMore}
                    isInUATOrMore={isInUATOrMore}
                    moduleValidation={moduleValidation}
                    setModuleValidation={setModuleValidation}
                />
            </View>
            <InputWrapper id='module-name-input-wrapper' labelText='Module Name' required>
                {(inputProps) => (
                    <Input
                        dataTestId='module-name-input'
                        {...inputProps}
                        value={selectedModule ? selectedModule.name : ''}
                        disabled={true}
                    />
                )}
            </InputWrapper>
            <InputWrapper id='module-id-input-wrapper' labelText='Module ID' required>
                {(inputProps) => (
                    <Input
                        dataTestId='module-id-input'
                        {...inputProps}
                        value={selectedModule ? selectedModule.id : ''}
                        disabled={true}
                    />
                )}
            </InputWrapper>
        </>
    )
}
