import { BaseTableDTO } from 'src/models/dto/items/BaseTableDTO'
import { defaultBaseItemDTO, ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export enum MultipleChoiceDisplayType {
    radio = 'radio',
    tile = 'tile',
    dropdown = 'dropdown',
    Image = 'Image',
    Columns = 'Columns',
    TABLE = 'TABLE',
}

export enum MultipleChoiceType {
    SingleSelect = 'SingleSelect',
    MultiSelect = 'MultiSelect',
}

export interface MultipleChoiceResponseDTO extends MediaElement {
    responseI18N: LocalizedAttribute<string>
    score?: number | null
    responseLabel?: string
}

export interface FreeTextResponseDTO {
    responseEnabled: boolean
    freeTextResponseI18N: LocalizedAttribute<string>
}

export interface MultipleChoiceItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.MultipleChoice
    questionI18N: LocalizedAttribute<string>
    responses: MultipleChoiceResponseDTO[]
    multipleChoiceType: MultipleChoiceType
    numRequiredResponses?: number
    displayType: MultipleChoiceDisplayType
    responseOrderRandomizationEnabled: boolean
    tableResponses: BaseTableDTO
    freeTextResponse: FreeTextResponseDTO
}

export const defaultMultipleChoiceItemDTO = () => {
    return {
        ...defaultBaseItemDTO(ItemType.MultipleChoice),
        questionI18N: LocalizeDefault<string>(''),
        responses: [
            {
                responseI18N: LocalizeDefault<string>(''),
                score: null,
                responseLabel: '',
            } as MultipleChoiceResponseDTO,
            {
                responseI18N: LocalizeDefault<string>(''),
                score: null,
                responseLabel: '',
            } as MultipleChoiceResponseDTO,
        ],
        multipleChoiceType: MultipleChoiceType.SingleSelect,
        displayType: MultipleChoiceDisplayType.radio,
        responseOrderRandomizationEnabled: false,
        tableResponses: {
            legends: [],
            headers: [],
            rows: [],
            harveyBallLegendEnabled: false,
            symbolLegendEnabled: false,
        },
        freeTextResponse: {
            responseEnabled: false,
            freeTextResponseI18N: LocalizeDefault<string>(''),
        },
    } as MultipleChoiceItemDTO
}
