import { v4 } from 'uuid'

import { ItemGroupDTO } from 'src/models/dto/activities/ItemGroupDTO'
import { InstructionalContentDTO } from 'src/models/dto/InstructionalContentDTO'
import { Locale } from 'src/models/dto/Locale'
import { ActivityDTO, ActivityType, WorkflowItem } from './ActivityDTO'

export enum ModuleUserType {
    Candidate = 'Candidate',
    MTurkWorker = 'MTurkWorker',
}

export enum ModuleLayout {
    Default = 'Default',
    Email = 'Email',
    Tier1 = 'Tier1',
    VerticalEvaluation = 'VerticalEvaluation',
    Email2_0 = 'Email2_0',
    CustomerFacing = 'CustomerFacing',
    Rules = 'Rules',
}

export enum QueryParams {
    OFFSET = 'offset',
    RECORDS_PER_PAGE = 'numberOfRecordsPerPage',
    SEARCH = 'search',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    OWNER = 'owner',
    TYPE = 'moduleType',
    STATUS = 'moduleStatus',
    SORT_FIELD = 'sortField',
    SORT_DIRECTION = 'sortDirection',
    LAST_MODIFIED_BY = 'lastModifiedBy',
    SELECTED_PAGE = 'selectedPage',
    ARCHIVED = 'archived',
}

export enum ModuleType {
    Other = 'Other',
    Evaluation = 'Evaluation',
    Landing = 'Landing',
    Transition = 'Transition',
    Instructional = 'Instructional',
    Content = 'Content',
    ItemsContainer = 'ItemsContainer',
}

export interface AssessmentMetadataDTO {
    minTimeToCompleteModuleInMinutes?: number
    maxTimeToCompleteModuleInMinutes?: number
    resultValidityInDays?: number
}

export interface ModuleTimerDTO {
    timeLimit?: number
    warningConfigs?: ModuleTimerWarningDTO[]
}

export interface ModuleTimerWarningDTO {
    timeRemaining?: number
}

export interface ModuleDTO {
    id: string
    version: string
    name: string
    workflow: ActivityDTO[]
    availableLocales: Locale[]
    autoProgressionOn?: boolean
    status: string
    userType?: ModuleUserType
    layout?: ModuleLayout
    moduleType?: ModuleType
    instructionalContent?: InstructionalContentDTO
    evaluationModule?: boolean
    compositeScores: EvaluationScoreDTO[]
    muppItemBankId?: string
    assessmentMetadata?: AssessmentMetadataDTO
    moduleTimerConfig?: ModuleTimerDTO
    usingMediaManager?: boolean
}

/**
 * Note: For ModuleEntity store, we will use `version` field as key, not `id` field
 * Reason: `id` field for ModuleDTO indicate moduleId instead of versionId
 * It is different from `id` field for ItemDTO or ItemGroupDTO which indicate versionId for Item and ItemGroup
 */
export type ModuleEntity = Omit<ModuleDTO, 'workflow' | 'instructionalContent'> & {
    workflowIds: string[]
    instructionalContentId?: string
    mturkPaymentCodeItemId?: string
}

export const defaultPage: () => ActivityDTO = () => {
    const newId = v4()
    return {
        moduleChild: {
            id: newId,
            name: newId,
            type: ActivityType.LaunchItemGroup,
            ppt: ActivityType.LaunchItemGroup,
            description: '',
            items: [],
            randomizationEnabled: false,
        } as WorkflowItem & Partial<ItemGroupDTO>,
    }
}

export const defaultModuleDTO = () => {
    return {
        name: '',
        id: v4(),
        version: v4(),
        availableLocales: [Locale.en_US],
        autoProgressionOn: false,
        status: '',
        workflow: [defaultPage()],
        compositeScores: [],
        usingMediaManager: true,
    } as ModuleDTO
}

export interface EvaluationScoreDTO {
    compositeScoreLabel: string
    compositeScoreExpression: string
}
