import { APP_CONFIG, Features } from 'src/config.app'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { getEnumKeyByEnumValue } from 'src/models/EnumUtils'

export const isFeatureEnabled = (featureName: Features): boolean => {
    if (!APP_CONFIG || !APP_CONFIG.featureFlags) {
        return false
    }
    return APP_CONFIG.featureFlags[featureName]
}

export const getStatusValue = (status: ModuleStatus): string => {
    if (
        !isFeatureEnabled(Features.FAST_TRACK_APPROVAL) &&
        status === getEnumKeyByEnumValue(ModuleStatus, ModuleStatus.PUBLISHED)
    ) {
        return 'Published'
    }

    return ModuleStatus[status as keyof typeof ModuleStatus]
}
