import React, { useEffect, useMemo, useState } from 'react'

import { InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form'

import { getReviewersList } from 'src/config.app'
import {
    CreateModuleReviewInput,
    ModuleGroupReviewRequest,
} from 'src/models/dto/approval/ReviewDTO'
import { Authenticator } from 'src/services/Authenticator'

interface ModuleGroupReviewFormProps {
    versionId: string
    onCreateReviewInputChange?: (review: CreateModuleReviewInput) => void
    onCreateGroupReviewInputChange?: (review: ModuleGroupReviewRequest) => void
    reviewIds?: string[]
    moduleName: string
    moduleReview?: boolean
    descriptionError: boolean
    setDescriptionError: (descriptionError: boolean) => void
}

export function ModuleGroupReviewForm(props: ModuleGroupReviewFormProps) {
    const {
        versionId,
        reviewIds,
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        moduleName,
        moduleReview,
        descriptionError,
        setDescriptionError,
    } = props
    const reviewId = useMemo(() => (reviewIds?.length ? reviewIds[0] : ''), [reviewIds])
    const requester = Authenticator.getDefaultUser()
    const [description, setDescription] = useState('')
    const [reviewers, setReviewers] = useState([])

    useEffect(() => {
        if (moduleReview) {
            onCreateReviewInputChange?.({
                moduleVersionId: versionId,
                reviewId: reviewId || undefined,
                title: moduleName,
                description,
                requester,
                reviewers: reviewers,
                reviewUrl: window.location.origin.concat(`/module/viewer/${versionId}/review`),
            })
        } else {
            onCreateGroupReviewInputChange?.({
                versionId: versionId,
                title: moduleName,
                description,
                reviewers: reviewers,
                reviewUrl: window.location.origin.concat(`/module-groups/${versionId}/review`),
            })
        }
    }, [
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        description,
        reviewers,
        requester,
        reviewId,
        moduleName,
        versionId,
        moduleReview,
    ])

    return (
        <>
            <InputWrapper
                id='request-review-description'
                labelText='Description'
                error={descriptionError}
                footer={
                    descriptionError
                        ? 'You must provide a description to create a review.'
                        : undefined
                }
                required
            >
                {(inputProps) => (
                    <TextArea
                        dataTestId='request-review-description'
                        value={description}
                        {...inputProps}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            setDescriptionError(false)
                        }}
                    />
                )}
            </InputWrapper>

            <InputWrapper id='request-reviewers-list' labelText='Reviewers'>
                {(inputProps) => (
                    <Select
                        {...inputProps}
                        data-test-id='request-reviewers-select'
                        options={getReviewersList(Authenticator.getDefaultUserName())}
                        defaultValue={[]}
                        isMulti={true}
                        onChange={setReviewers}
                        placeholder='Select reviewers'
                        getTriggerText={(numberOfSelectedItems: number) =>
                            numberOfSelectedItems === 1
                                ? '1 reviewer selected'
                                : `${numberOfSelectedItems} reviewers selected`
                        }
                        listMaxHeight='20vh'
                    />
                )}
            </InputWrapper>
        </>
    )
}
