/* eslint-disable react/display-name, jsx-a11y/media-has-caption */
import React, { useCallback, useRef, useState } from 'react'
import { isUndefined } from 'lodash'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { getMediaType } from 'src/services/media/MediaTypeUtils'

export interface RawMediaViewProps {
    url: string
    mimeType?: string
    altText?: string
    controls?: boolean
    startExpanded?: boolean
    captionsPath?: string
    locale?: string
    preload?: string
}

const style = { maxHeight: '100%', maxWidth: '100%' }

/**
 * Components that only renders a media element (image, video, audio) based on the mime type
 * The component will try to fit the media element on the parent component keeping the aspect ratio
 */

const VTT_REGEX = {
    // Speaker tag: <v.CLASSNAME SPEAKER>TEXT</v>
    speaker: {
        start: /(<v(\.[^>\s.]*)*(\s([^>]*))*>)/g,
        end: /(<\/v>)/g,
    },
}

export const updateClosedCaptionCue = (caption: string): string => {
    // remove the speaker end tag and replace the speaker start tag with [${SPEAKER}]
    const updatedCaption: string = caption
        .replace(VTT_REGEX.speaker.end, '')
        .replace(VTT_REGEX.speaker.start, (match, p1, p2, p3, p4: string) => {
            if (!isUndefined(p4)) {
                const speakerName: string = p4.trim()
                if (speakerName.length > 0) {
                    return `[${speakerName}] `
                }
            }
            return ''
        })
    return updatedCaption
}

export const RawMediaView = ({
    url,
    mimeType,
    altText,
    controls,
    startExpanded,
    captionsPath,
    locale,
    preload,
}: RawMediaViewProps) => {
    const bodyRef = useRef<HTMLVideoElement | HTMLAudioElement | null>(null)
    const [expanded, setExpanded] = useState(startExpanded ?? false)
    const expand = useCallback(() => {
        setExpanded(true)
        requestAnimationFrame(() => {
            bodyRef.current?.focus()
        })
    }, [bodyRef])
    const trackRef = useRef<HTMLTrackElement | null>(null)
    const [caption, setCaption] = useState('')

    const mediaType = mimeType ? getMediaType(mimeType) : null

    if (!url || !url.trim()) {
        return null
    } else if (mediaType === 'image') {
        return <img style={style} src={url} alt={altText ?? ''} />
    }

    let body = () => <></>

    const startCaptions = () => {
        if (trackRef.current) {
            trackRef.current.oncuechange = (e: Event) => {
                if (e.target) {
                    const { track } = e.target as HTMLTrackElement
                    const { activeCues } = track
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: thinks activeCues which type TextTrackCueList is not an array type
                    const text = [...activeCues].map((cue: VTTCue) => {
                        cue.text = updateClosedCaptionCue(cue.text)
                        return cue.getCueAsHTML().textContent
                    })[0]
                    setCaption(text as string)
                }
            }
        }
    }

    if (mediaType === 'video') {
        body = () => (
            <video
                ref={bodyRef as React.MutableRefObject<HTMLVideoElement | null>}
                style={style}
                src={url}
                controls={controls}
                title={altText}
                preload={preload ?? 'auto'}
            >
                {captionsPath && captionsPath.length > 0 && (
                    <track default kind='captions' srcLang={locale} src={captionsPath} />
                )}
            </video>
        )
    } else if (mediaType === 'audio') {
        body = () => (
            <>
                <View minHeight={40}>
                    <Text>{caption}</Text>
                </View>
                <audio
                    ref={bodyRef as React.MutableRefObject<HTMLAudioElement | null>}
                    style={{
                        minWidth: '180px',
                        width: '100%',
                        maxHeight: '100%',
                    }}
                    src={url}
                    controls={controls}
                    title={altText}
                    onPlay={startCaptions}
                    preload={preload ?? 'auto'}
                >
                    {captionsPath && captionsPath.length > 0 && (
                        <track
                            default
                            kind='captions'
                            srcLang={locale}
                            src={captionsPath}
                            ref={trackRef}
                        />
                    )}
                </audio>
            </>
        )
    }

    if (!controls) {
        const label = mediaType || 'media'
        return (
            <div style={{ display: 'inline-block' }} aria-label={`${label}: ${altText ?? ''}`}>
                <Col justifyContent='center' height='100%' aria-hidden>
                    <Text>[{label}]</Text>
                </Col>
            </div>
        )
    }

    return (
        <div style={{ display: 'inline-block' }} aria-expanded={expanded}>
            <Col justifyContent='center' height='100%' alignSelf={'center'}>
                {!expanded && (
                    <Button
                        onClick={expand}
                        dataTestId='show-raw-media-view'
                        size={ButtonSize.Small}
                        variant={ButtonVariant.Tertiary}
                    >
                        Show {mediaType}
                        <ScreenReaderOnly> : {altText ?? ''}</ScreenReaderOnly>
                    </Button>
                )}
                {expanded ? body() : null}
            </Col>
        </div>
    )
}
