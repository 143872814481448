import React, { useEffect, useMemo, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper, Select, SelectLayout } from '@amzn/stencil-react-components/form'
import { IconBin } from '@amzn/stencil-react-components/icons'
import { Row, View } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Text } from '@amzn/stencil-react-components/text'

import { SymbolIcon } from 'src/components/SymbolIcon'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { TableSymbolType } from 'src/models/dto/items/BaseTableDTO'
import { Locale } from 'src/models/dto/Locale'
import { focusOnId } from 'src/pages/module-builder/focus'
import { ItemEditorLabel } from 'src/pages/module-builder/item-editors/ItemEditorInputs'

const renderItemEditorLabel = ({
    htmlFor: id,
    labelId,
    children: labelText,
}: {
    htmlFor: string
    labelId?: string
    color: string
    children: React.ReactNode
}) => <ItemEditorLabel id={id} labelId={labelId} labelText={labelText} />

export const SymbolLegendInput = (props: {
    itemId: string
    currentSymbol?: TableSymbolType
    symbolLegendText?: string
    locale: Locale
    availableSymbols: TableSymbolType[]
    onLegendChange: (value: string) => void
    onSymbolChange: (symbol: TableSymbolType) => void
    index: number
    deleteIsDisabled: boolean
    deleteSymbolLegend: () => void
    disabled?: boolean
}) => {
    const [currSymbol, setCurrSymbol] = useState<TableSymbolType | undefined>(undefined)

    useEffect(() => {
        setCurrSymbol(props.currentSymbol || undefined)
    }, [props.currentSymbol])

    const renderOption = (symbol: TableSymbolType) => <SymbolIcon symbol={symbol} />

    const renderModal = ({ close }: { close: () => void }) => (
        <ModalContent
            dataTestId='delete-symbol-legend-modal'
            titleText={'Delete Symbol legend'}
            buttons={[
                <Button
                    key='delete-symbol-legend'
                    isDestructive
                    dataTestId='yes-delete-symbol-legend-button'
                    onClick={() => {
                        props.deleteSymbolLegend()
                        close()
                        focusOnId(
                            `${props.itemId}-symbol-legend-${props.index - 1}-symbol-toggle-button`
                        )
                    }}
                >
                    Yes, delete legend
                </Button>,
                <Button
                    key='close-legend'
                    onClick={close}
                    variant={ButtonVariant.Primary}
                    dataTestId='keep-legend'
                >
                    No, keep legend
                </Button>,
            ]}
        >
            <Row gridGap='S400'>
                <Text>Are you sure you want to delete {`"${props.symbolLegendText || ''}"`}?</Text>
            </Row>
        </ModalContent>
    )

    const idPrefix = useMemo(
        () => `${props.itemId}-symbol-legend-${props.index}`,
        [props.itemId, props.index]
    )
    const number = props.index + 1
    return (
        <Row columnGap={'S300'} rowGap='5px'>
            {/* 120px so the placeholder "Symbol" isn't cut off */}
            <View flex='0 0 120px'>
                <InputWrapper
                    labelText={`Symbol ${number}`}
                    id={`${idPrefix}-symbol`}
                    renderLabel={renderItemEditorLabel}
                    key={currSymbol}
                >
                    {(inputProps) => (
                        <div style={{ marginTop: '1px' }}>
                            <Select
                                {...inputProps}
                                options={props.availableSymbols}
                                renderOption={renderOption}
                                renderNativeOption={undefined}
                                placeholder={'Symbol'}
                                onChange={(symbol: TableSymbolType) => {
                                    props.onSymbolChange(symbol)
                                    focusOnId(
                                        `${props.itemId}-symbol-legend-${props.index}-symbol-toggle-button`
                                    )
                                }}
                                disabled={!isDefaultLocale(props.locale) || props.disabled}
                                width='100%'
                                value={currSymbol}
                                dataTestId={`select-legend-${props.index}`}
                                layout={SelectLayout.Custom}
                            />
                        </div>
                    )}
                </InputWrapper>
            </View>
            <View flex='1 0 150px' maxWidth='574px'>
                <InputWrapper
                    labelText='Symbol legend'
                    id={`${idPrefix}-symbol-legend-text`}
                    renderLabel={renderItemEditorLabel}
                >
                    {(inputProps) => (
                        <Input
                            style={{ marginTop: '1px' }}
                            {...inputProps}
                            width='100%'
                            value={props.symbolLegendText || ''}
                            placeholder={'Some human readable content'}
                            type='text'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                props.onLegendChange(event?.target?.value ?? '')
                            }}
                            dataTestId={`input-legend-${props.index}`}
                            disabled={props.disabled}
                        />
                    )}
                </InputWrapper>
            </View>
            <Row flex='0 0 52px' alignItems='flex-end'>
                <WithModal renderModal={renderModal}>
                    {({ open }) => (
                        <Button
                            icon={<IconBin title='Delete item' />}
                            isDestructive
                            variant={ButtonVariant.Tertiary}
                            aria-disabled={
                                props.deleteIsDisabled ||
                                !isDefaultLocale(props.locale) ||
                                props.disabled
                            }
                            onClick={open}
                            dataTestId={`delete-legend-${props.index}`}
                            aria-label={`delete symbol legend ${props.index + 1}`}
                        />
                    )}
                </WithModal>
            </Row>
        </Row>
    )
}

SymbolLegendInput.displayName = 'SymbolLegendInput'

export const SymbolSelectInput = <T,>({
    value,
    displayNamesToValues,
    setValue,
    labelId,
    id,
    options,
    dataTestId,
    disabled,
}: {
    value: string | undefined
    displayNamesToValues: Map<string, T>
    setValue: (nextValue: T) => void
    options: string[]
    id: string
    labelId?: string
    dataTestId: string
    disabled?: boolean
}) => {
    const renderOptions = (option: string) => (
        <Row gridGap={'S200'}>
            <div style={{ paddingTop: 4 }} aria-hidden>
                <SymbolIcon
                    symbol={displayNamesToValues.get(option) as unknown as TableSymbolType}
                />
            </div>
            <Text>{option}</Text>
        </Row>
    )

    return (
        <Select
            dataTestId={dataTestId}
            labelId={labelId}
            id={id}
            options={options}
            disabled={disabled}
            renderOption={renderOptions}
            value={value}
            placeholder='Select one'
            onChange={(nextValue: string) => {
                if (nextValue) {
                    const nextConvertedToCorrectType = displayNamesToValues.get(nextValue)
                    if (nextConvertedToCorrectType) {
                        setValue(nextConvertedToCorrectType)
                    }
                }
            }}
        />
    )
}
