import { CognitoAuth, CognitoAuthSession, CognitoAuthUserHandler } from 'amazon-cognito-auth-js'

import { publishUserEventMetrics, UserEventMethodNames } from 'src/metrics'
import { APP_CONFIG } from '../config.app'

const APP_ROOT_URI = '/'

type SuccessCallback = (session?: CognitoAuthSession) => void
type ErrorCallback = (error?: Error) => void

export class Authenticator {
    static redirectUri: string = APP_ROOT_URI
    static auth: CognitoAuth
    // what to do in case that auth is invalid?
    static errorCallback?: ErrorCallback

    // callback used internally for when a new session
    // is successfully established
    static newSessionCallback?: SuccessCallback
    // callback used internally for when a new session
    // is not successfully established
    static invalidSessionCallback?: ErrorCallback
    static retryCountForInvalidGrant = 0
    static readonly MAX_RETRY = 3

    private static authHandler: CognitoAuthUserHandler = {
        onSuccess: (session: CognitoAuthSession) => {
            if (session.isValid()) {
                // only update the browser URL, do not refresh the page
                Authenticator.retryCountForInvalidGrant = 0
                window.history.pushState({}, '', decodeURIComponent(Authenticator.redirectUri))
                Authenticator.newSessionCallback?.(session)
            } else {
                Authenticator.invalidSessionCallback?.(new Error('invalid session'))
            }
        },
        onFailure: (error: string | Error) => {
            if (typeof error === 'string') {
                let authError: { error: string } = { error: '' }
                authError = JSON.parse(error) as unknown as { error: string }

                if (
                    authError.error === 'invalid_grant' &&
                    Authenticator.retryCountForInvalidGrant < Authenticator.MAX_RETRY
                ) {
                    if (Authenticator.retryCountForInvalidGrant > 0) {
                        publishUserEventMetrics(UserEventMethodNames.RefreshingToken, 'error')
                    }

                    // Refresh token is expired, clean up the local session and re-attempt to authenticate
                    Authenticator.retryCountForInvalidGrant++
                    Authenticator.auth.clearCachedTokensScopes()
                    Authenticator.authenticateWithCognito()
                    publishUserEventMetrics(UserEventMethodNames.RefreshingToken, 'count')
                    return
                }
            }

            console.error(`Cognito authentication failed, Error: ${error.toString()}`)
            Authenticator.invalidSessionCallback?.(error as unknown as Error | undefined)
        },
    }

    static initCognitoAuth(errorCallback: ErrorCallback) {
        const authConfig = APP_CONFIG && APP_CONFIG.auth
        if (!authConfig) {
            return
        }
        Authenticator.errorCallback = errorCallback
        // default invalid session callback will delegate
        // to the error callback from the caller
        Authenticator.invalidSessionCallback = (error) => {
            Authenticator.errorCallback?.(error)
        }

        // register callback functions to be executed after authentication attempt
        Authenticator.auth = new CognitoAuth(authConfig)
        Authenticator.auth.userhandler = Authenticator.authHandler
        Authenticator.auth.useCodeGrantFlow()
    }

    static authenticate(successCallback: SuccessCallback) {
        if (!Authenticator.auth) {
            return Authenticator.errorCallback?.()
        }

        const session = Authenticator.auth.getSignInUserSession()

        if (session.isValid()) {
            return successCallback()
        }
        Authenticator.newSessionCallback = successCallback

        // tt may be a redirect back from Cognito after an authentication attempt
        const href = window.location.href
        const accessTokenReceived = href.includes('code=')

        if (accessTokenReceived || href.includes('error=')) {
            // authentication response is in URL hash
            // result of the following call will be passed to authHandler
            Authenticator.auth.parseCognitoWebResponse(href)
            return
        }

        // no valid session - making authentication attempt
        Authenticator.authenticateWithCognito()
    }

    static getValidUserSession(): CognitoAuthSession | null {
        const userSession: CognitoAuthSession = this.auth.getSignInUserSession()
        if (!userSession.isValid()) {
            Authenticator.authenticateWithCognito()
            return null
        }
        return userSession
    }

    private static setRedirectUri(url: string) {
        // save URL path in Cognito auth state so we can redirect user bac
        // to the same page after being authenticated with Cognito
        const encodedURIComponent = encodeURIComponent(url)
        Authenticator.redirectUri = encodedURIComponent
    }

    private static authenticateWithCognito(): void {
        Authenticator.setRedirectUri(window.location.href.replace(window.location.origin, ''))
        // cognito SDK will handle session refresh / authentication
        Authenticator.auth.getSession()
    }

    static getDefaultUser() {
        try {
            return (Authenticator.auth.getUsername() || '').replace(/^FederateOIDC_/, '')
        } catch (e) {
            console.error('getDefaultUser error', e)
            return ''
        }
    }

    static getDefaultUserName() {
        const currentUser = this.getDefaultUser()
        const split = currentUser.split('@')
        if (split.length > 1) {
            return split[0]
        }

        return currentUser
    }
}
