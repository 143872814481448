import _, { cloneDeep, isUndefined } from 'lodash'
import { v4 } from 'uuid'

import { Locale } from 'src/models/dto/Locale'
import {
    EvaluationScoreDTO,
    ModuleEntity,
    ModuleLayout,
    ModuleType,
    ModuleUserType,
} from 'src/models/dto/ModuleDTO'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ActivityEntityService } from 'src/services/EntityServices/ActivityEntityService'
import { Store, STORE_ACTION } from '../Store'

/**
 * ModuleEntityService is an example of a Service that serves a particular Entity
 *
 * There are 3 things we expect in such a service.
 * 1. The selector must be exported, this is so that we can reference the underlying store
 * 2. This service must implement "init", which creates the store
 * 3. This service must provide a create function which creates a valid Entity and stores it
 *
 * Unrequired but good practices
 * 1. The service provides means to evaluate and validate the entities based on the last action
 * 2. The service provides means to assemble a serialized output representing the Entity
 */
export const MODULE_ENTITY_STORE_SELECTOR = 'ModuleEntity'

export class ModuleEntityService {
    static store: Store<ModuleEntity>

    static init() {
        this.store = new Store<ModuleEntity>(MODULE_ENTITY_STORE_SELECTOR)
    }

    static get(entityId: string): ModuleEntity {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static create(name: string): ModuleEntity {
        const entity: ModuleEntity = {
            name: name,
            id: v4(),
            version: v4(),
            availableLocales: [Locale.en_US],
            autoProgressionOn: false,
            status: '',
            workflowIds: [],
            compositeScores: [],
            instructionalContentId: '',
            usingMediaManager: true,
        }

        this.insert(entity)
        return entity
    }

    static createBlank(): ModuleEntity {
        return this.create('')
    }

    static insert(entity: ModuleEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.version,
            payload: entity,
        })
    }

    //  we want to encourage usage of specific update methods
    static update(entity: ModuleEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.version,
            payload: entity,
        })
    }

    static updateName(entityId: string, name: string) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            name,
        }
        this.update(payload)
    }

    static updateMUPPItemBankId(entityId: string, muppItemBankId: string) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            muppItemBankId,
        }
        this.update(payload)
    }

    static updateWorkflow(entityId: string, workflowIds: string[]) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            workflowIds,
        }
        this.update(payload)
    }

    static updateModuleType(entityId: string, moduleType: ModuleType) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            moduleType,
        }
        this.update(payload)
    }

    static updateLayout(entityId: string, layout: ModuleLayout) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            layout,
        }
        this.update(payload)
    }

    static updateUserType(entityId: string, userType: ModuleUserType) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            userType,
        }
        this.update(payload)
    }

    static updateEvaluationScores(entityId: string, evaluationModule: boolean) {
        let entity = this.store.get(entityId)

        if (!evaluationModule) {
            while (entity.compositeScores.length > 0) {
                entity = this.store.get(entityId)
                this.deleteEvaluationScore(entityId, 0)
            }
        } else {
            const newEvaluationScore: EvaluationScoreDTO = {
                compositeScoreLabel: '',
                compositeScoreExpression: '',
            }
            this.addEvaluationScore(entityId, newEvaluationScore)
        }
    }

    static updateAutoProgressionOn(entityId: string, autoProgressionOn: boolean) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            autoProgressionOn,
        }
        this.update(payload)
    }

    static updateMturkPaymentCode(entityId: string, mturkPaymentCodeItemId: string) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            mturkPaymentCodeItemId,
        }
        this.update(payload)
    }

    static updateProgressBar(entityId: string, enableProgressBar: boolean) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            evaluationModule: enableProgressBar,
        }
        this.update(payload)
    }

    static updateInstructionalContent(entityId: string, instructionId: string) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            instructionalContentId: instructionId,
        }
        this.update(payload)
    }

    static addActivity(entityId: string, activityId: string, location?: number) {
        const entity = this.store.get(entityId)
        const activityIds = [...entity.workflowIds]
        if (isUndefined(location)) {
            activityIds.push(activityId)
        } else {
            activityIds.splice(location, 0, activityId)
        }

        this.updateWorkflow(entityId, activityIds)
    }

    static removeActivity(entityId: string, activityId: string) {
        const entity = this.store.get(entityId)

        this.updateWorkflow(
            entityId,
            entity.workflowIds.filter((id) => id !== activityId)
        )
    }

    static moveActivity(entityId: string, activityId: string, toIndex: number) {
        const entity = this.store.get(entityId)

        const temp = entity.workflowIds.filter((id) => id !== activityId)

        const workflow = [...temp.slice(0, toIndex), activityId, ...temp.slice(toIndex)]

        const payload: ModuleEntity = {
            ...entity,
            workflowIds: workflow,
        }

        this.update(payload)
    }

    static addAvailableLocale(entityId: string, locale: Locale) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            availableLocales: [...entity.availableLocales, locale].sort(),
        }
        this.update(payload)
    }

    static setAvailableLocales(entityId: string, locales: Locale[]) {
        const entity = this.store.get(entityId)

        const payload: ModuleEntity = {
            ...entity,
            availableLocales: [Locale.en_US, ...locales].sort(),
        }

        this.update(payload)

        const localeDiff = entity.availableLocales.filter(
            (l) => !locales.includes(l) && l !== Locale.en_US
        )

        if (localeDiff.length === 0) return

        const dto = ModuleService.getFullModuleDTO(ModuleService.serializeModuleDTO(entity.version))

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
        const removeLocation = (obj: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
            return _.transform(obj, (result: any, value: any, key: any) => {
                let newValue = value
                if (_.isString(key) && key.includes('I18N')) {
                    if (_.isArray(newValue)) {
                        for (let i = 0; i < localeDiff.length; i++) {
                            newValue = (newValue as []).map((l) => {
                                return _.omit(l, localeDiff[i])
                            })
                        }
                    } else {
                        for (let i = 0; i < localeDiff.length; i++) {
                            newValue = _.omit(newValue, localeDiff[i])
                        }
                    }
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                result[key] = _.isObject(newValue) ? removeLocation(newValue) : newValue
            })
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
        ModuleService.updateModule(removeLocation(dto as any))
    }

    static deleteAvailableLocale(entityId: string, locale: Locale) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            availableLocales: entity.availableLocales.filter((l) => l != locale).sort(),
        }
        this.update(payload)
    }

    static duplicate(moduleId: string, newName: string | undefined = undefined): ModuleEntity {
        const entity = this.store.get(moduleId)

        const newId = v4()
        const newVersionId = v4()
        const duplicate = {
            ...cloneDeep(entity),
            id: newId,
            version: newVersionId,
            name: newName ? newName : `${entity.name}-${newId}`,
        }

        duplicate.workflowIds = duplicate.workflowIds.map(
            (id) => ActivityEntityService.duplicate(id).id
        )

        this.insert(duplicate)
        return duplicate
    }

    static addEvaluationScore(entityId: string, evaluationScore: EvaluationScoreDTO) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            compositeScores: [
                ...(entity.compositeScores ? entity.compositeScores : []),
                evaluationScore,
            ],
        }
        this.update(payload)
    }

    static deleteEvaluationScore(entityId: string, scoreIndex: number) {
        const entity = this.store.get(entityId)
        const tempScoresMap: EvaluationScoreDTO[] = entity.compositeScores

        if (scoreIndex > -1) tempScoresMap.splice(scoreIndex, 1)

        const payload: ModuleEntity = {
            ...entity,
            compositeScores: tempScoresMap,
        }
        this.update(payload)
    }

    static updateEvaluationScoreLabel(entityId: string, scoreIndex: number, newValue: string) {
        const entity = this.store.get(entityId)
        entity.compositeScores[scoreIndex].compositeScoreLabel = newValue

        const payload: ModuleEntity = {
            ...entity,
            compositeScores: entity.compositeScores,
        }
        this.update(payload)
    }

    static updateEvaluationScoreExpression(entityId: string, scoreIndex: number, newValue: string) {
        const entity = this.store.get(entityId)
        entity.compositeScores[scoreIndex].compositeScoreExpression = newValue

        const payload: ModuleEntity = {
            ...entity,
            compositeScores: entity.compositeScores,
        }
        this.update(payload)
    }

    static moveCompositeScore(entityId: string, fromIndex: number, toIndex: number) {
        const entity = this.store.get(entityId)

        const compositeScore = entity.compositeScores[fromIndex]
        const temp = entity.compositeScores.filter((id, index) => index !== fromIndex)

        const newCompositeScores = [
            ...temp.slice(0, toIndex),
            compositeScore,
            ...temp.slice(toIndex),
        ]

        const payload: ModuleEntity = {
            ...entity,
            compositeScores: newCompositeScores,
        }

        this.update(payload)
    }

    static updateAssessmentMetadataEnabled(entityId: string, enabled: boolean) {
        const entity = this.store.get(entityId)
        const payload: ModuleEntity = {
            ...entity,
            assessmentMetadata: enabled ? entity.assessmentMetadata ?? {} : undefined,
        }
        this.update(payload)
    }

    static updateMinTimeToCompleteInMinutes(
        entityId: string,
        minTimeToCompleteModuleInMinutes?: number
    ) {
        const entity = this.store.get(entityId)
        this.update({
            ...this.store.get(entityId),
            assessmentMetadata: {
                ...entity.assessmentMetadata,
                minTimeToCompleteModuleInMinutes: minTimeToCompleteModuleInMinutes,
            },
        })
    }

    static updateMaxTimeToCompleteInMinutes(
        entityId: string,
        maxTimeToCompleteModuleInMinutes?: number
    ) {
        const entity = this.store.get(entityId)
        this.update({
            ...this.store.get(entityId),
            assessmentMetadata: {
                ...entity.assessmentMetadata,
                maxTimeToCompleteModuleInMinutes: maxTimeToCompleteModuleInMinutes,
            },
        })
    }

    static updateResultValidityInDays(entityId: string, resultValidityInDays?: number) {
        const entity = this.store.get(entityId)
        this.update({
            ...this.store.get(entityId),
            assessmentMetadata: {
                ...entity.assessmentMetadata,
                resultValidityInDays: resultValidityInDays,
            },
        })
    }

    static updateModuleTimerEnabled(entityId: string, enabled: boolean) {
        const entity = this.store.get(entityId)
        this.update({
            ...entity,
            moduleTimerConfig: enabled ? entity.moduleTimerConfig ?? {} : undefined,
        })
    }

    static updateTimeLimit(entityId: string, timeLimit?: number) {
        const entity = this.store.get(entityId)
        this.update({
            ...entity,
            moduleTimerConfig: {
                ...entity.moduleTimerConfig,
                timeLimit: timeLimit,
            },
        })
    }

    static updateWarningTimerEnabled(entityId: string, enabled: boolean) {
        const entity = this.store.get(entityId)

        this.update({
            ...entity,
            moduleTimerConfig: {
                ...entity.moduleTimerConfig,
                warningConfigs: enabled
                    ? entity.moduleTimerConfig?.warningConfigs ?? []
                    : undefined,
            },
        })
    }

    static addWarningTimer(entityId: string, value: number) {
        const entity = this.store.get(entityId)
        const warningConfigs = entity.moduleTimerConfig?.warningConfigs ?? []
        warningConfigs.push({
            timeRemaining: value,
        })
        this.update({
            ...entity,
            moduleTimerConfig: {
                ...entity.moduleTimerConfig,
                warningConfigs: warningConfigs,
            },
        })
    }

    static updateWarningTimer(entityId: string, index: number, nextValue: number) {
        const entity = this.store.get(entityId)
        const warningConfigs = entity.moduleTimerConfig?.warningConfigs ?? []
        warningConfigs[index].timeRemaining = nextValue
        this.update({
            ...entity,
            moduleTimerConfig: {
                ...entity.moduleTimerConfig,
                warningConfigs: warningConfigs,
            },
        })
    }

    static getModuleWarningConfig(entityId: string, index: number) {
        const entity = this.store.get(entityId)
        const warningConfigs = entity.moduleTimerConfig?.warningConfigs
        if (warningConfigs && index >= 0 && warningConfigs.length > index) {
            return warningConfigs[index]
        }
        return { timeRemaining: 0 }
    }

    static updateUsingMediaManager(entityId: string, usingMediaManager: boolean) {
        this.update({
            ...this.store.get(entityId),
            usingMediaManager,
        })
    }
}
