import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Editor } from '@toast-ui/react-editor'
import { debounce } from 'lodash'

import { TextArea } from '@amzn/stencil-react-components/form'
import { View } from '@amzn/stencil-react-components/layout'
import {
    deserializeFromMarkdown,
    RichTextEditor,
    RichTextEditorProps,
    useEditor,
} from '@amzn/stencil-react-components/rte'

import { Locale } from 'src/models/dto/Locale'

import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'

export type ToolbarControls = RichTextEditorProps['controls']

export const SimpleToolbar: ToolbarControls = {
    blockquote: false,
    bulletList: false,
    underline: false,
    link: false,
    heading: false,
    horizontalRule: false,
    keyboardShortcuts: false,
    fullScreen: false,
    clearFormat: false,
    orderedList: false,
    strike: false,
    history: false,
    textAlign: false,
    indent: false,
}

export interface MarkdownEditorProps {
    disabled?: boolean
    title?: string
    value: string
    onChange: React.Dispatch<string>
    id?: string
    dataTestId?: string
    fallbackToTextArea?: boolean
    height?: string | number
    locale?: Locale
    placeholder?: string
    toolbarControls?: ToolbarControls
}

const STYLE = {
    background: 'white',
    border: '2px solid #799294',
}

const WAIT = 200

const defaultHeight = '320px'

function ToastUIMarkdownEditor({
    value: markdownControlled,
    onChange,
    id,
    dataTestId,
    height = defaultHeight,
}: MarkdownEditorProps) {
    const editorRef = useRef<Editor | null>(null)
    const setEditorRef = useCallback((e: Editor) => {
        editorRef.current = e
    }, [])
    const lastMarkdown = useRef(markdownControlled)
    const [updateKey, increment] = useReducer((x: number) => x + 1, 0)
    useEffect(() => {
        if (lastMarkdown.current !== markdownControlled) {
            console.log('markdown changed', { markdownControlled })
            increment()
        }
    }, [markdownControlled, lastMarkdown])
    const debouncedOnChange = useMemo(() => {
        return debounce(() => {
            const md = editorRef.current?.getInstance().getMarkdown() ?? ''
            lastMarkdown.current = md
            onChange(md)
        }, WAIT)
    }, [lastMarkdown, onChange])

    return (
        <View
            style={STYLE}
            data-markdown-editor-id={id}
            dataTestId={dataTestId ?? 'markdown-editor'}
            key={updateKey}
        >
            <Editor
                key={updateKey}
                autofocus={false}
                previewStyle='vertical'
                initialEditType='markdown'
                initialValue={markdownControlled}
                onChange={debouncedOnChange}
                ref={setEditorRef}
                usageStatistics={false}
                height={`${height}`}
            />
        </View>
    )
}

export function StencilMarkdownEditor({
    value: savedValue,
    onChange: onValueChange,
    id,
    dataTestId,
    height = defaultHeight,
    toolbarControls,
    disabled,
}: MarkdownEditorProps) {
    const lastValue = useRef(savedValue)
    // deserialize from markdown will keep backwards functionality for when we have markdown in the cell
    const [content, setContent] = useState(() => deserializeFromMarkdown(savedValue ?? ''))

    const [editorProps, editorInstance] = useEditor({
        content,
        controls: {
            ...toolbarControls,
            fullScreen: false,
        },
    })

    useEffect(() => {
        if (savedValue === lastValue.current) {
            return
        }
        lastValue.current = savedValue
        setContent(savedValue ?? '')
    }, [savedValue])

    useEffect(() => {
        if (!editorInstance) {
            return
        }
        const update = () => {
            const html = editorInstance?.getHTML() ?? ''
            lastValue.current = html
            onValueChange?.(html)
        }
        const debouncedUpdate = debounce(update, WAIT)

        editorInstance.on('blur', debouncedUpdate.flush)
        editorInstance.on('update', debouncedUpdate)
        return () => {
            editorInstance.off('blur', debouncedUpdate.flush)
            editorInstance.off('update', debouncedUpdate)
        }
    }, [onValueChange, lastValue, editorInstance, content])

    return (
        <View id={id} dataTestId={dataTestId} style={{ minHeight: height }}>
            <RichTextEditor {...editorProps} content={content} disabled={disabled} />
        </View>
    )
}

function TextAreaMarkdownEditor({
    value: markdownControlled,
    onChange: onMarkdownChange,
    id,
    dataTestId,
    height = defaultHeight,
    placeholder,
    disabled,
}: MarkdownEditorProps) {
    return (
        <TextArea
            placeholder={placeholder ?? 'Markdown Content'}
            value={markdownControlled}
            onChange={(e) => onMarkdownChange(e.target.value)}
            dataTestId={dataTestId}
            id={id}
            height={height}
            disabled={disabled}
        />
    )
}

const useToastUIMarkdownEditor = false

export function MarkdownEditor(props: MarkdownEditorProps) {
    const { fallbackToTextArea } = props
    const canRenderMarkdownEditor =
        (window as never as Record<string, unknown>).ResizeObserver &&
        (document as never as Record<string, unknown>).elementFromPoint
    if (!canRenderMarkdownEditor || fallbackToTextArea) {
        return <TextAreaMarkdownEditor {...props} />
    }
    return useToastUIMarkdownEditor ? (
        <ToastUIMarkdownEditor {...props} />
    ) : (
        <StencilMarkdownEditor {...props} />
    )
}
