import React, { useCallback } from 'react'

import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, View } from '@amzn/stencil-react-components/layout'

import { ItemPoolModal } from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ItemPoolModal'

const padding = { left: 'S300', top: 'S400' }

export function ItemPoolVersionIdField(props: {
    footer?: string
    editDisabled?: boolean
    itemPoolVersionId: string
    onItemPoolVersionIdChange: (versionId: string) => void
}) {
    const { itemPoolVersionId, editDisabled, onItemPoolVersionIdChange } = props
    return (
        <>
            <View flex={'1 0'}>
                <InputWrapper
                    id={'item-pool-version-id'}
                    labelText={'Item Pool Version ID'}
                    footer={props.footer}
                    error={!!props.footer}
                >
                    {useCallback(
                        (inputProps) => (
                            <Input
                                {...inputProps}
                                placeholder={'Enter Version ID of Generated Item Pool'}
                                value={itemPoolVersionId}
                                disabled={editDisabled}
                                dataTestId={'item-pool-version-id'}
                                onChange={(e) => {
                                    onItemPoolVersionIdChange(e?.target?.value ?? '')
                                }}
                            />
                        ),
                        [editDisabled, itemPoolVersionId, onItemPoolVersionIdChange]
                    )}
                </InputWrapper>
            </View>
            <Col maxWidth='200px' justifyContent={'center'} gridGap={'S200'} padding={padding}>
                <ItemPoolModal
                    existingPool={itemPoolVersionId !== ''}
                    itemPoolVersionId={itemPoolVersionId}
                    setSelectItemPoolVersionId={onItemPoolVersionIdChange}
                />
            </Col>
        </>
    )
}
