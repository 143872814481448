import React from 'react'

import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { H4 } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { WritingExerciseItemDTO } from 'src/models/dto/items/WritingExerciseItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
    LabelSize,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { WritingExerciseHandler } from 'src/services/EntityServices/ItemUpdateHandlers/WritingExerciseHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const WritingExerciseItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<WritingExerciseItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const flexItemWidth = matches.s ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '450px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`0 1 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            WritingExerciseHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId={'optional-checkbox'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            WritingExerciseHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId='preserve-order-checkbox'
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                WritingExerciseHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextInput
                        dataTestId={'title'}
                        inputId={'title'}
                        disabled={editDisabled}
                        value={itemDTO.titleI18N[locale] ?? ''}
                        placeholder={'Title of Instructions'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            WritingExerciseHandler.updateTitle(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.titleI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Writing exercise instruction title (optional)'}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        locale={locale}
                        dataTestId={'instructions'}
                        inputId={'instructions'}
                        disabled={editDisabled}
                        value={itemDTO.instructionI18N[locale] ?? ''}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            WritingExerciseHandler.updateInstruction(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.instructionI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Writing exercise instructions'}
                        labelSize={LabelSize.Small}
                        placeholder={'Instructions for writing exercise'}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <Hr />
            <Spacer height='S200' />
            <Row alignItems={'center'}>
                <H4>WYSIWIG settings</H4>
            </Row>
            <ResponsiveRow alignItems={'center'} gridGap={'S400'}>
                <Col width={matches.s ? '100%' : 'default'}>
                    <ItemEditorNumberInput
                        dataTestId={'max-character-limit'}
                        inputId={'max-character-limit'}
                        itemId={itemDTO.id}
                        labelText='Maximum Character Limit'
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.wordLimit === 0 ? null : itemDTO.wordLimit}
                        placeholder='8000'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.wordLimit ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        setValue={(nextValue: number | null) => {
                            WritingExerciseHandler.updateWordLimit(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col width={matches.s ? '100%' : 'default'}>
                    <ItemEditorNumberInput
                        dataTestId={'min-characters-allowed'}
                        inputId={'min-characters-allowed'}
                        itemId={itemDTO.id}
                        labelText='Minimum characters allowed (optional)'
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.minWordLimit}
                        placeholder='200'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.minWordLimit ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        setValue={(nextValue: number | null) => {
                            WritingExerciseHandler.updateMinWordsAllowed(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
            </ResponsiveRow>
        </>
    )
}
