import React, { useState } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col } from '@amzn/stencil-react-components/layout'
import { ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { RenderMediaOptionsModalProps } from 'src/pages/asset-library/AssetUploadedPage'

export function DuplicateMediaModal({ close, duplicateMedia }: RenderMediaOptionsModalProps) {
    const [newTitle, setNewTitle] = useState('')
    const [loading, setLoading] = useState(false)

    const validationError = newTitle.length < 1

    const submit = () => {
        setLoading(true)
        duplicateMedia(newTitle)
        setLoading(false)
        close()
    }

    return (
        <ModalContent
            titleText='Duplicate module'
            buttons={[
                <Button key='close-modal' dataTestId='close-modal' onClick={close}>
                    Cancel
                </Button>,
                loading ? (
                    <Spinner key='spinner' />
                ) : (
                    <Button
                        key='submit'
                        dataTestId='submit-duplicate-media'
                        variant={ButtonVariant.Primary}
                        onClick={submit}
                        disabled={validationError}
                    >
                        Yes, duplicate
                    </Button>
                ),
            ]}
        >
            <Col gridGap='S300'>
                <Text>
                    Do you want to create a copy of this media asset? You must provide a new unique
                    title.
                    <br />
                </Text>
                <InputWrapper
                    id='duplicate-media-new-media-name'
                    labelText='Unique media title'
                    tooltipText='The title can be changed until the asset is published.'
                    required={true}
                    error={validationError}
                >
                    {(props) => (
                        <Input
                            dataTestId='duplicate-media-new-media-name'
                            type='text'
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            width='100%'
                            placeholder='Enter a media asset title'
                            {...props}
                        />
                    )}
                </InputWrapper>
            </Col>
        </ModalContent>
    )
}
