import { SubstituteModuleScore } from 'src/models/dto/module-groups/MESGroupDTO'
import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import {
    FunctionalScoreValidationDTO,
    MESCreateEditValidationDTO,
    PECCreateEditValidationDTO,
} from 'src/models/dto/module-groups/ModuleGroupValidations'
import { FunctionalScoreDTO } from 'src/models/dto/module-groups/PECGroupDTO'
import { ALPHA_NUMERIC_UNDERSCORE } from 'src/pages/module-groups/ToggleFunctionalScore'

export interface ModuleGroupInformationChangeSummary {
    replacedScores: Map<string, string>
    addedPrimaryScores: string[]
    removedScores: string[]
    defaultOrPrimaryChange: { old: string; new?: string }
    addedFunctionalScores: FunctionalScoreDTO[]
}
export class ModuleGroupEditUtils {
    public static createEmptySummary(moduleVersionId: string) {
        return {
            replacedScores: new Map<string, string>(),
            addedPrimaryScores: [],
            removedScores: [],
            defaultOrPrimaryChange: {
                old: moduleVersionId,
                new: undefined,
            },
            addedFunctionalScores: [],
        } as ModuleGroupInformationChangeSummary
    }

    public static getUpdatedSelectedPrimaryScoreLabels(
        addedScores: string[],
        replacedScores: Map<string, string>,
        removedScores: string[],
        currentScoreLabels: string[]
    ): string[] {
        const remainingOldScoreLabels = currentScoreLabels.filter((label) => {
            return !replacedScores.has(label) && !removedScores.includes(label)
        })
        return [...remainingOldScoreLabels, ...addedScores, ...Array.from(replacedScores.values())]
    }

    public static applyModuleSubScoreEdits(
        moduleSubScores: SubstituteModuleScore[],
        removedScores: string[],
        replacedScores: Map<string, string>
    ): SubstituteModuleScore[] {
        const updatedModuleSubScores = moduleSubScores.map((moduleSubScore) => {
            let updatedScores = this.removeSubstituteScores(moduleSubScore, removedScores)
            updatedScores = this.replaceSubstituteScores(
                { ...moduleSubScore, substituteScores: updatedScores },
                replacedScores
            )

            return { ...moduleSubScore, substituteScores: updatedScores }
        })

        return updatedModuleSubScores
    }

    public static applyFunctionalScoreEdits(
        originalFuncScores: FunctionalScoreDTO[],
        removedScores: string[],
        replacedScores: Map<string, string>,
        addedScores: FunctionalScoreDTO[],
        oldDefaultModuleVersionId: string,
        newDefaultModuleVersionId: string
    ): FunctionalScoreDTO[] {
        let updatedFunctionalScores = this.removeFunctionalScores(originalFuncScores, removedScores)
        updatedFunctionalScores = this.replaceDefaultScores(
            updatedFunctionalScores,
            replacedScores,
            oldDefaultModuleVersionId,
            newDefaultModuleVersionId
        )
        updatedFunctionalScores = [...updatedFunctionalScores, ...addedScores]
        return updatedFunctionalScores
    }

    public static hasAddedOrReplacedScoresPEC = (
        editInformationChangeSummary: ModuleGroupInformationChangeSummary
    ) => {
        const addedScores = editInformationChangeSummary.addedFunctionalScores
        const replacedScoresValues = Array.from(
            editInformationChangeSummary.replacedScores.values()
        )

        return addedScores.length > 0 || replacedScoresValues.length > 0
    }

    public static hasAtLeastOneChangePEC = (
        hasAddedOrReplacedScores: boolean,
        editInformationChangeSummary: ModuleGroupInformationChangeSummary
    ) => {
        return hasAddedOrReplacedScores || editInformationChangeSummary.removedScores.length > 0
    }

    public static hasRemainingScoresPEC = (
        editInformationChangeSummary: ModuleGroupInformationChangeSummary,
        functionalScores?: FunctionalScoreDTO[]
    ) => {
        return (
            editInformationChangeSummary.addedFunctionalScores.length > 0 ||
            (functionalScores?.length ?? 0) > editInformationChangeSummary.removedScores.length
        )
    }

    public static validateNewFunctionalScores = (
        functionalScore: FunctionalScoreDTO,
        functionalScoresSet: Set<string>,
        defaultOrPrimaryModule?: ModuleGroupModuleMetadata
    ): [string, string] => {
        let scoreLabelError = ''
        let moduleScoreError = ''

        if (functionalScore.functionalScoreLabel.length <= 0) {
            scoreLabelError = 'There must be a functional score label.'
        } else if (!ALPHA_NUMERIC_UNDERSCORE.test(functionalScore.functionalScoreLabel)) {
            scoreLabelError =
                'Functional score label cannot contain any non-alphanumeric characters.'
        } else {
            if (functionalScoresSet.has(functionalScore.functionalScoreLabel)) {
                scoreLabelError = 'Functional score label must be unique.'
            }
            functionalScoresSet.add(functionalScore.functionalScoreLabel)
        }

        if (
            !functionalScore.moduleVersionScoreLabelMap.get(
                defaultOrPrimaryModule?.versionId as string
            )
        ) {
            moduleScoreError = 'Module score must be selected.'
        }

        return [scoreLabelError, moduleScoreError]
    }

    public static validatePECFields(
        setPECValidation: (PECValidation: PECCreateEditValidationDTO) => void,
        editInformationChangeSummary: ModuleGroupInformationChangeSummary,
        functionalScores: FunctionalScoreDTO[],
        functionalScoreEnabled: boolean,
        setValidationMessage: (validationMessage: string) => void,
        defaultOrPrimaryModule?: ModuleGroupModuleMetadata
    ): [boolean, boolean] {
        let validationError = false
        let noChangeError = false

        const funcEquivalencyError = new Map<number, string>()
        const newFuncEquivalencyError = new Array<FunctionalScoreValidationDTO>()
        const functionalScoreLabels = functionalScores.map((func) => func.functionalScoreLabel)
        const removedScoreList = editInformationChangeSummary.removedScores
        const scoreLabelSet = new Set<string>(
            functionalScoreLabels.filter((scoreLabel) => !removedScoreList.includes(scoreLabel))
        )

        if (!editInformationChangeSummary.defaultOrPrimaryChange.new) {
            const hasAddedOrReplacedScores = this.hasAddedOrReplacedScoresPEC(
                editInformationChangeSummary
            )

            const hasRemainingScores = this.hasRemainingScoresPEC(
                editInformationChangeSummary,
                functionalScores
            )

            const hasAtLeastOneChange = this.hasAtLeastOneChangePEC(
                hasAddedOrReplacedScores,
                editInformationChangeSummary
            )

            if (!hasRemainingScores && functionalScoreEnabled) {
                setValidationMessage(
                    'Functional score is enabled, but there is no functional score.'
                )
                noChangeError = true
                return [validationError, noChangeError]
            }

            if (!hasAtLeastOneChange) {
                setValidationMessage('There must be at least one change to Save Module Group.')
                noChangeError = true
                return [validationError, noChangeError]
            }
        }

        let currentFunctionalScoreList: (string | undefined)[] = []

        if (editInformationChangeSummary.defaultOrPrimaryChange.new) {
            currentFunctionalScoreList = functionalScores.map((value) =>
                value.moduleVersionScoreLabelMap.get(
                    editInformationChangeSummary.defaultOrPrimaryChange.old
                )
            )
        } else {
            currentFunctionalScoreList = functionalScores.map((value) =>
                value.moduleVersionScoreLabelMap.get(defaultOrPrimaryModule?.versionId as string)
            )
        }

        editInformationChangeSummary.replacedScores.forEach((value, key) => {
            if (!value) {
                const keyIndex = currentFunctionalScoreList.indexOf(key)
                if (keyIndex !== undefined) {
                    funcEquivalencyError.set(keyIndex, 'New Module Score must be selected.')
                    validationError = true
                }
            }
        })

        editInformationChangeSummary.addedFunctionalScores.forEach((score, index) => {
            const [scoreLabelError, moduleScoreError] = this.validateNewFunctionalScores(
                score,
                scoreLabelSet,
                defaultOrPrimaryModule
            )

            if (scoreLabelError || moduleScoreError) {
                validationError = true
            }

            newFuncEquivalencyError[index] = {
                scoreLabel: scoreLabelError,
                moduleScore: moduleScoreError,
            }
        })

        setPECValidation({
            functionalEquivalency: funcEquivalencyError,
            newFunctionalEquivalency:
                newFuncEquivalencyError.length > 0 ? newFuncEquivalencyError : undefined,
        })
        return [validationError, noChangeError]
    }

    public static hasAddedOrReplacedScoresMES(
        editInformationChangeSummary: ModuleGroupInformationChangeSummary
    ): boolean {
        const addedScores = editInformationChangeSummary.addedPrimaryScores
        const replacedScoresValues = Array.from(
            editInformationChangeSummary.replacedScores.values()
        )
        return addedScores.length > 0 || replacedScoresValues.length > 0
    }

    public static hasAtLeastOneChangeMES(
        editInformationChangeSummary: ModuleGroupInformationChangeSummary
    ): boolean {
        const hasAddedOrReplacedScores = this.hasAddedOrReplacedScoresMES(
            editInformationChangeSummary
        )
        return hasAddedOrReplacedScores || editInformationChangeSummary.removedScores.length > 0
    }

    public static hasRemainingScoresMES(
        editInformationChangeSummary: ModuleGroupInformationChangeSummary,
        substituteScores?: string[]
    ): boolean {
        return (
            editInformationChangeSummary.addedPrimaryScores.length > 0 ||
            (substituteScores?.length ?? 0) > editInformationChangeSummary.removedScores.length
        )
    }

    public static validateMESFields(
        setMESValidation: (MESValidation: MESCreateEditValidationDTO) => void,
        editInformationChangeSummary: ModuleGroupInformationChangeSummary,
        setValidationMessage: (validationMessage: string) => void,
        substituteScores: string[]
    ): [boolean, boolean] {
        const subScoresError = new Map<number, string>()
        const arraySubScoresError = new Array<string>()
        const newSubScoresError = new Map<number, string>()
        let validationError = false
        let noChangeError = false

        if (!editInformationChangeSummary.defaultOrPrimaryChange.new) {
            const hasAtLeastOneChange = this.hasAtLeastOneChangeMES(editInformationChangeSummary)
            const hasRemainingScores = this.hasRemainingScoresMES(
                editInformationChangeSummary,
                substituteScores
            )

            if (!hasAtLeastOneChange) {
                setValidationMessage('There must be at least one change to save the module group.')
                noChangeError = true
                return [validationError, noChangeError]
            }

            if (!hasRemainingScores) {
                setValidationMessage('There must be at least one substitute score.')
                noChangeError = true
                return [validationError, noChangeError]
            }
        }

        editInformationChangeSummary.replacedScores.forEach((val, key) => {
            if (!val) {
                const keyIndex = substituteScores.indexOf(key)
                if (keyIndex !== undefined) {
                    subScoresError.set(keyIndex, `New Score ${keyIndex + 1} must be selected.`)
                    validationError = true
                }
            }
        })

        editInformationChangeSummary.addedPrimaryScores.forEach((score, index) => {
            if (!score) {
                newSubScoresError.set(index, `Added Score ${index + 1} must be selected.`)
                arraySubScoresError[index] = 'A substitute score must be selected.'
                validationError = true
            }
        })

        setMESValidation({
            substituteScores: subScoresError,
            newSubstituteScores: arraySubScoresError.length > 0 ? arraySubScoresError : undefined,
        })

        return [validationError, noChangeError]
    }

    private static removeSubstituteScores(
        moduleSubScore: SubstituteModuleScore,
        scoreLabelsToRemove: string[]
    ) {
        return moduleSubScore.substituteScores.filter(
            (subScore) => !scoreLabelsToRemove.includes(subScore.primaryScoreLabel)
        )
    }

    private static replaceSubstituteScores(
        moduleSubScore: SubstituteModuleScore,
        scoreReplacements: Map<string, string>
    ) {
        return moduleSubScore.substituteScores.map((subScore) => {
            const replacement = scoreReplacements.get(subScore.primaryScoreLabel)
            if (replacement) {
                return {
                    ...subScore,
                    primaryScoreLabel: replacement,
                }
            }
            return subScore
        })
    }

    private static removeFunctionalScores(
        functionalScores: FunctionalScoreDTO[],
        removedScores: string[]
    ) {
        return functionalScores.filter(
            (funcScore) => !removedScores.includes(funcScore.functionalScoreLabel)
        )
    }

    private static replaceDefaultScores(
        functionalScores: FunctionalScoreDTO[],
        replacedScores: Map<string, string>,
        oldDefaultModuleVersionId: string,
        newModuleVersionId: string
    ) {
        return functionalScores.map((funcScore) => {
            const updatedMap = new Map<string, string>(funcScore.moduleVersionScoreLabelMap)
            funcScore.moduleVersionScoreLabelMap.forEach((score, moduleVersionId) => {
                if (replacedScores.has(score) && moduleVersionId === oldDefaultModuleVersionId) {
                    updatedMap.delete(moduleVersionId)
                    updatedMap.set(newModuleVersionId, replacedScores.get(score) as string)
                }
            })
            return {
                ...funcScore,
                moduleVersionScoreLabelMap: updatedMap,
            }
        })
    }
}
