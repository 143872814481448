import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'

import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ItemBankMetadata } from 'src/models/dto/mupp/MUPPItemBankMetadataDTO'
import { MUPPItemBankTable } from 'src/pages/module-builder/mupp-exam-editor/MUPPItemBankTable'
import { MUPPItemBankService } from 'src/services/mupp/MUPPItemBankService'

export interface MUPPItemBankManagerProps {
    setItemBank?: (version: string, id: string, locales: string[]) => void
}

export const MUPPItemBankManager = (props: MUPPItemBankManagerProps) => {
    const [itemBanks, setItemBanks] = useState<ItemBankMetadata[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [loadingItemBankError, setLoadingItemBankError] = useState<string>('')

    useEffect(() => {
        let componentUnmounted = false
        const source = axios.CancelToken.source()

        const fetchData = async () => {
            const res = await MUPPItemBankService.getItemBankList(source.token)
            if (componentUnmounted) {
                return
            }
            setItemBanks(res)
            setIsLoading(false)
            setLoadingItemBankError('')
        }

        fetchData().catch((err: ErrorResponseThrowable) => {
            if (componentUnmounted) {
                return
            }
            setIsLoading(false)
            setLoadingItemBankError(err.message)
        })

        return () => {
            componentUnmounted = true
            source.cancel()
        }
    }, [])

    return (
        <View margin={40}>
            {isLoading ? (
                <Spinner loadingText={'Loading'} dataTestId={'item-bank-manager-page-spinner'} />
            ) : (
                <>
                    {itemBanks.length === 0 && (
                        <MessageBanner
                            type={MessageBannerType.Error}
                            dataTestId={'item-banks-error-message'}
                        >
                            Error loading item banks: {loadingItemBankError}
                        </MessageBanner>
                    )}
                    <h1>MUPP Item Banks: {itemBanks.length} </h1>
                    <MUPPItemBankTable itemBanks={itemBanks} setItemBank={props.setItemBank} />
                </>
            )}
        </View>
    )
}
