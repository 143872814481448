import { v4 } from 'uuid'

import { CATElementType, MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'
import { STORE_ACTION } from 'src/services/Store'

const activityType = ActivityType.LaunchCAT

export class MUPPExamHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(activityType, () => MUPPExamHandler.create())
    }

    static get(entityId: string): MUPPExamEntity {
        return ActivityEntityService.get(entityId) as MUPPExamEntity
    }

    /**
     *
     * @returns an MUPPExamEntity but does not add it to the Store
     */
    static create(): MUPPExamEntity {
        const newActivity = activity()

        return {
            ...newActivity,
            name: newActivity.id,
            ppt: ActivityType.LaunchCAT,
            type: ActivityType.LaunchCAT,
            selection: {
                itemBankId: '',
                pairingTableDTO: {
                    id: v4(),
                    type: CATElementType.MUPP_PAIRING_TABLE,
                    dimensionPairs: [],
                    locale: Locale.en_US,
                    name: 'pt1',
                },
                pctMax: null,
                groupSize: null,
                constraints: null,
            },
            locale: Locale.en_US,
        }
    }

    static update(payload: MUPPExamEntity) {
        ActivityEntityService.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: payload.id,
            payload: payload,
        })
    }
}
