import React, { useState } from 'react'

import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useSetLocaleWiseMediaOpt } from 'src/hooks/useSetLocaleWiseMediaOpt'
import { MediaStimulusItemDTO } from 'src/models/dto/items/MediaStimulusItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { MediaStimulusHandler } from 'src/services/EntityServices/ItemUpdateHandlers/MediaStimulusHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const MediaStimulusItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<MediaStimulusItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const [playbackCount, setPlaybackCount] = useState('')

    const configurePlaybackCount = (limitPlayback: boolean) => {
        if (limitPlayback) {
            setPlaybackCount('1')
            MediaStimulusHandler.updateMaxPlaybackCount(itemDTO.id, 1)
        } else {
            setPlaybackCount('')
            MediaStimulusHandler.updateMaxPlaybackCount(itemDTO.id, undefined)
        }
    }

    const setLocaleWiseMediaOpt = useSetLocaleWiseMediaOpt(itemDTO)

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col width={matches.s ? '100%' : '400px'}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            MediaStimulusHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            MediaStimulusHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                MediaStimulusHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col
                    width={matches.s ? '100%' : 'default'}
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                >
                    <ItemEditorCheckboxInput
                        inputId={'limit-playback'}
                        dataTestId={'limit-playback'}
                        itemId={itemDTO.id}
                        labelText={'Limit Playback'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.limitPlayback}
                        setValue={(nextValue: boolean) => {
                            configurePlaybackCount(nextValue)
                            MediaStimulusHandler.updateLimitPlayback(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                {itemDTO.limitPlayback && (
                    <Col width={matches.s ? '100%' : 'default'}>
                        <ItemEditorTextInput
                            inputId={'playback-quantity'}
                            dataTestId={'playback-quantity'}
                            itemId={itemDTO.id}
                            placeholder={'Number of Playbacks'}
                            labelText={'Playback Quantity'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            type={'number'}
                            value={itemDTO.maxPlaybackCount?.toString() || playbackCount}
                            setValue={(nextValue: string) => {
                                MediaStimulusHandler.updateMaxPlaybackCount(
                                    itemDTO.id,
                                    parseInt(nextValue)
                                )
                            }}
                            validationErrorMessage={(
                                validationErrorEntity.validationErrors.maxPlaybackCount ?? []
                            ).join(', ')}
                            showError={showValidationError && itemDTO.limitPlayback}
                        />
                    </Col>
                )}
            </ResponsiveRow>
            <Spacer height={'S200'} />
            <ResponsiveRow>
                <Col width={'100%'}>
                    <ItemEditorTextInput
                        inputId={'title'}
                        dataTestId={'media-title'}
                        value={itemDTO.titleI18N[locale] ?? ''}
                        placeholder={'Title to describe media stimulus'}
                        itemId={itemDTO.id}
                        disabled={editDisabled}
                        setValue={(nextValue: string) => {
                            MediaStimulusHandler.updateTitle(itemDTO.id, locale, nextValue)
                        }}
                        labelText={'Title'}
                    />
                </Col>
            </ResponsiveRow>
            <MediaManager
                itemId={itemDTO.id}
                locale={locale}
                localeWiseMediaOpt={itemDTO.localeWiseMedia}
                maxNumberOfMedia={1}
                setLocaleWiseMediaOpt={setLocaleWiseMediaOpt}
                validationErrorMessage={(
                    validationErrorEntity.validationErrors.localeWiseMedia ?? []
                ).join(', ')}
                showError={showValidationError}
                disabled={editDisabled}
            />
        </>
    )
}
