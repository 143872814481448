import React, { useCallback } from 'react'

import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { Expander, ExpanderTitleRendererParams } from '@amzn/stencil-react-components/expander'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { H5, Text } from '@amzn/stencil-react-components/text'

import { PairingTableDTO } from 'src/models/dto/activities/MUPPExamDTO'
import { Dimensions, TableDimension } from 'src/models/dto/mupp/PairingTableDTO'

export function PairingTableCellVisual({ data }: { data: PairingTableDTO }) {
    return (
        <Row gridGap={10} maxWidth={1200} dataTestId={'pairing-table-visual'} flexWrap={'wrap'}>
            {data.dimensionPairs.map((dp, i) => {
                const firstDimension: TableDimension | undefined = Dimensions.find(
                    (d) => d.systemName === dp.first
                )

                const secondDimension: TableDimension | undefined = Dimensions.find(
                    (d) => d.systemName === dp.second
                )

                if (!firstDimension || !secondDimension) return null

                return (
                    <Col gridGap={1} key={`${i + 1} ${dp.first},${dp.second}`}>
                        <Text textAlign='center' fontSize={'T200'}>
                            {/* eslint-disable-next-line no-magic-numbers,@typescript-eslint/restrict-plus-operands */}
                            {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                        </Text>

                        <svg key={i + 1} width={'46px'} height={'79px'}>
                            <title>
                                {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands,no-magic-numbers */}
                                {`${i + 1 < 10 ? '0' + (i + 1) : i + 1}: ${
                                    firstDimension.friendlyName
                                }, ${secondDimension.friendlyName}`}
                            </title>

                            <rect
                                x={'5px'}
                                y={0}
                                width={'36px'}
                                height={'36px'}
                                fill={firstDimension.hexColour}
                            />

                            <text
                                x={'50%'}
                                y={'31.18%'}
                                fontSize={'18px'}
                                textAnchor={'middle'}
                                fontWeight={'600'}
                            >
                                {firstDimension.shortForm}
                            </text>

                            <rect
                                x={'5px'}
                                y={'43px'}
                                width={'36px'}
                                height={'36px'}
                                fill={secondDimension.hexColour}
                            />

                            <text
                                x={'50%'}
                                y={'85.6%'}
                                fontSize={'18px'}
                                textAnchor={'middle'}
                                fontWeight={'600'}
                            >
                                {secondDimension.shortForm}
                            </text>
                        </svg>
                    </Col>
                )
            })}
        </Row>
    )
}

const PairingTableSummary = ({ pairingTable }: { pairingTable: PairingTableDTO }) => {
    return (
        <Row dataTestId={'written-pairings-row'} maxWidth={1200} flexWrap={'wrap'}>
            {pairingTable.dimensionPairs.map((dp, i) => {
                const firstDimension: string =
                    Dimensions.find((d) => d.systemName === dp.first)?.friendlyName ?? dp.second

                const secondDimension: string =
                    Dimensions.find((d) => d.systemName === dp.second)?.friendlyName ?? dp.second
                return (
                    <Col
                        gridGap={10}
                        flexWrap='wrap'
                        width={300}
                        key={`${i + 1} ${firstDimension}, ${secondDimension}-col`}
                    >
                        <Text
                            key={`${i + 1} ${firstDimension}, ${secondDimension}-text`}
                            fontSize={'T200'}
                            color={'neutral100'}
                        >
                            {`${i + 1} ${firstDimension}, ${secondDimension}`}
                        </Text>
                    </Col>
                )
            })}
        </Row>
    )
}

const ClickableH5 = styledWithTheme(H5)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.selectors.color('primary70'),
    ':hover': {
        color: theme.selectors.color('primary80'),
    },
}))

export const PairingTableCellSummary = ({ data }: { data: PairingTableDTO }) => {
    const renderTitle = useCallback(({ titleText, toggle }: ExpanderTitleRendererParams) => {
        return <ClickableH5 onClick={toggle}>{titleText}</ClickableH5>
    }, [])

    return (
        <Col flex={1} gridGap={10}>
            <PairingTableCellVisual data={data} />
            <Expander
                titleText={'Written pairings'}
                dataTestId={'written-pairings-expander'}
                renderTitle={renderTitle}
            >
                <PairingTableSummary pairingTable={data} />
            </Expander>
        </Col>
    )
}
