import React, { useCallback, useEffect, useState } from 'react'

import { Input, InputWrapper } from '@amzn/stencil-react-components/form'

import { ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { ModuleGroupUtility } from 'src/pages/module-groups/ModuleGroupUtility'
import { ModuleService } from 'src/services/backend/ModuleService'

export interface ModuleVersionIdFindProps {
    warning?: string
    processModule: (moduleVersion: ModuleVersionDTO | undefined) => void
    placeholder: string
    moduleVersionId: string
    setModuleVersionId: (versionId: string) => void
    moduleValidation?: string
    setModuleValidation?: (moduleValidation: string) => void
}

export const ModuleVersionIdFind = (props: ModuleVersionIdFindProps) => {
    const [loadModuleError, setLoadModuleError] = useState<boolean>(false)
    const [incorrectVersionIdFormat, setIncorrectVersionIdFormat] = useState<boolean>(false)
    const [currentModuleVersionId, setCurrentModuleVersionId] = useState<string>('')

    const validateVersionId = useCallback(
        async (versionId: string) => {
            const v4UuidRegex =
                /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
            if (v4UuidRegex.test(versionId)) {
                setLoadModuleError(false)
                try {
                    setIncorrectVersionIdFormat(false)
                    const moduleDTO: ModuleVersionDTO = await ModuleService.loadModuleVersionDTO(
                        versionId
                    )
                    props.processModule(moduleDTO)
                } catch (e) {
                    console.error(e)
                    setLoadModuleError(true)
                }
            } else if (versionId.length !== 0) {
                setIncorrectVersionIdFormat(true)
                props.processModule(undefined)
            } else {
                setIncorrectVersionIdFormat(false)
                props.processModule(undefined)
            }
        },
        [props]
    )

    useEffect(() => {
        if (currentModuleVersionId !== props.moduleVersionId) {
            if (!ModuleGroupUtility.isSkippedModule(props.moduleVersionId)) {
                void validateVersionId(props.moduleVersionId)
            }
            setCurrentModuleVersionId(props.moduleVersionId)
        }
    }, [currentModuleVersionId, props.moduleVersionId, validateVersionId])

    const footerLabel = (): string | undefined => {
        if (props.moduleValidation) {
            return props.moduleValidation
        } else if (loadModuleError) {
            return 'We could not find that module version id, please try again with a different version id'
        } else if (incorrectVersionIdFormat) {
            return 'The format of the version id is incorrect'
        } else if (props.warning) {
            return props.warning
        }
        return undefined
    }

    return (
        <>
            <InputWrapper
                id='module-group-name-input-wrapper'
                labelText='Version ID'
                required={true}
                warning={incorrectVersionIdFormat || !!props.warning}
                error={loadModuleError || !!props.moduleValidation}
                footer={footerLabel() ? footerLabel() : undefined}
            >
                {(inputProps) => (
                    <Input
                        dataTestId='module-version-id-input'
                        {...inputProps}
                        value={props.moduleVersionId}
                        onChange={(e) => {
                            if (props.setModuleValidation) {
                                props.setModuleValidation('')
                            }
                            props.setModuleVersionId(e.target.value)
                        }}
                        placeholder={props.placeholder}
                    />
                )}
            </InputWrapper>
        </>
    )
}
