import { useCallback } from 'react'

import { Change } from 'src/hooks/DTOEditor'
import { ItemDTO } from 'src/models/dto/items/ItemDTO'
import { MediaElement, Medias } from 'src/models/dto/items/MediaDTO'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'

export function useSetLocaleWiseMediaOpt<T extends ItemDTO & MediaElement>({ id }: T) {
    return useCallback(
        (change: Change<Medias | undefined>) => {
            ItemEntityService.updateLocaleWiseMediaOpt(id, change)
        },
        [id]
    )
}
