import { PreviewTTL, usePreview, UsePreviewResults } from 'src/hooks/usePreview'
import { CONTEXT_BOX_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ContextBoxEntityService'

export function useContextBoxPreview({
    contextBoxId,
    onPreviewUnexpectedError,
}: {
    contextBoxId: string
    onPreviewUnexpectedError?: () => void
}) {
    const usePreviewResults: UsePreviewResults = usePreview({
        entityId: contextBoxId,
        saved: false,
        entityStoreSelector: CONTEXT_BOX_ENTITY_STORE_SELECTOR,
        onPreviewUnexpectedError: onPreviewUnexpectedError,
    })
    return {
        ...usePreviewResults,
        executePreview: () => usePreviewResults.executePreview(PreviewTTL.THIRTY_MINUTES),
    }
}
