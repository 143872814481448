import { SetStateAction, useMemo } from 'react'

import { applyChange, SetStateCallback, StateAndCallback } from './index'

/**
 * Given object field name, return its unique key and value/setValue pair.
 */
export type UseObjectEditor<T> = { [field in keyof T & string]: StateAndCallback<T[field]> }

/**
 * Given an object value/setValue, return a factory for its fields' individual value/setValue.
 * @param props The value/setValue for the object type
 * @param fieldNames The list of field names to extract
 */
export function useObjectEditor<T>(
    props: StateAndCallback<T>,
    fieldNames: readonly (keyof T & string)[]
): UseObjectEditor<T> {
    const { value, onChange: onChangeForObject } = props

    const onChanges = useMemo(() => {
        const map: { [key in keyof T & string]: SetStateCallback<T[key]> } = {} as never
        for (const key of fieldNames) {
            // noinspection UnnecessaryLocalVariableJS
            const onChangeForObjectField = (change: SetStateAction<unknown>) => {
                onChangeForObject((obj) => ({ ...obj, [key]: applyChange(obj[key], change) }))
            }
            map[key] = onChangeForObjectField
        }
        return map
    }, [fieldNames, onChangeForObject])

    return useMemo(() => {
        const map: { [key in keyof T & string]: StateAndCallback<T[key]> } = {} as never
        for (const key of fieldNames) {
            map[key] = { value: value[key], onChange: onChanges[key] } as never
        }
        return map
    }, [value, fieldNames, onChanges])
}
