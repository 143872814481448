import React from 'react'

import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useUpdateRowLayoutDTO } from 'src/hooks/useUpdateRowLayoutDTO'
import {
    RowLayoutType,
    StaticContentLayoutDTO,
    TitleRowDTO,
} from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorTextInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { VALIDATION_ERROR_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ValidationErrorEntityService'

export interface TitleRowEditorProps {
    titleRow: TitleRowDTO
    locale: Locale
    rowIndex: number
    itemId: string
    workflowEntityId: string
    showValidationError?: boolean
    editDisabled?: boolean
}

export const TitleRowEditor = (props: TitleRowEditorProps) => {
    const { entity: staticContentLayoutDTO } = useItemEntity<StaticContentLayoutDTO>({
        entityId: props.itemId,
        workflowEntityId: props.workflowEntityId,
    })

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const updateRow = useUpdateRowLayoutDTO<TitleRowDTO>(props.itemId, props.rowIndex)

    return (
        <ItemEditorTextInput
            inputId={'title'}
            value={props.titleRow.titleI18N[props.locale] ?? ''}
            disabled={props.editDisabled}
            placeholder={'Enter the title'}
            itemId={staticContentLayoutDTO.id}
            dataTestId={`title-${props.rowIndex}-input`}
            setValue={(next: string) => {
                updateRow((r) => ({
                    rowLayoutType: RowLayoutType.TITLE,
                    titleI18N: {
                        ...r.titleI18N,
                        [props.locale]: next,
                    } as LocalizedAttribute<string>,
                }))
            }}
            validationErrorMessage={(
                validationErrorEntity.validationErrors[`rows[${props.rowIndex}].titleI18N`] ?? []
            ).join(', ')}
            showError={props.showValidationError}
            labelText={'Title text'}
            maxCharacterLimit={36}
        />
    )
}
