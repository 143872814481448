import * as React from 'react'

import { Checkbox } from '@amzn/stencil-react-components/form'
import { Col } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Table, TableColumn } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { ScoringTestingV2ValidationObject } from 'src/services/hook/ScoringTestingV2Service'

export const FileValidationOutputTable = ({
    fileValidationOutput,
}: {
    fileValidationOutput: ScoringTestingV2ValidationObject[]
}) => {
    const tableColumns: TableColumn[] = [
        {
            header: '',
            width: '10%',
            cellComponent: () => <Checkbox />,
        },
        {
            header: 'Sheet Name',
            width: '10%',
            cellComponent: ({ data }: { data: ScoringTestingV2ValidationObject }) => (
                <Col gridGap='S200' alignItems='center'>
                    <Text>{data.scoringVariation}</Text>
                    {data.incorrectVariationId && (
                        <MessageBanner type={MessageBannerType.Error}>
                            Variation Id does not exist
                        </MessageBanner>
                    )}
                </Col>
            ),
        },
        {
            header: 'Incorrect Headers',
            width: '20%',
            cellComponent: ({ data }: { data: ScoringTestingV2ValidationObject }) => (
                <ul>
                    {data.incorrectHeaders?.map((incorrectHeader, index) => {
                        return (
                            <li key={`${incorrectHeader}${index}`}>
                                {index % 2 !== 0 ? (
                                    <Text>{incorrectHeader}</Text>
                                ) : (
                                    <Text>
                                        <strong>{incorrectHeader}</strong>
                                    </Text>
                                )}
                            </li>
                        )
                    })}
                </ul>
            ),
        },
        {
            header: 'Incorrect Input Score',
            width: '20%',
            cellComponent: ({ data }: { data: ScoringTestingV2ValidationObject }) => (
                <ul>
                    {data.incorrectInputScores?.map((incorrectInputScore, index) => {
                        return (
                            <li key={`${incorrectInputScore}${index}`}>
                                {index % 2 !== 0 ? (
                                    <Text>{incorrectInputScore}</Text>
                                ) : (
                                    <Text>
                                        <strong>{incorrectInputScore}</strong>
                                    </Text>
                                )}
                            </li>
                        )
                    })}
                </ul>
            ),
        },
        {
            header: 'Missing Input Scores',
            width: '20%',
            cellComponent: ({ data }: { data: ScoringTestingV2ValidationObject }) => (
                <ul>
                    {data.missingInputScores?.map((missingInputScore, index) => {
                        return (
                            <li key={`${missingInputScore}${index}`}>
                                {index % 2 !== 0 ? (
                                    <Text>{missingInputScore}</Text>
                                ) : (
                                    <Text>
                                        <strong>{missingInputScore}</strong>
                                    </Text>
                                )}
                            </li>
                        )
                    })}
                </ul>
            ),
        },
        {
            header: 'Incorrect Output Scores',
            width: '20%',
            cellComponent: ({ data }: { data: ScoringTestingV2ValidationObject }) => (
                <ul>
                    {data.incorrectOutputScores?.map((incorrectOutputScore, index) => {
                        return (
                            <li key={`${incorrectOutputScore}${index}`}>
                                {index % 2 !== 0 ? (
                                    <Text>{incorrectOutputScore}</Text>
                                ) : (
                                    <Text>
                                        <strong>{incorrectOutputScore}</strong>
                                    </Text>
                                )}
                            </li>
                        )
                    })}
                </ul>
            ),
        },
    ]

    return (
        <Col gridGap='S100'>
            <MessageBanner type={MessageBannerType.Error}>
                The file could not be processed. Fix the errors below
            </MessageBanner>
            <Text fontSize='T300' fontWeight='bold'>
                Errors
            </Text>
            <Table columns={tableColumns} data={fileValidationOutput} />
        </Col>
    )
}
