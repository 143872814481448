import React from 'react'

import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import {
    PrimaryScoreLabelWithSubstitute,
    SubstituteModuleScore,
} from 'src/models/dto/module-groups/MESGroupDTO'
import { MESValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'

export interface MESModuleGroupAddModuleProps {
    moduleVersionId?: string
    moduleScores?: string[]
    substituteScoreList: string[]
    substituteModuleScore: SubstituteModuleScore
    setSubstituteModuleScore: (substituteScore: SubstituteModuleScore) => void
    mesValidations?: MESValidationDTO
    setMesValidations?: (mesValidations: MESValidationDTO) => void
    currentSubstituteScores: PrimaryScoreLabelWithSubstitute[]
    setCurrentSubstituteScores: (currentSubstituteScores: PrimaryScoreLabelWithSubstitute[]) => void
}

export const MESModuleGroupAddModule = (props: MESModuleGroupAddModuleProps) => {
    const noSelectOptionMessage = props.moduleVersionId
        ? 'The selected module has no more scores'
        : ' Please enter a valid module version id at the top of the page'

    const updateSubstituteScore = (
        primaryScoreLabel: string,
        moduleScoreLabel: string,
        index: number
    ) => {
        const newSubScore = {
            primaryScoreLabel: primaryScoreLabel,
            substituteScoreLabel: moduleScoreLabel,
        } as PrimaryScoreLabelWithSubstitute
        const updatedSubScores = props.substituteModuleScore.substituteScores
        updatedSubScores.splice(index, 1, newSubScore)
        props.setSubstituteModuleScore({
            ...props.substituteModuleScore,
            substituteScores: updatedSubScores,
        })
    }

    const updateSubstituteScores = (primaryScoreLabels: string[]) => {
        const updatedSubScores = primaryScoreLabels.map((label) => {
            return (
                props.currentSubstituteScores.find(
                    (subScore) => subScore.primaryScoreLabel === label
                ) || { primaryScoreLabel: label, substituteScoreLabel: '' }
            )
        })
        props.setSubstituteModuleScore({
            ...props.substituteModuleScore,
            substituteScores: updatedSubScores,
        })
    }

    const getModuleScoreOptions = () => {
        return props.moduleScores && props.moduleScores.length > 0
            ? [
                  '',
                  ...props.moduleScores.filter(
                      (moduleScore) =>
                          !props.currentSubstituteScores.find(
                              (subScore) => subScore.substituteScoreLabel === moduleScore
                          )
                  ),
              ]
            : []
    }

    return (
        <>
            <Text fontWeight='bold'>Measurement Equivalent Configuration</Text>
            <InputWrapper
                id='substitute-score-label-select-wrapper'
                labelText='Select Substitute Scores'
                error={!!props.mesValidations?.substituteScores}
                footer={
                    props.mesValidations?.substituteScores
                        ? props.mesValidations?.substituteScores
                        : undefined
                }
                required
            >
                {(inputProps) => (
                    <Select
                        dataTestId='substitute-score-select'
                        {...inputProps}
                        value={props.currentSubstituteScores.map(
                            (subScore) => subScore.primaryScoreLabel
                        )}
                        isMulti={true}
                        options={props.substituteScoreList}
                        onChange={(values: string[]) => {
                            updateSubstituteScores(values)

                            if (props.setMesValidations) {
                                props.setMesValidations({
                                    ...props.mesValidations,
                                    substituteScores: '',
                                } as MESValidationDTO)
                            }
                        }}
                        disabled={!props.moduleVersionId}
                        placeholder={
                            props.moduleVersionId
                                ? 'Choose an option'
                                : 'Please select a module version id first'
                        }
                    />
                )}
            </InputWrapper>
            <ul>
                <Col gridGap='S300'>
                    {props.currentSubstituteScores.map(
                        (substituteScore: PrimaryScoreLabelWithSubstitute, index: number) => (
                            <li key={substituteScore.primaryScoreLabel}>
                                <Col>
                                    <InputWrapper
                                        id={`module-score-wrapper-${substituteScore.primaryScoreLabel}`}
                                        labelText={`Select the module equivalent score for ${substituteScore.primaryScoreLabel}`}
                                        error={
                                            !!props.mesValidations?.moduleEquivalentScores?.get(
                                                substituteScore.primaryScoreLabel
                                            )
                                        }
                                        footer={
                                            props.mesValidations?.moduleEquivalentScores?.get(
                                                substituteScore.primaryScoreLabel
                                            )
                                                ? props.mesValidations?.moduleEquivalentScores?.get(
                                                      substituteScore.primaryScoreLabel
                                                  )
                                                : undefined
                                        }
                                        required
                                    >
                                        {(inputProps) => (
                                            <Select
                                                dataTestId={`module-score-select-${substituteScore.primaryScoreLabel}`}
                                                {...inputProps}
                                                value={substituteScore.substituteScoreLabel}
                                                options={getModuleScoreOptions()}
                                                noOptionsText={noSelectOptionMessage}
                                                renderOption={ModuleGroupRenderOption}
                                                onChange={(option: string) => {
                                                    updateSubstituteScore(
                                                        substituteScore.primaryScoreLabel,
                                                        option,
                                                        index
                                                    )
                                                    if (
                                                        props.setMesValidations &&
                                                        props.mesValidations?.moduleEquivalentScores?.get(
                                                            substituteScore.primaryScoreLabel
                                                        )
                                                    ) {
                                                        const tempModuleEquivalentScores =
                                                            props.mesValidations
                                                                ?.moduleEquivalentScores
                                                        tempModuleEquivalentScores.set(
                                                            substituteScore.primaryScoreLabel,
                                                            ''
                                                        )

                                                        props.setMesValidations({
                                                            ...props.mesValidations,
                                                            moduleEquivalentScores:
                                                                tempModuleEquivalentScores,
                                                        } as MESValidationDTO)
                                                    }
                                                }}
                                            />
                                        )}
                                    </InputWrapper>
                                </Col>
                            </li>
                        )
                    )}
                </Col>
            </ul>
        </>
    )
}
