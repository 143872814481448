import React, { useState } from 'react'
import PapaParse from 'papaparse'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Expander } from '@amzn/stencil-react-components/expander'
import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { IconArrowLeft } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner, SpinnerColorScheme } from '@amzn/stencil-react-components/spinner'
import { H2, Label, Text } from '@amzn/stencil-react-components/text'

import { WorkflowSearchField } from 'src/components/scoring-testing-v2/WorkflowSearchField'
import { HookAuthenticator } from 'src/pages/scoring-test-ui/authentication/HookAuthenticator'
import { Countries } from 'src/pages/scoring-test-ui/Countries'
import { CountrySearchField } from 'src/pages/scoring-test-ui/CountrySearchField'
import {
    CSVParseResult,
    downloadFileFromS3,
    parseS3GetResponse,
    uploadFileToS3,
} from '../../services/hook/HookFileService'
import { CSVPreviewTables } from './CSVPreviewTables'
import { callScoringTestAPI, ScoringTestAPIResponse } from './ScoringTestApiManager'
import { TableDataFormat } from './TableDataFormat'
/**
 * Automatic Scoring Testing UI (WIP)
 */

const SUCCESSFUL_API_RESPONSE = '200'

interface GetScoringResultResponse {
    filename: string
    fileUrl: string
}

export function ScoringTest() {
    //Authenticate with Hook (Workflow Builder Website's account)
    HookAuthenticator.initCognitoAuth()
    HookAuthenticator.authenticate(() => {})

    const pageSize = 10
    const INPUT_MEDIA_PATH = 'input'
    const OUTPUT_MEDIA_PATH = 'output'

    const [fileTableList, setFileTableList] = useState(new Map<string, TableDataFormat>())
    const [tabList, setTabList] = useState<string[]>([])
    const [tableIsExpanded, setTableIsExpanded] = useState<boolean>(false)
    const [pageDisplayState, setPageDisplayState] = useState<string>('inputScreen')
    const [file, setFile] = useState<File>()
    const [outputTabList, setOutputTabList] = useState<string[]>([])
    const [downloadUrls, setDownloadUrls] = useState(new Map<string, string>())
    const [errorMessage, setErrorMessage] = useState<string | null>('')
    const [noFileIsImported, setNoFileIsImported] = useState<boolean>(false)
    const [workflowIdIsEmpty, setWorkflowIdIsEmpty] = useState<boolean>(false)
    const [countryIdIsIncorrect, setCountryIdIsIncorrect] = useState<boolean>(false)
    const [workflowId, setWorkflowId] = useState<string>('')
    const [countryId, setCountryId] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [outputFileTableList, setOutputFileTableList] = useState(
        new Map<string, TableDataFormat>()
    )

    const updateOutputFileTableList = (key: string, value: TableDataFormat) => {
        setOutputFileTableList((map) => new Map(map.set(key, value)))
    }

    const updateFileTableList = (key: string, value: TableDataFormat) => {
        setFileTableList((map) => new Map(map.set(key, value)))
    }

    const deleteImportedFile = (filename: string) => {
        const newFileTableList = new Map(fileTableList)
        newFileTableList.delete(filename)
        setFileTableList(() => newFileTableList)
        setTabList(() => Array.from(newFileTableList.keys()))
    }

    const onFileAttached = () => {
        if (file) {
            new Promise((resolve, reject) =>
                PapaParse.parse(file, {
                    skipEmptyLines: true,
                    header: true,
                    complete: resolve,
                    error: reject,
                })
            )
                .then((result) => {
                    const resultObj = result as CSVParseResult
                    const columns = (resultObj.meta['fields'] as string[]).map(function (
                        x: string
                    ) {
                        if (!x) {
                            throw new Error(
                                'The input file provided is missing some column headers'
                            )
                        }
                        return {
                            header: x,
                            accessor: x,
                        }
                    })
                    updateFileTableList(file.name, {
                        columns: columns,
                        data: resultObj.data,
                        file: file,
                    })
                    setTabList(Array.from(fileTableList.keys()))
                    setTableIsExpanded(true)
                })
                .catch((err) => {
                    console.error(err)
                    setErrorMessage((err as Error)?.message)
                })
        }
    }

    async function getAndParseOutputFileFromS3(filename: string) {
        try {
            const result = await downloadFileFromS3(`${OUTPUT_MEDIA_PATH}/${filename}.csv`)
            const parsedFile = await parseS3GetResponse(result.Body!.toString(), filename)
            if (parsedFile) {
                parsedFile.forEach((val: TableDataFormat, i: string) => {
                    updateOutputFileTableList(i, val)
                })
            }
        } catch (e: unknown) {
            setErrorMessage((e as Error).message)
            return false
        }
        setPageDisplayState('outputScreen')
        return true
    }

    async function getScoringTestResults(
        value: TableDataFormat
    ): Promise<GetScoringResultResponse> {
        const key: string = value.file!.name
        try {
            await uploadFileToS3(value.file!, `${INPUT_MEDIA_PATH}/${value.file!.name}`)
        } catch (e) {
            setErrorMessage(`Error uploading ${value.file!.name} to S3`)
            return { filename: '', fileUrl: '' } as GetScoringResultResponse
        }
        const response: ScoringTestAPIResponse = await callScoringTestAPI(
            workflowId,
            key,
            countryId.toUpperCase()
        )
        if (response.status && response.status === SUCCESSFUL_API_RESPONSE) {
            const fileName: string = response.fileUrl.split('.csv', 2)[0].split('/output/', 2)[1]
            return (await getAndParseOutputFileFromS3(fileName))
                ? ({ filename: fileName, fileUrl: response.fileUrl } as GetScoringResultResponse)
                : ({ filename: '', fileUrl: '' } as GetScoringResultResponse)
        } else {
            if (response.error) {
                setErrorMessage(response.error)
                return { filename: '', fileUrl: '' } as GetScoringResultResponse
            }
            setErrorMessage('Something went wrong during the scoring test')
            return { filename: '', fileUrl: '' } as GetScoringResultResponse
        }
    }

    function validateFields(): boolean {
        let isValid = true
        if (Array.from(fileTableList.keys()).length == 0) {
            setNoFileIsImported(true)
            isValid = false
        } else {
            setNoFileIsImported(false)
        }
        if (!workflowId || !workflowId.trim()) {
            setWorkflowIdIsEmpty(true)
            isValid = false
        } else {
            setWorkflowIdIsEmpty(false)
        }
        if (
            countryId.length != 0 &&
            !Countries.find((country) => country.alpha3Code === countryId.toUpperCase())
        ) {
            setCountryIdIsIncorrect(true)
            isValid = false
        } else {
            setCountryIdIsIncorrect(false)
        }
        return isValid
    }

    async function onSubmitClicked() {
        setErrorMessage(null)
        setWorkflowIdIsEmpty(false)
        const localTabList: string[] = []
        const localUrlList = new Map<string, string>()

        if (validateFields()) {
            setTableIsExpanded(false)
            for (const value of Array.from(fileTableList.values())) {
                setIsLoading(true)
                const scoringResults: GetScoringResultResponse = await getScoringTestResults(value)
                setIsLoading(false)
                if (scoringResults.filename && scoringResults.fileUrl) {
                    localTabList.push(scoringResults.filename)
                    localUrlList.set(scoringResults.filename, scoringResults.fileUrl)
                }
            }
            setOutputTabList(localTabList)
            setDownloadUrls(localUrlList)
        }
    }

    return (
        <Col
            backgroundColor='white'
            padding={{ top: 'S300', left: 'S200', right: 'S200', bottom: '40vh' }}
            overflow='hidden'
        >
            {errorMessage && (
                <MessageBanner type={MessageBannerType.Error}>{`${errorMessage}`}</MessageBanner>
            )}

            {pageDisplayState == 'inputScreen' && (
                <>
                    <View padding={{ left: 'S300', right: 'S300' }}>
                        <H2>Automated Scoring Testing</H2>
                        <Text>Please provide the necessary test scenario input.</Text>
                    </View>

                    <Spacer height='S400' />
                    <Hr size='wide' color='neutral20' />
                    <Spacer height='S400' />
                    <Col gridGap='S200'>
                        <Card isElevated={true} data-test-id='file-upload-card'>
                            <Col data-test-id='file-upload' gridGap='S200' width='100%'>
                                <Label htmlFor='file-upload' data-test-id='csv-label'>
                                    Select your CSV file
                                </Label>
                                <FileUpload
                                    dragAndDropText='or just drag and drop your CSV file here!'
                                    accept='text/csv'
                                    id='file-upload'
                                    errorText='The upload failed'
                                    isMulti={false}
                                    onFileAttached={(files) => {
                                        setFile(files[0])
                                    }}
                                />
                                {noFileIsImported && (
                                    <MessageBanner
                                        type={MessageBannerType.Error}
                                        dataTestId='import-error-banner'
                                    >
                                        Please import a test case CSV file
                                    </MessageBanner>
                                )}
                                <Row justifyContent={'center'} gridGap='S400'>
                                    {/*// @ts-ignore: Expression produces a union type that is too complex to represent error*/}
                                    <Button
                                        disabled={!file}
                                        dataTestId={'import-csv-button'}
                                        onClick={onFileAttached}
                                    >
                                        Import File
                                    </Button>
                                </Row>
                            </Col>
                        </Card>

                        {fileTableList.size > 0 && (
                            <>
                                <Card
                                    isElevated={true}
                                    width='100%'
                                    data-test-id='table-tab-pagination-setup'
                                    display='auto'
                                >
                                    <Expander
                                        titleText='Input file tables'
                                        isExpanded={tableIsExpanded}
                                        onToggle={setTableIsExpanded}
                                        dataTestId={'csv-table-expander'}
                                    >
                                        <CSVPreviewTables
                                            fileTableList={fileTableList}
                                            tabList={tabList}
                                            tabGroup={'output-files-preview'}
                                            pageSize={pageSize}
                                            onFileDelete={(filename) =>
                                                deleteImportedFile(filename)
                                            }
                                        />
                                    </Expander>
                                </Card>
                            </>
                        )}

                        <Card isElevated={true}>
                            <Row justifyContent='center' width='100%'>
                                <Col width='44%'>
                                    <WorkflowSearchField
                                        workflowIdIsEmpty={workflowIdIsEmpty}
                                        onValueChange={(value) => setWorkflowId(value)}
                                        workflowId={workflowId}
                                    />
                                </Col>
                                <Spacer width='10%' />
                                <Col width='44%'>
                                    <CountrySearchField
                                        countryId={countryId}
                                        onValueChange={(value) => setCountryId(value)}
                                        countryIdNotFound={countryIdIsIncorrect}
                                    />
                                </Col>
                            </Row>
                        </Card>

                        <Col justifyContent='space-evenly' alignItems='center'>
                            <Spacer height='S700' />
                            {/* @ts-ignore: Expression produces a union type that is too complex to represent error */}
                            <Button
                                type='submit'
                                id='submit-button'
                                data-test-id='submit-button'
                                variant={ButtonVariant.Primary}
                                onClick={onSubmitClicked}
                            >
                                {isLoading ? (
                                    <Spinner
                                        colorScheme={SpinnerColorScheme.Inverted}
                                        data-test-id='submit-spinner'
                                    />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Col>
                    </Col>
                </>
            )}

            {pageDisplayState == 'outputScreen' && (
                <>
                    <View padding={{ left: 'S300', right: 'S300' }}>
                        <H2>Automated Scoring Testing</H2>
                        <Text>Output for your input files is shown below</Text>
                    </View>

                    <Spacer height='S400' />
                    <Hr size='wide' color='neutral20' />
                    <Spacer height='S400' />
                    <Row gridGap='S200' width='100%' height='100%' overflow='false'>
                        {/* @ts-ignore: Expression produces a union type that is too complex to represent error */}
                        <Button
                            icon={<IconArrowLeft />}
                            type='submit'
                            id='back-button'
                            data-test-id='back-button'
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            Back
                        </Button>
                    </Row>

                    <Col gridGap='S200'>
                        <Card
                            isElevated={true}
                            width='100%'
                            overflow='false'
                            data-test-id='table-tab-pagination-setup'
                        >
                            <CSVPreviewTables
                                fileTableList={outputFileTableList}
                                tabList={outputTabList}
                                tabGroup={'output-files-preview'}
                                pageSize={pageSize}
                                downloadable={true}
                                urlList={downloadUrls}
                            />
                        </Card>
                    </Col>
                </>
            )}
        </Col>
    )
}
