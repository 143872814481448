import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface WrittenResponseItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.WrittenResponse
    questionI18N: LocalizedAttribute<string>
    minCharacterLimit: number | null
    maxCharacterLimit: number | null
}

export const defaultWrittenResponseItemDTO = (): WrittenResponseItemDTO => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        minCharacterLimit: 0,
        maxCharacterLimit: 500,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.WrittenResponse,
        ppt: ItemType.WrittenResponse,
        questionI18N: LocalizeDefault<string>(''),
    }
}
