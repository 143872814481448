import { ActivityEntity, ActivityType } from 'src/models/dto/ActivityDTO'
import { Locale } from 'src/models/dto/Locale'

export type MUPPExamEntity = ActivityEntity & MUPPExamDTO

export interface MUPPExamDTO {
    id: string
    name: string
    type: ActivityType
    ppt: string
    selection: CATSelection
    locale: Locale
}

export interface CATSelection {
    itemBankId: string
    pairingTableDTO: PairingTableDTO
    pctMax: number | null
    groupSize: number | null
    constraints: MUPPConstraint[] | null
}

export interface PairingTableDTO {
    id: string
    type: CATElementType.MUPP_PAIRING_TABLE
    locale: Locale
    name: string
    dimensionPairs: DimensionPair[]
}

export enum CATElementType {
    MUPP_DICHOTOMOUS_ITEM,
    MUPP_ITEM_BANK,
    MUPP_PAIRING_TABLE,
    MUPP_EXAM,
}

export enum ConstraintType {
    EXPOSURE = 'EXPOSURE',
    MIN_ITEMS_BETWEEN_REPEAT = 'MIN_ITEMS_BETWEEN_REPEAT',
    STATEMENT_REPEAT_MAX = 'STATEMENT_REPEAT_MAX',
    LOCATION = 'LOCATION',
    UNI_LOCATION = 'UNI_LOCATION',
    MULTI_LOCATION = 'MULTI_LOCATION',
    SOCIAL_DESIRABILITY = 'SOCIAL_DESIRABILITY',
    UNI_SOCIAL_DESIRABILITY = 'UNI_SOCIAL_DESIRABILITY',
    MULTI_SOCIAL_DESIRABILITY = 'MULTI_SOCIAL_DESIRABILITY',
}

export interface MUPPConstraint {
    type: ConstraintType
}

export interface SingleValueConstraint extends MUPPConstraint {
    type:
        | ConstraintType.EXPOSURE
        | ConstraintType.MIN_ITEMS_BETWEEN_REPEAT
        | ConstraintType.MULTI_SOCIAL_DESIRABILITY
        | ConstraintType.SOCIAL_DESIRABILITY
        | ConstraintType.UNI_SOCIAL_DESIRABILITY
        | ConstraintType.STATEMENT_REPEAT_MAX
    value: number
}

export interface MultiValueConstraint extends MUPPConstraint {
    type: ConstraintType.LOCATION | ConstraintType.MULTI_LOCATION | ConstraintType.UNI_LOCATION
    lowerBound: number
    upperBound: number
}

export interface DimensionPair {
    first: string
    second: string
}

export interface APTDimensionPair {
    firstDimension: string
    secondDimension: string
}
