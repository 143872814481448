import React, { useState } from 'react'
import { DateTime } from 'luxon'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { RawMediaView } from 'src/components/media/RawMediaView'
import { Locale, LocaleDescription } from 'src/models/dto/Locale'
import { MediaTemplateModel } from 'src/pages/asset-library/AssetUploadedPage'
import { UploadButton } from 'src/pages/asset-library/UploadButton'

const AssetPreview = ({
    fileUrl,
    fileType,
    errorLoadingFile,
    isDeleted,
    altText,
    locale,
    captionsPath,
    updateMedia,
}: {
    fileUrl: string
    fileType?: string
    errorLoadingFile: boolean
    isDeleted: boolean
    locale: Locale
    altText?: string
    captionsPath: string
    updateMedia?: (updatedFilePath: string, updatedFileName: string) => void
}) => {
    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)
    if (errorLoadingFile) {
        return (
            <MessageBanner type={MessageBannerType.Error}>
                Error previewing asset. Please try refreshing the page.
            </MessageBanner>
        )
    } else if (isDeleted) {
        return (
            <>
                {unexpectedError && (
                    <MessageBanner type={MessageBannerType.Error}>{unexpectedError}</MessageBanner>
                )}
                <UploadButton
                    setError={setUnexpectedError}
                    acceptedTypes={fileType}
                    updateMedia={updateMedia}
                />
            </>
        )
    }
    return (
        <RawMediaView
            url={fileUrl}
            mimeType={fileType}
            controls
            startExpanded={true}
            captionsPath={captionsPath}
            locale={LocaleDescription[locale]}
            altText={altText}
        />
    )
}

export const FileView = ({
    media,
    captionsUrl,
    locale,
    errorLoadingFile,
    validationErrors,
    fileUrl,
    setMedia,
    setSaved,
    setIsDeleted,
    isDeleted,
}: {
    media: MediaTemplateModel
    captionsUrl: string
    locale: Locale
    errorLoadingFile: boolean
    validationErrors: Record<string, string>
    fileUrl: string
    setMedia: React.Dispatch<React.SetStateAction<MediaTemplateModel>>
    setSaved: React.Dispatch<React.SetStateAction<boolean>>
    setIsDeleted: (value: ((prevState: boolean) => boolean) | boolean) => void
    isDeleted: boolean
}) => {
    const shouldUseDefaultMediaData = locale === Locale.en_US || !media.fileRequiresTranslation
    const fileType = shouldUseDefaultMediaData ? media.fileType : media.languages[locale]?.fileType
    const fileName =
        (shouldUseDefaultMediaData ? media.defaultFileName : media.languages[locale]?.fileName) ??
        ''
    const fileNameValidationError = shouldUseDefaultMediaData
        ? validationErrors.defaultFileName
        : validationErrors[`media.languages[${locale}].fileName`]
    const uploadedBy =
        (shouldUseDefaultMediaData ? media.createdBy : media.languages[locale]?.fileCreatedBy) ?? ''
    const createdAt =
        (shouldUseDefaultMediaData ? media.createdAt : media.languages[locale]?.fileCreatedAt) ?? ''
    const altText = media.languages[locale]?.alternateText

    const updateMedia = (updatedFilePath: string, updatedFileName: string) => {
        setMedia((prevState) => ({
            ...prevState,
            defaultFileName: updatedFileName,
            defaultFilePath: updatedFilePath,
        }))
    }

    const CustomFileNameLabel = ({ htmlFor, children }) => {
        const disabledDeleteButton =
            media.languages[locale]?.published ||
            isDeleted ||
            (locale !== Locale.en_US && !media.fileRequiresTranslation)
        return (
            <Row maxHeight={'26px'}>
                <label htmlFor={htmlFor}>
                    <Text fontSize={'T200'}>{children}</Text>
                </label>
                <Spacer flex={1} />
                <Col alignSelf={'center'}>
                    <Button
                        dataTestId={'delete-file'}
                        variant={ButtonVariant.Tertiary}
                        isDestructive
                        size={ButtonSize.Small}
                        disabled={disabledDeleteButton}
                        onClick={() => {
                            if (shouldUseDefaultMediaData) {
                                setMedia((prevState) => ({
                                    ...prevState,
                                    defaultFileName: '',
                                    defaultFilePath: '',
                                }))
                            } else {
                                setMedia((prevState) => ({
                                    ...prevState,
                                    languages: {
                                        ...prevState.languages,
                                        [locale]: {
                                            ...prevState.languages[locale],
                                            filePath: '',
                                            fileName: '',
                                        },
                                    },
                                }))
                            }
                            setSaved(false)
                            setIsDeleted(true)
                        }}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Col gridGap='S400' flex={'1 1 0'} justifyContent={'center'}>
                <AssetPreview
                    fileUrl={fileUrl}
                    fileType={fileType}
                    errorLoadingFile={errorLoadingFile}
                    isDeleted={isDeleted}
                    altText={altText}
                    locale={locale}
                    captionsPath={captionsUrl}
                    updateMedia={updateMedia}
                />
            </Col>
            <Col gridGap='S400' flex={'1 1 0'}>
                <InputWrapper
                    id='filename'
                    labelText='File Name'
                    dataTestId={'file-name-wrapper'}
                    footer={validationErrors.defaultFileName}
                    renderLabel={CustomFileNameLabel}
                    error={(fileNameValidationError ?? '').length > 0}
                >
                    {(inputProps) => (
                        <Input {...inputProps} type='text' value={fileName} disabled={true} />
                    )}
                </InputWrapper>
                {uploadedBy.length > 0 && (
                    <InputWrapper id={'uploader'} labelText='Uploader'>
                        {(inputProps) => (
                            <Input {...inputProps} type='text' value={uploadedBy} disabled={true} />
                        )}
                    </InputWrapper>
                )}
            </Col>
            <Col gridGap='S400' flex={'1 1 0'}>
                <InputWrapper id={'filetype'} labelText='File Type'>
                    {(inputProps) => (
                        <Input {...inputProps} type='text' value={fileType} disabled={true} />
                    )}
                </InputWrapper>
                {createdAt.length > 0 && (
                    <InputWrapper id={'date'} labelText='Uploaded Date'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                type='text'
                                value={DateTime.fromISO(createdAt, {
                                    zone: 'UTC',
                                })
                                    .setZone('system')
                                    .toLocaleString(DateTime.DATETIME_SHORT)}
                                disabled={true}
                            />
                        )}
                    </InputWrapper>
                )}
            </Col>
        </>
    )
}
