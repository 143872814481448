import React, { useCallback, useMemo } from 'react'

import { Expander, ExpanderTitleRendererParams } from '@amzn/stencil-react-components/expander'
import { Col, Row, View } from '@amzn/stencil-react-components/layout'

import { ClickableH3, ClickableH4 } from 'src/components/ExpanderHeadings'
import { StructuralPreview } from 'src/components/StructuralPreview'
import { ItemGroupDTO } from 'src/models/dto/activities/ItemGroupDTO'
import { ActivityDTO, ActivityType } from 'src/models/dto/ActivityDTO'
import { Locale } from 'src/models/dto/Locale'
import { ModuleDTO } from 'src/models/dto/ModuleDTO'
import { locationId } from 'src/pages/module-review/Comments'
import { CommentsPopover } from 'src/pages/module-review/CommentsPopover'

export function itemDTOSubsetForPreview(itemDTO: { itemType: string } & Record<string, unknown>) {
    const {
        type: _t,
        ppt: _p,
        id: _i,
        versionId: _vid,
        poolVersionId: _pvid,
        itemType: _it,
        locale: _l,
        ...subset
    } = itemDTO as never as Record<string, unknown>
    return subset
}

type ItemPreviewProps = { locale: Locale; itemDTO: { itemType: string } & Record<string, unknown> }

function ItemPreview({ itemDTO, locale }: ItemPreviewProps) {
    const value = useMemo(() => itemDTOSubsetForPreview(itemDTO), [itemDTO])

    const renderItemPreviewTitle = useCallback(
        ({ titleText, toggle }: ExpanderTitleRendererParams) => {
            return (
                <Row justifyContent='space-between' width='100%'>
                    <ClickableH4 onClick={toggle}>{titleText}</ClickableH4>
                </Row>
            )
        },
        []
    )

    return (
        <Expander titleText={itemDTO.itemType || '(Unknown)'} renderTitle={renderItemPreviewTitle}>
            <StructuralPreview value={value} locale={locale} />
        </Expander>
    )
}

function PagePreview({
    activityDTO,
    index,
    locale,
}: {
    activityDTO: ActivityDTO
    index: number
    locale: Locale
}) {
    const items: ({ itemType: string } & Record<string, unknown>)[] = useMemo(() => {
        switch (activityDTO.moduleChild?.type) {
            case ActivityType.LaunchItem:
                return [activityDTO.moduleChild as never]
            case ActivityType.LaunchBucketsAndCups:
                return [{ ...activityDTO.moduleChild, itemType: 'LaunchBucketAndCups' }]
            case ActivityType.LaunchRandomSelection:
                return [{ ...activityDTO.moduleChild, itemType: 'LaunchRandomSelection' }]
            case ActivityType.LaunchAdaptiveEngine:
                return [{ ...activityDTO.moduleChild, itemType: 'LaunchAdaptiveEngine' }]
            default:
                return (
                    ((activityDTO.moduleChild as ItemGroupDTO | undefined)?.items as never[]) ?? []
                )
        }
    }, [activityDTO])
    const l = locationId({ pageNumber: index + 1 })
    const renderPagePreviewTitle = useCallback(
        ({ titleText, toggle }: ExpanderTitleRendererParams) => {
            return (
                <Row justifyContent='space-between' width='100%'>
                    <ClickableH3 onClick={toggle}>{titleText}</ClickableH3>
                    <CommentsPopover locationId={l} />
                </Row>
            )
        },
        [l]
    )

    let isConditional = false

    if (activityDTO.moduleChild?.type === ActivityType.LaunchItemGroup) {
        if (
            (activityDTO.moduleChild as ItemGroupDTO).branchingConditions !== undefined &&
            (activityDTO.moduleChild as ItemGroupDTO).branchingConditions !== null
        ) {
            isConditional = true
        }
    }

    return (
        <View backgroundColor='neutral0' padding='S300'>
            <Expander
                titleText={`Page ${index + 1} ${isConditional ? '(conditional)' : ''}`}
                renderTitle={renderPagePreviewTitle}
            >
                {items.map((itemDTO, itemIndex) => (
                    <View key={itemIndex} padding={{ left: 'S300' }}>
                        <ItemPreview itemDTO={itemDTO} locale={locale} key={itemIndex} />
                    </View>
                ))}
            </Expander>
        </View>
    )
}

export function ModulePreview({ moduleDTO, locale }: { moduleDTO: ModuleDTO; locale: Locale }) {
    return (
        <Col title='Module Preview'>
            <Col gridGap='S300'>
                {moduleDTO.workflow.map((activityDTO, index) => (
                    <PagePreview
                        key={index}
                        activityDTO={activityDTO}
                        index={index}
                        locale={locale}
                    />
                ))}
            </Col>
        </Col>
    )
}
