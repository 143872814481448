import {
    BaseTableDTO,
    TableCell,
    TableCellType,
    TableHeader,
    TableIconType,
    TableLegend,
    TableRow,
    TableSymbolType,
} from 'src/models/dto/items/BaseTableDTO'
import {
    getCellDataAfterDelete,
    replaceCellSymbol,
} from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import { Locale, LocalizeDefault } from '../../../models/dto/Locale'

export abstract class TableOperations<T extends BaseTableDTO> {
    protected abstract getTable(id: string): T

    protected abstract updateTable(id: string, entity: T)

    protected static createEmptyRows(numberOfRows: number, numberOfColumns: number): TableRow[] {
        return new Array(numberOfRows)
            .fill(1)
            .map(() => TableOperations.createEmptyRow(numberOfColumns))
    }

    protected static createEmptyRow(numberOfCells: number): TableRow {
        return {
            cells: new Array(numberOfCells).fill(1).map(() => undefined),
        }
    }

    protected static createEmptyHeaders(numberOfHeaders: number) {
        return new Array(numberOfHeaders).fill(1).map((_val, _ind) => ({
            headerI18N: LocalizeDefault<string>('', Locale.en_US),
        }))
    }

    public setNumberOfColumns(itemId: string, nextNumber: number) {
        const entity = this.getTable(itemId)
        const numberOfColumns = entity.headers.length

        const difference = nextNumber - numberOfColumns

        if (difference > 0) {
            this.updateTable(itemId, {
                ...entity,
                headers: [...entity.headers, ...TableOperations.createEmptyHeaders(difference)],
                rows: [
                    ...entity.rows.map((r) => ({
                        cells: [...r.cells, ...TableOperations.createEmptyRow(difference).cells],
                    })),
                ],
            })
        } else {
            this.updateTable(itemId, {
                ...entity,
                headers: entity.headers.slice(0, nextNumber),
                rows: entity.rows.map((row) => ({
                    cells: row.cells.slice(0, nextNumber),
                })),
            })
        }
    }

    public setNumberOfRows(itemId: string, nextNumber: number) {
        const entity = this.getTable(itemId)
        const numberOfRows = entity.rows.length

        const difference = nextNumber - numberOfRows

        if (difference > 0) {
            this.updateTable(itemId, {
                ...entity,
                rows: [
                    ...entity.rows,
                    ...TableOperations.createEmptyRows(difference, entity.headers.length),
                ],
            })
        } else {
            this.updateTable(itemId, {
                ...entity,
                rows: entity.rows.slice(0, nextNumber),
            })
        }
    }

    public setColumnName(itemId: string, columnIndex: number, columnText: string, locale: Locale) {
        const entity = this.getTable(itemId)
        const headers: TableHeader[] = entity.headers

        const left = headers.slice(0, columnIndex)
        const right = headers.slice(columnIndex + 1, headers.length)
        const updated = {
            headerI18N: {
                ...headers[columnIndex].headerI18N,
                [locale]: columnText,
            },
        }
        this.updateTable(itemId, {
            ...entity,
            headers: [...left, updated, ...right],
        })
    }

    public setCellData(itemId: string, columnIndex: number, rowIndex: number, cellData: TableCell) {
        const entity = this.getTable(itemId)
        this.updateTable(itemId, {
            ...entity,
            rows: entity.rows.map((row, rowNumber) => {
                if (rowNumber === rowIndex) {
                    return {
                        cells: row.cells.map((cell, columnNumber) => {
                            if (columnNumber === columnIndex) {
                                return cellData
                            }
                            return cell
                        }),
                    }
                }

                return row
            }),
        })
    }

    public toggleHarveyBallLegend(itemId: string, enabled: boolean) {
        const entity = this.getTable(itemId)

        if (this.getHarveyBallLegends(itemId).length === 0 && enabled) {
            this.updateTable(itemId, {
                ...entity,
                legends: entity.legends.concat([
                    {
                        iconType: TableIconType.HARVEY_BALL_EMPTY,
                        textI18N: LocalizeDefault('Low Amount'),
                    },
                    {
                        iconType: TableIconType.HARVEY_BALL_HALF_FULL,
                        textI18N: LocalizeDefault('Moderate Amount'),
                    },
                    {
                        iconType: TableIconType.HARVEY_BALL_FULL,
                        textI18N: LocalizeDefault('High Amount'),
                    },
                ]),
                rows: entity.rows,
                harveyBallLegendEnabled: true,
            })
        } else if (!enabled) {
            this.updateTable(itemId, {
                ...entity,
                legends: this.getSymbolLegends(itemId),
                rows: entity.rows.map((row) => ({
                    cells: row.cells.map((c) => (c?.type === TableCellType.ICON ? undefined : c)),
                })),
                harveyBallLegendEnabled: false,
            })
        }
    }

    public toggleSymbolLegend(itemId: string, enabled: boolean) {
        const entity = this.getTable(itemId)

        if (enabled) {
            this.updateTable(itemId, {
                ...entity,
                symbolLegendEnabled: true,
                legends: this.getHarveyBallLegends(itemId).concat({
                    iconType: undefined,
                    textI18N: LocalizeDefault(''),
                }),
                rows: entity.rows,
            })
        } else if (!enabled) {
            this.updateTable(itemId, {
                ...entity,
                symbolLegendEnabled: false,
                legends: this.getHarveyBallLegends(itemId),
                rows: entity.rows.map((row) => ({
                    cells: row.cells.map((c) => (c?.type === TableCellType.SYMBOL ? undefined : c)),
                })),
            })
        }
    }

    public setScaleName(itemId: string, iconType: TableIconType, locale: Locale, value: string) {
        const entity = this.getTable(itemId)
        this.updateTable(itemId, {
            ...entity,
            legends: entity.legends.map((l) => {
                if (l.iconType === iconType) {
                    l.textI18N[locale] = value
                }

                return l
            }),
        })
    }

    public setSymbolType(
        itemId: string,
        symbolType: TableSymbolType,
        locale: Locale,
        symbolLegendIndex: number
    ) {
        const entity = this.getTable(itemId)
        const newLegend = {
            iconType: symbolType,
            textI18N: {
                ...entity.legends[symbolLegendIndex].textI18N,
            },
        }
        const previousSymbol = entity.legends[symbolLegendIndex].iconType as TableSymbolType
        entity.legends[symbolLegendIndex] = newLegend
        this.updateTable(itemId, {
            ...entity,
        })
        this.modifyExistingSymbolCellsData(itemId, previousSymbol, symbolType)
    }

    public setSymbolLegend(
        itemId: string,
        locale: Locale,
        symbolLegendText: string,
        symbolLegendIndex: number
    ) {
        const entity = this.getTable(itemId)
        const newLegend = {
            iconType: entity.legends[symbolLegendIndex].iconType,
            textI18N: {
                ...entity.legends[symbolLegendIndex].textI18N,
                [locale]: symbolLegendText,
            },
        }
        entity.legends[symbolLegendIndex] = newLegend
        this.updateTable(itemId, {
            ...entity,
        })
    }

    public deleteSymbolLegend(itemId: string, index: number) {
        const entity = this.getTable(itemId)
        const newLegends = entity.legends
        const deletedLegend = newLegends.splice(index, 1)[0]
        this.updateTable(itemId, {
            ...entity,
            legends: newLegends,
        })
        if (deletedLegend.iconType && deletedLegend.textI18N) {
            const deletedSymbol: TableSymbolType = deletedLegend.iconType as TableSymbolType
            this.modifyExistingSymbolCellsData(itemId, deletedSymbol)
        }
    }

    public getSymbolLegends(itemId: string): TableLegend[] {
        const entity = this.getTable(itemId)
        return entity.legends.filter((legend) => {
            return !legend.iconType || TableSymbolType[legend.iconType]
        })
    }

    public getHarveyBallLegends(itemId: string): TableLegend[] {
        const entity = this.getTable(itemId)
        return entity.legends.filter((legend) => {
            return legend.iconType && TableIconType[legend.iconType]
        })
    }

    public createNewSymbolEmptyLegend(itemId: string) {
        const entity = this.getTable(itemId)
        this.updateTable(itemId, {
            ...entity,
            legends: entity.legends.concat({
                iconType: undefined,
                textI18N: LocalizeDefault(''),
            }),
        })
    }

    public getValueMapForSymbols(itemId: string, locale: Locale): Map<TableSymbolType, string> {
        const valueMap: Map<TableSymbolType, string> = new Map()
        this.getSymbolLegends(itemId).forEach((legend) => {
            if (legend.iconType && legend.textI18N[locale]) {
                valueMap.set(legend.iconType as TableSymbolType, legend.textI18N[locale] || '')
            }
        })
        return valueMap
    }

    public hasValidSymbolLegends(itemId: string, locale: Locale): boolean {
        return this.getValueMapForSymbols(itemId, locale).size > 0
    }

    public getFirstValidSymbolLegend(itemId: string, locale: Locale): TableSymbolType {
        return Array.from(this.getValueMapForSymbols(itemId, locale).keys())[0]
    }

    /*
    This method looks through all the symbols of all locales of all the cells to remove or update removedSymbol
    from the cell data
     */
    public modifyExistingSymbolCellsData(
        itemId: string,
        updateSymbol: TableSymbolType,
        newSymbol?: TableSymbolType
    ) {
        const entity = this.getTable(itemId)
        this.updateTable(itemId, {
            ...entity,
            rows: entity.rows.map((row) => ({
                cells: row.cells.map((cell) => {
                    if (cell?.type !== TableCellType.SYMBOL || !cell.dataI18N) {
                        return cell
                    }
                    Object.values<Locale>(Locale)
                        .filter((locale) => cell.dataI18N[locale])
                        .forEach((locale) => {
                            const symbolIndices: number[] | undefined = cell.dataI18N[locale]
                                ?.split(',')
                                .reduce((result: number[], symbol: string, index: number) => {
                                    if (TableSymbolType[symbol] === updateSymbol) {
                                        result.push(index)
                                    }
                                    return result
                                }, [])
                            if (symbolIndices && symbolIndices.length > 0) {
                                let data = cell.dataI18N[locale]
                                //if this method is called with newSymbol then it's a legend update
                                //Otherwise it's a legend deletion
                                if (newSymbol) {
                                    symbolIndices.forEach((index) => {
                                        data = replaceCellSymbol(data || '', index, newSymbol)
                                    })
                                } else {
                                    symbolIndices
                                        .reverse()
                                        .forEach(
                                            (index) =>
                                                (data = getCellDataAfterDelete(data || '', index))
                                        )
                                }
                                cell.dataI18N[locale] = data
                            }
                        })
                    return cell
                }),
            })),
        })
    }

    public static hasAtLeastOneHarveyBallLegend(legends: TableLegend[]) {
        const allHarveyBallTypes: string[] = Object.values(TableIconType)
        return legends.some((l) => (l.iconType ? allHarveyBallTypes.includes(l.iconType) : false))
    }

    public static hasAtLeastOneSymbolLegend(legends: TableLegend[]) {
        const allSymbolTypes: string[] = Object.values(TableSymbolType)
        return legends.some((l) => (l.iconType ? allSymbolTypes.includes(l.iconType) : false))
    }

    public getCellTypeDefault(id: string, cellType: TableCellType, locale: Locale) {
        switch (cellType) {
            case TableCellType.AVAILABILITY:
                return {
                    dataI18N: LocalizeDefault<string>('false', locale),
                    type: TableCellType.AVAILABILITY,
                }
            case TableCellType.ICON:
                return {
                    dataI18N: LocalizeDefault<string>('HARVEY_BALL_EMPTY', locale),
                    type: TableCellType.ICON,
                }
            case TableCellType.SYMBOL:
                if (this.getFirstValidSymbolLegend(id, locale)) {
                    return {
                        dataI18N: LocalizeDefault<string>(
                            this.getFirstValidSymbolLegend(id, locale),
                            locale
                        ),
                        type: TableCellType.SYMBOL,
                    }
                } else {
                    return {
                        dataI18N: LocalizeDefault<string>('', locale),
                        type: TableCellType.TEXT,
                    }
                }
            default:
                return {
                    dataI18N: LocalizeDefault<string>('', locale),
                    type: TableCellType.TEXT,
                }
        }
    }
}
