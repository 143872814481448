import React, { useCallback, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Expander } from '@amzn/stencil-react-components/expander'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'

import { FILTER_DATA_IDS } from 'src/components/ModuleListFilter'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import { ManualItemPoolConfig } from 'src/models/dto/item-pools/ItemPoolMetadataDTO'
import { ModuleType } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleMetaData } from 'src/models/SearchResultResponse'
import { ManualItemPoolBuilderFinal } from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ManualItemPoolBuilderFinal'
import { ManualItemPoolBuilderPoolTable } from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ManualItemPoolBuilderPoolTable'
import { SearchResultsPage } from 'src/pages/search/SearchResultsPage'
import { ModuleService } from 'src/services/backend/ModuleService'
import { ItemPoolService } from 'src/services/item-pools/ItemPoolService'

export const NOT_ITEM_POOL_ALLOWED_STATUS: ModuleStatus[] = [
    ModuleStatus.DRAFT_UNVALIDATED,
    ModuleStatus.DRAFT_VALIDATED,
]
const autoDismissTime = 5000
export const getModuleMetaData = async (moduleVersionId: string) => {
    const module = await ModuleService.loadModuleVersionDTO(moduleVersionId)
    return {
        moduleId: module.id,
        moduleVersionId: module.versionId,
        moduleName: module.name,
        owner: module.author,
        moduleType: ModuleType.ItemsContainer,
        status: module.status as ModuleStatus,
        createdAt: module.moduleReviewStatus.createdAt,
        modifiedAt: module.moduleReviewStatus.modifiedAt,
        modifiedBy: module.moduleReviewStatus.modifiedBy,
    } as ModuleMetaData
}

interface ManualItemPoolBuilderProps {
    isExisting: boolean
    poolVersionId?: string
    setSelectItemPoolVersionId?: (poolVersionId: string) => void
}
export const ManualItemPoolBuilder = ({
    isExisting,
    poolVersionId,
    setSelectItemPoolVersionId,
}: ManualItemPoolBuilderProps) => {
    const [searchInfoBanner, setSearchInfoBanner] = useState<boolean>(false)
    const [openSearch, setOpenSearch] = useState<boolean>(false)
    const [itemPoolCreation, setItemPoolCreation] = useState<boolean>(false)
    const [itemPoolVersionId, setItemPoolVersionId] = useState<string>('')
    const [errors, setErrors] = useState<string[]>()
    const addError = (errorMsg: string) => {
        setErrors((prevErrors) => [...(prevErrors ?? []), errorMsg])
    }
    const removeError = (errorMsg: string) => {
        setErrors((prevState) => prevState?.filter((value) => value !== errorMsg))
    }
    const [itemPoolContainers, setItemPoolContainers] = useState<ModuleMetaData[]>([])
    const addContainerToPool = useCallback((itemContainer: ModuleMetaData) => {
        setItemPoolContainers((prevItemPoolContainers) => [
            ...prevItemPoolContainers,
            itemContainer,
        ])
        setOpenSearch(false)
    }, [])

    const removeContainerFromPool = useCallback((itemContainer: ModuleMetaData) => {
        setItemPoolContainers((prevItemPoolContainers) =>
            prevItemPoolContainers.filter(
                (container) => container.moduleVersionId !== itemContainer.moduleVersionId
            )
        )
    }, [])

    const checkInPool = useCallback(
        (containerVersionId: string, containerModuleId: string) => {
            for (const container of itemPoolContainers) {
                if (
                    container.moduleVersionId === containerVersionId ||
                    container.moduleId === containerModuleId
                ) {
                    return true
                }
            }
            return false
        },
        [itemPoolContainers]
    )

    const replaceModule = useCallback(
        (oldVId: string, newVersion: ModuleMetaData) => {
            const newItemPool = itemPoolContainers.map((mod) => {
                if (mod.moduleVersionId === oldVId) {
                    return newVersion
                } else {
                    return mod
                }
            })

            setItemPoolContainers(newItemPool)
        },
        [itemPoolContainers]
    )

    useEffect(() => {
        if (isExisting && poolVersionId) {
            try {
                void (async () => {
                    const itemPool = await ItemPoolService.getItemPoolItems(poolVersionId)
                    if (itemPool) {
                        for (const itemContainer of itemPool) {
                            const moduleMetaData = await getModuleMetaData(itemContainer.versionId)
                            addContainerToPool(moduleMetaData)
                        }
                    }
                })()
            } catch (e) {
                console.error(e)
                addError('Server error when loading item pool containers')
            }
        }
    }, [isExisting, poolVersionId, addContainerToPool])

    const getItemContainerVersionIds = useCallback(() => {
        return itemPoolContainers.map((itemContainer) => {
            return itemContainer.moduleVersionId
        })
    }, [itemPoolContainers])

    const startDraft = useCallback(async () => {
        const itemContainers: string[] = getItemContainerVersionIds()
        const config = {
            itemContainerVersions: itemContainers,
        } as ManualItemPoolConfig
        try {
            const itemPoolResponse = await ItemPoolService.manualItemPool(config)
            setItemPoolVersionId(itemPoolResponse.versionId)
            setItemPoolCreation(true)
            setOpenSearch(false)
        } catch (e: unknown) {
            if ((e as ErrorResponseThrowable)?.errorResponse?.message) {
                // TODO: Change when BackEnd throws custom exception
                const msg = (e as ErrorResponseThrowable)?.errorResponse?.message
                if (msg.includes('Create manual item pool error(s):')) {
                    msg.slice(msg.indexOf('[') + 1, -1)
                        .split('\n')
                        .forEach((error) => {
                            addError(error)
                        })
                }
            } else {
                addError('Server error when creating manual item pool')
                console.error(e)
            }
        }
    }, [getItemContainerVersionIds])

    const addColumn = {
        header: <ScreenReaderOnly>Add action</ScreenReaderOnly>,
        width: '10%',
        cellComponent: ({ data }: { data: ModuleMetaData }) => {
            return (
                <Button
                    variant={ButtonVariant.Secondary}
                    disabled={
                        NOT_ITEM_POOL_ALLOWED_STATUS.includes(
                            ModuleStatus[data.status as keyof typeof ModuleStatus]
                        ) || checkInPool(data.moduleVersionId, data.moduleId)
                    }
                    icon={<IconPlus title='Add module' />}
                    dataTestId={`add-button-${data.moduleVersionId}`}
                    onClick={() => {
                        addContainerToPool(data)
                    }}
                />
            )
        },
    }

    return (
        <Container
            minWidth='1000px'
            width='100%'
            paddingHorizontal={0}
            dataTestId={'manual-item-pool-builder-modal-content'}
        >
            <Col padding='15px'>
                {itemPoolCreation && itemPoolVersionId ? (
                    <ManualItemPoolBuilderFinal
                        poolVersionId={itemPoolVersionId}
                        poolItemContainers={getItemContainerVersionIds()}
                        backClick={() => setItemPoolCreation(false)}
                        setItemPoolVersionId={setSelectItemPoolVersionId}
                    />
                ) : (
                    <Col width={'100%'}>
                        {searchInfoBanner ? (
                            <MessageBanner
                                type={MessageBannerType.Informational}
                                isDismissible={true}
                                autoDismissAfter={autoDismissTime}
                                onDismissed={() => setSearchInfoBanner(false)}
                            >
                                Only Item Containers are shown and only containers in REVIEW status
                                or beyond can be added.
                            </MessageBanner>
                        ) : null}
                        {errors?.map((value, index) => (
                            <MessageBanner
                                key={`errorBanner-${index}`}
                                dataTestId={`item-pool-error-message-banner-${index}`}
                                type={MessageBannerType.Error}
                                isDismissible={true}
                                onDismissed={() => removeError(value)}
                            >
                                {value}
                            </MessageBanner>
                        ))}
                        {itemPoolContainers?.length || 0 > 0 ? (
                            <ManualItemPoolBuilderPoolTable
                                itemPoolResults={itemPoolContainers}
                                removeClick={removeContainerFromPool}
                                updateModule={replaceModule}
                                addError={addError}
                            />
                        ) : null}
                        <Spacer height={'S200'} />
                        <Row maxWidth={'80%'} columnGap={10}>
                            <Button
                                icon={<IconPlus title='Add Item Container' />}
                                variant={ButtonVariant.Secondary}
                                dataTestId={'add-item-container-button'}
                                aria-label={'add item container'}
                                onClick={() => {
                                    setOpenSearch((prevState) => !prevState)
                                    setSearchInfoBanner(true)
                                }}
                            >
                                Add new container
                            </Button>
                            <Button
                                variant={ButtonVariant.Primary}
                                dataTestId={'create-manual-item-pool-button'}
                                aria-label={'create manual item pool'}
                                onClick={startDraft}
                                disabled={itemPoolContainers.length < 1}
                            >
                                Create Item Pool
                            </Button>
                        </Row>
                        <Expander
                            isExpanded={openSearch}
                            onToggle={setOpenSearch}
                            titleText='Search Item Containers'
                        >
                            <SearchResultsPage
                                embeddedInModal={true}
                                disabledFilters={[FILTER_DATA_IDS.MODULE_TYPE_SELECT]}
                                lockedModuleTypes={[ModuleType.ItemsContainer]}
                                selectModuleAction={addColumn}
                                noTypeColumn={true}
                            />
                        </Expander>
                    </Col>
                )}
            </Col>
        </Container>
    )
}
