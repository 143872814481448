export function getMediaType(mimeType: string): string | null {
    if (mimeType.startsWith('image')) {
        return 'image'
    } else if (mimeType.startsWith('video')) {
        return 'video'
    } else if (mimeType.startsWith('audio')) {
        return 'audio'
    }

    return null
}
