import React, { useState } from 'react'

import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { IconAlertCircleFill } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { CompletionCodeItemDTO } from 'src/models/dto/items/CompletionCodeItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorTextInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ITEM_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ItemEntityService'
import { CompletionCodeHandler } from 'src/services/EntityServices/ItemUpdateHandlers/CompletionCodeHandler'
import { MODULE_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export interface CompletionCodeItemEditorProps {
    itemId: string
    locale: Locale
    moduleId: string
    showValidationError?: boolean
    editDisabled?: boolean
}

export const CompletionCodeItemEditor = ({
    itemId,
    moduleId,
    locale,
    showValidationError,
    editDisabled,
}: CompletionCodeItemEditorProps) => {
    const { entity: itemDTO } = useEntity<CompletionCodeItemDTO>({
        entityId: itemId,
        selector: ITEM_ENTITY_STORE_SELECTOR,
        parent: { entityId: moduleId, selector: MODULE_ENTITY_STORE_SELECTOR },
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const invalid = ErrorCheckService.getErrorCount(validationErrorEntity.id) > 0
    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} Mturk Payment Code`

    const { matches } = useBreakpoints()

    return (
        <>
            <Col
                gridGap='S300'
                padding={{ top: 'S300', left: 'S400', right: 'S400', bottom: 'S300' }}
                backgroundColor='#FFFFFF'
            >
                {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                    <MessageBanner type={MessageBannerType.Error}>
                        <ul>
                            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                (message, index) => (
                                    <li key={index}>{message}</li>
                                )
                            )}
                        </ul>
                    </MessageBanner>
                )}
                <Row alignItems='center' gridGap='S200'>
                    <Col rowGap={0}>
                        <Row alignItems='center' gridGap='S200'>
                            <TriggerButton
                                dataTestId={`${
                                    isExpanded ? 'collapse' : 'expand'
                                }-mturk-payment-code-button`}
                                iconAltText={triggerButtonName}
                                aria-label={triggerButtonName}
                                isExpanded={isExpanded}
                                onClick={() => setIsExpanded(!isExpanded)}
                                role={'button'}
                            />
                            <Text
                                fontSize='T400'
                                fontWeight='bold'
                                color={invalid && showValidationError ? 'red70' : 'neutral90'}
                            >
                                Mturk Payment Code
                            </Text>
                        </Row>
                        <Row padding={{ left: 'S200' }}>
                            <Text>
                                The completion code will automatically be appended at the end of
                                this module.
                            </Text>
                        </Row>
                    </Col>
                    {invalid && showValidationError && (
                        <>
                            <Spacer flex={1} />
                            <Col padding={{ right: 'S200' }}>
                                <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                                    <IconAlertCircleFill />
                                    {ErrorCheckService.getErrorCount(validationErrorEntity.id)}{' '}
                                    issues
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
                <ExpanderContent isExpanded={isExpanded}>
                    <Spacer height={'S200'} />
                    <Hr size='wide' color='neutral20' />
                    <Spacer height={'S400'} />
                    <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                        <Col width={matches.s ? '100%' : '400px'}>
                            <ItemEditorTextInput
                                inputId={'unique-item-id'}
                                value={itemDTO.label}
                                placeholder={'Some human readable label'}
                                itemId={itemDTO.id}
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                setValue={(nextValue: string) => {
                                    CompletionCodeHandler.updateLabel(itemDTO.id, nextValue)
                                }}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors.label ?? []
                                ).join(', ')}
                                showError={showValidationError}
                                labelText={'Unique Item ID'}
                            />
                        </Col>
                    </ResponsiveRow>
                    <Spacer height='S200' />
                    <ResponsiveRow gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                        <Col flex='1'>
                            <ItemEditorTextInput
                                inputId={'statement'}
                                dataTestId={`statement-${itemDTO.id}`}
                                value={itemDTO.statementI18N[locale] ?? ''}
                                disabled={editDisabled}
                                itemId={itemDTO.id}
                                setValue={(nextValue: string) => {
                                    CompletionCodeHandler.updateStatement(
                                        itemDTO.id,
                                        locale,
                                        nextValue
                                    )
                                }}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors.statementI18N ?? []
                                ).join(', ')}
                                showError={showValidationError}
                                labelText={'Statement'}
                                placeholder={'Enter the code completion statement'}
                            />
                        </Col>
                    </ResponsiveRow>
                </ExpanderContent>
            </Col>
            <Spacer height={'S400'} />
        </>
    )
}
