import React, { useCallback, useMemo, useReducer, useRef } from 'react'
import { v4 } from 'uuid'

import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { H4 } from '@amzn/stencil-react-components/text'

import { MediaContentsEditorProps } from 'src/components/MediaContentsEditor/MediaContentsEditor'
import { MediaFilesTableV2 } from 'src/components/MediaContentsEditor/MediaFilesTableV2'
import { MediaManagerModal } from 'src/components/MediaContentsEditor/MediaManagerModal'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import {
    defaultMedias,
    MediaManagerMedia,
    MediaTemplateVersionIds,
} from 'src/models/dto/items/MediaDTO'
import { FocusId, focusOnId } from 'src/pages/module-builder/focus'

export const MediaManagerV2 = ({
    localeWiseMediaOpt,
    setLocaleWiseMediaOpt,
    disableMediaExpander = false,
    shouldExpandOnMount = false,
    maxNumberOfMedia,
    locale,
    buttonText,
    buttonVariant,
    lockedFileTypes,
    validationErrorMessage,
    showError,
    tableAriaLabel,
    disabled,
}: MediaContentsEditorProps) => {
    const [tableFocusId, buttonFocusId] = useMemo(() => {
        const suffix = v4()
        return [`MediaManagerV2-Table-${suffix}`, `MediaManagerV2-Button-${suffix}`] as [
            FocusId,
            FocusId
        ]
    }, [])
    const tableRef = useRef<HTMLDivElement | null>(null)

    const [resetKey, increment] = useReducer((x: number) => x + 1, 0)

    const [isExpanded, toggle] = useReducer(
        (x: boolean) => !x,
        shouldExpandOnMount || disableMediaExpander
    )

    const mediaList = localeWiseMediaOpt?.mediaManagerMediaVersionIds || []
    const setMediaList = useCallback(
        (change: (mediaList: MediaManagerMedia[]) => MediaManagerMedia[]) => {
            setLocaleWiseMediaOpt((mediasOpt) => {
                const medias = mediasOpt || defaultMedias()
                return {
                    ...medias,
                    mediaManagerMediaVersionIds: change(medias.mediaManagerMediaVersionIds || []),
                }
            })
        },
        [setLocaleWiseMediaOpt]
    )

    const addMediaEntry = useCallback(
        (media: MediaManagerMedia) => {
            setMediaList((l) => [...l, media])
        },
        [setMediaList]
    )

    const deleteMediaEntry = useCallback(
        (index: number) => {
            setMediaList((l) => {
                const newMediaList = [...l]
                newMediaList.splice(index, 1)
                return newMediaList
            })
            focusOnId(tableFocusId, buttonFocusId)
        },
        [setMediaList, tableFocusId, buttonFocusId]
    )
    const updateMediaTemplateVersionIds = useCallback(
        (index: number, mediaTemplateVersionIds: MediaTemplateVersionIds) => {
            setMediaList((l) => {
                const newMediaList = [...l]
                newMediaList[index] = {
                    ...newMediaList[index],
                    mediaTemplateVersionIds,
                }
                return newMediaList
            })
        },
        [setMediaList]
    )

    const selectMedia = useCallback(
        (versionId: string) => {
            addMediaEntry({
                mediaManagerVersionId: versionId,
                mediaTemplateVersionIds: {},
            } as MediaManagerMedia)
        },
        [addMediaEntry]
    )

    const showAddMedia =
        typeof maxNumberOfMedia === 'number' ? mediaList.length < maxNumberOfMedia : true

    return (
        <Col
            padding={disableMediaExpander ? { bottom: 'S300' } : { top: 'S300', bottom: 'S300' }}
            ref={tableRef}
            dataTestId='media-files-table-container'
        >
            {showError && (validationErrorMessage ?? '').length > 0 && (
                <MessageBanner type={MessageBannerType.Error}>
                    {validationErrorMessage}
                </MessageBanner>
            )}
            <Row alignItems='center' gridGap='S100' style={{ marginLeft: '-8px' }}>
                {!disableMediaExpander && (
                    <>
                        <TriggerButton
                            dataTestId='media-manager-expander'
                            isExpanded={isExpanded}
                            onClick={toggle}
                            iconAltText={'Expand/collapse media'}
                        />
                        <H4 fontSize='T400' fontWeight='bold'>
                            Media files
                        </H4>
                    </>
                )}
            </Row>
            {isExpanded && !disableMediaExpander && <Spacer height='S400' />}
            {isExpanded && (
                <Col gridGap='S300'>
                    <View
                        dataTestId={'media-list-container'}
                        id={mediaList.length ? tableFocusId : undefined}
                        tabIndex={-1}
                        overflow={'auto'}
                    >
                        <MediaFilesTableV2
                            {...{
                                mediaList,
                                addMediaEntry,
                                deleteMediaEntry,
                                updateMediaTemplateVersionIds,
                                tableAriaLabel,
                            }}
                            disabled={disabled}
                        />
                    </View>
                    {showAddMedia && (
                        <ResponsiveRow width='100%' gridGap='S200'>
                            <MediaManagerModal
                                onClose={() => {
                                    increment()
                                    focusOnId(buttonFocusId, tableFocusId)
                                }}
                                key={resetKey}
                                locale={locale}
                                selectMediaVersionId={selectMedia}
                                buttonText={buttonText}
                                disabled={disabled}
                                buttonVariant={buttonVariant}
                                buttonFocusId={buttonFocusId}
                                lockedFileTypes={lockedFileTypes}
                            />
                        </ResponsiveRow>
                    )}
                </Col>
            )}
        </Col>
    )
}
