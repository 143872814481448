import AWS from 'aws-sdk'
import { JsonString } from 'aws-sdk/clients/groundstation'

export class S3Handler {
    private s3BucketName: string

    constructor(s3BucketName: string) {
        this.s3BucketName = s3BucketName
    }

    public uploadJSONFileToS3(fileName: string, file: JsonString) {
        return new AWS.S3()
            .upload({
                Bucket: this.s3BucketName,
                Key: fileName,
                Body: file,
                ContentType: 'application/json',
            })
            .promise()
    }

    public deleteFileFromS3(fileName: string) {
        return new AWS.S3()
            .deleteObject({
                Bucket: this.s3BucketName,
                Key: fileName,
            })
            .promise()
    }

    async getFileNames() {
        return await new AWS.S3()
            .listObjectsV2({
                Bucket: this.s3BucketName,
            })
            .promise()
    }

    async loadFileFromS3(fileName: string) {
        return await new AWS.S3()
            .getObject({
                Bucket: this.s3BucketName,
                Key: fileName,
            })
            .promise()
    }
}
