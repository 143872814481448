import { applyChange, Change } from 'src/hooks/DTOEditor'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import {
    AvatarDTO,
    defaultVirtualMeetingDTO,
    VirtualMeetingDTO,
} from 'src/models/dto/items/VirtualMeetingDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import {
    factories,
    ITEM_ENTITY_STORE_SELECTOR,
} from 'src/services/EntityServices/ItemEntityService'
import { Store, STORE_ACTION, Stores } from 'src/services/Store'

const itemType = ItemType.VirtualMeeting

export class VirtualMeetingHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => this.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): VirtualMeetingDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as VirtualMeetingDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): VirtualMeetingDTO {
        return defaultVirtualMeetingDTO()
    }

    private static update(entity: VirtualMeetingDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as VirtualMeetingDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as VirtualMeetingDTO)
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.titleI18N[locale] = nextValue
        this.update(entity)
    }

    static deleteAvatar(id: string, index: number) {
        const entity = this.getEntity(id)
        entity.avatars.splice(index, 1)
        this.update(entity)
    }

    static updateAvatarFirstName(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        const entity = this.getEntity(id)
        entity.avatars[avatarIndex].firstNameI18N[locale] = nextValue
        this.update(entity)
    }

    static updateAvatarLastName(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        const entity = this.getEntity(id)
        entity.avatars[avatarIndex].lastNameI18N[locale] = nextValue
        this.update(entity)
    }

    static updateAvatarInfoLine1(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        const entity = this.getEntity(id)
        entity.avatars[avatarIndex].informationLine1I18N[locale] = nextValue
        this.update(entity)
    }

    static updateAvatarInfoLine2(
        id: string,
        locale: Locale,
        nextValue: string,
        avatarIndex: number
    ) {
        const entity = this.getEntity(id)
        entity.avatars[avatarIndex].informationLine2I18N[locale] = nextValue
        this.update(entity)
    }

    static addAvatar(id: string) {
        const entity = this.getEntity(id)
        entity.avatars.push({
            firstNameI18N: LocalizeDefault<string>(''),
            lastNameI18N: LocalizeDefault<string>(''),
            informationLine1I18N: LocalizeDefault<string>(''),
            informationLine2I18N: LocalizeDefault<string>(''),
        } as AvatarDTO)
        this.update(entity)
    }

    static updateAvatarLocaleWiseMediaOpt(
        id: string,
        change: Change<Medias | undefined>,
        avatarIndex: number
    ) {
        const entity = this.getEntity(id)
        const { localeWiseMedia } = entity.avatars[avatarIndex]
        entity.avatars[avatarIndex].localeWiseMedia = applyChange(localeWiseMedia, change)
        this.update(entity)
    }

    static updatePlaybackLimit(id: string, nextValue: number | null) {
        const entity = this.getEntity(id)
        if (nextValue === null) {
            entity.playbackLimit = undefined
        } else {
            entity.playbackLimit = nextValue
        }
        this.update(entity)
    }
}
