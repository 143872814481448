import React from 'react'

import { useEntity } from 'src/hooks/useEntity'
import { useUpdateRowLayoutDTO } from 'src/hooks/useUpdateRowLayoutDTO'
import { TextBlockRowDTO } from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorTextArea } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { VALIDATION_ERROR_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ValidationErrorEntityService'

export interface TextBlockRowEditorProps {
    textBlockRow: TextBlockRowDTO
    itemId: string
    workflowEntityId: string
    rowIndex: number
    showValidationError?: boolean
    locale: Locale
    editDisabled?: boolean
}

export const TextBlockRowEditor = (props: TextBlockRowEditorProps) => {
    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const updateRow = useUpdateRowLayoutDTO<TextBlockRowDTO>(props.itemId, props.rowIndex)

    return (
        <ItemEditorTextArea
            inputId={'text'}
            locale={props.locale}
            value={props.textBlockRow.textI18N[props.locale] ?? ''}
            itemId={props.itemId}
            disabled={props.editDisabled}
            setValue={(next: string) => {
                updateRow((r) => ({
                    ...r,
                    textI18N: {
                        ...r.textI18N,
                        [props.locale]: next,
                    } as LocalizedAttribute<string>,
                }))
            }}
            validationErrorMessage={(
                validationErrorEntity.validationErrors[`rows[${props.rowIndex}].textI18N`] ?? []
            ).join(', ')}
            showError={props.showValidationError}
            labelText={'Text'}
        />
    )
}
