import { DependencyList, useCallback, useState } from 'react'

import { useAsync } from '@amzn/stencil-react-components/hooks'

export interface UseAsyncAction {
    // True if the async action has been invoked and is loading
    isLoading: boolean
    // Invoke the async action, will not create additional invocations if isLoading is true
    invoke: () => void
    // Errors while running the async action, if applicable
    error?: Error | null
}
/**
 * Hook for a callable async action
 * @param func The async function for the action
 * @param deps The hook dependencies for the async function
 * @return { invoke, isLoading, error }
 */
export function useAsyncAction(func: () => Promise<void>, deps: DependencyList): UseAsyncAction {
    const [active, setActive] = useState(false)
    const [running, setRunning] = useState(false)
    const shouldStart = active && !running

    const { data: _data, ...ret } = useAsync(async () => {
        if (shouldStart) {
            try {
                setRunning(true)
                setActive(false)
                await func()
            } finally {
                setActive(false)
                setRunning(false)
            }
        }
    }, [shouldStart, ...deps])

    const invoke = useCallback(() => {
        setActive(true)
    }, [])
    return { invoke, ...ret, isLoading: ret.isLoading && shouldStart }
}
