import React from 'react'
import styled from '@emotion/styled'

import { View } from '@amzn/stencil-react-components/layout'

import { TableSymbolType } from 'src/models/dto/items/BaseTableDTO'
import { SYMBOL_MAPPING } from 'src/pages/module-builder/item-editors/table-editor/TableEditor'

const SymbolIconContainer = styled('div')({
    width: 20,
    height: 22,
    marginTop: 1,
})

export const SymbolIcon = ({ symbol }: { symbol: TableSymbolType }) => (
    <SymbolIconContainer aria-label={symbol}>
        <View
            style={{
                transform: `rotate(${SYMBOL_MAPPING.get(symbol)?.rotate || 0}deg)`,
            }}
        >
            {SYMBOL_MAPPING.get(symbol)?.element}
        </View>
    </SymbolIconContainer>
)
