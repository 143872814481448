import { FreeTextDisplayType } from 'src/models/dto/items/FreeTextItemDTO'
import { LocalizedAttribute, LocalizeDefault } from '../../../models/dto/Locale'
import { factories, item, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { FreeTextItemDTO } from './../../../models/dto/items/FreeTextItemDTO'
import { ItemDTO, ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.FreeText

export class FreetextHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => FreetextHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): FreeTextItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as FreeTextItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): FreeTextItemDTO {
        return {
            ...item(),
            itemType,
            freeTextDisplayType: FreeTextDisplayType.SingleLine,
            characterLimit: 50,
            questionI18N: LocalizeDefault<string>(''),
            ppt: 'FreeText',
        }
    }

    private static update(entity: FreeTextItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateQuestionI18N(id: string, questionI18N: LocalizedAttribute<string>) {
        const entity = this.getEntity(id)
        const payload = {
            ...entity,
            questionI18N,
        }
        this.update(payload)
    }

    static updateCharacterLimit(id: string, characterLimit: number) {
        const entity = this.getEntity(id)
        const payload = {
            ...entity,
            characterLimit,
        }
        this.update(payload)
    }

    static updateFreeTextDisplayType(id: string, freeTextDisplayType: FreeTextDisplayType) {
        const entity = this.getEntity(id)

        const payload = {
            ...entity,
            freeTextDisplayType,
        }

        this.update(payload)
    }
}
