import React from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col } from '@amzn/stencil-react-components/layout'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { Span, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'

interface ErrorModalProps {
    title: string
    close: () => void
    errorCount?: number
}

export function PreviewErrorModal({ close, title, errorCount }: ErrorModalProps) {
    if (!errorCount) {
        return null
    }

    return (
        <Modal dataTestId={'preview-error-modal'} close={close} isOpen={true}>
            <ModalContent
                titleText={title}
                buttons={[
                    <Button
                        key='close-error-modal'
                        dataTestId='close-error-modal'
                        onClick={close}
                        variant={ButtonVariant.Primary}
                    >
                        Okay
                    </Button>,
                ]}
            >
                <Col gridGap='S300'>
                    <Text>
                        We found{' '}
                        <Span color={'red70'} fontWeight={'bold'}>
                            {errorCount} validation errors
                        </Span>
                        . All error must be resolved before you can preview.
                    </Text>
                    <Text>Please resolve the marked errors and try again.</Text>
                </Col>
            </ModalContent>
        </Modal>
    )
}

export function ValidationErrorModal({ close, title, errorCount }: ErrorModalProps) {
    if (!errorCount) {
        return null
    }

    return (
        <Modal dataTestId={'validation-error-modal'} close={close} isOpen={true}>
            <ModalContent
                titleText={title}
                buttons={[
                    <Button
                        key='close-error-modal'
                        dataTestId='close-error-modal'
                        onClick={close}
                        variant={ButtonVariant.Primary}
                    >
                        Okay
                    </Button>,
                ]}
            >
                <Col gridGap='S300'>
                    <Text>
                        We found{' '}
                        <Span color={'red70'} fontWeight={'bold'}>
                            {errorCount} validation errors
                        </Span>{' '}
                        in this module.
                    </Text>
                    <Text>
                        Please review and resolve the marked errors. All errors must be addressed
                        before modules can be published.
                    </Text>
                </Col>
            </ModalContent>
        </Modal>
    )
}

export function RemoveConditionalFlagModal({
    close,
    title,
    removeConditional,
}: ErrorModalProps & { removeConditional: boolean }) {
    if (!removeConditional) {
        return null
    }

    return (
        <Modal dataTestId={'remove-conditional-modal'} close={close} isOpen={true}>
            <ModalContent
                titleText={title}
                buttons={[
                    <Button
                        key='close-remove-conditional-modal'
                        dataTestId='close-remove-conditional-modal'
                        onClick={close}
                        variant={ButtonVariant.Primary}
                    >
                        Okay
                    </Button>,
                ]}
            >
                <Col gridGap='S300'>
                    <Text>
                        You have moved a conditional page to either the first or last page of the
                        module workflow, which is not allowed. The page conditional flag will be
                        removed; to make this page conditional, move it to a position that is valid.
                    </Text>
                </Col>
            </ModalContent>
        </Modal>
    )
}
