import { CancelToken } from 'axios'

import { APP_CONFIG } from 'src/config.app'
import { MUPPItemBankDTO } from 'src/models/dto/activities/MUPPItemBankDTO'
import { ItemBankDTO, ItemBankMetadata } from 'src/models/dto/mupp/MUPPItemBankMetadataDTO'
import { ADAxios } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

export interface ItemBankSubmissionRequest {
    itemBankDTO: MUPPItemBankDTO
    author: string
}

export class MUPPItemBankService {
    static readonly getItemBanksURL = `${APP_CONFIG.backendAPIV1BaseUrl}/item-bank`

    static async submitItemBank(submission: ItemBankSubmissionRequest, cancelToken: CancelToken) {
        try {
            const { data }: { data: { versionId: string } } = await ADAxios.post(
                this.getItemBanksURL + '/submit',
                submission,
                { cancelToken }
            )
            return data.versionId
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async getItemBankList(cancelToken: CancelToken) {
        try {
            const { data }: { data: { itemBanks: ItemBankMetadata[] } } = await ADAxios.get(
                this.getItemBanksURL,
                { cancelToken }
            )
            return data.itemBanks ? data.itemBanks : ([] as ItemBankMetadata[])
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    static async getItemBankDTO(versionId: string, cancelToken: CancelToken) {
        try {
            const { data }: { data: { itemBank: ItemBankDTO } } = await ADAxios.get(
                this.getItemBanksURL + '/' + versionId,
                { cancelToken }
            )

            return data.itemBank
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }
}
