// Module Builder focus management

/**
 * A unique ID for focusing on elements. This type is written in a way so that string is not assignable to FocusId.
 */
export type FocusId = string & { readonly __tag: unique symbol }

/**
 * Generate a unique ID for focusing on instructional contents.
 * @param itemNumber instructionItemNumber
 */
export function instructionalContentFocusId(itemNumber: number | string) {
    return `FOCUS-instructional-content-${itemNumber}` as FocusId
}

/**
 * Generate a unique ID for focusing on pages.
 * @param page pageIndex + 1
 */
export function pageFocusId(page: number | string) {
    return `FOCUS-page-${page}` as FocusId
}

/**
 * Generate a unique ID for focusing on items within a page.
 * @param page pageIndex + 1
 * @param item itemIndex + 1
 */
export function itemFocusId(page: number | string, item: number | string) {
    return `FOCUS-page-${page}-item-${item}` as FocusId
}

/**
 * Generate a unique ID for focusing on end of page.
 * @param page pageIndex + 1
 */
export function endOfPageFocusTargetId(page: number | string) {
    return `FOCUS-page-${page}-end-of-page` as FocusId
}

const MOVE_UP_ID_SUFFIX = '-MOVE-UP'
const MOVE_DOWN_ID_SUFFIX = '-MOVE-DOWN'

/**
 * Derive a unique ID for focusing on the move up button.
 */
export function moveUpFocusId(focusId: FocusId) {
    return (focusId + MOVE_UP_ID_SUFFIX) as FocusId
}

/**
 * Derive a unique ID for focusing on the move down button.
 */
export function moveDownFocusId(focusId: FocusId) {
    return (focusId + MOVE_DOWN_ID_SUFFIX) as FocusId
}

/// Focus ID for the End of Module "Add new page" button
export const END_OF_MODULE_FOCUS_ID = 'FOCUS-end-of-module' as FocusId

/**
 * Focus and scroll-into-view on a newly-created element with the specified element id.
 * @param id The `id` attribute of the newly-created element.
 * @param idSecondary The `id` attribute of the element to focus instead if not found.
 * @param scroll Scroll into view after focusing, default true.
 */
export function focusOnId(id: FocusId | string, idSecondary?: FocusId | string, scroll = true) {
    requestAnimationFrame(() => {
        let el = document.getElementById(id)
        if (!el || el.hasAttribute('disabled')) {
            console.log('focusOnId: element not found or is disabled', { id, idSecondary }, el)
            el = null
            if (idSecondary) {
                el = document.getElementById(idSecondary)
            }
        }
        console.log('focusOnId', { id, idSecondary }, el?.tagName)
        el?.focus()
        if (scroll) {
            el?.scrollIntoView?.()
        }
    })
}

/**
 * Focus on the header in a table that was clicked when sorting
 * @param scroll Scroll into view after focusing, defaults true.
 */
export function focusOnSortedHeader(scroll = true) {
    requestAnimationFrame(() => {
        let el = document.querySelector('[aria-sort="ascending"]') as HTMLElement
        if (!el || el.hasAttribute('disabled')) {
            console.log('No headers sorted in ascending order, checking descending')
            el = document.querySelector('[aria-sort="descending"]') as HTMLElement
        }
        console.log('focused on a sorting header', el?.tagName)
        el?.focus()
        if (scroll) {
            el?.scrollIntoView?.()
        }
    })
}

/**
 * Focus on the page in the pagination list that was clicked when swapping pages
 * @param id The `data-test-id` attribute of the newly-created element.
 * @param idSecondary The `data-test-id` attribute of the element to focus instead if not found.
 * @param scroll Scroll into view after focusing, defaults true.
 */
export function focusOnDataTestId(id: string, idSecondary?: string, scroll = true) {
    requestAnimationFrame(() => {
        let el = document.querySelector(`[data-test-id="${id}"]`) as HTMLElement
        if (idSecondary && (!el || el.hasAttribute('disabled'))) {
            console.log('focusOnId: element not found or is disabled', { id, idSecondary }, el)
            el = document.querySelector(`[data-test-id="${idSecondary}"]`) as HTMLElement
        }
        console.log('focusOnId', { id, idSecondary }, el?.tagName)
        el?.focus()
        if (scroll) {
            el?.scrollIntoView?.()
        }
    })
}
