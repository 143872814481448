import { v4 } from 'uuid'

import { InstructionalContentEntity } from 'src/models/dto/InstructionalContentDTO'
import { Store, STORE_ACTION } from 'src/services/Store'

export const INSTRUCTIONAL_CONTENT_ENTITY_STORE_SELECTOR = 'InstructionalContentEntity'

export class InstructionalContentEntityService {
    static store: Store<InstructionalContentEntity>
    static init() {
        this.store = new Store<InstructionalContentEntity>(
            INSTRUCTIONAL_CONTENT_ENTITY_STORE_SELECTOR
        )
    }

    static get(entityId: string): InstructionalContentEntity {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static create(): InstructionalContentEntity {
        const newId = v4()
        const entity: InstructionalContentEntity = {
            id: newId,
            name: newId,
            itemIds: [] as string[],
        }

        this.insert(entity)
        return entity
    }

    static insert(entity: InstructionalContentEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static preformAction(entity: InstructionalContentEntity, action: STORE_ACTION) {
        this.store.dispatch({
            action: action,
            entityId: entity.id,
            payload: entity,
        })
    }

    // private because we want to encourage usage of specific update methods
    private static update(entity: InstructionalContentEntity) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateItems(entityId: string, itemIds: string[]) {
        const entity = this.store.get(entityId)

        const payload: InstructionalContentEntity = {
            ...entity,
            itemIds,
        }

        this.update(payload)
    }

    static addItem(entityId: string, itemVersionId: string) {
        const entity = this.store.get(entityId)
        this.updateItems(entityId, [...entity.itemIds, itemVersionId])
    }

    static removeItem(entityId: string, itemVersionId: string) {
        const entity = this.store.get(entityId)
        this.updateItems(
            entityId,
            entity.itemIds.filter((id) => id !== itemVersionId)
        )
    }

    private static moveItemId(itemIds: string[], itemId: string, toIndex: number) {
        const next = itemIds.filter((i) => i !== itemId)

        return [...next.slice(0, toIndex), itemId, ...next.slice(toIndex)]
    }

    /**
     * Moves an instruction entity id up
     * @param instructionId
     * @param itemEntityId
     */
    public static moveUp(instructionId: string, itemEntityId: string) {
        const instructionalContentEntity: InstructionalContentEntity = this.get(instructionId)

        const currentIndex = instructionalContentEntity.itemIds.indexOf(itemEntityId)

        if (currentIndex > 0) {
            this.update({
                ...instructionalContentEntity,
                itemIds: this.moveItemId(
                    instructionalContentEntity.itemIds,
                    itemEntityId,
                    currentIndex - 1
                ),
            })
        }
    }

    public static moveDown(instructionId: string, itemEntityId: string) {
        const instructionalContentEntity: InstructionalContentEntity = this.store.get(instructionId)

        const currentIndex = instructionalContentEntity.itemIds.indexOf(itemEntityId)

        if (currentIndex < instructionalContentEntity.itemIds.length - 1) {
            this.update({
                ...instructionalContentEntity,
                itemIds: this.moveItemId(
                    instructionalContentEntity.itemIds,
                    itemEntityId,
                    currentIndex + 1
                ),
            })
        }
    }
}
