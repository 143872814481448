import {
    CompletionCodeItemDTO,
    defaultCompletionCodeItemDTO,
} from 'src/models/dto/items/CompletionCodeItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.CompletionCode

export class CompletionCodeHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => CompletionCodeHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): CompletionCodeItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as CompletionCodeItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): CompletionCodeItemDTO {
        return defaultCompletionCodeItemDTO()
    }

    private static update(entity: CompletionCodeItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as CompletionCodeItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as CompletionCodeItemDTO)
    }

    static updateStatement(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.statementI18N[locale] = nextValue
        this.update(entity)
    }
}
