import React, { useEffect, useState } from 'react'

import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import {
    BUSINESS_PURPOSES,
    HIRE_TYPES,
    JOB_LEVELS,
    JobMetadataResponse,
    ROLE_KINDS,
} from 'src/models/dto/module-groups/JobMetadataResponse'
import { PECValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import { RuleSubType, RuleType } from 'src/models/dto/module-groups/PECGroupDTO'
import { Countries } from 'src/pages/scoring-test-ui/Countries'
import { JobMetadataService } from 'src/services/module-groups/JobMetadataService'

export interface JobMetadataSelectProps {
    ruleType: RuleType
    ruleSubType: RuleSubType
    setValues: (ruleType: RuleType, values: string[]) => void
    setRuleSubType: (ruleType: RuleType, subType: RuleSubType) => void
    editSelectedValues: string[]
    pecValidations?: PECValidationDTO
    setPecValidations?: (pecValidations: PECValidationDTO) => void
}

const JOB_METADATA_QUERY_MAX = 25

const renderSelectedValue = (selectedValue: JobMetadataResponse) => {
    return <Text fontSize='S100'>{selectedValue.value}</Text>
}

/**
 * TODO right now hook has a hardcoded list of countries which we are forced to use too
 * to avoid any issues when hook is evaluating a PEC rule.
 * Once hook switches to jobs API call to get the countries we can just integrate
 * The endpoint call to our jobMetadata API, and remove that list
 */
const formattedCountries = Countries.map((country) => {
    return {
        value: country.alpha3Code,
        description: country.name,
        category: RuleType.JOB_COUNTRY_CODE,
    } as JobMetadataResponse
})

const staticJobMetadataSet = new Set<RuleType>([
    RuleType.JOB_LEVEL,
    RuleType.JOB_HIRE_TYPE_ID,
    RuleType.JOB_BUSINESS_PURPOSE,
    RuleType.JOB_ROLE_KIND,
])

const queryableJobMetadataSet = new Set<RuleType>([
    RuleType.JOB_FAMILY,
    RuleType.JOB_CODE,
    RuleType.JOB_DEPARTMENT_ID,
    RuleType.JOB_EMPLOYEE_CLASS_ID,
])

const renderOperatorOption = (option: RuleSubType) => {
    return option === RuleSubType.INCLUDE ? 'IS' : 'IS NOT'
}
const OperatorSelector = ({
    ruleType,
    ruleSubType,
    setRuleSubType,
}: {
    ruleType: RuleType
    ruleSubType: RuleSubType
    setRuleSubType: (ruleType: RuleType, subType: RuleSubType) => void
}) => {
    return (
        <Col flex='60px 0.1 1'>
            <InputWrapper
                id={`select-${ruleType}-subtype-wrapper`}
                labelText={'Operator'}
                required={true}
            >
                {(inputProps) => (
                    <Select
                        dataTestId={`${ruleType}-subtype-select`}
                        {...inputProps}
                        renderOption={renderOperatorOption}
                        options={[RuleSubType.INCLUDE, RuleSubType.EXCLUDE]}
                        value={ruleSubType}
                        listPositioningStrategy='fixed'
                        placeholder={''}
                        onChange={(value: RuleSubType) => {
                            setRuleSubType(ruleType, value)
                        }}
                    />
                )}
            </InputWrapper>
        </Col>
    )
}

export const JobMetadataSelect = (props: JobMetadataSelectProps) => {
    const [metadataOptions, setMetadataOptions] = useState<JobMetadataResponse[]>([])
    const [queryJobMetadataError, setQueryJobMetadataError] = useState<boolean>(false)
    const [searchString, setSearchString] = useState<string>('')
    const [options, setOptions] = useState<JobMetadataResponse[] | string[]>([])
    const [handleEditValues, setHandleEditValues] = useState<string[] | JobMetadataResponse[]>([])

    const isStaticJobMetadata = staticJobMetadataSet.has(props.ruleType)
    const isQueryableJobMetadata = queryableJobMetadataSet.has(props.ruleType)

    useEffect(() => {
        if (props.ruleType === RuleType.JOB_LEVEL) {
            setHandleEditValues(props.editSelectedValues)
        } else {
            setHandleEditValues(
                props.editSelectedValues.map((value) => {
                    return {
                        category: props.ruleType,
                        value: value,
                        description: '',
                    } as JobMetadataResponse
                })
            )
        }
    }, [props.editSelectedValues, props.ruleType])

    useEffect(() => {
        const queryJobMetadata = async () => {
            try {
                if (isQueryableJobMetadata) {
                    setMetadataOptions(
                        await JobMetadataService.getJobMetadata(
                            props.ruleType,
                            JOB_METADATA_QUERY_MAX,
                            searchString
                        )
                    )
                } else if (props.ruleType === RuleType.JOB_COUNTRY_CODE) {
                    const filteredCountries = formattedCountries.filter(
                        (country) =>
                            country.description
                                .toLowerCase()
                                .includes(searchString.toLowerCase()) ||
                            country.value.toLowerCase().includes(searchString.toLowerCase())
                    )
                    setMetadataOptions(filteredCountries)
                }
            } catch (e) {
                console.error(e)
                setQueryJobMetadataError(true)
            }
        }
        void queryJobMetadata()
    }, [isQueryableJobMetadata, props.ruleType, searchString])

    const renderOption = (option: JobMetadataResponse) => (
        <Col>
            <Row gridGap='S200'>
                <Text fontSize='S100' fontWeight='bold'>{`${option.category as RuleType}:`}</Text>
                <Text fontSize='S100'>{option.value}</Text>
            </Row>
            <Row gridGap='S200'>
                <Text fontSize='S100' fontWeight='bold'>
                    Description:
                </Text>
                <Text fontSize='S100'>{option.description}</Text>
            </Row>
        </Col>
    )

    const renderStaticOption = (option: JobMetadataResponse) => (
        <Text fontSize='S100'>{option.description}</Text>
    )

    const footerMessage = () => {
        if (queryJobMetadataError) {
            return 'Something went wrong while getting job information'
        } else if (props.pecValidations?.categories?.get(props.ruleType)) {
            return props.pecValidations.categories.get(props.ruleType)
        } else {
            return undefined
        }
    }

    useEffect(() => {
        switch (props.ruleType) {
            case RuleType.JOB_LEVEL:
                setOptions(JOB_LEVELS.map((level) => level.toString()))
                break
            case RuleType.JOB_HIRE_TYPE_ID:
                setOptions(HIRE_TYPES)
                break
            case RuleType.JOB_BUSINESS_PURPOSE:
                setOptions(BUSINESS_PURPOSES)
                break
            case RuleType.JOB_ROLE_KIND:
                setOptions(ROLE_KINDS)
                break
            default:
                setOptions(metadataOptions)
        }
    }, [metadataOptions, props.ruleType])

    return (
        <Row gridGap='S300' width='100%'>
            <OperatorSelector
                ruleType={props.ruleType}
                ruleSubType={props.ruleSubType}
                setRuleSubType={props.setRuleSubType}
            />

            <Col flex='1 1 0'>
                <InputWrapper
                    id={`select-${props.ruleType}-wrapper`}
                    labelText={`${props.ruleType}`}
                    required={true}
                    error={
                        queryJobMetadataError ||
                        !!props.pecValidations?.categories?.get(props.ruleType)
                    }
                    footer={footerMessage()}
                >
                    {(inputProps) => (
                        <Select
                            dataTestId={`${props.ruleType}-select`}
                            {...inputProps}
                            renderSelectedValue={
                                props.ruleType === RuleType.JOB_LEVEL
                                    ? undefined
                                    : renderSelectedValue
                            }
                            options={options}
                            value={handleEditValues}
                            isMulti={true}
                            isSearchable={!isStaticJobMetadata}
                            renderOption={
                                props.ruleType === RuleType.JOB_LEVEL
                                    ? undefined
                                    : isStaticJobMetadata
                                    ? renderStaticOption
                                    : renderOption
                            }
                            listPositioningStrategy='fixed'
                            onChange={(values: string[] | JobMetadataResponse[]) => {
                                const transformedValues: string[] = values.map((option) =>
                                    typeof option === 'string'
                                        ? option
                                        : (option as JobMetadataResponse).value
                                )

                                if (
                                    props.setPecValidations &&
                                    props.pecValidations?.categories?.get(props.ruleType)
                                ) {
                                    const categoriesMap = props.pecValidations?.categories
                                    categoriesMap.set(props.ruleType, '')

                                    props.setPecValidations({
                                        ...props.pecValidations,
                                        categories: categoriesMap,
                                    } as PECValidationDTO)
                                }

                                props.setValues(props.ruleType, transformedValues)
                            }}
                            filterOptions={(query: string) => {
                                if (!isStaticJobMetadata) {
                                    setSearchString(query)
                                    return metadataOptions
                                }

                                return options
                            }}
                        />
                    )}
                </InputWrapper>
            </Col>
        </Row>
    )
}
