import React, { ReactNode } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { IconSearch } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, View } from '@amzn/stencil-react-components/layout'
import { H2 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/pages/search/SearchResultsPage'

export interface ModuleSearchMastheadProps {
    searchTerm: string
    onSearchTermChange: (searchTerm: string) => void
    onHitSearch: () => void
}

const customRenderLabel = ({
    htmlFor,
    children,
}: {
    htmlFor: string
    labelId?: string | undefined
    color: string
    children: ReactNode
}) => (
    <label htmlFor={htmlFor}>
        <H2 color='neutral0' fontSize='T200'>
            {children}
        </H2>
    </label>
)

export const HomeMasthead = (props: ModuleSearchMastheadProps) => {
    const layout = useLayoutDirection()

    return (
        <View
            margin={{ top: '2px' }}
            height={layout === LAYOUT_DIRECTION.MOBILE ? '187px' : '230px'}
        >
            <Flex
                flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                gridGap={16}
                justifyContent={'center'}
                width='100%'
                padding={
                    layout === LAYOUT_DIRECTION.MOBILE
                        ? { left: '20px', right: '20px', top: '20px' }
                        : { top: '60px' }
                }
            >
                <Row
                    justifyContent={'center'}
                    width={layout === LAYOUT_DIRECTION.MOBILE ? '100%' : '60%'}
                >
                    <Col width={'100%'}>
                        <InputWrapper
                            id='module-search-input'
                            labelText='Module Search'
                            renderLabel={customRenderLabel}
                        >
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    data-test-id='module-search-input'
                                    name='Module Search Input'
                                    placeholder='Enter a module name or ID...'
                                    onChange={(e) => props.onSearchTermChange(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') props.onHitSearch()
                                    }}
                                    insetElementLeading={{
                                        element: (
                                            <IconSearch aria-hidden={true} color='neutral60' />
                                        ),
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </Row>
                <Row
                    margin={
                        layout === LAYOUT_DIRECTION.MOBILE ? '0' : { top: '30px', left: '20px' }
                    }
                >
                    <Col width='100%'>
                        <Button
                            id='search-button'
                            data-test-id='search-button'
                            variant={ButtonVariant.Primary}
                            onClick={() => {
                                props.onHitSearch()
                            }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Flex>
        </View>
    )
}
