import React from 'react'

import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { HarveyBallValue2 } from 'src/components/CustomIcons/HarveyBall'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { Markdown } from 'src/components/Markdown'
import { SymbolIcon } from 'src/components/SymbolIcon'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    TableCell,
    TableCellType,
    TableIconType,
    TableSymbolType,
} from 'src/models/dto/items/BaseTableDTO'
import { TableItemDTO } from 'src/models/dto/items/TableItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { getHarveyBallLegend } from 'src/pages/module-builder/item-editors/table-editor/HarveyBallLegend'
import { getSymbolLegends } from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import { CellEditor } from 'src/pages/module-builder/item-editors/table-editor/TableCellEditor'
import {
    TableEditor,
    TableScaleHarveyBallScaleType,
    ViewCellComponentProps,
} from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { TableHandler } from 'src/services/EntityServices/ItemUpdateHandlers/TableHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import { ItemEditorProps } from './ItemEditorContainer'
import { ItemEditorCheckboxInput, ItemEditorTextInput } from './ItemEditorInputs'

const HARVEY_BALL_SCALE_MAPPINGS = new Map<string, TableScaleHarveyBallScaleType>([
    [TableIconType.HARVEY_BALL_EMPTY, TableScaleHarveyBallScaleType.HarveyBallEmpty],
    [TableIconType.HARVEY_BALL_HALF_FULL, TableScaleHarveyBallScaleType.HarveyBallHalfFull],
    [TableIconType.HARVEY_BALL_FULL, TableScaleHarveyBallScaleType.HarveyBallFull],
])

const subTypeLabelTexts = (subType: TableScaleHarveyBallScaleType) => {
    switch (subType) {
        case TableScaleHarveyBallScaleType.HarveyBallEmpty:
            return 'Harvey ball empty'
        case TableScaleHarveyBallScaleType.HarveyBallHalfFull:
            return 'Harvey ball half full'
        case TableScaleHarveyBallScaleType.HarveyBallFull:
            return 'Harvey ball full'
        default:
            return 'Text Only'
    }
}

export function TableItemCellView({
    cellData,
    locale,
    symbolLegends,
}: ViewCellComponentProps<TableCell> & { locale: Locale }) {
    switch (cellData.type) {
        case TableCellType.TEXT:
            return (
                <Row
                    alignItems='center'
                    gridGap='S200'
                    padding={{ top: 'S300', bottom: 'S300' }}
                    height='100%'
                    maxWidth={'420px'}
                >
                    <Text textAlign='left' tabIndex={0}>
                        <Markdown markdown={cellData.dataI18N[locale] ?? ''} />
                    </Text>
                    <ScreenReaderOnly>
                        {(cellData.dataI18N[locale] ?? '').length === 0 ? ' (Blank content)' : ''}
                    </ScreenReaderOnly>
                </Row>
            )
        case TableCellType.AVAILABILITY: {
            const cell = cellData.dataI18N[locale]
            const description =
                typeof cell === 'undefined'
                    ? 'Blank content'
                    : cell === 'true'
                    ? 'Shaded white'
                    : 'Shaded grey'
            return (
                <Row
                    alignItems='center'
                    gridGap='S200'
                    padding={{ top: 'S200', bottom: 'S200' }}
                    height='100%'
                    dataTestId={`shaded-${cell ?? ''}`}
                >
                    <View
                        minWidth={64}
                        minHeight={32}
                        height='100%'
                        backgroundColor={cell === 'true' ? 'white' : 'grey'}
                    >
                        <span style={{ height: '1em' }}>&nbsp;</span>
                        <ScreenReaderOnly>({description})</ScreenReaderOnly>
                    </View>
                </Row>
            )
        }
        case TableCellType.ICON: {
            const harveyBallType = HARVEY_BALL_SCALE_MAPPINGS.get(
                cellData.dataI18N[locale] || TableScaleHarveyBallScaleType.HarveyBallEmpty
            ) as TableScaleHarveyBallScaleType

            return (
                <Row
                    alignItems='center'
                    gridGap='S200'
                    padding={{ top: 'S300', bottom: 'S300' }}
                    height='100%'
                >
                    <HarveyBallValue2 type={harveyBallType} />
                    <ScreenReaderOnly>{`${subTypeLabelTexts(harveyBallType)},`}</ScreenReaderOnly>
                </Row>
            )
        }
        case TableCellType.SYMBOL: {
            const cellSymbolList: string[] = cellData.dataI18N[locale]?.split(',') || []
            return (
                <View height='100%'>
                    <Row
                        alignItems='center'
                        gridGap='S200'
                        padding={{ top: 'S300', bottom: 'S300' }}
                        aria-hidden
                    >
                        {cellSymbolList.map((symbol: string, index) => (
                            <SymbolIcon
                                key={index}
                                symbol={TableSymbolType[symbol] as TableSymbolType}
                            />
                        ))}
                    </Row>
                    <ScreenReaderOnly>
                        {cellSymbolList
                            .map((symbol: string) =>
                                symbolLegends.get(symbol as any as TableSymbolType)
                            )
                            .join(' ')}
                    </ScreenReaderOnly>
                </View>
            )
        }
        default:
            return null
    }
}

const tableOperations = TableHandler.INSTANCE

export const TableItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: tableItem } = useItemEntity<TableItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '350px'
    const CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '170px'

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        value={tableItem.label}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        placeholder={'Some human readable label'}
                        itemId={tableItem.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.updateLabel(tableItem.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '30px'}` }}
                    flex={`0 0 ${CHECKBOX_FLEX_BASIS}`}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId={'preserve-order-checkbox'}
                            inputId={'preserve-order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            itemId={tableItem.id}
                            labelText={'Preserve order'}
                            value={tableItem.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                tableOperations.updatePreserveOrder(tableItem.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </Row>
            <Spacer height='S500' />
            <TableEditor<TableCell>
                itemId={itemId}
                headers={tableItem.headers.map((t) => ({
                    headerName: t.headerI18N,
                    editable: true,
                }))}
                rows={tableItem.rows.map((r) => r.cells)}
                setNumberOfColumns={(nextNumber: number) =>
                    tableOperations.setNumberOfColumns(itemId, nextNumber)
                }
                setNumberOfRows={(nextNumber: number) =>
                    tableOperations.setNumberOfRows(itemId, nextNumber)
                }
                legends={[
                    getHarveyBallLegend({
                        itemId: itemId,
                        baseTableDTO: tableItem,
                        locale: locale,
                        tableOperations: tableOperations,
                    }),
                ]}
                symbolLegend={getSymbolLegends({
                    itemId: itemId,
                    baseTableDTO: tableItem,
                    locale: locale,
                    tableOperations: tableOperations,
                })}
                locale={locale}
                setColumnName={(columnIndex, columnText) =>
                    tableOperations.setColumnName(itemId, columnIndex, columnText, locale)
                }
                editCellComponent={(props) => (
                    <CellEditor
                        {...props}
                        itemId={itemId}
                        locale={locale}
                        harveyEnabled={tableItem.harveyBallLegendEnabled}
                        symbolEnabled={tableItem.symbolLegendEnabled}
                        tableOperations={TableHandler.INSTANCE}
                    />
                )}
                cellViewComponent={(props) => <TableItemCellView {...props} locale={locale} />}
                setCellData={({ colNum, rowNum, cellData }) => {
                    tableOperations.setCellData(itemId, colNum, rowNum, cellData)
                }}
                defaultCellData={(rowNum, colNum, cellType) => {
                    return TableHandler.INSTANCE.getCellTypeDefault(itemId, cellType, locale)
                }}
                disabled={editDisabled}
            />
        </>
    )
}
