import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Base64 } from 'js-base64'

import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { View } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Label } from '@amzn/stencil-react-components/text'

import { Locale } from 'src/models/dto/Locale'
import {
    defaultMediaLanguageDTO,
    defaultMediaUsedByDTO,
    MediaFileType,
    MediaTemplateDTO,
} from 'src/models/media/MediaTemplateDTO'
import {
    LocalMediaFilesService,
    LocalMediaFilesServiceFactory,
} from 'src/services/media/LocalMediaFilesService'

interface UploadButtonProps {
    setError: (error: string | undefined) => void
    acceptedTypes?: string
    updateMedia?: (updatedFilePath: string, updatedFileName: string) => void
}

export function UploadButton({ setError, acceptedTypes, updateMedia }: UploadButtonProps) {
    const [imageUploading, setImageUploading] = useState<boolean>(false)
    const fileUploadRef = useRef<HTMLDivElement | null>(null)
    const localMediaFilesService = useRef<LocalMediaFilesService>()
    const navigate = useNavigate()

    useEffect(() => {
        localMediaFilesService.current = LocalMediaFilesServiceFactory.fromScratch()
    }, [])

    useEffect(() => {
        const input = fileUploadRef.current?.querySelector('input[type=file]')
        if (input) {
            input.id = 'file-upload-input'
        }
    }, [])

    const onFileAttached = async (files: File[]) => {
        if (files && files.length == 1 && files[0].type) {
            const fileType = files[0].type
            setError(undefined)

            if (!Object.values(MediaFileType).includes(fileType as unknown as MediaFileType)) {
                setError(
                    'You have uploaded a file type we do not support. Please upload a supported type.'
                )
            } else {
                setImageUploading(true)
                localMediaFilesService.current?.set(files[0])
                const localFile = files[0]

                try {
                    const relativeS3Path = localMediaFilesService.current!.generateRelativeS3Path(
                        localFile.name
                    )

                    await localMediaFilesService.current!.uploadToS3(localFile.name, relativeS3Path)
                    //
                    if (updateMedia) {
                        updateMedia(relativeS3Path, localFile.name)
                    } else {
                        const mediaTemplateDTO: MediaTemplateDTO = {
                            fileType: localFile.type,
                            defaultFileName: localFile.name,
                            defaultFilePath: relativeS3Path,
                            title: '',
                            fileRequiresTranslation: false,
                            hideTranscriptInMedia: false,
                            languages: {
                                [Locale.en_US]: defaultMediaLanguageDTO(),
                            },
                            createdAt: '',
                            lastUpdatedAt: '',
                            createdBy: '',
                            lastUpdatedBy: '',
                            usedByCount: 0,
                            usedByList: [defaultMediaUsedByDTO()],
                        }

                        const src = JSON.stringify(mediaTemplateDTO)
                        const mediaTemplateDTOEncoded = Base64.encode(src)

                        navigate({
                            pathname: '/asset-library/create-asset',
                            search: `?asset=${mediaTemplateDTOEncoded}&locale=en_US`,
                        })
                    }
                } catch (e) {
                    console.error(e)
                    setError('Failed to upload file.')
                    setImageUploading(false)
                    return
                }
            }
        }
    }

    return (
        <>
            {imageUploading ? (
                <Spinner aria-label={'Loading...'} />
            ) : (
                <>
                    <ScreenReaderOnly>
                        <Label htmlFor='file-upload-input'>
                            This will open your browser to select a file to open
                        </Label>
                    </ScreenReaderOnly>
                    <View dataTestId={'upload-asset'}>
                        <FileUpload
                            ref={fileUploadRef}
                            onFileAttached={onFileAttached}
                            showDropZone={false}
                            isMulti={false}
                            accept={acceptedTypes ?? 'image/*, video/*, audio/*'}
                            dataTestId={'file-upload'}
                        />
                    </View>
                </>
            )}
        </>
    )
}
