import { SetStateAction, useCallback, useMemo } from 'react'
import _ from 'lodash'

import { applyChange, SetStateCallback, StateAndCallback } from './index'

export interface ListManipulation<T> {
    addItem(value?: T): void
    deleteItem(index: number): void
    getKey(this: void, entry: T, index: number): string | number | undefined
    onItemChange(this: void, index: number): SetStateCallback<T>
}

const EntryKey = Symbol('EntryKey')
let genEntryKeySeq = 0

interface WithEntryKey {
    [EntryKey]?: string | number
}

function genEntryKey(index?: number) {
    return `entryKey(${index ?? '?'}, uid=${genEntryKeySeq++})`
}

function getKeyForArrayEntry(entry: unknown, index: number) {
    const entry1 = entry as WithEntryKey
    if (entry1[EntryKey]) {
        return entry1[EntryKey] ?? index
    }
    entry1[EntryKey] = genEntryKey(index)
    return entry1[EntryKey]
}

function useListManipulation<T>(onChangeForList: SetStateCallback<T[]>): ListManipulation<T> {
    const onChange = onChangeForList
    const addItem = useCallback(
        (newValue: T) => {
            // Extra variable assignment is here to show the function name to the debugger
            const addItemToList = (value: T[]) => {
                return [...(value ?? []), newValue]
            }
            onChange(addItemToList)
        },
        [onChange]
    )

    const deleteItem = useCallback(
        (i: number) => {
            const deleteItemFromList = (value?: T[]) => {
                const newValue = [...(value ?? [])]
                newValue.splice(i, 1)
                return newValue
            }
            onChange(deleteItemFromList)
        },
        [onChange]
    )

    const getKey = useCallback((entry: unknown, i: number) => {
        if (_.isObject(entry)) {
            const e = entry as never as WithEntryKey
            if (!e[EntryKey]) {
                e[EntryKey] = getKeyForArrayEntry(entry, i)
            }
            return e[EntryKey]
        }
        return `${i}`
    }, [])

    const onItemChange = useCallback(
        (i: number) => (change: SetStateAction<T>) => {
            const updateByKey = (value?: T[]) => {
                const newValue = [...(value ?? [])]
                newValue[i] = applyChange(newValue[i], change)
                return newValue
            }

            onChange(updateByKey)
        },
        [onChange]
    )

    return useMemo(
        () => ({ addItem, deleteItem, getKey, onItemChange }),
        [addItem, deleteItem, getKey, onItemChange]
    )
}

export interface UseListEditor<T> {
    listManipulation: ListManipulation<T>
    getPropsForIndex: (
        value: T,
        index: number
    ) => { key: string | number; props: StateAndCallback<T> }
}

export function useListEditor<T>(props: StateAndCallback<T[]>): UseListEditor<T> {
    const { onChange } = props
    const listManipulation = useListManipulation(onChange)
    const { onItemChange, getKey } = listManipulation
    const len = props.value.length

    const itemChanges = useMemo(() => {
        const list: SetStateCallback<T>[] = []
        for (let i = 0; i < len; i++) {
            list.push(onItemChange(i))
        }
        return list
    }, [onItemChange, len])

    const getPropsForIndex = useCallback(
        (v: T, i: number) => {
            return {
                key: getKey(v, i) ?? i,
                props: { value: v, onChange: itemChanges[i] },
            }
        },
        [itemChanges, getKey]
    )

    return useMemo(
        () => ({ listManipulation, getPropsForIndex }),
        [listManipulation, getPropsForIndex]
    )
}
