import React, { useCallback, useState } from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Row, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { Locale, LocaleDescription } from 'src/models/dto/Locale'
import { Authenticator } from 'src/services/Authenticator'
import { ModuleService } from 'src/services/backend/ModuleService'

export interface ModuleTranslationRequestModalContentProps {
    versionId: string
    moduleName: string
    close: () => void
}

export function ModuleTranslationRequestModalContent(
    props: ModuleTranslationRequestModalContentProps
) {
    const [isInProgress, setIsInProgress] = useState<boolean>(false)
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>(false)

    const [locales, setLocales] = useState<string[]>([])

    const requester = Authenticator.getDefaultUser()
    const requesterEmail = `${requester}@amazon.com`
    const requestTranslations = useCallback(async () => {
        try {
            const selectedLocales = locales.map((v) => {
                const index = Object.values(LocaleDescription).indexOf(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    v as unknown as string
                )

                return Object.keys(Locale)[index]
            })

            setHasErrors(false)
            setIsInProgress(true)
            setRequestSucceeded(false)
            await ModuleService.requestTranslations(
                props.versionId,
                requester,
                requesterEmail,
                selectedLocales
            )
            setRequestSucceeded(true)
            window.location.reload()
            return true
        } catch (e) {
            setHasErrors(true)
            return false
        } finally {
            setIsInProgress(false)
        }
    }, [locales, props.versionId, requester, requesterEmail])

    const renderOption = (curLocale: string) => (
        <Row gridGap='S200' key={curLocale} dataTestId={curLocale} alignItems='center'>
            {curLocale}
        </Row>
    )

    return (
        <ModalContent titleText={'Request Module Translations'}>
            <View backgroundColor='neutral0' minWidth='450px'>
                <Col gridGap='S300'>
                    {hasErrors && (
                        <MessageBanner dataTestId={'error-banner'} type={MessageBannerType.Error}>
                            Something went wrong while requesting translations. Please try again.
                        </MessageBanner>
                    )}
                    {requestSucceeded && (
                        <MessageBanner
                            dataTestId={'succeeded-banner'}
                            type={MessageBannerType.Success}
                        >
                            Translation request succeeded.
                        </MessageBanner>
                    )}

                    <Col gridGap='S300'>
                        <Row>
                            <Text>
                                You are about to request translations for this module (
                                {props.moduleName}
                                ). Please select what languages you want this module to be
                                translated in.
                            </Text>
                        </Row>

                        <InputWrapper id={'locales-drop-down'} labelText={'Available locales'}>
                            {(inputProps) => (
                                <Select
                                    dataTestId={'locales-drop-down'}
                                    {...inputProps}
                                    renderOption={renderOption}
                                    value={locales}
                                    isMulti
                                    options={Object.values(LocaleDescription).filter(
                                        (l) => l !== LocaleDescription.en_US
                                    )}
                                    onChange={(value: string[]) => setLocales([...value])}
                                />
                            )}
                        </InputWrapper>

                        <Row flex={1}>
                            <Button
                                size={ButtonSize.Small}
                                variant={ButtonVariant.Tertiary}
                                dataTestId={'addLocales'}
                                onClick={() =>
                                    setLocales(
                                        Object.values(LocaleDescription).filter(
                                            (l) => l !== LocaleDescription.en_US
                                        )
                                    )
                                }
                            >
                                Add all Locales
                            </Button>
                        </Row>
                        <Row>
                            <Text>
                                The request may take a couple of weeks, when complete you will
                                receive an email at {requesterEmail}.
                            </Text>
                        </Row>
                    </Col>
                    {isInProgress ? (
                        <Spinner />
                    ) : (
                        <Row flex={1} gridGap='S300'>
                            <Button
                                dataTestId='request-translation-button'
                                onClick={requestTranslations}
                                disabled={isInProgress || requestSucceeded || locales.length === 0}
                            >
                                Request Translations
                            </Button>

                            <Button dataTestId='close-button' onClick={props.close}>
                                Close
                            </Button>
                        </Row>
                    )}
                </Col>
            </View>
        </ModalContent>
    )
}
