import React from 'react'

import { Col } from '@amzn/stencil-react-components/layout'

import { ArrowValue } from 'src/components/CustomIcons/ArrowValue'
import { HarveyBallValue } from 'src/components/CustomIcons/HarveyBall'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { RankingCellType, RankingRatingScale } from 'src/models/dto/items/RankingItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { CellMode } from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { ItemEditorTextInput } from './ItemEditorInputs'

export function ScaleValueInput(props: {
    itemId: string
    scale: RankingRatingScale
    locale: Locale
    onChange: (value: string) => void
}) {
    return (
        <Col>
            <ItemEditorTextInput
                inputId={`table-scale-value-legend-text-${props.scale.subType}`}
                itemId={props.itemId}
                disabled={!isDefaultLocale(props.locale)}
                labelText={`Scale legend for ${props.scale.subType}`}
                cellMode={CellMode.Table}
                placeholder=''
                validationErrorMessage={'Please enter a value for this rating'}
                value={props.scale.valueI18N[props.locale] || ''}
                insetElementLeading={{
                    element:
                        props.scale.type === RankingCellType.HarveyBall ? (
                            <HarveyBallValue type={props.scale.subType} />
                        ) : (
                            <ArrowValue type={props.scale.subType} />
                        ),
                }}
                setValue={(nextValue: string) => {
                    props.onChange(nextValue)
                }}
            />
        </Col>
    )
}

export function ScaleValueInput2(props: {
    dateTestId: string
    itemId: string
    inputId: string
    labelText?: string
    placeholderText?: string
    value?: string
    onChange: (value: string) => void
    insetElementLeading: JSX.Element
    locale: Locale
    disabled?: boolean
}) {
    return (
        <Col>
            <ItemEditorTextInput
                dataTestId={props.dateTestId}
                inputId={props.inputId}
                itemId={props.itemId}
                disabled={props.disabled}
                cellMode={CellMode.Table}
                labelText={props.labelText}
                placeholder={props.placeholderText || ''}
                validationErrorMessage={'Please enter a value for this rating'}
                value={props.value ?? ''}
                insetElementLeading={{
                    element: props.insetElementLeading,
                }}
                setValue={(nextValue: string) => {
                    props.onChange(nextValue)
                }}
            />
        </Col>
    )
}
