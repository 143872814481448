import { ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { LocalizedAttribute } from 'src/models/dto/Locale'

export interface ContextBoxDTO extends MediaElement {
    id: string
    contextType: ContextType
    contentI18N: LocalizedAttribute<string>
    iconType?: IconType
    maxPlays?: number
    /**
     * This field is required by the API and should always be set to ContextBox
     */
    ppt: string
    /**
     * @deprecated This field does not do anything but is required by the API
     */
    name: string
    /**
     * @deprecated This field does not do anything but is required by the API
     */
    label: string
    /**
     * This field is required by the API and should always be set to ContextBox
     */
    itemType: ItemType
}

export enum ContextType {
    AUDIO = 'AUDIO',
    CHAT = 'CHAT',
    SITUATION = 'SITUATION',
}

/**
 * For the UI and entity operations only.
 */
export enum RequestType {
    Call = 'Call',
    Message = 'Message',
    Situation = 'Situation',
    Audio = 'Audio',
}

export enum IconType {
    MESSAGE = 'MESSAGE',
    CALL = 'CALL',
}

export type ContextBoxEntity = Omit<ContextBoxDTO, 'ppt' | 'name' | 'label'>
