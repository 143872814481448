import { Locale } from 'src/models/dto/Locale'

export enum ItemPoolType {
    GENERATED = 'GENERATED',
    MANUAL = 'MANUAL',
}

export interface ItemPoolMetadataDTO {
    versionId: string
    type?: ItemPoolType
    status?: string
    itemContainers?: ItemContainerMetadataDTO[]
    locales?: Locale[]
    createdAt?: string
    createdBy?: string
}

export interface ItemContainerMetadataDTO {
    versionId: string
    name: string
    locales: Locale[]
}

export enum GeneratedItemPoolSubType {
    RANKING = 'RANKING',
    SLOT_PICKER = 'SLOT_PICKER',
    MCQ = 'MCQ',
}

export interface GeneratedItemPoolConfig {
    subtype: GeneratedItemPoolSubType
    constraints: string
    size: number
    async: boolean
    useReferenceMaterial: boolean
    flyoutReference: string | null
}

export interface ManualItemPoolConfig {
    itemContainerVersions: string[]
}

export interface CreateItemPoolResponse {
    versionId: string
}
