import React from 'react'

import { Col, Flex, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { EmailContentItemDTO } from 'src/models/dto/items/EmailContentItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { EmailContentHandler } from 'src/services/EntityServices/ItemUpdateHandlers/EmailContentHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const EmailContentItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<EmailContentItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '350px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`} width={matches.s ? '100%' : '410px'}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            EmailContentHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId={'optional-checkbox'}
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            EmailContentHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId={'preserve-order-checkbox'}
                            inputId={'preserveOrder'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                EmailContentHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
            </Row>
            <Spacer height='S400' />
            <Flex
                flexDirection={matches.s ? 'column' : 'row'}
                width='100%'
                gridGap='S300'
                alignItems={'flex-start'}
                flexWrap={'wrap'}
            >
                <Col flex='1 1' width={matches.s ? '100%' : undefined}>
                    <ItemEditorTextInput
                        dataTestId={'email-from'}
                        itemId={itemDTO.id}
                        inputId='email-from'
                        labelText='From (Email sender)'
                        placeholder='Enter an email address'
                        disabled={editDisabled}
                        value={itemDTO.fromI18N[locale] || ''}
                        setValue={(nextValue: string) =>
                            EmailContentHandler.updateFrom(itemDTO.id, locale, nextValue)
                        }
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.fromI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex='1 1' width={matches.s ? '100%' : undefined}>
                    <ItemEditorTextInput
                        dataTestId={'email-to'}
                        itemId={itemDTO.id}
                        inputId='email-to'
                        labelText='To (Email recipient)'
                        placeholder='Enter an email address'
                        value={itemDTO.toI18N[locale] || ''}
                        disabled={editDisabled}
                        setValue={(nextValue: string) =>
                            EmailContentHandler.updateTo(itemDTO.id, locale, nextValue)
                        }
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.toI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Flex>
            <Spacer height='S400' />
            <Col width={'100%'}>
                <ItemEditorTextInput
                    dataTestId={'email-subject'}
                    itemId={itemDTO.id}
                    inputId='email-subject'
                    labelText='Subject line'
                    placeholder='Write a subject line'
                    value={itemDTO.subjectI18N[locale] || ''}
                    disabled={editDisabled}
                    setValue={(nextValue: string) =>
                        EmailContentHandler.updateSubject(itemDTO.id, locale, nextValue)
                    }
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors.subjectI18N ?? []
                    ).join(', ')}
                    showError={showValidationError}
                />
            </Col>
            <Spacer height='S400' />
            <Col flex='1'>
                <ItemEditorTextArea
                    dataTestId={'email-message'}
                    inputId={'email-message'}
                    locale={locale}
                    value={itemDTO.messageI18N[locale] ?? ''}
                    disabled={editDisabled}
                    itemId={itemDTO.id}
                    setValue={(nextValue: string) => {
                        EmailContentHandler.updateMessage(itemDTO.id, locale, nextValue)
                    }}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors.messageI18N ?? []
                    ).join(', ')}
                    showError={showValidationError}
                    labelText={'Email message'}
                />
            </Col>
        </>
    )
}
