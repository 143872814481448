import React from 'react'

import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useSetLocaleWiseMediaOpt } from 'src/hooks/useSetLocaleWiseMediaOpt'
import { WrittenResponseItemDTO } from 'src/models/dto/items/WrittenResponseItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { WrittenResponseHandler } from 'src/services/EntityServices/ItemUpdateHandlers/WrittenResponseHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const WrittenResponseItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<WrittenResponseItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const flexItemWidth = matches.s ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '250px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    const setLocaleWiseMediaOpt = useSetLocaleWiseMediaOpt(itemDTO)

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            WrittenResponseHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId={'optional-checkbox'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            WrittenResponseHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId={'preserve-order-checkbox'}
                            inputId={'preserve-order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                WrittenResponseHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col flex={`3 0 ${matches.s ? '100%' : '200px'}`}>
                    <ItemEditorNumberInput
                        dataTestId={'min-character-limit'}
                        inputId={'min-character-limit'}
                        itemId={itemDTO.id}
                        labelText='Minimum Character Limit'
                        value={itemDTO.minCharacterLimit}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        placeholder='0'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.minCharacterLimit ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        setValue={(nextValue: number | null) => {
                            WrittenResponseHandler.updateMinCharacterLimit(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col flex={`3 0 ${matches.s ? '100%' : '200px'}`}>
                    <ItemEditorNumberInput
                        dataTestId={'max-character-limit'}
                        inputId={'max-character-limit'}
                        itemId={itemDTO.id}
                        labelText='Maximum Character Limit'
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.maxCharacterLimit === 0 ? null : itemDTO.maxCharacterLimit}
                        placeholder='500'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxCharacterLimit ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        setValue={(nextValue: number | null) => {
                            WrittenResponseHandler.updateMaxCharacterLimit(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextInput
                        dataTestId={'stimulus'}
                        inputId={'stimulus'}
                        disabled={editDisabled}
                        value={itemDTO.questionI18N[locale] ?? ''}
                        placeholder={'Enter the stimulus question'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            WrittenResponseHandler.updateQuestion(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.questionI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Stimulus'}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <Hr />
            <MediaManager
                itemId={itemDTO.id}
                locale={locale}
                localeWiseMediaOpt={itemDTO.localeWiseMedia}
                setLocaleWiseMediaOpt={setLocaleWiseMediaOpt}
                disabled={editDisabled}
            />
        </>
    )
}
