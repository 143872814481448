import { AxiosResponse } from 'axios'

import { APP_CONFIG } from 'src/config.app'
import {
    DeployModuleGroupVersionDTO,
    DeployModuleGroupVersionResponse,
} from 'src/models/dto/module-groups/ModuleGroupDeployments'
import {
    ModuleGroupDTO,
    ModuleGroupType,
    ModuleMappings,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import {
    DeployModuleVersionResponse,
    ModuleDeploymentTargetStage,
} from 'src/models/dto/ModuleDeployment'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

const MapDeploymentTargetStageToApiActionName: Record<ModuleDeploymentTargetStage, ApiActionNames> =
    {
        PREVIEW: ApiActionNames.DeployModuleGroupVersionPreview,
        UAT: ApiActionNames.DeployModuleGroupVersionUAT,
        PRODUCTION: ApiActionNames.DeployModuleGroupVersionProduction,
    }

export interface SaveModuleGroupVersionRequest {
    lastKnownTimeToken: number
    name: string
    purpose: string
    moduleMappings: ModuleMappings
}

export interface UpdateModuleGroupRequest {
    lastKnownTimeToken: number
    versionId: string
    purpose?: string
    moduleMappings: ModuleMappings
}

export class ModuleGroupService {
    public static async saveModuleGroupVersion(
        moduleGroupSaveRequest: SaveModuleGroupVersionRequest
    ) {
        try {
            const { data }: AxiosResponse<ModuleGroupDTO> = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions`,
                JSON.stringify(moduleGroupSaveRequest, this.mapToJSONReplacer),
                {
                    apiActionName: ApiActionNames.SaveModuleGroupVersion,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            const parsedData = JSON.parse(JSON.stringify(data), this.reviveMap)
            return parsedData as ModuleGroupDTO
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async loadModuleGroupVersionDTO(
        moduleGroupVersionId: string
    ): Promise<ModuleGroupDTO> {
        try {
            const { data }: AxiosResponse<ModuleGroupDTO> = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions/${moduleGroupVersionId}`,
                {
                    apiActionName: ApiActionNames.GetModuleGroupVersion,
                }
            )
            const parsedData = JSON.parse(JSON.stringify(data), this.reviveMap)
            return parsedData as ModuleGroupDTO
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async updateModuleGroupVersionDTO(updateRequest: UpdateModuleGroupRequest) {
        try {
            const { data }: AxiosResponse<ModuleGroupDTO> = await ADAxios.put(
                `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions`,
                JSON.stringify(updateRequest, this.mapToJSONReplacer),
                {
                    apiActionName: ApiActionNames.UpdateModuleGroupVersion,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            const parsedData = JSON.parse(JSON.stringify(data), this.reviveMap)
            return parsedData as ModuleGroupDTO
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static async deployModuleGroupVersion(
        moduleGroupVersionId: string,
        deployModuleGroupVersionDTO: DeployModuleGroupVersionDTO
    ): Promise<DeployModuleGroupVersionResponse> {
        try {
            const { data }: { data: DeployModuleVersionResponse } = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions/${moduleGroupVersionId}/deploy`,
                deployModuleGroupVersionDTO,
                {
                    apiActionName:
                        MapDeploymentTargetStageToApiActionName[deployModuleGroupVersionDTO.stage],
                }
            )

            return data as unknown as DeployModuleGroupVersionResponse
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }

    public static getModuleGroupType(
        moduleGroup: ModuleGroupDTO | undefined
    ): ModuleGroupType | undefined {
        if (moduleGroup?.moduleMappings.mes) {
            return ModuleGroupType.MES
        } else if (moduleGroup?.moduleMappings.pec) {
            return ModuleGroupType.PEC
        } else return undefined
    }

    // Maps are not natively supported by json,
    // we have to tell json how to format the object before sending it in the request
    public static mapToJSONReplacer(jsonKey: string, jsonValue: any): any {
        if (jsonValue instanceof Map) {
            const entriesArray: [string, string][] = Array.from(jsonValue.entries())
            const jsonObject: { [key: string]: string } = {}
            entriesArray.forEach(([key, value]) => {
                jsonObject[key] = value
            })
            return jsonObject
        }
        return jsonValue
    }

    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    private static reviveMap(key: any, value: any): any {
        if (typeof value === 'object' && value !== null && key === 'moduleVersionScoreLabelMap') {
            return new Map(Object.entries(value))
        }
        return value
    }
}
