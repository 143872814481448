import { APP_CONFIG } from 'src/config.app'
import { JobMetadataResponse } from 'src/models/dto/module-groups/JobMetadataResponse'
import { RuleType } from 'src/models/dto/module-groups/PECGroupDTO'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'

interface JobMetadataCallResponse {
    data: JobMetadataResponse[]
}

export class JobMetadataService {
    public static async getJobMetadata(
        metadataType: RuleType,
        numberOfRecords: number,
        searchString?: string
    ): Promise<JobMetadataResponse[]> {
        try {
            const { data }: { data: JobMetadataCallResponse } = await ADAxios.get(
                `${APP_CONFIG.backendAPIBaseUrl}/job-metadata`,
                {
                    params: {
                        category:
                            Object.keys(RuleType)[Object.values(RuleType).indexOf(metadataType)],
                        numberOfRecords: numberOfRecords,
                        ...(searchString && { search: searchString }),
                    },
                    apiActionName: ApiActionNames.GetJobMetadata,
                }
            )
            return data.data
        } catch (e: unknown) {
            handleAxiosError(e)
        }
    }
}
