import { useContext, useState } from 'react'
import axios from 'axios'
import useAxios from 'axios-hooks'

import { APP_CONFIG } from 'src/config.app'
import { ModuleBuilderContext } from 'src/contexts/ModuleBuilderContext'
import { useInterval } from 'src/hooks/useInterval'
import { MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { ModuleDTO, ModuleLayout } from 'src/models/dto/ModuleDTO'
import { ApiActionNames } from 'src/services/AxiosInterceptor'
import { ModuleService } from 'src/services/backend/ModuleService'
import {
    ACTIVITY_ENTITY_STORE_SELECTOR,
    ActivityEntityService,
} from 'src/services/EntityServices/ActivityEntityService'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { MUPPExamHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/MUPPExamHandler'
import {
    CONTEXT_BOX_ENTITY_STORE_SELECTOR,
    ContextBoxEntityService,
} from 'src/services/EntityServices/ContextBoxEntityService'
import {
    ITEM_ENTITY_STORE_SELECTOR,
    ItemEntityService,
} from 'src/services/EntityServices/ItemEntityService'
import {
    MODULE_ENTITY_STORE_SELECTOR,
    ModuleEntityService,
} from 'src/services/EntityServices/ModuleEntityService'

export enum PreviewTTL {
    // eslint-disable-next-line no-magic-numbers
    THIRTY_MINUTES = 1800,
    // eslint-disable-next-line no-magic-numbers
    ONE_DAY = 86400000,
}

export interface UsePreviewProps {
    entityId: string
    entityStoreSelector: string
    saved?: boolean
    onPreviewUnexpectedError?: () => void
    moduleLayout?: ModuleLayout
}

export enum PreviewProgress {
    TIMED_OUT = 'TIMED_OUT',
    NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
    PUBLISHING = 'PUBLISHING',
    FAILED_TO_PUBLISH = 'FAILED_TO_PUBLISH',
    SUCCESSFULLY_PUBLISHED = 'SUCCESSFULLY_PUBLISHED',
}

interface SyncRequest {
    moduleTemplateVersionId: string
    retryIfFailed: boolean
    moduleTemplate: string | null
}

interface SyncResponse {
    moduleTemplateVersionId: string
}

interface SyncProgressResponse {
    status: SyncForPreviewResponseStatus
}

export enum SyncForPreviewResponseStatus {
    TIMED_OUT = 'TIMED_OUT',
    NOT_YET_PUBLISHED = 'NOT_YET_PUBLISHED',
    PUBLISHING = 'PUBLISHING',
    FAILED_TO_PUBLISH = 'FAILED_TO_PUBLISH',
    SUCCESSFULLY_PUBLISHED = 'SUCCESSFULLY_PUBLISHED',
}

interface LaunchModuleResponse {
    previewUrl: string
}

export interface LaunchModuleRequest {
    moduleTemplateVersionId: string
    ttlInSeconds: number
}

export interface ModuleValidationErrorResponse {
    moduleValidationErrorMessages: ModuleValidationErrorMessage[]
}

export interface ModuleValidationErrorMessage {
    id?: string | null
    message: string
}

export interface UsePreviewResults {
    previewUrl: string | undefined
    previewValidationError: ModuleValidationErrorMessage[] | undefined
    executePreview: (ttl: PreviewTTL) => void
    generatingPreview: boolean
}

export const UNPROCESSABLE_ENTITY = 422

function createSyncPayload(
    entityId: string,
    failedPreviously: boolean,
    entityStoreSelector: string,
    moduleUsingMediaManager: boolean,
    moduleLayout: ModuleLayout,
    alreadyPersisted?: boolean
): SyncRequest {
    let moduleDTO: ModuleDTO | null = null

    switch (entityStoreSelector) {
        case MODULE_ENTITY_STORE_SELECTOR: {
            if (!alreadyPersisted) {
                const moduleEntity = ModuleEntityService.duplicate(entityId)
                moduleDTO = ModuleService.serializeModuleDTO(moduleEntity.version)
                entityId = moduleDTO.version
            }
            break
        }
        case ITEM_ENTITY_STORE_SELECTOR: {
            const moduleEntity = ModuleEntityService.createBlank()
            ModuleEntityService.updateName(moduleEntity.version, moduleEntity.version)
            ModuleEntityService.updateLayout(moduleEntity.version, moduleLayout)
            const itemGroupEntity = ActivityEntityService.create()
            ModuleEntityService.addActivity(moduleEntity.version, itemGroupEntity.id)
            const itemDTO = ItemEntityService.duplicateItem(entityId)
            ItemGroupHandler.addItem(itemGroupEntity.id, itemDTO.id)
            moduleDTO = {
                ...ModuleService.getFullModuleDTO(
                    ModuleService.serializeModuleDTO(moduleEntity.version)
                ),
                usingMediaManager: moduleUsingMediaManager,
            }
            entityId = moduleDTO.version
            break
        }
        case ACTIVITY_ENTITY_STORE_SELECTOR: {
            const newModuleEntity = ModuleEntityService.createBlank()
            ModuleEntityService.updateName(newModuleEntity.version, newModuleEntity.version)
            ModuleEntityService.updateLayout(newModuleEntity.version, moduleLayout)
            const duplicatedActivityEntity = ActivityEntityService.duplicate(entityId)
            if (duplicatedActivityEntity.type === ActivityType.LaunchCAT) {
                MUPPExamHandler.update({
                    ...(duplicatedActivityEntity as MUPPExamEntity),
                    name: duplicatedActivityEntity.id,
                })
            } else {
                ItemGroupHandler.updateName(
                    duplicatedActivityEntity.id,
                    duplicatedActivityEntity.id
                )

                ItemGroupHandler.removeBranchingConditions(duplicatedActivityEntity.id)
            }

            ModuleEntityService.addActivity(newModuleEntity.version, duplicatedActivityEntity.id)
            moduleDTO = {
                ...ModuleService.getFullModuleDTO(
                    ModuleService.serializeModuleDTO(newModuleEntity.version)
                ),
                usingMediaManager: moduleUsingMediaManager,
            }
            entityId = moduleDTO.version
            break
        }
        case CONTEXT_BOX_ENTITY_STORE_SELECTOR: {
            const moduleEntity = ModuleEntityService.createBlank()
            ModuleEntityService.updateName(moduleEntity.version, moduleEntity.version)
            ModuleEntityService.updateLayout(moduleEntity.version, ModuleLayout.CustomerFacing)
            ModuleEntityService.updateUsingMediaManager(moduleEntity.version, true)
            const itemGroupEntity = ActivityEntityService.create()
            ModuleEntityService.addActivity(moduleEntity.version, itemGroupEntity.id)
            const contextBoxDTO = ContextBoxEntityService.duplicateContextBox(entityId)
            ItemGroupHandler.setContextBoxId(itemGroupEntity.id, contextBoxDTO.id)
            moduleDTO = ModuleService.getFullModuleDTO(
                ModuleService.serializeModuleDTO(moduleEntity.version)
            )
            entityId = moduleDTO.version
            break
        }
    }

    return {
        moduleTemplateVersionId: entityId,
        retryIfFailed: failedPreviously,
        moduleTemplate: moduleDTO
            ? JSON.stringify(ModuleService.getFullModuleDTO(moduleDTO))
            : null,
    }
}

export function usePreview({
    entityId,
    saved,
    entityStoreSelector,
    onPreviewUnexpectedError,
    moduleLayout,
}: UsePreviewProps): UsePreviewResults {
    const ONE_SECOND = 1000

    const [failedPreviously, setFailedPreviously] = useState<boolean>(false)
    const [inProgressModuleVersion, setInProgressModuleVersion] = useState<string | undefined>()
    const [validationError, setValidationError] = useState<
        ModuleValidationErrorMessage[] | undefined
    >()
    const [previewUrl, setPreviewUrl] = useState<string | undefined>()
    const [ttlInSeconds, setTTLInSeconds] = useState<PreviewTTL | undefined>()
    const { moduleUsingMediaManager } = useContext(ModuleBuilderContext)

    const [{ loading: syncRequestExecuting }, executeSyncRequest] = useAxios<
        SyncResponse,
        SyncRequest,
        string
    >(
        {
            method: 'POST',
            apiActionName: ApiActionNames.InitiatePreview,
        },
        {
            manual: true,
        }
    )

    const [{ loading: syncProgressRequestExecuting }, executeSyncProgressRequest] = useAxios<
        SyncProgressResponse,
        any, //eslint-disable-line @typescript-eslint/no-explicit-any
        any //eslint-disable-line @typescript-eslint/no-explicit-any
    >(
        {
            apiActionName: ApiActionNames.PreviewProgress,
        },
        {
            manual: true,
        }
    )

    const [{ loading: launchRequestExecuting }, executeLaunchRequest] = useAxios<
        LaunchModuleResponse,
        LaunchModuleRequest,
        any //eslint-disable-line @typescript-eslint/no-explicit-any
    >(
        {
            method: 'POST',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/module-instance-previews/module-instances`,
            apiActionName: ApiActionNames.LaunchPreview,
        },
        {
            manual: true,
        }
    )

    useInterval(() => {
        if (
            inProgressModuleVersion &&
            !syncRequestExecuting &&
            !syncProgressRequestExecuting &&
            !launchRequestExecuting
        ) {
            executeSyncProgressRequest({
                url: `${APP_CONFIG.backendAPIV1BaseUrl}/module-instance-previews/synced-module-templates/${inProgressModuleVersion}/status`,
            })
                .then((syncProgressResponse) => {
                    switch (syncProgressResponse.data.status) {
                        case SyncForPreviewResponseStatus.TIMED_OUT:
                            setFailedPreviously(true)
                            setInProgressModuleVersion(undefined)
                            if (onPreviewUnexpectedError) {
                                onPreviewUnexpectedError()
                            }
                            return
                        case SyncForPreviewResponseStatus.FAILED_TO_PUBLISH:
                            setFailedPreviously(true)
                            setInProgressModuleVersion(undefined)
                            if (onPreviewUnexpectedError) {
                                onPreviewUnexpectedError()
                            }
                            return
                        case SyncForPreviewResponseStatus.SUCCESSFULLY_PUBLISHED:
                            if (inProgressModuleVersion && ttlInSeconds) {
                                executeLaunchRequest({
                                    data: {
                                        moduleTemplateVersionId: inProgressModuleVersion,
                                        ttlInSeconds: ttlInSeconds,
                                    },
                                })
                                    .then((launchResponse) => {
                                        setPreviewUrl(launchResponse.data.previewUrl)
                                        setInProgressModuleVersion(undefined)
                                    })
                                    .catch(() => {
                                        setInProgressModuleVersion(undefined)
                                        if (onPreviewUnexpectedError) {
                                            onPreviewUnexpectedError()
                                        }
                                        setValidationError(undefined)
                                    })
                            }
                            return
                    }
                })
                .catch(() => {
                    setInProgressModuleVersion(undefined)
                    if (onPreviewUnexpectedError) {
                        onPreviewUnexpectedError()
                    }
                    setValidationError(undefined)
                })
        }
    }, ONE_SECOND)

    return {
        generatingPreview: !!inProgressModuleVersion,
        previewUrl: previewUrl,
        previewValidationError: validationError,
        executePreview: (ttl: PreviewTTL) => {
            if (!inProgressModuleVersion) {
                setPreviewUrl(undefined)
                setTTLInSeconds(ttl)
                setValidationError(undefined)
                const syncPayload = createSyncPayload(
                    entityId,
                    failedPreviously,
                    entityStoreSelector,
                    moduleUsingMediaManager,
                    moduleLayout || ModuleLayout.Default,
                    saved
                )
                setInProgressModuleVersion(syncPayload.moduleTemplateVersionId ?? entityId)
                executeSyncRequest({
                    url: `${APP_CONFIG.backendAPIBaseUrl}/modules-versions/${syncPayload.moduleTemplateVersionId}/preview`,
                    data: syncPayload,
                }).catch((syncError) => {
                    setInProgressModuleVersion(undefined)

                    if (
                        axios.isAxiosError(syncError) &&
                        syncError?.response?.status === UNPROCESSABLE_ENTITY
                    ) {
                        const validationErrorResponse = syncError.response
                            .data as ModuleValidationErrorResponse
                        setValidationError(validationErrorResponse.moduleValidationErrorMessages)
                    } else {
                        setValidationError(undefined)
                        if (onPreviewUnexpectedError) {
                            onPreviewUnexpectedError()
                        }
                    }
                })
            }
        },
    }
}
