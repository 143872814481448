import { AxiosError } from 'axios'

import { APP_CONFIG } from 'src/config.app'
import { ItemGroupDTO } from 'src/models/dto/activities/ItemGroupDTO'
import { MUPPExamDTO } from 'src/models/dto/activities/MUPPExamDTO'
import { RandomSelectionDTO } from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import { ContextBoxDTO } from 'src/models/dto/ContextBoxDTO'
import { ErrorResponse } from 'src/models/dto/ErrorResponse'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { LikertGroupDTO } from 'src/models/dto/items/LikertItemDTO'
import { ModuleGroupDTO } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleDTO } from 'src/models/dto/ModuleDTO'
import { SaveModuleVersionResponse } from 'src/models/dto/SaveModuleVersionResponse'
import {
    GENERIC_ERROR_KEY,
    TemplateValidationError,
    ValidationErrorEntity,
} from 'src/models/dto/TemplateValidationError'
import { ADAxios, ApiActionNames } from 'src/services/AxiosInterceptor'
import { handleAxiosError } from 'src/services/backend/ModuleService'
import { ActivityEntityService } from 'src/services/EntityServices/ActivityEntityService'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { InstructionalContentEntityService } from 'src/services/EntityServices/InstructionalContentEntityService'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { ModuleEntityService } from 'src/services/EntityServices/ModuleEntityService'
import { ValidationErrorEntityService } from 'src/services/EntityServices/ValidationErrorEntityService'
import { ModuleGroupService } from 'src/services/module-groups/ModuleGroupService'

export class ErrorCheckService {
    public static async moduleVersionErrorCheck(
        moduleDTO: ModuleDTO,
        lastKnownSavedTimeToken: number,
        excludeScoringValiation?: boolean,
        excludeTranslationValidation?: boolean,
        abortController?: AbortController
    ): Promise<SaveModuleVersionResponse> {
        try {
            const {
                data,
            }: {
                data: Omit<SaveModuleVersionResponse, 'updatedAt'> & { updatedAt: number | string }
            } = await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/modules-versions`,
                {
                    versionId: moduleDTO.version,
                    lastKnownSavedTimeToken,
                    content: JSON.stringify(moduleDTO),
                    dryRunConfig: {
                        validationEnabled: true,
                        excludeScoringValidation: excludeScoringValiation,
                        excludeTranslationValidation: excludeTranslationValidation,
                    },
                },
                {
                    apiActionName: ApiActionNames.SaveModuleVersion,
                    signal: abortController?.signal,
                }
            )

            this.clearExistingValidationError(
                this.getRelatedEntityIdsFromModuleEntity(moduleDTO.version),
                []
            )
            return {
                ...data,
                updatedAt: new Date(data.updatedAt as string),
            } as unknown as SaveModuleVersionResponse
        } catch (e: unknown) {
            const axiosError = e as AxiosError | undefined
            if (axiosError?.response?.data) {
                const errorEntities = this.populateValidationErrors(
                    (
                        axiosError?.response?.data as {
                            errors: TemplateValidationError[]
                        }
                    ).errors
                )
                this.clearExistingValidationError(
                    this.getRelatedEntityIdsFromModuleEntity(moduleDTO.version),
                    errorEntities.map((entity) => entity.id)
                )
            }
            handleAxiosError(e)
        }
    }

    public static async templatesErrorCheck(
        dto: ItemDTO | ItemGroupDTO | RandomSelectionDTO | MUPPExamDTO | ContextBoxDTO,
        abortController?: AbortController
    ): Promise<void> {
        const itemType: ItemType | undefined = (dto as { itemType: ItemType }).itemType

        try {
            await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/templates`,
                {
                    content: JSON.stringify(dto),
                    dryRunConfig: {
                        validationEnabled: true,
                    },
                },
                {
                    apiActionName: ApiActionNames.ValidateTemplate,
                    signal: abortController?.signal,
                }
            )

            this.clearExistingValidationError(
                itemType !== undefined
                    ? [dto.id].concat(
                          itemType === ItemType.ContextBox
                              ? []
                              : this.getRelatedEntityIdsFromLikertGroupEntity(dto.id)
                      )
                    : this.getRelatedEntityIdsFromActivityEntity(dto.id),
                []
            )
        } catch (e: unknown) {
            const axiosError = e as AxiosError | undefined
            if (axiosError?.response?.data) {
                const errorEntities = this.populateValidationErrors(
                    (
                        axiosError?.response?.data as ErrorResponse & {
                            errors: TemplateValidationError[]
                        }
                    ).errors
                )
                this.clearExistingValidationError(
                    (dto as { itemType: ItemType }).itemType !== undefined
                        ? [dto.id].concat(
                              itemType === ItemType.ContextBox
                                  ? []
                                  : this.getRelatedEntityIdsFromLikertGroupEntity(dto.id)
                          )
                        : this.getRelatedEntityIdsFromActivityEntity(dto.id),
                    errorEntities.map((entity) => entity.id)
                )
            }
            handleAxiosError(e)
        }
    }

    public static async moduleGroupErrorCheck(
        dto: ModuleGroupDTO,
        abortController?: AbortController
    ): Promise<void> {
        try {
            await ADAxios.post(
                `${APP_CONFIG.backendAPIBaseUrl}/module-groups-versions/${dto.versionId}/validate`,
                JSON.stringify(
                    { moduleMappings: dto.moduleMappings },
                    ModuleGroupService.mapToJSONReplacer
                ),
                {
                    apiActionName: ApiActionNames.ValidateModuleGroup,
                    signal: abortController?.signal,
                }
            )
            this.clearExistingValidationError([dto.versionId], [])
        } catch (e: unknown) {
            const axiosError = e as AxiosError | undefined
            if (axiosError?.response?.data) {
                const errorEntities = this.populateValidationErrors(
                    (
                        axiosError?.response?.data as ErrorResponse & {
                            errors: TemplateValidationError[]
                        }
                    ).errors
                )
                this.clearExistingValidationError(
                    [dto.versionId],
                    errorEntities.map((entity) => entity.id)
                )
            }
            handleAxiosError(e)
        }
    }

    /**
     *
     * @param dtos set of TemplateValidationErrors to populate
     *
     * outcome: Validation Error Store is populated or first error is thrown
     */
    public static populateValidationErrors(
        dtos: TemplateValidationError[]
    ): ValidationErrorEntity[] {
        const aggregate: Record<string, Record<string, string[]>> = {}
        dtos.forEach((dto) => {
            const attributePath =
                dto.attributePath && dto.attributePath.length > 0
                    ? dto.attributePath
                    : GENERIC_ERROR_KEY
            const message = dto.message
            if (aggregate[dto.versionId]) {
                const errorMessages = aggregate[dto.versionId][attributePath] ?? []
                errorMessages.push(message)
                aggregate[dto.versionId][attributePath] = errorMessages
            } else {
                const validationError: Record<string, string[]> = {}
                validationError[attributePath] = [message]
                aggregate[dto.versionId] = validationError
            }
        })

        const entities: ValidationErrorEntity[] = []
        Object.entries(aggregate).forEach(([id, validationErrors]) => {
            const entity: ValidationErrorEntity = {
                id,
                validationErrors,
            }
            ValidationErrorEntityService.update(entity)
            entities.push(entity)
        })
        console.log('populateValidationErrors', { dtos, entities })

        return entities
    }

    private static getRelatedEntityIdsFromModuleEntity(moduleEntityId: string): string[] {
        const result = [moduleEntityId]
        const moduleEntity = ModuleEntityService.get(moduleEntityId)
        moduleEntity.workflowIds.forEach((workflowId) => {
            result.push(...this.getRelatedEntityIdsFromActivityEntity(workflowId))
        })
        if (moduleEntity.instructionalContentId)
            result.push(
                ...this.getRelatedEntityIdsFromInstructionalEntity(
                    moduleEntity.instructionalContentId
                )
            )
        if (moduleEntity.mturkPaymentCodeItemId) result.push(moduleEntity.mturkPaymentCodeItemId)
        return result
    }

    private static getRelatedEntityIdsFromActivityEntity(activityEntityId: string): string[] {
        const result = [activityEntityId]
        const activityEntity = ActivityEntityService.get(activityEntityId)

        if (activityEntity.type === ActivityType.LaunchItemGroup) {
            const { itemIds, contextBoxId } = ItemGroupHandler.get(activityEntityId)
            result.push(...itemIds)

            if (contextBoxId) {
                result.push(contextBoxId)
            }

            itemIds.forEach((id) => {
                result.concat(this.getRelatedEntityIdsFromLikertGroupEntity(id))
            })
        }

        return result
    }

    private static getRelatedEntityIdsFromLikertGroupEntity(id: string): string[] {
        const entity = ItemEntityService.get(id)
        let result: string[] = []
        if (entity.itemType == ItemType.LikertGroup) {
            result = (entity as LikertGroupDTO).stimulus.map((s) => s.stimulusV2DTO.id)
        }
        return result
    }

    private static getRelatedEntityIdsFromInstructionalEntity(
        instructionalContentEntityId: string
    ): string[] {
        const result = [instructionalContentEntityId]
        const instructionalContentEntity = InstructionalContentEntityService.get(
            instructionalContentEntityId
        )
        result.push(...instructionalContentEntity.itemIds)
        return result
    }

    private static clearExistingValidationError(
        allErrorEntityIds: string[],
        updatedErrorEntityIds: string[]
    ) {
        allErrorEntityIds
            .filter((id) => !updatedErrorEntityIds.includes(id))
            .forEach((id) => {
                ValidationErrorEntityService.update({
                    id,
                    validationErrors: {},
                })
            })
    }

    public static getTotalModuleError(moduleEntityId: string): number {
        const entityIds = this.getRelatedEntityIdsFromModuleEntity(moduleEntityId)
        return entityIds
            .map((entityId) => this.getErrorCount(entityId))
            .reduce((count, current) => count + current, 0)
    }

    public static getTotalActivityError(activityEntityId: string): number {
        const entityIds = this.getRelatedEntityIdsFromActivityEntity(activityEntityId)
        return entityIds
            .map((entityId) => this.getErrorCount(entityId))
            .reduce((count, current) => count + current, 0)
    }

    public static getTotalInstructionalContentError(instructionalContentEntityId: string): number {
        const entityIds = this.getRelatedEntityIdsFromInstructionalEntity(
            instructionalContentEntityId
        )
        return entityIds
            .map((entityId) => this.getErrorCount(entityId))
            .reduce((count, current) => count + current, 0)
    }

    public static getErrorCount(entityId: string): number {
        const errors: string[] = []
        const validationErrorEntity = ValidationErrorEntityService.get(entityId)
        Object.values(validationErrorEntity.validationErrors ?? []).forEach((errorMessages) => {
            errors.push(...errorMessages)
        })
        return errors.length
    }
}
