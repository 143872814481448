// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react'

export function useInterval(callback: () => unknown, delay: number) {
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    })

    useEffect(() => {
        let running = false
        function tick() {
            if (!running) {
                running = true
                savedCallback.current()
                running = false
            }
        }

        const id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}
