import { cloneDeep } from 'lodash'

export enum Locale {
    en_US = 'en_US',
    es_ES = 'es_ES',
    es_419 = 'es_419',
    fr_CA = 'fr_CA',
    ja_JP = 'ja_JP',
    ko_KR = 'ko_KR',
    ru_RU = 'ru_RU',
    uk_UA = 'uk_UA',
    de_DE = 'de_DE',
    en_GB = 'en_GB',
    pl_PL = 'pl_PL',
    fr_FR = 'fr_FR',
    it_IT = 'it_IT',
    es_MX = 'es_MX',
    es_US = 'es_US',
    zh_CN = 'zh_CN',
    zh_HK = 'zh_HK',
    pt_BR = 'pt_BR',
    nl_NL = 'nl_NL',
    nl_BE = 'nl_BE',
    ca_ES = 'ca_ES',
}

export const LocaleDescription: Record<Locale, string> = {
    en_US: 'English (US)',
    es_ES: 'Spanish (Spain)',
    es_419: 'Spanish (LATAM)',
    fr_CA: 'French (Canada)',
    ja_JP: 'Japanese',
    ko_KR: 'Korean',
    ru_RU: 'Russian',
    uk_UA: 'Ukrainian',
    de_DE: 'German',
    en_GB: 'English (UK)',
    pl_PL: 'Polish',
    fr_FR: 'French (France)',
    it_IT: 'Italian',
    es_MX: 'Spanish (Mexico)',
    es_US: 'Spanish (US)',
    zh_CN: 'Chinese (Simp)',
    zh_HK: 'Traditional Chinese (Hong Kong)',
    pt_BR: 'Portuguese (BR)',
    nl_NL: 'Dutch Netherlands (NL)',
    nl_BE: 'Dutch Belgium (BE)',
    ca_ES: 'Catalan',
}

export const Locales: Locale[] = [
    Locale.en_US,
    Locale.es_ES,
    Locale.fr_CA,
    Locale.ja_JP,
    Locale.ko_KR,
]

export type LocalizedAttribute<T> = {
    [language in Locale]: T | undefined
}

export interface LocaleProp {
    locale: Locale
}

/**
 * Helper function to scaffold a LocalizedAttribute where the parameter entity
 * is repeated for each supported locale. Deep cloning ensures that updates
 * to the parameter does not affect the LocalizedAttribute being returned
 *
 * @param value What the entry for the locale will be
 * @param locale The Locale
 * @returns a LocalizedAttribute object with entity cloned deeply
 */
export function LocalizeDefault<T>(value: T, locale?: Locale): LocalizedAttribute<T> {
    const ret: LocalizedAttribute<T> = {
        [Locale.en_US]: undefined,
        [Locale.es_ES]: undefined,
        [Locale.fr_CA]: undefined,
        [Locale.ja_JP]: undefined,
        [Locale.ko_KR]: undefined,
        [Locale.de_DE]: undefined,
        [Locale.en_GB]: undefined,
        [Locale.es_MX]: undefined,
        [Locale.es_US]: undefined,
        [Locale.fr_FR]: undefined,
        [Locale.it_IT]: undefined,
        [Locale.pl_PL]: undefined,
        [Locale.pt_BR]: undefined,
        [Locale.uk_UA]: undefined,
        [Locale.ru_RU]: undefined,
        [Locale.zh_CN]: undefined,
        [Locale.zh_HK]: undefined,
        [Locale.nl_BE]: undefined,
        [Locale.nl_NL]: undefined,
        [Locale.ca_ES]: undefined,
        [Locale.es_419]: undefined,
    }

    if (locale) {
        ret[locale] = cloneDeep(value)
    } else {
        ret[Locale.en_US] = cloneDeep(value)
    }
    return ret
}

export function fillLocalized<T>(map: Partial<LocalizedAttribute<T>>, defaultValue: T) {
    const localized: Partial<LocalizedAttribute<T>> = {}
    for (const locale of Object.values<Locale>(Locale)) {
        localized[locale] = map[locale] ?? defaultValue
    }
    return localized as LocalizedAttribute<T>
}
