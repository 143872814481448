import {
    defaultRatingItem,
    defaultRatingScale,
    MAX_RATING_SCALE,
    RatingItemDTO,
} from 'src/models/dto/items/RatingItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { ItemDTO, ItemType } from './../../../models/dto/items/ItemDTO'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.Rating

export class RatingHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => RatingHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): RatingItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as RatingItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): RatingItemDTO {
        return defaultRatingItem()
    }

    private static update(entity: RatingItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as RatingItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as RatingItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as RatingItemDTO)
    }

    static updateQuestion(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.questionI18N[locale] = nextValue
        this.update(entity)
    }

    private static checkArrayBounds<T>(index: number, arr: T[]) {
        if (arr && (index < 0 || index >= arr.length)) {
            throw new Error(`Index out of bounds: Index: ${index}, Size: ${arr.length}`)
        }
    }

    static addScale(id: string) {
        const entity = this.getEntity(id)
        if (entity.scales.length >= MAX_RATING_SCALE) {
            throw new Error('Rating scales can only be added up to six scales')
        }

        entity.scales.push(defaultRatingScale())
        this.update(entity)
    }

    static updateScaleName(id: string, index: number, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.scales)
        entity.scales[index].nameI18N[locale] = nextValue
        if (locale === Locale.en_US) {
            entity.scales[index].name = nextValue
        }
        this.update(entity)
    }

    static updateScaleScore(id: string, index: number, nextValue: number) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.scales)
        entity.scales[index].score = nextValue
        this.update(entity)
    }

    static deleteScale(id: string, index: number) {
        const entity = this.getEntity(id)
        this.checkArrayBounds(index, entity.scales)
        entity.scales.splice(index, 1)
        this.update(entity)
    }
}
