import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    defaultInformationImage,
    defaultInformationImagesItemDTO,
    InformationImagesItemDTO,
} from 'src/models/dto/items/InformationImagesItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { Locale } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.InformationImages

export class InformationImagesHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => InformationImagesHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): InformationImagesItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as InformationImagesItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): InformationImagesItemDTO {
        return defaultInformationImagesItemDTO()
    }

    private static update(entity: InformationImagesItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as InformationImagesItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as InformationImagesItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as InformationImagesItemDTO)
    }

    static updateImage(id: string, change: Change<Medias | undefined>, imageIndex: number) {
        const entity = this.getEntity(id)
        const { media } = entity.images[imageIndex]
        entity.images[imageIndex].media = applyChange(media, change)
        this.update(entity)
    }

    static updateInformationTitle(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.informationTitle[locale] = nextValue
        this.update(entity)
    }

    static updateImageLabel(id: string, imageIndex: number, labelValue: string, locale: Locale) {
        const entity = this.getEntity(id)
        entity.images[imageIndex].label[locale] = labelValue
        this.update(entity)
    }

    static deleteImage(id: string, index: number) {
        const entity = this.getEntity(id)
        entity.images.splice(index, 1)
        this.update(entity)
    }

    static addNewInformationImage(id: string) {
        const entity = this.getEntity(id)
        entity.images.push(defaultInformationImage())
        this.update(entity)
    }

    private static moveImage(id: string, fromIndex: number, toIndex: number) {
        const entity = this.getEntity(id)

        const image = entity.images[fromIndex]
        const temp = entity.images.filter((imageDTO, index) => index !== fromIndex)

        const newImagesList = [...temp.slice(0, toIndex), image, ...temp.slice(toIndex)]

        this.update({ ...entity, images: newImagesList })
    }

    static moveImageDown(id: string, itemIndex: number) {
        this.moveImage(id, itemIndex, itemIndex + 1)
    }

    static moveImageUp(id: string, itemIndex: number) {
        this.moveImage(id, itemIndex, itemIndex - 1)
    }
}
