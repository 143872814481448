import * as qs from 'querystring'
import { ParsedUrlQueryInput } from 'querystring'

import { AxiosError } from 'axios'
import { validate as isValidUUID } from 'uuid'

import { APP_CONFIG } from 'src/config.app'
import { MediaMetadataDTO } from 'src/models/media/MediaMetadataDTO'
import {
    MediaValErrorResponseThrowable,
    MediaValidationErrorMessage,
} from 'src/models/media/MediaValidationError'
import { ResponseBodyType } from 'src/models/ResponseBodyType'
import { Authenticator } from 'src/services/Authenticator'
import { ADAxios, ApiActionNames, ErrorMessages } from 'src/services/AxiosInterceptor'
import { S3FileState } from 'src/services/media/LocalMediaFilesService'
import { downloadFileFromS3 } from 'src/services/media/S3FileHandler'
import { UserMediaInfo } from 'src/services/media/UserMediaService'

export type MediaAxiosError = AxiosError<{
    mediaMetadataValidationErrorMessages: MediaValidationErrorMessage[]
    searchmediaMetadataRequestValidationErrorMessages: MediaValidationErrorMessage[]
}>

export interface MediaSearchRequest {
    filename: string
    author: string
    date: string
    fileType: string
    tags: string[]
    page: string
}

export interface SearchResponse {
    metadataList: MediaMetadataDTO[]
    page: number
    totalPages: number
    totalMatches: number
}

export class MediaService {
    // eslint-disable-next-line no-magic-numbers
    static readonly VALIDATION_ERROR_STATUS_CODE = 422
    static readonly UPLOAD_LINK = `${APP_CONFIG.backendAPIV1BaseUrl}/upload-file-metadata`
    static readonly SEARCH_LINK = `${APP_CONFIG.backendAPIV1BaseUrl}/search-media-metadata`

    private currentSearchRequest: MediaSearchRequest

    constructor(currentRequest: MediaSearchRequest) {
        this.currentSearchRequest = currentRequest
    }

    setCurrentRequest(value: MediaSearchRequest) {
        this.currentSearchRequest = value
    }

    getCurrentRequest() {
        return this.currentSearchRequest
    }

    public static async uploadMediaMetadata(media: UserMediaInfo) {
        try {
            const { data } = await ADAxios.post(
                MediaService.UPLOAD_LINK,
                JSON.stringify({
                    tags: media.assessmentMedia.tags,
                    filename: media.assessmentMedia.mediaFile,
                    s3Path: media.assessmentMedia.relativePath,
                    fileType: media.assessmentMedia.mimeType,
                    author: media.assessmentMedia.author,
                    date: media.assessmentMedia.date,
                }),
                {
                    apiActionName: ApiActionNames.UploadMedia,
                }
            )
            return data
        } catch (e: unknown) {
            media.s3State = S3FileState.NotUploaded
            if (
                (e as AxiosError | undefined)?.response?.status ===
                MediaService.VALIDATION_ERROR_STATUS_CODE
            ) {
                throw new MediaValErrorResponseThrowable({
                    responseBodyType: ResponseBodyType.ValidationError,
                    mediaValidationErrorMessages: (e as MediaAxiosError).response?.data
                        ?.mediaMetadataValidationErrorMessages,
                })
            }
            console.error('Media upload error', e)
            throw new Error(ErrorMessages.GENERAL_MEDIA_UPLOAD_ERROR)
        }
    }

    public static async searchMediaMetadata(mediaSearch: MediaSearchRequest) {
        try {
            const { data } = await ADAxios.get(this.SEARCH_LINK, {
                apiActionName: ApiActionNames.SearchMedia,
                params: {
                    date: mediaSearch.date,
                    author: mediaSearch.author,
                    tags: mediaSearch.tags,
                    fileType: mediaSearch.fileType?.split('/')[1],
                    page: mediaSearch.page,
                    ...(isValidUUID(mediaSearch.filename)
                        ? { fileId: mediaSearch.filename }
                        : { filename: mediaSearch.filename }),
                },
                paramsSerializer: (params?: ParsedUrlQueryInput) => qs.stringify(params),
            })

            return data as SearchResponse
        } catch (e: unknown) {
            if (
                (e as AxiosError | undefined)?.response?.status ===
                MediaService.VALIDATION_ERROR_STATUS_CODE
            ) {
                throw new MediaValErrorResponseThrowable({
                    responseBodyType: ResponseBodyType.ValidationError,
                    mediaValidationErrorMessages: (e as MediaAxiosError).response?.data
                        ?.searchmediaMetadataRequestValidationErrorMessages,
                })
            }
            console.error('Media search error', e)
            throw new Error(ErrorMessages.GENERAL_MEDIA_SEARCH_ERROR)
        }
    }

    static getDefaultAuthor() {
        try {
            return (Authenticator.auth.getUsername() || '').replace(/^FederateOIDC_/, '')
        } catch (e: unknown) {
            console.error('getDefaultAuthor error', e)
            return ''
        }
    }

    public static async getFromS3(path: string, type?: string) {
        try {
            const result = await downloadFileFromS3(path).then((response) => {
                let file: File
                if (type) {
                    file = new File([response?.Body as ArrayBufferView], 'test', {
                        type: type,
                    })
                } else {
                    file = new File([response?.Body as ArrayBufferView], 'test')
                }
                return URL.createObjectURL(file)
            })
            if (result) {
                return result
            }
        } catch (e: unknown) {
            throw new Error(`Getting the file from s3 failed: ${(e as Error).message}`)
        }

        throw new Error('Error getting the file from S3')
    }
}

export class MediaServiceFactory {
    public static mediaService: MediaService

    public static loadExisting(): MediaService {
        if (!MediaServiceFactory.mediaService) {
            MediaServiceFactory.mediaService = MediaServiceFactory.fromScratch()
        }
        return MediaServiceFactory.mediaService
    }

    public static fromScratch() {
        return new MediaService({
            page: '1',
            filename: '',
            author: '',
            date: '',
            fileType: '',
            tags: [],
        })
    }
}
