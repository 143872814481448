import React, { useMemo } from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { Text } from '@amzn/stencil-react-components/text'

import { DropdownButton } from 'src/components/DropdownButton'
import { APP_CONFIG, STAGE } from 'src/config.app'
import { ModuleBuilderEventKeys, publishUserEventMetrics, UserEventMethodNames } from 'src/metrics'
import {
    EDITABLE_ITEM_TYPES,
    EXCLUDED_FROM_PRODUCTION_ITEM_TYPES,
    getItemTitle,
    ItemType,
} from 'src/models/dto/items/ItemDTO'
import { StaticContentLayoutType } from 'src/models/dto/items/StaticContentLayoutDTO'
import { endOfPageFocusTargetId, focusOnId, itemFocusId } from 'src/pages/module-builder/focus'
import { ActivityDropDown } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'

interface EndOfPageCardProps {
    numberOfItems: number
    pageIndex: number
    moduleEntityId: string
    workflowEntityId: string
    hasVirtualMeeting: boolean
    editDisabled?: boolean
}

const EndOfPageCardToExport = React.memo((props: EndOfPageCardProps) => {
    const { matches } = useBreakpoints()

    const firstItemFocusId = itemFocusId(props.pageIndex + 1, props.numberOfItems + 1)
    const dropdownValues = useMemo(() => {
        const tempList = [...EDITABLE_ITEM_TYPES, ...Object.values(StaticContentLayoutType)]
        // this is for adding items that are not ready for release on prod
        if ([STAGE.BETA, STAGE.LOCAL, STAGE.GAMMA].includes(APP_CONFIG.stage)) {
            tempList.push(...EXCLUDED_FROM_PRODUCTION_ITEM_TYPES)
        }
        return Object.values(tempList)
            .filter((itemType) => {
                // If a virtual meeting item has already been added to the page, filter it so it does not
                // appear in the add content dropdown as there can only be one in a page.
                if (props.hasVirtualMeeting) {
                    return itemType !== ItemType.VirtualMeeting
                } else {
                    return true
                }
            })
            .map((itemType) => {
                return {
                    name: getItemTitle(itemType),
                    dataTestId: `add-${itemType}-option`,
                    onClick: () => {
                        const itemDTO = ItemEntityService.create(itemType)
                        publishUserEventMetrics(
                            UserEventMethodNames.ModuleBuilder,
                            ModuleBuilderEventKeys.AddContent
                        )
                        publishUserEventMetrics(
                            UserEventMethodNames.ModuleBuilder,
                            ModuleBuilderEventKeys.AddContent + `:${itemType}`
                        )

                        // If added item type is virtual meeting, put it at top of page and focus there
                        // Otherwise, add new item type to bottom of page and focus there
                        if (itemType !== ItemType.VirtualMeeting) {
                            ItemGroupHandler.addItem(props.workflowEntityId, itemDTO.id)
                            focusOnId(firstItemFocusId)
                        } else {
                            ItemGroupHandler.addItem(props.workflowEntityId, itemDTO.id, 0)
                            focusOnId(itemFocusId(props.pageIndex + 1, 1))
                        }
                    },
                }
            })
    }, [props.hasVirtualMeeting, props.workflowEntityId, props.pageIndex, firstItemFocusId])

    const title =
        props.numberOfItems === 0 ? 'No content yet!' : 'Add some more item types or pages.'

    const addContentAriaLabel = `Add item in position ${props.numberOfItems + 1}, page ${
        props.pageIndex + 1
    }`

    return (
        <>
            <Spacer height='S400' />
            <Card>
                <Row justifyContent='space-between' width='100%'>
                    <Col flex={'1 1 0%'}>
                        <Text fontSize='T400'>
                            <h4>
                                <strong>{title}</strong>
                            </h4>
                        </Text>
                        <Text color='neutral70'>
                            Add some item types or more pages to get started.
                        </Text>
                        {matches.s && (
                            <>
                                <Spacer height={'S400'} />
                                {
                                    <ActivityDropDown
                                        dataTestId={`end-of-page-${props.pageIndex}-add-new-page-button`}
                                        moduleEntityId={props.moduleEntityId}
                                        pageIndex={props.pageIndex + 1}
                                        editDisabled={props.editDisabled}
                                    />
                                }
                                <Spacer height={'S400'} />
                                <DropdownButton
                                    dataTestId={'add-item-button'}
                                    title='Add content'
                                    values={dropdownValues}
                                    ariaLabel={addContentAriaLabel}
                                    disabled={props.editDisabled}
                                />
                            </>
                        )}
                    </Col>
                    {matches.s ? null : (
                        <Row alignItems={'center'}>
                            <Spacer width={'S400'} />
                            <ActivityDropDown
                                dataTestId={`end-of-page-${props.pageIndex}-add-new-page-button`}
                                moduleEntityId={props.moduleEntityId}
                                pageIndex={props.pageIndex + 1}
                                editDisabled={props.editDisabled}
                            />
                            <Spacer width={'S400'} />
                            <DropdownButton
                                dataTestId={'add-item-button'}
                                id={endOfPageFocusTargetId(props.pageIndex + 1)}
                                title='Add content'
                                values={dropdownValues}
                                variant={ButtonVariant.Primary}
                                ariaLabel={addContentAriaLabel}
                                disabled={props.editDisabled}
                            />
                        </Row>
                    )}
                </Row>
            </Card>
            <Spacer height='S400' />
        </>
    )
})

EndOfPageCardToExport.displayName = 'EndOfPageCard'

export const EndOfPageCard = EndOfPageCardToExport
