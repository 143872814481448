import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface ChatHeader {
    headerStringI18N: LocalizedAttribute<string>
}

export interface ChatMessage {
    messageHeaderI18N: LocalizedAttribute<string>
    messageProfileImageRole?: string
    messageI18N: LocalizedAttribute<string>
}

export interface ChatItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.Chat
    chatHeader?: ChatHeader
    chatMessages: ChatMessage[]
}

export const defaultChatItemDTO = () => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.Chat,
        ppt: ItemType.Chat,
        chatHeader: {
            headerStringI18N: LocalizeDefault(''),
        },
        chatMessages: [
            {
                messageHeaderI18N: LocalizeDefault(''),
                messageI18N: LocalizeDefault(''),
            } as ChatMessage,
        ],
    } as ChatItemDTO
}
