import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonIconPosition, ButtonVariant } from '@amzn/stencil-react-components/button'
import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { InputWrapper } from '@amzn/stencil-react-components/form'
import { IconDownload } from '@amzn/stencil-react-components/icons'
import { Col, Container, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { FileValidationOutputTable } from 'src/components/scoring-testing-v2/FileValidationOutputTable'
import { ScoringTestingBreadCrumbs } from 'src/components/scoring-testing-v2/ScoringTestingBreadCrumbs'
import { StageSelection } from 'src/components/scoring-testing-v2/StageSelection'
import { WorkflowSearchField } from 'src/components/scoring-testing-v2/WorkflowSearchField'
import { APP_CONFIG, STAGE } from 'src/config.app'
import { uploadFileToS3 } from 'src/services/hook/HookFileService'
import {
    HookError,
    ScoringTestingV2Service,
    ScoringTestingV2ValidationObject,
} from 'src/services/hook/ScoringTestingV2Service'
import { XlsxFileParser } from 'src/services/parser/XlsxFileParser'

export const STORAGE_STAGE_NAME = 'hookStage'
let timer: number

export const ScoringTestingV2Landing = () => {
    const [workflowId, setWorkflowId] = useState<string | undefined>(undefined)
    const [fileName, setFileName] = useState<string | undefined>(undefined)
    const [fileValidationOutput, setFileValidationOutput] = useState<
        ScoringTestingV2ValidationObject[] | undefined
    >(undefined)
    const [submittingValidation, setSubmittingValidation] = useState<boolean>(false)
    const [missingWorkflowId, setMissingWorkflowId] = useState<boolean>(false)
    const [missingFileName, setMissingFileName] = useState<boolean>(false)
    const [apiError, setApiError] = useState<string | undefined>(undefined)
    const [generationError, setGenerationError] = useState<string | undefined>(undefined)
    const [selectedStage, setSelectedStage] = useState<STAGE>(
        (sessionStorage.getItem(STORAGE_STAGE_NAME) as STAGE) || APP_CONFIG.stage
    )
    const [resetFileUpload, setResetFileUpload] = useState<number>(0)

    const navigate = useNavigate()

    const INPUT_MEDIA_PATH = 'input'
    const INPUT_DEBOUNCE_TIME = 2000

    useEffect(() => {
        if (workflowId) {
            setMissingWorkflowId(false)
        }
        if (fileName) {
            setMissingFileName(false)
        }
    }, [fileName, workflowId])

    const validateInputFields = () => {
        workflowId ? setMissingWorkflowId(false) : setMissingWorkflowId(true)
        fileName ? setMissingFileName(false) : setMissingFileName(true)
        return workflowId && fileName
    }

    const clearForm = () => {
        setWorkflowId(undefined)
        setFileName(undefined)
        setFileValidationOutput(undefined)
        setMissingFileName(false)
        setMissingWorkflowId(false)
        setApiError(undefined)
        setResetFileUpload(resetFileUpload + 1)
        setGenerationError(undefined)
    }

    const submitForm = async () => {
        if (!validateInputFields()) {
            return
        }
        try {
            setApiError(undefined)
            const scoringResult = await ScoringTestingV2Service.callScoringTestingV2(
                workflowId as string,
                fileName as string,
                selectedStage
            )
            const outputFileName: string = scoringResult
                .split('.xlsx', 2)[0]
                .split('/output/', 2)[1]
            navigate(`/scoring-test-v2/${workflowId as string}/${outputFileName}`)
        } catch (e: unknown) {
            const hookError = e as HookError
            if (hookError.fileValidationInformation) {
                setFileValidationOutput(hookError.fileValidationInformation)
                return
            }
            setApiError(`Something went wrong while running the test: ${hookError.message}`)
        }
    }

    const handleFileValidation = async (valWorkflowId: string, valFileName: string) => {
        try {
            setApiError(undefined)
            setSubmittingValidation(true)
            await ScoringTestingV2Service.callFileValidation(
                valWorkflowId,
                valFileName,
                selectedStage
            )
            setFileValidationOutput(undefined)
        } catch (e) {
            const hookError = e as HookError
            if (hookError.fileValidationInformation) {
                setFileValidationOutput(hookError.fileValidationInformation)
            } else {
                setFileValidationOutput(undefined)
                setApiError(
                    `Something went wrong while running the validation: ${hookError.message}`
                )
            }
        }
        setSubmittingValidation(false)
    }

    const runValidationOnTimer = (currentWorkflowId: string) => {
        timer = window.setTimeout(async () => {
            if (currentWorkflowId && fileName) {
                setSubmittingValidation(true)
                setApiError(undefined)
                await handleFileValidation(currentWorkflowId, fileName)
                setSubmittingValidation(false)
            }
        }, INPUT_DEBOUNCE_TIME)
    }

    const onWorkflowIdChange = (value: string) => {
        setWorkflowId(value)
        clearTimeout(timer)
        runValidationOnTimer(value)
    }

    const uploadFile = async (file: File) => {
        setFileValidationOutput(undefined)
        setApiError(undefined)
        if (file) {
            try {
                await uploadFileToS3(file, `${INPUT_MEDIA_PATH}/${file.name}`, selectedStage)
                setFileName(file.name)
            } catch (e: unknown) {
                setApiError('Something went wrong while uploading the file to hook')
            }
            if (workflowId) {
                await handleFileValidation(workflowId, file.name)
            }
        }
    }

    const handleFileGeneration = async () => {
        setGenerationError(undefined)
        if (!workflowId) {
            setGenerationError('Please enter a valid workflow Id')
            return
        }
        try {
            const allScores = await ScoringTestingV2Service.callGetAllVariationsScores(
                workflowId,
                selectedStage
            )
            XlsxFileParser.saveXlsxWorkBook(
                XlsxFileParser.generateHeaderFile(allScores.allVariationsScores),
                `${workflowId}-header-template`
            )
        } catch (e: unknown) {
            const hookError = e as HookError
            setGenerationError(hookError.message)
        }
    }

    const handleEnvironmentChange = (stage: STAGE) => {
        clearForm()
        sessionStorage.setItem(STORAGE_STAGE_NAME, stage)
        setSelectedStage(stage)
    }

    const customRenderLabel = ({
        htmlFor,
        children,
    }: {
        htmlFor: string
        labelId?: string | undefined
        color: string
        children: ReactNode
    }) => (
        <label htmlFor={htmlFor}>
            <Text fontWeight='bold' fontSize='T200'>
                {children}
            </Text>
        </label>
    )

    return (
        <Container>
            <Col gridGap='S300'>
                <ScoringTestingBreadCrumbs />
                <Col gridGap='S100'>
                    <H2 fontSize='T400'>Automated Scoring Tool</H2>
                    <Text fontSize='T300'>
                        Provide all the required information for the test scenario below
                    </Text>
                </Col>
                <Hr size='wide' />
                {apiError && (
                    <MessageBanner type={MessageBannerType.Error}>{apiError}</MessageBanner>
                )}
                <Col gridGap='S100' padding={{ top: 'S300' }}>
                    <Text fontSize='T300' fontWeight='bold'>
                        Test Details
                    </Text>
                    <Row gridGap='S300' justifyContent='left'>
                        <WorkflowSearchField
                            workflowIdIsEmpty={missingWorkflowId}
                            onValueChange={(value) => onWorkflowIdChange(value)}
                            workflowId={workflowId ?? ''}
                            width={'50%'}
                            stage={selectedStage}
                        />
                        <StageSelection
                            designerEnv={APP_CONFIG.stage}
                            showOnDesignerEnvs={[STAGE.PROD, STAGE.LOCAL]}
                            hookStageOptions={
                                APP_CONFIG.stage === STAGE.PROD
                                    ? [STAGE.GAMMA, STAGE.PROD]
                                    : [STAGE.LOCAL, STAGE.BETA]
                            }
                            selectedHookStage={selectedStage}
                            setSelectedHookStage={handleEnvironmentChange}
                            width={'50%'}
                        />
                    </Row>
                </Col>
                <Row>
                    <Row gridGap='S200' alignItems='center' minWidth='23%'>
                        <Text>Need a header template?</Text>
                        <IconWithTooltip
                            tooltipText='Make sure you put in your workflow Id in above and download your template to start your scoring test.'
                            color='primary70'
                        />
                    </Row>
                    <Row gridGap='S200'>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            onClick={handleFileGeneration}
                            icon={<IconDownload />}
                            iconPosition={ButtonIconPosition.Trailing}
                        >
                            Download Template
                        </Button>
                        {generationError && (
                            <MessageBanner type={MessageBannerType.Error}>
                                {`Something went wrong while generating your headers: ${generationError}`}
                            </MessageBanner>
                        )}
                    </Row>
                </Row>
                <Spacer height='S200' />
                <View width='50%' padding={{ top: 'S100' }} dataTestId='file-upload-view'>
                    <InputWrapper
                        id='file-upload'
                        labelText='Select your file (XLSX only)'
                        renderLabel={customRenderLabel}
                        required
                        error={missingFileName}
                        footer={missingFileName ? 'Please upload a file' : undefined}
                        dataTestId={'file-upload-wrapper'}
                    >
                        {(inputProps) => (
                            <FileUpload
                                {...inputProps}
                                onFileAttached={(files) => uploadFile(files[0])}
                                dragAndDropText='Or just drag and drop xlsx file here'
                                accept={XlsxFileParser.XLSX_FILE_TYPE}
                                errorText='The file upload failed'
                                isMulti={false}
                                key={resetFileUpload}
                            />
                        )}
                    </InputWrapper>
                </View>
                {fileValidationOutput && (
                    <FileValidationOutputTable fileValidationOutput={fileValidationOutput} />
                )}
                {submittingValidation && (
                    <Row gridGap='S200'>
                        <Spinner size={SpinnerSize.Large} />
                        <Text>Running file validation...</Text>
                    </Row>
                )}
                <Row gridGap='S200' padding={{ top: 'S500' }}>
                    <Button onClick={clearForm}>Clear form</Button>
                    <Button variant={ButtonVariant.Primary} onClick={submitForm}>
                        Submit
                    </Button>
                </Row>
            </Col>
        </Container>
    )
}
