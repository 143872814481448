import { cloneDeep, isUndefined } from 'lodash'
import { v4 } from 'uuid'

import { ItemGroupEntity } from 'src/models/dto/activities/ItemGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    ConditionalBranchingLogicDTO,
    ConditionalBranchingType,
    ItemResponseTriggersDTO,
} from 'src/models/dto/ConditionalBranchingLogicDTO'
import { ItemDTO } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { STORE_ACTION } from 'src/services/Store'
import { activity, ActivityEntityService, factories } from '../ActivityEntityService'
import { ItemEntityService } from '../ItemEntityService'

const activityType = ActivityType.LaunchItemGroup

export class ItemGroupHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(activityType, () => ItemGroupHandler.create())
    }

    static get(entityId: string): ItemGroupEntity {
        return ActivityEntityService.get(entityId) as ItemGroupEntity
    }

    /**
     *
     * @returns an ItemGroupEntity but does not add it to the Store
     */
    static create(): ItemGroupEntity {
        const newActivity = activity()

        return {
            ...newActivity,
            name: newActivity.id,
            description: '',
            ppt: ActivityType.LaunchItemGroup,
            type: ActivityType.LaunchItemGroup,
            itemIds: [] as string[],
            randomizationEnabled: false,
        }
    }

    static update(payload: ItemGroupEntity) {
        ActivityEntityService.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: payload.id,
            payload: payload,
        })
    }

    static updateDescription(entityId: string, description: string) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            description,
        }

        this.update(payload)
    }

    static updateLabels(entityId: string, labels: string[]) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            labels,
        }

        this.update(payload)
    }

    static updateRandomization(entityId: string, randomizationEnabled: boolean) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            randomizationEnabled,
        }
        this.update(payload)
    }

    static addBranchingConditions(entityId: string) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            branchingConditions: {
                itemConditions: [
                    {
                        itemLabel: '',
                        responseLabels: [''],
                    },
                ],
                branchingType: ConditionalBranchingType.OR,
            },
        }
        this.update(payload)
    }

    static removeBranchingConditions(entityId: string) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            branchingConditions: undefined,
        }

        this.update(payload)
    }

    static updateBranchingType(entityId: string, type) {
        const entity = this.get(entityId)

        const branchingConditions: ConditionalBranchingLogicDTO = {
            ...entity.branchingConditions,
            branchingType: type,
        } as ConditionalBranchingLogicDTO

        const payload: ItemGroupEntity = {
            ...entity,
            branchingConditions: branchingConditions,
        }

        this.update(payload)
    }

    static updateConditionalBranchingItemLabel(entityId: string, index: number, itemLabel: string) {
        const entity = this.get(entityId)

        const itemConditions: ItemResponseTriggersDTO[] = {
            ...entity.branchingConditions?.itemConditions,
        } as ItemResponseTriggersDTO[]

        itemConditions[index].itemLabel = itemLabel

        this.update(entity)
    }

    static updateConditionalBranchingItemResponseLabels(
        entityId: string,
        index: number,
        responseLabels: string[]
    ) {
        const entity = this.get(entityId)

        const itemConditions: ItemResponseTriggersDTO[] = {
            ...entity.branchingConditions?.itemConditions,
        } as ItemResponseTriggersDTO[]

        itemConditions[index].responseLabels = responseLabels

        this.update(entity)
    }

    static deleteItemResponseLabels(entityId: string, index: number) {
        const entity = this.get(entityId)

        const itemConditions: ItemResponseTriggersDTO[] =
            entity.branchingConditions?.itemConditions.filter((t, i) => i !== index) ?? []

        const payload: ItemGroupEntity = {
            ...entity,
            branchingConditions: {
                ...entity.branchingConditions,
                itemConditions,
            } as ConditionalBranchingLogicDTO,
        }

        this.update(payload)
    }

    static addItemResponseLabel(entityId: string) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            branchingConditions: {
                ...entity.branchingConditions,
                itemConditions: [
                    ...(entity.branchingConditions?.itemConditions ?? []),
                    {
                        itemLabel: '',
                        responseLabels: [''],
                    } as ItemResponseTriggersDTO,
                ],
            } as ConditionalBranchingLogicDTO,
        }

        this.update(payload)
    }

    static updateItems(entityId: string, itemIds: string[]) {
        const entity = this.get(entityId)

        const payload: ItemGroupEntity = {
            ...entity,
            itemIds,
        }

        this.update(payload)
    }

    static removeFromItems(entityId: string, itemIndex: number) {
        const entity = this.get(entityId)
        const payload: ItemGroupEntity = {
            ...entity,
            itemIds: entity.itemIds.filter((t, i) => i !== itemIndex),
        }
        this.update(payload)
    }

    static addItem(workflowEntityId: string, itemVersionId: string, position?: number) {
        const entity = this.get(workflowEntityId)
        if (isUndefined(position) || position > entity.itemIds.length) {
            this.updateItems(workflowEntityId, [...entity.itemIds, itemVersionId])
        } else {
            this.updateItems(workflowEntityId, [
                ...entity.itemIds.slice(0, position),
                itemVersionId,
                ...entity.itemIds.slice(position),
            ])
        }
    }

    static removeItem(workflowEntityId: string, itemVersionId: string) {
        const entity = this.get(workflowEntityId)
        this.updateItems(
            workflowEntityId,
            entity.itemIds.filter((id) => id !== itemVersionId)
        )
    }

    static duplicate(workflowEntityId: string) {
        const duplicatedWorkflowEntity = cloneDeep(this.get(workflowEntityId))
        duplicatedWorkflowEntity.id = v4()

        const duplicatedItems: ItemDTO[] = ItemEntityService.duplicateItems(
            duplicatedWorkflowEntity.itemIds
        )
        duplicatedWorkflowEntity.itemIds = duplicatedItems.map((i) => i.id)
        return duplicatedWorkflowEntity
    }

    /**
     * Moves an item entity id up
     * @param itemGroupId
     * @param itemEntityId
     */
    public static moveUp(itemGroupId: string, itemEntityId: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(itemGroupId)

        const currentIndex = itemGroupEntity.itemIds.indexOf(itemEntityId)

        if (currentIndex > 0) {
            this.update({
                ...itemGroupEntity,
                itemIds: this.moveItemId(itemGroupEntity.itemIds, itemEntityId, currentIndex - 1),
            })
        }
    }

    public static moveDown(itemGroupId: string, itemEntityId: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(itemGroupId)

        const currentIndex = itemGroupEntity.itemIds.indexOf(itemEntityId)

        if (currentIndex < itemGroupEntity.itemIds.length - 1) {
            this.update({
                ...itemGroupEntity,
                itemIds: this.moveItemId(itemGroupEntity.itemIds, itemEntityId, currentIndex + 1),
            })
        }
    }

    private static moveItemId(itemIds: string[], itemId: string, toIndex: number) {
        const next = itemIds.filter((i) => i !== itemId)

        return [...next.slice(0, toIndex), itemId, ...next.slice(toIndex)]
    }

    static updateName(entityId: string, name: string) {
        this.update({
            ...this.get(entityId),
            name,
        })
    }

    static updateHeader(entityId: string, locale: Locale, pageHeader: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(entityId)
        // initialize header if it does not exist on the item group
        if (!itemGroupEntity.headerI18N) itemGroupEntity.headerI18N = LocalizeDefault<string>('')
        itemGroupEntity.headerI18N[locale] = pageHeader
        this.update(itemGroupEntity)
    }

    static updateBody(entityId: string, locale: Locale, pageBody: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(entityId)
        // initialize body if it does not exist on the item group
        if (!itemGroupEntity.bodyI18N) itemGroupEntity.bodyI18N = LocalizeDefault<string>('')
        itemGroupEntity.bodyI18N[locale] = pageBody
        this.update(itemGroupEntity)
    }

    static setContextBoxId(itemGroupId: string, contextBoxId: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(itemGroupId)
        this.update({
            ...itemGroupEntity,
            contextBoxId: contextBoxId,
        })
    }

    static removeContextBox(itemGroupId: string) {
        const itemGroupEntity: ItemGroupEntity = this.get(itemGroupId)
        this.update({
            ...itemGroupEntity,
            contextBoxId: undefined,
        })
    }
}
