import React, { useCallback, useEffect, useState } from 'react'

import { Col, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'

import { SmartExpanderContent } from 'src/components/SmartExpander'
import { useBucketsAndCupsGroupEntity } from 'src/hooks/useBucketsAndCupsGroup'
import { useEntity } from 'src/hooks/useEntity'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { BucketsAndCupsGroupEditor } from 'src/pages/module-builder/buckets-and-cups-editor/BucketsAndCupsGroupEditor'
import { activityDisplayNameMap } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { PageEditorProps } from 'src/pages/module-builder/page-editor/PageEditor'
import { PageHeader } from 'src/pages/module-builder/page-editor/PageHeader'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const BucketsAndCupsGroupPage = ({
    pageIndex,
    moduleEntityId,
    workflowEntityId,
    isLastPage,
    showValidationError,
    defaultExpanded,
    locale,
    editDisabled,
    moduleStatus,
}: PageEditorProps) => {
    if (!ValidationErrorEntityService.has(workflowEntityId)) {
        ValidationErrorEntityService.create(workflowEntityId)
    }

    const { entity } = useBucketsAndCupsGroupEntity({
        workflowEntityId: workflowEntityId,
        moduleEntityId: moduleEntityId,
    })

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [showError, setShowError] = useState(showValidationError)

    useEffect(() => {
        setShowError(showValidationError ?? false)
    }, [showValidationError])

    const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? true)

    const onPageHeaderClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded, setIsExpanded])

    useEffect(() => {
        setShowError(showValidationError ?? false)
    }, [showValidationError])

    return (
        <Col gridGap='S300' dataTestId={`page-editor-${pageIndex}`}>
            <Spacer height='S100' />
            <PageHeader
                isLastPage={isLastPage}
                moduleEntityId={moduleEntityId}
                workflowEntityId={workflowEntityId}
                isExpanded={isExpanded}
                pageIndex={pageIndex}
                onclick={onPageHeaderClick}
                activityName={activityDisplayNameMap.get(entity.type)}
                showValidationError={showError}
                onErrorCheck={setShowError}
                editDisabled={editDisabled}
                runErrorCheckDisabled
                moduleStatus={moduleStatus}
            />
            <SmartExpanderContent isExpanded={isExpanded}>
                {() => (
                    <>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showError && (
                            <MessageBanner
                                type={MessageBannerType.Error}
                                dataTestId={'generic-errors'}
                            >
                                <ul>
                                    {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                        (message, index) => (
                                            <li key={index}>{message}</li>
                                        )
                                    )}
                                </ul>
                            </MessageBanner>
                        )}
                        <BucketsAndCupsGroupEditor
                            moduleEntityId={moduleEntityId}
                            workflowEntityId={workflowEntityId}
                            showValidationErrors={!!showValidationError}
                            locale={locale}
                            editDisabled={editDisabled}
                        />
                        <Spacer height='S400' />
                    </>
                )}
            </SmartExpanderContent>
        </Col>
    )
}
