import { PagePatternTag } from 'src/models/dto/activities/BucketsAndCupsGroupDTO'

const ws = '\\s*'
const tag = '[^{},\\s]+'
const separator = `(?:${ws},${ws}|\\s+)`
const commaSeparated = `${tag}(?:${separator}${tag}(?:${separator})?)*`
const openBrace = `${ws}\\{${ws}`
const closeBrace = `${ws}\\}${ws}`
const redundantSeparator = new RegExp('^' + separator)
const pagePatternTag = new RegExp(
    `^${ws}(${tag})(?:${openBrace}(${commaSeparated})${closeBrace})?(?:${separator}|${ws}$)`
)

export function parsePagePatternTags(tags: string): PagePatternTag[] | null {
    // Example: tag1, tag2{sub1, sub2}, tag3
    const parts: PagePatternTag[] = []
    while (tags) {
        const match = pagePatternTag.exec(tags)
        if (!match || !match[0].trim()) {
            const matchSkip = redundantSeparator.exec(tags)
            if (!matchSkip) {
                // Parse error
                break
            }
            tags = tags.substring(matchSkip[0].length)
            continue
        }

        if (typeof match[2] === 'undefined') {
            parts.push({ tag: match[1], subTags: [] })
        } else {
            parts.push({
                tag: match[1],
                subTags: match[2]
                    .split(',')
                    .map((t) => t.trim())
                    .filter((t) => !!t),
            })
        }

        tags = tags.substring(match[0].length)
    }

    if (tags.length) {
        return null
    }

    return parts
}

export function pagePatternTagsToString(pagePatternTags: PagePatternTag[]): string {
    return pagePatternTags
        .map((t) => {
            if (!t.subTags.length) {
                return t.tag
            } else {
                return t.tag + '{' + t.subTags.join(', ') + '}'
            }
        })
        .join(', ')
}
