// Moved to separate file to avoid circular dependency conflict
export enum MediaFileType {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WAV = 'audio/wav',
    MP3 = 'audio/mpeg',
    MP4 = 'video/mp4',
    MOV = 'video/quicktime',
}
