import { ContentItemDTO, defaultContentItemDTO } from 'src/models/dto/items/ContentItemDTO'
import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { factories, ITEM_ENTITY_STORE_SELECTOR } from '../ItemEntityService'
import { Store, STORE_ACTION, Stores } from './../../Store'

const itemType = ItemType.Content

export class ContentHandler {
    static init() {
        // add the create function to the set of factories available in ItemEntityService
        factories.set(itemType, () => ContentHandler.create())
    }

    private static store() {
        return Stores.get(ITEM_ENTITY_STORE_SELECTOR) as Store<ItemDTO>
    }

    private static getEntity(id: string): ContentItemDTO {
        const store = this.store()
        if (store.has(id)) {
            return store.get(id) as ContentItemDTO
        } else {
            throw new Error(`entity ${id} does not exist in ${ITEM_ENTITY_STORE_SELECTOR}`)
        }
    }

    static create(): ContentItemDTO {
        return defaultContentItemDTO()
    }

    private static update(entity: ContentItemDTO) {
        this.store().dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.id,
            payload: entity,
        })
    }

    static updateLabel(id: string, nextValue: string) {
        this.update({
            ...this.getEntity(id),
            label: nextValue,
        } as ContentItemDTO)
    }

    static updateOptional(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            optional: nextValue,
        } as ContentItemDTO)
    }

    static updatePreserveOrder(id: string, nextValue: boolean) {
        this.update({
            ...this.getEntity(id),
            preserveOrder: nextValue,
        } as ContentItemDTO)
    }

    static updateText(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        entity.textI18N[locale] = nextValue
        this.update(entity)
    }

    static updateTitle(id: string, locale: Locale, nextValue: string) {
        const entity = this.getEntity(id)
        if (!entity.titleI18N) entity.titleI18N = LocalizeDefault<string>('')
        entity.titleI18N[locale] = nextValue
        this.update(entity)
    }
}
