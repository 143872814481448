import React, { useEffect, useState } from 'react'

import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Hr, Row } from '@amzn/stencil-react-components/layout'
import { Text } from '@amzn/stencil-react-components/text'

import { JobMetadataSelect } from 'src/components/module-groups/JobMetadataSelect'
import { ModuleGroupRenderOption } from 'src/components/module-groups/ModuleGroupRenderOption'
import { PECValidationDTO } from 'src/models/dto/module-groups/ModuleGroupValidations'
import {
    FunctionalScoreDTO,
    RuleDTO,
    RuleSubType,
    RuleType,
} from 'src/models/dto/module-groups/PECGroupDTO'
import { ModuleGroupUtility } from 'src/pages/module-groups/ModuleGroupUtility'

export interface PECModuleGroupAddModuleProps {
    pecValidations?: PECValidationDTO
    setPecValidations?: (pecValidations: PECValidationDTO) => void
    moduleVersionId?: string
    oldModuleVersionId?: string
    moduleScores: string[]
    functionalScores?: FunctionalScoreDTO[]
    rules: RuleDTO[]
    setRules: (rules: RuleDTO[]) => void
    setFunctionalScore: (functionalScore: FunctionalScoreDTO, index: number) => void
}

export const PECModuleGroupAddModule = (props: PECModuleGroupAddModuleProps) => {
    const [selectedModuleScores, setSelectedModuleScores] = useState<string[]>([])

    useEffect(() => {
        const alreadySelectedScores: string[] | undefined =
            props.moduleVersionId &&
            props.functionalScores &&
            props.functionalScores.length > 0 &&
            props.functionalScores.some((functionalScore) =>
                functionalScore.moduleVersionScoreLabelMap.get(props.moduleVersionId as string)
            )
                ? props.functionalScores?.map(
                      (functionalScore) =>
                          functionalScore.moduleVersionScoreLabelMap.get(
                              props.moduleVersionId as string
                          ) as string
                  )
                : undefined
        if (alreadySelectedScores) {
            setSelectedModuleScores(alreadySelectedScores)
        } else {
            setSelectedModuleScores([])
        }
    }, [props.functionalScores, props.moduleVersionId])

    useEffect(() => {
        if (
            props.moduleVersionId &&
            props.oldModuleVersionId &&
            props.oldModuleVersionId !== props.moduleVersionId &&
            props.functionalScores &&
            props.functionalScores.length > 0
        ) {
            props.functionalScores.forEach((functionalScore, index) => {
                const oldLabel = functionalScore.moduleVersionScoreLabelMap.get(
                    props.oldModuleVersionId as string
                )
                if (oldLabel && props.moduleScores.includes(oldLabel)) {
                    functionalScore.moduleVersionScoreLabelMap.set(
                        props.moduleVersionId as string,
                        oldLabel
                    )
                    props.setFunctionalScore(functionalScore, index)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.moduleScores])

    const onModuleScoreSelect = (
        moduleScore: string,
        functionalScore: FunctionalScoreDTO,
        index: number
    ) => {
        if (props.moduleVersionId && props.functionalScores) {
            const updatedFunctionalScore = {
                ...functionalScore,
                moduleVersionScoreLabelMap: functionalScore.moduleVersionScoreLabelMap.set(
                    props.moduleVersionId,
                    moduleScore
                ),
            } as FunctionalScoreDTO
            props.setFunctionalScore(updatedFunctionalScore, index)
        }
    }

    const addJobMetadataCategories = (categories: RuleType[]) => {
        const updatedRules = props.rules.filter((rule) => categories.includes(rule.type))
        const newCategories = categories.filter(
            (category) => !updatedRules.some((rule) => rule.type === category)
        )
        const addedRules = newCategories.map((category) => ({
            type: category,
            subType: RuleSubType.INCLUDE,
            values: [],
        }))
        props.setRules([...updatedRules, ...addedRules])
    }

    const setRuleSubType = (ruleType: RuleType, subType: RuleSubType) => {
        const updatedRules: RuleDTO[] = props.rules.map((rule) => {
            if (rule.type === ruleType) {
                return {
                    ...rule,
                    subType,
                }
            }
            return rule
        })
        props.setRules(updatedRules)
    }

    const addValuesToRule = (ruleType: RuleType, values: string[]) => {
        const updatedRules: RuleDTO[] = props.rules.map((rule) => {
            if (rule.type === ruleType) {
                return { ...rule, values }
            }
            return rule
        })
        props.setRules(updatedRules)
    }

    return (
        <>
            <Text fontWeight='bold'>Presentation Equivalent Configuration</Text>
            <InputWrapper
                id='metadata-categories-wrapper'
                labelText='Metadata Categories'
                error={!!props.pecValidations?.metadataCategories}
                footer={
                    props.pecValidations?.metadataCategories
                        ? props.pecValidations?.metadataCategories
                        : undefined
                }
                required
            >
                {(inputProps) => (
                    <Select
                        dataTestId='metadata-categories-select'
                        {...inputProps}
                        value={props.rules.map((rule) => rule.type)}
                        options={Object.values(RuleType)}
                        onChange={(values: string[]) => {
                            addJobMetadataCategories(values.map((value) => value as RuleType))

                            if (props.setPecValidations) {
                                props.setPecValidations({
                                    ...props.pecValidations,
                                    metadataCategories: '',
                                } as PECValidationDTO)
                            }
                        }}
                        width='70%'
                        isMulti
                    />
                )}
            </InputWrapper>
            <ul>
                {props.rules.map((rule) => (
                    <li key={rule.type}>
                        <Row gridGap='S200'>
                            <JobMetadataSelect
                                ruleType={rule.type}
                                ruleSubType={rule.subType}
                                setValues={addValuesToRule}
                                setRuleSubType={setRuleSubType}
                                editSelectedValues={rule.values}
                                pecValidations={props.pecValidations}
                                setPecValidations={props.setPecValidations}
                            />
                        </Row>
                    </li>
                ))}
            </ul>
            {props.functionalScores &&
                props.functionalScores.length > 0 &&
                !ModuleGroupUtility.isSkippedModule(props.moduleVersionId) && (
                    <>
                        <Hr size='wide' />
                        <Text fontWeight='bold'>Functional Equivalent Configuration</Text>
                        <ul>
                            <Col>
                                {props.functionalScores.map((functionalScore, index) => (
                                    <li key={functionalScore.functionalScoreLabel}>
                                        <Col gridGap='S300'>
                                            <InputWrapper
                                                id={`functional-${index}-wrapper`}
                                                labelText='Functional Score Equivalency'
                                            >
                                                {(inputProps) => (
                                                    <Input
                                                        {...inputProps}
                                                        value={functionalScore.functionalScoreLabel}
                                                        disabled={true}
                                                    />
                                                )}
                                            </InputWrapper>
                                            <InputWrapper
                                                id={`functional-${index}-select`}
                                                labelText='Module Score'
                                                error={
                                                    !!props.pecValidations?.functionalEquivalency?.get(
                                                        functionalScore.functionalScoreLabel
                                                    )
                                                }
                                                footer={
                                                    props.pecValidations?.functionalEquivalency?.get(
                                                        functionalScore.functionalScoreLabel
                                                    )
                                                        ? props.pecValidations?.functionalEquivalency?.get(
                                                              functionalScore.functionalScoreLabel
                                                          )
                                                        : undefined
                                                }
                                                required
                                            >
                                                {(inputProps) => (
                                                    <Select
                                                        {...inputProps}
                                                        dataTestId={`functional-${index}-select`}
                                                        options={
                                                            props.moduleScores &&
                                                            props.moduleScores.length > 0
                                                                ? [
                                                                      '',
                                                                      ...props.moduleScores.filter(
                                                                          (moduleScore) =>
                                                                              !selectedModuleScores.includes(
                                                                                  moduleScore
                                                                              )
                                                                      ),
                                                                  ]
                                                                : []
                                                        }
                                                        isSearchable={true}
                                                        onChange={(selectedScore: string) => {
                                                            onModuleScoreSelect(
                                                                selectedScore,
                                                                functionalScore,
                                                                index
                                                            )

                                                            if (
                                                                props.setPecValidations &&
                                                                props.pecValidations?.functionalEquivalency?.get(
                                                                    functionalScore.functionalScoreLabel
                                                                )
                                                            ) {
                                                                const updatedMap =
                                                                    props.pecValidations
                                                                        ?.functionalEquivalency
                                                                updatedMap.set(
                                                                    functionalScore.functionalScoreLabel,
                                                                    ''
                                                                )

                                                                props.setPecValidations({
                                                                    ...props.pecValidations,
                                                                    functionalEquivalency:
                                                                        updatedMap,
                                                                } as PECValidationDTO)
                                                            }
                                                        }}
                                                        placeholder='Select a module Scoring...'
                                                        value={() => {
                                                            return props.moduleVersionId
                                                                ? functionalScore.moduleVersionScoreLabelMap.get(
                                                                      props.moduleVersionId
                                                                  )
                                                                : ''
                                                        }}
                                                        renderOption={ModuleGroupRenderOption}
                                                        noOptionsText={
                                                            props.moduleVersionId
                                                                ? 'The selected module has no more scores'
                                                                : ' Please enter a valid module version id at the top of the page'
                                                        }
                                                        listPositioningStrategy={
                                                            index ===
                                                            (props.functionalScores
                                                                ?.length as number) -
                                                                1
                                                                ? 'fixed'
                                                                : 'absolute'
                                                        }
                                                    />
                                                )}
                                            </InputWrapper>
                                            {index + 1 !== props.functionalScores?.length && (
                                                <Hr size='narrow' />
                                            )}
                                        </Col>
                                    </li>
                                ))}
                            </Col>
                        </ul>
                    </>
                )}
        </>
    )
}
