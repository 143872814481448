import {
    CharRangeChoice,
    ListChoice,
    NumericRangeChoice,
    parseFromRegex,
} from 'src/pages/GraphConstructionEditor/Choice'

export interface ItemCodeGenerationData {
    weight?: number
    regex?: string[]
    choice?: boolean
    shuffle?: boolean
    pick?: number
}

export enum GraphConstructionType {
    // Leaf node
    Leaf = 'Leaf',
    // Sequential branch
    Seq = 'Seq',
    // Alternative branch
    Alt = 'Alt',
}

/**
 * Editable representation of item code generation rules.
 */
export interface BaseGraphConstruction {
    type: GraphConstructionType
}

/**
 * Leaf node. Contains data on generating a part of an item code.
 * Cannot contain children.
 */
export interface Leaf<D = ItemCodeGenerationData> extends BaseGraphConstruction {
    type: GraphConstructionType.Leaf
    data: D
    children?: undefined
}

/**
 * Sequential node. Applies the children nodes in order then concat the results.
 * Cannot contain children of Seq type.
 */
export interface Seq<D = ItemCodeGenerationData> extends BaseGraphConstruction {
    type: GraphConstructionType.Seq
    children: (Leaf<D> | Alt<D>)[]
    data?: D
}

/**
 * Alternative node. Randomly applies one of the child nodes by weight.
 * Cannot contain children of Alt type.
 */
export interface Alt<D = ItemCodeGenerationData> extends BaseGraphConstruction {
    type: GraphConstructionType.Alt
    children: (Leaf<D> | Seq<D>)[]
    data?: D
}

export type GraphConstruction<D = ItemCodeGenerationData> = Leaf<D> | Seq<D> | Alt<D>

/**
 * Item code generation rules for the designer backend.
 */
export interface Distribution {
    proportion: number
    combination: CombinationConfig
}

export interface CombinationConfig {
    choices: (CharRangeChoice | NumericRangeChoice | ListChoice)[]
    distributions: Distribution[]
    shuffle: boolean
    pick: number
}

function toDistributions(
    trees: { weight?: number; combination: CombinationConfig }[]
): Distribution[] {
    const total = trees.reduce((tw, { weight = 1 }) => tw + weight, 0)
    return trees.map(({ weight, combination }) => ({
        proportion: (weight ?? 1) / total,
        combination,
    }))
}

function treeProduct(
    first: CombinationConfig,
    rest: { weight?: number; combination: CombinationConfig }[]
): CombinationConfig {
    if (first.distributions.length === 0) {
        return { ...first, distributions: toDistributions(rest) }
    }
    return {
        ...first,
        distributions: first.distributions.map(({ proportion, combination: child }) => ({
            proportion,
            combination: treeProduct(child, rest),
        })),
    }
}

function transformData({ regex = [], shuffle = false, pick = undefined }: ItemCodeGenerationData) {
    return {
        choices: regex.map(parseFromRegex),
        shuffle: shuffle,
        pick: (pick as number) ?? regex.length,
    }
}

function expandIntoCases(
    g: GraphConstruction
): { weight?: number; combination: CombinationConfig }[] {
    if (g.type === GraphConstructionType.Leaf) {
        const { weight, ...data } = g.data ?? {}
        return [
            {
                weight,
                combination: {
                    ...transformData(data as never as ItemCodeGenerationData),
                    distributions: [],
                },
            },
        ]
    } else if (g.type === GraphConstructionType.Alt) {
        const alt = g as unknown as Alt
        const results: { weight?: number; combination: CombinationConfig }[] = []
        for (const child of alt.children) {
            const weight = child.data?.weight ?? 1
            const expanded = expandIntoCases(child)
            for (const { combination } of expanded) {
                results.push({ weight, combination })
            }
        }
        return results
    } else if (g.type === GraphConstructionType.Seq) {
        const seq = g as unknown as Seq
        if (seq.children.length === 0) {
            return []
        } else if (seq.children.length === 1) {
            return expandIntoCases(seq.children[0])
        }
        const {
            children: [first, ...rest],
        } = seq
        return expandIntoCases(first).map(({ weight, combination }) => {
            const tp = treeProduct(
                combination,
                expandIntoCases({ type: GraphConstructionType.Seq, children: rest } as Seq)
            )
            return { weight, combination: tp }
        })
    }
    throw new Error('expandIntoCases: unknown type: ' + (g as BaseGraphConstruction).type)
}

/**
 * Convert a graph construction into the backend-readable form as a list of alternatives.
 * @param g The graph construction to be converted
 */
export function expandToDistributions(g: GraphConstruction): Distribution[] {
    return toDistributions(expandIntoCases(g))
}

export const body: Alt = Object.freeze({
    type: GraphConstructionType.Alt,
    children: [
        {
            type: GraphConstructionType.Leaf,
            data: { regex: ['A-Z', 'a-z', '0-9'], pick: 3, shuffle: true },
        },
        {
            type: GraphConstructionType.Leaf,
            data: { regex: ['A-Z', 'a-z', '0-9'], pick: 4, shuffle: true },
        },
        {
            type: GraphConstructionType.Leaf,
            data: { regex: ['A-Z', 'a-z', '0-9'], pick: 5, shuffle: true },
        },
    ],
})

export const itemCodeConstruction: Alt = Object.freeze({
    type: GraphConstructionType.Alt,
    children: [
        {
            type: GraphConstructionType.Seq,
            data: { weight: 1 },
            children: [
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['A'], weight: 1 },
                },
                body,
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['3-9'] },
                },
            ],
        },
        {
            type: GraphConstructionType.Seq,
            data: { weight: 1 },
            children: [
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['Z'], weight: 1 },
                },
                body,
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['3-9'] },
                },
            ],
        },
        {
            type: GraphConstructionType.Seq,
            data: { weight: 2 },
            children: [
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['B-Y'] },
                },
                body,
                {
                    type: GraphConstructionType.Leaf,
                    data: { regex: ['1-2'] },
                },
            ],
        },
    ],
})
