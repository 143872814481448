import React, { useCallback, useMemo, useState } from 'react'
import axios from 'axios'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    Checkbox,
    Input,
    InputWrapper,
    LabelPosition,
    Select,
} from '@amzn/stencil-react-components/form'
import { IconBin } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, View } from '@amzn/stencil-react-components/layout'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Table } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { PairingTableDTO } from 'src/models/dto/activities/MUPPExamDTO'
import {
    DimensionPairConstraintType,
    Dimensions,
    PAIRING_TABLE_CSV_HEADER,
    PairingTableConstraints,
} from 'src/models/dto/mupp/PairingTableDTO'
import { PairingTableCellSummary } from 'src/pages/pairing-table-generator/PairingTableCellVisual'
import {
    AutoPairingTablesService,
    DimensionPairConstraint,
} from 'src/services/mupp/AutoPairingTablesService'

interface PairConstraintRow {
    dimension1: React.ReactElement
    dimension2: React.ReactElement
    type: React.ReactElement
    value: React.ReactElement
    action: React.ReactElement
}

interface GeneratedPairingTableRow {
    pairingTable: PairingTableDTO
    index: number
    action: React.ReactElement
}

const PairingTableGenerator = () => {
    const [loadingPairingTables, setLoadingPairingTables] = useState<boolean>(false)

    // Pairing Table Constraints
    const [selectedDimensions, setSelectedDimensions] = useState<string[]>([])
    // eslint-disable-next-line no-magic-numbers
    const [dimensionCount, setDimensionCount] = useState<number>(12)
    const [dimensionPairConstraints, setDimensionPairConstraints] = useState<
        DimensionPairConstraint[]
    >([])
    const [forwardLookingWindow, setForwardLookingWindow] = useState<number>(1)
    const [shouldUniDimensionAppearFirst, setShouldUniDimensionAppearFirst] =
        useState<boolean>(false)

    // Stores Generated Pairing Tables
    const [pairingTables, setPairingTables] = useState<PairingTableDTO[]>([])

    // Row used in pair constraint table
    const [constraintRows, setConstrainRows] = useState<PairConstraintRow[]>([])

    const hasInvalidPairConstraints = useCallback(() => {
        const seenPairs: Set<string> = new Set<string>()
        // Check if there are any repeat pairs.
        const containsSamePairs = dimensionPairConstraints.some((currentPairConstraint) => {
            let currentPair = ''
            if (
                currentPairConstraint.dimensionPair.first <
                currentPairConstraint.dimensionPair.second
            ) {
                currentPair = `${currentPairConstraint.dimensionPair.first}${currentPairConstraint.dimensionPair.second}`
            } else {
                currentPair = `${currentPairConstraint.dimensionPair.second}${currentPairConstraint.dimensionPair.first}`
            }

            if (seenPairs.has(currentPair)) {
                return true
            }

            seenPairs.add(currentPair)
            return false
        })

        return (
            (dimensionPairConstraints.length > 0 &&
                dimensionPairConstraints.find(
                    (pc) =>
                        !selectedDimensions.some((s) => s === pc.dimensionPair.first) ||
                        !selectedDimensions.some((s) => s === pc.dimensionPair.second)
                ) !== undefined) ||
            containsSamePairs
        )
    }, [dimensionPairConstraints, selectedDimensions])

    const updatePairConstraint = (constraint: DimensionPairConstraint, index: number) => {
        setDimensionPairConstraints((prev) => {
            prev[index] = constraint
            return [...prev]
        })
    }

    // Dimension Pair Constraint Columns
    const dimensionPairConstraintColumns = [
        {
            header: 'Dimension 1',
            accessor: 'dimension1',
        },
        {
            header: 'Dimension 2',
            accessor: 'dimension2',
        },
        {
            header: 'Type',
            accessor: 'type',
        },
        {
            header: 'Value',
            accessor: 'value',
        },
        {
            header: 'Action',
            accessor: 'action',
        },
    ]

    useMemo(() => {
        setConstrainRows(
            dimensionPairConstraints.map((pc, index) => {
                return {
                    dimension1: (
                        <Select
                            key={`dimension1-${index}`}
                            id={`dimension1-${index}`}
                            dataTestId={`dimension1-${index}`}
                            value={pc.dimensionPair.first}
                            error={
                                selectedDimensions.filter((s) => s === pc.dimensionPair.first)
                                    .length === 0
                            }
                            disabled={loadingPairingTables}
                            options={selectedDimensions}
                            onChange={(first: string) =>
                                updatePairConstraint(
                                    {
                                        ...pc,
                                        dimensionPair: {
                                            ...pc.dimensionPair,
                                            first,
                                        },
                                    },
                                    index
                                )
                            }
                        />
                    ),
                    dimension2: (
                        <Select
                            key={`dimension2-${index}`}
                            id={`dimension2-${index}`}
                            dataTestId={`dimension2-${index}`}
                            value={pc.dimensionPair.second}
                            disabled={loadingPairingTables}
                            options={selectedDimensions}
                            error={
                                selectedDimensions.filter((s) => s === pc.dimensionPair.second)
                                    .length === 0
                            }
                            onChange={(second: string) =>
                                updatePairConstraint(
                                    {
                                        ...pc,
                                        dimensionPair: {
                                            ...pc.dimensionPair,
                                            second,
                                        },
                                    },
                                    index
                                )
                            }
                        />
                    ),
                    type: (
                        <Select
                            key={`constraint-type-${index}`}
                            id={`constraint-type-${index}`}
                            dataTestId={`constraint-type-${index}`}
                            value={pc.type}
                            disabled={loadingPairingTables}
                            options={Object.values(DimensionPairConstraintType)}
                            onChange={(type: string) => {
                                updatePairConstraint(
                                    {
                                        ...pc,
                                        type: type as DimensionPairConstraintType,
                                    },
                                    index
                                )
                            }}
                        />
                    ),
                    value: (
                        <InputWrapper id={`constraint-value-${index}`} labelText=''>
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    dataTestId={`constraint-value-${index}`}
                                    type={'number'}
                                    value={pc.value}
                                    disabled={loadingPairingTables}
                                    min={0}
                                    aria-label='Constraint value'
                                    onChange={(e) =>
                                        updatePairConstraint(
                                            {
                                                ...pc,
                                                value: parseInt(e.target.value),
                                            },
                                            index
                                        )
                                    }
                                />
                            )}
                        </InputWrapper>
                    ),
                    action: (
                        <Button
                            key={`delete-constraint-${index}`}
                            dataTestId={`delete-constraint-${index}`}
                            icon={
                                <IconBin
                                    key={`delete-constraint-icon-${index}`}
                                    title=''
                                    aria-hidden
                                />
                            }
                            variant={ButtonVariant.Secondary}
                            size={ButtonSize.Small}
                            onClick={() =>
                                setDimensionPairConstraints((prev) => {
                                    const newList = [...prev]
                                    newList.splice(index, 1)
                                    return newList
                                })
                            }
                        />
                    ),
                } as PairConstraintRow
            })
        )
    }, [dimensionPairConstraints, loadingPairingTables, selectedDimensions])

    const addPairConstraint = () => {
        setDimensionPairConstraints((prev) => {
            const newList = [...prev]
            newList.push({
                dimensionPair: {
                    first: selectedDimensions[0],
                    second: selectedDimensions[0],
                },
                type: DimensionPairConstraintType.EXACT,
                value: 1,
            })
            return newList
        })
    }

    // Generated Pairing Tables table columns
    const pairingTableColumns = [
        {
            header: ' ',
            accessor: 'index',
            width: '2%',
        },
        {
            header: 'Pairings',
            accessor: 'pairingTable',
            cellComponent: PairingTableCellSummary,
        },
        {
            header: 'Action',
            accessor: 'action',
        },
    ]

    const generatePairingTables = async () => {
        let componentUnmounted = false
        const source = axios.CancelToken.source()
        try {
            setLoadingPairingTables(true)
            setPairingTables([])

            const request: PairingTableConstraints = {
                dimensionCount,
                shouldUniDimensionsAppearFirst: shouldUniDimensionAppearFirst,
                dimensionPairConstraints: dimensionPairConstraints.map((pc) => {
                    return {
                        ...pc,
                        dimensionPair: {
                            first:
                                Dimensions.find((d) => pc.dimensionPair.first === d.friendlyName)
                                    ?.systemName ?? pc.dimensionPair.first,
                            second:
                                Dimensions.find((d) => pc.dimensionPair.second === d.friendlyName)
                                    ?.systemName ?? pc.dimensionPair.second,
                        },
                    }
                }),
                forwardLookingWindow: forwardLookingWindow.toString(),
                pairingTableCount: 3,
            }

            const res = await AutoPairingTablesService.generateAutoPairingTables(
                {
                    ...request,
                    dimensions: selectedDimensions.map(
                        (s) => Dimensions.find((d) => d.friendlyName === s)?.systemName ?? s
                    ),
                },
                source.token
            )

            if (componentUnmounted) return

            const returnedPairingTables = res.pairingTables?.map(
                (pt: PairingTableDTO, index: number) => {
                    const csvBody =
                        PAIRING_TABLE_CSV_HEADER +
                        pt.dimensionPairs
                            .map(
                                (dp) =>
                                    `${pt.id},${pt.name},${pt.name},${pt.locale},${dp.first},${dp.second}`
                            )
                            .join('\n')

                    const action: React.ReactElement = (
                        <a
                            aria-hidden
                            href={`data:text/plain,${encodeURIComponent(csvBody)}`}
                            download={'pairingTable.csv'}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                key={`download-${index}`}
                                dataTestId={`download-${index}`}
                                variant={ButtonVariant.Secondary}
                                size={ButtonSize.Small}
                            >
                                Download
                            </Button>
                        </a>
                    )

                    return {
                        pairingTable: pt,
                        index: index + 1,
                        action,
                    } as GeneratedPairingTableRow
                }
            )

            setPairingTables(returnedPairingTables as [])
        } catch (e) {
            if (componentUnmounted) return
            setPairingTables([])
        } finally {
            setLoadingPairingTables(false)
        }

        return () => {
            componentUnmounted = true
            source.cancel()
        }
    }

    return (
        <View>
            <Col
                display={'flex'}
                flex={1}
                justifyContent={'center'}
                padding={{ right: 100, left: 100, top: 10, bottom: 10 }}
            >
                <Col display={'flex'} flex={1} gridGap={20}>
                    <InputWrapper labelText='Dimensions' id='dimensions'>
                        {(props) => (
                            <Select
                                value={selectedDimensions}
                                isMulti
                                disabled={loadingPairingTables}
                                isSearchable
                                {...props}
                                options={Dimensions.map((d) => d.friendlyName)}
                                onChange={setSelectedDimensions}
                            />
                        )}
                    </InputWrapper>

                    <Col gridGap={10}>
                        <Text
                            id={'pair-constraints'}
                            dataTestId={'pair-constraints'}
                            fontSize={'T200'}
                        >
                            Pair Constrains
                        </Text>
                        <Table
                            data={constraintRows}
                            actionHeader=' '
                            getRowAttributes={({ index }) => {
                                return {
                                    dataTestId: `pair-constraint-row-${index}`,
                                    'aria-label': `pair-constraint-row-${index}`,
                                }
                            }}
                            columns={dimensionPairConstraintColumns}
                        />

                        <Row>
                            <Button
                                variant={ButtonVariant.Secondary}
                                dataTestId={'add-constraint'}
                                disabled={loadingPairingTables || selectedDimensions.length === 0}
                                key={'add-constraint-button'}
                                onClick={addPairConstraint}
                                aria-label={'Add Pair Constraint'}
                                size={ButtonSize.Small}
                            >
                                Add Pair Constraint
                            </Button>
                        </Row>
                    </Col>

                    <InputWrapper id='statement-count' labelText='Statement Count'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                data-test-id='statement-count'
                                type={'number'}
                                disabled={loadingPairingTables}
                                value={dimensionCount}
                                min={4}
                                max={12}
                                aria-label='Statement count'
                                onChange={(e) => {
                                    setDimensionCount(Number(e.target.value))
                                }}
                            />
                        )}
                    </InputWrapper>

                    <InputWrapper id='forward-looking-window' labelText='Forward Looking Window'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                data-test-id='forward-looking-window'
                                type={'number'}
                                disabled={loadingPairingTables}
                                value={forwardLookingWindow}
                                min={1}
                                max={3}
                                aria-label='Forward looking window'
                                onChange={(e) => {
                                    setForwardLookingWindow(Number(e.target.value))
                                }}
                            />
                        )}
                    </InputWrapper>

                    <InputWrapper
                        id='uni-dimension-pairs'
                        labelText={'Should Uni Dimensions Pairs Appear First?'}
                        labelPosition={LabelPosition.Trailing}
                    >
                        {(inputProps) => (
                            <Checkbox
                                dataTestId={'uni-dimension-pairs'}
                                disabled={loadingPairingTables}
                                {...inputProps}
                                value={shouldUniDimensionAppearFirst.toString()}
                                onChange={(e) =>
                                    setShouldUniDimensionAppearFirst(Boolean(e.target.value))
                                }
                            />
                        )}
                    </InputWrapper>

                    <Hr />

                    <Row>
                        <Button
                            id={'generate-button'}
                            variant={ButtonVariant.Secondary}
                            dataTestId={'generate-button'}
                            disabled={
                                loadingPairingTables ||
                                hasInvalidPairConstraints() ||
                                selectedDimensions.length === 0
                            }
                            key={'generate-button'}
                            onClick={generatePairingTables}
                            aria-label={'Generate Pairing Tables'}
                            size={ButtonSize.Small}
                        >
                            Generate Pairing Tables
                        </Button>
                    </Row>

                    {loadingPairingTables ? (
                        <Row flex={1} gridGap={30}>
                            <Spinner /> Generating Pairing Tables, this could take up to 30
                            seconds...
                        </Row>
                    ) : pairingTables.length > 0 ? (
                        <>
                            <Hr />
                            <Table
                                data={pairingTables}
                                actionHeader=' '
                                dataTestId={'generated-pairing-tables-table'}
                                getRowAttributes={({ index }) => {
                                    return {
                                        dataTestId: `generated-pairing-tables-table-row-${index}`,
                                        'aria-label': `generated-pairing-tables-table-row-${index}`,
                                    }
                                }}
                                columns={pairingTableColumns}
                            />
                        </>
                    ) : (
                        <Row flex={1} gridGap={10}>
                            <Text
                                fontSize={'T200'}
                                id={'no-tables-generated'}
                                dataTestId={'no-tables-generated'}
                            >
                                {' '}
                                No pairing tables generated.{' '}
                            </Text>
                        </Row>
                    )}
                </Col>
            </Col>
        </View>
    )
}

export default PairingTableGenerator
