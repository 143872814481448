import React from 'react'

import { InputFooter } from '@amzn/stencil-react-components/form'
import { Col } from '@amzn/stencil-react-components/layout'

import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { applyChange } from 'src/hooks/DTOEditor'
import { useEntity } from 'src/hooks/useEntity'
import { useUpdateRowLayoutDTO } from 'src/hooks/useUpdateRowLayoutDTO'
import { MediaRowDTO, RowLayoutType } from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale } from 'src/models/dto/Locale'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { VALIDATION_ERROR_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ValidationErrorEntityService'

export interface MediaRowEditorProps {
    mediaRow: MediaRowDTO
    dataTestId: string
    itemId: string
    rowIndex: number
    workflowEntityId: string
    locale: Locale
    editDisabled?: boolean
}

export const MediaRowEditor = (props: MediaRowEditorProps) => {
    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const updateRow = useUpdateRowLayoutDTO<MediaRowDTO>(props.itemId, props.rowIndex)

    const maxNumberOfMedia = props.mediaRow.rowLayoutType === RowLayoutType.IMAGE_CLUSTER ? 4 : 1

    return (
        <Col
            gridGap={10}
            flex={1}
            dataTestId={props.dataTestId}
            data-media-row={props.rowIndex + 1}
        >
            {validationErrorEntity.validationErrors[`rows[${props.rowIndex}].mediaI18N`] && (
                <InputFooter
                    style={{
                        visibility: validationErrorEntity.validationErrors[
                            `rows[${props.rowIndex}].mediaI18N`
                        ]
                            ? 'visible'
                            : 'hidden',
                    }}
                    error
                    id={'media-row-error-footer'}
                >
                    {validationErrorEntity.validationErrors[
                        `rows[${props.rowIndex}].mediaI18N`
                    ].join(', ')}
                </InputFooter>
            )}
            <MediaManager
                inputId={`media-files-add-image-path-index-${props.rowIndex}`}
                itemId={props.itemId}
                localeWiseMediaOpt={props.mediaRow.localeWiseMedia}
                maxNumberOfMedia={maxNumberOfMedia}
                locale={props.locale}
                shouldExpandOnMount
                disableMediaExpander={true}
                setLocaleWiseMediaOpt={(next) => {
                    updateRow((r) => ({
                        ...r,
                        localeWiseMedia: applyChange(r.localeWiseMedia, next),
                    }))
                }}
                disabled={props.editDisabled}
            />
        </Col>
    )
}
