import React from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputFooter } from '@amzn/stencil-react-components/form'
import { IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { H5, Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { useSetLocaleWiseMediaOpt } from 'src/hooks/useSetLocaleWiseMediaOpt'
import { AvatarDTO, VirtualMeetingDTO } from 'src/models/dto/items/VirtualMeetingDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { getListOfFileTypes, MediaFileGroups } from 'src/models/media/MediaTemplateDTO'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { VirtualMeetingHandler } from 'src/services/EntityServices/ItemUpdateHandlers/VirtualMeetingHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const VirtualMeetingItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<VirtualMeetingDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const flexItemWidth = matches.s ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '250px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'

    const setLocaleWiseMediaOpt = useSetLocaleWiseMediaOpt(itemDTO)
    const fieldFlex = matches.s ? '1 1' : '1 1 29%'

    const MIN_NUMBER_OF_ATTENDEES = 1
    const MAX_NUMBER_OF_ATTENDEES = 4

    const idPrefix = itemId
    const attendeesHeaderId = `${idPrefix}-attendees-header`

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <H5 color='neutral100' fontWeight='bold'>
                Meeting Details
            </H5>
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId={'unique-item-id'}
                        inputId={'unique-item-id'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            VirtualMeetingHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId={'optional-checkbox'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            VirtualMeetingHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'flex-start'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextInput
                        dataTestId={'title'}
                        inputId={'title'}
                        disabled={editDisabled}
                        value={itemDTO.titleI18N[locale] ?? ''}
                        placeholder={'Enter a meeting title'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            VirtualMeetingHandler.updateTitle(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.titleI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Meeting title'}
                        maxCharacterLimit={70}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <Row alignItems={'flex-start'}>
                <Col alignItems={'center'} height={'100%'}>
                    <Spacer height='S100' />
                    <H5 id={attendeesHeaderId}>Attendees</H5>
                </Col>
                <IconWithTooltip tooltipText={'Omit the job candidate'} />
            </Row>
            <View
                border={matches.s ? '0px' : '1px solid #B9C0C8'}
                role='list'
                aria-labelledby={attendeesHeaderId}
            >
                {/*For each avatar, display title banner, 4 text prompts, and add profile image feature*/}
                {itemDTO.avatars.map((avatar: AvatarDTO, avatarIndex: number) => {
                    const attendeeLabelId = `${idPrefix}-attendee-${avatarIndex}`
                    return (
                        <View key={avatarIndex} role='listitem' aria-labelledby={attendeeLabelId}>
                            <Row
                                alignItems='center'
                                backgroundColor={matches.s ? 'white' : 'neutral05'}
                                justifyContent='space-between'
                                padding={matches.s ? 0 : 'S200'}
                                width='100%'
                            >
                                <Text id={attendeeLabelId} color='neutral90'>{`Attendee ${
                                    avatarIndex + 1
                                }`}</Text>
                                {avatarIndex == 0 && (
                                    <IconWithTooltip tooltipText={'Meeting organizer'} />
                                )}
                                <Spacer flex='1 1 0' />
                                {!matches.s && (
                                    <Button
                                        dataTestId={`avatar-${avatarIndex}-delete-button`}
                                        icon={
                                            <IconBin
                                                title={`Delete meeting avatar ${avatarIndex + 1}`}
                                            />
                                        }
                                        aria-disabled={
                                            itemDTO.avatars.length <= MIN_NUMBER_OF_ATTENDEES ||
                                            editDisabled
                                        }
                                        variant={ButtonVariant.Tertiary}
                                        size={ButtonSize.Small}
                                        isDestructive
                                        onClick={() => {
                                            VirtualMeetingHandler.deleteAvatar(
                                                itemDTO.id,
                                                avatarIndex
                                            )
                                        }}
                                    />
                                )}
                            </Row>
                            <Row
                                width='100%'
                                gridGap='S300'
                                alignItems={'flex-start'}
                                flexWrap={'wrap'}
                                padding={matches.s ? 0 : 'S200'}
                            >
                                <Col flex={fieldFlex}>
                                    <ItemEditorTextInput
                                        dataTestId={`avatar-${avatarIndex}-first-name`}
                                        inputId={`first-name-${avatarIndex}`}
                                        disabled={editDisabled}
                                        value={
                                            itemDTO.avatars[avatarIndex].firstNameI18N[locale] ?? ''
                                        }
                                        placeholder={'E.g. Jane'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            VirtualMeetingHandler.updateAvatarFirstName(
                                                itemDTO.id,
                                                locale,
                                                nextValue.trim(),
                                                avatarIndex
                                            )
                                        }}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `avatar[${avatarIndex}].firstNameI18N`
                                            ] ?? []
                                        ).join(', ')}
                                        showError={showValidationError}
                                        labelText={'First name'}
                                        maxCharacterLimit={12}
                                    />
                                </Col>
                                <Col flex={fieldFlex}>
                                    <ItemEditorTextInput
                                        dataTestId={`avatar-${avatarIndex}-last-name`}
                                        inputId={`last-name-${avatarIndex}`}
                                        disabled={editDisabled}
                                        value={
                                            itemDTO.avatars[avatarIndex].lastNameI18N[locale] ?? ''
                                        }
                                        placeholder={'E.g. Smith'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            VirtualMeetingHandler.updateAvatarLastName(
                                                itemDTO.id,
                                                locale,
                                                nextValue.trim(),
                                                avatarIndex
                                            )
                                        }}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `avatar[${avatarIndex}].lastNameI18N`
                                            ] ?? []
                                        ).join(', ')}
                                        showError={showValidationError}
                                        labelText={'Last name'}
                                        maxCharacterLimit={12}
                                    />
                                </Col>
                                <Col flex={fieldFlex}>
                                    <ItemEditorTextInput
                                        dataTestId={`avatar-${avatarIndex}-info-line-1`}
                                        inputId={`info-line-1-${avatarIndex}`}
                                        disabled={editDisabled}
                                        value={
                                            itemDTO.avatars[avatarIndex].informationLine1I18N[
                                                locale
                                            ] ?? ''
                                        }
                                        placeholder={'E.g. Product Manager'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            VirtualMeetingHandler.updateAvatarInfoLine1(
                                                itemDTO.id,
                                                locale,
                                                nextValue.trim(),
                                                avatarIndex
                                            )
                                        }}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `avatar[${avatarIndex}].informationLine1I18N`
                                            ] ?? []
                                        ).join(', ')}
                                        showError={showValidationError}
                                        labelText={'Information line 1'}
                                        maxCharacterLimit={24}
                                    />
                                </Col>
                                <Col flex={fieldFlex}>
                                    <ItemEditorTextInput
                                        dataTestId={`avatar-${avatarIndex}-info-line-2`}
                                        inputId={`info-line-2-${avatarIndex}`}
                                        disabled={editDisabled}
                                        value={
                                            itemDTO.avatars[avatarIndex].informationLine2I18N[
                                                locale
                                            ] ?? ''
                                        }
                                        placeholder={'E.g. AWS'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            VirtualMeetingHandler.updateAvatarInfoLine2(
                                                itemDTO.id,
                                                locale,
                                                nextValue.trim(),
                                                avatarIndex
                                            )
                                        }}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `avatar[${avatarIndex}].informationLine2I18N`
                                            ] ?? []
                                        ).join(', ')}
                                        showError={showValidationError}
                                        labelText={'Information line 2'}
                                        maxCharacterLimit={24}
                                    />
                                </Col>
                                {!matches.s && <Spacer flex='1 1 63%' />}
                            </Row>
                            <Col
                                dataTestId={`avatar-${avatarIndex}-add-media`}
                                padding={matches.s ? 0 : 'S100'}
                            >
                                <Text color='neutral100' fontWeight='bold'>
                                    Profile image
                                </Text>
                                <MediaManager
                                    itemId={itemDTO.id}
                                    locale={locale}
                                    localeWiseMediaOpt={
                                        itemDTO.avatars[avatarIndex].localeWiseMedia
                                    }
                                    setLocaleWiseMediaOpt={(change) => {
                                        VirtualMeetingHandler.updateAvatarLocaleWiseMediaOpt(
                                            itemDTO.id,
                                            change,
                                            avatarIndex
                                        )
                                    }}
                                    disableMediaExpander={true}
                                    maxNumberOfMedia={1}
                                    buttonText={'Add profile image'}
                                    buttonVariant={ButtonVariant.Secondary}
                                    lockedFileTypes={getListOfFileTypes(MediaFileGroups.IMAGE)}
                                    disabled={editDisabled}
                                />
                                {validationErrorEntity.validationErrors[
                                    `avatar[${avatarIndex}].localeWiseMedia`
                                ] && (
                                    <div>
                                        <InputFooter
                                            style={{ visibility: 'visible' }}
                                            error
                                            id={`avatar-${avatarIndex}-image-footer`}
                                        >
                                            {validationErrorEntity.validationErrors[
                                                `avatar[${avatarIndex}].localeWiseMedia`
                                            ].join(', ')}
                                        </InputFooter>
                                        <Spacer height='S400' />
                                    </div>
                                )}
                            </Col>
                            {matches.s && (
                                <Col width={'100%'}>
                                    <Button
                                        dataTestId={`avatar-${avatarIndex}-delete-button`}
                                        aria-disabled={
                                            itemDTO.avatars.length <= MIN_NUMBER_OF_ATTENDEES ||
                                            editDisabled
                                        }
                                        variant={ButtonVariant.Primary}
                                        isDestructive
                                        onClick={() => {
                                            VirtualMeetingHandler.deleteAvatar(
                                                itemDTO.id,
                                                avatarIndex
                                            )
                                        }}
                                    >
                                        Delete Attendee {avatarIndex + 1}
                                    </Button>
                                    <Spacer height='S300' />
                                </Col>
                            )}
                        </View>
                    )
                })}
                <Row alignItems='flex-end' width='100%' padding={matches.s ? undefined : 'S200'}>
                    {!matches.s && <Spacer flex='1 1 0' />}
                    <View flex={matches.s ? '1 1 0' : undefined}>
                        <Button
                            style={{ width: '100%' }}
                            dataTestId={'add-avatars'}
                            icon={!matches.s ? <IconPlus aria-hidden /> : <></>}
                            variant={!matches.s ? ButtonVariant.Tertiary : ButtonVariant.Secondary}
                            onClick={() => {
                                VirtualMeetingHandler.addAvatar(itemDTO.id)
                            }}
                            aria-disabled={
                                itemDTO.avatars.length === MAX_NUMBER_OF_ATTENDEES || editDisabled
                            }
                        >
                            Add attendee
                        </Button>
                    </View>
                </Row>
            </View>
            <Spacer height='S400' />
            <H5 color='neutral100' fontWeight='bold'>
                Meeting audio recording
            </H5>
            <MediaManager
                itemId={itemDTO.id}
                locale={locale}
                localeWiseMediaOpt={itemDTO.localeWiseMedia}
                setLocaleWiseMediaOpt={setLocaleWiseMediaOpt}
                disableMediaExpander={true}
                maxNumberOfMedia={1}
                buttonText={'Add audio clip'}
                buttonVariant={ButtonVariant.Secondary}
                lockedFileTypes={getListOfFileTypes(MediaFileGroups.AUDIO)}
                disabled={editDisabled}
            />
            {validationErrorEntity.validationErrors.localeWiseMedia && (
                <div>
                    <InputFooter style={{ visibility: 'visible' }} error id={'audio-clip-footer'}>
                        {validationErrorEntity.validationErrors.localeWiseMedia.join(', ')}
                    </InputFooter>
                    <Spacer height='S400' />
                </div>
            )}
            <Col flexGrow={1} width={matches.s ? '100%' : '30%'}>
                <ItemEditorNumberInput
                    dataTestId={'playback-limit'}
                    inputId={'playback-limit'}
                    value={itemDTO.playbackLimit ?? null}
                    disabled={editDisabled}
                    placeholder={'E.g. 3'}
                    itemId={itemDTO.id}
                    setValue={(nextValue) => {
                        VirtualMeetingHandler.updatePlaybackLimit(itemDTO.id, nextValue)
                    }}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors.playbackLimit ?? []
                    ).join(', ')}
                    showError={showValidationError}
                    labelText={'Max play count (optional)'}
                />
            </Col>
        </>
    )
}
