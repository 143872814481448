import React from 'react'

import { ModuleVersionIdFind } from 'src/components/ModuleVersionIdFind'
import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'

export interface ModuleVersionIdInputSearchProps {
    setSelectedModule: (selectedModule: ModuleGroupModuleMetadata | undefined) => void
    setModuleScores: (scores: string[]) => void
    moduleVersionId: string
    setModuleVersionId: (moduleVersionId: string) => void
    setIsInUATOrMore: (isInUATOrMore: boolean) => void
    isInUATOrMore: boolean
    moduleValidation?: string
    setModuleValidation?: (moduleValidation: string) => void
}

export const ModuleGetAndValidateInput = (props: ModuleVersionIdInputSearchProps) => {
    const processModule = (moduleVersionDTO: ModuleVersionDTO | undefined) => {
        if (moduleVersionDTO) {
            props.setSelectedModule({
                id: moduleVersionDTO.id,
                versionId: moduleVersionDTO.versionId,
                name: moduleVersionDTO.name,
            })
            const moduleStatus = ModuleStatus[moduleVersionDTO.status as keyof typeof ModuleStatus]
            const uatCheck =
                moduleStatus === ModuleStatus.LIVE ||
                moduleStatus === ModuleStatus.PUBLISHED ||
                moduleStatus === ModuleStatus.UAT
            props.setIsInUATOrMore(uatCheck)
            props.setModuleScores(
                moduleVersionDTO.content.compositeScores.map((score) => score.compositeScoreLabel)
            )
        } else {
            props.setModuleScores([])
            props.setIsInUATOrMore(true)
            props.setSelectedModule(undefined)
        }
    }

    return (
        <>
            <ModuleVersionIdFind
                processModule={processModule}
                warning={
                    !props.isInUATOrMore
                        ? 'The module has to be in UAT or more to be added to a module group'
                        : undefined
                }
                placeholder='Enter the full module version Id....'
                moduleVersionId={props.moduleVersionId}
                setModuleVersionId={props.setModuleVersionId}
                moduleValidation={props.moduleValidation}
                setModuleValidation={props.setModuleValidation}
            />
        </>
    )
}
