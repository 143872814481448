import { v4 } from 'uuid'

import { uploadFileToS3 } from './S3FileHandler'

/**
 * state of local file regarding S3
 */
export enum S3FileState {
    NotUploaded = 'NotUploaded',
    Uploading = 'Uploading',
    Uploaded = 'Uploaded',
}

/**
 * information about the files loaded from local storage
 */
export interface LocalMediaFileInfo {
    id: string
    file: File
    s3State: S3FileState
    relativeS3Path?: string
}

/**
 * base media path on S3
 */
const BASE_MEDIA_PATH = 'media'

/**
 * this service keeps track of the files opened on the browser
 * by user from local storage
 */
export class LocalMediaFilesService {
    /**
     * mapping by file name to the files opened
     */
    private localFilesMap: Map<string, LocalMediaFileInfo>

    constructor() {
        this.localFilesMap = new Map<string, LocalMediaFileInfo>()
    }

    set(file: File, replaceConflicts = false) {
        if (!this.localFilesMap.has(file.name) || replaceConflicts) {
            const localFile: LocalMediaFileInfo = {
                id: v4(),
                file,
                s3State: S3FileState.NotUploaded,
            }

            this.localFilesMap.set(localFile.file.name, localFile)
            return localFile
        }
        throw new Error(
            `File with name: "${file.name}", already exists. File names have to be unique.`
        )
    }

    generateRelativeS3Path(fileName: string): string {
        const localFile = this.localFilesMap.get(fileName)

        if (localFile?.file) {
            const mimeType = localFile.file.type
            const id = localFile.id

            return `${BASE_MEDIA_PATH}/${mimeType}/${id}`
        }
        throw new Error(`Media information for file "${fileName}" was not found`)
    }

    async uploadToS3(fileName: string, path: string) {
        const localFile = this.localFilesMap.get(fileName)

        if (!localFile) {
            throw new Error(`Media information for file "${fileName}" was not found`)
        }
        if (localFile.s3State === S3FileState.Uploading) {
            throw new Error(`File "${localFile.file.name}" already being uploaded to S3`)
        }
        if (localFile.s3State === S3FileState.Uploaded) {
            throw new Error(`File "${localFile.file.name}" already uploaded to S3`)
        }

        localFile.s3State = S3FileState.Uploading

        let result: unknown | null | undefined
        try {
            result = await uploadFileToS3(localFile.file, path)
        } catch (e: unknown) {
            localFile.s3State = S3FileState.NotUploaded
            throw new Error(
                `Upload of file "${localFile.file.name}" failed: ${(e as Error).message}`
            )
        }

        if (result) {
            localFile.s3State = S3FileState.Uploaded
            localFile.relativeS3Path = path
        } else {
            localFile.s3State = S3FileState.NotUploaded
            throw new Error(`Error uploading "${localFile.file.name}" to S3`)
        }
    }
}

export class LocalMediaFilesServiceFactory {
    private static testing: LocalMediaFilesService

    public static loadExisting(): LocalMediaFilesService {
        if (!LocalMediaFilesServiceFactory.testing) {
            LocalMediaFilesServiceFactory.testing = LocalMediaFilesServiceFactory.fromScratch()
        }

        return LocalMediaFilesServiceFactory.testing
    }

    public static fromScratch() {
        return new LocalMediaFilesService()
    }
}
