import React from 'react'

import { Row } from '@amzn/stencil-react-components/layout'
import { Label } from '@amzn/stencil-react-components/text'
import { ToggleSwitch } from '@amzn/stencil-react-components/toggle-switch'

import { ModuleGroupModuleMetadata } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleGroupUtility } from 'src/pages/module-groups/ModuleGroupUtility'

export const SkipModuleToggle = ({
    skipRuleEnabled,
    setSelectedModule,
    setSelectedModuleVersionId,
}: {
    skipRuleEnabled: boolean
    setSelectedModuleVersionId: (versionId: string) => void
    setSelectedModule: (module: ModuleGroupModuleMetadata | undefined) => void
}) => {
    return (
        <Row gridGap='S200'>
            <Label aria-required={true} dataTestId='mg-add-skip-module-label'>
                {'Skip Module?'}
            </Label>
            <ToggleSwitch
                checked={skipRuleEnabled}
                dataTestId='mg-add-skip-module-toggle'
                onChange={(value) => {
                    if (value) {
                        setSelectedModule(ModuleGroupUtility.STATIC_SKIPPED_MODULE)
                        setSelectedModuleVersionId(
                            ModuleGroupUtility.STATIC_SKIPPED_MODULE.versionId
                        )
                    } else {
                        setSelectedModule(undefined)
                        setSelectedModuleVersionId('')
                    }
                }}
            />
        </Row>
    )
}
