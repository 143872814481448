import React, { useCallback, useEffect, useState } from 'react'

import { InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { SearchField } from '@amzn/stencil-react-components/search'
import { SortDirection } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import {
    ModuleGroupModuleMetadata,
    ModuleGroupType,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleVersionDTO } from 'src/models/dto/ModuleVersionDTO'
import { ModuleListSortCriteria, PaginationConfig } from 'src/models/ListModulesRequest'
import { ModuleMetaData } from 'src/models/SearchResultResponse'
import { ModuleService } from 'src/services/backend/ModuleService'
import { SearchService } from 'src/services/backend/SearchService'

const paginationConfig: PaginationConfig = {
    offset: 0,
    numberOfRecordsPerPage: 25,
}

const moduleSortCriteria: ModuleListSortCriteria = {
    sortField: 'moduleName',
    sortDirection: SortDirection.Ascending,
}

const renderResult = (option: ModuleMetaData) => (
    <Col key={option.moduleName}>
        <Row gridGap='S200'>
            <Text>{option.moduleName}</Text>
            <Text fontWeight='bold'>{option.status}</Text>
        </Row>
        <Text>Version Id: {option.moduleVersionId}</Text>
    </Col>
)

export const ModuleSearchSelect = ({
    defaultOrPrimaryModule,
    setDefaultOrPrimaryModule,
    equivalencyType,
    setModuleScores,
    setIsFetching,
    setLoadModuleError,
    moduleValidation,
    setModuleValidation,
}: {
    defaultOrPrimaryModule?: ModuleGroupModuleMetadata | undefined
    setDefaultOrPrimaryModule: (module: ModuleGroupModuleMetadata | undefined) => void
    equivalencyType: ModuleGroupType
    setModuleScores: (scores: string[]) => void
    setIsFetching: (isFetching: boolean) => void
    setLoadModuleError: (boolean: boolean) => void
    moduleValidation?: string
    setModuleValidation?: (moduleValidation: string) => void
}) => {
    const [searchResults, setSearchResults] = useState<ModuleMetaData[]>([])
    const [moduleSearchError, setModuleSearchError] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')

    const footerMessage = () => {
        if (moduleSearchError) {
            return moduleSearchError
        } else if (moduleValidation) {
            return moduleValidation
        } else {
            return undefined
        }
    }

    useEffect(() => {
        return () => {
            setDefaultOrPrimaryModule(undefined)
        }
    }, [setDefaultOrPrimaryModule])

    const onSearchSelect = useCallback(
        async (versionId: string) => {
            setLoadModuleError(false)
            setIsFetching(true)
            try {
                const moduleDTO: ModuleVersionDTO = await ModuleService.loadModuleVersionDTO(
                    versionId
                )
                setModuleScores(
                    moduleDTO.content.compositeScores.map((score) => score.compositeScoreLabel)
                )
            } catch (e) {
                console.error(e)
                setLoadModuleError(true)
            }
            setIsFetching(false)
        },
        [setIsFetching, setLoadModuleError, setModuleScores]
    )

    useEffect(() => {
        if (defaultOrPrimaryModule) {
            setSearchQuery(defaultOrPrimaryModule.name)
            const getModuleScores = async () => {
                await onSearchSelect(defaultOrPrimaryModule.versionId)
            }
            void getModuleScores()
        }
    }, [defaultOrPrimaryModule, onSearchSelect])

    const onSearchFieldChange = async (value: string) => {
        if (value.length >= 3) {
            setModuleSearchError(undefined)
            setIsLoading(true)
            await SearchService.searchModules(value, {}, paginationConfig, moduleSortCriteria)
                .then((response) => {
                    setSearchResults(response.data)
                })
                .catch((err) => {
                    console.error(err)
                    setModuleSearchError('An error occurred while searching for modules')
                    setIsLoading(false)
                    return []
                })
            setIsLoading(false)
        }
    }

    return (
        <>
            <InputWrapper
                id='module-search-select-wrapper'
                labelText={
                    equivalencyType === ModuleGroupType.PEC ? 'Default Module' : 'Reference Module'
                }
                error={!!moduleSearchError || !!moduleValidation}
                footer={footerMessage()}
                required
            >
                {(inputProps) => (
                    <SearchField
                        {...inputProps}
                        query={searchQuery}
                        data-test-id='module-search-field'
                        renderResult={renderResult}
                        results={searchResults}
                        resultToString={(result: ModuleMetaData) => result.moduleVersionId}
                        onResultClick={(value: ModuleMetaData) => {
                            setSearchQuery(value.moduleName)
                            setDefaultOrPrimaryModule({
                                id: value.moduleId,
                                name: value.moduleName,
                                versionId: value.moduleVersionId,
                            })
                        }}
                        placeholder='Enter the module name here...'
                        isLoading={isLoading}
                        onChange={async (query: string) => {
                            if (setModuleValidation) {
                                setModuleValidation('')
                            }
                            setSearchResults([])
                            setSearchQuery(query)
                            setDefaultOrPrimaryModule(undefined)
                            await onSearchFieldChange(query)
                        }}
                        error={!!moduleSearchError || !!moduleValidation}
                    />
                )}
            </InputWrapper>
        </>
    )
}
