import React, { useMemo, useState } from 'react'
import _ from 'lodash'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col } from '@amzn/stencil-react-components/layout'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { ItemBankMetadata, ItemBankVersion } from 'src/models/dto/mupp/MUPPItemBankMetadataDTO'
import { listRows } from 'src/pages/module-builder/mupp-exam-editor/ItemBankDetailsModal'

export interface ViewModulesProps {
    setItemBank: (versionId: ItemBankMetadata) => void
    selectedItemBank?: ItemBankMetadata
}

interface ItemBankTableProps {
    itemBankVersionId: string
    itemBankId: string
    locales: string[]
    setItemBank: (version: string, id: string, locales: string[]) => void
}

const ItemBankTableActions = ({ data: props }: { data: ItemBankTableProps; index: number }) => {
    const returnNodes: JSX.Element[] = []
    returnNodes.push(
        <Button
            variant={ButtonVariant.Secondary}
            id={`select-${props.itemBankId}-button`}
            key={`select-${props.itemBankId}-button`}
            dataTestId={`select-${props.itemBankId}-button`}
            onClick={() => {
                if (props.setItemBank) {
                    props.setItemBank(props.itemBankVersionId, props.itemBankId, props.locales)
                }
            }}
            style={{ height: '100%' }}
        >
            Select
        </Button>
    )

    return <Col>{returnNodes}</Col>
}

interface AvailableListCellProps {
    values: string[]
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const AvailableListCell = ({ data }: any) => {
    const props: AvailableListCellProps = data as AvailableListCellProps

    if (props.values.length === 0) {
        return (
            <Text fontSize='T200' color='red'>
                Not Available
            </Text>
        )
    }
    return listRows(props.values)
}

export interface MUPPItemBankRowProps {
    description: React.ReactNode
    id: React.ReactNode
    latestVersionId: string
    availableLocales: React.ReactNode
    availableDimensions: React.ReactNode
    createdAt: React.ReactNode
    createdBy: React.ReactNode
    actions?: React.ReactNode
}

export interface MUPPItemBankTableProps {
    itemBanks: ItemBankMetadata[]
    setItemBank?: (version: string, id: string, locales: string[]) => void
    viewModulesProps?: ViewModulesProps
}

export const MUPPItemBankTable = (props: MUPPItemBankTableProps) => {
    const [selectedPage, setSelectedPage] = useState(1)
    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: SortDirection.Descending,
        defaultActiveSortId: 'createdAt',
    })

    const pageSize = 5

    const columns: TableColumn<ItemBankVersion>[] = useMemo(() => {
        const cols: TableColumn<ItemBankVersion>[] = [
            { header: 'Name', accessor: 'description', width: '30%', sortId: 'description' },
            {
                header: 'Available Locales',
                accessor: 'availableLocales',
                cellComponent: AvailableListCell,
            },
            {
                header: 'Available Dimensions',
                accessor: 'availableDimensions',
                cellComponent: AvailableListCell,
            },
            { header: 'ID', accessor: 'id' },
            { header: 'Last Updated At', accessor: 'createdAt', sortId: 'createdAt' },
            { header: 'Last Updated By', accessor: 'createdBy', sortId: 'createdBy' },
        ]

        if (props.setItemBank) {
            cols.push({
                header: 'Actions',
                accessor: 'actions',
                cellComponent: ItemBankTableActions,
            })
        }
        return cols
    }, [props.setItemBank])

    const numberOfPages = Math.ceil(props.itemBanks.length / pageSize)

    const sortData = (
        id: string,
        directions: SortDirection,
        unSortedItemBanks: ItemBankMetadata[]
    ): MUPPItemBankRowProps[] => {
        const direction = sortDirection === SortDirection.Ascending ? 'asc' : 'desc'
        const unSortedRows = unSortedItemBanks
            .filter((ib: ItemBankMetadata) => ib.versions.length > 0)
            .map((ib, index) => {
                return {
                    description: (
                        <Text fontSize='T200' key={`id-${index}`}>
                            {ib.versions[0].description}
                        </Text>
                    ),
                    id: (
                        <Text fontSize='T200' key={`id-${index}`}>
                            {ib.itemBankId}
                        </Text>
                    ),
                    latestVersionId: ib.versions[0].versionId,
                    availableLocales: {
                        values: ib.availableLocales,
                    } as AvailableListCellProps,
                    availableDimensions: {
                        values: ib.availableDimensions,
                    } as AvailableListCellProps,
                    createdAt: (
                        <Text fontSize='T200' key={`id-${index}`}>
                            {ib.versions[0].createdAt}
                        </Text>
                    ),
                    createdBy: (
                        <Text fontSize='T200' key={`id-${index}`}>
                            {ib.versions[0].createdBy}
                        </Text>
                    ),
                    actions: {
                        itemBankVersionId: ib.latestItemBankVersionId,
                        itemBankId: ib.itemBankId,
                        locales: ib.availableLocales,
                        setItemBank: props.setItemBank,
                    },
                }
            })

        let sorted: MUPPItemBankRowProps[]
        if (activeSortId === 'description') {
            sorted = _.orderBy(unSortedRows, 'description', [direction, direction])
        } else if (activeSortId === 'createdAt') {
            sorted = _.orderBy(unSortedRows, 'createdAt', [direction, direction])
        } else {
            sorted = _.orderBy(unSortedRows, 'createdBy', [direction, direction])
        }

        return sorted
    }

    const sortedData = sortData(
        (activeSortId as string) ?? 'createdAt',
        sortDirection,
        props.itemBanks
    )

    // eslint-disable-next-line no-mixed-operators
    const page = sortedData.slice(
        (selectedPage - 1) * pageSize,
        (selectedPage - 1) * pageSize + pageSize
    )

    return (
        <Col flex={1} dataTestId={'item-bank-table-container'} gridGap='S200'>
            <Table
                dataTestId={'item-bank-table'}
                columns={columns}
                activeSortId={activeSortId}
                aria-label='item bank table'
                sortDirection={sortDirection}
                onSort={onSort}
                getRowAttributes={({
                    index,
                    data,
                }: {
                    index: number
                    data: { latestVersionId: string }
                }) => {
                    return {
                        dataTestId: `item-bank-row-${index}`,
                        'data-item-bank-version-id': data.latestVersionId,
                        'aria-label': `item-bank-row-${index}`,
                    }
                }}
                data={page}
            />
            <Pagination
                dataTestId={'item-bank-table-pagination'}
                aria-label='item bank table pagination'
                numberOfPages={numberOfPages}
                onPageSelect={(tablePage) => {
                    setSelectedPage(tablePage)
                }}
                selectedPage={selectedPage}
            />
        </Col>
    )
}
