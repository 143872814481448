import React, { useMemo, useState } from 'react'
import _ from 'lodash'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    IconArrowRight,
    IconCheck,
    IconChime,
    IconCross,
    IconPlayFill,
    IconPlus,
    IconRecord,
    IconSort,
    IconStarFill,
    IconStop,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import {
    TableElement,
    TbodyElement,
    TheadElement,
    TrElement,
} from '@amzn/stencil-react-components/table'
import { H4, H5, Label, P, Text } from '@amzn/stencil-react-components/text'

import { ArrowValue2 } from 'src/components/CustomIcons/ArrowValue'
import { WarningModal } from 'src/components/WarningModal'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { SymbolLegend, TableCellType, TableSymbolType } from 'src/models/dto/items/BaseTableDTO'
import { Locale, LocalizedAttribute } from 'src/models/dto/Locale'
import { focusOnId } from 'src/pages/module-builder/focus'
import { SymbolLegendInput } from 'src/pages/module-builder/item-editors/SymbolLegendInput'
import { getAvailableSymbols } from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import {
    TableCell2,
    TableHeaderCell2,
} from 'src/pages/module-builder/item-editors/table-editor/TableCellEditor'
import { ItemEditorCheckboxInput, ItemEditorNumberInput } from '../ItemEditorInputs'
import { ScaleValueInput2 } from '../ScaleValueInput'

export enum LegendType {
    HARVEY_BALL = 'HARVEY_BALL',
    ARROW = 'ARROW',
}

export enum TableScaleHarveyBallScaleType {
    HarveyBallEmpty = 'EmptyBall',
    HarveyBallHalfFull = 'HalfFullBall',
    HarveyBallFull = 'FullBall',
}

export enum TableScaleArrowScaleType {
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
}

interface LegendScale<ScaleType> {
    scaleType: ScaleType
    insetElementLeading: JSX.Element
    value?: string
    scaleDataTestId: string
}

export interface Legend<ScaleType> {
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    onScaleChange: (scaleType: ScaleType, value: string) => void
    legendType: LegendType
    legendHumanReadableName: string
    legendDataTestId: string
    scales: LegendScale<ScaleType>[]
}

export function DefaultHarveyBallLegend<ScaleType = TableScaleHarveyBallScaleType>(
    enabled: boolean,
    setEnabled: (enabled: boolean) => void,
    scales: LegendScale<ScaleType>[],
    onScaleChange: (scaleType: ScaleType, value: string) => void
): Legend<ScaleType> {
    return {
        enabled,
        setEnabled,
        legendType: LegendType.HARVEY_BALL,
        legendHumanReadableName: 'Harvey Ball',
        legendDataTestId: 'harvey-ball-checkbox',
        scales,
        onScaleChange,
    }
}

export function DefaultArrowLegend<ScaleType = TableScaleArrowScaleType>(
    Enum: { ArrowUp: ScaleType; ArrowDown: ScaleType },
    enabled: boolean,
    setEnabled: (enabled: boolean) => void,
    onScaleChange: (scaleType: ScaleType, value: string) => void
): Legend<ScaleType> {
    return {
        enabled,
        setEnabled,
        legendType: LegendType.ARROW,
        legendHumanReadableName: 'Arrow',
        legendDataTestId: 'arrow-checkbox',
        scales: [
            {
                scaleType: Enum.ArrowUp,
                insetElementLeading: <ArrowValue2 type={TableScaleArrowScaleType.ArrowUp} />,
                value: 'Up',
                scaleDataTestId: 'arrow-up-scale-input',
            },
            {
                scaleType: Enum.ArrowDown,
                insetElementLeading: <ArrowValue2 type={TableScaleArrowScaleType.ArrowDown} />,
                value: 'Down',
                scaleDataTestId: 'arrow-down-scale-input',
            },
        ],
        onScaleChange,
    }
}

export const SYMBOL_MAPPING = new Map<TableSymbolType, { rotate: number; element: JSX.Element }>([
    [TableSymbolType.CHIME, { rotate: 0, element: <IconChime dataTestId={'CHIME'} aria-hidden /> }],
    [
        TableSymbolType.PLAY,
        { rotate: 270, element: <IconPlayFill dataTestId={'PLAY'} aria-hidden /> },
    ],
    [
        TableSymbolType.RECORD,
        { rotate: 0, element: <IconRecord dataTestId={'RECORD'} aria-hidden /> },
    ],
    [TableSymbolType.STOP, { rotate: 0, element: <IconStop dataTestId={'STOP'} aria-hidden /> }],
    [
        TableSymbolType.DIAMOND_STOP,
        { rotate: 45, element: <IconStop dataTestId={'DIAMOND_STOP'} aria-hidden /> },
    ],
    [
        TableSymbolType.STAR,
        { rotate: 0, element: <IconStarFill dataTestId={'STAR'} aria-hidden /> },
    ],
    [TableSymbolType.CROSS, { rotate: 0, element: <IconCross dataTestId={'CROSS'} aria-hidden /> }],
    [TableSymbolType.CHECK, { rotate: 0, element: <IconCheck dataTestId={'CHECK'} aria-hidden /> }],
    [
        TableSymbolType.ARROW_UP,
        { rotate: 270, element: <IconArrowRight dataTestId={'ARROW_UP'} aria-hidden /> },
    ],
    [
        TableSymbolType.ARROW_DOWN,
        { rotate: 90, element: <IconArrowRight dataTestId={'ARROW_DOWN'} aria-hidden /> },
    ],
    [TableSymbolType.SORT, { rotate: 90, element: <IconSort dataTestId={'SORT'} aria-hidden /> }],
    [
        TableSymbolType.PLUS_CROSS,
        { rotate: 0, element: <IconPlus dataTestId={'PLUS_CROSS'} aria-hidden /> },
    ],
])

export interface EditCellComponentProps<CellData> {
    itemId: string
    rowNum: number
    colNum: number
    nextCellData: CellData
    setNextCellData: React.Dispatch<React.SetStateAction<CellData>>
}

export interface ViewCellComponentProps<CellData> {
    itemId: string
    rowNum: number
    colNum: number
    cellData: CellData
    symbolLegends: Map<TableSymbolType, string>
}

export interface Header {
    headerName: LocalizedAttribute<string> | string
    editable: boolean
}

export interface TableEditorProps<CellData, ScaleType = string> {
    dataTestId?: {
        table?: string
        row?: string
        cell?: string
        headerCell?: string
        warningModal?: string
        deleteModal?: string
    }
    itemId: string
    headers: Header[]
    warningModalDataTestId?: string
    rows: (CellData | undefined)[][]
    defaultCellData: (rowNum: number, colNum: number, cellType: TableCellType) => CellData
    setNumberOfColumns: (numberOfColumns: number) => void
    setNumberOfRows: (numberOfRows: number) => void
    legends: Legend<ScaleType>[]
    symbolLegend?: SymbolLegend
    additionalInputs?: JSX.Element
    locale: Locale
    setColumnName: (colNum: number, headerText: string) => void
    setCellData: (params: { rowNum: number; colNum: number; cellData: CellData }) => void
    onClear?: (rowCol: { rowNum: number; colNum: number }) => void
    editCellComponent: (props: EditCellComponentProps<CellData>) => JSX.Element
    cellViewComponent: (props: ViewCellComponentProps<CellData>) => JSX.Element
    cellEditModalTitleText?: (rowNum: number, colNum: number) => string
    minRows?: number
    disabled?: boolean
}

export function TableEditor<CellData, ScaleType = TableScaleHarveyBallScaleType>({
    itemId,
    headers,
    dataTestId = {},
    rows,
    setNumberOfColumns,
    setNumberOfRows,
    legends,
    symbolLegend,
    additionalInputs,
    locale,
    setColumnName,
    defaultCellData,
    onClear,
    setCellData,
    editCellComponent,
    cellViewComponent,
    cellEditModalTitleText,
    minRows,
    disabled,
}: TableEditorProps<CellData, ScaleType>) {
    const {
        table: tableDataTestId,
        headerCell: headerCellDataTestId,
        row: rowDataTestId,
        cell: cellDataTestId,
        warningModal: warningModalDataTestId,
        deleteModal: deleteModalDataTestId,
    } = dataTestId
    const {
        matches: { s: small },
    } = useBreakpoints()

    const [nextNumberOfRows, setNextNumberOfRows] = useState<number>(rows.length)

    const [nextNumberOfColumns, setNextNumberOfColumns] = useState<number>(
        headers.filter((h) => h.editable).length
    )

    const [useBlurForNumberOfColumns, setUseBlurForNumberOfColumns] = useState(true)

    const [useBlurForNumberOfRows, setUseBlurForNumberOfRows] = useState(true)

    const [isLoseTableDataWarningModalOpen, setIsLoseTableDataWarningModalOpen] = useState(false)

    const [isMobileWarningOpen, setIsMobileWarningOpen] = useState(false)

    const [ackMobileRisk, setAckMobileRisk] = useState(false)

    const columnSlices: number[][] = useMemo(() => {
        const colsPerTable = small ? 1 : headers.length
        return _.chunk(_.range(headers.length), colsPerTable)
    }, [small, headers.length])

    const numberOfColumns = headers.filter((h) => h.editable).length

    const renderSymbolWarningModal = ({ close }: { close: () => void }) => (
        <ModalContent
            dataTestId='remove-symbol-legends-modal'
            titleText={'Stop using symbol legends'}
            buttons={[
                <Button
                    key='rempve-symbol-legends'
                    isDestructive
                    dataTestId='yes-stop-using-symbol-legends'
                    onClick={() => {
                        symbolLegend?.setEnabled(false)
                        close()
                    }}
                >
                    Yes, Stop using symbol legends
                </Button>,
                <Button
                    key='close-media'
                    onClick={close}
                    variant={ButtonVariant.Primary}
                    dataTestId='keep-using-symbol-legends'
                >
                    No, keep symbol legends
                </Button>,
            ]}
        >
            <Row gridGap='S400'>
                <Text>
                    Are you sure you want to stop using symbol legends? This will discard all your
                    symbol legends.
                </Text>
            </Row>
        </ModalContent>
    )
    const scaleLegendLabelId = `${itemId}-scale-legend`

    const symbolLegendMap = new Map<TableSymbolType, string>()

    symbolLegend?.legendDefinitions.forEach((l) => {
        if (l.symbolType) {
            symbolLegendMap.set(l.symbolType, l.symbolLegendText ?? '')
        }
    })

    return (
        <Col gridGap='S300'>
            <H4>Table Layout</H4>

            {/* table layout settings */}
            <Row gridGap={small ? 'S200' : 'S300'} alignItems='center' width='100%' flexWrap='wrap'>
                <Col flex='1 1 40vw' maxWidth={small ? undefined : '120px'}>
                    <ItemEditorNumberInput
                        dataTestId={'columns-input'}
                        inputId={'table-columns'}
                        itemId={itemId}
                        labelText={'Table columns'}
                        disabled={!isDefaultLocale(locale) || disabled}
                        placeholder=''
                        value={numberOfColumns}
                        min={1}
                        max={20}
                        setOnBlur={useBlurForNumberOfColumns}
                        setValue={(nextValue: number | null) => {
                            setUseBlurForNumberOfColumns(true)
                            if (nextValue !== null) {
                                if (nextValue > numberOfColumns) {
                                    if (nextValue > 4 && !ackMobileRisk) {
                                        setIsMobileWarningOpen(true)
                                        setNextNumberOfColumns(nextValue)
                                    } else {
                                        setNumberOfColumns(nextValue)
                                    }
                                } else if (nextValue < numberOfColumns) {
                                    setIsLoseTableDataWarningModalOpen(true)
                                    setNextNumberOfColumns(nextValue)
                                }
                            }
                        }}
                    />
                </Col>
                <Col flex='1 1 40vw' maxWidth={small ? undefined : '120px'}>
                    <ItemEditorNumberInput
                        dataTestId={'rows-input'}
                        inputId={'table-rows'}
                        itemId={itemId}
                        labelText={'Table rows'}
                        disabled={!isDefaultLocale(locale) || disabled}
                        placeholder=''
                        min={minRows ?? 1}
                        max={20}
                        value={rows.length}
                        setOnBlur={useBlurForNumberOfRows}
                        setValue={(nextValue: number | null) => {
                            setUseBlurForNumberOfRows(true)
                            if (nextValue !== null) {
                                if (nextValue > rows.length) {
                                    if (nextValue > 5 && !ackMobileRisk) {
                                        setIsMobileWarningOpen(true)
                                        setNextNumberOfRows(nextValue)
                                    } else {
                                        setNumberOfRows(nextValue)
                                    }
                                } else if (nextValue < rows.length) {
                                    setIsLoseTableDataWarningModalOpen(true)
                                    setNextNumberOfRows(nextValue)
                                }
                            }
                        }}
                    />
                </Col>
                {legends.map((l) => (
                    <Col
                        key={l.legendType}
                        flex={small ? '1 1 80vw' : '0 1 auto'}
                        padding={{ top: 'S100', bottom: 'S100' }}
                    >
                        <Spacer height='S400' />
                        <ItemEditorCheckboxInput
                            inputId={`${l.legendType}-checkbox`}
                            dataTestId={l.legendDataTestId}
                            disabled={!isDefaultLocale(locale) || disabled}
                            itemId={itemId}
                            labelText={`Use ${l.legendHumanReadableName} scale`}
                            value={l.enabled}
                            setValue={(enabled: boolean) => l.setEnabled(enabled)}
                        />
                    </Col>
                ))}
                {symbolLegend && (
                    <Col
                        flex={small ? '1 1 80vw' : '0 1 auto'}
                        padding={{ top: 'S100', bottom: 'S100' }}
                    >
                        <Spacer height='S400' />
                        <WithModal renderModal={renderSymbolWarningModal}>
                            {({ open }) => (
                                <ItemEditorCheckboxInput
                                    inputId={'symbol-legend-checkbox'}
                                    dataTestId={symbolLegend.legendDataTestId}
                                    disabled={!isDefaultLocale(locale) || disabled}
                                    itemId={itemId}
                                    labelText={`Use ${symbolLegend.legendHumanReadableName}`}
                                    value={symbolLegend.enabled}
                                    setValue={(enabled: boolean) => {
                                        enabled ? symbolLegend.setEnabled(enabled) : open()
                                    }}
                                />
                            )}
                        </WithModal>
                    </Col>
                )}
                {additionalInputs}
            </Row>

            {/* modal to warn that the number of columns/rows requested will lead to data loss */}
            <WarningModal
                open={isLoseTableDataWarningModalOpen}
                title='Delete content?'
                modalTestId={deleteModalDataTestId ?? 'table-delete-content-modal'}
                buttons={[
                    {
                        variant: ButtonVariant.Secondary,
                        label: 'Yes, proceed',
                        isDestructive: true,
                        value: true,
                        dataTestId: 'yes-button',
                    },
                    {
                        variant: ButtonVariant.Primary,
                        label: `No, keep ${
                            nextNumberOfColumns != numberOfColumns ? 'cols' : 'rows'
                        }`,
                        value: false,
                        dataTestId: 'no-button',
                    },
                ]}
                onClose={(value?: boolean | number | string) => {
                    if (value) {
                        setNumberOfColumns(nextNumberOfColumns)
                        setNumberOfRows(nextNumberOfRows)
                    } else {
                        setUseBlurForNumberOfColumns(false)
                        setUseBlurForNumberOfRows(false)
                    }
                    setIsLoseTableDataWarningModalOpen(false)
                }}
            >
                <P>
                    Making this change will remove{' '}
                    {rows.length - nextNumberOfRows !== 0 &&
                        `${rows.length - nextNumberOfRows} rows `}
                    {numberOfColumns - nextNumberOfColumns !== 0 &&
                        `${numberOfColumns - nextNumberOfColumns} columns `}
                    from your layout with data in them.
                </P>
                <P>Are you sure you want to proceed?</P>
            </WarningModal>

            {/* modal to warn the number of columns/rows exceeds recommendations for mobile */}
            <WarningModal
                open={isMobileWarningOpen}
                title='Mobile layout risk'
                modalTestId={warningModalDataTestId ?? 'table-mobile-warning-modal'}
                buttons={[
                    {
                        variant: ButtonVariant.Secondary,
                        label: 'Cancel',
                        value: false,
                    },
                    {
                        variant: ButtonVariant.Primary,
                        label: 'I understand',
                        value: true,
                    },
                ]}
                onClose={(value?: boolean | number | string) => {
                    // acknowledge
                    setAckMobileRisk(!!value)
                    setIsMobileWarningOpen(false)
                    if (value) {
                        setNumberOfRows(nextNumberOfRows)
                        setNumberOfColumns(nextNumberOfColumns)
                    } else {
                        setUseBlurForNumberOfColumns(false)
                        setUseBlurForNumberOfRows(false)
                    }
                }}
            >
                <P>
                    Adding more than 4 columns or 5 rows may negatively impact mobile layouts,
                    depending on the length of your content. This will make these layouts difficult
                    or impossible for users on phones.
                </P>
                <P>
                    Ensure you preview and check your module at mobile sizes to see if the layout
                    breaks.
                </P>
            </WarningModal>

            {legends
                .filter((l) => l.enabled)
                .map((legend) => {
                    return (
                        <View
                            dataTestId={`${legend.legendType}-scales-section`}
                            key={legend.legendType}
                        >
                            <Row gridGap='S300'>
                                <Label
                                    id={scaleLegendLabelId}
                                >{`${legend.legendHumanReadableName} scale legend`}</Label>
                            </Row>
                            <Flex
                                flexDirection={small ? 'column' : 'row'}
                                gridGap='S300'
                                aria-labelledby={scaleLegendLabelId}
                            >
                                {legend.scales.map((scale, index: number) => (
                                    <Col flex='1' key={index}>
                                        <ScaleValueInput2
                                            dateTestId={`${scale.scaleDataTestId}`}
                                            inputId={`table-${
                                                legend.legendHumanReadableName ?? ''
                                            }-scale-${index}`}
                                            itemId={itemId}
                                            labelText={`Scale legend for ${
                                                scale.scaleType as never as string
                                            }`}
                                            placeholderText={`${
                                                scale.scaleType as never as string
                                            }`}
                                            value={scale.value}
                                            locale={locale}
                                            onChange={(value) =>
                                                legend.onScaleChange(scale.scaleType, value)
                                            }
                                            insetElementLeading={scale.insetElementLeading}
                                            disabled={disabled}
                                        />
                                    </Col>
                                ))}
                            </Flex>
                        </View>
                    )
                })}
            {symbolLegend && symbolLegend.enabled && (
                <View dataTestId={'symbol-scales-section'}>
                    <Flex flexDirection='column' gridGap='S300'>
                        <Row gridGap='S300'>
                            <H5>Symbols</H5>
                        </Row>
                        <Col role='list' gridGap='S300'>
                            {symbolLegend.legendDefinitions.map((legendDef, index: number) => {
                                return (
                                    <Col
                                        flex='1'
                                        key={legendDef.index}
                                        role='listitem'
                                        aria-label={`Symbol legend ${index + 1}`}
                                    >
                                        <SymbolLegendInput
                                            itemId={itemId}
                                            currentSymbol={legendDef.symbolType}
                                            symbolLegendText={legendDef.symbolLegendText}
                                            locale={locale}
                                            availableSymbols={getAvailableSymbols(
                                                symbolLegend?.legendDefinitions
                                            )}
                                            onLegendChange={(value: string) => {
                                                symbolLegend?.onDefTextChange(
                                                    value,
                                                    legendDef.index
                                                )
                                            }}
                                            onSymbolChange={(symbol: TableSymbolType) => {
                                                symbolLegend?.onDefSymbolTypeChange(
                                                    symbol,
                                                    legendDef.index
                                                )
                                            }}
                                            index={index}
                                            deleteIsDisabled={
                                                symbolLegend?.legendDefinitions.length == 1
                                            }
                                            deleteSymbolLegend={() =>
                                                symbolLegend?.onLegendDelete(legendDef.index)
                                            }
                                            disabled={disabled}
                                        />
                                    </Col>
                                )
                            })}
                        </Col>
                        <Row justifyContent='flex-start'>
                            <Button
                                icon={<IconPlus aria-hidden />}
                                variant={ButtonVariant.Tertiary}
                                aria-disabled={
                                    symbolLegend.legendDefinitions.length ===
                                        symbolLegend.numOfSymbols ||
                                    !isDefaultLocale(locale) ||
                                    disabled
                                }
                                onClick={() => {
                                    symbolLegend?.createEmptySymbolLegend()
                                    focusOnId(
                                        `${itemId}-symbol-legend-${
                                            (symbolLegend?.legendDefinitions ?? []).length
                                        }-symbol-toggle-button`
                                    )
                                }}
                                dataTestId={'add-legend-button'}
                            >
                                Add Symbol
                            </Button>
                        </Row>
                    </Flex>
                </View>
            )}

            <H4>Table content</H4>
            <Col gridGap='S200' data-test-id={tableDataTestId ?? 'Table'}>
                {columnSlices.map((slice: number[], k) => {
                    const set = new Set(slice)
                    const filterFunc = (_entry: unknown, i: number) => set.has(i)
                    return (
                        <TableElement key={k}>
                            <TheadElement>
                                <TrElement>
                                    {headers.filter(filterFunc).map((h: Header, i: number) => (
                                        <TableHeaderCell2
                                            locale={locale}
                                            dataTestId={
                                                headerCellDataTestId
                                                    ? `${headerCellDataTestId}-${i}`
                                                    : headerCellDataTestId
                                            }
                                            key={i}
                                            header={h}
                                            itemId={itemId}
                                            columnIndex={i}
                                            updateHeader={(nextColumnName) =>
                                                setColumnName(i, nextColumnName)
                                            }
                                            disabled={disabled}
                                        />
                                    ))}
                                </TrElement>
                            </TheadElement>
                            <TbodyElement>
                                {rows.map((row, rowIndex: number) => (
                                    <TrElement
                                        key={rowIndex}
                                        data-test-id={rowDataTestId ?? 'ranking-table-response-row'}
                                    >
                                        {row
                                            .filter(filterFunc)
                                            .map(
                                                (
                                                    cellData: CellData | undefined,
                                                    columnIndex: number
                                                ) => (
                                                    <TableCell2
                                                        key={`cell-${rowIndex}-${columnIndex}`}
                                                        dataTestId={
                                                            cellDataTestId
                                                                ? `${cellDataTestId}-${rowIndex}-${columnIndex}`
                                                                : cellDataTestId
                                                        }
                                                        itemId={itemId}
                                                        defaultNextData={defaultCellData}
                                                        cellData={cellData}
                                                        cellEditComponent={editCellComponent}
                                                        cellViewComponent={cellViewComponent}
                                                        columnNumber={columnIndex}
                                                        rowNumber={rowIndex}
                                                        onClear={onClear}
                                                        saveChanges={(nextCellData: CellData) => {
                                                            setCellData({
                                                                rowNum: rowIndex,
                                                                colNum: columnIndex,
                                                                cellData: nextCellData,
                                                            })
                                                        }}
                                                        cellEditModalTitleText={
                                                            cellEditModalTitleText
                                                        }
                                                        symbolLegends={symbolLegendMap}
                                                        disabled={disabled}
                                                    />
                                                )
                                            )}
                                    </TrElement>
                                ))}
                            </TbodyElement>
                        </TableElement>
                    )
                })}
            </Col>
        </Col>
    )
}
