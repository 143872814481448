import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col, Flex } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Spinner } from '@amzn/stencil-react-components/spinner'

import { DropdownButton } from 'src/components/DropdownButton'
import { ModuleGroupDeploymentModalContent } from 'src/components/module-groups/ModuleGroupDeploymentModalContent'
import { ModuleGroupReviewRequest } from 'src/models/dto/approval/ReviewDTO'
import { ErrorResponseThrowable } from 'src/models/dto/ErrorResponse'
import {
    ModuleGroupDTO,
    ModuleGroupStatus,
    Review,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleDeploymentTargetStage } from 'src/models/dto/ModuleDeployment'
import { focusOnId } from 'src/pages/module-builder/focus'
import { useFetchModuleGroupOnInterval } from 'src/pages/module-groups/hooks'
import { RenderModuleGroupBuilderModal } from 'src/pages/module-groups/ModuleGroupBuilderModal'
import { ModuleGroupReviewForm } from 'src/pages/module-groups/ModuleGroupReviewForm'
import { moduleGroupViewerRoute } from 'src/pages/module-review'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'

enum ModuleGroupOptionsModal {
    REQUEST_REVIEW = 'REQUEST_REVIEW',
    DEPLOY_MODULE_GROUP_UAT = 'DEPLOY_MODULE_GROUP_UAT',
    EDIT_GROUP_INFORMATION = 'EDIT_GROUP_INFORMATION',
}

export enum ModuleGroupOptionValues {
    REQUEST_REVIEW = 'REQUEST_REVIEW',
    OPEN_REVIEW = 'OPEN_REVIEW',
    BACK_TO_VIEWER = 'BACK_TO_VIEWER',
    DEPLOY_UAT = 'DEPLOY_UAT',
    RUN_ERROR_CHECK = 'RUN_ERROR_CHECK',
    EDIT_GROUP_INFORMATION = 'EDIT_GROUP_INFORMATION',
}

export interface RenderModalGroupProps {
    moduleGroupEntity: ModuleGroupDTO
    moduleGroupStatus: ModuleGroupStatus
    reviewStatus?: Review | undefined
    close: () => void
}

export function RequestGroupReviewModal({
    moduleGroupEntity,
    moduleGroupStatus,
    close,
}: RenderModalGroupProps) {
    const [reviewInput, setReviewInput] = useState<ModuleGroupReviewRequest | null>(null)
    const [error, setError] = useState('')
    const [validationError, setValidationError] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)

    const { submitting, setSubmitting, timedOut, reviewStatus, fetchError } =
        useFetchModuleGroupOnInterval(moduleGroupEntity.versionId)

    const navigate = useNavigate()

    useEffect(() => {
        if (reviewStatus) {
            navigate(
                moduleGroupViewerRoute({
                    moduleGroupVersionId: moduleGroupEntity.versionId,
                    reviewId: reviewStatus.reviewId,
                    revisionNumber: reviewStatus.revisionNumber.toString(),
                })
            )
        }
    }, [moduleGroupEntity.versionId, navigate, reviewStatus])

    useEffect(() => {
        if (timedOut) {
            setError(
                'Timed out while trying to retrieve review information. Please refresh the page and try again.'
            )
        }
        if (fetchError) {
            setError(
                'Something went wrong while fetching review information. Please refresh the page and try again.'
            )
        }
    }, [timedOut, fetchError])

    const submit = async () => {
        if (
            ModuleGroupStatus[moduleGroupStatus as unknown as keyof ModuleGroupStatus] ===
                ModuleGroupStatus.DRAFT_UNVALIDATED ||
            moduleGroupStatus === ModuleGroupStatus.DRAFT_UNVALIDATED
        ) {
            setValidationError('Cannot create a review when module group is in unvalidated state.')
            return
        }

        if (!reviewInput || reviewInput.description === '') {
            setDescriptionError(true)
            return
        }

        setSubmitting(true)
        try {
            await ApprovalService.initiateModuleGroupReviewProcess(reviewInput)
        } catch (e: unknown) {
            console.error('Error while requesting review: ', e)
            setSubmitting(false)
            setError(
                `Error while requesting module group review: ${
                    (e as ErrorResponseThrowable)?.errorResponse?.message ?? (e as Error).message
                }`
            )
        }
    }

    return (
        <ModalContent
            titleText='Request Review'
            buttons={[
                <Button
                    key={0}
                    dataTestId='request-review-cancel'
                    variant={ButtonVariant.Tertiary}
                    onClick={close}
                >
                    Cancel
                </Button>,
                <Button
                    key={1}
                    dataTestId='request-review-submit'
                    variant={ButtonVariant.Primary}
                    onClick={submit}
                >
                    Submit
                </Button>,
            ]}
            maxWidth='80vw'
        >
            <Col gridGap='S400' minWidth='240px' width='50vw' height='40vh'>
                {error && (
                    <Flex flexDirection={'column'}>
                        <MessageBanner
                            dataTestId={'review-submit-error'}
                            type={MessageBannerType.Error}
                        >
                            {error}
                        </MessageBanner>
                    </Flex>
                )}
                {validationError && (
                    <Flex flexDirection={'column'}>
                        <MessageBanner
                            dataTestId={'review-validation-error'}
                            type={MessageBannerType.Error}
                        >
                            {validationError}
                        </MessageBanner>
                    </Flex>
                )}
                {ModuleGroupStatus[moduleGroupStatus as unknown as keyof ModuleGroupStatus] ===
                    ModuleGroupStatus.DRAFT_UNVALIDATED && (
                    <Flex flexDirection={'column'}>
                        <MessageBanner
                            dataTestId={'review-submit-error'}
                            type={MessageBannerType.Warning}
                        >
                            You will not be able to request review for unvalidated draft.
                            <br />
                            Please make sure your changes are validated and saved before requesting
                            review.
                        </MessageBanner>
                    </Flex>
                )}
                {submitting && <Spinner />}
                <ModuleGroupReviewForm
                    versionId={moduleGroupEntity.versionId}
                    onCreateGroupReviewInputChange={setReviewInput}
                    moduleName={moduleGroupEntity.name}
                    descriptionError={descriptionError}
                    setDescriptionError={setDescriptionError}
                />
            </Col>
        </ModalContent>
    )
}

export function ModuleGroupOptions({
    moduleGroupEntity,
    moduleGroupStatus,
    reviewStatus,
    optionsToInclude,
    onErrorCheck,
}: {
    moduleGroupEntity: ModuleGroupDTO
    moduleGroupStatus: ModuleGroupStatus
    reviewStatus: Review | undefined
    optionsToInclude: Set<ModuleGroupOptionValues>
    onErrorCheck?: (hasValidationError: boolean) => void
}) {
    const [openModal, setOpenModal] = useState<ModuleGroupOptionsModal | null>(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const dropdownValues = (open: () => void) => {
        const launchReview = {
            name: reviewStatus ? 'Open review' : 'Request review',
            dataTestId: 'request-review-option',
            onClick: () => {
                if (reviewStatus) {
                    navigate(
                        moduleGroupViewerRoute({
                            moduleGroupVersionId: moduleGroupEntity.versionId,
                            reviewId: reviewStatus.reviewId,
                            revisionNumber: reviewStatus.revisionNumber.toString(),
                        })
                    )
                } else {
                    setOpenModal(ModuleGroupOptionsModal.REQUEST_REVIEW)
                    open()
                }
            },
        }

        const editModuleGroupInformation = {
            name: 'Edit Module Group Details',
            dataTestId: 'edit-module-group-details-option',
            onClick: () => {
                setOpenModal(ModuleGroupOptionsModal.EDIT_GROUP_INFORMATION)
                open()
            },
        }

        const backToViewer = {
            name: 'Back to Viewer',
            onClick: () => {
                // AA-22688 Reset the focus to the top of the page for accessibility reasons.
                focusOnId('module-header-bread-crumb')
                navigate(
                    moduleGroupViewerRoute({
                        moduleGroupVersionId: moduleGroupEntity.versionId,
                    })
                )
            },
        }

        const launchDeployUAT = {
            name: 'Deploy to UAT',
            dataTestId: 'deploy-modules-uat',
            onClick: () => {
                setOpenModal(ModuleGroupOptionsModal.DEPLOY_MODULE_GROUP_UAT)
                open()
            },
        }

        const launchErrorCheck = {
            name: 'Run error check',
            dataTestId: 'run-error-check-option',
            onClick: async () => {
                setLoading(true)
                try {
                    await ErrorCheckService.moduleGroupErrorCheck(moduleGroupEntity)
                    onErrorCheck?.(false)
                    setLoading(false)
                } catch (e: unknown) {
                    onErrorCheck?.(true)
                    console.error(e)
                    setLoading(false)
                }
            },
        }

        const values: { name: string; onClick: () => void; dataTestId?: string }[] = []

        if (optionsToInclude.has(ModuleGroupOptionValues.OPEN_REVIEW)) {
            values.push(launchReview)
        }

        if (optionsToInclude.has(ModuleGroupOptionValues.BACK_TO_VIEWER)) {
            values.push(backToViewer)
        }

        if (optionsToInclude.has(ModuleGroupOptionValues.DEPLOY_UAT)) {
            values.push(launchDeployUAT)
        }

        if (optionsToInclude.has(ModuleGroupOptionValues.RUN_ERROR_CHECK)) {
            values.push(launchErrorCheck)
        }

        if (optionsToInclude.has(ModuleGroupOptionValues.EDIT_GROUP_INFORMATION)) {
            values.push(editModuleGroupInformation)
        }

        return values
    }

    const renderModals: Record<ModuleGroupOptionsModal, React.FC<RenderModalGroupProps>> = useMemo(
        () => ({
            [ModuleGroupOptionsModal.REQUEST_REVIEW]: (props) => (
                <RequestGroupReviewModal {...props} />
            ),
            [ModuleGroupOptionsModal.DEPLOY_MODULE_GROUP_UAT]: (props) => (
                <ModuleGroupDeploymentModalContent
                    moduleGroupVersionId={props.moduleGroupEntity.versionId}
                    targetStage={ModuleDeploymentTargetStage.UAT}
                    close={props.close}
                />
            ),
            [ModuleGroupOptionsModal.EDIT_GROUP_INFORMATION]: (props) => {
                return (
                    <RenderModuleGroupBuilderModal
                        moduleGroupEditEntity={props.moduleGroupEntity}
                        setRenderOptionModal={props.close}
                    />
                )
            },
        }),
        []
    )

    const renderModal = useCallback(
        ({ close }: { close: () => void }) => {
            return openModal
                ? renderModals[openModal]({
                      close,
                      moduleGroupEntity,
                      moduleGroupStatus,
                      reviewStatus,
                  })
                : null
        },
        [openModal, renderModals, moduleGroupEntity, moduleGroupStatus, reviewStatus]
    )

    return (
        <>
            <WithModal renderModal={renderModal} isScrollable={false}>
                {({ open }) => (
                    <>
                        {loading ? <Spinner dataTestId='loading-spinner' /> : null}
                        <div style={{ visibility: loading ? 'hidden' : undefined }}>
                            <DropdownButton
                                title='Options'
                                values={dropdownValues(open)}
                                dataTestId={'module-group-options'}
                            />
                        </div>
                    </>
                )}
            </WithModal>
        </>
    )
}
