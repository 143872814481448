import * as React from 'react'
import { useState } from 'react'
import { DateTime } from 'luxon'

import { InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { SearchField } from '@amzn/stencil-react-components/search'
import { Text } from '@amzn/stencil-react-components/text'

import { STAGE } from 'src/config.app'
import {
    HookSearchWorkflowIDsResponse,
    HookWorkflowObject,
    searchWorkflowIds,
    WorkflowIdSearchParams,
} from 'src/pages/scoring-test-ui/ScoringTestApiManager'

export interface WorkflowSearchFieldProps {
    workflowIdIsEmpty: boolean
    onValueChange: (value: string) => void
    workflowId: string
    width?: string
    stage?: STAGE
}

export const WorkflowSearchField = (props: WorkflowSearchFieldProps) => {
    const { onValueChange, workflowIdIsEmpty, workflowId } = props
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [workflowList, setWorkflowList] = useState<HookWorkflowObject[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const renderResult = (option: HookWorkflowObject) => {
        if (option.lastModifiedAt && option.lastModifiedBy) {
            const formattedDate: string = DateTime.fromISO(option.lastModifiedAt, {
                zone: 'UTC',
            })
                .setZone('system')
                .toLocaleString({ month: 'short', day: '2-digit', year: 'numeric' })

            return (
                <Row>
                    <ScreenReaderOnly>
                        {option.id}
                        {`last Updated: ${formattedDate}`}
                        {`by: ${option.lastModifiedBy}`}
                    </ScreenReaderOnly>
                    <Col height='auto' minWidth='S100' width='S100' />
                    <Col padding={[5, 0]}>
                        <Text
                            aria-hidden={true}
                            fontSize='T200'
                            color='neutral100'
                            fontWeight='bold'
                        >
                            {option.id}
                        </Text>
                        <Row>
                            <Text aria-hidden={true} color='neutral70'>
                                Last updated at:
                            </Text>
                            <Spacer width='S100' />
                            <Text aria-hidden={true} color='primary70' fontWeight='bold'>
                                {formattedDate}
                            </Text>
                            <Spacer width='S100' />
                            <Text aria-hidden={true} color='neutral70'>
                                by:
                            </Text>
                            <Spacer width='S100' />
                            <Text aria-hidden={true} color='primary70' fontWeight='bold'>
                                {option.lastModifiedBy}
                            </Text>
                        </Row>
                    </Col>
                </Row>
            )
        }
        return (
            <Row>
                <ScreenReaderOnly>{option.id}</ScreenReaderOnly>
                <Col height='auto' minWidth='S100' width='S100' />
                <Text aria-hidden={true} fontSize='T200' color='neutral100' fontWeight='bold'>
                    {option.id}
                </Text>
            </Row>
        )
    }

    const searchWorkflows = async (value: string) => {
        onValueChange(value)
        setErrorMessage('')
        if (value?.length <= 3) {
            setWorkflowList([])
            return
        }
        setIsLoading(true)
        const workflowIdSearchParams: WorkflowIdSearchParams = {
            query: value,
            offset: 0,
            limit: 25,
        }
        const searchWorkflowRes: HookSearchWorkflowIDsResponse = await searchWorkflowIds(
            workflowIdSearchParams,
            props.stage
        )
        if (searchWorkflowRes.error) {
            setErrorMessage(searchWorkflowRes.error)
            setWorkflowList([])
        }
        setIsLoading(false)
        setWorkflowList(searchWorkflowRes.workflows)
    }

    const resultToString = (result: HookWorkflowObject) => result.id

    return (
        <Col display='inline-flex' width={props.width ? props.width : undefined}>
            <InputWrapper
                id='workflow-id-wrapper'
                labelText={'Workflow ID'}
                footer={
                    !!errorMessage || workflowIdIsEmpty
                        ? errorMessage || 'Please enter or select a valid workflow Id'
                        : undefined
                }
                error={!!errorMessage || workflowIdIsEmpty}
            >
                {(inputProps) => (
                    <SearchField
                        data-test-id='workflow-id'
                        name='Workflow ID'
                        results={workflowList}
                        resultToString={resultToString}
                        renderResult={renderResult}
                        {...inputProps}
                        onChange={searchWorkflows}
                        onResultClick={(workflowObject: HookWorkflowObject) =>
                            onValueChange(workflowObject.id)
                        }
                        query={workflowId}
                        error={!!errorMessage || workflowIdIsEmpty}
                        placeholder='Enter the Workflow ID here...'
                        listMaxHeight='45vh'
                        isLoading={isLoading}
                    />
                )}
            </InputWrapper>
        </Col>
    )
}
