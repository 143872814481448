import { AssessmentMedia } from 'src/models/AssessmentMedia'
import { S3FileState } from './LocalMediaFilesService'

/**
 * information about the list of media files
 * related to the user
 */
export interface UserMediaInfo {
    id: number
    url: string
    selected: boolean
    s3State: S3FileState
    assessmentMedia: AssessmentMedia
}

/**
 * media header entry type
 */
export interface MediaCSVHeader {
    label: string
    key: string
}

/**
 * media CSV File headers
 */
// const mediaCsvHeaders: MediaCSVHeader[] = [
//     { label: 'id', key: 'id' },
//     { label: 'versionId', key: 'versionId' },
//     { label: 'locale', key: 'locale' },
//     { label: 'relativePath', key: 'relativePath' },
//     { label: 'mimeType', key: 'mimeType' },
//     { label: 'altText', key: 'alternativeText' },
//     { label: 'mediaLabel', key: 'label' },
//     { label: 'tags', key: 'tags' },
// ]

/**
 * CSV headers and data to represend a media CSV file
 */
export interface MediaCSV {
    headers: MediaCSVHeader[]
    data: {
        id: string
        versionId: string
        locale: string
        relativePath: string
        mimeType: string
        alternativeText: string
        label: string
    }[]
}

/**
 * this service keeps track of the files displayed for the user
 */
export class UserMediaService {
    private userMediaList: UserMediaInfo[]
    // private assessmentBuilderClient: CSVConversionService = new CSVConversionService()

    constructor() {
        this.userMediaList = []
    }

    fixLocale(value: string): string {
        return value.replace('-', '_')
    }

    validateAlternativeText(value: string) {
        if (value.trim().length === 0) {
            throw new Error('Cannot be empty')
        }
    }

    validateMediaLabel(value: string) {
        const normalizedValue = value.trim().toLowerCase()

        if (normalizedValue.length === 0) {
            throw new Error('Cannot be empty')
        }
        if (
            this.userMediaList.filter(
                (m) => m.assessmentMedia.label.trim().toLocaleLowerCase() === normalizedValue
            ).length > 1
        ) {
            throw new Error('Cannot be duplicated with other label')
        }
    }

    getLastId() {
        return Math.max(...[...this.userMediaList.map((m) => m.id), 0])
    }

    get() {
        return this.userMediaList
    }

    add(media: UserMediaInfo) {
        this.userMediaList.push(media)
        return this.userMediaList
    }

    find(id: number) {
        return this.userMediaList.find((m) => m.id === id)
    }

    update(media: UserMediaInfo) {
        const index = this.userMediaList.indexOf(media)

        if (index) {
            this.userMediaList[index] = media
            return media
        }
        return null
    }

    // generateCSVData() {
    //     const selected = this.userMediaList.filter((m) => m.selected)

    //     const data = selected.map((s) => {
    //         return {
    //             id: v4(),
    //             versionId: v4(),
    //             locale: this.fixLocale(s.assessmentMedia.locale),
    //             relativePath: s.assessmentMedia.relativePath ?? '',
    //             mimeType: s.assessmentMedia.mimeType ?? '',
    //             alternativeText: s.assessmentMedia.alternativeText,
    //             label: s.assessmentMedia.label,
    //             tags: s.assessmentMedia.tags,
    //         }
    //     })

    //     return {
    //         headers: mediaCsvHeaders,
    //         data,
    //     }
    // }
}

export class UserMediaServiceFactory {
    private static testing: UserMediaService

    public static loadExisting(): UserMediaService {
        if (!UserMediaServiceFactory.testing) {
            UserMediaServiceFactory.testing = UserMediaServiceFactory.fromScratch()
        }

        return UserMediaServiceFactory.testing
    }

    public static fromScratch() {
        return new UserMediaService()
    }
}
