import { startCase } from 'lodash'
import { v4 } from 'uuid'

import { StaticContentLayoutType } from 'src/models/dto/items/StaticContentLayoutDTO'
import { Locale } from 'src/models/dto/Locale'

export enum ItemType {
    FreeText = 'FreeText',
    Likert = 'Likert',
    LikertStimulus = 'LikertStimulus',
    LikertGroup = 'LikertGroup',
    AudioEvaluation = 'AudioEvaluation',
    AudioCheck = 'AudioCheck',
    Rating = 'Rating',
    RankingResponseTable = 'RankingResponseTable',
    Table = 'Table',
    MultipleChoice = 'MultipleChoice',
    StaticContentLayout = 'StaticContentLayout',
    FlyoutInstructionalContentItem = 'FlyoutInstructionalContent',
    Content = 'Content',
    SingleSelectCheckbox = 'SingleSelectCheckbox',
    WrittenResponse = 'WrittenResponse',
    MediaStimulus = 'MediaStimulus',
    CompletionCode = 'CompletionCode',
    EmailContent = 'EmailContent',
    Chat = 'Chat',
    VirtualMeeting = 'VirtualMeeting',
    WritingExercise = 'WritingExercise',
    InformationImages = 'InformationImages',
    ContextBox = 'ContextBox',
}

export const EDITABLE_ITEM_TYPES = [
    ItemType.FreeText,
    ItemType.LikertGroup,
    ItemType.AudioEvaluation,
    ItemType.AudioCheck,
    ItemType.Rating,
    ItemType.RankingResponseTable,
    ItemType.MultipleChoice,
    ItemType.Content,
    ItemType.SingleSelectCheckbox,
    ItemType.Table,
    ItemType.WrittenResponse,
    ItemType.MediaStimulus,
    ItemType.EmailContent,
    ItemType.Chat,
    ItemType.VirtualMeeting,
    ItemType.WritingExercise,
    ItemType.InformationImages,
]

export const EXCLUDED_FROM_PRODUCTION_ITEM_TYPES = []

export const getItemTitle = (itemType: ItemType | StaticContentLayoutType): string => {
    switch (itemType) {
        case ItemType.InformationImages:
            return 'Image Set'
        case StaticContentLayoutType.STATIC_MEDIA_LIST:
        case StaticContentLayoutType.STATIC_SINGLE_MEDIA:
        case StaticContentLayoutType.STATIC_TEXT_WRAPPED_MEDIA:
            return `${startCase(ItemType.StaticContentLayout)} - ${startCase(
                itemType.toLowerCase()
            )}`
        default:
            return startCase(itemType)
    }
}

export interface ItemDTO {
    id: string
    name: string // This is referenceId
    itemType: ItemType
    locale: string
    label: string
    optional: boolean
    preserveOrder: boolean
    ppt: string // for backward compatibility with ItemDTO on the BE
    instructionalContent?: InstructionalContentDTO
}

// Must be declared here to avoid circular dependency
export interface InstructionalContentDTO {
    id: string
    name: string
    items: ItemDTO[]
}

export const defaultBaseItemDTO = (itemType: ItemType) => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        label: '',
        optional: false,
        preserveOrder: false,
        locale: Locale.en_US,
        ppt: itemType,
        itemType,
    }
}
