import { useEffect, useState } from 'react'

import { useAsync } from '@amzn/stencil-react-components/hooks'

import { downloadFileFromS3 } from 'src/services/media/S3FileHandler'
import { MediaContentsEditor } from './MediaContentsEditor'

export function getName(url: string) {
    return /(?:^|\/)([^/]*$)/[Symbol.match](url)?.[1] ?? url
}

export function sizeDescription(bytes: number) {
    const MB_THRESHOLD = 1e5
    const CONVERT_MB = 1e-6
    const CONVERT_KB = 1e-3
    const opts = { maximumSignificantDigits: 3 }
    return bytes > MB_THRESHOLD
        ? (bytes * CONVERT_MB).toLocaleString('en-US', opts) + ' MB'
        : (bytes * CONVERT_KB).toLocaleString('en-US', opts) + ' KB'
}

export { MediaContentsEditor }

export function useMediaSrc(url: string) {
    const name = getName(url)
    const [mediaSrc, setMediaSrc] = useState('')
    const [size, setSize] = useState('? KB')
    const { data: downloadResponse } = useAsync(() => downloadFileFromS3(url), [url])
    useEffect(() => {
        if (!downloadResponse) {
            return
        }
        try {
            const file = new File([downloadResponse?.Body as ArrayBufferView], name)
            setSize(sizeDescription(file.size))
            setMediaSrc(URL.createObjectURL(file))
        } catch (e: unknown) {
            console.error('Unable to retrieve media from S3.', e)
        }
    }, [downloadResponse, name])
    return { name, mediaSrc, size }
}
