import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface VirtualMeetingDTO extends ItemDTO, MediaElement {
    itemType: ItemType.VirtualMeeting
    titleI18N: LocalizedAttribute<string>
    optional: boolean
    avatars: AvatarDTO[]
    playbackLimit?: number
}

export interface AvatarDTO extends MediaElement {
    firstNameI18N: LocalizedAttribute<string>
    lastNameI18N: LocalizedAttribute<string>
    informationLine1I18N: LocalizedAttribute<string>
    informationLine2I18N: LocalizedAttribute<string>
}

export const defaultVirtualMeetingDTO = (): VirtualMeetingDTO => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        preserveOrder: true,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.VirtualMeeting,
        ppt: ItemType.VirtualMeeting,
        titleI18N: LocalizeDefault<string>(''),
        avatars: [
            {
                firstNameI18N: LocalizeDefault<string>(''),
                lastNameI18N: LocalizeDefault<string>(''),
                informationLine1I18N: LocalizeDefault<string>(''),
                informationLine2I18N: LocalizeDefault<string>(''),
            },
            {
                firstNameI18N: LocalizeDefault<string>(''),
                lastNameI18N: LocalizeDefault<string>(''),
                informationLine1I18N: LocalizeDefault<string>(''),
                informationLine2I18N: LocalizeDefault<string>(''),
            },
        ],
        playbackLimit: undefined,
    }
}
