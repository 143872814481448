import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { MediaElement } from 'src/models/dto/items/MediaDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export interface LikertRowStatementScore {
    score: number
}

export interface LikertRowStatementObject {
    rowStatement: string
    rowStatementI18N: LocalizedAttribute<string>
    rowStatementLabel: string
    rowStatementScores: LikertRowStatementScore[]
}

export interface LikertScaleObject {
    nameI18N: LocalizedAttribute<string>
}

export interface LikertItemDTO extends ItemDTO, MediaElement {
    itemType: ItemType.Likert
    statementI18N: LocalizedAttribute<string>
    scales: LikertScaleObject[]
    rowStatementObjects: LikertRowStatementObject[]
    isResponseOrderRandomizationEnabled: boolean
}

export interface LikertStimulusDTO extends ItemDTO, MediaElement {
    itemType: ItemType.LikertStimulus
    stimulusI18N: LocalizedAttribute<string>
    scores: string[]
}

export interface StimulusReferenceDTO {
    stimulusV2DTO: LikertStimulusDTO
    optional: boolean
}

export const defaultLikertStimulusDTO = (): LikertStimulusDTO => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.LikertStimulus,
        ppt: ItemType.LikertStimulus,
        stimulusI18N: LocalizeDefault<string>(''),
        scores: [''],
    }
}

export const defaultStimulusReferenceDTO = (): StimulusReferenceDTO => {
    return {
        stimulusV2DTO: defaultLikertStimulusDTO(),
        optional: false,
    }
}

export interface LikertGroupDTO extends ItemDTO, MediaElement {
    itemType: ItemType.LikertGroup
    header: {
        statementI18N: LocalizedAttribute<string>
        scaleI18N: LocalizedAttribute<string>[]
    }
    stimulus: StimulusReferenceDTO[]
    groupSize?: number
}

export const defaultLikertGroupDTO = (): LikertGroupDTO => {
    const newId = v4()
    return {
        id: newId,
        name: newId,
        optional: false,
        preserveOrder: false,
        label: '',
        locale: Locale.en_US,
        itemType: ItemType.LikertGroup,
        ppt: ItemType.LikertGroup,
        header: {
            statementI18N: LocalizeDefault<string>(''),
            scaleI18N: [LocalizeDefault<string>('')],
        },
        stimulus: [
            {
                stimulusV2DTO: defaultLikertStimulusDTO(),
                optional: false,
            },
        ],
        groupSize: 1,
    }
}
