import { ActivityEntity } from 'src/models/dto/ActivityDTO'

export interface AdaptiveEngineSelectionGroupEntity
    extends ActivityEntity,
        AdaptiveEngineSelectionGroupDTO {}

export interface AdaptiveEngineSelectionGroupDTO {
    id: string
    name: string
    itemPoolDistributions: ItemPoolDistributionDTO[]
    adaptiveEngineItemParameters: AdaptiveEngineItemParametersDTO[]
    experimentalItemPoolID: string
    examTerminationCriteria: ExamTerminationCriteriaDTO
}

export interface ItemPoolDistributionDTO {
    itemPoolId: string
    minItems: number // integer
    percentage: number // integer
}

export interface AdaptiveEngineItemParametersDTO {
    pageID: string
    difficulty: number
    discrimination: number
    guessing: number
    group: number // integer
}

// For remembering temporary state, ignored by serialization
export const LastStandardErrorThresh = Symbol('LastStandardErrorThresh')

export interface ExamTerminationCriteriaDTO {
    maxLength?: number | null // integer
    standardErrorThresh?: number | null
    [LastStandardErrorThresh]?: number | null
}
