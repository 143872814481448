import React, { useState } from 'react'
import _ from 'lodash'

import { Expander } from '@amzn/stencil-react-components/expander'
import { Col, Hr, Row } from '@amzn/stencil-react-components/layout'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { LocalizedAttribute } from 'src/models/dto/Locale'
import { ItemBankDTO, MUPPDichotomousItemDTO } from 'src/models/dto/mupp/MUPPItemBankMetadataDTO'

export interface StatementCellProps {
    statementI18N: LocalizedAttribute<string>
    id: string
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StatementCell = ({ data }: any) => {
    const props = data as StatementCellProps
    return (
        <Col key={`${props.id}-col`} gridGap={5}>
            {Object.entries(props.statementI18N).map(([key, value], index) => {
                return (
                    <Row key={`${props.id}-${index}-row`}>
                        <Text
                            fontSize='T200'
                            dataTestId={`locale-cell-${key}-txt`}
                            id={`locale-cell-${key}`}
                            key={`${key}-${index}-${props.id}`}
                        >
                            {key} : {value}{' '}
                        </Text>
                        <Hr />
                    </Row>
                )
            })}
        </Col>
    )
}

export interface MUPPItemBankItemRowProps {
    id: React.ReactNode
    version: React.ReactNode
    label: React.ReactNode
    statementI18N: React.ReactNode
    dimension: React.ReactNode
    socialDesirability: React.ReactNode
    exposure: React.ReactNode
    delta: React.ReactNode
    alpha: React.ReactNode
    tau: React.ReactNode
}

export interface MUPPItemBankItemTableProps {
    itemBank: ItemBankDTO
    items: Map<string, MUPPDichotomousItemDTO>
}

export const MUPPItemBankItemTable = (props: MUPPItemBankItemTableProps) => {
    const [selectedPage, setSelectedPage] = useState(1)
    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: SortDirection.Ascending,
        defaultActiveSortId: 'label',
    })

    const pageSize = 10

    const columns: TableColumn<MUPPDichotomousItemDTO>[] = [
        { header: 'Item Label', accessor: 'label', sortId: 'label' },
        { header: 'Version ID', accessor: 'version' },
        { header: 'ID', accessor: 'id' },
        {
            header: 'Statement',
            accessor: 'statementI18N',
            width: '50%',
            cellComponent: StatementCell,
        },
        { header: 'Dimension', accessor: 'dimension', sortId: 'dimension' },
        { header: 'Social Desirability', accessor: 'socialDesirability' },
        { header: 'Exposure', accessor: 'exposure' },
        { header: 'Delta', accessor: 'delta' },
        { header: 'Alpha', accessor: 'alpha' },
        { header: 'Tau', accessor: 'tau' },
    ]

    const numberOfPages = Math.ceil(props.items.size / pageSize)

    const sortData = (
        id: string,
        directions: SortDirection,
        items: MUPPDichotomousItemDTO[]
    ): MUPPItemBankItemRowProps[] => {
        const direction = sortDirection === SortDirection.Ascending ? 'asc' : 'desc'

        let sorted: MUPPDichotomousItemDTO[]
        if (activeSortId === 'dimension') {
            sorted = _.orderBy(items, 'dimension', [direction, direction])
        } else {
            sorted = _.orderBy(items, 'label', [direction, direction])
        }

        return sorted.map((i, index) => {
            const row: MUPPItemBankItemRowProps = {
                id: (
                    <Text fontSize='T200' key={`id-${index}`}>
                        {i.id}
                    </Text>
                ),
                version: (
                    <Text fontSize='T200' key={`version-${index}`}>
                        {i.version}
                    </Text>
                ),
                label: (
                    <Text fontSize='T200' key={`label-${index}`}>
                        {i.label}
                    </Text>
                ),
                socialDesirability: (
                    <Text fontSize='T200' key={`sd-${index}`}>
                        {i.socialDesirability}
                    </Text>
                ),
                exposure: (
                    <Text fontSize='T200' key={`ex-${index}`}>
                        {i.exposure}
                    </Text>
                ),
                delta: (
                    <Text fontSize='T200' key={`de-${index}`}>
                        {i.delta}
                    </Text>
                ),
                alpha: (
                    <Text fontSize='T200' key={`al-${index}`}>
                        {i.alpha}
                    </Text>
                ),
                tau: (
                    <Text fontSize='T200' key={`ta-${index}`}>
                        {i.tau}
                    </Text>
                ),
                dimension: (
                    <Text fontSize='T200' key={`di-${index}`}>
                        {i.dimension}
                    </Text>
                ),
                statementI18N: {
                    statementI18N: i.statementI18N,
                    id: i.id,
                },
            }
            return row
        })
    }

    const sortedData = sortData(
        activeSortId ?? 'label',
        sortDirection,
        Array.from(props.items.values())
    )

    // eslint-disable-next-line no-mixed-operators
    const page = sortedData.slice(
        (selectedPage - 1) * pageSize,
        (selectedPage - 1) * pageSize + pageSize
    )

    return (
        <Col flex={1} gridGap={'S200'}>
            <Expander
                titleText={`Statements: ${props.items.size}`}
                aria-label={'item bank item table'}
                dataTestId={'mupp-item-bank-item-table-expander'}
                shouldExpandOnMount={true}
            >
                <Col flex={1} gridGap='S200'>
                    <Table
                        dataTestId='item-bank-item-table'
                        aria-label={'item bank item table'}
                        columns={columns}
                        activeSortId={activeSortId}
                        sortDirection={sortDirection}
                        onSort={onSort}
                        getRowAttributes={({ index }) => {
                            return {
                                dataTestId: `item-bank-item-row-${index}`,
                                'aria-label': `item-bank-item-row-${index}`,
                            }
                        }}
                        data={page}
                    />
                    <Pagination
                        dataTestId={'item-bank-item-table-pagination'}
                        aria-label='item-bank-item-table-pagination'
                        numberOfPages={numberOfPages}
                        onPageSelect={(tablePage) => {
                            setSelectedPage(tablePage)
                        }}
                        selectedPage={selectedPage}
                    />
                </Col>
            </Expander>
        </Col>
    )
}
