import React, { useContext, useMemo, useState } from 'react'

import { ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconBin, IconSearch } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { Modal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { H4 } from '@amzn/stencil-react-components/text'

import { Button } from 'src/components/Button'
import { ModalCloseButtonComponent } from 'src/components/MediaContentsEditor/ModalCloseButtonComponent'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleMetaData } from 'src/models/SearchResultResponse'
import {
    ModuleInfo,
    ModuleType,
} from 'src/pages/automation-tests-request/WorkflowDefinitionsUploaderPage'
import {
    ItemEditorNumberInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { SearchResultsPage } from 'src/pages/search/SearchResultsPage'

interface ModuleInfoInputProps {
    module: ModuleInfo
    index: number
    removeModule(index: number): void
    updateModule(index: number, value: ModuleInfo): void
    isLast: boolean
}

export const ModuleInfoInput = ({
    module,
    index,
    removeModule,
    updateModule,
    isLast,
}: ModuleInfoInputProps) => {
    const SELECTABLE_TYPES = useMemo(() => Object.values(ModuleType), [])
    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '300px'
    const [searchModule, setSearchModule] = useState(false)

    const { theme } = useContext(StencilContext)
    const width = theme.constants.maxWidth
    const maxWidth = matches.s ? '100vw' : '95vw'
    const minHeight = matches.s ? '100vh' : '95vh'

    const close = () => {
        setSearchModule(false)
    }

    return (
        <>
            {index !== 0 && (
                <div className='dashed-add'>
                    <div className='dashed-line' />
                    <Spacer height={'S500'} />
                </div>
            )}
            <H4> Module {index + 1} </H4>
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`} width={matches.s ? '100%' : '375px'}>
                    <Spacer height={'S200'} />
                    <ItemEditorTextInput
                        dataTestId={`module-${index + 1}-name`}
                        inputId={`module-${index + 1}-name`}
                        value={module.name}
                        placeholder={'Enter the module name'}
                        labelText={`Module ${index + 1} name`}
                        itemId={`module-${index + 1}-name`}
                        disabled={true}
                        setValue={(name: string) => {
                            updateModule(index, {
                                ...module,
                                name,
                            })
                        }}
                    />
                </Col>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`} width={matches.s ? '100%' : '375px'}>
                    <Spacer height={'S200'} />
                    <ItemEditorTextInput
                        dataTestId={`module-${index + 1}-versionId`}
                        inputId={`module-${index + 1}-versionId`}
                        value={module.versionId}
                        placeholder={'Enter the module version id'}
                        labelText={`Module ${index + 1} versionId`}
                        itemId={`module-${index + 1}-version-id`}
                        disabled={true}
                        setValue={(versionId: string) => {
                            updateModule(index, {
                                ...module,
                                versionId,
                            })
                        }}
                    />
                </Col>
                <Col flex={'3 0 250px'} width={matches.s ? '100%' : '205px'}>
                    <InputWrapper
                        labelText={`What layout is module ${index + 1}?`}
                        id='basic-details-layout'
                    >
                        {(props) => (
                            <Select
                                dataTestId={`module-${index + 1}-type`}
                                options={SELECTABLE_TYPES}
                                placeholder='Select layout'
                                {...props}
                                value={module.type ?? ''}
                                onChange={(type: ModuleType) => {
                                    if (type === ModuleType.WorkflowInstruction) {
                                        module.pageTitles = [
                                            'What to Expect',
                                            'Tips and Reminders',
                                            'Accommodations',
                                        ]
                                    } else {
                                        delete module.pageTitles
                                    }

                                    if (type === ModuleType.WorkStyles) {
                                        module.itemCount = -1
                                    } else {
                                        delete module.itemCount
                                    }

                                    if (type === ModuleType.CRAInstruction) {
                                        module.pages = -1
                                    } else {
                                        delete module.pages
                                    }

                                    updateModule(index, {
                                        ...module,
                                        type,
                                    })
                                }}
                            />
                        )}
                    </InputWrapper>
                </Col>
                <Col>
                    <Spacer height={'S500'} />
                    <Button
                        id={`delete-module-${index + 1}-button`}
                        dataTestId={`delete-module-${index + 1}-button`}
                        variant={ButtonVariant.Tertiary}
                        isDestructive={true}
                        onClick={() => {
                            removeModule(index)
                        }}
                        disabled={isLast}
                        aria-label={`Delete module info at index ${index + 1}.`}
                        icon={<IconBin aria-hidden={true} />}
                    />
                </Col>
                <Col>
                    <Spacer height={'S500'} />
                    <Button
                        id={`search-module-${index + 1}-button`}
                        dataTestId={`search-module-${index + 1}-button`}
                        variant={ButtonVariant.Tertiary}
                        onClick={() => {
                            setSearchModule(true)
                        }}
                        aria-label={`Search for module at index ${index + 1}.`}
                        icon={<IconSearch aria-hidden={true} />}
                    />
                </Col>
            </Row>
            {module.itemCount && (
                <Row gridGap='S300' alignItems={'flex-start'}>
                    <Col width={matches.s ? '100%' : '375px'}>
                        <Spacer height={'S200'} />
                        <ItemEditorNumberInput
                            dataTestId={`module-${index + 1}-itemCount`}
                            inputId={`module-${index + 1}-item-count`}
                            labelText={`How many questions are in the WorkStyles for module ${
                                index + 1
                            }?`}
                            value={module.itemCount >= 0 ? module.itemCount : null}
                            placeholder='10'
                            setValue={(nextValue: number | null) => {
                                updateModule(index, {
                                    ...module,
                                    itemCount: nextValue ?? -1,
                                })
                            }}
                            itemId={`module-${index + 1}`}
                            min={1}
                        />
                    </Col>
                </Row>
            )}
            {module.pages && (
                <Row gridGap='S300' alignItems={'flex-start'}>
                    <Col width={matches.s ? '100%' : '375px'}>
                        <Spacer height={'S200'} />
                        <ItemEditorNumberInput
                            dataTestId={`module-${index + 1}-CRAInstructionsPages`}
                            inputId={`module-${index + 1}-CRAInstructionsPages`}
                            labelText={`How many pages are in the CRA Instructions for module ${
                                index + 1
                            }?`}
                            value={module.pages >= 0 ? module.pages : null}
                            placeholder='10'
                            setValue={(nextValue: number | null) => {
                                updateModule(index, {
                                    ...module,
                                    pages: nextValue ?? -1,
                                })
                            }}
                            itemId={`module-${index + 1}`}
                            min={1}
                        />
                    </Col>
                </Row>
            )}
            <Modal dataTestId='search-module' isOpen={searchModule} isScrollable close={close}>
                <div
                    style={{
                        width,
                        maxWidth,
                        minHeight,
                        height: minHeight,
                        backgroundColor: '#F8F8F8',
                        overflow: 'auto',
                    }}
                >
                    <div
                        style={{
                            padding: '5px',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ModalCloseButtonComponent close={close} />
                    </div>
                    <div style={{ marginTop: '-60px' }}>
                        <SearchResultsPage
                            embeddedInModal={true}
                            lockedModuleStatus={[
                                ModuleStatus.UAT,
                                ModuleStatus.PUBLISHED,
                                ModuleStatus.LIVE,
                            ]}
                            selectModuleAction={{
                                header: <ScreenReaderOnly>Select action</ScreenReaderOnly>,
                                width: '10%',
                                cellComponent: ({ data }: { data: ModuleMetaData }) => {
                                    return (
                                        <Col
                                            alignItems={'center'}
                                            justifyContent={'space-around'}
                                            height={'100%'}
                                        >
                                            <Button
                                                size={ButtonSize.Small}
                                                variant={ButtonVariant.Tertiary}
                                                dataTestId='use-search-result'
                                                onClick={() => {
                                                    updateModule(index, {
                                                        name: data.moduleName,
                                                        versionId: data.moduleVersionId,
                                                        type: module.type,
                                                    })
                                                    close()
                                                }}
                                            >
                                                Select
                                            </Button>
                                        </Col>
                                    )
                                },
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}
