import React, { useCallback, useEffect, useState } from 'react'

import { Col, Flex, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { SmartExpanderContent } from 'src/components/SmartExpander'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useActivityEntity, useEntity } from 'src/hooks/useEntity'
import { RandomSelectionGroupEntity } from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    ItemEditorNumberInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { activityDisplayNameMap } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { PageEditorProps } from 'src/pages/module-builder/page-editor/PageEditor'
import { PageHeader } from 'src/pages/module-builder/page-editor/PageHeader'
import { ItemPoolModal } from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ItemPoolModal'
import { RandomSelectionGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/RandomSelectionGroupHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const RandomSelectionGroupEditor = ({
    pageIndex,
    locale,
    moduleEntityId,
    workflowEntityId,
    isLastPage,
    showValidationError,
    defaultExpanded,
    editDisabled,
}: PageEditorProps) => {
    const { matches } = useBreakpoints()

    const { entity } = useActivityEntity<RandomSelectionGroupEntity>({
        workflowEntityId,
        moduleEntityId,
    })

    if (!ValidationErrorEntityService.has(workflowEntityId)) {
        ValidationErrorEntityService.create(workflowEntityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [showError, setShowError] = useState(showValidationError)

    useEffect(() => {
        setShowError(showValidationError ?? false)
    }, [showValidationError])

    const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? true)

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '400px'

    const setVersionId = useCallback(
        (versionId: string) => {
            RandomSelectionGroupHandler.updateItemPool(entity.id, versionId)
        },
        [entity.id]
    )

    return (
        <Col gridGap='S300' dataTestId={`page-editor-${pageIndex}`}>
            <Spacer height='S100' />
            <PageHeader
                isLastPage={isLastPage}
                moduleEntityId={moduleEntityId}
                workflowEntityId={workflowEntityId}
                isExpanded={isExpanded}
                pageIndex={pageIndex}
                onclick={() => {
                    setIsExpanded(!isExpanded)
                }}
                activityName={activityDisplayNameMap.get(entity.type)}
                showValidationError={showError}
                onErrorCheck={setShowError}
                editDisabled={editDisabled}
            />
            <SmartExpanderContent isExpanded={isExpanded}>
                {() => (
                    <>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showError && (
                            <MessageBanner type={MessageBannerType.Error}>
                                <ul>
                                    {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                        (message, index) => (
                                            <li key={index}>{message}</li>
                                        )
                                    )}
                                </ul>
                            </MessageBanner>
                        )}
                        <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                            <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                                <ItemEditorTextInput
                                    inputId={'rsg-item-pool-version-id'}
                                    dataTestId={'rsg-item-pool-version-id'}
                                    value={entity.poolVersionId}
                                    placeholder={'Enter the item pool version ID'}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    itemId={entity.id}
                                    setValue={(nextValue: string) => {
                                        RandomSelectionGroupHandler.updateItemPool(
                                            entity.id,
                                            nextValue
                                        )
                                    }}
                                    validationErrorMessage={(
                                        validationErrorEntity.validationErrors.poolVersionId ?? []
                                    ).join(', ')}
                                    showError={showError}
                                    labelText={'Item Pool version ID'}
                                />
                            </Col>
                            <Col>
                                <Spacer height='S400' />
                                <Flex flexDirection={'row'} columnGap={5}>
                                    <ItemPoolModal
                                        existingPool={entity.poolVersionId !== ''}
                                        itemPoolVersionId={entity.poolVersionId}
                                        setSelectItemPoolVersionId={setVersionId}
                                        disabledItemPoolModal={
                                            !isDefaultLocale(locale) || editDisabled
                                        }
                                    />
                                </Flex>
                            </Col>
                        </Row>
                        <Spacer height='S200' />
                        <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                            <Col flex={`3 0 ${matches.s ? '100%' : '200px'}`}>
                                <ItemEditorNumberInput
                                    dataTestId={'rsg-questions-size'}
                                    inputId={'rsg-questions-size'}
                                    itemId={entity.id}
                                    labelText='Question selection size (5-200)'
                                    value={entity.numberOfItemsToPick}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    placeholder='10'
                                    setValue={(nextValue: number | null) => {
                                        RandomSelectionGroupHandler.updateNoOfItemsToPick(
                                            entity.id,
                                            nextValue
                                        )
                                    }}
                                    validationErrorMessage={(
                                        validationErrorEntity.validationErrors
                                            .numberOfItemsToPick ?? []
                                    ).join(', ')}
                                    showError={showError}
                                />
                            </Col>
                            <Col flex={`3 0 ${matches.s ? '100%' : '200px'}`}>
                                <ItemEditorNumberInput
                                    dataTestId={'rsg-questions-per-page'}
                                    inputId={'rsg-questions-per-page'}
                                    itemId={entity.id}
                                    labelText='Question per page (1-10)'
                                    value={entity.groupSize}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                    placeholder='5'
                                    setValue={(nextValue: number | null) => {
                                        RandomSelectionGroupHandler.updateGroupSize(
                                            entity.id,
                                            nextValue
                                        )
                                    }}
                                    validationErrorMessage={(
                                        validationErrorEntity.validationErrors.groupSize ?? []
                                    ).join(', ')}
                                    showError={showError}
                                />
                            </Col>
                        </Row>
                        <Spacer height='S400' />
                    </>
                )}
            </SmartExpanderContent>
        </Col>
    )
}
