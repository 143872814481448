import AWS, { DynamoDB } from 'aws-sdk'
import { WriteRequest } from 'aws-sdk/clients/dynamodb'

export class DDBHandler {
    private readonly ddbTable: string

    constructor(ddbTable: string) {
        this.ddbTable = ddbTable
    }

    public loadResults(
        keyName: string,
        keyValue: string,
        config: DynamoDB.Types.ClientConfiguration
    ) {
        const params = {
            TableName: this.ddbTable,
            KeyConditionExpression: '#keyName = :keyValue',
            ExpressionAttributeValues: {
                ':keyValue': { S: keyValue },
            },
            // ExpressionAttributeNames is required because we have used a specific primary key
            ExpressionAttributeNames: {
                '#keyName': keyName,
            },
        }

        return new AWS.DynamoDB(config).query(params).promise()
    }

    public batchWrite(requests: WriteRequest[], config: DynamoDB.Types.ClientConfiguration) {
        const params = {
            RequestItems: {
                [this.ddbTable]: requests,
            },
        }

        return new AWS.DynamoDB(config).batchWriteItem(params).promise()
    }
}
