import { v4 } from 'uuid'

import { ModuleGroupDTO } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { Store, STORE_ACTION } from '../Store'

export const MODULE_GROUP_ENTITY_SELECTOR = 'ModuleGroupEntity'

export class ModuleGroupEntityService {
    static store: Store<ModuleGroupDTO>

    static init() {
        this.store = new Store<ModuleGroupDTO>(MODULE_GROUP_ENTITY_SELECTOR)
    }

    static get(entityId: string): ModuleGroupDTO {
        return this.store.get(entityId)
    }

    static has(entityId: string): boolean {
        return this.store.has(entityId)
    }

    static insert(entity: ModuleGroupDTO) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_CREATE,
            entityId: entity.versionId,
            payload: entity,
        })
    }

    static update(entity: ModuleGroupDTO) {
        this.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: entity.versionId,
            payload: entity,
        })
    }

    static create(): ModuleGroupDTO {
        const entity: ModuleGroupDTO = {
            name: '',
            id: v4(),
            versionId: v4(),
            status: '',
            moduleMappings: {},
            savedTimeToken: -1,
            purpose: '',
            moduleMetadataList: [],
        }

        this.insert(entity)
        return entity
    }

    static insertOrUpdate(moduleGroup: ModuleGroupDTO) {
        if (this.has(moduleGroup.versionId)) {
            this.update(moduleGroup)
        } else {
            this.insert(moduleGroup)
        }
    }
}
