import * as KatalMetrics from '@amzn/katal-metrics'
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector'
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson'
import KatalMetricsContext from '@amzn/katal-metrics/lib/KatalMetricsContext'
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher'
import KatalMetricObject from '@amzn/katal-metrics/lib/metricObject/KatalMetricObject'
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi'
import SushiClient, { SushiClientOverride, SushiOptions } from '@amzn/katal-sushi-client'

import { APP_CONFIG, STAGE } from 'src/config.app'

// Katal TypeScript headers declare these builder types to return "any" instead of "this", which causes
// ESLint errors. These are redeclared versions to work around these errors
interface KatalMetricsDriverSushiBuilderPatched {
    context: unknown
    withSushiClient(sushiClient: SushiClient): this
    withDomainRealm(domain: string, realm: string): this
    withCustomProducer(sushiProducerId: string): this
    withCustomSourceGroup(sourceGroupId: string): this
    withErrorHandler(errorHandler: (error: Error) => void): this
    withSushiClientOptions(sushiClientOptions: SushiOptions): this
    withSushiClientTransportOverride(sushiClientTransportOverride: SushiClientOverride): this
    build(): KatalMetricsDriverSushi
}

interface KatalMetricsContextBuilderPatched {
    context: unknown
    withSite(site: string): this
    withServiceName(serviceName: string): this
    withMethodName(methodName: string): this
    withActionId(actionId: string): this
    withRequestId(requestId: string): this
    withRelatedMetrics(...relatedMetrics: KatalMetricObject[]): this
    addRelatedMetrics(...relatedMetrics: KatalMetricObject[]): this
    withRelatedMetricsSingleAction(...metrics: KatalMetricObject[]): this
    addRelatedMetricsSingleAction(...metrics: KatalMetricObject[]): this
    build(): KatalMetricsContext
}

const metricsConsoleErrorHandler = (err: Error) => console.error(err)

const stage = APP_CONFIG?.stage ?? STAGE.LOCAL

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    if (process.env.NODE_ENV === 'test') {
        const metricsDriver = new KatalMetricsDriverArrayCollector()
        //  Attach to global window object so tests can see it
        ;(window as unknown as { metricsDriver: KatalMetrics.MetricsDriver }).metricsDriver =
            metricsDriver
        return metricsDriver
    }

    if (stage === STAGE.LOCAL) {
        return new KatalMetricsDriverConsoleLogJson()
    } else {
        /**
         * An ongoing issue with PreProd Katal Metrics data in Pmet - https://issues.amazon.com/KAT-7727
         * We will use 'prod' stage insteuntil the issue is gone
         */
        return (
            new KatalMetricsDriverSushi.Builder() as unknown as KatalMetricsDriverSushiBuilderPatched
        )
            .withDomainRealm('prod', 'USAmazon') //should be replaced with "stage === STAGE.PROD ? 'prod' : 'preprod', 'USAmazon'" after the issue has resolved.
            .withErrorHandler(metricsConsoleErrorHandler)
            .build()
    }
}

const makePublisher = (): KatalMetricsPublisher => {
    const metricsDriver = makeMetricsDriver()
    const serviceName = 'RxtAssessmentDesignerV2Website'

    const initialMetricsContext: KatalMetricsContext = (
        new KatalMetrics.Context.Builder() as unknown as KatalMetricsContextBuilderPatched
    )
        .withSite('AssessmentDesignerV2')
        .withServiceName(stage === STAGE.PROD ? serviceName : `${serviceName}.${stage}`)
        .build()
    return new KatalMetricsPublisher(
        metricsDriver,
        metricsConsoleErrorHandler,
        initialMetricsContext
    )
}

const initialMetricsPublisher = makePublisher()

interface KatalMetricsPublisherMap {
    [key: string]: KatalMetricsPublisher
}

const apiCallMetricPublishers: KatalMetricsPublisherMap = {}

export function publishApiCallMetrics(
    apiName: string,
    responseTime: number,
    isError = false,
    isValidationError = false
) {
    if (!apiCallMetricPublishers[apiName]) {
        apiCallMetricPublishers[apiName] =
            initialMetricsPublisher.newChildActionPublisherForMethod(apiName)
    }

    if (responseTime !== 0) {
        apiCallMetricPublishers[apiName].publishTimerMonitor('responseTime', responseTime)
    }

    apiCallMetricPublishers[apiName].publishCounterMonitor('errorResponse', isError ? 1 : 0)

    if (isError) {
        apiCallMetricPublishers[apiName].publishCounterMonitor(
            'validationError',
            isValidationError ? 1 : 0
        )
    }
}

export enum UserEventMethodNames {
    HomePage = 'HomePage',
    SearchResult = 'SearchResult',
    ModuleViewer = 'ModuleViewer',
    ModuleBuilder = 'ModuleBuilder',
    ModuleReview = 'ModuleReview',
    ItemPoolEditor = 'ItemPoolEditor',
    RefreshingToken = 'RefreshingToken',
    DeployModuleVersionPreview = 'DeployModuleVersionPreview',
    DeployModuleVersionUAT = 'DeployModuleVersionUAT',
    DeployModuleVersionProduction = 'DeployModuleVersionProduction',
    ResearchWorkflow = 'ResearchWorkflow',
    WorkflowAutomationRequest = 'WorkflowAutomationRequest',
}

export enum CommonUserEventKeys {
    Open = 'open',
}

export enum ModuleBuilderEventKeys {
    CreateNewModule = 'createNewModule',
    ContinueEditingModule = 'continueEditingModule',
    AddContent = 'addContent',
}

const userEventMetricPublishers: KatalMetricsPublisherMap = {}
export function publishUserEventMetrics(
    userEventMethodName: UserEventMethodNames,
    userEventKey: string,
    count = 1
) {
    if (!userEventMetricPublishers[userEventMethodName]) {
        userEventMetricPublishers[userEventMethodName] =
            initialMetricsPublisher.newChildActionPublisherForMethod(userEventMethodName)
    }

    const publisher: KatalMetricsPublisher = userEventMetricPublishers[userEventMethodName]
    publisher.publishCounterMonitor(userEventKey, count)
}

const errorEventMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('Error')
export function publishErrorEventMetrics(errorType: string, count = 1) {
    errorEventMetricPublisher.publishCounterMonitor(errorType, count)
}

const timeSpentMetricsPublishder = initialMetricsPublisher.newChildActionPublisherForMethod('timer')
export function publishTimeSpentMetrics(userEventMethodName: UserEventMethodNames, timer: number) {
    timeSpentMetricsPublishder.publishTimerMonitor(userEventMethodName.toString(), timer)
}

export default initialMetricsPublisher
