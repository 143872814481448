import { ResponseBodyType } from 'src/models/ResponseBodyType'

export interface MediaValidationErrorMessage {
    id?: string | null
    message: string
}

export interface MediaErrorTemplate {
    message: string
    attribute: string
}

export interface MediaValidationErrorResponse {
    responseBodyType: ResponseBodyType
    mediaValidationErrorMessages?: MediaValidationErrorMessage[]
}

export class MediaValErrorResponseThrowable extends Error {
    errorResponse: MediaValidationErrorResponse

    constructor(errorResponse: MediaValidationErrorResponse) {
        super()
        this.errorResponse = errorResponse
    }
}
