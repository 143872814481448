import React from 'react'

import { StencilProvider } from '@amzn/stencil-react-components/context'
import { Col } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'

export const AuthenticationFailure = () => {
    return (
        <StencilProvider>
            <Col padding={20}>
                <MessageBanner type={MessageBannerType.Error}>Authentication failed</MessageBanner>
            </Col>
        </StencilProvider>
    )
}
