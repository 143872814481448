import React, { useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker'
import { Expander } from '@amzn/stencil-react-components/expander'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconSearch } from '@amzn/stencil-react-components/icons'
import { Col, Container } from '@amzn/stencil-react-components/layout'

import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { ModuleGroupStatus, ModuleGroupType } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'

interface ModuleGroupsFilterProps {
    moduleGroupName: string
    typeOfEquivalency: ModuleGroupType[]
    moduleVersionId: string
    status: ModuleGroupStatus[]
    startDate: string
    endDate: string
    ownerOfRecord: string
    updateGroupName: (groupName: string) => void
    updateTypeOfEquivalency: (typeOfEquivalency: ModuleGroupType[]) => void
    updateModuleVersionId: (moduleVersionId: string) => void
    updateStatus: (status: ModuleGroupStatus[]) => void
    updateStartDate: (startDate: string) => void
    updateEndDate: (endDate: string) => void
    updateOwnerOfRecord: (ownerOfRecord: string) => void
    applyFilters: () => void
    syncState: boolean
    updateSyncState: (syncState: boolean) => void
    sideFilter: boolean
}

export const ModuleGroupsFilter = (props: ModuleGroupsFilterProps) => {
    const [moduleGroupName, setModuleGroupName] = useState<string>('')
    const [typeOfEquivalency, setTypeOfEquivalency] = useState<ModuleGroupType[]>([])
    const [moduleVersionId, setModuleVersionId] = useState<string>('')
    const [status, setStatus] = useState<ModuleGroupStatus[]>([])
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [ownerOfRecord, setOwnerOfRecord] = useState<string>('')

    const setFieldsInParent = () => {
        props.updateStatus(status)
        props.updateModuleVersionId(moduleVersionId)
        props.updateEndDate(endDate)
        props.updateStartDate(startDate)
        props.updateOwnerOfRecord(ownerOfRecord)
        props.updateGroupName(moduleGroupName)
        props.updateTypeOfEquivalency(typeOfEquivalency)
    }

    const resetParentFields = () => {
        props.updateStatus([])
        props.updateModuleVersionId('')
        props.updateEndDate('')
        props.updateStartDate('')
        props.updateOwnerOfRecord('')
        props.updateGroupName('')
        props.updateTypeOfEquivalency([])
        props.applyFilters()
    }

    useEffect(() => {
        if (props.syncState) {
            props.updateSyncState(false)
            setModuleGroupName(props.moduleGroupName)
            setStartDate(props.startDate)
            setEndDate(props.endDate)
            setTypeOfEquivalency(props.typeOfEquivalency)
            setModuleVersionId(props.moduleVersionId)
            setOwnerOfRecord(props.ownerOfRecord)
            setStatus(props.status)
        }
    }, [
        props,
        props.endDate,
        props.moduleGroupName,
        props.moduleVersionId,
        props.ownerOfRecord,
        props.startDate,
        props.status,
        props.syncState,
        props.typeOfEquivalency,
    ])

    const isStartDateDisabled = (date: string): boolean => {
        return !!endDate && new Date(date) > new Date(endDate)
    }

    const isEndDateDisabled = (date: string) => {
        return !!startDate && new Date(date) < new Date(startDate)
    }

    const isStartAfterEnd = () => {
        return !!endDate && !!startDate && new Date(startDate) > new Date(endDate)
    }

    if (props.sideFilter) {
        return (
            <Container>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <InputWrapper id='module-group-name-input' labelText='Module group name'>
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    value={moduleGroupName}
                                    insetElementLeading={{
                                        element: <IconSearch aria-hidden={true} />,
                                    }}
                                    dataTestId='module-group-name-input'
                                    placeholder='Enter module group'
                                    aria-label='Module Group name'
                                    onChange={(e) => {
                                        setModuleGroupName(e.target.value)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <InputWrapper id='module-version-id-input' labelText='Module Version ID'>
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    value={moduleVersionId}
                                    dataTestId='module-version-id-input'
                                    placeholder='Enter a Module Version ID'
                                    aria-label='Module version id'
                                    onChange={(e) => {
                                        setModuleVersionId(e.target.value)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <InputWrapper
                            id='type-of-equivalency-input'
                            labelText='Type of equivalency'
                        >
                            {(inputProps) => (
                                <Select
                                    {...inputProps}
                                    value={typeOfEquivalency}
                                    dataTestId='type-of-equivalency-input'
                                    placeholder='Select an equivalency'
                                    aria-label='Type of equivalency'
                                    options={Object.keys(ModuleGroupType)}
                                    onChange={setTypeOfEquivalency}
                                    isMulti={true}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <InputWrapper id='status-input' labelText='Status'>
                            {(inputProps) => (
                                <Select
                                    {...inputProps}
                                    value={status}
                                    dataTestId='status-input'
                                    placeholder='Select a Status'
                                    aria-label='Status'
                                    options={Object.values(ModuleGroupStatus)}
                                    onChange={setStatus}
                                    isMulti={true}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <InputWrapper id='owner-of-record-input' labelText='Owner of record'>
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    value={ownerOfRecord}
                                    dataTestId='owner-of-record-input'
                                    placeholder='Enter owner of record'
                                    aria-label='Owner of record'
                                    onChange={(e) => {
                                        setOwnerOfRecord(e.target.value)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <InputWrapper
                        labelText='Start Date'
                        id='start-date-wrapper'
                        error={isStartAfterEnd()}
                        footer={
                            isStartAfterEnd() ? 'Start date must be before end date' : undefined
                        }
                    >
                        {(dateProps) => (
                            <PopupDatePicker
                                {...dateProps}
                                id='start-date-input'
                                dataTestId='start-date-input'
                                inputProps={{
                                    width: '100%',
                                }}
                                value={startDate}
                                onChange={setStartDate}
                                isDateDisabled={(date) => isStartDateDisabled(date)}
                            />
                        )}
                    </InputWrapper>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <InputWrapper
                        labelText='End Date'
                        id='end-date-wrapper'
                        error={isStartAfterEnd()}
                        footer={
                            isStartAfterEnd() ? 'Start date must be before end date' : undefined
                        }
                    >
                        {(dateProps) => (
                            <PopupDatePicker
                                {...dateProps}
                                id='end-date-input'
                                dataTestId='end-date-input'
                                inputProps={{ width: '100%' }}
                                value={endDate}
                                onChange={setEndDate}
                                isDateDisabled={(date) => isEndDateDisabled(date)}
                            />
                        )}
                    </InputWrapper>
                </ResponsiveRow>
                <ResponsiveRow padding={{ top: 'S400', bottom: 'S200' }}>
                    <Col flex={1}>
                        <Button
                            onClick={() => {
                                setFieldsInParent()
                                props.applyFilters()
                            }}
                            dataTestId='apply-module-group-filters'
                        >
                            Filter
                        </Button>
                    </Col>
                </ResponsiveRow>
                <ResponsiveRow padding={{ bottom: 'S200' }}>
                    <Col flex={1}>
                        <Button variant={ButtonVariant.Tertiary} onClick={resetParentFields}>
                            Reset filter
                        </Button>
                    </Col>
                </ResponsiveRow>
            </Container>
        )
    }

    return (
        <Container backgroundColor={'neutral05'} paddingTop='S200' paddingHorizontal='S200'>
            <Expander titleText={'Filters'} shouldExpandOnMount={true}>
                <Container paddingHorizontal={'S600'} paddingBottom={'S200'}>
                    <ResponsiveRow gridGap={'S200'} padding={{ bottom: 'S200' }}>
                        <Col flex={1}>
                            <InputWrapper
                                id='module-group-name-input'
                                labelText='Module group name'
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        value={moduleGroupName}
                                        insetElementLeading={{
                                            element: <IconSearch aria-hidden={true} />,
                                        }}
                                        dataTestId='module-group-name-input'
                                        placeholder='Enter module group'
                                        aria-label='Module Group name'
                                        onChange={(e) => {
                                            setModuleGroupName(e.target.value)
                                        }}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1}>
                            <InputWrapper
                                id='module-version-id-input'
                                labelText='Module Version ID'
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        value={moduleVersionId}
                                        dataTestId='module-version-id-input'
                                        placeholder='Enter a Module Version ID'
                                        aria-label='Module version id'
                                        onChange={(e) => {
                                            setModuleVersionId(e.target.value)
                                        }}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                    </ResponsiveRow>
                    <ResponsiveRow gridGap={'S200'} padding={{ bottom: 'S200' }}>
                        <Col flex={1}>
                            <InputWrapper
                                id='type-of-equivalency-input'
                                labelText='Type of equivalency'
                            >
                                {(inputProps) => (
                                    <Select
                                        {...inputProps}
                                        value={typeOfEquivalency}
                                        dataTestId='type-of-equivalency-input'
                                        placeholder='Select an equivalency'
                                        aria-label='Type of equivalency'
                                        options={Object.keys(ModuleGroupType)}
                                        onChange={setTypeOfEquivalency}
                                        isMulti={true}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1}></Col>
                    </ResponsiveRow>
                    <ResponsiveRow gridGap={'S200'} padding={{ bottom: 'S200' }}>
                        <Col flex={1}>
                            <InputWrapper id='status-input' labelText='Status'>
                                {(inputProps) => (
                                    <Select
                                        {...inputProps}
                                        value={status}
                                        dataTestId='status-input'
                                        placeholder='Select a Status'
                                        aria-label='Status'
                                        options={Object.values(ModuleGroupStatus)}
                                        onChange={setStatus}
                                        isMulti={true}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1}>
                            <InputWrapper id='owner-of-record-input' labelText='Owner of record'>
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        value={ownerOfRecord}
                                        dataTestId='owner-of-record-input'
                                        placeholder='Enter owner of record'
                                        aria-label='Owner of record'
                                        onChange={(e) => {
                                            setOwnerOfRecord(e.target.value)
                                        }}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                    </ResponsiveRow>
                    <ResponsiveRow gridGap={'S200'} padding={{ bottom: 'S200' }}>
                        <Col flex={1}>
                            <ResponsiveRow gridGap={'S200'} id={'row-for-time-frame'}>
                                <Col flex={1}>
                                    <PopupDatePicker
                                        id='start-date-input'
                                        dataTestId='start-date-input'
                                        labelText={'Start Date'}
                                        inputProps={{
                                            width: '100%',
                                        }}
                                        value={startDate}
                                        onChange={setStartDate}
                                    />
                                </Col>
                                <Col flex={1}>
                                    <PopupDatePicker
                                        id='end-date-input'
                                        dataTestId='end-date-input'
                                        labelText={'End Date'}
                                        inputProps={{ width: '100%' }}
                                        value={endDate}
                                        onChange={setEndDate}
                                    />
                                </Col>
                            </ResponsiveRow>
                        </Col>
                        <Col flex={1}></Col>
                    </ResponsiveRow>
                    <ResponsiveRow gridGap={'S200'} padding={{ top: 'S400', bottom: 'S200' }}>
                        <Button
                            onClick={() => {
                                setFieldsInParent()
                                props.applyFilters()
                            }}
                            dataTestId='apply-module-group-filters'
                        >
                            Filter
                        </Button>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {
                                resetParentFields()
                            }}
                        >
                            Reset filter
                        </Button>
                    </ResponsiveRow>
                </Container>
            </Expander>
        </Container>
    )
}
